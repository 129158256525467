export const initialState = {
  name: 'app',
  state: 'loading',
  services: {},
  theme: 'light',
  config: {
    auth: {
      allowPasswordChange: {
        ui: false,
        email: false,
      },
    },
    similarityThresholds: {
      low: 0.75,
      medium: 0.95,
    },
    maxImageSize: 2 * 1024 * 1024 * 1024,
    license: {},
  },
  features: {
    license: false,
    estimations: {
      face_quality: false,
      body_attributes: false,
      liveness: false,
    },
    components: {
      events: false,
      tasks: false,
      sender: false,
    },
  },
  plugins: [],
};
