import React from 'react';

import { DEEPFAKE_STATE_OPTIONS_STRICT, GENDER_OPTIONS_STRICT, AGE_OPTIONS } from '@vlabs/api-bindings/src/constants';
import { LIVENESS_OPTIONS_STRICT } from '@vlabs/api-bindings/src/luna-client/constants';
import { DeleteIcon, PlusIcon } from '@vlabs/icons';
import validate from '@vlabs/shared/validators';
import { Control, SettingsItemWrapper, Grid, GridRow, GridCol } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import './GenericPolicySubForm.sass';
import { useHandlerMatchLabels } from '../hooks';
import { defaultMatch } from './constants';

export const genericPolicyDefaultValues = {
  match: [],
  gender: null,
  ageGte: undefined,
  ageLt: undefined,
  liveness: undefined,
  deepfake: undefined,
};

const MatchEntry = ({
  fieldName,
  disabled,
  field,
  fieldArrayIndex,
  remove,
}) => {
  const form = useFormContext();
  const { t } = useTranslation();

  const matchLabels = useHandlerMatchLabels();
  const match = useWatch({ name: `${fieldName}.match` });
  const matchSimilarityGtes = match?.map(({ similarityGte }) => similarityGte);

  const similarityGte = matchSimilarityGtes[fieldArrayIndex] !== undefined
    ? Number(matchSimilarityGtes[fieldArrayIndex])
    : undefined;

  return (
    <div className="SettingsSubForm" key={field.id}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ width: '90px' }}>{t('labels')}</div>
        <div style={{ width: '240px' }}>
          <Control.Select
            control={form.control}
            disabled={disabled}
            errors={form.formState.errors}
            isClearable
            name={`${fieldName}.match.${fieldArrayIndex}.label`}
            noOptionsMessage={() => t('handlers:создайте набор параметров сравнения в блоке "Политика сравнения"')}
            options={matchLabels}
            placeholder={t('labels')}
            rules={{ required: validate.required() }}
          />
        </div>
        <span style={{ marginRight: '5px' }}>{t('с точностью')}</span>
        <div className="SettingsSubForm__AgeFilter">
          <div className="SettingsSubForm__AgeInputs">
            <Control.Input
              {...form.register(`${fieldName}.match.${fieldArrayIndex}.similarityGte`, {
                min: validate.gte(0),
                max: validate.lte(1),
                validate: validate.number(),
              })}
              disabled={disabled}
              errors={form.formState.errors}
              placeholder={t('from')}
            />
          </div>
          <div className="SettingsSubForm__AgeDivider">:</div>
          <div className="SettingsSubForm__AgeInputs">
            <Control.Input
              {...form.register(`${fieldName}.match.${fieldArrayIndex}.similarityLte`, {
                min: validate.gte(Math.max(similarityGte, 0)),
                max: validate.lte(1),
                validate: validate.number(),
              })}
              disabled={disabled}
              errors={form.formState.errors}
              placeholder={t('to')}
            />
          </div>
        </div>
        <Control.RoundButton
          data-testid="genericPolicy.deleteButton"
          icon={<DeleteIcon />}
          kind="negative"
          onClick={() => remove(fieldArrayIndex)}
          title={t('кнопка.удалить')}
          variant="dimmed"
        />
      </div>
    </div>
  );
};

export default function GenericPolicySubForm({
  fieldName,
  filtersTitle: iFiltersTitle,
  matchTitle: iMatchesTitle,
  disabled,
  children,
}) {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({ name: `${fieldName}.match` });

  const basicAttributesStatus = useWatch({ name: 'extract.basicAttributes' });
  const livenessIsEnabled = useWatch({ name: 'estimate_liveness.estimate' });
  const deepfakeIsEnabled = useWatch({ name: 'estimate_deepfake.estimate' });

  const filtersTitle = t(iFiltersTitle || 'handlers:сохранять только в тех случаях, если');
  const matchesTitle = t(iMatchesTitle);

  return (
    <div className="GenericPolicySubForm">
      <Grid>
        <GridRow><GridCol><div className="Subtitle-1">{filtersTitle}</div></GridCol></GridRow>
        <GridRow>
          <GridCol>
            <Control.Select
              control={control}
              disabled={disabled || !basicAttributesStatus}
              isClearable
              name={`${fieldName}.gender`}
              options={GENDER_OPTIONS_STRICT.raw}
              placeholder={t('gender')}
            />
          </GridCol>
          <GridCol>
            <Control.Select
              control={control}
              disabled={disabled || !basicAttributesStatus}
              isClearable
              name={`${fieldName}.age`}
              options={AGE_OPTIONS.raw}
              placeholder={t('age')}
            />
          </GridCol>
          <GridCol>
            <Control.Select
              control={control}
              disabled={disabled || !livenessIsEnabled}
              isMulti
              name={`${fieldName}.liveness`}
              options={LIVENESS_OPTIONS_STRICT.raw}
              placeholder={t('Liveness')}
            />
          </GridCol>
          <GridCol>
            <Control.Select
              control={control}
              disabled={disabled || !deepfakeIsEnabled}
              isMulti
              name={`${fieldName}.deepfake`}
              options={DEEPFAKE_STATE_OPTIONS_STRICT.raw}
              placeholder={t('Deepfake')}
            />
          </GridCol>
        </GridRow>
        <GridRow><GridCol><div className="Border" /></GridCol></GridRow>
        <GridRow><GridCol><div className="Subtitle-1">{t(matchesTitle)}</div></GridCol></GridRow>

        {useWatch({ name: `${fieldName}.match` }) && fields.map((field, i) => {
          return (
            <GridRow>
              <GridCol>
                <MatchEntry
                  disabled={disabled}
                  field={field}
                  fieldArrayIndex={i}
                  fieldName={fieldName}
                  remove={remove}
                />
              </GridCol>
            </GridRow>
          );
        })}
        <GridRow>
          <GridCol>
            <SettingsItemWrapper>
              <div className="GenericPolicy_Plus">
                <Control.RoundButton
                  data-testid="genericPolicy.plusIcon"
                  icon={<PlusIcon />}
                  kind="primary"
                  onClick={() => append(defaultMatch)}
                  title={t('кнопка.добавить')}
                  variant="dimmed"
                />
              </div>
            </SettingsItemWrapper>
          </GridCol>
        </GridRow>
        {children}
      </Grid>
    </div>
  );
}

GenericPolicySubForm.propTypes = {
  fieldName: PropTypes.string.isRequired,
  filtersTitle: PropTypes.string,
  matchTitle: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.node,
};

GenericPolicySubForm.defaultProps = {
  filtersTitle: undefined,
  matchTitle: undefined,
  disabled: undefined,
  children: undefined,
};
