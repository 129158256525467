import React from 'react';

import Loader from '@vlabs/shared/legacy-components/loader/Loader';

import st from './LoadingPage.module.sass';

const LoadingPage = () => {
  return (
    <div className={st.LoadingPage}>
      <Loader />
    </div>
  );
};

export default LoadingPage;
