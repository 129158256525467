/* eslint-disable camelcase */
import React, { useEffect } from 'react';

import { routes } from '@vlabs/shared/navigation/routes';
import { selectAppState } from '@vlabs/shared/selectors/appSelectors';
import { actionColumnProps } from '@vlabs/shared/utils';
import { Page, Panel, Table, TableCells, openConfirmPopup } from '@vlabs/uikit';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';

import { selectNotifications } from './selectors';
import {
  updatePageSize,
  updatePage,
  deleteNotification,
  fetchNotifications,
} from './store';

const NotificationListPageComponent = ({
  notificationsData,
  setNotifications,
  deletion,
  setPageSize,
  setPageIndex,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const appState = useSelector(selectAppState);
  const dispatch = useDispatch();

  useEffect(() => {
    if (appState === 'loaded') setNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, appState]);

  const onEditNotification = ({ uuid }) => {
    history.push(generatePath(routes.notifications.read, { notificationId: uuid }));
  };

  const onCreateNotification = () => {
    history.push(generatePath(routes.notifications.create));
  };

  const onDeleteNotification = (notification) => {
    const message = (
      <span>
        {t('vlNotifier:подтверждение.удаление.текст')}
        &nbsp;
        <strong>{notification.filters.name}</strong>
        ?
      </span>
    );

    const onConfirm = async () => {
      await deletion(notification?.uuid);
    };

    openConfirmPopup({
      title: t('vlNotifier:подтверждение.удаление.заголовок'),
      message,
      type: 'delete',
      onConfirm,
    });
  };

  return (
    <Page className="Global__Table_fullScreen_wrapper">
      <Panel className="Global__Table_fullScreen">
        <Table
          columns={[
            { Header: t('vlNotifier:колонки.наименование'), accessor: 'filters.name' },
            actionColumnProps({
              id: 'action2',
              Cell: TableCells.EditCell,
            }),
            actionColumnProps({
              id: 'action3',
              Cell: TableCells.DeleteCell,
            }),
          ]}
          data={notificationsData.data}
          onActions={{
            onAddRow: { handler: onCreateNotification },
            onEditRow: { handler: onEditNotification },
            onDeleteRow: { handler: onDeleteNotification },
          }}
          pageIndex={notificationsData.pageIndex}
          pageSize={notificationsData.pageSize}
          pageSizeOptions={notificationsData.pageSizeOptions}
          paginationType="offsetBased"
          rowProps={(row) => ({ 'data-notification-id': row.original.uuid })}
          setPageIndex={setPageIndex}
          setPageSize={setPageSize}
        />
      </Panel>
    </Page>
  );
};

export const NotificationListPage = connect(
  (state) => ({
    notificationsData: selectNotifications(state),
  }),
  (dispatch) => ({
    setNotifications: () => dispatch(fetchNotifications),
    deletion: (id) => dispatch(deleteNotification(id)),
    setPageSize: (pageSize) => dispatch(updatePageSize({ pageSize })),
    setPageIndex: (pageIndex) => dispatch(updatePage({ pageIndex })),
  }),
)(NotificationListPageComponent);
