import { loadProgressBar } from '@vlabs/axios-progress-bar';
import Axios from 'axios';
import formatISO from 'date-fns/formatISO';
import qs from 'qs';

import '@vlabs/axios-progress-bar/dist/nprogress.css';
import { DEFAULT_HEALTHCHECK_TIMEOUT } from './config';

export class ClementineDepartmentsClient {
  constructor() {
    this.baseURL = '/api/hermes';
    this.http = Axios.create({
      baseURL: this.baseURL,
      withCredentials: true,
      paramsSerializer: (params) => (
        qs.stringify(params, {
          arrayFormat: 'comma',
          serializeDate: formatISO,
        })),
    });
    loadProgressBar('', this.http);
  }

  healthcheck() {
    return this.http.head('/version', {
      timeout: DEFAULT_HEALTHCHECK_TIMEOUT,
    }).catch((e) => {
      if (e?.code === 'ECONNABORTED') throw e;
      if (e?.response?.status >= 500) throw e;
    });
  }

  async version() {
    const { data: { version } } = await this.http.get('/version');
    return version;
  }

  get departments() {
    const prefix = 'departments';
    return {
      create: (payload) => this.http.post(`/${prefix}`, payload),
      getAll: async () => {
        const pageSize = 50;
        const results = [];
        let response;
        do {
          // eslint-disable-next-line no-await-in-loop
          response = await this.http.get(`/${prefix}`, { params: { pageSize } });
          if (!response.data?.data) break;
          results.push(...response.data.data);
        } while (response.data?.length || false);
        return {
          data: results,
          meta: {
            count: results.length,
          },
        };
      },
      getPage: async (params) => {
        const { data } = await this.http.get(`/${prefix}`, { params });
        return data;
      },
      get: (departmentId) => this.http.get(`/${prefix}/${departmentId}`),
      update: (departmentId, payload) => this.http.patch(`/${prefix}/${departmentId}`, payload),
      delete: (departmentId) => this.http.delete(`/${prefix}/${departmentId}`),
      addCamera: (departmentId, payload) => this.http.post(`/${prefix}/${departmentId}/cameras`, payload),
      removeCamera: (departmentId, cameraId) => this.http.delete(`/${prefix}/${departmentId}/cameras/${cameraId}`),
      addAccount: (departmentId, payload) => this.http.post(`/${prefix}/${departmentId}/accounts`, payload),
      removeAccount: (departmentId, accountId) => this.http.delete(`/${prefix}/${departmentId}/accounts/${accountId}`),
    };
  }
}
