import React from 'react';

import { EVENT_ENUMS } from '@vlabs/api-bindings/src/luna-client/constants';
import { lowerBodyImages } from '@vlabs/shared/assets/images';
import cn from 'classnames';
import { useTranslation, Trans } from 'react-i18next';

import { Card } from './Card';
import st from './Card.module.sass';

const LOWER_BODY_IMAGES = {
  trousers: lowerBodyImages.Trousers,
  shorts: lowerBodyImages.Shorts,
  skirt: lowerBodyImages.Skirt,
};

export const LowerBodyCard = ({ values }) => {
  const { t } = useTranslation();

  const img = LOWER_BODY_IMAGES[values?.lower_garment?.type] || lowerBodyImages.Unknown;
  const lowerGarmentType = EVENT_ENUMS.LOWER_BODY.LOWER_GARMENT.TYPE?.[values?.lower_garment?.type];
  const colorsLabel = (values.lower_garment.colors || []).map(
    (color) => EVENT_ENUMS.LOWER_BODY.LOWER_GARMENT.COLORS[color]?.label,
  ).join(', ');

  return (
    <Card
      back={(
        <div className={st.Tooltip}>
          <Trans i18nKey="events:карточка.низ" values={{ type: lowerGarmentType?.label, color: colorsLabel }} />
        </div>
      )}
      front={(
        <div className={st.Root}>
          <div
            className={st.ImageBlock}
            data-testid="eventAttributesCell.lowerGarmentColors"
          >
            {t('events:lower_clothing_colors')}
            <img alt={`${lowerGarmentType.values} lower garment`} className={st.Image} src={img} />
            {lowerGarmentType?.label}
          </div>
          {(values.lower_garment.colors || []).slice(0, 2).map(
            (color) => (
              <div className={st.ColorBlock}>
                <div className={cn(st.Color, st[`Color_${color}`])} />
                <div className={cn(st.ColorLabel)}>
                  {EVENT_ENUMS.LOWER_BODY.LOWER_GARMENT.COLORS[color]?.label}
                </div>
              </div>
            ))}
        </div>
      )}
    />
  );
};
