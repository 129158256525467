import React, { memo } from 'react';

import { IconInner } from '../../IconInner';

const DownloadIconInner = (props) => (
  <IconInner name="DownloadIcon" viewBox="0 0 24 24" {...props}>
    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4m4-5 5 5 5-5m-5 5V3" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
  </IconInner>
);

export const DownloadIcon = memo(DownloadIconInner);
