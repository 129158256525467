import { createSlice } from '@reduxjs/toolkit';
import { apiContainer } from '@vlabs/api-bindings';
import { selectAppState } from '@vlabs/shared/selectors/appSelectors';
import i18next from 'i18next';
import { toast } from 'react-toastify';

export const defaultNotification = {
  filters: { name: '' },
  ui: { enable: false },
  telegram: {
    enable: false,
    targets: '',
    recipients: '' },
  email: {
    enable: false,
    targets: '',
    recipients: '' },
};

const store = createSlice({
  name: 'notifications',
  initialState: {
    state: undefined,
    data: [],
    pageIndex: 0,
    pageSize: 25,
    pageSizeOptions: [10, 25, 50, 100],
  },
  reducers: {
    setNotifications(state, { payload: { data } }) {
      state.data = data;
      state.state = 'loaded';
    },
    setPage(state, { payload: { pageIndex } = {} }) {
      if (pageIndex !== undefined) state.pageIndex = pageIndex;
    },
    setPageSize(state, { payload: { pageSize } = {} }) {
      if (pageSize !== undefined) state.pageSize = pageSize;
    },
  },
});

export default store.reducer;

export const { setNotifications, setFilters, setPageSize, setPage } = store.actions;

export const fetchNotifications = async (dispatch, getState) => {
  if (selectAppState(getState()) !== 'loaded') return;
  const {
    notifications: { pageIndex, pageSize },
  } = getState();

  try {
    const { data } = await apiContainer.notifierClient.filters.getAll({ page: pageIndex + 1, pageSize });
    dispatch(setNotifications({ data }));
  } catch (error) {
    dispatch(setNotifications([]));
    throw error;
  }
};
export const getNotification = async (id) => apiContainer.notifierClient.filters.get(id);

export const updateNotification = (
  notificationId, newData, onSuccess = undefined,
) => async (dispatch) => {
  if (notificationId === 'create') {
    await apiContainer.notifierClient.filters.create(newData);
    if (onSuccess) onSuccess();
    toast.success(`${i18next.t('vlNotifier:создание.успех')}.`);
  } else {
    await apiContainer.notifierClient.filters.replace(notificationId, newData);
    toast.success(`${i18next.t('vlNotifier:обновление.успех')}.`);
  }
  dispatch(fetchNotifications);
};

export const deleteNotification = (id) => async (dispatch) => {
  await apiContainer.notifierClient.filters.delete(id);
  toast.success(`${i18next.t('vlNotifier:удаление.успех')}.`);
  dispatch(fetchNotifications);
};

export const updatePageSize = (params) => async (dispatch) => {
  dispatch(setPageSize(params));
  dispatch(fetchNotifications);
};

export const updatePage = (params) => async (dispatch) => {
  dispatch(setPage(params));
  dispatch(fetchNotifications);
};
