import React, { memo } from 'react';

import { IconInner } from '../../IconInner';

const ChevronDoubleDownIconInner = (props) => (
  <IconInner name="ChevronDoubleDownIcon" viewBox="0 0 24 24" {...props}>
    <path d="m7 13 5 5 5-5M7 6l5 5 5-5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
  </IconInner>
);

export const ChevronDoubleDownIcon = memo(ChevronDoubleDownIconInner);
