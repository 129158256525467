import React from 'react';

import validate from '@vlabs/shared/validators';
import { Control, SettingsItemWrapper } from '@vlabs/uikit';
import i18n from 'app/translations/i18n';
import { useFormContext, useFormState } from 'react-hook-form';

const CommonSubForm = () => {
  const { register } = useFormContext();
  const { errors } = useFormState();

  return (
    <SettingsItemWrapper title={i18n.t('handlers:policy name')}>
      <Control.Input
        autoFocus
        errors={errors}
        {...register('description', {
          maxLength: validate.maxLength(128),
          required: validate.required(),
        })}
      />
    </SettingsItemWrapper>
  );
};

export default CommonSubForm;
