import React, { useEffect } from 'react';

import { META_TYPE, META_NUM_OPERATOR, META_STR_OPERATOR } from '@vlabs/api-bindings/src/constants';
import { DeleteIcon } from '@vlabs/icons';
import validate from '@vlabs/shared/validators';
import { Control, Divider, SettingsItemWrapper } from '@vlabs/uikit';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import st from '../FilterForms.module.sass';

export function MetaFilter({ i, remove, replace }) {
  const { t } = useTranslation();
  const { register, formState: { errors }, control, watch, getValues } = useFormContext();
  const type = useWatch({ name: `meta.${i}.type` })?.value;

  useEffect(() => {
    const sub = watch((data, { name }) => {
      if (name === `meta.${i}.type`) {
        const v = getValues();
        v.meta[i].operator = undefined;
        replace(v.meta); // заменяем массив
      }
    });

    return () => sub.unsubscribe();
  }, [watch, getValues, i, replace]);

  return (
    <SettingsItemWrapper
      className={st.wrapper}
      contentClassName={st.width}
    >
      <div className={st.meta_flex}>
        <div className={st.width}>
          <div className={st.flex}>
            <div className={st.width}>
              <Control.Input
                {...register(`meta.${i}.key`, {
                  required: validate.required(),
                  validate: validate.metaKey(),
                })}
                errors={errors}
                label={t('events:key')}
                placeholder={t('введите')}
              />
            </div>
            <Divider small />
            <div className={st.width}>
              <Control.Input
                {...register(`meta.${i}.value`, {
                  required: validate.required(),
                  validate: validate.metaValue(type),
                })}
                errors={errors}
                label={t('events:value')}
                placeholder={t('введите')}
              />
            </div>
          </div>
          <div className={st.divider} />
          <div className={st.flex}>
            <div className={st.width}>
              <Control.Select
                control={control}
                errors={errors}
                isClearable
                label={t('events:type')}
                name={`meta.${i}.type`}
                options={META_TYPE.raw}
                rules={{ required: validate.required() }}
              />
            </div>
            <Divider small />
            <div className={st.width}>
              <Control.Select
                control={control}
                disabled={!watch(`meta.${i}.type`)}
                errors={errors}
                isClearable
                label={t('events:operator')}
                name={`meta.${i}.operator`}
                options={(watch(`meta.${i}.type`)?.value === 'string')
                  ? META_STR_OPERATOR.raw : META_NUM_OPERATOR.raw}
                rules={{ required: validate.required() }}
              />
            </div>
          </div>
        </div>
        <Control.RoundButton
          data-testid="meta.deleteButton"
          icon={<DeleteIcon />}
          kind="negative"
          onClick={() => remove(i)}
          title={t('кнопка.удалить')}
          variant="dimmed"
        />
      </div>
    </SettingsItemWrapper>
  );
}
