import React, { memo } from 'react';

import { IconInner } from '../../IconInner';

const MoreHorizontalIconInner = (props) => (
  <IconInner name="MoreHorizontalIcon" viewBox="0 0 24 24" {...props}>
    <path d="M12 13a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm7 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM5 13a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
  </IconInner>
);

export const MoreHorizontalIcon = memo(MoreHorizontalIconInner);
