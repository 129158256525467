import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Button } from '../../controls/button/Button';

const LogoutForm = ({
  onSubmit,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <Button
        fullWidth
        onClick={onSubmit}
      >
        {t('uikit:формы.действия.выход')}
      </Button>
    </div>
  );
};

LogoutForm.propTypes = {
  onSubmit: PropTypes.func,
};
LogoutForm.defaultProps = {
  onSubmit: () => {},
};

export { LogoutForm };
