import React from 'react';

import PropTypes from 'prop-types';

import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE_OPTIONS } from '../../../constants';
import { PaginationPageSizeSelect } from '../PaginationPageSizeSelect';

import '../Pagination.sass';

const SizeOnlyPagination = ({
  pageSize,
  setPageSize,
  pageSizeOptions,
}) => {
  return (
    <div className="Pagination" data-testid="paginationRoot">
      <PaginationPageSizeSelect
        onChange={(e) => setPageSize(Number(e.value))}
        options={pageSizeOptions}
        value={pageSize}
      />
    </div>
  );
};

SizeOnlyPagination.propTypes = {
  pageSize: PropTypes.number,
  pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
  setPageSize: PropTypes.func,
};

SizeOnlyPagination.defaultProps = {
  pageSize: DEFAULT_PAGE_SIZE,
  pageSizeOptions: DEFAULT_PAGE_SIZE_OPTIONS,
  setPageSize: () => {},
};

export { SizeOnlyPagination };
