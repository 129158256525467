import { apiContainer } from '@vlabs/api-bindings';

export async function estimateLiveness(file) {
  if (!file) return undefined;
  const data = await apiContainer.lunaClient.sdk.estimate(file, {
    image_type: 0,
    detect_face: 1,
    estimate_liveness: 1,
    multiface_policy: 0,
  });
  if (data.data?.images_estimations) return data.data?.images_estimations[0];
  return data;
}
