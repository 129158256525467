import React from 'react';

import { ChevronDoubleDownIcon, ChevronDoubleUpIcon, ChevronDownIcon, ChevronUpIcon } from '@vlabs/icons';
import { Control } from '@vlabs/uikit';
import PropTypes from 'prop-types';

import st from './OrderSelector.module.sass';

const moveElement = (arr, from, to) => {
  const element = arr.splice(from, 1)[0];
  arr.splice(to, 0, element);
  return arr;
};

export const OrderSelector = ({
  list,
  setList,
  keyMap,
}) => {
  return (
    <div className={st.Wrapper}>
      {list.map((column, idx) => {
        return (
          <div className={st.Item} key={column}>
            {keyMap[column] || column}
            <div className={st.Controls}>
              {idx === 0 && (
                <>
                  <div className={st.Empty} />
                  <div className={st.Empty} />
                </>
              )}
              {idx > 0 && (
                <>
                  <Control.Button
                    className={st.Up}
                    onClick={() => { setList([...moveElement(list, idx, 0)]); }}
                    variant="dimmed"
                  >
                    <ChevronDoubleUpIcon />
                  </Control.Button>
                  <Control.Button
                    className={st.Up}
                    onClick={() => { setList([...moveElement(list, idx, idx - 1)]); }}
                    variant="dimmed"
                  >
                    <ChevronUpIcon />
                  </Control.Button>
                </>
              )}
              {idx === list.length - 1 && (
                <>
                  <div className={st.Empty} />
                  <div className={st.Empty} />
                </>
              )}
              {idx < list.length - 1 && (
                <>
                  <Control.Button
                    className={st.Down}
                    onClick={() => { setList([...moveElement(list, idx, idx + 1)]); }}
                    variant="dimmed"
                  >
                    <ChevronDownIcon />
                  </Control.Button>
                  <Control.Button
                    className={st.Down}
                    onClick={() => { setList([...moveElement(list, idx, list.length - 1)]); }}
                    variant="dimmed"
                  >
                    <ChevronDoubleDownIcon />
                  </Control.Button>
                </>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

OrderSelector.propTypes = {
  list: PropTypes.arrayOf(PropTypes.string),
  setList: PropTypes.func.isRequired,
  keyMap: PropTypes.shape({}),
};

OrderSelector.defaultProps = {
  list: [],
  keyMap: {},
};
