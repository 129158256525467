export const mapFilters = (input) => {
  const fields = { ...input };
  const numbArray = [
    'top_similar_object_similarity__gte',
    'top_similar_object_similarity__lt',
    'age__gte',
    'age__lt',
  ];

  const result = {};
  Object.entries(fields).forEach(([key, original]) => {
    if (!original || (Array.isArray(original) && original.length === 0)) return;

    if (key === 'age') {
      result.age__gte = original?.value[0];
      result.age__lt = original?.value[1];
    }

    if (Array.isArray(original)) {
      result[key] = original
        .map((fieldValue) => {
          // Check if fieldValue is value of MultiDropdown
          if (typeof fieldValue === 'object'
                && fieldValue !== null
                && fieldValue.value !== undefined) {
            if (key === 'sources') return fieldValue.label;
            return fieldValue.value;
          }
          return fieldValue;
        });
    } else if (typeof original === 'object' && Object.keys(original).length) {
      result[key] = original.value;
    } else if (numbArray.includes(key)) {
      result[key] = Number(original);
    } else if (typeof original !== 'object') result[key] = original;
  });
  delete result.age;
  return result;
};

export const toApi = ({ filters, telegram, email, ui }) => ({
  filters: mapFilters(filters),
  ui: {
    enable: ui?.enable,
  },
  telegram: {
    enable: telegram?.enable,
    recipients: telegram?.recipients?.split(/[ ,]+/).map((id) => Number(id)) || [],
    targets: telegram?.targets
      ? Object.entries(telegram.targets)
        .filter(([, value]) => value)
        .map(([key]) => key)
      : [],
  },
  email: {
    enable: email?.enable,
    recipients: email?.recipients?.split(/[ ,]+/) || [],
    targets: email?.targets
      ? Object.entries(email.targets)
        .filter(([, value]) => value)
        .map(([key]) => key)
      : [],
  },
});
