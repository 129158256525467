import { createSelector } from '@reduxjs/toolkit';

import { SLICE_NAME } from '.';

export const selectSlice = ({ [SLICE_NAME]: slice }) => slice;
export const selectCurrentSource = createSelector(
  selectSlice,
  (slice) => slice.current,
);
export const selectLunaSourcesOptions = createSelector(
  selectSlice,
  (slice) => slice.lunaSourcesOptions,
);
