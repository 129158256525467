import React from 'react';

import { optionPropType } from '@vlabs/shared';
import { START_OF_DAY, END_OF_DAY } from '@vlabs/shared/config';
import { selectListOptions } from '@vlabs/shared/selectors/listSelectors';
import validate from '@vlabs/shared/validators';
import { Grid, GridCol, GridRow, Divider, Control, Step } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

function StepFacesFilters({
  listOptions,
}) {
  const { t } = useTranslation();
  const { control, register, formState: { errors } } = useFormContext();

  return (
    <Step noBorder>
      <h6>{t('tasks:exportForm.заголовки.фильтры')}</h6>
      <Divider small />

      <Grid>
        <GridRow>
          <GridCol cols={6}>
            <Control.Select
              control={control}
              errors={errors}
              id="content.filters.list_id"
              isClearable
              isCreatable
              label={t('list')}
              name="content.filters.list_id"
              options={listOptions}
              rules={{ validate: validate.uuidOptions }}
            />
            <Divider small />

            <Control.Input
              id="content.filters.user_data"
              label={t('пользовательские данные')}
              {...register('content.filters.user_data', { maxLength: validate.maxLength(128) })}
              errors={errors}
              placeholder={t('введите')}
            />
            <Divider small />

            <Control.DateInput
              control={control}
              enableTime
              id="content.filters.create_time__gte"
              label={t('дата создания от')}
              name="content.filters.create_time__gte"
              options={START_OF_DAY}
              placeholder={t('выберите даты')}
            />
            <Divider small />

            <Control.Input
              id="content.filters.face_id__gte"
              label={t('идентификатор первого лица')}
              {...register('content.filters.face_id__gte', { validate: validate.uuid() })}
              errors={errors}
              placeholder={t('введите id')}
            />
          </GridCol>

          <GridCol cols={6}>
            <Control.Input
              errors={errors}
              id="content.filters.face_ids"
              label={t('идентификаторы лиц')}
              placeholder={t('введите через запятую')}
              {...register('content.filters.face_ids', { validate: validate.multipleUuid() })}
            />
            <Divider small />

            <Control.Input
              id="content.filters.external_ids"
              label={t('внешние идентификаторы лиц')}
              {...register('content.filters.external_ids', {
                validate: validate.multipleExternalId() })}
              errors={errors}
              placeholder={t('введите через запятую')}
            />
            <Divider small />

            <Control.DateInput
              control={control}
              enableTime
              id="content.filters.create_time__lt"
              label={t('дата создания до')}
              name="content.filters.create_time__lt"
              options={END_OF_DAY}
              placeholder={t('выберите даты')}
            />
            <Divider small />

            <Control.Input
              id="content.filters.face_id__lt"
              label={t('идентификатор последнего лица')}
              {...register('content.filters.face_id__lt', { validate: validate.uuid() })}
              errors={errors}
              placeholder={t('введите id')}
            />
          </GridCol>
        </GridRow>
      </Grid>
      <Divider small />
    </Step>
  );
}

StepFacesFilters.fieldNames = [
  'content.filters',
  'meta',
];

StepFacesFilters.propTypes = {
  listOptions: PropTypes.arrayOf(optionPropType),
};

StepFacesFilters.defaultProps = {
  listOptions: [],
};

export default connect((state) => ({
  listOptions: selectListOptions(state),
}))(StepFacesFilters);
