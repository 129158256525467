import React from 'react';

import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDoubleLeftIcon,
  CircleEmptyIcon,
} from '@vlabs/icons';
import PropTypes from 'prop-types';

import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE_OPTIONS } from '../../../constants';
import { PaginationButton } from '../pagination-button/PaginationButton';
import { PaginationPageSizeSelect } from '../PaginationPageSizeSelect';

import '../Pagination.sass';

const IndeterminatePagination = ({
  pageSize,
  setPageSize,
  pageSizeOptions,
  canFirstPage,
  canPreviousPage,
  canNextPage,
  firstPage,
  previousPage,
  nextPage,
  noPageSizeSelect,
}) => {
  return (
    <div className="Pagination" data-testid="paginationRoot">
      <PaginationButton
        data-testid="firstPageButton"
        disabled={!canFirstPage}
        icon={<ChevronDoubleLeftIcon />}
        onClick={() => firstPage()}
      />

      <PaginationButton
        data-testid="prevPageButton"
        disabled={!canPreviousPage}
        icon={<ChevronLeftIcon />}
        onClick={() => previousPage()}
      />

      <CircleEmptyIcon className="Pagination__Icon" />

      <PaginationButton
        data-testid="nextPageButton"
        disabled={!canNextPage}
        icon={<ChevronRightIcon />}
        onClick={() => nextPage()}
      />

      {!noPageSizeSelect && (
        <PaginationPageSizeSelect
          onChange={(e) => setPageSize(Number(e.value))}
          options={pageSizeOptions}
          value={pageSize}
        />
      )}
    </div>
  );
};

IndeterminatePagination.propTypes = {
  canFirstPage: PropTypes.bool,
  firstPage: PropTypes.func,
  canPreviousPage: PropTypes.bool,
  previousPage: PropTypes.func,
  canNextPage: PropTypes.bool,
  nextPage: PropTypes.func,
  pageSize: PropTypes.number,
  pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
  setPageSize: PropTypes.func,
  noPageSizeSelect: PropTypes.bool,
};

IndeterminatePagination.defaultProps = {
  canFirstPage: false,
  firstPage: undefined,
  canPreviousPage: false,
  previousPage: undefined,
  canNextPage: false,
  nextPage: undefined,
  setPageSize: () => {},
  pageSize: DEFAULT_PAGE_SIZE,
  pageSizeOptions: DEFAULT_PAGE_SIZE_OPTIONS,
  noPageSizeSelect: false,
};

export { IndeterminatePagination };
