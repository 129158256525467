import React from 'react';

import { PersonIcon, CalendarIcon, ListIcon, FolderIcon } from '@vlabs/icons';
import InfoRow from '@vlabs/shared/legacy-components/info-row/InfoRow';
import ListLink from '@vlabs/shared/legacy-components/list-link/ListLink';
import { routes } from '@vlabs/shared/navigation/routes';
import { parseISOToLocale } from '@vlabs/shared/utils';
import { Control } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';

import st from './FaceInfo.module.sass';

export function FaceInfo({ face }) {
  const { t } = useTranslation();

  let listsTitle = t('списки');
  if (face?.lists?.length !== undefined) listsTitle += `(${face.lists.length})`;

  return (
    <div className={st.Wrapper}>
      <InfoRow
        data-testid="face.createTime"
        icon={<CalendarIcon className="StaticIcon" />}
        title={t('date created')}
        value={parseISOToLocale(face?.create_time)}
      />
      <InfoRow
        data-testid="face.userData"
        icon={<FolderIcon className="StaticIcon" />}
        title={t('information')}
        value={face?.user_data}
      />
      {face?.external_id && (
        <InfoRow
          data-testid="face.externalId"
          icon={<PersonIcon className="StaticIcon" />}
          title={t('external id')}
          value={(
            <Control.Link
              title={t('перейти')}
              to={generatePath(routes.externalId.read, { externalId: face.external_id })}
            >
              {face.external_id}
            </Control.Link>
          )}
        />
      )}
      {face?.lists?.length !== 0 && (
        <InfoRow
          data-testid="face.lists"
          icon={<ListIcon className="StaticIcon" />}
          title={listsTitle}
          value={<ListLink lists={face.lists} />}
        />
      )}
      <InfoRow
        data-testid="face.faceId"
        icon={<FolderIcon className="StaticIcon" />}
        title={t('additional information')}
        toCopy={face?.face_id}
        value={t('id лица')}
      />
    </div>
  );
}

FaceInfo.propTypes = {
  face: PropTypes.objectOf(PropTypes.any),
};

FaceInfo.defaultProps = {
  face: {},
};
