import { createSlice } from '@reduxjs/toolkit';
import { apiContainer } from '@vlabs/api-bindings';

import { SLICE_NAME } from '.';

const initialState = {
  pageData: {
    data: [],
  },
  pageSize: 25,
  pageSizeOptions: [10, 25, 50],
  state: 'notAsked',
};

const store = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setPageData(state, { payload }) {
      state.pageData = payload;
      state.state = 'loaded';
    },
    setPageSize(state, { payload }) {
      state.pageSize = payload;
    },
  },
});

export default store.reducer;

export const fetchPageData = async (dispatch, getState) => {
  const { [SLICE_NAME]: { pageSize } } = getState();
  try {
    const { streams: data } = await apiContainer.lunaStreamsClient.queue.processing({
      limit: pageSize,
    });
    dispatch(store.actions.setPageData({ data }));
  } catch (error) {
    dispatch(store.actions.setPageData([]));
    throw error;
  }
};

export const setPageSize = (pageSize) => (dispatch) => {
  dispatch(store.actions.setPageSize(pageSize));
  dispatch(fetchPageData);
};
