import { useEffect } from 'react';

import { useFormContext, useWatch } from 'react-hook-form';

export const useHandlerMatchLabels = () => {
  return useWatch({
    name: 'matchPolicy',
  })?.map(({ label }) => ({
    value: label,
    label,
  }));
};

export const useUpdateField = ({
  path,
  value,
  on = undefined,
  updateIf = undefined,
}) => {
  const { setValue, getValues } = useFormContext();

  useEffect(() => {
    if (on !== undefined && !on()) return;

    const update = (fullPath) => {
      const currentValue = getValues(fullPath);
      let newValue = value;
      if (value instanceof Function) newValue = value(currentValue);
      if (updateIf !== undefined && !updateIf(currentValue)) return;
      if (updateIf === undefined && currentValue === newValue) return;
      setValue(fullPath, newValue);
    };

    if (path.includes('.@.')) {
      const [prefix, nestedPath] = path.split('.@.');
      const listItems = getValues(prefix);
      listItems.forEach((_, idx) => update(`${prefix}.${idx}.${nestedPath}`));
    } else {
      update(path);
    }
  }, [on, path, value, setValue, getValues, updateIf]);
};

export const useClearPolicyFilters = (policyName, { on }) => {
  useUpdateField({ path: `${policyName}.filters.age`, value: null, on });
  useUpdateField({ path: `${policyName}.filters.gender`, value: null, on });
  useUpdateField({ path: `${policyName}.filters.liveness`,
    value: [],
    on,
    updateIf: (value) => Array.isArray(value) && value.length > 0 });
  useUpdateField({ path: `${policyName}.filters.match`,
    value: [],
    on,
    updateIf: (value) => Array.isArray(value) && value.length > 0 });
  useUpdateField({ path: `${policyName}.ttl`, value: null, on });
};

export const useUpdateAllPoliciesField = ({
  on,
  path,
  value,
  updateIf = undefined,
}) => {
  const { setValue, getValues } = useFormContext();

  useEffect(() => {
    if (!on()) return;

    const clear = (prefix) => {
      const fullPath = `${prefix}.${path}`;
      const currentValue = getValues(fullPath);
      if (updateIf !== undefined && !updateIf(currentValue)) return;
      if (updateIf === undefined && currentValue === value) return;
      setValue(fullPath, value);
    };

    ['attributesPolicy',
      'facePolicy',
      'eventPolicy',
      'notificationPolicy',
      'faceSamplePolicy',
      'bodySamplePolicy',
      'imageOriginPolicy',
    ].forEach(clear);

    ['faceToListPolicy.lists',
      'tagPolicy',
      'matchPolicy',
      'callbacksPolicy',
    ].forEach((prefix) => {
      const listItems = getValues(prefix);
      if (updateIf !== undefined && !updateIf(listItems)) return;
      listItems.forEach((_, idx) => clear(`${prefix}.${idx}`));
    });
  }, [on, setValue, getValues, path, value, updateIf]);
};
