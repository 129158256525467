import React from 'react';

import { selectAppPlugins } from '@vlabs/shared/selectors/appSelectors';
import { Page, Table, Panel, Divider } from '@vlabs/uikit';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import st from './PluginsPage.module.sass';

function StateCell({ value }) {
  const { t } = useTranslation();
  const stateList = [t('plugins:state.not running'), t('plugins:state.running')];

  return (
    <>
      <div className={cn(st.State, st[value === 0 ? 'State_notRunning' : 'State_running'])} />
      <span>{stateList[value]}</span>
    </>
  );
}

StateCell.propTypes = {
  value: PropTypes.number,
};

StateCell.defaultProps = {
  value: undefined,
};

function PluginsPageComponents({
  plugins,
}) {
  const { t } = useTranslation();
  return (
    <Page title={t('plugins:title')}>
      <Divider />
      <Panel>
        <Table
          columns={[
            { Header: t('plugins:table.columns.name'), accessor: 'name' },
            { Header: t('plugins:table.columns.state'), accessor: 'running', Cell: StateCell },
          ]}
          data={plugins}
        />
      </Panel>
    </Page>
  );
}

PluginsPageComponents.propTypes = {
  plugins: PropTypes.shape({
    name: PropTypes.string,
    running: PropTypes.number,
  }),
};

PluginsPageComponents.defaultProps = {
  plugins: [],
};

export const PluginsPage = connect((state) => ({
  plugins: selectAppPlugins(state),
}))(PluginsPageComponents);
