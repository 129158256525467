import { formatISO } from 'date-fns';

import { CleanSchema, get } from '../../json-transform';
import { Token } from '../constants';

export const permissionsSchema = new CleanSchema({
  account: get('account').asArrayOfOptionValues(Token.Permissions.view),
  face: get('face').asArrayOfOptionValues(Token.Permissions.crudm),
  list: get('list').asArrayOfOptionValues(Token.Permissions.crud),
  event: get('event').asArrayOfOptionValues(Token.Permissions.crm),
  attribute: get('attribute').asArrayOfOptionValues(Token.Permissions.crudm),
  handler: get('handler').asArrayOfOptionValues(Token.Permissions.crud),
  verifier: get('verifier').asArrayOfOptionValues(Token.Permissions.crud),
  task: get('task').asArrayOfOptionValues(Token.Permissions.crud),
  face_sample: get('face_sample').asArrayOfOptionValues(Token.Permissions.crd),
  body_sample: get('body_sample').asArrayOfOptionValues(Token.Permissions.crd),
  image: get('image').asArrayOfOptionValues(Token.Permissions.crd),
  object: get('object').asArrayOfOptionValues(Token.Permissions.crd),
  token: get('token').asArrayOfOptionValues(Token.Permissions.crud),
  resources: get('resources').asArrayOfOptionValues(Token.Permissions.resources),
  emit_events: {
    allowed: get('emit_events.allowed').asInt().ifNaN(undefined),
    white_list: get('emit_events.white_list').asArrayOfOptionValues().ifEmpty(undefined),
    black_list: get('emit_events.black_list').asArrayOfOptionValues().ifEmpty(undefined),
  },
  lambdas: get('lambdas').asArrayOfOptionValues(Token.Permissions.crud),
  streams: get('streams').asArrayFromString(Token.Permissions.crud),
  source: get('source').asArrayFromString(Token.Permissions.crud),
});

export const postToken = new CleanSchema({
  description: get('description').asString().asISOString().ifEmptyString(undefined),
  expiration_time: get('expiration_time').switch(
    (v) => (v === null ? 'null' : typeof v),
    {
      null: () => null,
      object: (v) => formatISO(v),
      string: (v) => v,
    },
  ),
  permissions: get('permissions').asSchema(permissionsSchema),
  visibility_area: get('visibility_area').asOptionValue(),
});
