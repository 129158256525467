import React from 'react';

import { CircleCheckIcon, CircleCrossIcon } from '@vlabs/icons';
import i18n from 'i18next';

import './ProgressCell.sass';

const STATUS_OPTIONS = {
  failed: i18n.t('verifiers:verifierTest.table.status.failed'),
  success: i18n.t('verifiers:verifierTest.table.status.success'),
};

// FIXME сделать один общий компонент прогресса. это временный вариант

const ProgressCell = (status) => {
  if (!status) return null;
  const iconsMap = {
    failed: <CircleCrossIcon className="ProgressCell_canceled" />,
    success: <CircleCheckIcon className="ProgressCell_finished" />,
  };

  return (
    <div className="ProgressCell">
      <div title={STATUS_OPTIONS[status]}>
        {iconsMap[status]}
      </div>
    </div>
  );
};

export default ProgressCell;
