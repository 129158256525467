import React from 'react';

import { Control } from '@vlabs/uikit';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import st from './FilterSoundNotification.module.sass';

export const FilterSoundNotification = () => {
  const { t } = useTranslation();

  const { setIsSoundOn, sound, setSoundThreshold } = useFormContext();

  const { isSoundOn, soundThreshold } = sound;

  return (
    <div className={st.FilterForm__Body}>
      <Control.Switch
        id="filters-sound-notification"
        label={t('events:filter form.sound notification')}
        onChange={setIsSoundOn}
        value={isSoundOn}
      />

      <div className={st.SoundNotification__Similarity}>
        <span className={st.SoundNotification__Label}>
          {`${t('events:filter form.similarity threshold')}:`}
        </span>

        <Control.Input
          className={st.SoundNotification__Input}
          disabled={!isSoundOn}
          inputStyle="implicit"
          max={100}
          min={0}
          onChange={(event) => setSoundThreshold(event?.target?.value)}
          placeholder={t('from')}
          step="0.01"
          type="number"
          value={soundThreshold}
        />
        <span>
          %
        </span>
      </div>
    </div>
  );
};
