import React from 'react';

import validate from '@vlabs/shared/validators';
import {
  Page, Control, Divider,
} from '@vlabs/uikit';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import st from './Departments.module.sass';

function DepartmentForm({
  defaultValues,
  onSubmit = () => {},
}) {
  const { t } = useTranslation();
  const formMethods = useForm({ defaultValues });

  const {
    register,
    handleSubmit,
    formState: {
      errors,
    } } = formMethods;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Page
        className={st.FormWidth}
        title={t('departments:form.title', { context: defaultValues?.departmentId ? 'update' : 'create' })}
      >
        <Divider />

        <Control.Input
          id="name"
          label={t('departments:form.name')}
          {...register('name', {
            required: validate.required(),
            maxLength: validate.maxLength(128),
          })}
          errors={errors}
        />

        <Divider small />

        <Control.Button fullWidth type="submit">
          {t('форма.submit')}
        </Control.Button>

      </Page>
    </form>
  );
}

export default DepartmentForm;
