import React, { memo } from 'react';

import { IconInner } from '../../IconInner';

const ArrowStraightUpDownIconInner = (props) => (
  <IconInner name="ArrowStraightUpDownIcon" viewBox="0 0 16 16" {...props}>
    <path d="M2.6665 5.33301L5.33317 2.66634M5.33317 2.66634L7.99984 5.33301M5.33317 2.66634L5.33317 13.333" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" />
    <path d="M7.99976 10.6665L10.6664 13.3332M10.6664 13.3332L13.3331 10.6665M10.6664 13.3332L10.6664 2.6665" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" />
  </IconInner>
);

export const ArrowStraightUpDownIcon = memo(ArrowStraightUpDownIconInner);
