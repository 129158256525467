import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import './GridCol.sass';

const GridCol = ({
  children,
  cols,
  className,
  contentClassName,
}) => {
  return (
    <div
      className={cn(
        className,
        {
          GridCol: true,
          [`GridCol--cols-${cols}`]: !!cols,
          GridCol__Auto: !cols,
        },
      )}
    >
      <div className={cn(
        contentClassName,
        {
          GridCol__Content: true,
        },
      )}
      >
        {children}
      </div>
    </div>
  );
};

GridCol.propTypes = {
  children: PropTypes.node,
  cols: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  className: PropTypes.string,
  contentClassName: PropTypes.string,
};

GridCol.defaultProps = {
  children: undefined,
  cols: null,
  className: undefined,
  contentClassName: undefined,
};

export { GridCol };
