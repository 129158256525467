import React, { useCallback } from 'react';

import { selectIsAppFeatureAvailable } from '@vlabs/shared/selectors/appSelectors';
import { Fold } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { useClearPolicyFilters } from '../hooks';
import GenericPolicySubForm from './GenericPolicySubForm';
import { TTLSelectField } from './TTLSelectField/TTLSelectField';

const BodySamplePolicySubForm = ({
  isBodyLicenseAvailable,
}) => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const bodySamplePolicyIsEnabled = useWatch({ name: 'bodySamplePolicy.isEnabled' });

  useClearPolicyFilters('bodySamplePolicy', {
    on: useCallback(() => bodySamplePolicyIsEnabled === false, [bodySamplePolicyIsEnabled]),
  });

  return (
    <Fold
      control={control}
      disabled={!isBodyLicenseAvailable}
      isOpen={isBodyLicenseAvailable && bodySamplePolicyIsEnabled}
      name="bodySamplePolicy.isEnabled"
      switch
      testId="bodySamplePolicy"
      title={t('handlers:setup sections.save body image')}
    >
      <GenericPolicySubForm
        fieldName="bodySamplePolicy.filters"
        matchTitle={t('handlers:policies.storage_policy.body_sample_policy.match title')}
      >
        <TTLSelectField fieldName="bodySamplePolicy" />
      </GenericPolicySubForm>
    </Fold>
  );
};

BodySamplePolicySubForm.propTypes = {
  isBodyLicenseAvailable: PropTypes.bool,
};
BodySamplePolicySubForm.defaultProps = {
  isBodyLicenseAvailable: false,
};

export default connect((state) => ({
  isBodyLicenseAvailable: selectIsAppFeatureAvailable(state, 'estimations.body_attributes'),
}))(BodySamplePolicySubForm);
