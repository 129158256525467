import React, { useEffect, useContext } from 'react';

import { EventFaceActions, EventBobyActions } from '@vlabs/shared/components/action-button-groups/EventActions';
import { SampleWithActions } from '@vlabs/shared/components/sample/SampleWithActions';
import { GoToCell } from '@vlabs/shared/components/table';
import { EventInfoCell } from '@vlabs/shared/legacy-components/event-info-cell/EventInfoCell';
import { Table, ButtonToggleGroup, ButtonToggle } from '@vlabs/uikit';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { SearchContext } from '../search-context';
import './Preview.sass';

const EventPreviewCell = ({ value }) => (
  <>
    {value.matcherType === 'body' && (
      <SampleWithActions
        actions={(
          <EventBobyActions
            fullFrameURL={value?.detectionWithUiFullFrame?.uiFullFrame}
            rect={value?.detectionWithUiFullFrame?.detection?.rect}
            sampleURL={value?.uiBodyDetection?.uiSampleURL}
            track={value?.uiFaceTrack?.map((i) => value?.face_detections[i])}
          />
        )}
        data-testid="PreviewImage"
        imageUrl={value?.uiBodyDetection?.uiSampleURL}
        type="body"
      />
    )}
    {value.matcherType === 'face' && (
      <SampleWithActions
        actions={(
          <EventFaceActions
            fullFrameURL={value?.detectionWithUiFullFrame?.uiFullFrame}
            rect={value?.detectionWithUiFullFrame?.detection?.rect}
            sampleURL={value?.uiFaceDetection?.uiSampleURL}
            track={value?.uiFaceTrack?.map((i) => value?.face_detections[i])}
          />
        )}
        data-testid="PreviewImage"
        imageUrl={value?.uiFaceDetection?.uiSampleURL}
        type="face"
      />
    )}

  </>
);

export const EventPreview = () => {
  const { t } = useTranslation();
  const { referencesPreview, onMatcherTypeChange, matcherType, setMatcherType } = useContext(SearchContext);
  const reference = referencesPreview?.data?.[0];

  useEffect(() => {
    if (reference?.body_detections && reference?.body_detections?.length !== 0) {
      setMatcherType('body');
    }
    if (reference?.face_detections && reference?.face_detections?.length !== 0) {
      setMatcherType('face');
    }
    if (!reference?.face_detections && !reference?.body_detections) {
      setMatcherType('');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reference?.face_detections, reference?.body_detections]);

  const buildEventLink = (matchResult) => {
    return matchResult.event_id ? `/events/${matchResult.event_id}` : undefined;
  };

  const columns = [
    {
      id: 'eventSampleCell',
      accessor: (value) => ({ ...value, matcherType }),
      Cell: EventPreviewCell,
      width: 80,
    },
    {
      id: 2,
      // FIXME: непонятный компонент, либо это ячейка таблицы и она должна принимать как row, так и value
      // либо это отдельный компонент, который можно сделать частью *Cell компонента
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ row }) => <EventInfoCell {...row} />,
      width: 135,
    },
    {
      id: 3,
      Cell: GoToCell,
      accessor: buildEventLink,
      width: 1,
    },
  ];

  return (
    <div
      className={cn('EventPreview__Table_custom')}
      data-testid="table.referencesPreview"
    >
      <ButtonToggleGroup
        className="ButtonToggle"
        itemClassName="ButtonToggle__Item"
        onChange={onMatcherTypeChange}
        testId="buttonSubgroup"
        value={matcherType}
      >
        <ButtonToggle
          disabled={(matcherType === 'body' || matcherType === '') && !reference?.face_detections?.length}
          value="face"
        >
          {t('face')}
        </ButtonToggle>
        <ButtonToggle
          disabled={(matcherType === 'face' || matcherType === '') && !reference?.body_detections?.length}
          value="body"
        >
          {t('тело')}
        </ButtonToggle>
      </ButtonToggleGroup>
      <Table
        columns={columns}
        data={referencesPreview.data}
        noHead
      />
    </div>
  );
};
