import React, { forwardRef } from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import './Textarea.sass';
import { ErrorMessage } from '../error-message/ErrorMessage';
import { getError, warnLabelWithoutId } from '../utils';

const Textarea = forwardRef(({
  id,
  name,
  className,
  inputStyle,
  onChange,
  onChangeValue,
  errors,
  hasError,
  errorMessage,
  label,
  ...props
}, externalRef) => {
  const error = getError(errors, name);

  const $onChange = (e) => {
    e.stopPropagation();
    if (onChange) onChange(e);
    if (onChangeValue) onChangeValue(e.target.value);
  };

  if (label && !id) {
    warnLabelWithoutId(label, name);
  }

  return (
    <>
      {label && (
        <label
          className="Input__Label"
          htmlFor={id}
        >
          {label}
        </label>
      )}

      <textarea
        className={cn(
          className,
          'Body-1',
          {
            Textarea: true,
            Textarea_error: hasError || error,
            [`Textarea_style-${inputStyle}`]: inputStyle,
          },
        )}
        data-testid="textarea"
        id={id}
        name={name}
        onChange={$onChange}
        ref={externalRef}
        {...props}
      />

      <ErrorMessage error={errorMessage ? { message: errorMessage } : error} />
    </>
  );
});

Textarea.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  inputStyle: PropTypes.oneOf(['default', 'implicit']),
  onChange: PropTypes.func,
  onChangeValue: PropTypes.func,
  errors: PropTypes.object,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  label: PropTypes.string,
};

Textarea.defaultProps = {
  id: undefined,
  name: undefined,
  className: undefined,
  inputStyle: 'default',
  onChange: undefined,
  onChangeValue: undefined,
  errors: undefined,
  hasError: undefined,
  errorMessage: undefined,
  label: undefined,
};

Textarea.displayName = 'Textarea';

export { Textarea };
