import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import './Badge.sass';

const Badge = ({
  value,
  kind,
  className,
  'data-testid': testid,
}) => (
  <div
    className={cn(
      className,
      'Small-1',
      {
        Badge: true,
        [`Badge_${kind}`]: kind,
      },
    )}
    data-testid={testid}
  >
    {value}
  </div>
);

Badge.propTypes = {
  value: PropTypes.string.isRequired,
  kind: PropTypes.oneOf([
    'positive',
    'attention',
    'negative',
  ]),
  className: PropTypes.string,
  'data-testid': PropTypes.string,
};

Badge.defaultProps = {
  kind: undefined,
  className: undefined,
  'data-testid': undefined,
};

export { Badge };
