import React from 'react';

import { CALLBACK_TYPE_OPTIONS } from '@vlabs/api-bindings/src/luna-client/tasks/constants';
import { PlusIcon } from '@vlabs/icons';
import { CallbackPolicyForm } from '@vlabs/shared/legacy-components/callback-form/CallbackPolicyForm';
import { defaultHttpValues } from '@vlabs/shared/legacy-components/callback-form/defaultValues';
import { Control, Divider } from '@vlabs/uikit';
import { useFieldArray, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import nextId from 'react-id-generator';

import st from './StepCallbacksPolicy.module.sass';

export function StepCallbacksPolicy() {
  const { t } = useTranslation();
  const { fields, append, remove } = useFieldArray({ name: 'notification_policy.task_callbacks', keyName: 'id' });
  const taskCallbacks = useWatch({ name: 'notification_policy.task_callbacks' });

  return (
    <>
      <h6>{t('tasks:настройки уведомлений')}</h6>
      {taskCallbacks && fields.map((field, i) => (
        <div className={st.CallbackPolicyItem} key={field.id}>
          <CallbackPolicyForm callbackTypeOptions={CALLBACK_TYPE_OPTIONS.raw} fieldName={`notification_policy.task_callbacks.${i}`} key={field.id} />
          <Divider small />
          <Control.Button
            data-testid="deleteButton"
            kind="negative"
            onClick={() => remove(i)}
            variant="outlined"
          >
            {t('tasks:удалить уведомление')}
          </Control.Button>
        </div>
      ))}
      <Control.Button
        data-testid="addButton"
        icon={<PlusIcon />}
        onClick={() => append({
          ...defaultHttpValues,
          id: nextId(),
        })}
        title={t('кнопка.добавить')}
        variant="dimmed"
      >
        {t('tasks:добавить настройки уведомления')}
      </Control.Button>
      <Divider small />
    </>
  );
}

StepCallbacksPolicy.fieldNames = [
  'notification_policy',
];
