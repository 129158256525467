import React, { useEffect } from 'react';

import validate from '@vlabs/shared/validators';
import { Page, Control, Divider } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useForm, FormProvider, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import './TagForm.sass';

import GenericPolicySubForm from './GenericPolicySubForm';

function TagForm({
  onSubmit,
  isSelectedTag,
  initialValues,
}) {
  const { t } = useTranslation();
  const [estimate_deepfake, estimate_liveness, extract, matchPolicy] = useWatch({ name: ['estimate_deepfake', 'estimate_liveness', 'extract', 'matchPolicy'] });
  // приходится пробрасывать отдельно, иначе GenericPolicy подпишется на
  // этот параметр во внутренней форме, и поля будут дизейблиться не везде
  const faceDescriptorIsEnabled = useWatch({ name: 'extract.faceDescriptor' });

  const formMethods = useForm({ defaultValues: {
    ...initialValues,
    estimate_deepfake,
    estimate_liveness,
    matchPolicy,
    extract,
  } });
  const {
    register, handleSubmit: originalHandlerSubmit,
    reset,
    formState: { errors },
  } = formMethods;
  useEffect(() => {
    reset({ ...initialValues, estimate_liveness, estimate_deepfake, extract, matchPolicy });
  }, [initialValues, estimate_liveness, estimate_deepfake, extract, reset, matchPolicy]);

  // Предотвращает сабмит основной формы
  const handleSubmit = (func) => (e) => {
    if (e?.preventDefault) e.preventDefault();
    if (e?.stopPropagation) e.stopPropagation();
    originalHandlerSubmit(func)(e);
  };

  return (
    <FormProvider {...formMethods}>
      <Page
        className="TagCreatePage TagCreateForm"
        title={isSelectedTag
          ? t('handlers:policies.conditional_tags_policy.edit tag')
          : t('handlers:policies.conditional_tags_policy.add tag')}
      >
        <Divider small />
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="TagCreateForm TagCreateForm__Row">
            <div className="Subtitle-1">
              {t('handlers:policies.conditional_tags_policy.tag')}
            </div>
            <div style={{ width: '322px' }}>
              <Control.Input
                errors={errors}
                {...register('tag', {
                  required: validate.required(),
                  maxLength: validate.maxLength(36),
                })}
              />
            </div>
            <Divider small />
          </div>

          <div className="TagCreateForm TagCreateForm__MatchSubform">
            <div className="TagCreateForm TagPolicy_ListWrapper">
              <GenericPolicySubForm
                faceFieldsDisabled={!faceDescriptorIsEnabled}
                fieldName="filters"
                matchTitle={t('handlers:policies.conditional_tags_policy.save if')}
              />
            </div>
          </div>
          <Control.Button fullWidth type="submit">
            {isSelectedTag
              ? t('кнопка.изменить')
              : t('кнопка.добавить')}
          </Control.Button>
        </form>
      </Page>
    </FormProvider>
  );
}

TagForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isSelectedTag: PropTypes.bool.isRequired,
  initialValues: PropTypes.objectOf(PropTypes.any),
};

TagForm.defaultProps = {
  initialValues: undefined,
};

export default TagForm;
