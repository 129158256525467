/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';

import { optionPropType } from '@vlabs/shared';
import DownloadButton from '@vlabs/shared/legacy-components/download-button/DownloadButton';
import { selectAppState } from '@vlabs/shared/selectors/appSelectors';
import { actionColumnProps } from '@vlabs/shared/utils';
import { Table, Divider, TableCells, Modal, Control } from '@vlabs/uikit';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';

import { selectHandlerOptions, selectHandlersWithLists } from '@vlabs/pages/handlers/selectors';
import { selectSourceOptionsByAccount } from '@vlabs/pages/sources/selectors';

import ReportActivityForm from './forms/ReportActivityForm';
import ReportComingForm from './forms/ReportComingForm';
import ReportLeavingForm from './forms/ReportLeavingForm';
import ProgressCell from './progress-cell/ProgressCell';
import { selectReports } from './selectors';
import * as storeActions from './store';

import './VLTimeTrackingTasksTab.sass';

export const MASK_OPTIONS = [
  { value: 1, label: i18n.t('timeTracking:нет маски') },
  { value: 2, label: i18n.t('timeTracking:есть маска') },
];

export const REPORT_OPTIONS = [
  { value: 'entry', label: i18n.t('timeTracking:приходы') },
  { value: 'exit', label: i18n.t('timeTracking:уходы') },
  { value: 'activity', label: i18n.t('timeTracking:рабочая активность') },
];

const DownloadReportCell = ({ value, row: { original } }) => {
  if (!value) return null;
  return (
    <DownloadButton onClick={() => storeActions.downloadCSV(original)} />
  );
};

function VLTimeTrackingTasksTab({
  availableHandlers,
  createReportsEntry,
  createReportsExit,
  createReportsActivity,
  camOptions,
  handlerOptions,
  setReportList,
  reportsData,
  setPageSize,
  setPageIndex,
}) {
  const { t } = useTranslation();
  const appState = useSelector(selectAppState);

  const [reportComingFormIsOpen, setReportComingFormIsOpen] = useState(false);
  const [reportLeavingFormIsOpen, setReportLeavingFormIsOpen] = useState(false);
  const [reportActivityFormIsOpen, setReportActivityFormIsOpen] = useState(false);
  const handlerOptionsWithLists = Object.values(availableHandlers).map(({ option }) => option);

  useEffect(() => {
    if (appState === 'loaded') setReportList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appState]);

  const submitForm = (values, func, closeModal) => {
    func(values);
    closeModal(false);
  };

  return (
    <>
      <Modal isOpen={reportComingFormIsOpen} onRequestClose={() => setReportComingFormIsOpen(false)}>
        <ReportComingForm
          availableHandlers={availableHandlers}
          camOptions={camOptions}
          handlerOptions={handlerOptionsWithLists}
          maskOptions={MASK_OPTIONS}
          onSubmit={(v) => submitForm(v, createReportsEntry, setReportComingFormIsOpen)}
        />
      </Modal>
      <Modal isOpen={reportLeavingFormIsOpen} onRequestClose={() => setReportLeavingFormIsOpen(false)}>
        <ReportLeavingForm
          availableHandlers={availableHandlers}
          camOptions={camOptions}
          handlerOptions={handlerOptionsWithLists}
          maskOptions={MASK_OPTIONS}
          onSubmit={(v) => submitForm(v, createReportsExit, setReportLeavingFormIsOpen)}
        />
      </Modal>
      <Modal isOpen={reportActivityFormIsOpen} onRequestClose={() => setReportActivityFormIsOpen(false)}>
        <ReportActivityForm
          availableHandlers={availableHandlers}
          camOptions={camOptions}
          handlerOptions={handlerOptionsWithLists}
          maskOptions={MASK_OPTIONS}
          onSubmit={(v) => submitForm(v, createReportsActivity, setReportActivityFormIsOpen)}
        />
      </Modal>
      <Divider small />
      <Control.Button
        className="TimeTracking__Button"
        onClick={() => setReportComingFormIsOpen(true)}
      >
        {t('timeTracking:приходы')}
      </Control.Button>
      <Control.Button
        className="TimeTracking__Button"
        onClick={() => setReportLeavingFormIsOpen(true)}
      >
        {t('timeTracking:уходы')}
      </Control.Button>
      <Control.Button
        className="TimeTracking__Button"
        onClick={() => setReportActivityFormIsOpen(true)}
      >
        {t('timeTracking:рабочая активность')}
      </Control.Button>

      <Divider small />
      <div className="TimeTracking__Divider" />

      <Table
        columns={[
          { Header: t('timeTracking:table.columns.author'), accessor: 'author' },
          { Header: t('timeTracking:table.columns.type'),
            accessor: 'typeReport',
            Cell: ({ value }) => {
              const typeReport = REPORT_OPTIONS.find(({ value: type }) => type === value);
              return typeReport?.label || value;
            },
          },
          {
            Header: t('timeTracking:table.columns.policy name'),
            accessor: 'handlerId',
            Cell: ({ value }) => {
              const handler = handlerOptions?.find(({ value: handlerId }) => handlerId === value);
              return handler?.label || value;
            },
          },
          { Header: t('timeTracking:table.columns.date'), accessor: 'date', Cell: TableCells.DateCell },
          { Header: t('timeTracking:table.columns.status'), accessor: 'status', Cell: ({ value }) => ProgressCell(value), width: 125 },
          actionColumnProps({
            id: 'actionDownload',
            accessor: 'pathReport',
            Cell: DownloadReportCell,
          }),
        ]}
        data={reportsData?.data}
        meta={reportsData?.meta}
        pageIndex={reportsData.pageIndex}
        pageSize={reportsData.pageSize}
        pageSizeOptions={reportsData.pageSizeOptions}
        paginationType="offsetBased"
        setPageIndex={setPageIndex}
        setPageSize={setPageSize}
      />
    </>
  );
}

VLTimeTrackingTasksTab.propTypes = {
  setPageSize: PropTypes.func.isRequired,
  setPageIndex: PropTypes.func.isRequired,
  setReportList: PropTypes.func.isRequired,
  createReportsEntry: PropTypes.func.isRequired,
  createReportsExit: PropTypes.func.isRequired,
  createReportsActivity: PropTypes.func.isRequired,
  availableHandlers: PropTypes.objectOf(PropTypes.any),
  camOptions: PropTypes.arrayOf(optionPropType),
  handlerOptions: PropTypes.arrayOf(optionPropType),
  reportsData: PropTypes.arrayOf(PropTypes.any),
};

VLTimeTrackingTasksTab.defaultProps = {
  camOptions: [],
  handlerOptions: [],
  availableHandlers: {},
  reportsData: {},
};

export default connect(
  (state) => ({
    availableHandlers: selectHandlersWithLists(state),
    camOptions: selectSourceOptionsByAccount(state),
    handlerOptions: selectHandlerOptions(state),
    reportsData: selectReports(state),
  }),
  (dispatch) => ({
    setReportList: () => dispatch(storeActions.fetchReportList),
    setPageSize: (pageSize) => dispatch(storeActions.updatePageSize({ pageSize })),
    setPageIndex: (pageIndex) => dispatch(storeActions.updatePage({ pageIndex })),
    createReportsEntry: (params) => dispatch(storeActions.createReportsEntry(params)),
    createReportsExit: (params) => dispatch(storeActions.createReportsExit(params)),
    createReportsActivity: (params) => dispatch(storeActions.createReportsActivity(params)),
  }),
)(VLTimeTrackingTasksTab);
