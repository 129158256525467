/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';

import { EditIcon } from '@vlabs/icons';
import {
  Control,
  Grid,
  GridCol,
  GridRow,
  Fold,
  Divider,
  Page,
  Margin,
  FieldWrapper,
  SettingsItemWrapper,
} from '@vlabs/uikit';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { HandlerLists } from './HandlerLists';
import st from './ThermoForm.module.sass';

function ThermoForm({
  onSubmit,
  onHandlerEdit,
  handlerLists,
  listOptions,
  values,
  asyncListOptions,
  asyncHandlerOptions,
}) {
  const { t } = useTranslation();
  const formMethods = useForm({ defaultValues: values });
  const { handleSubmit, reset, control, register, watch } = formMethods;

  const submitHandler = handleSubmit(onSubmit);

  useEffect(() => { reset(values); }, [values, reset]);

  const onAction = (
    <Control.Button type="submit" variant="outlined">
      {t('кнопка.сохранить')}
    </Control.Button>
  );

  useEffect(() => {
    const sub = watch((data, { name }) => {
      if (name === 'handler') {
        submitHandler();
      }
    });

    return () => sub.unsubscribe();
  }, [watch, submitHandler]);

  const context = { ...formMethods, asyncListOptions, asyncHandlerOptions };

  return (
    <FormProvider {...context}>
      <form onSubmit={submitHandler}>
        <Page actions={onAction} title={t('Настройки модуля "Термо"')}>
          <Divider />
          <Grid>
            <GridRow>
              <GridCol cols={6}>
                <Fold isOpen title={t('general')}>
                  <SettingsItemWrapper title={t('Активно')}>
                    <Control.Switch name="isActive" />
                  </SettingsItemWrapper>
                </Fold>
                <Divider />

                <Fold isOpen title={t('Карантин')}>
                  <SettingsItemWrapper title={t('Список для карантина')}>
                    <FieldWrapper>
                      <Control.Select
                        control={control}
                        name="quarantine.list"
                        options={listOptions}
                      />
                    </FieldWrapper>
                  </SettingsItemWrapper>
                  <SettingsItemWrapper title={t('Пороговое значение температуры')}>
                    <FieldWrapper>
                      <Control.Input
                        {...register('quarantine.threshold')}
                        step={0.1}
                        type="number"
                      />
                    </FieldWrapper>
                  </SettingsItemWrapper>
                </Fold>
                <Divider />
              </GridCol>
              <GridCol>
                <Fold isOpen title={t('Выбор обработчика сценария')}>
                  <SettingsItemWrapper
                    contentClassName={st.SettingWithIcon}
                    title={t('handler')}
                  >
                    <FieldWrapper>
                      <Control.Select
                        control={control}
                        isAsync
                        loadOptions={asyncHandlerOptions}
                        name="handler"
                      />
                    </FieldWrapper>
                    {onHandlerEdit && (
                      <Margin left>
                        <Link to={`handlers/${watch('handler')?.value}`}>
                          <EditIcon
                            className={cn(st.Icon, {
                              [st.Icon_disabled]: !watch('handler'),
                            })}
                          />
                        </Link>
                      </Margin>
                    )}
                  </SettingsItemWrapper>

                  <HandlerLists lists={handlerLists} />
                </Fold>
              </GridCol>
            </GridRow>
          </Grid>
        </Page>
      </form>
    </FormProvider>
  );
}

ThermoForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onHandlerEdit: PropTypes.func,
  values: PropTypes.shape({}),
  // eslint-disable-next-line react/forbid-prop-types
  handlerLists: PropTypes.any,
  asyncListOptions: PropTypes.func,
  asyncHandlerOptions: PropTypes.func,
};

ThermoForm.defaultProps = {
  onHandlerEdit: undefined,
  values: undefined,
  handlerLists: undefined,
  asyncListOptions: () => [],
  asyncHandlerOptions: () => [],
};

export default ThermoForm;
