import React from 'react';

import PropTypes from 'prop-types';
import ReactModal from 'react-modal';

import './Modal.sass';

const Modal = ({
  className,
  overlayClassName,
  bodyOpenClassName,
  appElement,
  'data-testid': testId,
  ...props
}) => {
  return (
    <ReactModal
      appElement={appElement || document.getElementById('root')}
      ariaHideApp={false}
      bodyOpenClassName={bodyOpenClassName}
      className={className}
      overlayClassName={overlayClassName}
      testId={testId}
      {...props}
    />
  );
};

Modal.propTypes = {
  className: PropTypes.string,
  overlayClassName: PropTypes.string,
  bodyOpenClassName: PropTypes.string,
  appElement: PropTypes.object, // PropTypes.node не подходит
  'data-testid': PropTypes.string,
};

Modal.defaultProps = {
  className: 'Modal',
  overlayClassName: 'Modal__Overlay',
  bodyOpenClassName: 'Modal__DocumentBody',
  appElement: undefined,
  'data-testid': 'modal',
};

export { Modal };
