import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import { Control } from '../controls/index';

import './ButtonScrimGroup.sass';

const ButtonScrimGroup = ({
  children,
  testId,
  className,
  itemClassName,
  childrenAs,
  position,
}) => {
  const childrenArray = React.Children.toArray(children);

  const Component = childrenAs || Control.RoundButton;
  const dynamicProps = childrenAs ? {} : { variant: 'dimmed' };

  return (
    <div
      className={cn(
        className,
        {
          ButtonScrimGroup: true,
          ButtonScrimGroup_oneButton: childrenArray.length === 1,
          ButtonScrimGroup_positioned: position,
          [`ButtonScrimGroup_position-${position}`]: position,
        },
      )}
      data-testid={testId}
    >
      {
        childrenArray.map(({ props: childProps = {} }, index) => (
          <Component
            {...dynamicProps}
            className={cn(itemClassName, 'ButtonScrimGroup__Item')}
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            {...childProps}
          >
            {childProps.children}
          </Component>
        ))
      }
    </div>
  );
};

ButtonScrimGroup.propTypes = {
  children: PropTypes.node,
  testId: PropTypes.string,
  className: PropTypes.string,
  itemClassName: PropTypes.string,
  position: PropTypes.oneOf([
    'top-left', 'top-center', 'top-right',
    'bottom-left', 'bottom-center', 'bottom-right',
  ]),
  childrenAs: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

ButtonScrimGroup.defaultProps = {
  children: undefined,
  testId: 'button-scrim-group',
  position: undefined,
  className: undefined,
  itemClassName: undefined,
  childrenAs: undefined,
};

export { ButtonScrimGroup };
