import { processFloat, processInteger } from '../helpers';

const to = (values, schema) => {
  if (!schema) return values;

  const $values = { ...values };

  Object.entries(schema).forEach(([fieldName, field]) => {
    const value = values[fieldName];

    if (value === '') {
      $values[fieldName] = null;
      return;
    }

    if (
      field.type === 'relationship'
      || field.type === 'creatable-select'
      || Array.isArray(field.enum)
    ) {
      if (value === Object(value) && 'value' in value) {
        $values[fieldName] = value.value;
      }
      return;
    }

    if (field.type === 'integer') {
      $values[fieldName] = processInteger(value);
      return;
    }

    if (field.type === 'number') {
      $values[fieldName] = processFloat(value);
    }
  });

  return $values;
};

export const dynamic = {
  toUpdate(values, schema) {
    return to(values, schema);
  },
  toCreate(typeName, values, schema) {
    return {
      component_name: typeName,
      settings: to(values, schema),
    };
  },
};
