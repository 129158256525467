import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import './AccountMenu.sass';

const AccountMenu = ({
  children,
  className,
  isOpened,
  ...props
}) => {
  return (
    <div
      className={cn(className, {
        AccountMenu: true,
        AccountMenu_visible: isOpened,
      })}
      {...props}
      data-testid="account.menu"
    >
      {children}
    </div>
  );
};

AccountMenu.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isOpened: PropTypes.bool.isRequired,
};

AccountMenu.defaultProps = {
  children: undefined,
  className: undefined,
};

export { AccountMenu };
