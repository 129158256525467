import React from 'react';

import { optionPropType } from '@vlabs/shared';
import { routes } from '@vlabs/shared/navigation/routes';
import { selectListOptions } from '@vlabs/shared/selectors/listSelectors';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';

import { selectHandlerListsById } from '@vlabs/pages/handlers/selectors';
import { getAsyncHandlerOptions } from '@vlabs/pages/handlers/store';

import { selectThermoSettings } from './selectors';
import { updateThermoSettings } from './store';
import ThermoForm from './ThermoForm';

function ThermoPageComponent({
  settings,
  onUpdate,
  handlerLists,
  listOptions,
}) {
  const history = useHistory();

  return (
    <ThermoForm
      asyncHandlerOptions={getAsyncHandlerOptions}
      handlerLists={handlerLists}
      listOptions={listOptions}
      onHandlerEdit={(handlerId) => history.push(generatePath(routes.handlers.read, { handlerId }))}
      onSubmit={onUpdate}
      values={settings}
    />
  );
}

ThermoPageComponent.propTypes = {
  settings: PropTypes.shape({}),
  onUpdate: PropTypes.func.isRequired,
  handlerLists: PropTypes.arrayOf(PropTypes.any),
  listOptions: PropTypes.arrayOf(optionPropType),
};
ThermoPageComponent.defaultProps = {
  settings: undefined,
  handlerLists: undefined,
  listOptions: [],
};

export const ThermoPage = connect(
  (state) => ({
    settings: selectThermoSettings(state),
    handlerLists: selectHandlerListsById(selectThermoSettings(state)?.handler?.value)(state),
    listOptions: selectListOptions(state),
  }),
  (dispatch) => ({
    onUpdate: (settings) => dispatch(updateThermoSettings(settings)),
  }),
)(ThermoPageComponent);
