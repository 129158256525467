import React, { useCallback } from 'react';

import { DetectionSelector } from '@vlabs/shared/components/detection-selector';
import { imageTypes } from '@vlabs/shared/config';
import { selectMaxImageSize } from '@vlabs/shared/selectors/appSelectors';
import validate from '@vlabs/shared/validators';
import { Control } from '@vlabs/uikit';
import { connect } from 'react-redux';

const PhotoUploadFormComponent = ({
  maxImageSize,
  disabledWhenDetections = true,
  onFileSelect,
  detections,
  onResetFile,
  onSelectSampleId,
  file,
  errorProps,
  isMultiDetections,
  selectedStateProps,
}) => {
  const [renderedImage, setRenderedImage] = React.useState(undefined);

  const FileWithDetectionsViewer = useCallback((renderer) => (
    <DetectionSelector
      detections={detections}
      imageRenderer={renderer}
      isMultiDetections={isMultiDetections}
      onSelect={onSelectSampleId}
      renderedImage={renderedImage}
      selectedStateProps={selectedStateProps}
    />
  ), [onSelectSampleId, detections, renderedImage, isMultiDetections, selectedStateProps]);

  return (
    <Control.FileDropzone
      data-testid="fileDropzone"
      id="file"
      onChangeValue={onFileSelect}
      {...(errorProps?.register && errorProps.register('file', {
        required: !file && validate.required(),
      }))}
      disabledWhenDetections={disabledWhenDetections}
      error={errorProps?.errors?.file?.message || errorProps?.errors?.message}
      fileViewer={{
        'image/x-portable-pixmap': () => FileWithDetectionsViewer(<Control.TextFileRenderer />),
        'image/tiff': () => FileWithDetectionsViewer(<Control.TextFileRenderer />),
        'image/jpeg': () => FileWithDetectionsViewer(<Control.ImageFileRenderer
          photo={file?.binary}
          setRenderedImage={setRenderedImage}
        />),
        'image/png': () => FileWithDetectionsViewer(<Control.ImageFileRenderer
          photo={file?.binary}
          setRenderedImage={setRenderedImage}
        />),
        'image/bmp': () => FileWithDetectionsViewer(<Control.ImageFileRenderer
          photo={file?.binary}
          setRenderedImage={setRenderedImage}
        />),
      }}
      localFile={file}
      maxImageSize={maxImageSize}
      onlyImages
      onReset={onResetFile}
      supportedImageTypes={imageTypes}
    />
  );
};

export const PhotoUploadForm = connect(
  (state) => ({
    maxImageSize: selectMaxImageSize(state),
  }),
)(PhotoUploadFormComponent);
