import React from 'react';

import { ROLE_OPTIONS } from '@vlabs/api-bindings/src/constants';
import validate from '@vlabs/shared/validators';
import {
  Page, Control, Divider,
} from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import './AccountForm.sass';

function AccountForm({
  defaultValues,
  onSubmit,
}) {
  const { t } = useTranslation();
  const { handleSubmit, register, control, formState: { errors } } = useForm({ defaultValues });
  return (
    <FormProvider>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Page className="AccountForm" title={t('accounts:edit form.title')}>
          <Divider />

          <Control.Input
            id="name"
            label={t('accounts:name')}
            {...register('name', {
              required: validate.required(),
              maxLength: validate.maxLength(50),
            })}
            errors={errors}
          />
          <Divider small />

          <Control.Input
            id="email"
            label={t('accounts:email')}
            {...register('email', {
              required: validate.required(),
              maxLength: validate.maxLength(254),
              validate: validate.email(),
            })}
            errors={errors}
          />
          <Divider small />

          <Control.Input
            id="post"
            label={t('accounts:position')}
            {...register('post', {
              maxLength: validate.maxLength(128),
            })}
            errors={errors}
          />
          <Divider small />

          <Control.Select
            control={control}
            errors={errors}
            id="role"
            isDisabled
            label={t('accounts:role')}
            name="role"
            options={ROLE_OPTIONS.raw}
            rules={{ required: validate.required() }}
          />
          <Divider small />

          <Control.Button fullWidth type="submit">
            {t('форма.submit')}
          </Control.Button>
        </Page>
      </form>
    </FormProvider>
  );
}

AccountForm.propTypes = {
  defaultValues: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    post: PropTypes.string,
    role: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
  }),
  onSubmit: PropTypes.func,
};

AccountForm.defaultProps = {
  defaultValues: {},
  onSubmit: undefined,
};

export default AccountForm;
