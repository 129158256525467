export const permissions = {
  source: {
    view: 'source:view',
    creation: 'source:creation',
    modification: 'source:modification',
    deletion: 'source:deletion',
  },
  appInfo: {
    view: 'appInfo:view',
  },
  department: {
    view: 'department:view',
  },
  thermo: {
    read: 'thermo:read',
  },
  notification: {
    view: 'notification:view',
  },

  // Новые права
  verifier: {
    view: 'verifier:view',
    creation: 'verifier:creation',
    modification: 'verifier:modification',
    deletion: 'verifier:deletion',
  },
  account: {
    view: 'account:view',
  },
  attribute: {
    view: 'attribute:view',
    matching: 'attribute:matching',
    creation: 'attribute:creation',
    modification: 'attribute:modification',
    deletion: 'attribute:deletion',
  },
  bodySample: {
    view: 'body_sample:view',
    creation: 'body_sample:creation',
    deletion: 'body_sample:deletion',
  },
  event: {
    view: 'event:view',
    matching: 'event:matching',
    creation: 'event:creation',
  },
  face: {
    view: 'face:view',
    matching: 'face:matching',
    creation: 'face:creation',
    modification: 'face:modification',
    deletion: 'face:deletion',
  },
  faceSample: {
    view: 'face_sample:view',
    creation: 'face_sample:creation',
    deletion: 'face_sample:deletion',
  },
  handler: {
    view: 'handler:view',
    creation: 'handler:creation',
    modification: 'handler:modification',
    deletion: 'handler:deletion',
  },
  image: {
    view: 'image:view',
    creation: 'image:creation',
    deletion: 'image:deletion',
  },
  lambdas: {
    view: 'lambdas:view',
    creation: 'lambdas:creation',
    modification: 'lambdas:modification',
    deletion: 'lambdas:deletion',
  },
  list: {
    view: 'list:view',
    creation: 'list:creation',
    modification: 'list:modification',
    deletion: 'list:deletion',
  },
  object: {
    view: 'object:view',
    creation: 'object:creation',
    deletion: 'object:deletion',
  },
  resources: {
    iso: 'resources:iso',
    liveness: 'resources:liveness',
    sdk: 'resources:sdk',
  },
  task: {
    view: 'task:view',
    creation: 'task:creation',
    modification: 'task:modification',
    deletion: 'task:deletion',
  },
  token: {
    view: 'token:view',
    creation: 'token:creation',
    modification: 'token:modification',
    deletion: 'token:deletion',
  },
  emit_events: {
    allowed: 'emit_events:1',
    notAllowed: 'emit_events:0',
  },
};

export const permissionValues = {
  view: 'view',
  creation: 'creation',
  modification: 'modification',
  deletion: 'deletion',
  batchProcessing: 'batchProcessing',
  iso: 'iso', 
  liveness: 'liveness',
  sdk: 'sdk',
};
