import React, { memo } from 'react';

import { IconInner } from '../../IconInner';

const DollarSignIconInner = (props) => (
  <IconInner name="DollarSignIcon" viewBox="0 0 24 24" {...props}>
    <path d="M12 1v22m5-18H9.5a3.5 3.5 0 1 0 0 7h5a3.5 3.5 0 1 1 0 7H6" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
  </IconInner>
);

export const DollarSignIcon = memo(DollarSignIconInner);
