import { apiContainer } from '@vlabs/api-bindings';

const DIGITS = 5;

const mapDetails = (data) => {
  const attributesFields = ['gaze', 'head_pose', 'mouth_attributes'];
  const quality = {};
  const attributes = {};

  Object.entries(data?.quality).forEach(([k, v]) => {
    quality[k] = v.toFixed(DIGITS);
  });

  Object.entries(data?.attributes).forEach(([k, v]) => {
    const val = {};
    if (attributesFields.includes(k)) {
      Object.entries(v).forEach(([key, value]) => {
        val[key] = value.toFixed(DIGITS);
      });
      attributes[k] = { ...val };
    }
    if (k === 'eyes_attributes') attributes[k] = v;
  });

  return { quality, attributes };
};

export async function estimateFaceAttributesByImage(image) {
  const { data: estimate } = await apiContainer.lunaClient.sdk.estimate(image, {
    image_type: 0,
    estimate_quality: 1,
    estimate_mouth_attributes: 1,
    estimate_eyes_attributes: 1,
    estimate_emotions: 1,
    estimate_gaze: 1,
    estimate_head_pose: 1,
  });

  const estimation = estimate?.images_estimations[0]?.estimations[0]?.face?.detection;

  if (!estimation) return undefined;
  return mapDetails(estimation);
}
