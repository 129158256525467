import { versionGetter } from '../../../utils';
import { create as createSchema4 } from './schemes/4.x.create';
import { read as readSchema4 } from './schemes/4.x.read';
import { create as createSchema5 } from './schemes/5.x.create';
import { read as readSchema5 } from './schemes/5.x.read';

export default new Proxy({
  4: {
    read: readSchema4,
    create: createSchema4,
  },
  5: {
    read: readSchema5,
    create: createSchema5,
  },
}, versionGetter);
