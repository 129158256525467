import { TableCells } from './cells';

export const actionColumnProps = (props) => ({
  width: 40,
  minWidth: 40,
  maxWidth: 40,
  type: 'action',
  align: 'center',
  disableFilters: true,
  ...props,
});

export const selectColumn = actionColumnProps({
  id: 'selection',
  Header: TableCells.SelectAllRowsCell,
  Cell: TableCells.SelectRowCell,
});

export const importColumn = actionColumnProps({
  id: 'import',
  Header: TableCells.ImportCell,
  Cell: TableCells.EditCell,
});

export const exportColumn = actionColumnProps({
  id: 'export',
  Header: TableCells.ExportCell,
  Cell: TableCells.DeleteCell,
});
