/* eslint-disable consistent-return */
import React, { useState, useEffect } from 'react';

import { apiContainer } from '@vlabs/api-bindings';
import { FileTextIcon, CircleInfoIcon } from '@vlabs/icons';
import { selectAppServices } from '@vlabs/shared/selectors/appSelectors';
import { Page, Table, Panel, Divider, Tooltip } from '@vlabs/uikit';
import cn from 'classnames';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import st from './InfoPage.module.sass';

const StateLabelCell = ({ value }) => {
  const { t } = useTranslation();

  const stateList = {
    loading: t('info:stateList.loading'),
    loaded: t('info:stateList.loaded'),
    failed: t('info:stateList.failed'),
  };

  return (
    <>
      <div className={cn(st.State, st[`State_${value}`])} />
      <span>{stateList[value]}</span>
    </>
  );
};

StateLabelCell.propTypes = {
  value: PropTypes.string,
};

StateLabelCell.defaultProps = {
  value: undefined,
};

const HealthCheckCell = ({ value }) => {
  const [response, setResponse] = useState(null);
  const { t } = useTranslation();

  async function healthCheck(client) {
    try {
      const responseResult = await apiContainer[client].healthcheck();
      return {
        status: responseResult.status,
        data: responseResult.data,
      };
    } catch (error) {
      return {
        status: error.response,
      };
    }
  }

  useEffect(() => {
    const fetchHealthCheck = async () => {
      try {
        const services = {
          clementineAuth: 'clementineAuthClient',
          lunaStreams: 'lunaStreamsClient',
          lunaPlatform: 'lunaClient',
          vlNotifier: 'notifierClient',
          vlTimetracker: 'timeTrackerClient',
          clementineDepartments: 'clementineDepartmentsClient',
          vaReporter: 'vaReporterClient',
          vlAccess: 'vlAccessClient',
        };

        const fetchResult = await healthCheck(services[value]);
        setResponse(fetchResult);
      } catch (error) {
        return null;
      }
    };

    fetchHealthCheck();

    const interval = setInterval(fetchHealthCheck, 1800000);
    return () => clearInterval(interval);
  }, [value]);

  if (value === 'ui' || value === 'clementineUI' || response?.status === undefined) return;

  const goodResponses = [200, 201, 202, 204, 205, 206, 207, 208, 226];

  if (!goodResponses.includes(response?.status)) {
    return (
      <div className="badResponse">
        <span>{t('info:healthcheck.badresponse')}</span>
        {(response?.error_code || response?.erros) && (
        <Tooltip
          className="Tooltip_ui"
          data-testid="badResponseTooltip"
          data-tooltip-content={JSON.stringify(response)}
        >
          <CircleInfoIcon className="Tooltip_icon" />
        </Tooltip>
        )}
      </div>
    );
  }

  return (
    <div className={cn(st.GoodResponse)}>
      <span>{t('info:healthcheck.goodresponse')}</span>
      {response?.data.execution_time && (
      <Tooltip
        className="Tooltip_ui"
        data-testid="executionTimeTooltip"
        data-tooltip-content={t('info:healthcheck.executiontime', { value: response?.data.execution_time })}
      >
        <CircleInfoIcon className="Tooltip_icon" />
      </Tooltip>
      )}
    </div>
  );
};

HealthCheckCell.propTypes = {
  value: PropTypes.string,
};

HealthCheckCell.defaultProps = {
  value: undefined,
};

function UIDocsCell({ value }) {
  if (value === undefined) return null;
  return (
    <a
      aria-label="FileTextIcon"
      className={st.DocsLink}
      href={value}
      title={i18n.t('посмотреть')}
    >
      <FileTextIcon />
    </a>
  );
}

UIDocsCell.propTypes = {
  value: PropTypes.string,
};

UIDocsCell.defaultProps = {
  value: undefined,
};

function InfoPageComponent({
  services,
  showDocumentationColumn,
}) {
  const { t } = useTranslation();
  const data = Object.values(services);

  const columns = [
    { Header: t('info:table.columns.title'), accessor: 'title' },
    { Header: t('info:table.columns.version'), accessor: 'version' },
    { Header: t('info:table.columns.state'), accessor: 'state', Cell: StateLabelCell },
    { Header: t('info:table.columns.healthcheck'), accessor: 'name', Cell: HealthCheckCell },
  ];

  if (showDocumentationColumn) {
    columns.push({
      Header: t('info:table.columns.documentation'),
      accessor: 'uiDocsURL',
      Cell: UIDocsCell,
    });
  }

  return (
    <Page title={t('info:title')}>
      <Divider />
      <Panel>
        <Table
          columns={columns}
          data={data}
        />
      </Panel>
    </Page>
  );
}

InfoPageComponent.propTypes = {
  services: PropTypes.shape({}),
  showDocumentationColumn: PropTypes.bool,
};

InfoPageComponent.defaultProps = {
  services: {},
  showDocumentationColumn: true,
};

export const InfoPage = connect((state) => ({
  services: selectAppServices(state),
}))(InfoPageComponent);
