import React from 'react';

import { CameraOffIcon } from '@vlabs/icons';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import st from './PreviewCell.module.sass';

const PreviewCell = ({
  getLastFrameURL,
  original,
}) => {
  const { t } = useTranslation();

  if (!original?.alive) {
    return (
      <div
        className={cn(st.Preview, st.Preview_noPhoto)}
        data-testid="streamPreviewNoPhoto"
      >
        <CameraOffIcon className={st.Preview_offIcon} />
        {t('превью отсутствует')}
      </div>
    );
  }

  const previewUri = getLastFrameURL(original?.id);

  return (
    <img
      alt={previewUri}
      className={st.Preview}
      data-testid="streamPreview"
      src={previewUri}
    />
  );
};

PreviewCell.propTypes = {
  original: PropTypes.objectOf(PropTypes.any),
  getLastFrameURL: PropTypes.func,
};

PreviewCell.defaultProps = {
  original: '',
  getLastFrameURL: undefined,
};

export default PreviewCell;
