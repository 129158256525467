/* eslint-disable max-classes-per-file */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import formatISO from 'date-fns/formatISO';
import parseISO from 'date-fns/parseISO';
import _, { isEmpty as _isEmpty, isNaN, isNull, isObject, isString, isUndefined } from 'lodash';

export const isEmpty = (v) => (isObject(v) ? _isEmpty(v) : false);

class Callable extends Function {
  constructor() {
    // We create a new Function object using `super`, with a `this` reference
    // to itself (the Function object) provided by binding it to `this`,
    // then returning the bound Function object (which is a wrapper around the
    // the original `this`/Function object). We then also have to store
    // a reference to the bound Function object, as `_bound` on the unbound `this`,
    // so the bound function has access to the new bound object.
    // Pro: Works well, doesn't rely on deprecated features.
    // Con: A little convoluted, and requires wrapping `this` in a bound object.

    super('...args', 'return this._bound._call(...args)');
    // Or without the spread/rest operator:
    // super('return this._bound._call.apply(this._bound, arguments)')
    this._bound = this.bind(this);

    // eslint-disable-next-line no-constructor-return
    return this._bound;
  }
}

export class Getter extends Callable {
  constructor(path, defaultValue) {
    super();
    this.path = path;
    this.pipeline = [];
    this.omits = [];
    this.defaultValue = defaultValue;
    this.typeChecks = [
      (func) => (v) => (isUndefined(v) ? v : func(v)),
      // (func) => (v) => (isNull(v) ? v : func(v)),
      // (func) => (v) => (isNaN(v) ? v : func(v)),
    ];
  }

  _registerCast(func) {
    this.pipeline.push(_.flow(this.typeChecks)(func));
    return this;
  }

  as(cast) {
    return this._registerCast(cast);
  }

  ifNull(defaultValue) {
    return this._registerCast((v) => (isNull(v) ? defaultValue : v));
  }

  ifNaN(defaultValue) {
    return this._registerCast((v) => (isNaN(v) ? defaultValue : v));
  }

  ifUndefined(defaultValue) {
    return this._registerCast((v) => (isUndefined(v) ? defaultValue : v));
  }

  ifEmpty(defaultValue) {
    return this._registerCast((v) => (isEmpty(v) ? defaultValue : v));
  }

  ifEmptyString(defaultValue) {
    return this._registerCast((v) => (isString(v) && v.length === 0 ? defaultValue : v));
  }

  asFloat() { return this._registerCast(parseFloat); }

  asInt(radix = 10) {
    return this._registerCast((v) => {
      if (typeof v === 'boolean') return Number(v);
      if (typeof v === 'string' && v === 'null') return null;
      return parseInt(v, radix);
    });
  }

  asString() { return this._registerCast((v) => (v === undefined ? undefined : String(v))); }

  asBoolean() { return this._registerCast((v) => (v === undefined ? undefined : Boolean(v))); }

  asDate(parser = parseISO) { return this._registerCast((v) => (v === undefined ? undefined : parser(v))); }

  asISOString({
    formatter = formatISO,
    unpack = false,
  } = {}) {
    return this._registerCast((v) => {
      if (v === undefined) return undefined;
      if (v === null) return undefined;
      if (Array.isArray(v)) {
        if (v.length === 0) return undefined;
        if (unpack) return v.map(formatter).join(',');
        return v.map(formatter);
      }
      if (v instanceof Date) return formatter(v);
      throw new Error(`${v} not known value type for iso string formatter`);
    });
  }

  asOption(proxy) {
    return this._registerCast((value) => {
      if (proxy === undefined) return { label: value, value };
      return proxy[value];
    });
  }

  asOptionValue() { return this._registerCast((option) => option?.value); }

  asArrayOf(each) {
    return this._registerCast((initialValue) => {
      let value = initialValue;
      if (!Array.isArray(initialValue)) value = Array(initialValue);
      // eslint-disable-next-line no-use-before-define
      if (each) value = value.map(each);
      return value;
    });
  }

  asArrayOfOptions(proxy) {
    return this._registerCast((value) => {
      if (proxy === undefined) return value.map((v) => ({ label: v, value: v }));
      return value.map((key) => proxy[key]);
    });
  }

  asArrayOfOptionValues() {
    return this._registerCast((value) => {
      if (value === undefined) return [];
      if (!Array.isArray(value)) value = [value];
      return value.map((option) => option?.value).filter((v) => v !== undefined);
    });
  }

  asArrayWithOptionalNull() {
    return this._registerCast((initialValue) => {
      return initialValue.map((value) => (value === 'null' ? null : value));
    });
  }

  asArrayOfInt(radix = 10) {
    return this._registerCast((initialValue) => {
      let value = initialValue;
      if (!Array.isArray(initialValue)) value = Array(initialValue);
      return value.map((v) => parseInt(v, radix));
    });
  }

  asArrayOfFloat() {
    return this._registerCast((initialValue) => {
      let value = initialValue;
      if (!Array.isArray(initialValue)) value = Array(initialValue);
      return value.map(parseFloat);
    });
  }

  asArrayFrom(getter) {
    return this._registerCast(getter);
  }

  toNormalizedPercent(precision = 4) {
    return this._registerCast((v) => Number((v / 100).toFixed(precision)));
  }

  asArrayFromString(splitter = ',') {
    return this._registerCast((v) => {
      if (Array.isArray(v)) return v;
      if (v?.length === 0) return [];
      return v.split(splitter).map((s) => s.trim());
    });
  }

  switch(predicate, cases) {
    return this._registerCast((value) => {
      const predicateValue = predicate ? predicate(value) : value;
      let currentCase = cases[predicateValue];

      if (!currentCase && cases.default) {
        currentCase = cases.default;
      } else if (isUndefined(currentCase)) {
        const textValue = typeof value === 'object' ? JSON.stringify(value) : value;
        const textPredicateValue = typeof predicateValue === 'object' ? JSON.stringify(predicateValue) : predicateValue;
        throw Error(`JSON Transform: Switch case could not be undefined, value: ${textValue}, predicateValue: ${textPredicateValue}`);
      }

      if (typeof currentCase === 'function') {
        return currentCase(value);
      }
      return currentCase;
    });
  }

  asSchema(schema) { return this._registerCast((v) => schema(v)); }

  _debug() {
    return this._registerCast((v) => {
      // eslint-disable-next-line no-console
      console.debug(this.path, typeof v, v);
      return v;
    });
  }

  _call(value) {
    try {
      return _.flow(this.pipeline)(this.path === undefined ? value : _.get(value, this.path, this.defaultValue));
    } catch (e) {
      console.debug(`path: "${this.path}"`);
      console.debug('raw value:', _.get(value, this.path));
      console.debug('object:', value);
      console.error(e);
      throw e;
    }
  }
}

export function get(...args) {
  return new Getter(...args);
}

export class Schema extends Callable {
  constructor(schema, ...mixins) {
    super();
    this._result = {};
    this._schema = mixins?.length > 0 ? _.merge(schema, ...mixins) : schema;
    this._omits = [];
    this._initSchema = this._initSchema.bind(this);
    this.omitBy = this.omitBy.bind(this);
    this._initSchema();
  }

  _initSchema() {
    this.mapper = (obj) => (partialSchema) => {
      const result = {};
      Object.entries(partialSchema).forEach(([k, v]) => {
        if (v instanceof Getter || v instanceof Schema) {
          result[k] = v(obj);
          return;
        }
        if (typeof v === 'object' && !Array.isArray(v) && v !== null) {
          result[k] = this.mapper(obj)(v);
          return;
        }
        result[k] = v;
      });
      return result;
    };
  }

  omitBy(predecate) {
    this._omits.push(predecate);
    return this;
  }

  ifEmpty(value) {
    this.default = value;
    return this;
  }

  static get(path, ...args) {
    return new Getter(path, ...args);
  }

  _applyOmits(obj) {
    return _.omitBy(obj, (v, k) => {
      for (let i = 0; i < this._omits.length; i++) {
        if (this._omits[i](v, k) === true) return true;
      }
      return false;
    });
  }

  _call(obj) {
    const raw = this.mapper(obj)(this._schema);
    const result = this._applyOmits(raw);
    if (Object.prototype.hasOwnProperty.call(this, 'default')
    && isEmpty(result)) return this.default;
    return result;
  }
}

export class CleanSchema extends Schema {
  constructor(schema) {
    super(schema);
    this.omitBy(isUndefined);
    this.omitBy(isEmpty);
  }

  _call(obj) {
    function removeEmptyObjects(value) {
      return _(value)
        .pickBy(_.isPlainObject)
        .mapValues((v) => _.omitBy(removeEmptyObjects(v), _.isUndefined))
        .omitBy(isEmpty)
        .assign(_.omitBy(value, _.isPlainObject))
        .value();
    }
    const raw = this.mapper(obj)(this._schema);
    const result = this._applyOmits(removeEmptyObjects(raw));
    if (Object.prototype.hasOwnProperty.call(this, 'default')
    && isEmpty(result)) return this.default;
    return result;
  }
}

export const pipe = (...args) => _.flow([...args]);
export const CASTS = {
  OPTIONCONST: (proxy) => (v) => {
    if (v === null) return null;
    if (Array.isArray(v)) return v.map((el) => proxy[el]);
    return proxy[v];
  },
  ONEOF: (...options) => (v) => {
    if (v === undefined) return undefined;
    if (!options.includes(v)) {
      throw Error(`ONEOF Cast error: ${v} is not allowed in ${options.join(', ')}`);
    }
    return v;
  },
  ANYOF: (...options) => (array) => {
    if (!Array.isArray(array)) return [];
    array.forEach((v) => {
      if (!options.includes(v)) {
        throw Error(`ONEOF Cast error: ${v} is not allowed in ${options.join(', ')}`);
      }
    });
    return array;
  },
  OPTIONFROMVALUE: (value) => (
    value === undefined || value === null
      ? undefined
      : ({ value, label: value })
  ),
  OPTIONARRAYVALUE: (options) => (
    Array.isArray(options) && options.length > 0
      ? options.map(({ value }) => value)
      : undefined
  ),
  DATE: {
    fromISOString: parseISO,
    toISOString: formatISO,
  },
};
