import React, { forwardRef } from 'react';

import { ChevronLeftIcon } from '@vlabs/icons';

import { RoundButton } from '../button/RoundButton';

const BackButton = forwardRef((props, externalRef) => {
  return (
    <RoundButton
      data-testid="BackButton"
      icon={<ChevronLeftIcon />}
      kind="neutral"
      ref={externalRef}
      variant="outlined"
      {...props}
    />
  );
});

export { BackButton };
