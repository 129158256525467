import React, { useCallback } from 'react';

import { selectIsAppFeatureAvailable } from '@vlabs/shared/selectors/appSelectors';
import { Control } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useFormContext, useWatch } from 'react-hook-form';
import { connect } from 'react-redux';

import { useUpdateField } from '../hooks';

function EstimateBodyToggleComponent({
  isBodyLicenseAvailable,
}) {
  const { control } = useFormContext();
  const estimateBody = useWatch({ name: 'estimate.body' });
  const onDisableEstimateBodyCallback = useCallback(() => estimateBody === false, [estimateBody]);

  useUpdateField({ path: 'estimate.face', value: true, on: onDisableEstimateBodyCallback });
  useUpdateField({ path: 'extract.bodyDescriptor', value: false, on: onDisableEstimateBodyCallback });

  return (
    <Control.Switch
      control={control}
      disabled={!isBodyLicenseAvailable}
      name="estimate.body"
    />
  );
}

EstimateBodyToggleComponent.propTypes = {
  isBodyLicenseAvailable: PropTypes.bool,
};

EstimateBodyToggleComponent.defaultProps = {
  isBodyLicenseAvailable: false,
};

export const EstimateBodyToggle = connect((state) => ({
  isBodyLicenseAvailable: selectIsAppFeatureAvailable(state, 'estimations.body_attributes'),
}))(EstimateBodyToggleComponent);
