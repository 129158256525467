import React from 'react';

import { EMOTION_OPTIONS } from '@vlabs/api-bindings/src/constants';
import { emotionImages } from '@vlabs/shared/assets/images';
import { useTranslation, Trans } from 'react-i18next';

import { Card } from './Card';
import st from './Card.module.sass';

const EMOTION_IMAGES = {
  1: emotionImages.Anger,
  2: emotionImages.Disgust,
  3: emotionImages.Fear,
  4: emotionImages.Happiness,
  5: emotionImages.Neutral,
  6: emotionImages.Sadness,
  7: emotionImages.Surprise,
};

export const EmotionCard = ({ emotion }) => {
  const { t } = useTranslation();

  const emotionTitle = EMOTION_OPTIONS[emotion]?.label;
  const emotionImg = EMOTION_IMAGES[emotion];
  return (
    <Card
      back={(
        <div className={st.Tooltip}>
          <Trans i18nKey="events:карточка.эмоция" values={{ emotion: emotionTitle }} />
        </div>
      )}
      front={(
        <div className={st.Root}>
          <div
            className={st.ImageBlock}
            data-testid="faceAttributesCell.emotion"
          >
            {t('emotion')}
            <img alt="emotion icon" className={st.Image} src={emotionImg} />
            {emotionTitle}
          </div>
        </div>
      )}
    />
  );
};
