import React, { useState, useEffect } from 'react';

import { CrossIcon } from '@vlabs/icons';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { RoundButton } from '../../controls/button/RoundButton';
import { Input } from '../../controls/input/Input';

import './RangeFilter.sass';

export function buildRangeFilter({ options: [min, max] }) {
  const RangeFilter = ({
    column,
  }) => {
    const { filterValue, setFilter } = column;
    const { t } = useTranslation();
    const [ageFrom, setAgeFrom] = useState();
    const [ageTo, setAgeTo] = useState();
    const [ageFromError, setAgeFromError] = useState(undefined);
    const [ageToError, setAgeToError] = useState(undefined);

    useEffect(() => {
      const value = ageFrom && ageTo ? `${ageFrom},${ageTo}` : undefined;
      setFilter(value);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ageFrom, ageTo]);

    const clearFilter = () => {
      setAgeFrom();
      setAgeTo();
    };

    return (
      <div className="RangeFilter">
        <div className="RangeFilter__Input">
          <Input
            error={ageFromError}
            inputStyle="implicit"
            max={max}
            min={min}
            onChange={(e) => {
              const { value } = e.target;
              if (value && value < min) {
                setAgeFromError(min);
                return;
              }
              if (value && value > max) {
                setAgeFromError(max);
                return;
              }
              setAgeFromError(undefined);
              setAgeFrom(value || undefined);
              if (value) {
                if (ageTo && (ageTo < value)) {
                  setAgeTo(value);
                } else if (!ageTo) {
                  setAgeTo(max);
                }
              } else {
                setAgeTo(undefined);
              }
            }}
            placeholder={t('uikit:table.фильтры.от')}
            type="number"
            value={(filterValue && filterValue.split(',')[0]) || ''}
          />
        </div>
        <div className="RangeFilter__Divider">:</div>
        <div className="RangeFilter__Input">
          <Input
            error={ageToError}
            inputStyle="implicit"
            max={max}
            min={min}
            onChange={(e) => {
              const { value } = e.target;
              if (value && value < min) {
                setAgeToError(min);
                return;
              }
              if (value && value > max) {
                setAgeToError(max);
                return;
              }
              setAgeToError(undefined);
              setAgeTo(value || undefined);
              if (value) {
                if (ageFrom && (ageFrom > value)) {
                  setAgeFrom(value);
                } else if (!ageFrom) {
                  setAgeFrom(min);
                }
              } else {
                setAgeFrom(undefined);
              }
            }}
            placeholder={t('uikit:table.фильтры.до')}
            type="number"
            value={(filterValue && filterValue.split(',')[1]) || ''}
          />
        </div>
        <RoundButton
          kind="negative"
          onClick={clearFilter}
          variant="dimmed"
        >
          <CrossIcon />
        </RoundButton>
      </div>
    );
  };

  RangeFilter.propTypes = {
    column: PropTypes.any,
  };

  RangeFilter.defaultProps = {
    column: undefined,
  };

  return RangeFilter;
}
