import React, { useCallback } from 'react';

import { apiContainer } from '@vlabs/api-bindings';
import { ButtonWithPermissions } from '@vlabs/shared/components/button-with-permissions/ButtonWithPermissions';
import { permissions } from '@vlabs/shared/config';
import { useModal } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { PhotoForm } from '../forms';

export const UpdatePhotoWidget = ({
  faceId,
  afterSubmit,
}) => {
  const { t } = useTranslation();
  const modal = useModal();

  const submitForm = useCallback(async ({ sample_id }) => {
    const { data: [{ attribute_id }] } = await apiContainer.lunaClient.extractor
      .extractAttributes([sample_id], { extract_basic_attributes: 1 });
    await apiContainer.lunaClient.faces.attributes(faceId).put({ attribute_id });
    await apiContainer.lunaClient.faces.patch(faceId, { avatar: `/6/samples/faces/${sample_id}` });
    if (afterSubmit) afterSubmit();
    modal.close();
    toast.success(`${t('Информация успешно обновлена')}.`);
  }, [afterSubmit, modal, t, faceId]);

  return (
    <>
      {modal.wrap(<PhotoForm
        data-testid="photoForm"
        onSubmit={submitForm}
      />)}
      <ButtonWithPermissions
        data-testid="faceActionsUpdateButton"
        fullWidth
        onClick={modal.open}
        permissions={{
          rules: [
            permissions.faceSample.creation,
            permissions.attribute.creation,
            permissions.attribute.modification,
            permissions.face.modification],
          method: 'allof',
        }}
        variant="outlined"
      >
        {t('faces:обновить фотографию')}
      </ButtonWithPermissions>
    </>
  );
};

UpdatePhotoWidget.propTypes = {
  afterSubmit: PropTypes.func,
  faceId: PropTypes.string,
};

UpdatePhotoWidget.defaultProps = {
  faceId: undefined,
  afterSubmit: undefined,
};
