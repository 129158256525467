import { Schema, get } from '../../json-transform';
import {
  STREAM_TYPE_OPTIONS, ROTATION_OPTIONS,
  SENDING_TYPE_OPTIONS, LIVENESS_MODE_OPTIONS,
  FRAME_PROCESSING_MODE_OPTIONS,
  STREAM_STATUS_OPTIONS,
} from '../constants';

const parseROI = (value) => {
  if (value.mode) {
    return value;
  }
  if (Array.isArray(value) && value.length === 4) {
    return {
      x: value[0],
      y: value[1],
      width: value[2],
      height: value[3],
      mode: 'abs',
    };
  }
  return undefined;
};

export const readStream = (client) => new Schema({
  stream_id: get('stream_id').asString(),
  account_id: get('account_id').asString(),
  name: get('name').asString(),
  description: get('description').asString(),
  status: get('status').asOption(STREAM_STATUS_OPTIONS),
  groups: get('groups').asArrayOfOptions(),
  group_name: get('groups').as((groups) => groups?.[0]).asOption(),
  data: {
    type: get('data.type').asOption(STREAM_TYPE_OPTIONS),
    reference: get('data.reference').asString(),
    roi: get('data.roi').as(parseROI),
    droi: get('data.droi').as(parseROI),
    mask: get('data.mask').asString(),
    rotation: get('data.rotation').asOption(ROTATION_OPTIONS),
    preferred_program_stream_frame_width: get('data.preferred_program_stream_frame_width').asInt(),
    endless: get('data.endless').asBoolean(),
  },
  event_handler: {
    origin: get('event_handler.origin').asString(),
    api_version: get('event_handler.api_version').asInt(),
    bestshot_handler: {
      handler_id: get('event_handler.bestshot_handler.handler_id').asString(),
    },
    detection_handler: {
      handler_id: get('event_handler.detection_handler.handler_id').asString(),
    },
    frame_store: get('event_handler.frame_store').asString(),
  },
  policies: {
    sending: {
      time_period_of_searching: get('policies.sending.time_period_of_searching').asInt(),
      silent_period: get('policies.sending.silent_period').asInt(),
      type: get('policies.sending.type').asOption(SENDING_TYPE_OPTIONS),
      number_of_bestshots_to_send: get('policies.sending.number_of_bestshots_to_send').asInt(),
      send_only_full_set: get('policies.sending.send_only_full_set').asBoolean(),
      delete_track_after_sending: get('policies.sending.delete_track_after_sending').asBoolean(),
    },
    primary_track_policy: {
      use_primary_track_policy: get('policies.primary_track_policy.use_primary_track_policy').asBoolean(),
      best_shot_min_size: get('policies.primary_track_policy.best_shot_min_size').asInt(),
      best_shot_proper_size: get('policies.primary_track_policy.best_shot_proper_size').asInt(),
    },
    liveness: {
      use_mask_liveness_filtration: get('policies.liveness.use_mask_liveness_filtration').asBoolean(),
      use_flying_faces_liveness_filtration: get('policies.liveness.use_flying_faces_liveness_filtration').asBoolean(),
      liveness_mode: get('policies.liveness.liveness_mode').asOption(LIVENESS_MODE_OPTIONS),
      number_of_liveness_checks: get('policies.liveness.number_of_liveness_checks').asInt(),
      liveness_threshold: get('policies.liveness.liveness_threshold').asFloat(),
      livenesses_weights: get('policies.liveness.livenesses_weights').asArrayOfFloat().asString(),
      mask_backgrounds_count: get('policies.liveness.mask_backgrounds_count').asInt(),
    },
    filtering: {
      min_score: get('policies.filtering.min_score').asFloat(),
      detection_yaw_threshold: get('policies.filtering.detection_yaw_threshold').asFloat(),
      detection_pitch_threshold: get('policies.filtering.detection_pitch_threshold').asFloat(),
      detection_roll_threshold: get('policies.filtering.detection_roll_threshold').asFloat(),
      yaw_number: get('policies.filtering.yaw_number').asInt(),
      yaw_collection_mode: get('policies.filtering.yaw_collection_mode').asBoolean(),
      mouth_occlusion_threshold: get('policies.filtering.mouth_occlusion_threshold').asFloat(),
      min_body_size_threshold: get('policies.filtering.min_body_size_threshold').asFloat(),
    },
    frame_processing_mode: get('policies.frame_processing_mode').asOption(FRAME_PROCESSING_MODE_OPTIONS),
    real_time_mode_fps: get('policies.real_time_mode_fps').asInt(),
    ffmpeg_threads_number: get('policies.ffmpeg_threads_number').asInt(),
    healthcheck: {
      max_error_count: get('policies.healthcheck.max_error_count').asInt(),
      period: get('policies.healthcheck.period').asInt(),
      retry_delay: get('policies.healthcheck.retry_delay').asInt(),
      timeout: get('policies.healthcheck.timeout').asInt().ifNaN(undefined),
    },
  },
  location: {
    city: get('location.city').ifNull(''),
    area: get('location.area').ifNull(''),
    district: get('location.district').ifNull(''),
    street: get('location.street').ifNull(''),
    house_number: get('location.house_number').ifNull(''),
    geo_position: {
      longitude: get('location.geo_position.longitude').asFloat(),
      latitude: get('location.geo_position.latitude').asFloat(),
    },
  },
  autorestart: {
    restart: get('autorestart.restart').asBoolean(),
    attempt_count: get('autorestart.attempt_count').asInt(),
    delay: get('autorestart.delay').asInt(),
  },
  preview: {
    live: {
      url: get('preview.live.url'),
      uiUrl: get('stream_id').as((streamId) => client.streams.liveURL(streamId)),
    },
    last_frame: {
      url: get('preview.live.url'),
      uiUrl: get('stream_id').as((streamId) => client.streams.lastFrameURL(streamId)),
    },
  },
  lastFrameURL: get('stream_id').as((streamId) => client.streams.lastFrameURL(streamId)),
  video_info: {
    width: get('video_info.width').asInt().ifNaN(undefined),
    height: get('video_info.height').asInt().ifNaN(undefined),
    frame_rate: get('video_info.frame_rate').asInt().ifNaN(undefined),
    bit_rate: get('video_info.bit_rate').asInt().ifNaN(undefined),
    gop_size: get('video_info.gop_size').asInt().ifNaN(undefined),
    start_time: get('video_info.start_time').asDate(),
    duration: get('video_info.duration').asFloat().ifNaN(undefined),
    progress: get('video_info.progress').asInt().ifNaN(undefined),
  },
});
