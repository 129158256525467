import React, { useEffect } from 'react';

import { optionPropType } from '@vlabs/shared';
import validate from '@vlabs/shared/validators';
import { Control, Grid, GridCol, GridRow, Divider } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useFormContext, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { selectStaticHandlerOptions } from '@vlabs/pages/handlers/selectors';

import { getDefaultValues } from './defaultValues';
import * as sources from './forms';

const StepEstimatorTaskComponent = ({ handlerOptions }) => {
  const { t } = useTranslation();
  const { sourceTypes, control, watch, register, reset, getValues } = useFormContext();
  const { errors } = useFormState();

  const activeSourceType = watch('content.source.source_type')?.value;

  let SourceForm = null;
  // eslint-disable-next-line default-case
  switch (activeSourceType) {
    case sourceTypes.file.value:
      SourceForm = <sources.File />;
      break;
    case sourceTypes.zip.value:
      SourceForm = <sources.ZIP />;
      break;
    case sourceTypes.ftp.value:
      SourceForm = <sources.FTP />;
      break;
    case sourceTypes.network_disk.value:
      SourceForm = <sources.NetworkDisk />;
      break;
    case sourceTypes.s3.value:
      SourceForm = <sources.S3 />;
      break;
    case sourceTypes.samba.value:
      SourceForm = <sources.Samba />;
      break;
  }

  useEffect(() => {
    const values = getValues();
    Object.keys(values.content.source).forEach((key) => {
      if (key === 'source_type') return;
      if (key === activeSourceType) return;
      delete values.content.source[key];
    });
    values.content.source[activeSourceType] = getDefaultValues(sourceTypes).content.source[activeSourceType];
    reset(values);
  }, [activeSourceType, reset, getValues, sourceTypes]);

  return (
    <>
      <h6>{t('настройки задачи')}</h6>

      <Grid>
        <GridRow>
          <GridCol>
            <Control.Select
              autoFocus
              control={control}
              errors={errors}
              id="content.source.source_type"
              label={t('tasks:lp.estimator task.fields.content.source.source_type.label')}
              name="content.source.source_type"
              options={sourceTypes.raw}
              rules={{ required: validate.required() }}
            />
          </GridCol>
        </GridRow>

        {SourceForm}

        <GridRow>
          <GridCol>
            <Control.Input
              id="description"
              label={t('tasks:lp.estimator task.fields.description.label')}
              placeholder={t('tasks:lp.estimator task.fields.description.placeholder')}
              {...register('description', {
                maxLength: validate.maxLength(128),
              })}
              errors={errors}
            />
          </GridCol>
        </GridRow>

        <GridRow>
          <GridCol>
            <Control.Select
              control={control}
              errors={errors}
              id="content.handler.handler_id"
              isCreatable
              label={t('tasks:lp.estimator task.fields.content.handler.handler_id.label')}
              menuPlacement="top"
              name="content.handler.handler_id"
              options={handlerOptions}
              rules={{ required: validate.required(), validate: validate.uuidOptions }}
            />
          </GridCol>
        </GridRow>
      </Grid>
      <Divider small />
    </>
  );
};

StepEstimatorTaskComponent.fieldNames = [
  'description',
  'content.source',
  'content.handler',
];

StepEstimatorTaskComponent.propTypes = {
  handlerOptions: PropTypes.arrayOf(optionPropType),
};

StepEstimatorTaskComponent.defaultProps = {
  handlerOptions: [],
};

export const StepEstimatorTask = connect((state) => ({
  handlerOptions: selectStaticHandlerOptions(state),
}))(StepEstimatorTaskComponent);
