import React from 'react';

import { ChevronLeftIcon, ChevronRightIcon, MoreHorizontalIcon } from '@vlabs/icons';
import PropTypes from 'prop-types';

import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE_OPTIONS } from '../../../constants';
import { PaginationButton } from '../pagination-button/PaginationButton';
import '../Pagination.sass';
import { PaginationPageSizeSelect } from '../PaginationPageSizeSelect';

const MAX_SIBLING_PAGE_COUNT = 2;

const Pagination = ({
  pageIndex,
  pageCount,
  pageSize,
  setPageSize,
  pageSizeOptions,
  canPreviousPage,
  previousPage,
  canNextPage,
  nextPage,
  gotoPage,
  noPageSizeSelect,
}) => {
  const pages = [];
  const startPage = Math.max(pageIndex - MAX_SIBLING_PAGE_COUNT, 0);
  const lastPage = pageCount === -1
    ? pageIndex
    : Math.min(pageIndex + MAX_SIBLING_PAGE_COUNT, pageCount - 1);

  for (let i = startPage; i <= lastPage; i += 1) pages.push({ clickable: true, index: i });
  if (startPage > 1) pages.unshift({ clickable: false, index: 'leftPlaceholder' });
  if (startPage > 0) pages.unshift({ clickable: true, index: 0 });
  if ((pageCount - 1 - lastPage) > 1) pages.push({ clickable: false, index: 'rightPlaceholder' });
  if ((pageCount - 1 - lastPage) > 0) pages.push({ clickable: true, index: pageCount - 1 });

  return (
    <div className="Pagination" data-testid="paginationRoot">
      <PaginationButton
        data-testid="prevPageButton"
        disabled={!canPreviousPage}
        onClick={() => previousPage()}
      >
        <ChevronLeftIcon />
      </PaginationButton>

      {!pages.length && (
        <PaginationButton
          data-testid="pageButton1"
          disabled
        >
          1
        </PaginationButton>
      )}

      {pages.map(({ clickable, index }) => {
        if (clickable) {
          return (
            <PaginationButton
              active={index === pageIndex}
              data-testid={`pageButton${index + 1}`}
              key={index}
              onClick={() => gotoPage(index)}
            >
              {index + 1}
            </PaginationButton>
          );
        }
        return (
          <MoreHorizontalIcon
            className="Pagination__Icon"
            key={index}
          />
        );
      })}

      <PaginationButton
        data-testid="nextPageButton"
        disabled={!canNextPage}
        onClick={() => nextPage()}
      >
        <ChevronRightIcon />
      </PaginationButton>

      {!noPageSizeSelect && (
        <PaginationPageSizeSelect
          onChange={(e) => setPageSize(Number(e.value))}
          options={pageSizeOptions}
          value={pageSize}
        />
      )}
    </div>
  );
};

Pagination.propTypes = {
  canPreviousPage: PropTypes.bool,
  previousPage: PropTypes.func,
  canNextPage: PropTypes.bool,
  nextPage: PropTypes.func,
  pageIndex: PropTypes.number,
  gotoPage: PropTypes.func,
  pageCount: PropTypes.number,
  pageSize: PropTypes.number,
  pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
  setPageSize: PropTypes.func,
  noPageSizeSelect: PropTypes.bool,
};

Pagination.defaultProps = {
  canPreviousPage: false,
  previousPage: undefined,
  canNextPage: false,
  nextPage: undefined,
  pageIndex: 0,
  gotoPage: undefined,
  pageCount: 1,
  pageSize: DEFAULT_PAGE_SIZE,
  pageSizeOptions: DEFAULT_PAGE_SIZE_OPTIONS,
  setPageSize: () => {},
  noPageSizeSelect: false,
};

export { Pagination };
