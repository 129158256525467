import { apiContainer } from '@vlabs/api-bindings';
import { handlerMindigital } from '@vlabs/shared/payloads/handlers';

export async function estimateMindigitalCompliance({ handlerId, file }) {
  if (!file || !handlerId) return undefined;
  const data = await apiContainer.lunaClient.events.emit(
    handlerId, file, { policies: handlerMindigital.policies });
  if (data.events?.length > 0) return data.events[0];
  return data;
}
