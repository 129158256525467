import React from 'react';

import PropTypes from 'prop-types';

import './Footer.sass';

const Footer = ({
  children,
  ...restProps
}) => {
  return (
    <div className="Footer" {...restProps}>
      <div className="Footer__Text Small-1">
        {children}
      </div>
    </div>
  );
};

Footer.propTypes = {
  children: PropTypes.node,
};

Footer.defaultProps = {
  children: undefined,
};

export { Footer };
