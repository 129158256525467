import React from 'react';

import PropTypes from 'prop-types';

import { Breadcrumb, BreadcrumbPropType } from './Breadcrumb';
import './Breadcrumbs.sass';

const Breadcrumbs = ({
  breadcrumbs,
}) => {
  return (
    <div className="Breadcrumbs Small-1">
      {breadcrumbs && breadcrumbs.map((crumb, i) => (
        <React.Fragment key={crumb.caption}>
          <Breadcrumb {...crumb} />

          {i + 1 < breadcrumbs.length && (
            <span className="Breadcrumbs__Separator" />
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

Breadcrumbs.propTypes = {
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape(BreadcrumbPropType),
  ),
};

Breadcrumbs.defaultProps = {
  breadcrumbs: [],
};

export {
  Breadcrumbs,
};
