import React from 'react';

import PropTypes from 'prop-types';

import { optionsProxy } from '../../utils/optionsProxy';
import { Select } from '../controls/select/Select';

const PaginationPageSizeSelect = ({
  options,
  value,
  onChange,
}) => {
  const $options = optionsProxy.create(options.map((size) => ({
    value: size,
    label: String(size),
  })));

  return (
    <div data-testid="pageSizeOptions" style={{ width: 100 }}>
      <Select
        inputStyle="implicit"
        isSearchable={false}
        menuPlacement="auto"
        onChange={onChange}
        options={$options.raw}
        value={$options[value]}
      />
    </div>
  );
};

PaginationPageSizeSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.number).isRequired,
  value: PropTypes.number,
  onChange: PropTypes.func,
};

PaginationPageSizeSelect.defaultProps = {
  value: undefined,
  onChange: undefined,
};

export { PaginationPageSizeSelect };
