import React, { memo } from 'react';

import { IconInner } from '../../IconInner';

const SquareCheckIconInner = (props) => (
  <IconInner name="SquareCheckIcon" viewBox="0 0 24 24" {...props}>
    <path d="m9 11 3 3L22 4" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
    <path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
  </IconInner>
);

export const SquareCheckIcon = memo(SquareCheckIconInner);
