import React from 'react';

import {
  GENDER_OPTIONS,
  EMOTION_OPTIONS,
  MASK_OPTIONS,
  AGE_OPTIONS,
} from '@vlabs/api-bindings/src/constants';
import { EVENT_ENUMS } from '@vlabs/api-bindings/src/luna-client/constants';
import { optionPropType } from '@vlabs/shared';
import { START_OF_DAY, END_OF_DAY } from '@vlabs/shared/config';
import SelectOptionGroupLabel from '@vlabs/shared/legacy-components/select-option-group-label/SelectOptionGroupLabel';
import { selectIsAppFeatureAvailable } from '@vlabs/shared/selectors/appSelectors';
import validate from '@vlabs/shared/validators';
import { Grid, GridCol, GridRow, Divider, Control } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { selectStaticHandlerOptions } from '@vlabs/pages/handlers/selectors';
import { selectSourceOptionsByAccount } from '@vlabs/pages/sources/selectors';

import './SearchByEvents.sass';

function SearchByEvents({
  filterName,
  camOptions,
  isBodyLicenseAvailable,
  handlerOptions,
}) {
  const { t } = useTranslation();
  const { watch, register, control, formState: { errors } } = useFormContext();
  const prefix = filterName ? `${filterName}.` : '';

  const similarityGte = Number(watch(`${prefix}topSimilarObjectSimilarityGte`)) || 0;

  const longitude = watch(`${prefix}originLongitude`);
  const longitudelDelta = watch(`${prefix}longitudeDelta`);

  const latitude = watch(`${prefix}originLatitude`);
  const latitudeDelta = watch(`${prefix}latitudeDelta`);

  return (
    <Grid>
      <GridRow>
        <GridCol>
          <Control.Select
            control={control}
            formatGroupLabel={SelectOptionGroupLabel}
            id="sources"
            isMulti
            label={t('source')}
            name={`${prefix}sources`}
            options={camOptions}
          />
          <Divider small />

          <Control.Input
            id="userData"
            label={t('пользовательские данные')}
            {...register(`${prefix}userData`, { maxLength: validate.maxLength(128) })}
            errors={errors}
          />
          <Divider small />

          <Control.Select
            control={control}
            id="age"
            isClearable
            label={t('age')}
            name={`${prefix}age`}
            options={AGE_OPTIONS.raw}
          />
          <Divider small />

          <Control.Select
            control={control}
            id="gender"
            isClearable
            label={t('gender')}
            name={`${prefix}gender`}
            options={GENDER_OPTIONS.raw}
          />
          <Divider small />

          <Control.Select
            control={control}
            id="emotions"
            isMulti
            label={t('эмоции')}
            name={`${prefix}emotions`}
            options={EMOTION_OPTIONS.raw}
          />
          <Divider small />

          <Control.Select
            control={control}
            id="masks"
            isClearable
            isMulti
            label={t('маска')}
            name={`${prefix}masks`}
            options={MASK_OPTIONS.raw}
          />
          <Divider small />

          <Control.DateInput
            control={control}
            enableTime
            id="createTimeGte"
            label={t('дата создания от')}
            name={`${prefix}createTimeGte`}
            options={START_OF_DAY}
          />
          <Divider small />

          <Control.DateInput
            control={control}
            enableTime
            id="createTimeLt"
            label={t('дата создания до')}
            name={`${prefix}createTimeLt`}
            options={END_OF_DAY}
          />
        </GridCol>

        <GridCol>
          <Control.Input
            errors={errors}
            id="eventIds"
            label={t('идентификаторы событий через запятую')}
            placeholder={t('идентификатор')}
            {...register(`${prefix}eventIds`, { validate: validate.multipleUuid() })}
          />
          <Divider small />

          <Control.Input
            id="externalIds"
            label={t('внешние идентификаторы событий через запятую')}
            placeholder={t('идентификатор')}
            {...register(`${prefix}externalIds`, {
              validate: validate.multipleExternalId() })
            }
            errors={errors}
          />
          <Divider small />

          <Control.Input
            errors={errors}
            id="faceIds"
            label={t('идентификаторы лиц через запятую')}
            placeholder={t('идентификатор')}
            {...register(`${prefix}faceIds`, { validate: validate.multipleUuid() })}
          />
          <Divider small />

          <div className="SearchByEvents__AgeFilter">
            <div>
              <Control.Input
                id="topSimilarObjectSimilarityGte"
                label={t('similarity')}
                placeholder={t('from')}
                {...register(`${prefix}topSimilarObjectSimilarityGte`, {
                  min: validate.gte(0),
                  max: validate.lte(1),
                  validate: validate.number(),
                })}
                errors={errors}
              />
            </div>
            <div className="AgeFilterDivider">:</div>
            <div>
              <Control.Input
                placeholder={t('to')}
                {...register(`${prefix}topSimilarObjectSimilarityLt`, {
                  min: validate.gte(Math.max(similarityGte, 0)),
                  max: validate.lte(1),
                  validate: validate.number(),
                })}
                errors={errors}
              />
            </div>
          </div>

          <Divider small />

          <Control.Select
            control={control}
            errors={errors}
            id="handlers"
            isClearable
            isCreatable
            isMulti
            label={t('сценарии')}
            name={`${prefix}handlers`}
            options={handlerOptions}
            rules={{ validate: validate.uuidOptions }}
          />
          <Divider small />

          <Control.Input
            id="trackIds"
            label={t('идентификаторы треков через запятую')}
            placeholder={t('идентификатор')}
            {...register(`${prefix}trackIds`, {
              validate: validate.multipleTrackIds(),
            })}
            errors={errors}
          />
          <Divider small />

          <Control.Input
            id="tags"
            label={t('теги через запятую')}
            {...register(`${prefix}tags`)}
          />
        </GridCol>

        {isBodyLicenseAvailable && (
          <GridCol>
            <Control.Select
              control={control}
              id="apparent_age"
              isClearable
              label={t('events:body basic attributes.apparent age')}
              name={`${prefix}apparent_age`}
              options={AGE_OPTIONS.raw}
            />
            <Divider small />

            <Control.Select
              control={control}
              id="apparent_gender"
              isClearable
              isMulti
              label={t('events:body basic attributes.apparent gender')}
              name={`${prefix}apparent_gender`}
              options={EVENT_ENUMS.BODY_BASIC_ATTRIBUTES.APPARENT_GENDER.raw}
            />
            <Divider small />

            <Control.Select
              control={control}
              id="upper_clothing_colors"
              isClearable
              isMulti
              label={t('events:upper_clothing_colors')}
              name={`${prefix}upper_clothing_colors`}
              options={EVENT_ENUMS.UPPER_BODY.UPPER_CLOSING_COLOR.raw}
            />
            <Divider small />

            <Control.Select
              control={control}
              id="sleeve_lengths"
              isClearable
              isMulti
              label={t('events:sleeve')}
              name={`${prefix}sleeve_lengths`}
              options={EVENT_ENUMS.UPPER_BODY.SLEEVE.raw}
            />
            <Divider small />

            <Control.Select
              control={control}
              id="headwear_states"
              isClearable
              isMulti
              label={t('events:headwear')}
              name={`${prefix}headwear_states`}
              options={EVENT_ENUMS.UPPER_BODY.HEADWEAR.raw}
            />
            <Divider small />

            <Control.Select
              control={control}
              id="headwear_apparent_colors"
              isMulti
              label={t('events:headwear_colors')}
              name={`${prefix}headwear_apparent_colors`}
              options={EVENT_ENUMS.UPPER_BODY.HEADWEAR_COLORS.raw}
            />
            <Divider small />

            <Control.Select
              control={control}
              id="backpack_states"
              isClearable
              isMulti
              label={t('events:backpack')}
              name={`${prefix}backpack_states`}
              options={EVENT_ENUMS.ACCESSORIES.BACKPACK.raw}
            />
            <Divider small />

            <Control.Select
              control={control}
              id="lower_garment_colors"
              isMulti
              label={t('events:bottom_colors')}
              name={`${prefix}lower_garment_colors`}
              options={EVENT_ENUMS.LOWER_BODY.LOWER_GARMENT.COLORS.raw}
            />
            <Divider small />

            <Control.Select
              control={control}
              id="lower_garment_types"
              isMulti
              label={t('events:bottom_type')}
              name={`${prefix}lower_garment_types`}
              options={EVENT_ENUMS.LOWER_BODY.LOWER_GARMENT.TYPE.raw}
            />
            <Divider small />

            <Control.Select
              control={control}
              id="shoes_apparent_colors"
              isMulti
              label={t('events:shoes_colors')}
              name={`${prefix}shoes_apparent_colors`}
              options={EVENT_ENUMS.LOWER_BODY.SHOES.APPARENT_COLOR.raw}
            />
          </GridCol>
        )}
      </GridRow>

      <div className="GridTense">
        <GridRow>
          <GridCol>
            <div className="CandidateFilters__Subtitle">{t('location')}</div>
          </GridCol>
        </GridRow>

        <GridRow>
          <GridCol>
            <Control.Input
              id="districts"
              label={t('геопозиция.район')}
              {...register(`${prefix}districts`, {
                maxLength: validate.maxLength(36),
              })}
              errors={errors}
            />
          </GridCol>

          <GridCol>
            <Control.Input
              id="areas"
              label={t('геопозиция.область')}
              {...register(`${prefix}areas`, {
                maxLength: validate.maxLength(36),
              })}
              errors={errors}
            />
          </GridCol>
        </GridRow>

        <GridRow>
          <GridCol>
            <Control.Input
              id="cities"
              label={t('геопозиция.город')}
              {...register(`${prefix}cities`, {
                maxLength: validate.maxLength(36),
              })}
              errors={errors}
            />
          </GridCol>
          <GridCol>
            <Control.Input
              id="streets"
              label={t('геопозиция.улица')}
              {...register(`${prefix}streets`, {
                maxLength: validate.maxLength(36),
              })}
              errors={errors}
            />
          </GridCol>
          <GridCol cols={2}>
            <Control.Input
              id="houseNumbers"
              label={t('геопозиция.номер дома')}
              {...register(`${prefix}houseNumbers`, {
                maxLength: validate.maxLength(36),
              })}
              errors={errors}
            />
          </GridCol>
        </GridRow>

        <GridRow>
          <GridCol>
            <div className="CandidateFilters__Flex">
              <div className="CandidateFilters__Coord">
                <Control.Input
                  id="originLongitude"
                  label={t('долгота (-180...180)')}
                  {...register(`${prefix}originLongitude`, {
                    min: validate.gte(-180),
                    max: validate.lte(180),
                    validate: {
                      number: validate.number(),
                      validateLatitude: (value) => {
                        if (!value && latitude) {
                          return validate.required();
                        }
                        return true;
                      },
                      validateDelta: (value) => {
                        if (!value && longitudelDelta) {
                          return validate.required();
                        }
                        return true;
                      },
                    } })}
                  errors={errors}
                />
              </div>
              <Divider small />
              <div className="CandidateFilters__CoordDelta">
                <Control.Input
                  id="longitudeDelta"
                  label={t('погрешность (0...90)')}
                  {...register(`${prefix}longitudeDelta`, {
                    min: validate.gte(0),
                    max: validate.lte(90),
                    validate: validate.number(),
                  })}
                  errors={errors}
                />
              </div>
            </div>
          </GridCol>

          <GridCol>
            <div className="CandidateFilters__Flex">
              <div className="CandidateFilters__Coord">
                <Control.Input
                  id="originLatitude"
                  label={t('широта (-90...90)')}
                  {...register(`${prefix}originLatitude`, {
                    min: validate.gte(-90),
                    max: validate.lte(90),
                    validate: {
                      number: validate.number(),
                      validateLongitude: (value) => {
                        if (!value && longitude) {
                          return validate.required();
                        }
                        return true;
                      },
                      validateDelta: (value) => {
                        if (!value && latitudeDelta) {
                          return validate.required();
                        }
                        return true;
                      },
                    } })}
                  errors={errors}
                />
              </div>
              <Divider small />
              <div className="CandidateFilters__CoordDelta">
                <Control.Input
                  id="latitudeDelta"
                  label={t('погрешность (0...90)')}
                  {...register(`${prefix}latitudeDelta`, {
                    min: validate.gte(0),
                    max: validate.lte(90),
                    validate: validate.number(),
                  })}
                  errors={errors}
                />
              </div>
            </div>
          </GridCol>
        </GridRow>
      </div>
    </Grid>
  );
}

SearchByEvents.propTypes = {
  filterName: PropTypes.string,
  camOptions: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })),
  handlerOptions: PropTypes.arrayOf(optionPropType),
  isBodyLicenseAvailable: PropTypes.bool,
};

SearchByEvents.defaultProps = {
  filterName: '',
  camOptions: [],
  handlerOptions: [],
  isBodyLicenseAvailable: false,
};

export default connect((state) => ({
  camOptions: selectSourceOptionsByAccount(state),
  handlerOptions: selectStaticHandlerOptions(state),
  isBodyLicenseAvailable: selectIsAppFeatureAvailable(state, 'estimations.body_attributes'),
}))(SearchByEvents);
