import React, { useCallback } from 'react';

import { Fold, SettingsItemWrapper, Control } from '@vlabs/uikit';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useUpdateField, useClearPolicyFilters } from '../hooks';
import { FaceToListPolicySubForm } from './FaceToListPolicySubForm';
import GenericPolicySubForm from './GenericPolicySubForm';

const FacePolicySubform = () => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const facePolicyIsEnabled = useWatch({ name: 'facePolicy.isEnabled' });

  const onDisableFacePolicyCallback = useCallback(() => facePolicyIsEnabled === false, [facePolicyIsEnabled]);
  useClearPolicyFilters('facePolicy', { on: onDisableFacePolicyCallback });
  useUpdateField({ on: onDisableFacePolicyCallback, path: 'faceToListPolicy.isEnabled', value: false });

  return (
    <Fold
      control={control}
      isOpen={facePolicyIsEnabled}
      name="facePolicy.isEnabled"
      switch
      testId="facePolicy"
      title={t('handlers:setup sections.save face in database')}
    >
      <GenericPolicySubForm
        fieldName="facePolicy.filters"
        matchTitle={t('handlers:сохранять лицо в тех случаях, если лицо было найдено')}
      />

      <SettingsItemWrapper
        test-id="faceToListPolicy"
        title={t('handlers:прикреплять лицо к списку')}
      >
        <Control.Switch
          control={control}
          name="faceToListPolicy.isEnabled"
        />
      </SettingsItemWrapper>

      <FaceToListPolicySubForm />

    </Fold>
  );
};

export default FacePolicySubform;
