import { createSelector } from '@reduxjs/toolkit';
import { roles } from '@vlabs/shared/config';

import { selectAccountModel, selectAccountRole } from '@vlabs/pages/auth/selectors';

export const selectDepartmentOptions = (state) => state.departments.departmentOptions;
export const selectDepartments = (state) => state.departments || {};

export const selectDepartmentsData = (state) => state.departments.data;
export const selectDepartmentsPageData = (state) => ({
  data: state.departments.pageData,
  pageIndex: state.departments.pageIndex,
  pageSize: state.departments.pageSize,
  filters: state.departments.filters,
  pageSizeOptions: state.departments.pageSizeOptions,
});
export const selectDepartmentsById = (state) => state.departments.byId;

export const selectAccountDepartments = createSelector(
  selectDepartments,
  selectAccountModel,
  selectAccountRole,
  ({ data: departments }, account, role) => {
    if (!account) return [];

    if (role === roles.ADMIN) {
      return departments || [];
    }

    return departments?.filter((department) => department.accountIds.includes(account.id)) || [];
  },
);
export const selectAccountDepartmentOptions = createSelector(
  selectAccountDepartments,
  selectDepartmentOptions,
  (departments, options) => {
    const departmentIds = departments.map(({ departmentId }) => departmentId);
    return options.filter(({ value }) => departmentIds.includes(value));
  },
);
