import React from 'react';

import PropTypes from 'prop-types';

import { Checkbox } from '../../controls/checkbox/Checkbox';

const SelectAllRowsCell = ({
  getToggleAllRowsSelectedProps,
}) => {
  return (
    <Checkbox {...getToggleAllRowsSelectedProps()} />
  );
};

SelectAllRowsCell.propTypes = ({
  getToggleAllRowsSelectedProps: PropTypes.func,
});

SelectAllRowsCell.defaultProps = ({
  getToggleAllRowsSelectedProps: undefined,
});

export { SelectAllRowsCell };
