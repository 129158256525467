import React from 'react';

import validate from '@vlabs/shared/validators';
import {
  Page, Control, Divider,
} from '@vlabs/uikit';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import st from './Departments.module.sass';

function DepartmentAccountForm({
  loadAccountOptions = undefined,
  onSubmit = () => {},
}) {
  const { t } = useTranslation();
  const formMethods = useForm();

  const {
    control,
    handleSubmit,
    formState: {
      errors,
    } } = formMethods;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Page
        className={st.FormWidth}
        title={t('departments:user.form.title')}
      >
        <Divider />

        <Control.Select
          control={control}
          errors={errors}
          id="accountId"
          isAsync
          label={t('departments:user.form.user name')}
          loadOptions={loadAccountOptions}
          name="accountId"
          rules={{ required: validate.required() }}
        />
        <Divider small />

        <Control.Button fullWidth type="submit">
          {t('форма.submit')}
        </Control.Button>
      </Page>
    </form>
  );
}

export default DepartmentAccountForm;
