/* eslint-disable import/prefer-default-export */
import { formatISO } from 'date-fns';

export const toApi = ({ handlerId, cameras, listsId, mask, dateEnd, dateStart, minMark, email }, typeReport) => {
  const localDateEnd = formatISO(new Date(dateEnd));
  const localDateStart = formatISO(new Date(dateStart));

  return ({
    typeReport,
    handlerId: handlerId.value,
    cameras: cameras.map(({ label }) => label),
    listsId: listsId.map(({ value }) => value),
    mask: mask?.value || undefined,
    dateStart: localDateStart,
    dateEnd: localDateEnd,
    minMark: Number(minMark) || undefined,
    author: email,
  });
};
