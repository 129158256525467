import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import { BreadcrumbArrayPropType } from '../breadcrumbs/Breadcrumb';
import { PageHeader } from './PageHeader';

import './Page.sass';

const Page = ({
  title,
  breadcrumbs,
  children,
  actions,
  onBackButtonClick,
  className,
  oneScreenSize,
}) => {
  return (
    <div className={cn(
      className,
      {
        Page: true,
        Page_oneScreenSize: oneScreenSize,
      },
    )}
    >
      {(title || breadcrumbs || actions || onBackButtonClick) && (
        <PageHeader
          actions={actions}
          breadcrumbs={breadcrumbs}
          onBackButtonClick={onBackButtonClick}
          title={title}
        />
      )}

      {children}
    </div>
  );
};

Page.propTypes = {
  breadcrumbs: BreadcrumbArrayPropType,
  title: PropTypes.string,
  children: PropTypes.node,
  actions: PropTypes.node,
  className: PropTypes.string,
  onBackButtonClick: PropTypes.func,
  oneScreenSize: PropTypes.bool,
};

Page.defaultProps = {
  breadcrumbs: undefined,
  title: undefined,
  children: [],
  actions: undefined,
  className: undefined,
  onBackButtonClick: undefined,
  oneScreenSize: false,
};

export { Page };
