import React from 'react';

import { optionPropType } from '@vlabs/shared';
import { selectListOptions } from '@vlabs/shared/selectors/listSelectors';
import validate from '@vlabs/shared/validators';
import { Control, Divider } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useFormContext, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

const StepGarbageCollectionComponent = ({ listOptions }) => {
  const { t } = useTranslation();
  const { defaultListOption, control, register } = useFormContext();
  const { errors } = useFormState();
  return (
    <>
      <h6>{t('настройки задачи')}</h6>
      <Divider small />

      <Control.Input
        id="description"
        label={t('tasks:GCTask.форма.description.label')}
        {...register('description', {
          maxLength: validate.maxLength(128),
        })}
        autoFocus
        errors={errors}
      />
      <Divider small />

      <Control.Checkbox
        errors={errors}
        id="content.store_results"
        label={t('tasks:GCTask.форма.content.store_results.label')}
        {...register('content.store_results')}
      />
      <Divider small />

      <Control.Checkbox
        errors={errors}
        id="content.remove_samples"
        label={t('tasks:GCTask.форма.content.remove_samples.label')}
        {...register('content.remove_samples')}
      />
      <Divider small />

      <Control.Select
        control={control}
        disabled={defaultListOption}
        errors={errors}
        id="content.filters.list_id"
        isCreatable
        label={t('tasks:GCTask.форма.content.filters.list_id.label')}
        name="content.filters.list_id"
        options={listOptions}
        rules={{ required: validate.required(), validate: validate.uuidOptions }}
      />
      <Divider small />

      <Control.Input
        id="content.filters.user_data"
        label={t('tasks:GCTask.форма.content.filters.user_data.label')}
        {...register('content.filters.user_data', {
          maxLength: validate.maxLength(128),
        })}
        errors={errors}
      />
      <Divider small />

      <Control.DateInput
        control={control}
        data-testid="create_time__gte"
        enableTime
        label={t('tasks:GCTask.форма.content.filters.create_time__gte.label')}
        name="content.filters.create_time__gte"
      />
      <Divider small />

      <Control.DateInput
        control={control}
        data-testid="create_time__lt"
        enableTime
        label={t('tasks:GCTask.форма.content.filters.create_time__lt.label')}
        name="content.filters.create_time__lt"
      />
      <Divider small />
    </>
  );
};

StepGarbageCollectionComponent.fieldNames = [
  'description',
  'content.filters',
];

StepGarbageCollectionComponent.propTypes = {
  listOptions: PropTypes.arrayOf(optionPropType),
};

StepGarbageCollectionComponent.defaultProps = {
  listOptions: [],
};

export const StepGarbageCollection = connect((state) => ({
  listOptions: selectListOptions(state),
}))(StepGarbageCollectionComponent);
