import React, { useCallback } from 'react';

import { apiContainer } from '@vlabs/api-bindings';
import { TASKS_TYPE_OPTIONS } from '@vlabs/api-bindings/src/constants';
import { TableCellsWithPermissions } from '@vlabs/shared/components/table/cells/index';
import { permissions } from '@vlabs/shared/config';
import DownloadButton from '@vlabs/shared/legacy-components/download-button/DownloadButton';
import { selectAppState } from '@vlabs/shared/selectors/appSelectors';
import { actionColumnProps } from '@vlabs/shared/utils';
import {
  ExporterTaskWidget, GarbageCollectionWidget, EstimatorTaskWidget,
  PackageImportWidget, CrossMatchWidget, PackageIdentificationWidget,
} from '@vlabs/shared/widgets/tasks';
import { TableCells, Table, openConfirmPopup, useTableHook, Group } from '@vlabs/uikit';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import { LPTasksProgressCell, CancelCell } from './components';
import { downloadResultHandler } from './downloadResultHandler';

import './TaskListPage.sass';

function LPTasksTab({ fetchTasksCount }) {
  const { t } = useTranslation();

  const onFetch = async ({ page, pageSize }) => {
    fetchTasksCount();
    const { data: { tasks } } = await apiContainer.lunaClient.tasks.getAll({ page: page + 1, page_size: pageSize });
    return { data: tasks };
  };
  // FIXME: пересмотреть зависимости
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const tableProps = useTableHook(useCallback(onFetch, []), { paginationType: 'offsetBased' });

  const onCancelHandler = (task) => {
    const onConfirm = async () => {
      try {
        await apiContainer.lunaClient.tasks.cancel(task.task_id);
        tableProps.onFetch();
        toast.success(t('tasks:подтверждение.остановка задачи.успех'));
      } catch (e) {
        if (e?.error_code === 28019) {
          toast.error(t('tasks:подтверждение.остановка задачи.ошибка'));
          return;
        }
        throw e;
      }
    };

    openConfirmPopup({
      title: t('tasks:подтверждение.остановка задачи.title'),
      message: t('tasks:подтверждение.остановка задачи.message', { task }),
      type: 'warning',
      onConfirm,
    });
  };

  const onDeleteHandler = (task) => {
    const onConfirm = async () => {
      await apiContainer.lunaClient.tasks.delete(task.task_id);
      tableProps.onFetch();
      toast.success(t('tasks:подтверждение.удаление.успех'));
    };
    openConfirmPopup({
      title: t('tasks:подтверждение.удаление.title'),
      message: t('tasks:подтверждение.удаление.message', { task }),
      type: 'delete',
      onConfirm,
    });
  };

  const columns = [
    { Header: t('tasks:table.columns.task_id'), accessor: 'task_id', width: 50 },
    { Header: t('tasks:table.columns.description'),
      accessor: ({ description }) => {
        const descriptionEmail = description.split(' ')[0];

        if (descriptionEmail === 'undefined') {
          return description.split(' ')[1];
        }

        return description;
      } },
    {
      Header: t('tasks:table.columns.type'),
      accessor: ({ description, task_type }) => {
        const typeLabel = description.split(' ')[1];
        if (typeLabel === 'packageIdentification') {
          return t('tasks:пакетная идентификация');
        }
        if (typeLabel === 'packageImport') {
          return t('tasks:пакетный импорт');
        }
        return TASKS_TYPE_OPTIONS[task_type]?.label || task_type;
      },
    },
    { Header: t('tasks:table.columns.create_time'), accessor: 'create_time', Cell: TableCells.DateCell },
    { Header: t('tasks:table.columns.end_time'), accessor: 'end_time', Cell: TableCells.DateCell },
    {
      Header: t('tasks:table.columns.status'),
      accessor: 'task_status',
      Cell: ({ value }) => LPTasksProgressCell(value),
      width: 60,
    },
    actionColumnProps({ id: 'actionCancel', Cell: ({ row }) => CancelCell({ onCancelHandler, row }) }),
    actionColumnProps({
      id: 'actionDownload',
      Cell: ({ row }) => (
        row.original.task_status === 5 && DownloadButton({ onClick: () => downloadResultHandler(row.original) })
      ),
    }),
    actionColumnProps({ id: 'actionDelete', Cell: TableCellsWithPermissions.DeleteCell }),
  ];

  const afterSubmit = () => tableProps.onFetch();

  return (
    <>
      <Group className="TaskList__Buttons">
        <CrossMatchWidget afterSubmit={afterSubmit} />
        <ExporterTaskWidget afterSubmit={afterSubmit} objectType="faces" />
        <ExporterTaskWidget afterSubmit={afterSubmit} objectType="events" />
        <EstimatorTaskWidget afterSubmit={afterSubmit} />
        <PackageImportWidget afterSubmit={afterSubmit} />
        <PackageIdentificationWidget afterSubmit={afterSubmit} />
        <GarbageCollectionWidget afterSubmit={afterSubmit} />
      </Group>

      <Table
        {...tableProps}
        columns={columns}
        onActions={{
          onDeleteRow: { handler: onDeleteHandler, permissions: { rules: [permissions.task.deletion] } },
        }}
        rowProps={({ original }) => ({ 'data-task-id': original.task_id })}
      />
    </>
  );
}

export default connect((state) => ({
  appState: selectAppState(state),
}))(LPTasksTab);
