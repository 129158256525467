import React from 'react';

import { TASKS } from '@vlabs/api-bindings/src/luna-client/constants';
import validate from '@vlabs/shared/validators';
import { Control, GridCol, GridRow, SettingsItemWrapper } from '@vlabs/uikit';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

function NetworkDisk() {
  const { t } = useTranslation();
  const { register, formState: { errors }, control } = useFormContext();

  return (
    <>
      <GridRow>
        <GridCol>
          <Control.Input
            id="content.source.network_disk.reference.path"
            label={t('tasks:lp.estimator task.fields.content.source.network_disk.reference.path.label')}
            placeholder={t('tasks:lp.estimator task.fields.content.source.network_disk.reference.path.placeholder')}
            {...register('content.source.network_disk.reference.path', {
              required: validate.required(),
              validate: {
                url: validate.url(),
              },
            })}
            errors={errors}
          />
        </GridCol>
      </GridRow>

      <GridRow>
        <GridCol>
          <Control.Input
            id="content.source.network_disk.reference.prefix"
            label={t('tasks:lp.estimator task.fields.content.source.network_disk.reference.prefix.label')}
            placeholder={t('tasks:lp.estimator task.fields.content.source.network_disk.reference.prefix.placeholder')}
            {...register('content.source.network_disk.reference.prefix')}
            errors={errors}
          />
        </GridCol>
        <GridCol>
          <Control.Input
            id="content.source.network_disk.reference.postfix"
            label={t('tasks:lp.estimator task.fields.content.source.network_disk.reference.postfix.label')}
            placeholder={t('tasks:lp.estimator task.fields.content.source.network_disk.reference.postfix.placeholder')}
            {...register('content.source.network_disk.reference.postfix')}
            errors={errors}
          />
        </GridCol>
      </GridRow>

      <GridRow>
        <GridCol>
          <SettingsItemWrapper title={t('tasks:lp.estimator task.fields.content.source.network_disk.reference.follow_links.label')}>
            <Control.Switch
              control={control}
              name="content.source.network_disk.reference.follow_links"
            />
          </SettingsItemWrapper>
        </GridCol>
      </GridRow>

      <GridRow>
        <GridCol>
          <SettingsItemWrapper title={t('tasks:lp.estimator task.fields.content.source.network_disk.recursive.label')}>
            <Control.Switch
              control={control}
              name="content.source.network_disk.recursive"
            />
          </SettingsItemWrapper>
        </GridCol>
      </GridRow>

      <GridRow>
        <GridCol>
          <Control.Select
            control={control}
            errors={errors}
            id="content.source.network_disk.image_type"
            label={t('tasks:lp.estimator task.fields.content.source.network_disk.image_type.label')}
            name="content.source.network_disk.image_type"
            options={TASKS.ESTIMATOR.SOURCE.IMAGE_TYPE.raw}
          />
        </GridCol>
      </GridRow>
    </>
  );
}

export { NetworkDisk };
