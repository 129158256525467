import React, { useContext } from 'react';

import { FaceAction } from '@vlabs/shared/components/action-button-groups/FaceAction';
import { SampleWithActions } from '@vlabs/shared/components/sample/SampleWithActions';
import { GoToCell } from '@vlabs/shared/components/table';
import FaceInfoCell from '@vlabs/shared/legacy-components/face-info-cell/FaceInfoCell';
import { Table } from '@vlabs/uikit';

import { SearchContext } from '../search-context';

import './Preview.sass';

const FacePreviewCell = ({ value }) => (
  <SampleWithActions
    actions={(
      <FaceAction imageUrl={value} />
    )}
    data-testid="PreviewImage"
    imageUrl={value}
    type="face"
  />
);

export const FacePreview = () => {
  const buildFaceLink = (matchResult) => {
    return matchResult.face_id ? `/faces/${matchResult.face_id}` : undefined;
  };
  const { referencesPreview, referenceType, setPreviewPageIndex } = useContext(SearchContext);

  const columns = [
    {
      id: 1,
      accessor: 'uiAvatarURL',
      Cell: FacePreviewCell,
      width: 80,
    },
    {
      id: 2,
      Cell: FaceInfoCell,
      width: 135,
    },
    {
      id: 3,
      Cell: GoToCell,
      accessor: buildFaceLink,
      width: 1,
    },
  ];

  return (
    <div
      className="EventPreview__Table_custom"
      data-testid="table.facePreview"
    >
      {referenceType === 'face_external_id' && (
        <Table
          columns={columns}
          data={referencesPreview.data}
          meta={{ count: referencesPreview.count }}
          noHead
          pageIndex={referencesPreview.pageIndex}
          pageSize={2}
          pageSizeOptions={[2]}
          paginationType="offsetBased"
          setPageIndex={setPreviewPageIndex}
        />
      )}
      {referenceType === 'face' && (
        <Table
          columns={columns}
          data={referencesPreview.data}
          noHead
        />
      )}
    </div>
  );
};
