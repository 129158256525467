import React, { memo } from 'react';

import { IconInner } from '../../IconInner';

const CloudOffIconInner = (props) => (
  <IconInner name="CloudOffIcon" viewBox="0 0 24 24" {...props}>
    <g clipPath="url(#a)"><path d="M22.61 16.95A4.999 4.999 0 0 0 18 10h-1.26a8 8 0 0 0-7.05-6M5 5a8 8 0 0 0 4 15h9a5 5 0 0 0 1.7-.3M1 1l22 22" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" /></g>
  </IconInner>
);

export const CloudOffIcon = memo(CloudOffIconInner);
