import React, { forwardRef } from 'react';

import { LockedIcon } from '@vlabs/icons';
import cn from 'classnames';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';
import ReactSwitch from 'react-switch';

import { bundleEventHandlers } from '../../utils/bundleEventHandlers';
import { mergeRefs } from '../../utils/mergeRefs';
import { ErrorMessage } from '../error-message/ErrorMessage';
import { getError, warnLabelWithoutId } from '../utils';

import './Switch.sass';

const Switch = forwardRef(({
  id,
  name,
  label,
  control,
  value,
  defaultValue,
  rules,
  onChange,
  errors,
  hasError,
  errorMessage,
  className,
  disabled,
  title,
  uncheckedIcon = false,
  ...props
}, externalRef) => {
  let controllerRef;
  let controllerOnChange;
  const dynamicProps = {
    onChange,
    checked: !!value,
  };

  let $value = value;

  const error = getError(errors, name);

  if (control) {
    // В данном случае окей вызывать хук по условию, потому что проп control
    // никогда не меняется динамически между рендерами,
    // он либо всегда есть либо его никогда нет
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { field } = useController({
      name,
      control,
      defaultValue,
      rules,
    });

    if ($value === undefined) {
      $value = field.value;
    }

    dynamicProps.onBlur = field.onBlur;
    dynamicProps.checked = $value;
    controllerRef = field.ref;
    controllerOnChange = field.onChange;
  }

  const $onChange = bundleEventHandlers(onChange, controllerOnChange);

  if (label && !id) {
    warnLabelWithoutId(label, name);
  }

  return (
    <>
      <div
        className={cn(
          className,
          {
            Switch: true,
            Switch_on: $value,
            Switch_off: !$value,
            Switch_error: hasError || error,
            Switch_disabled: disabled,
          },
        )}
        data-testid="switchRoot"
      >
        <div className="Switch__Wrapper" title={title}>
          <ReactSwitch
            className="Switch__Input"
            id={id}
            name={name}
            {...dynamicProps}
            activeBoxShadow="0"
            checkedIcon={false}
            disabled={disabled}
            handleDiameter={12}
            height={20}
            onChange={$onChange}
            ref={mergeRefs(externalRef, controllerRef)}
            uncheckedIcon={uncheckedIcon}
            width={36}
            {...props}
          />
        </div>

        {label && (
          <label
            className="Input__Label"
            htmlFor={id}
          >
            {label}
          </label>
        )}
      </div>

      <ErrorMessage error={errorMessage ? { message: errorMessage } : error} />
    </>
  );
});

Switch.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  control: PropTypes.objectOf(PropTypes.any),
  value: PropTypes.any,
  defaultValue: PropTypes.any,
  rules: PropTypes.any,
  onChange: PropTypes.func,
  errors: PropTypes.object,
  hasError: undefined,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  title: PropTypes.string,
  uncheckedIcon: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.node,
  ]),
};

Switch.defaultProps = {
  id: undefined,
  name: undefined,
  label: undefined,
  className: undefined,
  control: undefined,
  value: undefined,
  defaultValue: undefined,
  rules: undefined,
  onChange: undefined,
  errors: undefined,
  hasError: undefined,
  disabled: undefined,
  errorMessage: undefined,
  title: undefined,
  uncheckedIcon: false,
};

Switch.displayName = 'Switch';

export { Switch };
