import React, { memo } from 'react';

import { IconInner } from '../../IconInner';

const CheckIconInner = (props) => (
  <IconInner name="CheckIcon" viewBox="0 0 24 24" {...props}>
    <path d="M20 6 9 17l-5-5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
  </IconInner>
);

export const CheckIcon = memo(CheckIconInner);
