export const routes = {
  app: {
    info: '/app/information',
    documentation: '/app/documentation',
    features: '/app/features',
    plugins: '/app/plugins',
  },
  auth: {
    reset: '/auth/reset/:token',
  },
  handlers: {
    all: '/handlers',
    create: '/handlers/create',
    other: '/handlers/create/other',
    read: '/handlers/:handlerId',
  },
  verifiers: {
    all: '/verifiers',
    create: '/verifiers/create',
    read: '/verifiers/:verifierId',
    verify: '/verifiers/:verifierId/test',
  },
  events: {
    eventDetails: '/events/:eventId',
    latest: '/events',
    archive: '/archive',
  },
  sources: {
    main: '/sources',
    lunaStreams: {
      streams: {
        main: '/sources/luna-streams/streams',
        view: '/sources/luna-streams/streams/:streamId',
        create: '/sources/luna-streams/streams/create',
        edit: '/sources/luna-streams/streams/:streamId/edit',

      },
      groups: {
        main: '/groups',
        view: '/groups/:groupId',
        create: '/groups/create',
        edit: '/groups/:groupId/edit',
      },
      queue: {
        main: '/queue',
      },
    },
    // vlaccess: {},
  },
  search: '/search',
  searchByDocuments: '/search-by-documents',
  accounts: {
    all: '/accounts',
  },
  lists: {
    all: '/lists',
    read: '/lists/:listId',
  },
  tasks: {
    all: '/tasks',
  },
  checks: {
    read: '/checks',
  },
  departments: {
    all: '/departments',
    read: '/departments/:departmentId',
  },
  notifications: {
    all: '/notifications',
    create: '/notifications/create',
    read: '/notifications/:notificationId',
  },
  externalId: {
    read: '/externalId/:externalId',
  },
  faces: {
    all: '/faces',
  },
  thermo: {
    read: '/thermo',
  },
  vaReporter: {
    reports: '/reports',
    plans: {
      validation: '/plans/:planId/calibrations/:calibrationId/validation',
      calibration: '/plans/:planId/calibrations/:calibrationId',
      calibrationList: '/plans/:planId/calibrations',
      listAll: '/plans',
    },
  },
  facePage: '/faces/:faceId',
};
