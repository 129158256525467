import Age18_44 from './age-group_18-44.svg';
import Age45_60 from './age-group_45-60.svg';
import Age60 from './age-group_60+.svg';
import AgeLess18 from './age-group_less-than-18.svg';
import Backpack from './backpack.svg';
import UnknownBackpack from './backpack_unknown-type.svg';
import DeepfakeImageFail from './deepfake-icon-fail.svg';
import DeepfakeImageSuccess from './deepfake-icon-success.svg';
import Anger from './emotion-anger.svg';
import Disgust from './emotion-disgust.svg';
import Fear from './emotion-fear.svg';
import Happiness from './emotion-happiness.svg';
import Neutral from './emotion-neutral.svg';
import Sadness from './emotion-sadness.svg';
import Surprise from './emotion-surprise.svg';
import Men from './gender_men.svg';
import UnknownGender from './gender_unknown-type.svg';
import Women from './gender_women.svg';
import Headwear from './headwear.svg';
import UnknownHeadwear from './headwear_unknown-type.svg';
import LivenessImageFail from './liveness-icon-fail.svg';
import LivenessImageSuccess from './liveness-icon-success.svg';
import LivenessImageUnknown from './liveness-icon-unknown.svg';
import LivenessImage from './liveness-icon.svg';
import Shorts from './lower-body_shorts.svg';
import Skirt from './lower-body_skirt.svg';
import Trousers from './lower-body_trousers.svg';
import UnknownLowerBody from './lower-body_unknown-type.svg';
import Mask from './mask.svg';
import MouthOcclusion from './mask_mouth-occlusion.svg';
import MatchTypeImage from './match-type-icon.svg';
import NoBackpack from './no-backpack.svg';
import NoHeadwear from './no-headwear.svg';
import NoMask from './no-mask.svg';
import Shoes from './shoes.svg';
import Abormal from './temperature_abnormal.svg';
import High from './temperature_high.svg';
import Normal from './temperature_normal.svg';
import LongSleeve from './upper-body_long-sleeves.svg';
import ShortSleeve from './upper-body_short-sleeves.svg';
import UnknownSleeves from './upper-body_unknown-sleeves.svg';

export {
  MatchTypeImage,
  LivenessImage,
  LivenessImageSuccess,
};

export const deepfakeImages = {
  DeepfakeImageFail,
  DeepfakeImageSuccess,
};

export const livenessImages = {
  LivenessImageSuccess,
  LivenessImageFail,
  LivenessImageUnknown,
};

export const headwearImages = {
  NoHeadwear,
  Headwear,
  Unknown: UnknownHeadwear,
};

export const lowerBodyImages = {
  Shorts,
  Skirt,
  Trousers,
  Unknown: UnknownLowerBody,
};

export const maskImages = {
  Mask,
  MouthOcclusion,
  NoMask,
};

export const shoesImages = {
  Shoes,
};

export const temperatureImages = {
  Abormal,
  High,
  Normal,
};

export const emotionImages = {
  Anger,
  Disgust,
  Fear,
  Happiness,
  Neutral,
  Sadness,
  Surprise,
};

export const upperBodyImages = {
  LongSleeve,
  ShortSleeve,
  UnknownSleeves,
};

export const ageImages = {
  Age18_44,
  Age45_60,
  Age60,
  AgeLess18,
};

export const backpackImages = {
  Backpack,
  Unknown: UnknownBackpack,
  NoBackpack,
};

export const genderImages = {
  Unknown: UnknownGender,
  Men,
  Women,
};
