import { apiContainer } from '@vlabs/api-bindings';

import { estimateFaceAttributesByImage } from './estimateFaceAttributesByImage';

export async function estimateFaceAttributesBySampleId(sampleId) {
  if (!sampleId) return undefined;
  const img = await apiContainer.lunaClient.samples.getImage('faces', sampleId);
  if (!img) return undefined;

  const estimation = await estimateFaceAttributesByImage(img);

  return estimation;
}
