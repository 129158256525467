import React from 'react';

import validate from '@vlabs/shared/validators';
import {
  Page, Control, Divider,
} from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import './ListCreateForm.sass';

function ListCreateForm({
  defaultValues,
  onSubmit,
}) {
  const { t } = useTranslation();

  const formMethods = useForm({ defaultValues });

  const {
    register,
    handleSubmit,
    formState: {
      errors,
    } } = formMethods;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Page
        className="FormWidth"
        title={t('lists:форма.заголовок', { context: defaultValues?.list_id ? 'обновление' : 'создание' })}
      >
        <Divider />

        <Control.Input
          id="user_data"
          label={t('lists:форма.название')}
          {...register('user_data', {
            required: validate.required(),
            maxLength: validate.maxLength(128),
          })}
          errors={errors}
        />

        <Divider small />

        <Control.Button fullWidth type="submit">
          {t('lists:форма.submit')}
        </Control.Button>

      </Page>
    </form>
  );
}

ListCreateForm.propTypes = {
  defaultValues: PropTypes.objectOf(PropTypes.any),
  onSubmit: PropTypes.func.isRequired,
};
ListCreateForm.defaultProps = {
  defaultValues: {},
};

export default ListCreateForm;
