import React from 'react';

import PropTypes from 'prop-types';

import { DateInput } from '../../controls/date-input/DateInput';

export function buildDateFilter() {
  const DateFilter = ({
    column,
  }) => {
    const { filterValue, setFilter } = column;

    const onClear = () => { setFilter(''); };

    const onChange = (value) => {
      if (value === null) {
        setFilter('');
      } else {
        const [from, to] = value;
        setFilter(from && to ? [from, to] : null);
      }
    };

    return (
      <div className="DateRange">
        <DateInput
          inputStyle="implicit"
          mode="range"
          onChange={onChange}
          onClear={onClear}
          value={filterValue}
        />
      </div>
    );
  };

  DateFilter.propTypes = {
    column: PropTypes.any.isRequired,
  };

  return DateFilter;
}
