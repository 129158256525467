import React, { memo } from 'react';

import { IconInner } from '../../IconInner';

const SearchIconInner = (props) => (
  <IconInner name="SearchIcon" viewBox="0 0 24 24" {...props}>
    <path d="M11 19a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm10 2-4.35-4.35" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
  </IconInner>
);

export const SearchIcon = memo(SearchIconInner);
