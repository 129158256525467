import { CleanSchema, get } from '../../json-transform';
import { LOGS } from '../constants';
import { paginationQSfields } from './pagination';

export const getLogsQS = new CleanSchema({
  ...paginationQSfields,
  level: get('level', []).asArrayOf(String),
  component_id: get('component_id').asString().ifEmptyString(undefined),
  time_start: get('dateFrom.0').asISOString(),
  time_end: get('dateTo.0').asISOString(),
  order_by: get('order_by', LOGS.ORDER_BY.time_created_desc).asOptionValue(),
});
