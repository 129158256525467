import { createSlice } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';

const initialState = {
  settings: {
    isActive: false,
    handler: undefined,
    quarantine: {
      list: undefined,
      threshold: undefined,
    },
    identify: [],
  },
};

const store = createSlice({
  name: 'thermo',
  initialState,
  reducers: {
    setSettings(state, { payload }) {
      state.settings = cloneDeep(payload);
    },
  },
});

export default store.reducer;

export const { setSettings } = store.actions;

export const updateThermoSettings = (settings) => async (dispatch) => {
  dispatch(setSettings(settings));
};
