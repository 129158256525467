import { configureStore, isPlain } from '@reduxjs/toolkit';

import { setStore } from '@vlabs/shared/redux/store';

import rootReducer from './rootReducer';

const isSerializable = (value) => value instanceof Date || isPlain(value);

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      isSerializable,
    },
  }),
});
setStore(store);
export const { getState } = store;
export { store };
