import { create } from './create';
import { getFacesCountQS } from './getFacesCountQS';
import { getFacesQS } from './getFacesQS';
import { read } from './read';

export const schemes = {
  read,
  create,
  getFacesQS,
  getFacesCountQS,
};
