import { ForgotPasswordForm } from './forgot-password/ForgotPasswordForm';
import { LoginForm } from './login-form/LoginForm';
import { LogoutForm } from './logout-form/LogoutForm';
import { ResetPasswordForm } from './reset-password/ResetPasswordForm';

export const Forms = {
  ForgotPasswordForm,
  LoginForm,
  LogoutForm,
  ResetPasswordForm,
};
