import React, { memo } from 'react';

import { IconInner } from '../../IconInner';

const RefreshRightIconInner = (props) => (
  <IconInner name="RefreshRightIcon" viewBox="0 0 24 24" {...props}>
    <path d="M23 4v6h-6M1 20v-6h6" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
    <path d="M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
  </IconInner>
);

export const RefreshRightIcon = memo(RefreshRightIconInner);
