import React from 'react';

import PropTypes from 'prop-types';

import './ThumbCell.sass';

const ThumbCell = ({
  value,
  imageProps,
}) => {
  if (!value) return null;

  return (
    // eslint-disable-next-line jsx-a11y/alt-text
    <img
      className="ThumbCell"
      key={value}
      src={value}
      {...(imageProps || {})}
    />
  );
};

ThumbCell.propTypes = {
  value: PropTypes.string,
  imageProps: PropTypes.object,
};

ThumbCell.defaultProps = {
  value: undefined,
  imageProps: undefined,
};

export { ThumbCell };
