import lunaStreams from './lunaStreams.json';
import lunaStreamsGroups from './lunaStreamsGroups.json';
import lunaStreamsQueue from './lunaStreamsQueue.json';
import lunaStreamsStreams from './lunaStreamsStreams.json';

export default {
  lunaStreams,
  lunaStreamsStreams,
  lunaStreamsGroups,
  lunaStreamsQueue,
};
