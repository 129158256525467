import React, { useCallback, useState } from 'react';

import { DeleteIcon, EditIcon, PlusIcon } from '@vlabs/icons';
import { openConfirmPopup, Control, SettingsItemWrapper, useModal } from '@vlabs/uikit';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CallbackForm } from './CallbackForm';
import st from './CallbacksPolicySubForm.module.sass';

const CallbacksPolicySubForm = () => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const { fields, remove, update, append } = useFieldArray({ control, name: 'callbacksPolicy' });
  const modal = useModal();
  const [selectedIndex, setSelectedIndex] = useState();
  const callbacksPolicyList = useWatch({ name: 'callbacksPolicy' }) || [];
  const callbackPolicyCallbacks = callbacksPolicyList.map(({ type, url }) => `${type.value}://${url}`);

  const openModal = useCallback((index) => {
    setSelectedIndex(index);
    modal.open();
    // FIXME: пересмотреть зависимости
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (value) => {
    if (selectedIndex !== undefined) {
      update(selectedIndex, value);
    } else {
      append(value);
    }
    modal.close();
  };

  const removeAfterConfirmation = (i, name) => {
    // TODO: возможно сделать асбтракцию над confirmation попапами,
    //  потому что конструирование каждый раз тега и
    //  формулирование вопроса вручную это не оч хорошо
    const message = (
      <p>
        {t('handlers:policies.storage_policy.callbacks.delete.message')}
        &nbsp;
        {name}
        ?
      </p>
    );

    openConfirmPopup({
      title: t('handlers:policies.storage_policy.callbacks.delete.title'),
      message,
      type: 'delete',
      onConfirm: () => remove(i),
    });
  };

  const toggleCallback = useCallback((callbackIndex) => {
    update(callbackIndex, {
      ...fields[callbackIndex],
      enable: !fields[callbackIndex].enable,
    });
  }, [fields, update]);
  return (
    <>
      {modal.wrap(<CallbackForm
        initialValues={callbacksPolicyList[selectedIndex]}
        isSelected={selectedIndex !== undefined}
        onSubmit={onSubmit}
      />)}

      {callbacksPolicyList && fields.map((field, i) => (
        <SettingsItemWrapper
          data-testid={`callbacksPolicy.${i}`}
          key={field.id}
          title={callbackPolicyCallbacks[i]}
        >
          <div className={st.Controls}>
            <Control.Switch
              checked={fields[i].enable || false}
              data-testid={`callbacksPolicy.${i}.enableIcon`}
              onChange={() => toggleCallback(i)}
              title={t('кнопка.включить')}
            />
            <Control.RoundButton
              data-testid={`callbacksPolicy.${i}.editIcon`}
              icon={<EditIcon />}
              kind="primary"
              onClick={() => openModal(i)}
              title={t('кнопка.редактировать')}
              variant="dimmed"
            />
            <Control.RoundButton
              data-testid={`callbacksPolicy.${i}.deleteIcon`}
              icon={<DeleteIcon />}
              kind="negative"
              onClick={() => removeAfterConfirmation(i, callbackPolicyCallbacks[i])}
              title={t('кнопка.удалить')}
              variant="dimmed"
            />
          </div>
        </SettingsItemWrapper>
      ))}
      <SettingsItemWrapper>
        <div className="MatchPolicy_Margin">
          <Control.RoundButton
            data-testid="callbacksPolicy.plusIcon"
            icon={<PlusIcon />}
            kind="primary"
            onClick={() => openModal()}
            title={t('кнопка.добавить')}
            variant="dimmed"
          />
        </div>
      </SettingsItemWrapper>
    </>
  );
};

export { CallbacksPolicySubForm };
