/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import st from './Menu.module.sass';

const MenuItem = ({
  onClick,
  className,
  children,
  testId,
  disabled,
}) => (
  <div
    className={cn(
      st.Item,
      className,
      {
        [st.Item_disabled]: disabled,
        InteractionWrapper: !disabled,
      })}
    data-testid={testId}
    onClick={onClick}
  >
    {children}
    <div className="InteractionOberlay" />
  </div>
);

MenuItem.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
  testId: PropTypes.string,
  disabled: PropTypes.bool,
};

MenuItem.defaultProps = {
  onClick: undefined,
  children: undefined,
  className: undefined,
  testId: undefined,
  disabled: undefined,
};

export { MenuItem };
