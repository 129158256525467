import React from 'react';

import validate from '@vlabs/shared/validators';
import { Control, Fold, SettingsItemWrapper } from '@vlabs/uikit';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

function PrimaryTrackPolicySubForm() {
  const { t } = useTranslation();
  const { register, control, watch, formState: { errors } } = useFormContext();
  return (
    <Fold
      control={control}
      isOpen={watch('policies.primary_track_policy.use_primary_track_policy')}
      name="policies.primary_track_policy.use_primary_track_policy"
      switch
      testId="primary-track-fold"
      title={t('lunaStreamsStreams:форма.primary track policy.use_primary_track_policy')}
    >
      <SettingsItemWrapper title={t('lunaStreamsStreams:форма.primary track policy.best_shot_min_size')}>
        <Control.Input
          {...register('policies.primary_track_policy.best_shot_min_size', {
            min: validate.gte(0),
            max: validate.lte(2147483647),
            validate: validate.integer(),
          })}
          errors={errors}
        />
      </SettingsItemWrapper>
      <SettingsItemWrapper title={t('lunaStreamsStreams:форма.primary track policy.best_shot_proper_size')}>
        <Control.Input
          {...register('policies.primary_track_policy.best_shot_proper_size', {
            min: validate.gte(0),
            max: validate.lte(2147483647),
            validate: validate.integer(),
          })}
          errors={errors}
        />
      </SettingsItemWrapper>
    </Fold>
  );
}

PrimaryTrackPolicySubForm.propTypes = {

};

export default PrimaryTrackPolicySubForm;
