import React, { useCallback, useState } from 'react';

import { apiContainer } from '@vlabs/api-bindings';
import { Panel } from '@vlabs/uikit';
import PropTypes from 'prop-types';

import st from './FullFrame.module.sass';

function FullFrame({
  rect,
  fullFrameURL,
}) {
  const [detectionRectStyle, setDetectionRectStyle] = useState({});

  const onImageLoad = useCallback(
    async ({ target: img }) => {
      const rescaleFactor = await apiContainer.lunaClient.images.getRescaleFactor(fullFrameURL);
      const webScale = parseFloat(img.offsetWidth) / parseFloat(img.naturalWidth);
      if (Number.isNaN(webScale)) return;
      const newDetectionRectStyle = {
        top: rect.y * rescaleFactor * webScale,
        left: rect.x * rescaleFactor * webScale,
        width: rect.width * rescaleFactor * webScale,
        height: rect.height * rescaleFactor * webScale,
        display: 'block',
      };
      setDetectionRectStyle(newDetectionRectStyle);
    },
    [rect, fullFrameURL],
  );

  return (
    <Panel>
      <div className={st.Root}>
        <div className={st.ImageContainer}>
          <img
            alt="origin"
            className={st.Image}
            data-testid="imageOrigin"
            onLoad={onImageLoad}
            src={fullFrameURL}
          />
          <div
            className={st.Detection}
            style={detectionRectStyle}
          />
        </div>
      </div>
    </Panel>
  );
}

FullFrame.propTypes = {
  rect: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  fullFrameURL: PropTypes.string,
};
FullFrame.defaultProps = {
  rect: undefined,
  fullFrameURL: undefined,
};
export { FullFrame };
