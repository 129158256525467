import { createSelector } from '@reduxjs/toolkit';

import { SLICE_NAME } from '.';

export const selectSlice = ({ [SLICE_NAME]: groups }) => groups;
export const selectGroups = createSelector(
  selectSlice,
  ({ groups }) => groups,
);
export const selectPageData = createSelector(
  selectSlice,
  ({ pageData }) => pageData,
);
export const selectPageParameters = createSelector(
  selectSlice,
  (slice) => ({
    pageSizeOptions: slice.pageSizeOptions,
    pageSize: slice.pageSize,
    pageIndex: slice.pageIndex,
  }),
);
export const selectGroupFilters = createSelector(
  selectSlice,
  ({ filters }) => filters,
);
export const selectGroupOptions = createSelector(
  selectSlice,
  ({ groupOptions }) => groupOptions,
);
export const selectGroupOptionsByAccountId = createSelector(
  selectGroupOptions,
  (_, accountId) => accountId,
  (options, accountId) => options.filter((option) => option.accountId === accountId),
);
