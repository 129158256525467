/* eslint-disable no-underscore-dangle */
const prepareRect = (canvas, target) => {
  const zoomFactorX = canvas.backgroundImage.width / (canvas.width * canvas.getZoom());
  const zoomFactorY = canvas.backgroundImage.height / (canvas.height * canvas.getZoom());
  return ({
    x: target.left * zoomFactorX || 0,
    y: target.top * zoomFactorY || 0,
    width: target.width * target.scaleX * zoomFactorX || 0,
    height: target.height * target.scaleY * zoomFactorY || 0,
  });
};

export const enableRectDrawing = (canvas, onChange) => {
  // eslint-disable-next-line no-param-reassign
  canvas.__eventListeners = {};
  let heightWithoutBottom;
  let widthWithoutRight;

  const fixMinusValues = (target) => {
    if (target.left < 0) {
      target.set({ left: 0 });
    }
    if (target.top < 0) {
      target.set({ top: 0 });
    }
  };

  const onMouseUp = ({ target }) => {
    if (!target) return;

    fixMinusValues(target);

    if (onChange) onChange(prepareRect(canvas, target));
  };

  function preventDragOffCanvas({ target }) {
    const {
      left, top, width, height, scaleX, scaleY,
    } = target;

    // ограничение на верхнюю грань и левую грань
    fixMinusValues(target);

    // ограничение на правую грань
    if (left + width * scaleX > this.width) {
      target.set({ left: (this.width - width * scaleX) });
    }
    if (width * scaleX > this.width) {
      target.set({ scaleX: this.width / width });
    }

    // ограничение на нижнюю грань
    if (top + height * scaleY > this.height) {
      target.set({ top: (this.height - height * scaleY) });
    }
    if (height * scaleY > this.height) {
      target.set({ scaleY: this.height / height });
    }
    heightWithoutBottom = target.top + height * target.scaleY;
    widthWithoutRight = target.left + width * target.scaleX;

    if (onChange) onChange(prepareRect(canvas, target));
  }

  function onObjectScaling({ target }) {
    const {
      width, height, scaleX, scaleY,
    } = target;
    const imageWidth = this.width;
    const imageHeight = this.height;

    const bottomBound = () => {
      fixMinusValues(target);
      if (target.top + height * scaleY > this.height) {
        target.set({ scaleY: (imageHeight - target.top) / height });
      }
      heightWithoutBottom = target.top + height * target.scaleY;
    };

    const rightBound = () => {
      fixMinusValues(target);
      if (target.left + width * scaleX > this.width) {
        target.set({ scaleX: (imageWidth - target.left) / width });
      }
      widthWithoutRight = target.left + width * target.scaleX;
    };

    const topBound = () => {
      fixMinusValues(target);
      heightWithoutBottom = heightWithoutBottom || (target.top + height * scaleY);
      if (heightWithoutBottom / height < scaleY) {
        target.set({ scaleY: heightWithoutBottom / height });
      }
    };

    const leftBound = () => {
      fixMinusValues(target);
      widthWithoutRight = widthWithoutRight || (target.left + width * scaleX);
      if (widthWithoutRight / width < scaleX) {
        target.set({ scaleX: widthWithoutRight / width });
      }
    };

    switch (target.__corner) {
      case 'mb':
        bottomBound();
        break;
      case 'mr':
        rightBound();
        break;
      case 'ml':
        leftBound();
        break;
      case 'mt':
        topBound();
        break;
      case 'br':
        rightBound();
        bottomBound();
        break;
      case 'bl':
        leftBound();
        bottomBound();
        break;
      case 'tl':
        leftBound();
        topBound();
        break;
      case 'tr':
        topBound();
        rightBound();
        break;
      default:
        break;
    }

    if (onChange) onChange(prepareRect(canvas, target));
  }

  canvas.on('mouse:up', onMouseUp);
  canvas.on('object:moving', preventDragOffCanvas);
  canvas.on('object:scaling', onObjectScaling);
};
