import { get, CleanSchema } from '../../../json-transform';

const taskCallbacksSchema = new CleanSchema({
  type: get('type').asOptionValue(),
  authorization: {
    type: get('authorization.type').asOptionValue(),
    login: get('authorization.login').asString().ifEmptyString(undefined),
    password: get('authorization.password').asString().ifEmptyString(undefined),
  },
  chat_id: get('chat_id').asInt().ifNaN(undefined),
  token: get('token').asString().ifEmptyString(undefined),
  url: get('url').asString(),
  params: {
    timeout: get('params.timeout').asInt().ifNaN(undefined),
    content_type: get('params.content_type').asOptionValue(),
    headers: get('params.headers', '').as((headers) => {
      const result = {};
      headers.split('\n').forEach((headerLine) => {
        const [name, value] = headerLine.split(': ');
        result[name] = value;
      });
      return result;
    }).ifEmpty(undefined),
  },
  enable: get('enable', true).asInt(),
});

export const notificationPolicySchema = new CleanSchema({
  enable: get('enable', true).asInt(),
  task_callbacks: get('task_callbacks').asArrayOf(taskCallbacksSchema),
});
