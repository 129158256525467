import React, { useState, useEffect, useMemo, useCallback } from 'react';

import { ROLE_OPTIONS } from '@vlabs/api-bindings/src/constants';
import { KeyIcon, MailIcon } from '@vlabs/icons';
import { actionColumnProps } from '@vlabs/shared';
import { selectAuthConfig } from '@vlabs/shared/selectors/appSelectors';
import {
  Page, Panel, Table, TableCells,
  TableFilters, openConfirmPopup, Modal, Control,
} from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import AccountForm from './AccountForm';
import NewPasswordForm from './NewPasswordForm';
import { selectAccounts } from './selectors';
import * as accountsStore from './store';

const AccountListPageComponent = ({
  accountsData,
  createAccount,
  updateAccount,
  deleteAccount,
  resetPasswordByEmail,
  allowPasswordChange,
  setFilters,
  setPageSize,
  setPageIndex,
  resetAccountsState,
}) => {
  const { t } = useTranslation();
  const [accountEditFormIsOpen, setAccountEditFormIsOpen] = useState(false);
  const [newPasswordFormIsOpen, setNewPasswordFormIsOpen] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(undefined);

  useEffect(() => () => resetAccountsState(), [resetAccountsState]);

  function roleSorting(rowA, rowB, id) {
    const a = ROLE_OPTIONS[rowA.values[id]]?.label || String(rowA.values[id]);
    const b = ROLE_OPTIONS[rowB.values[id]]?.label || String(rowB.values[id]);

    return a.localeCompare(b);
  }

  const closeAccountEditForm = () => {
    setSelectedAccount(undefined);
    setAccountEditFormIsOpen(false);
  };

  const showAccountEditForm = (account = {}) => {
    setSelectedAccount({
      ...account,
      role: ROLE_OPTIONS[account?.role ?? 0],
    });
    setAccountEditFormIsOpen(true);
  };

  const onDeleteHandler = (account) => {
    const message = <Trans i18nKey="accounts:delete user.confirmation.message" values={{ account }} />;
    const onConfirm = async () => {
      await deleteAccount(account.id);
    };

    openConfirmPopup({
      title: t('accounts:delete user.confirmation.title'),
      message,
      type: 'delete',
      onConfirm,
    });
  };

  const onUpdateAccount = async ({ role: { value: role }, ...values }) => {
    const data = { role, ...values };
    let errors;
    if (selectedAccount?.id) {
      errors = await updateAccount(selectedAccount.id, data);
    } else {
      errors = await createAccount(data);
    }
    if (errors === undefined) {
      closeAccountEditForm();
      setSelectedAccount(undefined);
    }
  };

  const onUpdatePassword = async ({ id, password }) => {
    await updateAccount(id, { password });
    setNewPasswordFormIsOpen(false);
  };

  const showNewPasswordForm = (account) => {
    setSelectedAccount(account);
    setNewPasswordFormIsOpen(true);
  };

  const closeNewPasswordForm = () => {
    setSelectedAccount(undefined);
    setNewPasswordFormIsOpen(false);
  };

  const sendResetPasswordEmail = useCallback((account) => {
    const message = <Trans i18nKey="accounts:password recovery.confirmation.message" values={{ account }} />;
    const onConfirm = async () => {
      await resetPasswordByEmail(account.id);
      closeAccountEditForm();
    };
    openConfirmPopup({
      title: t('accounts:password recovery.confirmation.title'),
      message,
      type: 'warning',
      onConfirm,
    });
  }, [resetPasswordByEmail, t]);

  const columns = useMemo(() => ([
    {
      Header: <TableCells.TableHeaderCell
        label={t('accounts:table.columns.name.header.label')}
        title={t('accounts:table.columns.name.header.title')}
      />,
      accessor: 'name',
      Filter: TableFilters.buildTextFilter(),
      width: 100,
    },
    {
      Header: <TableCells.TableHeaderCell
        label={t('accounts:table.columns.email.header.label')}
        title={t('accounts:table.columns.email.header.title')}
      />,
      accessor: 'email',
      Filter: TableFilters.buildTextFilter(),
      width: 100,
    },
    {
      Header: <TableCells.TableHeaderCell
        label={t('accounts:table.columns.position.header.label')}
        title={t('accounts:table.columns.position.header.title')}
      />,
      accessor: 'post',
      Filter: TableFilters.buildTextFilter(),
      width: 100,
    },
    {
      Header: <TableCells.TableHeaderCell
        label={t('accounts:table.columns.role.header.label')}
        title={t('accounts:table.columns.role.header.title')}
      />,
      Filter: TableFilters.buildOneOfFilter({ options: ROLE_OPTIONS.raw }),
      sortType: roleSorting,
      accessor: 'role',
      Cell: ({ value: userRole }) => {
        const roleOption = ROLE_OPTIONS[userRole];
        return roleOption?.label || userRole;
      },
      width: 100,
    },
    actionColumnProps({
      id: 'actionChangePassword',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ row }) => (
        <Control.RoundButton
          data-testid="accountList.changePasswordButton"
          disabled={allowPasswordChange.ui === false}
          icon={<KeyIcon />}
          kind="primary"
          onClick={() => showNewPasswordForm(row.original)}
          title={t('accounts:таблица.кнопки.изменить пароль')}
          variant="dimmed"
        />
      ),
    }),
    actionColumnProps({
      id: 'actionSendResetPasswordEmail',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ row }) => (
        <Control.RoundButton
          data-testid="accountList.resetPasswordByEmailButton"
          disabled={allowPasswordChange.email === false}
          icon={<MailIcon />}
          kind="primary"
          onClick={() => sendResetPasswordEmail(row.original)}
          title={t('accounts:таблица.кнопки.сбросить пароль')}
          variant="dimmed"
        />
      ),
    }),
    actionColumnProps({ id: 'actionEdit', Cell: TableCells.EditCell }),
    actionColumnProps({ id: 'actionDelete', Cell: TableCells.DeleteCell }),
  ]), [t, allowPasswordChange.ui, allowPasswordChange.email, sendResetPasswordEmail]);

  return (
    <Page className="Global__Table_fullScreen_wrapper">
      <Modal
        isOpen={accountEditFormIsOpen}
        onRequestClose={closeAccountEditForm}
      >
        <AccountForm
          defaultValues={selectedAccount}
          onSubmit={onUpdateAccount}
        />
      </Modal>
      <Modal
        isOpen={newPasswordFormIsOpen}
        onRequestClose={closeNewPasswordForm}
      >
        <NewPasswordForm
          defaultValues={selectedAccount}
          onSubmit={onUpdatePassword}
        />
      </Modal>
      <Panel className="Global__Table_fullScreen">
        <Table
          columns={columns}
          data={accountsData.data}
          disableSortBy={false}
          filters={accountsData.filters}
          onActions={{
            onClickRow: { handler: showAccountEditForm },
            onAddRow: { handler: showAccountEditForm },
            onEditRow: { handler: showAccountEditForm },
            onDeleteRow: { handler: onDeleteHandler },
          }}
          pageIndex={accountsData.pageIndex}
          pageSize={accountsData.pageSize}
          pageSizeOptions={accountsData.pageSizeOptions}
          paginationType="offsetBased"
          rowProps={(row) => ({ 'data-account-id': row.original.id })}
          setFilters={setFilters}
          setPageIndex={setPageIndex}
          setPageSize={setPageSize}
        />
      </Panel>
    </Page>
  );
};

AccountListPageComponent.propTypes = {
  accountsData: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.any),
    filters: PropTypes.arrayOf(PropTypes.shape),
    pageIndex: PropTypes.number,
    pageSize: PropTypes.number,
    pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
  }),
  createAccount: PropTypes.func,
  updateAccount: PropTypes.func,
  deleteAccount: PropTypes.func,
  setFilters: PropTypes.func,
  setPageSize: PropTypes.func,
  setPageIndex: PropTypes.func,
  resetAccountsState: PropTypes.func,
  resetPasswordByEmail: PropTypes.func,
  allowPasswordChange: PropTypes.shape({
    ui: PropTypes.bool,
    email: PropTypes.bool,
  }),
};

AccountListPageComponent.defaultProps = {
  accountsData: {
    data: [],
    filters: [],
    pageIndex: 0,
    pageSize: 25,
    pageSizeOptions: [10, 25, 50, 100],
  },
  createAccount: undefined,
  updateAccount: undefined,
  deleteAccount: undefined,
  setFilters: undefined,
  setPageSize: undefined,
  setPageIndex: undefined,
  resetAccountsState: undefined,
  resetPasswordByEmail: undefined,
  allowPasswordChange: {
    ui: false,
    email: false,
  },
};

export const AccountListPage = connect(
  (state) => ({
    accountsData: selectAccounts(state),
    allowPasswordChange: selectAuthConfig(state)?.allowPasswordChange,
  }),
  (dispatch) => ({
    createAccount: (data) => dispatch(accountsStore.createAccount(data)),
    updateAccount: (id, data) => dispatch(accountsStore.updateAccount(id, data)),
    deleteAccount: (id) => dispatch(accountsStore.deleteAccount(id)),
    resetPasswordByEmail: (id) => dispatch(accountsStore.resetPasswordByEmail(id)),
    setFilters: (filters) => dispatch(accountsStore.setFilters({ filters })),
    setPageSize: (pageSize) => dispatch(accountsStore.setPageSize({ pageSize })),
    setPageIndex: (pageIndex) => dispatch(accountsStore.setPage({ pageIndex })),
    resetAccountsState: () => dispatch(accountsStore.resetStore()),
  }),
)(AccountListPageComponent);
