import React, { useCallback } from 'react';

import {
  FACE_QUALITY_EYE,
  FACE_QUALITY_EYEBROWS_STATE,
  FACE_QUALITY_FACE_COLOR_TYPES,
  FACE_QUALITY_SHOULDERS_POSITION,
  FACE_QUALITY_GLASSES,
  FACE_QUALITY_HEADWEAR_TYPE,
  FACE_QUALITY_IMAGE_FORMAT,
  FACE_QUALITY_SMILE_PROPERTIES,
  FACE_QUALITY_YES_NO,
} from '@vlabs/api-bindings/src/luna-client/handlers/constants';
import { CircleInfoIcon } from '@vlabs/icons';
import { selectIsAppFeatureAvailable } from '@vlabs/shared/selectors/appSelectors';
import validate from '@vlabs/shared/validators';
import { Control, Fold, SettingsItemWrapper, Divider, Tooltip } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useFormContext, useFormState, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import st from './FaceQualitySubForm.module.sass';

const FaceQualitySubForm = ({
  isFaceQualityAvailable,
}) => {
  const { t } = useTranslation();
  const { control, register } = useFormContext();
  const { errors } = useFormState();

  const minMaxField = useCallback(({
    name, validation = {},
  }) => {
    const minValidationRules = {
      validate: validate.number(),
    };
    if (validation.min?.min !== undefined) {
      minValidationRules.min = validate.gte(validation.min.min);
    }
    if (validation.min?.max !== undefined) {
      minValidationRules.max = validate.lte(validation.min.max);
    }

    const maxValidationRules = {
      validate: validate.number(),
    };
    if (validation.max?.min !== undefined) {
      maxValidationRules.min = { value: validation.max.min, message: t('валидация.число.больше или равно', { value: validation.max.min }) };
    }
    if (validation.max?.max !== undefined) {
      maxValidationRules.max = { value: validation.max.max, message: t('валидация.число.меньше или равно', { value: validation.max.max }) };
    }

    return (
      <SettingsItemWrapper title={t(`handlers:качество лица.${name}`)}>
        <div className={st.Field}>
          <div className={st.MinMaxField__From}>
            <Control.Input
              {...register(`faceQuality.${name}.threshold.min`, minValidationRules)}
              errors={errors}
              placeholder={t('from')}
            />
          </div>
          <div className={st.MinMaxField__Divider}>:</div>
          <div className={st.MinMaxField__Till}>
            <Control.Input
              {...register(`faceQuality.${name}.threshold.max`, maxValidationRules)}
              errors={errors}
              placeholder={t('to')}
            />
          </div>
          <Divider small />
          <div className={st.MinMaxField__Enabled}>
            <Control.Switch
              control={control}
              name={`faceQuality.${name}.isEnabled`}
            />
          </div>
        </div>
      </SettingsItemWrapper>
    );
  }, [t, register, errors, control]);

  const selectField = useCallback(({
    name, options, isMulti = true,
  }) => {
    return (
      <SettingsItemWrapper
        title={t(`handlers:качество лица.${name}`)}
      >
        <div className={st.Field}>
          <div className={st.Inputs}>
            <Control.Select
              control={control}
              errors={errors}
              isMulti={isMulti}
              name={`faceQuality.${name}.threshold`}
              options={options}
            />
          </div>
          <Divider small />
          <Control.Switch
            control={control}
            errors={errors}
            name={`faceQuality.${name}.isEnabled`}
          />

        </div>
      </SettingsItemWrapper>
    );
  }, [t, errors, control]);

  const FoldTitle = (
    <div className={st.FoldTitle}>
      <h5>{t('handlers:setup sections.face quality.title')}</h5>

      {!isFaceQualityAvailable && (
        <Tooltip
          className="Tooltip_ui"
          data-tooltip-content={t('handlers:setup sections.face quality.tooltip.licenses.false')}
          tooltipProps={{ effect: 'solid' }}
        >
          <CircleInfoIcon className={st.Tooltip_Icon_notAvailable} />
        </Tooltip>
      )}
    </div>
  );

  return (
    <Fold
      control={control}
      disabled={!isFaceQualityAvailable}
      isOpen={useWatch({ name: 'estimate.faceQuality' })}
      name="estimate.faceQuality"
      switch
      testId="faceQuality"
      title={FoldTitle}
    >
      <div>
        {selectField({ name: 'imageFormat', options: FACE_QUALITY_IMAGE_FORMAT.raw })}
        {minMaxField({ name: 'imageSize', validation: { min: { min: 0 }, max: { min: 0 } } })}
        {minMaxField({ name: 'imageWidth', validation: { min: { min: 0 }, max: { min: 0 } } })}
        {minMaxField({ name: 'imageHeight', validation: { min: { min: 0 }, max: { min: 0 } } })}
        {minMaxField({ name: 'aspectRatio', validation: { min: { min: 0 }, max: { min: 0 } } })}
        {minMaxField({ name: 'illuminationQuality', validation: { min: { min: 0, max: 1 }, max: { min: 0, max: 1 } } })}
        {minMaxField({ name: 'specularityQuality', validation: { min: { min: 0, max: 1 }, max: { min: 0, max: 1 } } })}
        {minMaxField({ name: 'blurrinessQuality', validation: { min: { min: 0, max: 1 }, max: { min: 0, max: 1 } } })}
        {minMaxField({ name: 'darkQuality', validation: { min: { min: 0, max: 1 }, max: { min: 0, max: 1 } } })}
        {minMaxField({ name: 'lightQuality', validation: { min: { min: 0, max: 1 }, max: { min: 0, max: 1 } } })}
        {minMaxField({ name: 'illuminationUniformity', validation: { min: { min: 0, max: 1 }, max: { min: 0, max: 1 } } })}
        {minMaxField({ name: 'dynamicRange', validation: { min: { min: 0, max: 1 }, max: { min: 0, max: 1 } } })}
        {minMaxField({ name: 'backgroundUniformity', validation: { min: { min: 0, max: 1 }, max: { min: 0, max: 1 } } })}
        {minMaxField({ name: 'backgroundLightness', validation: { min: { min: 0, max: 1 }, max: { min: 0, max: 1 } } })}
        {selectField({ name: 'radialDistortion', options: FACE_QUALITY_YES_NO.raw, isMulti: false })}
        {selectField({ name: 'faceColorType', options: FACE_QUALITY_FACE_COLOR_TYPES.raw })}
        {selectField({ name: 'shoulders_position', options: FACE_QUALITY_SHOULDERS_POSITION.raw })}
        {minMaxField({ name: 'faceWidth', validation: { min: { min: 0 }, max: { min: 0 } } })}
        {minMaxField({ name: 'faceHeight', validation: { min: { min: 0 }, max: { min: 0 } } })}
        {minMaxField({ name: 'indentUpper', validation: { min: { min: 0 }, max: { min: 0 } } })}
        {minMaxField({ name: 'indentLower', validation: { min: { min: 0 }, max: { min: 0 } } })}
        {minMaxField({ name: 'indentLeft', validation: { min: { min: 0 }, max: { min: 0 } } })}
        {minMaxField({ name: 'indentRight', validation: { min: { min: 0 }, max: { min: 0 } } })}
        {minMaxField({ name: 'headYaw', validation: { min: { min: -180, max: 180 }, max: { min: -180, max: 180 } } })}
        {minMaxField({ name: 'headPitch', validation: { min: { min: -180, max: 180 }, max: { min: -180, max: 180 } } })}
        {minMaxField({ name: 'headRoll', validation: { min: { min: -180, max: 180 }, max: { min: -180, max: 180 } } })}
        {minMaxField({ name: 'gazeYaw', validation: { min: { min: -180, max: 180 }, max: { min: -180, max: 180 } } })}
        {minMaxField({ name: 'gazePitch', validation: { min: { min: -180, max: 180 }, max: { min: -180, max: 180 } } })}
        {minMaxField({ name: 'mouthSmiling', validation: { min: { min: 0, max: 1 }, max: { min: 0, max: 1 } } })}
        {minMaxField({ name: 'mouthOccluded', validation: { min: { min: 0, max: 1 }, max: { min: 0, max: 1 } } })}
        {minMaxField({ name: 'mouthOpen', validation: { min: { min: 0, max: 1 }, max: { min: 0, max: 1 } } })}
        {selectField({ name: 'smileProperties', options: FACE_QUALITY_SMILE_PROPERTIES.raw })}
        {selectField({ name: 'glasses', options: FACE_QUALITY_GLASSES.raw })}
        {selectField({ name: 'leftEye', options: FACE_QUALITY_EYE.raw })}
        {selectField({ name: 'rightEye', options: FACE_QUALITY_EYE.raw })}
        {selectField({ name: 'redEyes', options: FACE_QUALITY_YES_NO.raw, isMulti: false })}
        {minMaxField({ name: 'eyeDistance', validation: { min: { min: 0 }, max: { min: 0 } } })}
        {minMaxField({ name: 'headWidth', validation: { min: { min: 0, max: 1 }, max: { min: 0, max: 1 } } })}
        {minMaxField({ name: 'headHeight', validation: { min: { min: 0, max: 1 }, max: { min: 0, max: 1 } } })}
        {minMaxField({ name: 'headHorizontalCenter', validation: { min: { min: 0, max: 1 }, max: { min: 0, max: 1 } } })}
        {minMaxField({ name: 'headVerticalCenter', validation: { min: { min: 0, max: 1 }, max: { min: 0, max: 1 } } })}
        {selectField({ name: 'eyebrowsState', options: FACE_QUALITY_EYEBROWS_STATE.raw })}
        {selectField({ name: 'headwearType', options: FACE_QUALITY_HEADWEAR_TYPE.raw })}
        {selectField({ name: 'naturalLight', options: FACE_QUALITY_YES_NO.raw, isMulti: false })}
      </div>
    </Fold>
  );
};

FaceQualitySubForm.propTypes = {
  isFaceQualityAvailable: PropTypes.bool,
};
FaceQualitySubForm.defaultProps = {
  isFaceQualityAvailable: false,
};

export default connect((state) => ({
  isFaceQualityAvailable: selectIsAppFeatureAvailable(state, 'estimations.face_quality'),
}))(FaceQualitySubForm);
