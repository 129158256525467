import React from 'react';

import { selectIsAppFeatureAvailable } from '@vlabs/shared/selectors/appSelectors';
import { Control, SettingsItemWrapper } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { EstimateBodyToggle } from './EstimateBodyToggle';
import { ExtractBodyDescriptorToggle } from './ExtractBodyDescriptorToggle';

const EstimationsBodySubForm = ({
  isBodyLicenseAvailable,
}) => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const estimateBody = useWatch({ name: 'estimate.body' });

  const noBodyLicenseTooltipText = t('handlers:policies.detect_policy.tooltip.body license is required');

  return (
    <>
      <SettingsItemWrapper
        data-tooltip-content={!isBodyLicenseAvailable ? noBodyLicenseTooltipText : null}
        disabled={!isBodyLicenseAvailable}
        title={t('handlers:policies.detect_policy.detect_body')}
      >
        <EstimateBodyToggle />
      </SettingsItemWrapper>

      <SettingsItemWrapper
        data-tooltip-content={!isBodyLicenseAvailable ? noBodyLicenseTooltipText : null}
        disabled={!estimateBody || !isBodyLicenseAvailable}
        title={t('handlers:policies.extract_policy.extract_body_descriptor')}
      >
        <ExtractBodyDescriptorToggle />
      </SettingsItemWrapper>

      <SettingsItemWrapper
        data-tooltip-content={!isBodyLicenseAvailable ? noBodyLicenseTooltipText : t('handlers:policies.detect_policy.tooltip.gender and age estimation')}
        disabled={!isBodyLicenseAvailable}
        title={t('handlers:policies.detect_policy.body_attributes.estimate_basic_attributes')}
      >
        <Control.Switch
          control={control}
          disabled={!isBodyLicenseAvailable}
          name="estimate.body_attributes.estimate_basic_attributes"
        />
      </SettingsItemWrapper>

      <SettingsItemWrapper
        data-tooltip-content={!isBodyLicenseAvailable ? noBodyLicenseTooltipText : t('handlers:policies.detect_policy.tooltip.estimation of headwear')}
        disabled={!isBodyLicenseAvailable}
        title={t('handlers:policies.detect_policy.body_attributes.estimate_upper_body')}
      >
        <Control.Switch
          control={control}
          disabled={!isBodyLicenseAvailable}
          name="estimate.body_attributes.estimate_upper_body"
        />
      </SettingsItemWrapper>

      <SettingsItemWrapper
        data-tooltip-content={!isBodyLicenseAvailable ? noBodyLicenseTooltipText : t('handlers:policies.detect_policy.tooltip.estimation of lower body clothing type')}
        disabled={!isBodyLicenseAvailable}
        title={t('handlers:policies.detect_policy.body_attributes.estimate_lower_body')}
      >
        <Control.Switch
          control={control}
          disabled={!isBodyLicenseAvailable}
          name="estimate.body_attributes.estimate_lower_body"
        />
      </SettingsItemWrapper>

      <SettingsItemWrapper
        data-tooltip-content={!isBodyLicenseAvailable ? noBodyLicenseTooltipText : t('handlers:policies.detect_policy.tooltip.estimation of a backpack')}
        disabled={!isBodyLicenseAvailable}
        title={t('handlers:policies.detect_policy.body_attributes.estimate_accessories')}
      >
        <Control.Switch
          control={control}
          disabled={!isBodyLicenseAvailable}
          name="estimate.body_attributes.estimate_accessories"
        />
      </SettingsItemWrapper>
    </>
  );
};

EstimationsBodySubForm.propTypes = {
  isBodyLicenseAvailable: PropTypes.bool,
};
EstimationsBodySubForm.defaultProps = {
  isBodyLicenseAvailable: false,
};

export default connect((state) => ({
  isBodyLicenseAvailable: selectIsAppFeatureAvailable(state, 'estimations.body_attributes'),
}))(EstimationsBodySubForm);
