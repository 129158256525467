import {
  CAMERA_PROTOCOL_OPTIONS,
  CAMERA_ROTATION_OPTIONS,
  LIVENESS_MODE_OPTIONS,
  TIME_TYPE_OPTIONS,
} from '../../../../constants';
import { Schema, get } from '../../../../json-transform';

export const read = new Schema({
  id: get('id').asString(),
  name: get('name').asString(),
  previewUrl: get('preview_url').asString(),
  alive: get('alive').asBoolean(),
  input: {
    url: get('input.url').asString(),
    transport: get('input.transport').asOption(CAMERA_PROTOCOL_OPTIONS),
    roi: get('input.roi').asString(),
    droi: get('input.droi').asString(),
    uiFormatROI: get('input.roi').as(([x, y, width, height]) => ({ x, y, width, height })),
    uiFormatDROI: get('input.droi').as(([x, y, width, height]) => ({ x, y, width, height })),
    rotation: get('input.rotation').asOption(CAMERA_ROTATION_OPTIONS),
    frameProcessingMode: get('input.frame_processing_mode').asOption(),
    numberOfFfmpegThreads: get('input.ffmpeg_threads_number').asInt(),
  },
  output: {
    url: get('output.url').asString(),
    lunaAccountId: get('output.luna_account_id').asString(),
    lunaHumanHandlerId: get('output.luna_human_handler_id').asString(),
    lunaDynamicHumanHandlerId: get('output.luna_dynamic_human_handler_id').asString(),
    imageStoreUrl: get('output.image_store_url').asString(),
  },
  location: {
    isEnabled: get('location.send_location_data').asBoolean(),
    city: get('location.city').asString(),
    area: get('location.area').asString(),
    district: get('location.district').asString(),
    street: get('location.street').asString(),
    houseNumber: get('location.house_number').asString(),
    geoPosition: {
      longitude: get('location.geo_position.longitude').asFloat(),
      latitude: get('location.geo_position.latitude').asFloat(),
    },
  },
  healthcheck: {
    maxErrorCount: get('health_check.max_error_count').asInt(),
    period: get('health_check.period').asInt(),
    retryDelay: get('health_check.retry_delay').asInt(),
  },
  filtering: {
    minScore: get('filtering.min_score').asFloat(),
    detectionYawThreshold: get('filtering.detection_yaw_threshold').asInt(),
    detectionPitchThreshold: get('filtering.detection_pitch_threshold').asInt(),
    detectionRollThreshold: get('filtering.detection_roll_threshold').asInt(),
    yawNumber: get('filtering.yaw_number').asInt(),
    yawCollectionMode: get('filtering.yaw_collection_mode').asBoolean(),
    mouthOcclusionThreshold: get('filtering.mouth_occlusion_threshold').asFloat(),
  },
  sending: {
    timePeriodOfSearching: get('sending.time_period_of_searching').asInt(),
    silentPeriod: get('sending.silent_period').asInt(),
    type: get('sending.type').asOption(TIME_TYPE_OPTIONS),
    numberOfBestshotsToSend: get('sending.number_of_bestshots_to_send').asInt(),
    sendEachFrames: get('sending.send_each_frames').asInt(),
    sendEachMilliseconds: get('sending.send_each_milliseconds').asInt(),
    sendOnlyFullSet: get('sending.send_only_full_set').asBoolean(),
    deleteTrackAfterSending: get('sending.delete_track_after_sending').asBoolean(),
  },
  primaryTrackPolicy: {
    usePrimaryTrackPolicy: get('primary_track_policy.use_primary_track_policy').asBoolean(),
    bestShotMinSize: get('primary_track_policy.best_shot_min_size').asInt(),
    bestShotProperSize: get('primary_track_policy.best_shot_proper_size').asInt(),
  },
  liveness: {
    useShouldersLivenessFiltration: get('liveness.use_shoulders_liveness_filtration').asBoolean(),
    useMaskLivenessFiltration: get('liveness.use_mask_liveness_filtration').asBoolean(),
    useFlyingFacesLivenessFiltration: get('liveness.use_flying_faces_liveness_filtration').asBoolean(),
    livenessMode: get('liveness.liveness_mode').asOption(LIVENESS_MODE_OPTIONS),
    numberOfLivenessChecks: get('liveness.number_of_liveness_checks').asInt(),
    livenessThreshold: get('liveness.liveness_threshold').asFloat(),
    livenessesWeights: get('liveness.livenesses_weights').asArrayOfFloat(),
    maskBackgroundsCount: get('liveness.mask_backgrounds_count').asInt(),
  },
  videoInfo: {
    width: get('video_info.width').asInt(),
    height: get('video_info.height').asInt(),
    frameRate: get('video_info.frame_rate').asInt(),
    bitRate: get('video_info.bit_rate').asInt(),
    gopSize: get('video_info.gop_size').asInt(),
    startTime: get('video_info.start_time').asDate(),
  },
});
