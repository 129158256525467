import { loadProgressBar } from '@vlabs/axios-progress-bar';
import '@vlabs/axios-progress-bar/dist/nprogress.css';

import { BaseLunaClient } from '../BaseLunaClient';
import { DEFAULT_HEALTHCHECK_TIMEOUT } from '../config';
import { buildBindedProxy } from './schemas';

export class LunaStreamsClient extends BaseLunaClient {
  constructor(baseUrl = '', apiVersion = 1) {
    super({
      apiVersion,
      baseUrl,
    });
    this.apiVersion = apiVersion;
    this.shs = buildBindedProxy(this);
    loadProgressBar('', this.http);
  }

  healthcheck() {
    return this.http.get('/healthcheck', {
      timeout: DEFAULT_HEALTHCHECK_TIMEOUT,
      baseURL: this.baseURL,
    });
  }

  get config() {
    return {
      get: () => this.http.get('/config'),
    };
  }

  get streams() {
    const prefix = 'streams';
    return {
      create: async (payload) => {
        const { data } = await this.http.post(`/${prefix}`, this.shs.createStream(payload));
        return data;
      },
      getAll: async (params) => {
        const PAGE_SIZE = 100;
        const count = await this.streams.getCount(params);

        const requests = [];
        for (let page = 1; page < Math.ceil(count / PAGE_SIZE) + 1; page += 1) {
          requests.push(this.streams.getPage({ page_size: PAGE_SIZE, page, ...params }));
        }
        const result = await Promise.all(requests);
        return result.flatMap(({ data: page }) => page);
      },
      getPage: async (params) => {
        const [{ data: { streams: data } }, count] = await Promise.all([
          this.http.get(`/${prefix}`, { params: this.shs.getStreamsQS(params) }),
          this.streams.getCount(params),
        ]);
        return { data: data.map(this.shs.readStream), meta: { count } };
      },
      getCount: async (params) => {
        const { data: { streams_count: count } } = await this.http.get(`/${prefix}/count`, { params });
        return count;
      },
      get: async (streamId) => {
        const { data } = await this.http.get(`/${prefix}/${streamId}`);
        return this.shs.readStream(data);
      },
      update: (streamId, payload) => this.http.patch(`/${prefix}/${streamId}`, payload),
      replace: async (streamId, payload) => {
        const { data } = await this.http.put(`/${prefix}/${streamId}`, this.shs.createStream(payload));
        return data;
      },
      delete: (streamId) => this.http.delete(`/${prefix}/${streamId}`),
      liveURL: (streamId) => {
        return `${this.baseURLWithVersion}/${prefix}/${streamId}/preview/handler/live`;
      },
      lastFrameURL: (streamId) => {
        return `${this.baseURLWithVersion}/${prefix}/${streamId}/preview/handler/frame`;
      },
    };
  }

  get preview() {
    return {
      live: (streamId) => `${this.baseURLWithVersion}/streams/${streamId}/preview/handler/live`,
      lastFrame: (streamId) => `${this.baseURLWithVersion}/streams/${streamId}/preview/handler/frame`,
    };
  }

  get queue() {
    return {
      processing: async (params) => {
        const { data } = await this.http.get('/streams/processing/queue', { params });
        return { streams: data.streams.map(this.shs.readStream) };
      },
      unqueue: (payload) => this.http.post('/streams/processing/queue', payload),
      feedback: (payload) => this.http.post('/streams/processing/feedback', payload),
    };
  }

  get groups() {
    const prefix = 'groups';
    return {
      getPage: async (params, props = {}) => {
        const { withCount = false } = props;
        const promises = [
          this.http.get(`/${prefix}`, { params }),
        ];
        if (withCount) promises.push(this.groups.count(params));
        const result = await Promise.all(promises);
        const [{ data: { groups: data } }, count] = result;
        return { data, meta: { count } };
      },
      count: async (params) => {
        const { data: { groups_count: count } } = await this.http.get(`/${prefix}/count`, { params });
        return count;
      },
      getAll: async () => {
        const PAGE_SIZE = 100;
        const count = await this.groups.count();
        const pages = Math.ceil(count / PAGE_SIZE);
        const promises = [];
        for (let i = 0; i < pages; i += 1) {
          promises.push(this.groups.getPage({ page_size: PAGE_SIZE, page: i + 1 }));
        }
        const result = await Promise.all(promises);
        return result.flatMap(({ data: groups }) => groups);
      },
      get: async (groupId) => {
        const { data } = await this.http.get(`/${prefix}/${groupId}`);
        return data;
      },
      create: async ({ account_id, group_name, description }) => {
        const { data } = await this.http.post(`/${prefix}`, { account_id, group_name, description });
        return data;
      },
      update: async (groupId, { description }) => {
        const { data } = await this.http.patch(`/${prefix}/${groupId}`, { description });
        return data;
      },
      delete: async (groupId) => {
        await this.http.delete(`/${prefix}/${groupId}`);
      },
    };
  }

  group(group_id) {
    return {
      attachStreams: (stream_ids) => this.http.patch('/linker', this.shs.linker({
        group_id,
        stream_ids,
        action: 'attach',
      })),
      detachStreams: (stream_ids) => this.http.patch('/linker', this.shs.linker({
        group_id,
        stream_ids,
        action: 'detach',
      })),
    };
  }
}
