import { createSlice } from '@reduxjs/toolkit';
import { apiContainer } from '@vlabs/api-bindings';
import { selectAppState } from '@vlabs/shared/selectors/appSelectors';
import { parseISOToLocale } from '@vlabs/shared/utils';
import FileSaver from 'file-saver';
import i18n from 'i18next';
import { toast } from 'react-toastify';

import { selectAccountEmail } from '@vlabs/pages/auth/selectors';

const store = createSlice({
  name: 'reports',
  initialState: {
    state: undefined,
    data: [],
    meta: {
      count: undefined,
    },
    pageIndex: 0,
    pageSize: 25,
    pageSizeOptions: [10, 25, 50, 100],
  },
  reducers: {
    setReport(state, { payload: { items, total: count } }) {
      state.data = items || [];
      state.meta.count = count || undefined;
      state.state = 'loaded';
    },
    setPage(state, { payload: { pageIndex } = {} }) {
      if (pageIndex !== undefined) state.pageIndex = pageIndex;
    },
    setPageSize(state, { payload: { pageSize } = {} }) {
      if (pageSize !== undefined) state.pageSize = pageSize;
    },
  },
});

export default store.reducer;

export const {
  setReport,
  setPageData,
  setPageSize,
  setPage,
} = store.actions;

export const fetchReportList = async (dispatch, getState) => {
  const author = selectAccountEmail(getState());
  const { reports: { pageIndex, pageSize } } = getState();
  const { data } = await apiContainer.timeTrackerClient.reports.get({ author, page: pageIndex + 1, size: pageSize });
  dispatch(setReport(data));
};

export const createReportsEntry = (params) => async (dispatch, getState) => {
  if (selectAppState(getState()) !== 'loaded') return;
  const author = selectAccountEmail(getState());
  await apiContainer.timeTrackerClient.reports.createReportsEntry({
    ...params,
    email: author,
  }).then(() => {
    toast.success(`${i18n.t('timeTracking:отчет направлен на формирование')}.`);
  }).catch((error) => {
    toast.error(i18n.t('timeTracking:в процессе формирования отчета возникла ошибка', { error: error.response.data }));
    console.error(error.response.data);
  });
  dispatch(fetchReportList);
};

export const downloadCSV = ({ date, typeReport, pathReport }) => apiContainer.timeTrackerClient.reports
  .downloadCSV(pathReport)
  .then(({ data }) => {
    const formatDate = parseISOToLocale(date)?.replace(/,\s/, '__');
    const fileName = `${formatDate}__${typeReport}`;
    FileSaver.saveAs(data, `${fileName}.csv`);
  }).catch((error) => {
    toast.error(i18n.t('В процессе скачивания отчета возникла ошибка', { error }));
    console.error(error);
  });

export const createReportsExit = (params) => async (dispatch, getState) => {
  const author = selectAccountEmail(getState());
  if (selectAppState(getState()) !== 'loaded') return;
  await apiContainer.timeTrackerClient.reports.createReportsExit({
    ...params,
    email: author,
  }).then(() => {
    toast.success(`${i18n.t('timeTracking:отчет направлен на формирование')}.`);
  });
  dispatch(fetchReportList);
};

export const createReportsActivity = (params) => async (dispatch, getState) => {
  if (selectAppState(getState()) !== 'loaded') return;
  const author = selectAccountEmail(getState());
  await apiContainer.timeTrackerClient.reports.createReportsActivity({
    ...params,
    email: author,
  }).then(() => {
    toast.success(`${i18n.t('timeTracking:отчет направлен на формирование')}.`);
  }).catch((error) => {
    toast.error(i18n.t('timeTracking:в процессе формирования отчета возникла ошибка', { error: error.response.data }));
    console.error(error.response.data);
  });
  dispatch(fetchReportList);
};

export const updatePageSize = (params) => async (dispatch) => {
  dispatch(setPageSize(params));
  dispatch(fetchReportList);
};

export const updatePage = (params) => async (dispatch) => {
  dispatch(setPage(params));
  dispatch(fetchReportList);
};
