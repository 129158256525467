import React, { useState, useEffect } from 'react';

import { CREATE_STREAM_STATUS_OPTIONS } from '@vlabs/api-bindings/src/luna-streams/constants';
import validate from '@vlabs/shared/validators';
import { Control, Fold, SettingsItemWrapper } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { selectGroupOptionsByAccountId } from '../../groups/selectors';

function CommonSubForm({
  getGroupOptionsByAccountId,
}) {
  const { t } = useTranslation();
  const [groupOptions, setGroupOptions] = useState([]);
  const { register, control, formState: { errors }, state, watch, setValue } = useFormContext();
  const statusValidationErrorMessage = t('lunaStreamsStreams:форма.common.доступные статусы', {
    statuses: CREATE_STREAM_STATUS_OPTIONS.raw.map(({ label }) => label).join(', '),
  });

  const accountId = watch('account_id');
  const selectedGroup = watch('group_name');

  useEffect(() => {
    const groupOptionsByAccountId = getGroupOptionsByAccountId(accountId);
    setGroupOptions(groupOptionsByAccountId);
    const selectedGroupIsAllowed = groupOptionsByAccountId
      .find((availableGroup) => selectedGroup?.value === availableGroup.value);
    if (selectedGroup && !selectedGroupIsAllowed) setValue('group_name', '');
  }, [accountId, getGroupOptionsByAccountId, setValue, selectedGroup]);

  return (
    <Fold isOpen title={t('lunaStreamsStreams:форма.common.заголовок')}>
      <SettingsItemWrapper title={t('lunaStreamsStreams:форма.common.account id')}>
        <Control.Input
          autoFocus
          errors={errors}
          {...register('account_id', {
            validate: validate.uuid(),
            required: validate.required(),
          })}
          disabled={state === 'edit'}
        />
      </SettingsItemWrapper>
      <SettingsItemWrapper title={t('lunaStreamsStreams:форма.common.name')}>
        <Control.Input
          {...register('name', {
            maxLength: validate.maxLength(128),
          })}
          errors={errors}
        />
      </SettingsItemWrapper>
      <SettingsItemWrapper title={t('lunaStreamsStreams:форма.common.description')}>
        <Control.Input
          {...register('description', {
            maxLength: validate.maxLength(512),
          })}
          errors={errors}
        />
      </SettingsItemWrapper>
      <SettingsItemWrapper title={t('lunaStreamsStreams:форма.common.group')}>
        <Control.Select
          control={control}
          errors={errors}
          isClearable
          isCreatable
          name="group_name"
          options={groupOptions}
          rules={{
            validate: (group) => validate.regex(/^[a-zA-Z0-9_\\-]{1,128}$/)(group?.value),
          }}
        />
      </SettingsItemWrapper>
      <SettingsItemWrapper title={t('lunaStreamsStreams:форма.common.status')}>
        <Control.Select
          control={control}
          errors={errors}
          name="status"
          options={CREATE_STREAM_STATUS_OPTIONS.raw}
          rules={{
            validate: {
              positive: (option) => CREATE_STREAM_STATUS_OPTIONS.raw
                .map(({ value }) => value)
                .includes(option?.value) || statusValidationErrorMessage,
            },
          }}
        />
      </SettingsItemWrapper>
    </Fold>
  );
}

CommonSubForm.propTypes = {
  getGroupOptionsByAccountId: PropTypes.func,
};

CommonSubForm.defaultProps = {
  getGroupOptionsByAccountId: undefined,
};

export default connect((state) => ({
  getGroupOptionsByAccountId: (accountId) => selectGroupOptionsByAccountId(state, accountId),
}))(CommonSubForm);
