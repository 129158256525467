import React, { useState } from 'react';

import { FilterIcon } from '@vlabs/icons';
import { Control } from '@vlabs/uikit';
import cn from 'classnames';

import st from './PageWithSidebar.module.sass';

// FIXME: перенести в кит, доработать левый бар
export function PageWithSidebar({
  rigthSidebar,
  filterCount,
  isStatic,
  children,
}) {
  const isSidebarOpen = !!isStatic;
  const [isRigthSidebarOpen, setIsRigthSidebarOpen] = useState(isSidebarOpen);

  return (
    <div className={cn(st.PageWithSidebar, {
      [st.RigthSidebarIsOpen]: isRigthSidebarOpen,
    })}
    >
      <div className={st.Content}>
        {children}
      </div>

      {!isStatic && (
        <div className={st.RigthIconPanel}>
          <Control.Button
            data-testid="filtersSidebar.toggleButton"
            onClick={() => setIsRigthSidebarOpen(!isRigthSidebarOpen)}
            variant={isRigthSidebarOpen ? 'invisible' : 'dimmed'}
          >
            <FilterIcon />
            {filterCount !== undefined && filterCount}
          </Control.Button>
        </div>
      )}

      <div className={st.RigthSidebar}>
        {rigthSidebar}
      </div>
    </div>
  );
}
