import React from 'react';

import PropTypes from 'prop-types';

import st from './cells.module.sass';

function GroupsCell({ value }) {
  return (
    <div
      data-testid="streamGroups"
    >
      {value.map((group) => (
        <span className={st.GroupCell} key={group.value}>
          {group.label}
        </span>
      ))}
    </div>
  );
}

GroupsCell.propTypes = {
  value: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })),
};

GroupsCell.defaultProps = {
  value: undefined,
};

export { GroupsCell };
