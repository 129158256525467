import React from 'react';

import PropTypes from 'prop-types';

import { Brand } from '../../brand/Brand';

import './Navbar.sass';

const Navbar = ({
  children,
  CustomBrand,
  ...restProps
}) => {
  return (
    <div className="Navbar" {...restProps}>
      {CustomBrand || <Brand />}
      <div className="Navbar__Links">
        {children}
      </div>
    </div>
  );
};

Navbar.propTypes = {
  children: PropTypes.node,
  CustomBrand: PropTypes.element,
};

Navbar.defaultProps = {
  children: undefined,
  CustomBrand: null,
};

export { Navbar };
