import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import './NotificationBubble.sass';

const NotificationBubble = ({
  count,
}) => {
  return (
    <span
      className={cn(
        'Small-1',
        {
          NotificationBubble: true,
          NotificationBubble_attention: count > 0,
        },
      )}
      data-testid="notificationBubble"
    >
      {count}
    </span>
  );
};

NotificationBubble.propTypes = {
  count: PropTypes.number,
};

NotificationBubble.defaultProps = {
  count: 0,
};

export { NotificationBubble };
