import React from 'react';

import validate from '@vlabs/shared/validators';
import {
  Page, Control, Margin, Grid, GridRow, GridCol,
} from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import st from './CreateHandlerForm.module.sass';

function DefineAttributesForm({
  onSubmit,
}) {
  const { t } = useTranslation();
  const { register, handleSubmit, formState: { errors } } = useForm();

  return (
    <form
      data-testid="modal.defineAttributes"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Page className={st.Page}>
        <Grid>
          <GridRow>
            <GridCol>
              <Margin display="block">
                <span className={st.Description}>
                  {t('handlers:сценарий "Определение атрибутов и свойств лица (пол, возраст, эмоции и др.) без идентификации" позволяет обнаружить все лица на кадре, выполнить все возможные проверки и сохранить событие.')}
                </span>
              </Margin>
              <Margin display="block">
                <div className="Body-2">
                  {t('handlers:форма.наименование сценария')}
                </div>
                <Control.Input
                  {...register('description', {
                    maxLength: validate.maxLength(128),
                    required: validate.required(),
                  })}
                  errors={errors}
                />
              </Margin>
            </GridCol>
          </GridRow>
        </Grid>
        <Margin display="block">
          <Control.Button
            fullWidth
            type="submit"
          >
            {t('handlers:создать')}
          </Control.Button>
        </Margin>
      </Page>
    </form>
  );
}

DefineAttributesForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default DefineAttributesForm;
