import React from 'react';

import { CircleCheckIcon, CircleCrossIcon } from '@vlabs/icons';
import i18n from 'i18next';

import './ProgressCell.sass';

const STATUS_OPTIONS = {
  error: i18n.t('constants:ошибка'),
  done: i18n.t('constants:готово'),
};

const ProgressCell = (status) => {
  const iconsMap = {
    error: <CircleCrossIcon className="ProgressCell_canceled" />,
    done: <CircleCheckIcon className="ProgressCell_finished" />,
  };

  return (
    <div className="ProgressCell">
      <div title={STATUS_OPTIONS[status]}>
        {iconsMap[status]}
      </div>
    </div>
  );
};

export default ProgressCell;
