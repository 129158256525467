/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import { PageWithSidebar } from '@vlabs/shared/components/page-with-sidebar';
import { Divider } from '@vlabs/uikit';

import { Filters } from './components/Filters';
import { ReferenceSelector } from './components/ReferenceSelector';
import { Results } from './components/Results';
import st from './SearchPage.module.sass';

const SearchPage = () => {
  return (
    <PageWithSidebar
      isStatic
      rigthSidebar={<Filters />}
    >
      <div className={st.Root}>
        <ReferenceSelector />
        <Divider small />
        <Results />
      </div>

    </PageWithSidebar>

  );
};

export default SearchPage;
