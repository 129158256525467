import { apiContainer } from '@vlabs/api-bindings';
import { TASKS } from '@vlabs/api-bindings/src/luna-client/constants';
import FileSaver from 'file-saver';
import i18next from 'i18next';
import { toast } from 'react-toastify';

const getCrossMatchCsvColumnNames = (data) => {
  const csvColumnNames = [i18next.t('tasks:эталонный ID лица')];
  let maxLength = 0;
  data.forEach(({ candidates }) => {
    if (maxLength < candidates.length) {
      maxLength = candidates.length;
    }
  });
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < maxLength; i++) {
    csvColumnNames.push(`${i18next.t('tasks:id лица кандидата')} ${i + 1}`);
    csvColumnNames.push(`${i18next.t('tasks:% схожести')} ${i + 1}`);
  }
  return csvColumnNames;
};

const saveCrossMatchResultAsCsv = (data, id) => {
  const csvRows = data.result.map(({ reference_id, candidates }) => {
    const newArr = [reference_id];
    candidates.forEach(({ candidate_id, similarity }) => {
      newArr.push(candidate_id);
      newArr.push(similarity);
    });
    return newArr;
  });

  const csvData = [getCrossMatchCsvColumnNames(data.result), ...csvRows];
  const csvContent = `data:text/csv;charset=utf-8,${csvData.map((e) => e.join(',')).join('\n')}`;
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');

  link.setAttribute('href', encodedUri);
  link.setAttribute('download', `${id}.csv`);
  document.body.appendChild(link);

  link.click();
};

const saveTaskResultAsJson = (data, id) => {
  const blob = new Blob([JSON.stringify(data, null, 2)], { type: 'application/json' });
  FileSaver.saveAs(blob, `${id}.json`);
};

const saveTaskResultAsZip = (data, id) => {
  FileSaver.saveAs(data, `${id}.zip`);
};

export const downloadResultHandler = async ({ task_id, task_type }) => {
  const taskType = TASKS.TASKS_TYPE[task_type].label;
  const exportConfigHeaders = {
    headers: { Accept: 'application/zip' },
    responseType: 'blob',
  };
  const configHeaders = taskType === 'export objects' ? exportConfigHeaders : {};
  let response;

  try {
    response = await apiContainer.lunaClient.tasks.result(task_id, configHeaders);
  } catch (e) {
    if ((e?.error_code === 28017 && e?.desc === 'Object not found') || e?.response?.status === 404) {
      toast.error(i18next.t('tasks:api.ошибка.отчет удален'));
      return;
    }
    throw e;
  }

  if (taskType === 'export objects') {
    saveTaskResultAsZip(response.data, task_id);
  } else if (taskType === 'cross match') {
    try {
      saveCrossMatchResultAsCsv(response.data, task_id);
    } catch (e) {
      saveTaskResultAsJson(response.data, task_id);
    }
  } else {
    saveTaskResultAsJson(response.data, task_id);
  }
};
