import React from 'react';

import { LinkBrokenIcon } from '@vlabs/icons';
import { ButtonWithPermissions } from '@vlabs/shared/components/button-with-permissions/ButtonWithPermissions';
import i18n from 'i18next';
import PropTypes from 'prop-types';

export const DetachCell = ({
  onClick,
  buttonProps,
  permissions,
  row,
}) => {
  return (
    <ButtonWithPermissions
      data-testid="detachCell"
      icon={<LinkBrokenIcon />}
      kind="primary"
      onClick={() => onClick(row.original)}
      permissions={permissions}
      round
      title={i18n.t('faces:кнопки.открепление')}
      variant="dimmed"
      {...(buttonProps || {})}
    />
  );
};

DetachCell.propTypes = ({
  row: PropTypes.objectOf(PropTypes.any),
  onClick: PropTypes.func,
  buttonProps: PropTypes.object,
  permissions: PropTypes.shape({
    rules: PropTypes.arrayOf(PropTypes.string).isRequired,
    method: PropTypes.oneOf(['oneof', 'allof']),
  }),
});

DetachCell.defaultProps = ({
  row: undefined,
  onClick: undefined,
  buttonProps: undefined,
  permissions: {},
});
