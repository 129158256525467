import React, { useContext } from 'react';

import { ButtonWithPermissions } from '@vlabs/shared/components/button-with-permissions/ButtonWithPermissions';
import { SizeOnlyPagination, IndeterminatePagination, Pagination, TableCells } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import '@vlabs/uikit/src/components/table/TableBottomPanel.sass';

const actionColumnProps = (props) => ({
  width: 40,
  minWidth: 40,
  maxWidth: 40,
  type: 'action',
  align: 'center',
  disableFilters: true,
  ...props,
});

const selectColumn = actionColumnProps({
  id: 'selection',
  Header: TableCells.SelectAllRowsCell,
  Cell: TableCells.SelectRowCell,
});

const TableBottomPanelWithPermissions = ({
  scrollToTop,
  CustomPagination,
  extraButtons,
  TableContext,
}) => {
  const { t } = useTranslation();
  const context = useContext(TableContext);
  const {
    selectedFlatRows,
    state: { pageIndex, pageSize },
    pageCount,
    setPageSize,
    pageSizeOptions,
    canPreviousPage,
    previousPage,
    canNextPage,
    nextPage,
    canFirstPage,
    firstPage,
    canLastPage,
    lastPage,
    gotoPage,
    paginationType,
    actions,
    onActions,
    visibleColumns,
  } = context;

  const withScrollToTop = (fn) => (...args) => {
    scrollToTop();
    fn(...args);
  };

  const $nextPage = withScrollToTop(nextPage);
  const $previousPage = withScrollToTop(previousPage);
  const $firstPage = withScrollToTop(firstPage);
  const $lastPage = withScrollToTop(lastPage);
  const $gotoPage = withScrollToTop(gotoPage);

  const onAddRow = actions.onAddRow?.getHandlerIfCan?.();
  const addRowPermissions = onActions.onAddRow?.permissions;
  const addingButtonLabel = actions.onAddRow?.label;

  const selectedRows = selectedFlatRows.map(({ original }) => original);

  const rowSelectionIsEnabled = visibleColumns.findIndex(({ id }) => id === selectColumn.id) >= 0;

  const onDeleteSelectedRows = actions.onDeleteRow?.getHandlerIfCan?.(selectedRows);
  const deleteSelectedRowPermissions = onActions.onDeleteRow?.permissions;
  const deletingButtonLabel = actions.onDeleteRow?.label;

  if (
    paginationType === undefined
    && CustomPagination === undefined
    && onAddRow === undefined
    && !rowSelectionIsEnabled
  ) return null;

  return (
    <div className="TableBottomPanel">
      <div className="TableBottomPanel__Controls">
        {onAddRow && (
          <ButtonWithPermissions
            data-testid="table.addButton"
            hasPermission={addRowPermissions}
            onClick={() => onAddRow()}
            permissions={addRowPermissions}
          >
            {addingButtonLabel || t('uikit:table.действия.добавление нового элемента')}
          </ButtonWithPermissions>
        )}
        {extraButtons && extraButtons(context)}
        {rowSelectionIsEnabled && onDeleteSelectedRows && (
          <ButtonWithPermissions
            data-testid="table.deleteButton"
            disabled={selectedRows.length === 0}
            kind="negative"
            onClick={() => onDeleteSelectedRows(selectedRows)}
            onKeyPress={() => onDeleteSelectedRows(selectedRows)}
            permissions={deleteSelectedRowPermissions}
          >
            {deletingButtonLabel || t('uikit:table.действия.удаление выбранных элементов')}
          </ButtonWithPermissions>
        )}
      </div>

      <div className="TableBottomPanel__Pagination">
        {paginationType === 'timeBased' && (
          <IndeterminatePagination
            canFirstPage={canFirstPage}
            canLastPage={canLastPage}
            canNextPage={canNextPage}
            canPreviousPage={canPreviousPage}
            firstPage={$firstPage}
            lastPage={$lastPage}
            nextPage={$nextPage}
            pageSize={pageSize}
            pageSizeOptions={pageSizeOptions}
            previousPage={$previousPage}
            setPageSize={setPageSize}
          />
        )}
        {paginationType === 'offsetBased' && (
          <Pagination
            canNextPage={canNextPage}
            canPreviousPage={canPreviousPage}
            gotoPage={$gotoPage}
            nextPage={$nextPage}
            pageCount={pageCount}
            pageIndex={pageIndex}
            pageSize={pageSize}
            pageSizeOptions={pageSizeOptions}
            previousPage={$previousPage}
            setPageSize={setPageSize}
          />
        )}
        {paginationType === 'sizeOnly' && (
          <SizeOnlyPagination
            pageSize={pageSize}
            pageSizeOptions={pageSizeOptions}
            setPageSize={setPageSize}
          />
        )}
        {CustomPagination && (
          <CustomPagination
            context={context}
            firstPage={$firstPage}
            gotoPage={$gotoPage}
            lastPage={$lastPage}
            nextPage={$nextPage}
            previousPage={$previousPage}
          />
        )}
      </div>
    </div>
  );
};

TableBottomPanelWithPermissions.propTypes = {
  scrollToTop: PropTypes.func.isRequired,
  TableContext: PropTypes.objectOf(PropTypes.any).isRequired,
  CustomPagination: PropTypes.func,
  extraButtons: PropTypes.func,
};

TableBottomPanelWithPermissions.defaultProps = {
  CustomPagination: undefined,
  extraButtons: undefined,
};

export { TableBottomPanelWithPermissions };
