import { loadProgressBar } from '@vlabs/axios-progress-bar';
import Axios from 'axios';

import '@vlabs/axios-progress-bar/dist/nprogress.css';
import { DEFAULT_HEALTHCHECK_TIMEOUT } from './config';
import { paramsSerializer } from './utils';

export class ClementineAuthClient {
  constructor() {
    this.baseURL = '/api/artemis';
    this.http = Axios.create({
      baseURL: this.baseURL,
      withCredentials: true,
      paramsSerializer,
    });
    loadProgressBar('', this.http);
  }

  healthcheck() {
    return this.http.head('/version', {
      timeout: DEFAULT_HEALTHCHECK_TIMEOUT,
    }).catch((e) => {
      if (e?.code === 'ECONNABORTED') throw e;
      if (e?.response?.status >= 500) throw e;
    });
  }

  async version() {
    const { data: { version } } = await this.http.get('/version');
    return version;
  }

  get auth() {
    const prefix = 'auth';
    return {
      getCurrentAccount: () => this.http.get(`/${prefix}/me`),
      login: (payload) => this.http.post(`/${prefix}/login`, payload),
      logout: () => this.http.post(`/${prefix}/logout`),
      reset: (accountId) => this.http.post(`/${prefix}/reset`, { accountId }),
      resetPassword: (token, password) => this.http.post(`/${prefix}/reset/${token}`, { password }),
    };
  }

  get accounts() {
    const prefix = 'accounts';
    return {
      create: (payload) => this.http.post(`/${prefix}`, payload),
      getAll: (params) => this.http.get(`/${prefix}`, { params }),
      get: (accountId) => this.http.get(`/${prefix}/${accountId}`),
      update: (accountId, payload) => {
        return this.http.patch(`/${prefix}/${accountId}`, Object.entries(payload)
          .filter((p) => p[1] !== null)
          .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {}));
      },
      delete: (accountId) => this.http.delete(`/${prefix}/${accountId}`),
    };
  }
}
