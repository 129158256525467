import React, { useState } from 'react';

import {
  FaceRecognitionIcon,
  FilesIcon,
  PersonsIcon,
  RecognitionIcon,
  StorageIcon,
  CircleInfoIcon,
} from '@vlabs/icons';
import { Menu, MenuItem } from '@vlabs/pages/handlers/components';
import {
  BiometricIdentificationForm,
  TemplateRegistrationForm,
  SaveUniqueForm,
  SaveIdentifiedForm,
  DefineAttributesForm,
  CreatedHandlerForm,
  BiometricIdentificationFaceQualityForm,
} from '@vlabs/pages/handlers/preconfigured-scenarios/index';
import { routes } from '@vlabs/shared/navigation/routes';
import { selectIsAppFeatureAvailable } from '@vlabs/shared/selectors/appSelectors';
import { selectListOptions } from '@vlabs/shared/selectors/listSelectors';
import { Page, Modal, Tooltip } from '@vlabs/uikit';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';

import { createPreconfiguredHandler } from '@vlabs/pages/handlers/store';

import st from './CreateHandlerPage.module.sass';
import {
  biometricIdentification,
  identificationFaceQuality,
  defineAttributes,
  saveIdentified,
  saveUnique,
  templateRegistration,
} from './createHandlerPageHelpers';

const CreateHandlerPage = ({
  createHandler,
  listOptions,
  isFaceQualityAvailable,
  isLivenessAvailable,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [createdModalIsOpen, setCreatedModalIsOpen] = useState(false);
  const [handlerType, setHandlerType] = useState(undefined);
  const [createdHandlerId, setCreatedHandlerId] = useState();
  const [handlerName, setHandlerName] = useState();

  const tooltipText = t(`handlers:setup sections.face quality.tooltip.licenses.${isFaceQualityAvailable}`);

  const onCreateForm = () => {
    history.push(generatePath(routes.handlers.other));
  };

  const onOpenModal = (scenarioType) => {
    if (scenarioType === 'faceQuality' && !isFaceQualityAvailable) return;
    setHandlerType(scenarioType);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const onOpenCreatedModal = () => {
    closeModal();
    setCreatedModalIsOpen(true);
  };

  const closeCreatedModal = () => {
    setCreatedModalIsOpen(false);
    setCreatedHandlerId(undefined);
  };

  const onSubmitForm = async (values, func) => {
    try {
      setCreatedHandlerId(
        await createHandler(func({
          ...values,
          // FIXME: Investigate
          liveness: isLivenessAvailable,
        })),
      );
      setHandlerName(values?.description);
      onOpenCreatedModal();
    } catch (error) {
      return undefined;
    }
    return undefined;
  };

  return (
    <Page>
      <Modal appElement={document.getElementById('root')} isOpen={modalIsOpen} onRequestClose={closeModal}>
        {handlerType === 'biometricIdentification' && (
          <BiometricIdentificationForm
            listOptions={listOptions}
            onSubmit={(v) => onSubmitForm(v, biometricIdentification)}
          />
        )}
        {handlerType === 'templateRegistration' && (
          <TemplateRegistrationForm
            listOptions={listOptions}
            onSubmit={(v) => onSubmitForm(v, templateRegistration)}
          />
        )}
        {handlerType === 'saveUnique' && (
          <SaveUniqueForm
            listOptions={listOptions}
            onSubmit={(v) => onSubmitForm(v, saveUnique)}
          />
        )}
        {handlerType === 'saveIdentified' && (
          <SaveIdentifiedForm
            listOptions={listOptions}
            onSubmit={(v) => onSubmitForm(v, saveIdentified)}
          />
        )}
        {handlerType === 'defineAttributes' && (
          <DefineAttributesForm
            onSubmit={(v) => onSubmitForm(v, defineAttributes)}
          />
        )}

        {handlerType === 'faceQuality' && (
          <BiometricIdentificationFaceQualityForm
            listOptions={listOptions}
            onSubmit={(v) => onSubmitForm(v, identificationFaceQuality)}
          />
        )}
      </Modal>

      <Modal
        appElement={document.getElementById('root')}
        isOpen={createdModalIsOpen}
        onRequestClose={closeCreatedModal}
      >
        <CreatedHandlerForm
          handlerId={createdHandlerId}
          handlerName={handlerName}
        />
      </Modal>

      <Menu isOpen title={t('handlers:выберите тип сценария, который хотите создать')}>
        <MenuItem
          onClick={() => onOpenModal('templateRegistration')}
          testId="handler.templateRegistration"
        >
          <div className={st.MenuItemContainer}>
            <RecognitionIcon className={st.MenuItemIcon} />
            {t('handlers:сценарий 1. Регистрация эталонного биометрического шаблона (с сохранением в список)')}
          </div>
        </MenuItem>

        <MenuItem
          onClick={() => onOpenModal('biometricIdentification')}
          testId="handler.biometricIdentification"
        >
          <div className={st.MenuItemContainer}>
            <FaceRecognitionIcon className={st.MenuItemIcon} />
            {t('handlers:сценарий 2. Биометрическая идентификация лиц без сохранения в список')}
          </div>
        </MenuItem>

        <MenuItem
          onClick={() => onOpenModal('saveIdentified')}
          testId="handler.saveIdentified"
        >
          <div className={st.MenuItemContainer}>
            <StorageIcon className={st.MenuItemIcon} />
            {t('handlers:сценарий 3. Сохранение в базе данных идентифицированных в списке лиц')}
          </div>
        </MenuItem>

        <MenuItem
          onClick={() => onOpenModal('defineAttributes')}
          testId="handler.defineAttributes"
        >
          <div className={st.MenuItemContainer}>
            <PersonsIcon className={st.MenuItemIcon} />
            {t('handlers:сценарий 4. Определение атрибутов и свойств лица (пол, возраст, эмоции и др.) без идентификации')}
          </div>
        </MenuItem>

        <MenuItem
          onClick={() => onOpenModal('saveUnique')}
          testId="handler.saveUnique"
        >
          <div className={st.MenuItemContainer}>
            <PersonsIcon className={st.MenuItemIcon} />
            {t('handlers:сценарий 5. Сохранение событий только для уникальных лиц для последующего подсчета')}
          </div>
        </MenuItem>

        <MenuItem
          disabled={!isFaceQualityAvailable}
          onClick={() => onOpenModal('faceQuality')}
          testId="handler.faceQuality"
        >
          <div className={st.MenuItemContainer}>
            <RecognitionIcon className={cn(st.MenuItemIcon, {
              [st.MenuItemIcon_disabled]: !isFaceQualityAvailable,
            })}
            />
            <div className={st.MenuItemTextTooltip}>
              {t('handlers:качество лица.наименование сценария')}
              <Tooltip
                className="Tooltip_ui"
                data-tooltip-content={tooltipText}
                tooltipProps={{ effect: 'solid' }}
              >
                <CircleInfoIcon className={cn({
                  [st.Tooltip__Icon]: true,
                  [st.Tooltip__Icon_notAvailable]: !isFaceQualityAvailable,
                })}
                />
              </Tooltip>
            </div>
          </div>
        </MenuItem>

        <MenuItem
          onClick={onCreateForm}
          testId="handler.other"
        >
          <div className={st.MenuItemContainer}>
            <FilesIcon className={st.MenuItemIcon} />
            {t('handlers:другой')}
          </div>
        </MenuItem>
      </Menu>
    </Page>
  );
};

CreateHandlerPage.propTypes = {
  isFaceQualityAvailable: PropTypes.bool,
  isLivenessAvailable: PropTypes.bool,
  createHandler: PropTypes.func.isRequired,
  listOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.any,
  })),
};
CreateHandlerPage.defaultProps = {
  isFaceQualityAvailable: false,
  isLivenessAvailable: false,
  listOptions: [],
};

export default connect((state) => ({
  listOptions: selectListOptions(state),
  isLivenessAvailable: selectIsAppFeatureAvailable(state, 'estimations.liveness'),
  isFaceQualityAvailable: selectIsAppFeatureAvailable(state, 'estimations.face_quality'),
}),
(dispatch) => ({
  createHandler: (data) => dispatch(createPreconfiguredHandler(data)),
}))(CreateHandlerPage);
