import React, { useCallback } from 'react';

import { selectListOptions } from '@vlabs/shared/selectors/listSelectors';
import validate from '@vlabs/shared/validators';
import { Control, SettingsItemWrapper } from '@vlabs/uikit';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useFieldArray, useFormContext, useFormState, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { useUpdateField } from '../hooks';
import { defaultFilters } from './constants';
import GenericPolicySubForm from './GenericPolicySubForm';

function FaceToListPolicySubFormComponent({
  listOptions,
}) {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({ control, name: 'faceToListPolicy.lists' });
  const { errors } = useFormState();
  // FIXME: непонятно, что за disabledLists
  const disabledLists = useWatch({ name: 'faceToListPolicy.lists' }).map(({ list }) => list);
  const faceToListPolicyIsEnabled = useWatch({ name: 'faceToListPolicy.isEnabled' });

  useUpdateField({
    path: 'faceToListPolicy.lists',
    on: useCallback(() => faceToListPolicyIsEnabled === false, [faceToListPolicyIsEnabled]),
    value: [],
    updateIf: useCallback((v) => !isEmpty(v), []),
  });

  return (
    <>
      {faceToListPolicyIsEnabled && fields.map((field, i) => (
        <div className="LinkToListsPolicy" key={field.id}>
          <SettingsItemWrapper node={(
            <div className="MatchPolicy_Dropdown">
              <Control.Select
                control={control}
                errors={errors}
                name={`faceToListPolicy.lists.${i}.list`}
                options={listOptions}
                rules={{
                  validate: (list) => {
                    if (!list) return validate.required();
                    if (list?.isDeleted) return t('handlers:валидация.список');
                    return undefined;
                  },
                }}
              />
            </div>
        )}
          >
            <Control.Button
              kind="negative"
              onClick={() => remove(i)}
              variant="outlined"
            >
              {t('Удалить блок')}
            </Control.Button>
          </SettingsItemWrapper>
          <GenericPolicySubForm
            disabled={!disabledLists[i]}
            fieldName={`faceToListPolicy.lists.${i}.filters`}
            filtersTitle={t('Прикреплять в тех случаях, если')}
            matchTitle={t('Прикреплять в тех случаях, если лицо было найдено')}
          />
        </div>
      ))}
      {faceToListPolicyIsEnabled && (
      <SettingsItemWrapper>
        <Control.Button
          onClick={() => append({ list: undefined, filters: defaultFilters })}
          variant="outlined"
        >
          {t('Добавить список')}
        </Control.Button>
      </SettingsItemWrapper>
      )}
    </>
  );
}

FaceToListPolicySubFormComponent.propTypes = {
  listOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })),
};

FaceToListPolicySubFormComponent.defaultProps = {
  listOptions: [],
};

export const FaceToListPolicySubForm = connect((state) => ({
  listOptions: selectListOptions(state),
}))(FaceToListPolicySubFormComponent);
