import React, { memo } from 'react';

import { IconInner } from '../../IconInner';

const KeyIconInner = (props) => (
  <IconInner name="KeyIcon" viewBox="0 0 24 24" {...props}>
    <path d="M15.5 7.5 19 4m2-2-2 2 2-2Zm-9.61 9.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777l.001-.001Zm0 0L15.5 7.5l-4.11 4.11ZM15.5 7.5l3 3L22 7l-3-3-3.5 3.5Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
  </IconInner>
);

export const KeyIcon = memo(KeyIconInner);
