import React, { useEffect, useState } from 'react';

import { optionPropType } from '@vlabs/shared';
import { START_OF_DAY, END_OF_DAY } from '@vlabs/shared/config';
import SelectOptionGroupLabel from '@vlabs/shared/legacy-components/select-option-group-label/SelectOptionGroupLabel';
import validate from '@vlabs/shared/validators';
import { Page, Control, Divider, Grid, GridCol, GridRow } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import st from './ReportForm.module.sass';

function ReportLeavingForm({
  handlerOptions,
  maskOptions,
  camOptions,
  onSubmit,
  availableHandlers,
}) {
  const { t } = useTranslation();
  const [listOptions, setListOptions] = useState([]);

  const formMethods = useForm();

  const {
    control,
    reset,
    getValues,
    watch,
    handleSubmit,
    formState: {
      errors,
    } } = formMethods;

  useEffect(() => {
    const sub = watch((data, { name }) => {
      if (name === 'handlerId') {
        const newValues = getValues();

        newValues.listsId = null;
        newValues.mask = null;
        setListOptions(availableHandlers[data.handlerId.value]?.listOptions);

        reset(newValues);
      }
    });

    return () => sub.unsubscribe();
  }, [watch, setListOptions, availableHandlers, getValues, reset]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Page className={st.Root} title={t('timeTracking:уходы')}>
        <Divider small />

        <Grid>
          <GridRow>
            <GridCol cols={6}>
              <Control.Select
                control={control}
                errors={errors}
                id="handlerId"
                label={t('handler')}
                name="handlerId"
                options={handlerOptions}
                rules={{ required: validate.required() }}
              />
            </GridCol>

            <GridCol cols={6}>
              <Control.Select
                control={control}
                errors={errors}
                id="listsId"
                isClearable
                isMulti
                label={t('list')}
                name="listsId"
                options={watch('handlerId') ? listOptions : []}
                rules={{ required: validate.required() }}
              />
            </GridCol>

          </GridRow>

          <GridRow>
            <GridCol cols={6}>
              <Control.DateInput
                control={control}
                enableTime
                errors={errors}
                id="dateStart"
                label={t('дата создания от')}
                name="dateStart"
                options={START_OF_DAY}
                rules={{ required: validate.required() }}
              />
            </GridCol>

            <GridCol cols={6}>
              <Control.Select
                control={control}
                errors={errors}
                formatGroupLabel={SelectOptionGroupLabel}
                id="cameras"
                isClearable
                isCreatable
                isMulti
                label={t('source')}
                name="cameras"
                options={camOptions}
                rules={{ required: validate.required() }}
              />
            </GridCol>
          </GridRow>

          <GridRow>
            <GridCol cols={6}>
              <Control.DateInput
                control={control}
                enableTime
                errors={errors}
                id="dateEnd"
                label={t('дата создания до')}
                name="dateEnd"
                options={END_OF_DAY}
                rules={{ required: validate.required() }}
              />
            </GridCol>

            <GridCol cols={6}>
              <Control.Select
                control={control}
                id="mask"
                isClearable
                isCreatable
                label={t('маска')}
                name="mask"
                options={maskOptions}
              />
            </GridCol>
          </GridRow>

        </Grid>
        <Divider />

        <div className={st.ButtonPanel}>
          <Control.Button
            kind="attention"
            onClick={() => reset({ handlerId: null })}
          >
            {t('кнопка.сбросить')}
          </Control.Button>

          <Divider small />

          <Control.Button
            type="submit"
          >
            {t('timeTracking:сформировать отчет')}
          </Control.Button>

        </div>
      </Page>
    </form>
  );
}

ReportLeavingForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  handlerOptions: PropTypes.arrayOf(optionPropType),
  camOptions: PropTypes.arrayOf(optionPropType),
  maskOptions: PropTypes.arrayOf(optionPropType),
  availableHandlers: PropTypes.objectOf(PropTypes.any),
};

ReportLeavingForm.defaultProps = {
  handlerOptions: [],
  camOptions: [],
  maskOptions: [],
  availableHandlers: {},
};

export default ReportLeavingForm;
