export const calcSimilarityPercent = (similarity) => (
  similarity
    ? `${Math.round(similarity * 100 * 100) / 100}%`
    : undefined
);

export function bytesConverter(inputValue) {
  const sizes = {
    undefined: 0,
    k: 1,
    m: 2,
    g: 3,
  };
  const value = inputValue.trim().match(/^\d+/)[0];
  if (value === undefined) return undefined;
  let unit = inputValue.trim().match(/[a-zA-Z]+$/)[0];
  unit = unit?.toLowerCase();
  return value * 1024 ** sizes[unit];
}
