import shared_en from './shared_en.json';
import shared_ru from './shared_ru.json';

// FIXME: обсудить использование
export const shared = {
  en: {
    shared: shared_en,
  },
  ru: {
    shared: shared_ru,
  },
};
