import { loadProgressBar } from '@vlabs/axios-progress-bar';
import '@vlabs/axios-progress-bar/dist/nprogress.css';
import Axios from 'axios';
import formatISO from 'date-fns/formatISO';
import i18n from 'i18next';
import qs from 'qs';

import { filterEmptyKeys } from '../utils';
import { vlAccessMappers as mappers } from './mappers';

const urlBuilder = (prefix) => (...parts) => {
  // пустая строка чтобы слеш был еще и в конце
  return [prefix, ...parts, ''].join('/');
};

const urlOther = urlBuilder('');

export class VLAccessClient {
  constructor(baseURL) {
    this.baseURL = baseURL;
    this.baseURLWithVersion = `${this.baseURL}`;
    this.http = Axios.create({
      baseURL: this.baseURLWithVersion,
      withCredentials: true,
      paramsSerializer: (initialParams) => {
        const params = filterEmptyKeys(initialParams);
        return qs.stringify(params, {
          arrayFormat: 'repeat',
          serializeDate: formatISO,
        });
      },
    });
    loadProgressBar('', this.http);
  }

  async healthcheck() {
    try {
      return await this.http.get('/version/');
    } catch (e) {
      if (e?.code === 'ECONNABORTED') throw e;
      if (e?.response?.status >= 500) throw e;
    }
    return undefined;
  }

  get settings() {
    const settings = urlBuilder('settings');
    return {
      download: () => this.http.get(settings('download'), {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      }),
      clean: () => this.http.post(settings('clean')),
      upload: (formData) => this.http.post(settings(), mappers.settings.to(formData), {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      }),
    };
  }

  get services() {
    const service = urlBuilder('service');
    return {
      create: (typeName, formData, schema) => this.http.post(
        service(),
        mappers.dynamic.toCreate(typeName, formData, schema),
      ),
      update: (id, formData, schema) => this.http.patch(
        service(id),
        mappers.dynamic.toUpdate(formData, schema),
      ),
      getActive: (params) => this.http.get(service('active'), { params }),
      get: (id) => this.http.get(service(id)),
      getTypes: () => this.http.get(service()),
      restart: (id) => this.http.post(service(id, 'restart')),
      delete: (id) => this.http.delete(service(id)),
    };
  }

  get controllers() {
    const controller = urlBuilder('controller');
    return {
      create: (typeName, formData, schema) => this.http.post(
        controller(),
        mappers.dynamic.toCreate(typeName, formData, schema),
      ),
      update: (id, formData, schema) => this.http.patch(
        controller(id),
        mappers.dynamic.toUpdate(formData, schema),
      ),
      getActive: (params) => this.http.get(controller('active'), { params }),
      getActiveByParent: (id) => this.http.get(controller('active'), {
        params: { relation_component_id: id },
      }),
      get: (id) => this.http.get(controller(id)),
      getTypes: () => this.http.get(controller()),
      getGroups: () => this.http.get(controller('groups')),
      restart: (id) => this.http.post(controller(id, 'restart')),
      delete: (id) => this.http.delete(controller(id)),
    };
  }

  get devices() {
    const device = urlBuilder('device');
    return {
      create: (typeName, formData, schema) => this.http.post(
        device(),
        mappers.dynamic.toCreate(typeName, formData, schema),
      ),
      update: (id, formData, schema) => this.http.patch(
        device(id),
        mappers.dynamic.toUpdate(formData, schema),
      ),
      getActive: (params) => this.http.get(device('active'), { params }),
      get: (id) => this.http.get(device(id)),
      getTypes: () => this.http.get(device()),
      getGroups: () => this.http.get(device('groups')),
      restart: (id) => this.http.post(device(id, 'restart')),
      delete: (id) => this.http.delete(device(id)),
    };
  }

  get sources() {
    const sources = urlBuilder('clementine');
    return {
      getAll: () => this.http.get(sources('sources')),
    };
  }

  get pipelines() {
    const pipeline = urlBuilder('pipeline');
    return {
      create: (typeName, formData, schema) => this.http.post(
        pipeline(),
        mappers.dynamic.toCreate(typeName, formData, schema),
      ),
      update: (id, formData, schema) => this.http.patch(
        pipeline(id),
        mappers.dynamic.toUpdate(formData, schema),
      ),
      getActive: (params) => this.http.get(pipeline('active'), { params }),
      get: (id) => this.http.get(pipeline(id)),
      getTypes: () => this.http.get(pipeline()),
      restart: (id) => this.http.post(pipeline(id, 'restart')),
      delete: (id) => this.http.delete(pipeline(id)),
    };
  }

  get auth() {
    const auth = urlBuilder('auth');
    return {
      login: (payload) => this.http.post(auth('token'), payload),
      logout: () => this.http.delete(auth('token')),
    };
  }

  get schema() {
    const schema = urlBuilder('schema');
    return {
      get: (type, name) => this.http.get(schema(type, name)),
    };
  }

  get docs() {
    const docs = urlBuilder('docs');
    return {
      get: (type, name) => this.http.get(docs(type, name), { params: { lang: i18n.language } }),
    };
  }

  get logs() {
    const logs = urlBuilder('logs');
    return {
      getAll: (params) => this.http.get(logs(), { params: mappers.getLogsQS(params) }),
      download: (filters) => this.http.get(logs('download'), { params: mappers.getLogsQS(filters) }),
    };
  }

  async version() {
    const { data: { version } } = await this.http.get(urlOther('version'));
    return version;
  }

  async getTZOffset() {
    try {
      const resp = await this.http.get('/now', { baseURL: '/' });
      const { data: now } = resp;
      if (now[now.length - 1] === 'Z') return 0;
      const { sign, hours, minutes } = now.match(/(?<sign>[+-])(?<hours>\d{2}):(?<minutes>\d{2})$/).groups;
      const offset = (parseInt(hours, 10) * 60 + parseInt(minutes, 10)) * (sign === '-1' ? 1 : -1);
      return offset;
    } catch (error) {
      if (error?.response?.status === 404) {
        return new Date().getTimezoneOffset();
      }
      throw error;
    }
  }
}
