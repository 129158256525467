import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { useAsyncDebounce } from 'react-table';

import { Input } from '../../controls/input/Input';

export function buildTextFilter() {
  const TextFilter = ({
    column,
  }) => {
    const { filterValue, setFilter } = column;
    const [value, setValue] = useState(filterValue);

    const onClearValue = () => {
      setFilter('');
      setValue('');
    };

    const onDebouncedChange = useAsyncDebounce((val) => {
      setFilter(val || undefined);
    }, 1000);

    const onChange = (e) => {
      setValue(e.target.value);
      onDebouncedChange(e.target.value);
    };

    return (
      <Input
        data-testid="textFilter"
        inputStyle="implicit"
        isClearable
        onChange={onChange}
        onClear={onClearValue}
        value={value || ''}
      />
    );
  };

  TextFilter.propTypes = {
    column: PropTypes.shape({
      filterValue: PropTypes.any,
      setFilter: PropTypes.func,
    }).isRequired,
  };

  return TextFilter;
}
