import { POLICIES } from '@vlabs/api-bindings/src/luna-client/handlers/constants';
// разделила на две части, так как уведомления для Telegram появились только для задач
import { CALLBACK_TYPE_OPTIONS } from '@vlabs/api-bindings/src/luna-client/tasks/constants';

export const defaultHttpValues = {
  type: POLICIES.CALLBACK.type.http,
  authorization: {},
  url: '',
  params: {
    timeout: 60,
    content_type: POLICIES.CALLBACK.params.content_type['application/json'],
    headers: '',
  },
};

export const defaultTelegramValues = {
  type: CALLBACK_TYPE_OPTIONS.telegram,
  token: '',
  chat_id: null,
  params: {
    timeout: 60,
  },
};
