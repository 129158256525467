import React from 'react';

import { Control, SettingsItemWrapper } from '@vlabs/uikit';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const EstimationsSubForm = () => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  return (
    <>
      <SettingsItemWrapper
        data-tooltip-content={t('verifiers:form.extract.basicAttributes.tooltip')}
        title={t('verifiers:form.extract.basicAttributes.label')}
      >
        <Control.Switch
          control={control}
          name="extract.basicAttributes"
        />
      </SettingsItemWrapper>

      <SettingsItemWrapper
        data-tooltip-content={t('verifiers:form.estimate.headPose.tooltip')}
        title={t('verifiers:form.estimate.headPose.label')}
      >
        <Control.Switch
          control={control}
          name="estimate.headPose"
        />
      </SettingsItemWrapper>

      <SettingsItemWrapper
        title={t('verifiers:form.estimate.emotion.label')}
      >
        <Control.Switch
          control={control}
          name="estimate.emotion"
        />
      </SettingsItemWrapper>

      <SettingsItemWrapper
        data-tooltip-content={t('verifiers:form.estimate.mask.tooltip')}
        title={t('verifiers:form.estimate.mask.label')}
      >
        <Control.Switch
          control={control}
          name="estimate.mask"
        />
      </SettingsItemWrapper>

      <SettingsItemWrapper
        data-tooltip-content={t('verifiers:form.estimate.quality.tooltip')}
        title={t('verifiers:form.estimate.quality.label')}
      >
        <Control.Switch
          control={control}
          name="estimate.quality"
        />
      </SettingsItemWrapper>

      <SettingsItemWrapper
        data-tooltip-content={t('verifiers:form.estimate.gaze.tooltip')}
        title={t('verifiers:form.estimate.gaze.label')}
      >
        <Control.Switch
          control={control}
          name="estimate.gaze"
        />
      </SettingsItemWrapper>

      <SettingsItemWrapper
        title={t('verifiers:form.estimate.glasses.label')}
      >
        <Control.Switch
          control={control}
          name="estimate.glasses"
        />
      </SettingsItemWrapper>

      <SettingsItemWrapper
        data-tooltip-content={t('verifiers:form.estimate.eyes.tooltip')}
        title={t('verifiers:form.estimate.eyes.label')}
      >
        <Control.Switch
          control={control}
          name="estimate.eyes"
        />
      </SettingsItemWrapper>

      <SettingsItemWrapper
        data-tooltip-content={t('verifiers:form.estimate.mouth.tooltip')}
        title={t('verifiers:form.estimate.mouth.label')}
      >
        <Control.Switch
          control={control}
          name="estimate.mouth"
        />
      </SettingsItemWrapper>

      <SettingsItemWrapper
        data-tooltip-content={t('verifiers:form.estimate.landmarks68.tooltip')}
        title={t('verifiers:form.estimate.landmarks68.label')}
      >
        <Control.Switch
          control={control}
          name="estimate.landmarks68"
        />
      </SettingsItemWrapper>

      <SettingsItemWrapper
        data-tooltip-content={t('verifiers:form.extract.exif.tooltip')}
        title={t('verifiers:form.extract.exif.label')}
      >
        <Control.Switch
          control={control}
          name="extract.exif"
        />
      </SettingsItemWrapper>
    </>
  );
};

export default EstimationsSubForm;
