import { useState } from 'react';

import { DEFAULT_PAGE_SIZE_OPTIONS, DEFAULT_PAGE_SIZE } from '@vlabs/uikit/src/constants';
// FIXME: Пересмотреть необходимость использования этого хука и его реализацию
function usePagination(
  defaultPageSize = DEFAULT_PAGE_SIZE,
  defaultPageIndex = 0,
  pageSizeOptions = DEFAULT_PAGE_SIZE_OPTIONS) {
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [pageIndex, setPageIndex] = useState(defaultPageIndex);

  return {
    pageIndex,
    setPageIndex,
    pageSize,
    setPageSize,
    pageSizeOptions,
  };
}

export { usePagination };
