import { get, CleanSchema } from '../../../json-transform';
import { notificationPolicySchema } from './notificationPolicySchema';

const arrayOfKeys = (columns) => Object.entries(columns)
  .reduce((acc, [key, value]) => (value ? [...acc, key] : acc), []);

const getMetaObject = (meta) => {
  const updatedMetaObject = {};
  meta.forEach((m) => {
    updatedMetaObject[`${m.key}__${m.operator.value}`] = m.value;
  });
  return updatedMetaObject;
};

export const exporter = new CleanSchema({
  description: get('description'),
  notification_policy: get('notification_policy').asSchema(notificationPolicySchema),
  content: {
    objects_type: get('content.objects_type.value'),
    columns: get('content.columns').as(arrayOfKeys).ifEmpty(undefined),
    save_images: get('content.save_images').asInt().ifNaN(undefined),
    csv_delimiter: get('content.csv_delimiter').ifEmptyString(undefined),
    descriptor: {
      type: get('content.descriptor.type.value'),
    },
    filters: {
      meta: get('meta').as(getMetaObject),
      face_ids: get('content.filters.face_ids').asArrayFromString().ifEmpty(undefined),
      event_ids: get('content.filters.event_ids').asArrayFromString().ifEmpty(undefined),
      external_ids: get('content.filters.external_ids').asArrayFromString().ifEmpty(undefined),
      user_data: get('content.filters.user_data').asString().ifEmptyString(undefined),
      top_matching_candidates_label: get('content.filters.top_matching_candidates_label').asString().ifEmptyString(undefined),
      top_similar_object_ids: get('content.filters.top_similar_object_ids').asArrayFromString().ifEmpty(undefined),
      top_similar_external_ids: get('content.filters.top_similar_external_ids').asArrayFromString().ifEmpty(undefined),
      list_id: get('content.filters.list_id.value'),
      create_time__gte: get('content.filters.create_time__gte[0]').asISOString(),
      create_time__lt: get('content.filters.create_time__lt[0]').asISOString(),
      end_time__gte: get('content.filters.end_time__gte[0]').asISOString(),
      end_time__lt: get('content.filters.end_time__lt[0]').asISOString(),
      event_id__gte: get('content.filters.event_id__gte').ifEmptyString(undefined),
      event_id__lt: get('content.filters.event_id__lt').ifEmptyString(undefined),
      face_id__gte: get('content.filters.face_id__gte').ifEmptyString(undefined),
      face_id__lt: get('content.filters.face_id__lt').ifEmptyString(undefined),
      handler_ids: get('content.filters.handler_ids').asArrayOfOptionValues().ifEmpty(undefined),
      top_similar_object_similarity__gte: get('content.filters.top_similar_object_similarity__gte').asFloat().ifNaN(undefined),
      top_similar_object_similarity__lt: get('content.filters.top_similar_object_similarity__lt').asFloat().ifNaN(undefined),
      age__gte: get('content.filters.age.value[0]').asInt().ifNaN(undefined),
      age__lt: get('content.filters.age.value[1]').asInt().ifNaN(undefined),
      gender: get('content.filters.gender.value').asInt().ifNaN(undefined),
      emotions: get('content.filters.emotions').asArrayOfOptionValues().asArrayWithOptionalNull().ifEmpty(undefined),
      masks: get('content.filters.masks').asArrayOfOptionValues().asArrayWithOptionalNull().ifEmpty(undefined),
      sources: get('content.filters.sources').asArrayOfOptionValues().asArrayWithOptionalNull().ifEmpty(undefined),
      liveness: get('content.filters.liveness').asArrayOfOptionValues().asArrayWithOptionalNull().ifEmpty(undefined),
      tags: get('content.filters.tags').asArrayFromString().ifEmpty(undefined),
      cities: get('content.filters.cities').asArrayFromString().asArrayWithOptionalNull().ifEmpty(undefined),
      areas: get('content.filters.areas').asArrayFromString().asArrayWithOptionalNull().ifEmpty(undefined),
      districts: get('content.filters.districts').asArrayFromString().asArrayWithOptionalNull().ifEmpty(undefined),
      streets: get('content.filters.streets').asArrayFromString().asArrayWithOptionalNull().ifEmpty(undefined),
      house_numbers: get('content.filters.house_numbers').asArrayFromString().asArrayWithOptionalNull().ifEmpty(undefined),
      track_ids: get('content.filters.track_ids').asArrayFromString().asArrayWithOptionalNull().ifEmpty(undefined),
      geo_position: {
        origin_longitude: get('content.filters.origin_longitude').asFloat().ifNaN(undefined),
        origin_latitude: get('content.filters.origin_latitude').asFloat().ifNaN(undefined),
        longitude_delta: get('content.filters.longitude_delta').asFloat().ifNaN(undefined),
        latitude_delta: get('content.filters.latitude_delta').asFloat().ifNaN(undefined),
      },
      apparent_gender: get('content.filters.apparent_gender').asArrayOfOptionValues().asArrayWithOptionalNull().ifEmpty(undefined),
      headwear_apparent_colors: get('content.filters.headwear_apparent_colors').asArrayOfOptionValues().asArrayWithOptionalNull().ifEmpty(undefined),
      shoes_apparent_colors: get('content.filters.shoes_apparent_colors').asArrayOfOptionValues().asArrayWithOptionalNull().ifEmpty(undefined),
      lower_garment_colors: get('content.filters.lower_garment_colors').asArrayOfOptionValues().asArrayWithOptionalNull().ifEmpty(undefined),
      lower_garment_types: get('content.filters.lower_garment_types').asArrayOfOptionValues().asArrayWithOptionalNull().ifEmpty(undefined),
      apparent_age__gte: get('content.filters.apparent_age.value[0]').asInt().ifNaN(undefined),
      apparent_age__lt: get('content.filters.apparent_age.value[1]').asInt().ifNaN(undefined),
      headwear_states: get('content.filters.headwear_states').asArrayOfOptionValues().asArrayWithOptionalNull().ifEmpty(undefined),
      sleeve_lengths: get('content.filters.sleeve_lengths').asArrayOfOptionValues().asArrayWithOptionalNull().ifEmpty(undefined),
      upper_clothing_colors: get('content.filters.upper_clothing_colors').asArrayOfOptionValues().asArrayWithOptionalNull().ifEmpty(undefined),
      backpack_states: get('content.filters.backpack_states').asArrayOfOptionValues().asArrayWithOptionalNull().ifEmpty(undefined),
    },
  },
});
