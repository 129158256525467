import React from 'react';

import { optionPropType } from '@vlabs/shared';
import { START_OF_DAY, END_OF_DAY } from '@vlabs/shared/config';
import { selectListOptions } from '@vlabs/shared/selectors/listSelectors';
import validate from '@vlabs/shared/validators';
import { Control, Divider } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import st from './FiltersForm.module.sass';

const defaultValues = {
  list_id: null,
  create_time__gte: null,
  create_time__lt: null,
  face_ids: '',
  user_data: '',
};

function FiltersForm({
  listOptions,
  onSubmit,
}) {
  const { t } = useTranslation();

  // FIXME: если мы хотим показывать только те списки которые привязаны к лицам с конкретным внешним айди, то нужно написать доп логику
  // пройти по массиву лиц, вытащить lists и отфильтровать listOptions
  const form = useForm({ defaultValues });

  const onReset = () => {
    onSubmit(defaultValues);
    form.reset(defaultValues);
  };

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <div className={st.Root}>
        <div className={st.Content}>
          {/* FIXME:  фильтры по лицам (как и по событиям) используются в ряде разделов и в апи эти фильтры идентичны,
          поэтому нужно вынести их в одну какую-то сабформу (часть формы) и переиспользовать */}
          <Control.Select
            control={form.control}
            id="list_id"
            isClearable
            label={t('externalID:filters.list_id.label')}
            name="list_id"
            options={listOptions}
            placeholder={t('externalID:filters.list_id.placeholder')}
          />
          <Divider small />

          <Control.DateInput
            control={form.control}
            enableTime
            id="create_time__gte"
            label={t('externalID:filters.create_time__gte.label')}
            name="create_time__gte"
            options={START_OF_DAY}
            placeholder={t('externalID:filters.create_time__gte.placeholder')}
          />
          <Divider small />

          <Control.DateInput
            control={form.control}
            enableTime
            id="create_time__lt"
            label={t('externalID:filters.create_time__lt.label')}
            name="create_time__lt"
            options={END_OF_DAY}
            placeholder={t('externalID:filters.create_time__lt.placeholder')}
          />
          <Divider small />

          <Control.Input
            errors={form.formState.errors}
            id="face_ids"
            label={t('externalID:filters.face_ids.label')}
            placeholder={t('externalID:filters.face_ids.placeholder')}
            {...form.register('face_ids', { validate: validate.multipleUuid() })}
          />
          <Divider small />

          <Control.Input
            errors={form.formState.errors}
            id="user_data"
            label={t('externalID:filters.user_data.label')}
            placeholder={t('externalID:filters.user_data.placeholder')}
            {...form.register('user_data')}
          />
        </div>

        <div className="StickyBottomContainer">
          <div className={st.ButtonPanel}>
            <Control.Button
              data-testid="filtersForm.submitButton"
              type="submit"
            >
              {t('externalID:action.filter')}
            </Control.Button>

            <Divider small />

            <Control.Button
              data-testid="filtersForm.resetButton"
              kind="warning"
              onClick={onReset}
            >
              {t('externalID:button.reset')}
            </Control.Button>
          </div>
        </div>
      </div>
    </form>
  );
}

FiltersForm.propTypes = {
  listOptions: PropTypes.arrayOf(optionPropType),
  onSubmit: PropTypes.func.isRequired,
};

FiltersForm.defaultProps = {
  listOptions: [],
};

const $FiltersForm = connect(
  (state) => ({
    listOptions: selectListOptions(state),
  }),
)(FiltersForm);

export { $FiltersForm as FiltersForm };
