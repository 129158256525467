import React from 'react';

import { optionPropType } from '@vlabs/shared';
import { START_OF_DAY, END_OF_DAY } from '@vlabs/shared/config';
import { selectListOptions } from '@vlabs/shared/selectors/listSelectors';
import validate from '@vlabs/shared/validators';
import { Grid, GridCol, GridRow, Divider, Control } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

function SearchByFaces({
  filterName,
  listOptions,
}) {
  const { t } = useTranslation();
  const prefix = filterName ? `${filterName}.` : '';
  const { control, register, formState: { errors } } = useFormContext();

  return (
    <Grid>
      <GridRow>
        <GridCol cols={6}>
          <div className="Body-2 MatchPolicy__InputLabel">{t('list')}</div>
          <Control.Select
            control={control}
            errors={errors}
            isClearable
            isCreatable
            name={`${prefix}list`}
            options={listOptions}
            rules={{ validate: validate.uuidOptions }}
          />
          <Divider small />

          <div className="Body-2 MatchPolicy__InputLabel">
            {t('пользовательские данные')}
          </div>
          <Control.Input
            {...register(`${prefix}userData`, { maxLength: validate.maxLength(128) })}
            errors={errors}
          />

          <Divider small />

          <div className="Body-2 MatchPolicy__InputLabel">
            {t('дата создания от')}
          </div>
          <Control.DateInput
            control={control}
            enableTime
            name={`${prefix}createTimeGte`}
            options={START_OF_DAY}
          />
        </GridCol>

        <GridCol cols={6}>
          <div className="Body-2 MatchPolicy__InputLabel">{t('идентификаторы лиц через запятую')}</div>
          <Control.Input
            errors={errors}
            placeholder={t('идентификатор')}
            {...register(`${prefix}faceIds`, { validate: validate.multipleUuid() })}
          />
          <Divider small />

          <div className="Body-2 MatchPolicy__InputLabel">{t('внешние идентификаторы лиц через запятую')}</div>
          <Control.Input
            {...register(`${prefix}externalIds`, {
              validate: validate.multipleExternalId() })
            }
            errors={errors}
            placeholder={t('идентификатор')}
          />
          <Divider small />

          <div className="Body-2 MatchPolicy__InputLabel">{t('дата создания до')}</div>
          <Control.DateInput
            control={control}
            enableTime
            name={`${prefix}createTimeLt`}
            options={END_OF_DAY}
          />
        </GridCol>
      </GridRow>
    </Grid>
  );
}

SearchByFaces.propTypes = {
  filterName: PropTypes.string,
  listOptions: PropTypes.arrayOf(optionPropType),
};

SearchByFaces.defaultProps = {
  filterName: '',
  listOptions: [],
};

export default connect((state) => ({
  listOptions: selectListOptions(state),
}))(SearchByFaces);
