import { openConfirmPopup } from '@vlabs/uikit';
import i18next from 'i18next';

export const showResourceIntensiveTaskPopup = (onConfirm) => {
  openConfirmPopup({
    title: i18next.t('tasks:подтверждение.ресурсоёмкая задача.title'),
    message: i18next.t('tasks:подтверждение.ресурсоёмкая задача.message'),
    type: 'info',
    onConfirm,
  });
};
