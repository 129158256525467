import { useState, useEffect, useCallback } from 'react';
// ХУК ИСПОЛЬЗУЕТСЯ ДЛЯ ВЫПОЛНЕНИЯ ЗАПРОСОВ К API
// ЗАПРОС ИДЁТ САМ ПО МОНТИРОВАНИЮ
// ПАРАМЕТРОМИ ПРИНИМАЕТ ОБЪЕКТ, ГДЕ ДОЛЖНА БЫТЬ САМА ФУНКЦИЮ ЗАПРОСА
// И ПАРАМЕТРЫ, КОТОРЫЕ МОГУТ БЫТЬ МАССИВОМ ИЛИ ЕДИНИЧНОЙ ПЕРЕМЕННОЙ
// ЕСЛИ ПАРАМЕТРЫ ПЕРЕДАНЫ МАССИВОМ,- ОНИ БУДУТ ПЕРЕДАНЫ В ФУНКЦИЮ ЧЕРЕЗ ЗАПЯТУЮ В ТОМ ПОРЯДКЕ, В КАКОМ ОНИ ИДУТ В ИСХОДНОМ МАССИВЕ
// ЕСЛИ ПАРАМЕТР - ПРОСТО ПЕРЕМНЕННАЯ, ТО ОН БУДЕТ ПЕРЕДАН В ФУНКЦИЮ КАК ЕСТЬ
// ВОЗВРАЩАЕТ:
// 1 - ОТВЕТ ОТ СЕРВЕРА В ФОРМАТЕ data, meta
// 2 - ФУНКЦИЮ ЗАПРОСА
// 3 - ФЛАГ СОСТОЯНИЯ ПРОЦЕССА ЗАГРУЗКИ

// FIXME: Пересмотреть необходимость использования этого хука и его реализацию
function useApiRequest({ apiRequest, parameters }) {
  const [state, setState] = useState({ data: null, meta: null });
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = Array.isArray(parameters)
        ? await apiRequest(...parameters)
        : await apiRequest(parameters);

      setState({ data: response?.data || response, meta: response?.meta || null });
    } catch (error) {
      setState({ error: error.message });
    } finally {
      setIsLoading(false);
    }
  }, [apiRequest, parameters]);

  useEffect(() => {
    fetchData();
  }, [parameters]);

  return {
    state,
    isLoading,
    fetchData,
  };
}

export { useApiRequest };
