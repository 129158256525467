import {
  LunaClient, ClementineAuthClient, ClementineDepartmentsClient,
  NotifierClient, LunaConfigurator, VLTimeTrackerClient,
  VLAccessClient,
  VAReporterClient, VAToolboxClient, apiContainer, LunaStreamsClient,
} from '@vlabs/api-bindings';

apiContainer.add('lunaClient', new LunaClient('/api/lp5'));
apiContainer.add('clementineAuthClient', new ClementineAuthClient());
apiContainer.add('clementineDepartmentsClient', new ClementineDepartmentsClient());
apiContainer.add('lunaConfigurator', new LunaConfigurator());
apiContainer.add('notifierClient', new NotifierClient());
apiContainer.add('timeTrackerClient', new VLTimeTrackerClient());
apiContainer.add('vaReporterClient', new VAReporterClient());
apiContainer.add('vaToolboxClient', new VAToolboxClient());
apiContainer.add('vlAccessClient', new VLAccessClient('/api/vl-access'));
apiContainer.add('lunaStreamsClient', new LunaStreamsClient('/api/luna-streams'));
