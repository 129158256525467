import { get, CleanSchema, Schema } from '../../../json-transform';
import { TASKS } from '../../constants';
import { notificationPolicySchema } from './notificationPolicySchema';

const sourceSchemas = {
  [TASKS.ESTIMATOR.SOURCE.SOURCE_TYPE.zip.value]: new CleanSchema({
    source_type: TASKS.ESTIMATOR.SOURCE.SOURCE_TYPE.zip.value,
    reference: get('reference').as((value) => {
      const result = new CleanSchema({
        url: get('reference.url').asString(),
        prefix: get('reference.prefix').asString().ifEmptyString(undefined),
        postfix: get('reference.postfix').asString().ifEmptyString(undefined),
      })(value);
      if (!result.prefix && !result.postfix) return value;
      return result;
    }),
    authorization: {
      password: get('authorization.password').asString().ifEmptyString(undefined),
    },
    recursive: get('recursive').asBoolean(),
    image_type: get('image_type.value').asInt().ifNaN(undefined),
  }),
  [TASKS.ESTIMATOR.SOURCE.SOURCE_TYPE.ftp.value]: new CleanSchema({
    source_type: TASKS.ESTIMATOR.SOURCE.SOURCE_TYPE.ftp.value,
    reference: {
      host: get('reference.host').asString(),
      port: get('reference.port').asInt().ifNaN(undefined),
      max_sessions: get('reference.max_sessions').asInt().ifNaN(undefined),
      path: get('reference.path').asString().ifEmptyString(undefined),
      prefix: get('reference.prefix').asString().ifEmptyString(undefined),
      postfix: get('reference.postfix').asString().ifEmptyString(undefined),
    },
    authorization: {
      user: get('authorization.user').asString().ifEmptyString(undefined),
      password: get('authorization.password').asString().ifEmptyString(undefined),
    },
    recursive: get('recursive').asBoolean(),
    image_type: get('image_type.value').asInt().ifNaN(undefined),
  }),
  [TASKS.ESTIMATOR.SOURCE.SOURCE_TYPE.samba.value]: new CleanSchema({
    source_type: TASKS.ESTIMATOR.SOURCE.SOURCE_TYPE.samba.value,
    reference: {
      host: get('reference.host').asString(),
      port: get('reference.port').asInt().ifNaN(undefined),
      path: get('reference.path').asString().ifEmptyString(undefined),
      prefix: get('reference.prefix').asString().ifEmptyString(undefined),
      postfix: get('reference.postfix').asString().ifEmptyString(undefined),
    },
    authorization: {
      user: get('authorization.user').asString().ifEmptyString(undefined),
      password: get('authorization.password').asString().ifEmptyString(undefined),
    },
    recursive: get('recursive').asBoolean(),
    image_type: get('image_type.value').asInt().ifNaN(undefined),
  }),
  [TASKS.ESTIMATOR.SOURCE.SOURCE_TYPE.network_disk.value]: new CleanSchema({
    source_type: TASKS.ESTIMATOR.SOURCE.SOURCE_TYPE.network_disk.value,
    reference: {
      path: get('reference.path').asString().ifEmptyString(undefined),
      prefix: get('reference.prefix').asString().ifEmptyString(undefined),
      postfix: get('reference.postfix').asString().ifEmptyString(undefined),
      follow_links: get('reference.follow_links').asBoolean(),
    },
    recursive: get('recursive').asBoolean(),
    image_type: get('image_type.value').asInt().ifNaN(undefined),
  }),
  [TASKS.ESTIMATOR.SOURCE.SOURCE_TYPE.s3.value]: new CleanSchema({
    source_type: TASKS.ESTIMATOR.SOURCE.SOURCE_TYPE.s3.value,
    reference: {
      endpoint: get('reference.endpoint').asString().ifEmptyString(undefined),
      bucket_name: get('reference.bucket_name').asString().ifEmptyString(undefined),
      prefix: get('reference.prefix').asString().ifEmptyString(undefined),
      region: get('reference.region').asString().ifEmptyString(undefined),
    },
    authorization: get('authorization').as((value) => {
      const result = new CleanSchema({
        public_access_key: get('public_access_key').asString().ifEmptyString(undefined),
        secret_access_key: get('secret_access_key').asString().ifEmptyString(undefined),
        signature_version: get('signature_version.value').asString().ifEmptyString(undefined),
      })(value);
      if (!result.public_access_key && !result.secret_access_key) return undefined;
      return result;
    }),
    recursive: get('recursive').asBoolean(),
    save_origin: get('save_origin.value').asBoolean(),
    image_type: get('image_type.value').asInt().ifNaN(undefined),
  }),
};
const optionValue = (option) => option?.value;

const faceMatchPolicySchema = new CleanSchema({
  label: get('label'),
  limit: get('limit').asInt().ifNaN(undefined),
  threshold: get('threshold').asFloat().toNormalizedPercent().ifNaN(undefined),
  candidates: {
    origin: get('origin.value'),
    face_ids: get('faceIds').asArrayFromString().ifEmpty(undefined),
    account_id: get('accountId'),
    external_ids: get('externalIds').asArrayFromString().ifEmpty(undefined),
    user_data: get('userData').ifEmptyString(undefined),
    face_id__gte: get('faceIdGte'),
    face_id__lt: get('faceIdLt'),
    list_id: get('list.value'),
    create_time__gte: get('createTimeGte[0]').asISOString(),
    create_time__lt: get('createTimeLt[0]').asISOString(),
  },
});

const eventMatchPolicySchema = new CleanSchema({
  limit: get('limit').asInt().ifNaN(undefined),
  threshold: get('threshold').asFloat().toNormalizedPercent().ifNaN(undefined),
  candidates: {
    origin: get('origin.value').asString(),
    event_ids: get('eventIds').asArrayFromString().ifEmpty(undefined),
    account_id: get('accountId'),
    event_id__gte: get('eventIdGte'),
    event_id__lt: get('eventIdLt'),
    handler_ids: get('handlers').asArrayOf(optionValue).ifEmpty(undefined),
    external_ids: get('externalIds').asArrayFromString().ifEmpty(undefined),
    top_matching_candidates_label: get('topMatchingCandidatesLabel'),
    top_similar_object_ids: get('topSimilarObjectIds').asArrayFromString().ifEmpty(undefined),
    top_similar_object_similarity__gte: get('topSimilarObjectSimilarityGte').asFloat().ifNaN(undefined),
    top_similar_object_similarity__lt: get('topSimilarObjectSimilarityLt').asFloat().ifNaN(undefined),
    age__gte: get('age.value[0]').asInt().ifNaN(undefined),
    age__lt: get('age.value[1]').asInt().ifNaN(undefined),
    gender: get('gender.value').asInt().ifNaN(undefined),
    apparent_gender: get('apparent_gender').asArrayOf(optionValue).asArrayWithOptionalNull().ifEmpty(undefined),
    apparent_age__gte: get('apparent_age.value[0]').asInt().ifNaN(undefined),
    apparent_age__lt: get('apparent_age.value[1]').asInt().ifNaN(undefined),
    upper_clothing_colors: get('upper_clothing_colors').asArrayOf(optionValue).asArrayWithOptionalNull().ifEmpty(undefined),
    headwear_states: get('headwear_states').asArrayOf(optionValue).asArrayWithOptionalNull().ifEmpty(undefined),
    headwear_apparent_colors: get('headwear_apparent_colors').asArrayOf(optionValue).asArrayWithOptionalNull().ifEmpty(undefined),
    sleeve_lengths: get('sleeve_lengths').asArrayOf(optionValue).asArrayWithOptionalNull().ifEmpty(undefined),
    backpack_states: get('backpack_states').asArrayOf(optionValue).asArrayWithOptionalNull().ifEmpty(undefined),
    lower_garment_colors: get('lower_garment_colors').asArrayOf(optionValue).asArrayWithOptionalNull().ifEmpty(undefined),
    lower_garment_types: get('lower_garment_types').asArrayOf(optionValue).asArrayWithOptionalNull().ifEmpty(undefined),
    shoes_apparent_colors: get('shoes_apparent_colors').asArrayOf(optionValue).asArrayWithOptionalNull().ifEmpty(undefined),
    emotions: get('emotions').asArrayOf(optionValue).asArrayWithOptionalNull().ifEmpty(undefined),
    liveness: get('liveness').asArrayOf(optionValue).asArrayWithOptionalNull().ifEmpty(undefined),
    masks: get('masks').asArrayOf(optionValue).asArrayWithOptionalNull().ifEmpty(undefined),
    ethnic_groups: get('ethnicities').asArrayOf(optionValue).asArrayWithOptionalNull().ifEmpty(undefined),
    face_ids: get('faceIds').asArrayFromString().ifEmpty(undefined),
    user_data: get('userData').ifEmptyString(undefined),
    sources: get('sources').asArrayOf(optionValue).asArrayWithOptionalNull().ifEmpty(undefined),
    tags: get('tags').asArrayFromString().ifEmpty(undefined),
    cities: get('cities').asArrayFromString().asArrayWithOptionalNull().ifEmpty(undefined),
    areas: get('areas').asArrayFromString().asArrayWithOptionalNull().ifEmpty(undefined),
    districts: get('districts').asArrayFromString().asArrayWithOptionalNull().ifEmpty(undefined),
    streets: get('streets').asArrayFromString().asArrayWithOptionalNull().ifEmpty(undefined),
    house_numbers: get('houseNumbers').asArrayFromString().asArrayWithOptionalNull().ifEmpty(undefined),
    track_ids: get('trackIds').asArrayFromString().ifEmpty(undefined),
    create_time__gte: get('createTimeGte[0]').asISOString(),
    create_time__lt: get('createTimeLt[0]').asISOString(),
    geo_position: {
      origin_longitude: get('originLongitude').asFloat().ifNaN(undefined),
      origin_latitude: get('originLatitude').asFloat().ifNaN(undefined),
      longitude_delta: get('longitudeDelta').asFloat().ifNaN(undefined),
      latitude_delta: get('latitudeDelta').asFloat().ifNaN(undefined),
    },
  },
});

const handlerSchema = new CleanSchema({
  handler_id: get('handler_id').as((v) => (v?.value ? v.value : v)),
  description: get('description').ifEmpty(undefined),
  policies: {
    extract_policy: {
      extract_basic_attributes: get('policies.extract_policy.extract_basic_attributes').asInt().ifNaN(undefined),
    },
    match_policy: get('policies.matchPolicy', []).asArrayOf((item) => {
      switch (get('origin.value')(item)) {
        case 'faces': return faceMatchPolicySchema(item);
        case 'events': return eventMatchPolicySchema(item);
        default: return item;
      }
    }).ifEmpty(undefined),
    storage_policy: {
      face_policy: {
        store_face: get('policies.storage_policy.face_policy.store_face').asInt().ifNaN(undefined),
        link_to_lists_policy: get('policies.storage_policy.face_policy.link_to_lists_policy', []).asArrayOf(new Schema({
          list_id: get('list_id.value'),
        })).ifEmpty(undefined),
      },
      attribute_policy: {
        store_attribute: get('policies.storage_policy.attribute_policy.store_attribute').asInt().ifNaN(undefined),
      },
      notification_policy: {
        send_notification: get('policies.storage_policy.notification_policy.send_notification').asInt().ifNaN(undefined),
      },
    },
    detect_policy: {
      detect_face: get('policies.detect_policy.detect_face').asInt().ifNaN(undefined),
      face_quality: {
        estimate: get('policies.detect_policy.face_quality.estimate').asInt().ifNaN(undefined),
        filter: get('policies.detect_policy.face_quality.filter').asInt().ifNaN(undefined),
      },
    },
  },
});

export const estimator = new CleanSchema({
  description: get('description').asString(),
  notification_policy: get('notification_policy').asSchema(notificationPolicySchema),
  content: {
    handler: get('content.handler').asSchema(handlerSchema),
    source: get('content.source').as(
      ({ source_type, ...sources }) => {
        const schema = sourceSchemas[source_type.value];
        const obj = sources[source_type.value];
        return schema(obj);
      },
    ),
  },
});
