import React from 'react';

import { temperatureImages } from '@vlabs/shared/assets/images';
import { Divider } from '@vlabs/uikit';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';

import { Card } from './Card';
import st from './Card.module.sass';

const TEMPERATURE_IMAGES = {
  't#abnormal': temperatureImages.Abormal,
  't#high': temperatureImages.High,
  't#normal': temperatureImages.Normal,
};

export const TemperatureCard = ({ temperature, tag }) => {
  const img = TEMPERATURE_IMAGES[tag];

  return (
    <Card
      back={(
        <div className={st.Tooltip}>
          <Trans i18nKey="events:карточка.температура" values={{ temperature }} />
        </div>
      )}
      front={(
        <div
          className={cn(st.Temperature, {
            [st.Temperature_normal]: tag === 't#normal',
            [st.Temperature_high]: tag === 't#high',
            [st.Temperature_abnormal]: tag === 't#abnormal',
          })}
          data-testid="eventAttributesCell.temperature"
        >
          <img alt={`${tag}`} className={st.Image} src={img} />
          <Divider small />
          {`${temperature}°C`}
        </div>
      )}
    />
  );
};

TemperatureCard.propTypes = {
  tag: PropTypes.string,
  temperature: PropTypes.string,
};

TemperatureCard.defaultProps = {
  tag: undefined,
  temperature: undefined,
};
