import React from 'react';

import PropTypes from 'prop-types';

import './GridRow.sass';

const GridRow = ({
  children,
}) => {
  return (
    <div className="GridRow">
      {children}
    </div>
  );
};

GridRow.propTypes = {
  children: PropTypes.node.isRequired,
};

export { GridRow };
