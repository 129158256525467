// eslint-disable-next-line import/order
import './index.sass';

import { kitEnTranslations } from './i18n/en';
import { kitRuTranslations } from './i18n/ru';

export { regexp } from './components/utils/regexps';
export { validator } from './components/utils/validators';

export { optionsProxy } from './utils/optionsProxy';

export const i18n = {
  ru: kitRuTranslations,
  en: kitEnTranslations,
};

export * as ActionColumns from './components/table/actionColumns';

export {
  DEFAULT_SUPPORTED_IMAGE_TYPES,
  DEFAULT_MAX_IMAGE_SIZE,
  DEFAULT_SUPPORTED_ARCHIVE_TYPES,
  DEFAULT_MAX_ARCHIVE_SIZE,
} from './components/controls/utils';

export * as errorToasts from './components/error-toasts';

export { Account } from './components/account/Account';
export { AccountMenuButton } from './components/account/account-menu-item/AccountMenuButton';
export { AccountMenuLink } from './components/account/account-menu-item/AccountMenuLink';
export { AccountMenu } from './components/account/account-menu/AccountMenu';
export { ConfirmPopup, openConfirmPopup } from './components/confirm-popup/ConfirmPopup';
export { Avatar } from './components/avatar/Avatar';
export { Badge } from './components/badge/Badge';
export { Brand } from './components/brand/Brand';
export { Breadcrumbs } from './components/breadcrumbs/Breadcrumbs';
export { ButtonScrimGroup } from './components/button-scrim-group/ButtonScrimGroup';
export { ButtonScrimItem } from './components/button-scrim-group/ButtonScrimItem';
export { ButtonToggle } from './components/button-toggle/ButtonToggle';
export { ButtonToggleGroup } from './components/button-toggle/ButtonToggleGroup';
export { Cam } from './components/cam/Cam';
export { Can, can } from './components/can/Can';
export { Control } from './components/controls';
export { CopyButton } from './components/copy-paste/CopyButton';
export { Divider } from './components/divider/Divider';
export { FaceCard } from './components/face-card/FaceCard';
export { FaceSelector } from './components/face-selector/FaceSelector';
export { FieldWrapper } from './components/field-wrapper/FieldWrapper';
export { Forms } from './components/forms';
export { Grid } from './components/grids/Grid';
export { GridCol } from './components/grids/GridCol';
export { GridRow } from './components/grids/GridRow';
export { Group } from './components/group/Group';
export { ImagesPointsDetector } from './components/images-points/images-points-detector/ImagesPointsDetector';
export { ImagesPointsMatcher } from './components/images-points/images-points-matcher/ImagesPointsMatcher';
export { Layout } from './components/layout';
export { LanguageSelector } from './components/language-selector/LanguageSelector';
export { ListSelector } from './components/list-selector/ListSelector';
export { Margin } from './components/margin/Margin';
export { MetaInfo } from './components/meta/MetaInfo';
export { Modal } from './components/modal/Modal';
export { Page } from './components/page/Page';
export { Panel } from './components/panel/Panel';
export { ProgressBar } from './components/progress-bar/ProgressBar';
export { ROIPreview } from './components/roi-preview/ROIPreview';
export { ROI } from './components/roi/ROI';
export { Setting } from './components/setting/Setting';
export { SettingsItemWrapper } from './components/settings-item-wrapper/SettingsItemWrapper';
export { SimilarityBadge } from './components/similarity-badge/SimilarityBadge';
export { Slider } from './components/slider/Slider';
export { Step } from './components/stepper/Step';
export { Stepper } from './components/stepper/Stepper';
export { Sticky } from './components/sticky/Sticky';
export { Fold } from './components/fold/Fold';
export { TableCells } from './components/table/cells';
export { TableFilters } from './components/table/filters';
export { Table } from './components/table/Table';
export { Tab } from './components/tabs/Tab';
export { Tabs } from './components/tabs/Tabs';
export { Tooltip } from './components/tooltip/Tooltip';
export { useTableHook } from './hooks/useTableHook';
export { useWindowSize } from './hooks/useWindowSize';
export { useModal } from './hooks/useModal';
export { Pages } from './pages';
export { Pagination } from './components/pagination/pagination/Pagination';
export { IndeterminatePagination } from './components/pagination/indeterminate-pagination/IndeterminatePagination';
export { SizeOnlyPagination } from './components/pagination/size-only-pagination/SizeOnlyPagination';
