import React from 'react';

import { FRAME_PROCESSING_MODE_OPTIONS } from '@vlabs/api-bindings/src/luna-streams/constants';
import validate from '@vlabs/shared/validators';
import { Control, Fold, SettingsItemWrapper } from '@vlabs/uikit';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

function AdditionalPoliciesSubForm() {
  const { t } = useTranslation();
  const { register, control, formState: { errors } } = useFormContext();
  return (
    <Fold isOpen title={t('lunaStreamsStreams:форма.additional policies.заголовок')}>
      <SettingsItemWrapper title={t('lunaStreamsStreams:форма.additional policies.frame_processing_mode')}>
        <Control.Select
          control={control}
          name="policies.frame_processing_mode"
          options={FRAME_PROCESSING_MODE_OPTIONS.raw}
        />
      </SettingsItemWrapper>
      <SettingsItemWrapper title={t('lunaStreamsStreams:форма.additional policies.ffmpeg_threads_number')}>
        <Control.Input
          {...register('policies.ffmpeg_threads_number', {
            min: validate.gte(0),
            max: validate.lte(2147483647),
            validate: validate.integer(),
          })}
          errors={errors}
        />
      </SettingsItemWrapper>
      <SettingsItemWrapper title={t('lunaStreamsStreams:форма.additional policies.real_time_mode_fps')}>
        <Control.Input
          {...register('policies.real_time_mode_fps', {
            min: validate.gte(0),
            max: validate.lte(2147483647),
            validate: validate.integer(),
          })}
          errors={errors}
        />
      </SettingsItemWrapper>
    </Fold>
  );
}

AdditionalPoliciesSubForm.propTypes = {

};

export default AdditionalPoliciesSubForm;
