import React, { memo } from 'react';

import { IconInner } from '../../IconInner';

const PlayerPlayInCircleIconInner = (props) => (
  <IconInner name="PlayerPlayInCircleIcon" viewBox="0 0 24 24" {...props}>
    <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
    <path d="m10 8 6 4-6 4V8Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
  </IconInner>
);

export const PlayerPlayInCircleIcon = memo(PlayerPlayInCircleIconInner);
