import { versionGetter } from '../../utils';
import { createStream } from './createStream';
import { getStreamsQS } from './getStreamsQS';
import { linker } from './linker';
import { readStream } from './readStream';

export { createStream, readStream };
export function buildBindedProxy(client) {
  const versions = {
    _: {
      readStream: readStream(client),
      createStream,
      getStreamsQS,
      linker,
    },
  };
  // const version = await client.version();
  // if (version?.major && Object.keys(versions).includes(version.major)) return versions[version.major];
  return versions._;
}
export default new Proxy({
  _: {
    readStream,
    createStream,
    getStreamsQS,
  },
}, versionGetter);
