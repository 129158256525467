/* eslint-disable no-restricted-globals */
import i18next from 'i18next';

const UUID_PATTERN_STR = '[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}';
export const MULTIPLE_UUID_PATTERN = new RegExp(`^(?:${UUID_PATTERN_STR})(?:(?:\\s*,\\s*)(?:${UUID_PATTERN_STR}))*$`);
export const UUID_PATTERN = new RegExp(`^${UUID_PATTERN_STR}$`);

const TRACK_ID_PATTERN_STR = '[a-zA-Z0-9_\\-]{1,36}';
export const MULTIPLE_TRACK_ID_PATTERN = new RegExp(`^(?:${TRACK_ID_PATTERN_STR})(?:(?:\\s*,\\s*)(?:${TRACK_ID_PATTERN_STR}))*$`);

export const MULTIPLE_EXTERNAL_ID_PATTERN = /^([^,\r\n\t\0]{0,36})(?:,\s?([^,\r\n\t\0]{0,36}))*$/u;
export const ROI_PATTERN = /^\s*[0-9]+,\s*[0-9]+,\s*[0-9]+,\s*[0-9]+\s*$/;

export const EMAIL_PATTERN = /(^[-!#$%&'*+/=?^`{}|~\w]+(\.[-!#$%&'*+/=?^`{}|~\w]+)*|^"([!#-[\]-\u007f]|\\)*")@(?:[A-Z0-9](?:[A-Z0-9-]{0,61}[A-Z0-9])?\.)+(?:[A-Z]{2,6}|[A-Z0-9-]{2,})$|^\[(25[0-5]|2[0-4]\d|[0-1]?\d?\d)(\.(25[0-5]|2[0-4]\d|[0-1]?\d?\d)){3}\]$/iu;
export const URI_PATTERN = /^https?:\/\/.+/;
export const URL_PATTERN = /^(\/[^/\s]*)+\w/g;
export const META_KEY_PATTERN = /^[a-zA-Z0-9]+(_[a-zA-Z0-9]+)*_?$/;

export const isEmptyValue = (value) => !value || value === undefined || value === '' || value?.length === 0;

export const regexValidator = (regex, message) => (value) => {
  if (isEmptyValue(value)) return true;
  if (value.match(regex)) return true;

  return message || i18next.t('валидация.regex', { regex });
};

export const multipleTrackIdsValidator = (message) => (value) => regexValidator(
  MULTIPLE_TRACK_ID_PATTERN,
  message || i18next.t('валидация.regex', { regex: TRACK_ID_PATTERN_STR }),
)(value);

export const UUIDValidator = (message) => (value) => regexValidator(
  UUID_PATTERN,
  message || i18next.t('валидация.uuid'),
)(value);

export const multipleUUIDValidator = (message) => (value) => regexValidator(
  MULTIPLE_UUID_PATTERN,
  message || i18next.t('валидация.uuid'),
)(value);

export const UUIDOptionsValidator = (v) => {
  if (isEmptyValue(v)) return true;

  const formattedValues = Array.isArray(v) ? v.map(({ value }) => value) : [v.value];
  const isIdsValid = formattedValues.every((value) => value.match(UUID_PATTERN));
  if (isIdsValid) return true;

  return i18next.t('валидация.uuid');
};

export const multipleExternalIdValidator = (message) => (value) => regexValidator(
  MULTIPLE_EXTERNAL_ID_PATTERN,
  message || i18next.t('валидация.длина.максимум для каждого id', { count: 36 }),
)(value);

export const emailValidator = (message) => (value) => regexValidator(
  EMAIL_PATTERN,
  message || i18next.t('валидация.email'),
)(value);

export const UriValidator = (message) => (value) => regexValidator(
  URI_PATTERN,
  message || i18next.t('валидация.uri'),
)(value);

export const UrlValidator = (message) => (value) => regexValidator(
  URL_PATTERN,
  message || i18next.t('валидация.url'),
)(value);

export const ROIValidator = (message) => (value) => regexValidator(
  ROI_PATTERN,
  message || i18next.t('валидация.roi'),
)(value);

export const integerValidator = (message) => (value) => {
  if (isEmptyValue(value)) return true;
  if (Number.isInteger(Number(value))) return true;
  return message || i18next.t('валидация.число.целое');
};

export const numberValidator = (message) => (value) => {
  if (isEmptyValue(value)) return true;
  if (!isNaN(value)) return true;
  return message || i18next.t('валидация.число.только числа');
};

export const metaValueValidator = (type) => (value) => {
  if (isEmptyValue(value)) return true;
  if (type === 'integer' && !Number.isInteger(Number(value))) return i18next.t('валидация.число.целое');
  if (type === 'numeric' && isNaN(value)) return i18next.t('валидация.число.только числа');
  return true;
};

export const metaKeyValidator = (message) => (value) => regexValidator(
  META_KEY_PATTERN,
  message || i18next.t('валидация.meta.key'),
)(value);

export const maxLengthValidator = (value, message) => ({
  value,
  message: message || i18next.t('валидация.длина.максимум', { count: value }),
});

export const minLengthValidator = (value, message) => ({
  value,
  message: message || i18next.t('валидация.длина.минимум', { count: value }),
});

export const requiredValidator = (message) => message ?? i18next.t('валидация.обязательно для заполнения');

export const lteValidator = (value, message) => ({
  value,
  message: message || i18next.t('валидация.число.меньше или равно', { value }),
});

export const gteValidator = (value, message) => ({
  value,
  message: message || i18next.t('валидация.число.больше или равно', { value }),
});

export const rangeValidator = ({
  left,
  right,
  context,
}, message) => (value) => {
  if (isEmptyValue(value)) return true;
  const STRICT_ENUM = ['none', 'left', 'right', 'both'];
  if (left === undefined || right === undefined || context === undefined) {
    throw new Error(`left, right and context thresholds are required in rangeValidator, given: ${JSON.stringify({
      left,
      right,
      context,
    })}`);
  }
  if (!STRICT_ENUM.includes(context)) throw new Error(`available values for context are [${STRICT_ENUM.join(', ')}] in rangeValidator, given: ${context}`);
  if (context === 'none') {
    if (value <= left) return message || i18next.t('валидация.число.диапазон', { left, right, context });
    if (value >= right) return message || i18next.t('валидация.число.диапазон', { left, right, context });
  }
  if (context === 'left') {
    if (value <= left) return message || i18next.t('валидация.число.диапазон', { left, right, context });
    if (value > right) return message || i18next.t('валидация.число.диапазон', { left, right, context });
  }
  if (context === 'right') {
    if (value < left) return message || i18next.t('валидация.число.диапазон', { left, right, context });
    if (value >= right) return message || i18next.t('валидация.число.диапазон', { left, right, context });
  }
  if (context === 'both') {
    if (value < left) return message || i18next.t('валидация.число.диапазон', { left, right, context });
    if (value > right) return message || i18next.t('валидация.число.диапазон', { left, right, context });
  }
  return true;
};

export default {
  lte: lteValidator,
  gte: gteValidator,
  maxLength: maxLengthValidator,
  minLength: minLengthValidator,
  number: numberValidator,
  integer: integerValidator,
  range: rangeValidator,
  regex: regexValidator,
  uuid: UUIDValidator,
  uuidOptions: UUIDOptionsValidator,
  multipleUuid: multipleUUIDValidator,
  multipleExternalId: multipleExternalIdValidator,
  required: requiredValidator,
  roi: ROIValidator,
  email: emailValidator,
  uri: UriValidator,
  url: UrlValidator,
  multipleTrackIds: multipleTrackIdsValidator,
  metaKey: metaKeyValidator,
  metaValue: metaValueValidator,
};
