import { DEEPFAKE_MODE_OPTIONS, MULTIFACE_POLICY_OPTIONS } from '@vlabs/api-bindings/src/constants';
import { FACE_QUALITY_EYE, FACE_QUALITY_SHOULDERS_POSITION, FACE_QUALITY_EYEBROWS_STATE, FACE_QUALITY_FACE_COLOR_TYPES, FACE_QUALITY_GLASSES, FACE_QUALITY_HEADWEAR_TYPE, FACE_QUALITY_IMAGE_FORMAT, FACE_QUALITY_SMILE_PROPERTIES, FACE_QUALITY_YES_NO } from '@vlabs/api-bindings/src/luna-client/handlers/constants';

export const defaultHandler = {
  extract: {
    faceDescriptor: true,
    basicAttributes: true,
  },
  estimate: {
    face: true,
    headPose: true,
    quality: true,
  },
  estimate_liveness: {
    estimate: false,
    liveness_threshold: 0.5,
    quality_threshold: 0.5,
  },
  estimate_deepfake: {
    estimate: false,
    real_threshold: 0.5,
    mode: DEEPFAKE_MODE_OPTIONS[2],
  },
  estimate_people_count: {
    estimate: false,
    people_coordinates: true,
  },
  faceQuality: {
    imageFormat: {
      isEnabled: true,
      threshold: [FACE_QUALITY_IMAGE_FORMAT.JPEG, FACE_QUALITY_IMAGE_FORMAT.JPEG2000, FACE_QUALITY_IMAGE_FORMAT.PNG],
    },
    illuminationQuality: {
      isEnabled: true,
      threshold: {
        min: 0.3,
        max: 1,
      },
    },
    specularityQuality: {
      isEnabled: true,
      threshold: {
        min: 0.3,
        max: 1,
      },
    },
    blurrinessQuality: {
      isEnabled: true,
      threshold: {
        min: 0.61,
        max: 1,
      },
    },
    darkQuality: {
      isEnabled: true,
      threshold: {
        min: 0.5,
        max: 1,
      },
    },
    lightQuality: {
      isEnabled: true,
      threshold: {
        min: 0.57,
        max: 1,
      },
    },
    headYaw: {
      isEnabled: true,
      threshold: {
        min: -5,
        max: 5,
      },
    },
    headPitch: {
      isEnabled: true,
      threshold: {
        min: -5,
        max: 5,
      },
    },
    headRoll: {
      isEnabled: true,
      threshold: {
        min: -8,
        max: 8,
      },
    },
    gazeYaw: {
      isEnabled: true,
      threshold: {
        min: -5,
        max: 5,
      },
    },
    gazePitch: {
      isEnabled: true,
      threshold: {
        min: -5,
        max: 5,
      },
    },
    mouthSmiling: {
      isEnabled: true,
      threshold: {
        min: 0,
        max: 0.5,
      },
    },
    mouthOccluded: {
      isEnabled: true,
      threshold: {
        min: 0,
        max: 0.5,
      },
    },
    mouthOpen: {
      isEnabled: true,
      threshold: {
        min: 0,
        max: 0.5,
      },
    },
    glasses: {
      isEnabled: true,
      threshold: [FACE_QUALITY_GLASSES.no_glasses, FACE_QUALITY_GLASSES.eyeglasses],
    },
    leftEye: {
      isEnabled: true,
      threshold: [FACE_QUALITY_EYE.open],
    },
    rightEye: {
      isEnabled: true,
      threshold: [FACE_QUALITY_EYE.open],
    },
    headHorizontalCenter: {
      isEnabled: true,
      threshold: {
        min: 0.45,
        max: 0.55,
      },
    },
    headVerticalCenter: {
      isEnabled: true,
      threshold: {
        min: 0.3,
        max: 0.5,
      },
    },
    headWidth: {
      isEnabled: true,
      threshold: {
        min: 0.5,
        max: 0.75,
      },
    },
    headHeight: {
      isEnabled: true,
      threshold: {
        min: 0.6,
        max: 0.9,
      },
    },
    eyeDistance: {
      isEnabled: true,
      threshold: {
        min: 90,
        max: 100,
      },
    },
    imageWidth: {
      isEnabled: true,
      threshold: {
        min: 180,
        max: 1920,
      },
    },
    imageHeight: {
      isEnabled: true,
      threshold: {
        min: 180,
        max: 1080,
      },
    },
    aspectRatio: {
      isEnabled: true,
      threshold: {
        min: 0.74,
        max: 0.8,
      },
    },
    faceWidth: {
      isEnabled: true,
      threshold: {
        min: 180,
        max: 1920,
      },
    },
    faceHeight: {
      isEnabled: true,
      threshold: {
        min: 180,
        max: 1080,
      },
    },
    indentLeft: {
      isEnabled: true,
      threshold: {
        min: 20,
        max: 50,
      },
    },
    indentRight: {
      isEnabled: true,
      threshold: {
        min: 20,
        max: 50,
      },
    },
    indentUpper: {
      isEnabled: true,
      threshold: {
        min: 20,
        max: 50,
      },
    },
    indentLower: {
      isEnabled: true,
      threshold: {
        min: 20,
        max: 50,
      },
    },
    imageSize: {
      isEnabled: true,
      threshold: {
        min: 5120,
        max: 2097152,
      },
    },
    eyebrowsState: {
      isEnabled: true,
      threshold: [FACE_QUALITY_EYEBROWS_STATE.neutral],
    },
    shoulders_position: {
      isEnabled: true,
      threshold: [FACE_QUALITY_SHOULDERS_POSITION.parallel],
    },
    headwearType: {
      isEnabled: true,
      threshold: [FACE_QUALITY_HEADWEAR_TYPE.none],
    },
    smileProperties: {
      isEnabled: true,
      threshold: [FACE_QUALITY_SMILE_PROPERTIES.none],
    },
    faceColorType: {
      isEnabled: true,
      threshold: [FACE_QUALITY_FACE_COLOR_TYPES.color],
    },
    naturalLight: {
      isEnabled: true,
      threshold: FACE_QUALITY_YES_NO[1],
    },
    redEyes: {
      isEnabled: true,
      threshold: FACE_QUALITY_YES_NO[0],
    },
    radialDistortion: {
      isEnabled: true,
      threshold: FACE_QUALITY_YES_NO[0],
    },
    illuminationUniformity: {
      isEnabled: true,
      threshold: {
        min: 0.5,
        max: 1,
      },
    },
    dynamicRange: {
      isEnabled: true,
      threshold: {
        min: 0.5,
        max: 1,
      },
    },
    backgroundUniformity: {
      isEnabled: true,
      threshold: {
        min: 0.5,
        max: 1,
      },
    },
    backgroundLightness: {
      isEnabled: true,
      threshold: {
        min: 0.5,
        max: 1,
      },
    },
  },
  filters: {
    multifacePolicy: MULTIFACE_POLICY_OPTIONS[1],
    faceDescriptorScoreThreshold: 0.5,
    yawThreshold: 30,
    rollThreshold: 40,
    pitchThreshold: 30,
  },
  attributesPolicy: {
    isEnabled: false,
  },
  facePolicy: {
    isEnabled: false,
    useSampleAsAvatar: true,
  },
  faceToListPolicy: {
    isEnabled: false,
    lists: [],
  },
  eventPolicy: {
    isEnabled: true,
  },
  notificationPolicy: {
    isEnabled: true,
  },
  faceSamplePolicy: {
    isEnabled: true,
  },
  bodySamplePolicy: {
    isEnabled: false,
  },
  imageOriginPolicy: {
    isEnabled: false,
  },
  matchPolicy: [],
  tagPolicy: [],
  callbacksPolicy: [],
};
