import React from 'react';

import st from './SelectOptionGroupLabel.module.sass';

const SelectOptionGroupLabel = (group) => {
  const { label, options } = group;

  return (
    <div className={st.Root}>
      <span className={st.GroupLabel}>{label}</span>
      <span className={st.GroupBadge}>{options.length}</span>
    </div>
  );
};

export default SelectOptionGroupLabel;
