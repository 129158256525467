import { DETECTION_TYPES } from './constants';
import { fromAPI } from './transformers';

export default async (imageFile, detectionTypes, estimateFace, estimateBody) => {
  let estimations = [];
  if ((detectionTypes || []).includes(DETECTION_TYPES.FACES)) {
    const { data } = await estimateFace(imageFile.raw);
    estimations = [...estimations, ...data.images_estimations[0].estimations];
  }
  if ((detectionTypes || []).includes(DETECTION_TYPES.BODIES)) {
    const { data } = await estimateBody(imageFile.raw);
    estimations = [...estimations, ...data.images_estimations[0].estimations];
  }

  if (estimations && estimations.length > 0) {
    return fromAPI(estimations);
  }

  return [];
};
