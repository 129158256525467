import React, { useState, useEffect, forwardRef, useRef } from 'react';

import { TriangleExclamationIcon, CrossIcon } from '@vlabs/icons';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { mergeRefs } from '../../utils/mergeRefs';
import { RoundButton } from '../button/RoundButton';
import {
  DEFAULT_MAX_ARCHIVE_SIZE,
  DEFAULT_MAX_IMAGE_SIZE,
  DEFAULT_SUPPORTED_ARCHIVE_TYPES,
  DEFAULT_SUPPORTED_IMAGE_TYPES,
} from '../utils';
import { TextFileRenderer } from './file-renderer/TextFileRenderer';
import {
  ArchiveSelectedCaption,
  ArchivesOnlyCaption,
  DefaultCaption,
  ImageSelectedCaption,
  ImagesOnlyCaption,
} from './fileDropzoneCaptions';
import './FileDropzone.sass';
import { addFileType } from './utils';

const readAsUrl = (image) => new Promise((resolve) => {
  const reader = new FileReader();
  reader.onloadend = () => resolve(reader.result);
  reader.readAsDataURL(image);
});

const megabyte = 1024 * 1024;
const gigabyte = megabyte * 1024;
const toGb = (bytes) => Math.round(bytes / gigabyte);
const toMb = (bytes) => Math.round(bytes / megabyte);

// TODO: при очистке формы через встроенный метод reset это нельзя отследить
//   внутри компонента, поэтому визуально он не меняется. Единственный вариант
//   это отслеживать событие reset на форме

/**
 * Обертка над нативным <input type="file"> с поддержкой drag'n'drop
 *
 * Не заточен для использования в формах, вместо него в них
 * следует использовать FileInput, который выглядит ближе к нативному
 */
const FileDropzone = forwardRef(({
  accept: acceptProp,
  onChange,
  onChangeValue,
  fileViewer,
  onReset,
  disabled,
  disabledWhenDetections,
  supportedImageTypes,
  supportedArchiveTypes,
  maxImageSize,
  maxArchiveSize,
  defaultFile,
  onlyImages: acceptOnlyImages,
  onlyArchives: acceptOnlyArchives,
  className,
  caption: captionProp,
  error: errorProp,
  localFile,
  ...restProps
}, externalRef) => {
  const [error, setError] = useState(errorProp);
  const [caption, setCaption] = useState(captionProp);
  const [file, setFile] = useState(undefined);
  const [FileViewerComponent, setFileViewerComponent] = useState(undefined);

  const [highlighted, setHighlighted] = useState(false);
  const internalRef = useRef(null);
  const { t } = useTranslation();

  let defaultCaption = <DefaultCaption />;
  if (acceptOnlyArchives) defaultCaption = <ArchivesOnlyCaption />;
  if (acceptOnlyImages) defaultCaption = <ImagesOnlyCaption />;

  if (acceptOnlyArchives && acceptOnlyImages) {
    // eslint-disable-next-line no-console
    console.debug('UIKIT: несовместимые параметры в FileDropzone — onlyImages и onlyArchives');
  }

  // Следим за изменением file извне через пропс localFile
  // Вызываем сброс, если извне вызван onReset
  useEffect(() => {
    if (onReset && !localFile && file) {
      internalRef.current.value = '';
      setFile(undefined);
      setCaption(undefined);
    }
  }, [onReset, localFile, file]);

  //
  // Data shortcuts
  //

  const $accept = [];
  if (!acceptOnlyImages) $accept.push(...supportedArchiveTypes);
  if (!acceptOnlyArchives) $accept.push(...supportedImageTypes);

  //
  // Listeners
  //

  useEffect(() => {
    if (defaultFile instanceof FileList) {
      internalRef.current.files = defaultFile;
    }
  }, []);

  useEffect(() => {
    setError(errorProp);
  }, [errorProp]);

  useEffect(() => {
    setCaption(captionProp);
  }, [captionProp]);

  useEffect(() => {
    if (disabled || !internalRef.current) return;
    if (!defaultFile) {
      internalRef.current.value = '';
    }
  }, [defaultFile, internalRef, disabled]);

  // Updating file viewer
  //
  useEffect(() => {
    if (file?.raw?.type) {
      const viewerComponent = fileViewer[file.raw.type];
      if (viewerComponent) {
        setFileViewerComponent(viewerComponent);
      }
    } else {
      setFileViewerComponent(<TextFileRenderer />);
    }
  }, [file, fileViewer]);

  // Updating caption
  //
  useEffect(() => {
    if (captionProp) {
      setCaption(captionProp);
      return;
    }

    if (!file?.raw) return;

    if (supportedImageTypes.includes(file.raw.type)) {
      setCaption(<ImageSelectedCaption name={file.raw.name} />);
      setError(undefined);
      return;
    }

    if (supportedArchiveTypes.includes(file.raw.type)) {
      setCaption(<ArchiveSelectedCaption name={file.raw.name} />);
      setError(undefined);
    }
  }, [file, captionProp]);

  //
  // Basic methods
  //

  const $onChange = (event, newFile) => {
    if (onReset) onReset();
    setFile(newFile);
    if (onChange) onChange(event);
    if (onChangeValue) onChangeValue(newFile);
  };

  const reset = (clickEvent) => {
    if (clickEvent) clickEvent.stopPropagation();
    if (!internalRef.current) return;
    if (onReset) onReset();

    internalRef.current.value = '';
    internalRef.current.dispatchEvent(new Event('change', { bubbles: true }));
  };

  const onEmptyInput = (event) => {
    $onChange(event, undefined);
    setError(undefined);
    setCaption(undefined);
    if (onReset) onReset();
  };

  const handleImage = async (event, newFile) => {
    if (newFile.size > maxImageSize) {
      reset();
      setError(t(
        'uikit:control.fileDropzone.ошибки.неподходящий размер изображения',
        { size: toMb(maxImageSize) },
      ));
      return;
    }

    const binary = await readAsUrl(newFile);
    $onChange(event, {
      type: 'image',
      raw: newFile,
      binary,
    });
  };

  const handleArchive = (event, newFile) => {
    if (newFile.size > maxArchiveSize) {
      reset();
      setError(t(
        'uikit:control.fileDropzone.ошибки.неподходящий размер архива',
        { size: toGb(maxArchiveSize) },
      ));
      return;
    }

    $onChange(event, {
      type: 'archive',
      raw: newFile,
    });
  };

  const onFileSelected = (event) => {
    event.persist();

    let newFile = event.target.files[0];
    if (!newFile) {
      onEmptyInput(event);
      return;
    }
    if (newFile.type === '') newFile = addFileType(newFile);
    const fileIsImage = newFile.type.startsWith('image/');
    const fileIsArchive = newFile.type.startsWith('application/');

    if ((fileIsImage || acceptOnlyImages) && !supportedImageTypes.includes(newFile.type)) {
      setError(t('uikit:control.fileDropzone.ошибки.неподходящий формат изображения'));
      return;
    }

    if ((fileIsArchive || acceptOnlyArchives) && !supportedArchiveTypes.includes(newFile.type)) {
      setError(t('uikit:control.fileDropzone.ошибки.неподходящий формат архива'));
      return;
    }

    if (fileIsImage) {
      handleImage(event, newFile);
    } else if (fileIsArchive) {
      handleArchive(event, newFile);
    } else {
      setError(t('uikit:control.fileDropzone.ошибки.неподходящий формат файла'));
    }
  };

  const openFileDialog = () => {
    internalRef.current.click();
  };

  //
  // Drag n Drop handlers
  //

  const onDragOver = (event) => {
    event.preventDefault();
    setHighlighted(true);
  };

  const onDragLeave = () => {
    setHighlighted(false);
  };

  const onDrop = (event) => {
    event.preventDefault();

    internalRef.current.files = event.dataTransfer.files;
    internalRef.current.dispatchEvent(new Event('change', { bubbles: true }));

    setHighlighted(false);
  };

  return (
    <div
      className={cn(
        className,
        'InteractionWrapper',
        {
          FileDropzone: true,
          FileDropzone_selected: file,
          FileDropzone_highlighted: highlighted,
          FileDropzone_error: errorProp || error,
        },
      )}
      disabled={disabled}
      onClick={!disabled ? openFileDialog : undefined}
      onDragLeave={!disabled ? onDragLeave : undefined}
      onDragOver={!disabled ? onDragOver : undefined}
      onDrop={!disabled ? onDrop : undefined}
      onKeyPress={!disabled ? openFileDialog : undefined}
      role="button"
      tabIndex={disabled ? -1 : 0}
    >
      <input
        accept={acceptProp || $accept}
        className="FileDropzone__Input"
        disabled={disabledWhenDetections && file && !error}
        onChange={onFileSelected}
        ref={mergeRefs(internalRef, externalRef)}
        type="file"
        {...restProps}
      />

      {file && (
        <RoundButton
          className="FileDropzone__ResetButton"
          icon={<CrossIcon />}
          kind="negative"
          onClick={reset}
          variant="flat"
        />
      )}

      {file ? (
        FileViewerComponent
      ) : (
        caption || defaultCaption
      )}

      {(errorProp || error) && (
        <div
          className="FileDropzone__ErrorMessage"
          data-testid="file-dropzone-error"
        >
          <TriangleExclamationIcon />
          {errorProp || error}
        </div>
      )}

      <div className="InteractionOverlay" />
    </div>
  );
});

FileDropzone.displayName = 'FileDropzone';

FileDropzone.propTypes = {
  accept: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  onChange: PropTypes.func,
  onChangeValue: PropTypes.func,
  onReset: PropTypes.func,
  caption: PropTypes.node,
  disabled: PropTypes.bool,
  disabledWhenDetections: PropTypes.bool,
  defaultFile: PropTypes.instanceOf(FileList),
  supportedImageTypes: PropTypes.arrayOf(PropTypes.string),
  supportedArchiveTypes: PropTypes.arrayOf(PropTypes.string),
  maxImageSize: PropTypes.number,
  maxArchiveSize: PropTypes.number,
  error: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  fileViewer: PropTypes.shape({}),
  localFile: PropTypes.shape({}),
  onlyImages: PropTypes.bool,
  onlyArchives: PropTypes.bool,
  className: PropTypes.string,
};

FileDropzone.defaultProps = {
  accept: undefined,
  onChange: undefined,
  onChangeValue: undefined,
  onReset: undefined,
  caption: undefined,
  disabled: false,
  disabledWhenDetections: false,
  defaultFile: undefined,
  supportedImageTypes: DEFAULT_SUPPORTED_IMAGE_TYPES,
  supportedArchiveTypes: DEFAULT_SUPPORTED_ARCHIVE_TYPES,
  maxImageSize: DEFAULT_MAX_IMAGE_SIZE,
  maxArchiveSize: DEFAULT_MAX_ARCHIVE_SIZE,
  error: undefined,
  fileViewer: undefined,
  localFile: undefined,
  onlyImages: false,
  onlyArchives: false,
  className: undefined,
};

export { FileDropzone };
