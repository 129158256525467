import React from 'react';

import { DownloadIcon } from '@vlabs/icons';
import i18n from 'i18next';
import PropTypes from 'prop-types';

import st from './DownloadCell.module.sass';

const DownloadCell = ({ value }) => {
  if (!value?.url) return null;

  return (
    <div
      id={value.url}
      title={i18n.t('reports:download')}
    >
      <a
        aria-label="DownloadIcon"
        className={st.Link}
        download={value?.description}
        href={value.url}
      >
        <DownloadIcon className={st.Icon} />
      </a>
    </div>
  );
};

DownloadCell.propTypes = {
  value: PropTypes.shape({
    url: PropTypes.string,
    description: PropTypes.string,
  }),
};

DownloadCell.defaultProps = {
  value: undefined,
};

export {
  DownloadCell,
};
