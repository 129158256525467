import React from 'react';

import { selectListOptions } from '@vlabs/shared/selectors/listSelectors';
import validate from '@vlabs/shared/validators';
import {
  Grid, GridRow, GridCol, Control, Divider,
} from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import st from './FaceEditForm.module.sass';

function FaceEditForm({
  listOptions,
  defaultValues,
  onSubmit,
}) {
  const { t } = useTranslation();
  const formMethods = useForm({ defaultValues: {
    ...defaultValues,
    lists: defaultValues.lists?.map((listId) => listOptions.find(({ value }) => listId === value)),
  } });

  const {
    control,
    register,
    handleSubmit,
    formState: {
      errors,
    } } = formMethods;

  const submitForm = ({ user_data, external_id, lists }) => {
    onSubmit({ user_data, external_id, lists: lists.map(({ value }) => value) });
  };

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <div className={st.Root}>
        <Grid>
          <GridRow>
            <GridCol>
              <Control.Textarea
                data-testid="faceEditFormTextarea"
                id="user_data"
                label={t('information')}
                rows={5}
                {...register('user_data', {
                  maxLength: validate.maxLength(128),
                })}
                errors={errors}
              />
            </GridCol>
          </GridRow>
          <GridRow>
            <GridCol>
              <Control.Input
                data-testid="faceEditFormInput"
                id="external_id"
                label={t('external id')}
                {...register('external_id', {
                  maxLength: validate.maxLength(36),
                })}
                errors={errors}
              />
            </GridCol>
          </GridRow>
          <GridRow>
            <GridCol>
              <Control.Select
                control={control}
                data-testid="faceEditFormDropdownList"
                id="lists"
                isClearable
                isMulti
                label={t('списки')}
                name="lists"
                options={listOptions}
              />
            </GridCol>
          </GridRow>
          <Divider small />

          <Control.Button
            data-testid="faceEditFormSaveButton"
            fullWidth
            type="submit"
          >
            {t('форма.submit')}
          </Control.Button>
        </Grid>
      </div>
    </form>
  );
}

FaceEditForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  listOptions: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })),
  defaultValues: PropTypes.objectOf(PropTypes.any),
};

FaceEditForm.defaultProps = {
  listOptions: [],
  defaultValues: {},
};

const $FaceEditForm = connect(
  (state) => ({
    listOptions: selectListOptions(state),
  }),
)(FaceEditForm);

export { $FaceEditForm as FaceEditForm };
