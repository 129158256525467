import { createSelector } from '@reduxjs/toolkit';
import { get } from 'lodash';

import { initialState } from './initialState';

export const selectAppSlice = (state) => state.app;

export const selectAppState = createSelector(
  selectAppSlice,
  ({ state }) => state,
);
export const selectAppServices = createSelector(
  selectAppSlice,
  ({ services }) => services,
);
export const selectAppFeatures = createSelector(
  selectAppSlice,
  ({ features }) => features || initialState.features,
);
export const selectAppPlugins = createSelector(
  selectAppSlice,
  ({ plugins }) => plugins || initialState.plugins,
);
export const selectIsAppFeatureAvailable = createSelector(
  selectAppFeatures,
  (store, featureName) => featureName,
  (features, featureName) => !!get(features, featureName, false),
);
export const selectAppService = createSelector(
  selectAppServices,
  (store, serviceName) => serviceName,
  (services, serviceName) => services?.[serviceName],
);
export const selectAppServiceState = createSelector(
  selectAppServices,
  (store, serviceName) => serviceName,
  (services, serviceName) => services?.[serviceName]?.state,
);

// config selectors
export const selectAppConfig = createSelector(
  selectAppSlice,
  ({ config }) => config,
);
export const selectSimilarityThresholds = createSelector(
  selectAppConfig,
  ({ similarityThresholds }) => similarityThresholds,
);
export const selectAuthConfig = createSelector(
  selectAppConfig,
  ({ auth }) => auth,
);

export const selectMaxImageSize = createSelector(
  selectAppConfig,
  ({ maxImageSize }) => maxImageSize,
);
