import React, { useEffect } from 'react';

import { POLICIES } from '@vlabs/api-bindings/src/luna-client/handlers/constants';
import validate from '@vlabs/shared/validators';
import { Control, GridRow, GridCol } from '@vlabs/uikit';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const headerLineRegex = /^[\w\-_]*: .*$/i;

export const HttpCallbackPolicyForm = ({ fieldName }) => {
  const { t } = useTranslation();
  const prefix = fieldName ? `${fieldName}.` : '';
  const form = useFormContext();
  const type = useWatch({ name: `${prefix}authorization.type` });

  useEffect(() => {
    if (!type) {
      form.unregister(`${prefix}authorization`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.unregister, type, prefix]);

  const makeAuthorizationFields = (authorizationType) => {
    switch (authorizationType?.value) {
      case POLICIES.CALLBACK.authorization.type.basic.value: {
        return (
          <>
            <GridCol>
              <Control.Input
                id="authorization.login"
                label={t('handlers:policies.storage_policy.callbacks.callback.authorization.login.label')}
                placeholder={t('handlers:policies.storage_policy.callbacks.callback.authorization.login.placeholder')}
                {...form.register(`${prefix}authorization.login`, {
                  required: type && validate.required(),
                })}
                data-testid="callback.authorization.login"
                errors={form.formState.errors}
              />
            </GridCol>

            <GridCol>
              <Control.Input
                id="authorization.password"
                label={t('handlers:policies.storage_policy.callbacks.callback.authorization.password.label')}
                placeholder={t('handlers:policies.storage_policy.callbacks.callback.authorization.password.placeholder')}
                {...form.register(`${prefix}authorization.password`, {
                  required: type && validate.required(),
                })}
                data-testid="callback.authorization.password"
                errors={form.formState.errors}
              />
            </GridCol>
          </>
        );
      }
      default: {
        return null;
      }
    }
  };

  return (
    <>
      <GridRow>
        <GridCol>
          <Control.Input
            id="url"
            label={t('handlers:policies.storage_policy.callbacks.callback.url.label')}
            placeholder={t('handlers:policies.storage_policy.callbacks.callback.url.placeholder')}
            {...form.register(`${prefix}url`, {
              required: validate.required(),
            })}
            autoFocus
            data-testid="callback.url"
            errors={form.formState.errors}
          />
        </GridCol>
      </GridRow>
      <GridRow>
        <GridCol>
          <Control.Select
            control={form.control}
            data-testid="callback.authorization.type"
            id="authorization.type"
            isClearable
            label={t('handlers:policies.storage_policy.callbacks.callback.authorization.type')}
            name={`${prefix}authorization.type`}
            options={POLICIES.CALLBACK.authorization.type.raw}
          />
        </GridCol>

        {makeAuthorizationFields(form.watch(`${prefix}authorization.type`))}

      </GridRow>
      <GridRow>
        <GridCol>
          <Control.Input
            id="params.timeout"
            label={t('handlers:policies.storage_policy.callbacks.callback.params.timeout.label')}
            placeholder={t('handlers:policies.storage_policy.callbacks.callback.params.timeout.placeholder')}
            {...form.register(`${prefix}params.timeout`, {
              validate: validate.integer(),
            })}
            data-testid="callback.params.timeout"
            errors={form.formState.errors}
          />
        </GridCol>
        <GridCol>
          <Control.Select
            control={form.control}
            data-testid="callback.params.content_type"
            errors={form.formState.errors}
            id="params.content_type"
            label={t('handlers:policies.storage_policy.callbacks.callback.params.content_type')}
            name={`${prefix}params.content_type`}
            options={POLICIES.CALLBACK.params.content_type.raw}
            value={POLICIES.CALLBACK.params.content_type['application/json']}
          />
        </GridCol>
      </GridRow>
      <GridRow>
        <GridCol>
          <Control.Textarea
            id="params.headers"
            label={t('handlers:policies.storage_policy.callbacks.callback.params.headers.label')}
            placeholder={t('handlers:policies.storage_policy.callbacks.callback.params.headers.placeholder')}
            {...form.register(`${prefix}params.headers`, {
              validate: (value) => {
                if (value.length === 0) return true;
                const isNonValidIndex = value
                  .split('\n')
                  .map((v) => v.match(headerLineRegex))
                  .findIndex((v) => v === null);
                if (isNonValidIndex !== -1) return t('валидация.regex', { regex: headerLineRegex });
                return true;
              },
            })}
            data-testid="callback.params.headers"
            errors={form.formState.errors}
          />
        </GridCol>
      </GridRow>
    </>
  );
};
