import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import './Margin.sass';

const Margin = ({
  left,
  right,
  top,
  bottom,
  display,
  children,
  className,
}) => {
  const full = !left && !right && !top && !bottom;

  return (
    <div
      className={cn(
        className,
        {
          Margin: true,
          'Margin--full': full,
          'Margin--left': left,
          'Margin--right': right,
          'Margin--top': top,
          'Margin--bottom': bottom,
          'Margin--block': display === 'block',
        },
      )}
    >
      {children}
    </div>
  );
};

Margin.propTypes = {
  left: PropTypes.bool,
  right: PropTypes.bool,
  top: PropTypes.bool,
  bottom: PropTypes.bool,
  display: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
};

Margin.defaultProps = {
  left: false,
  right: false,
  top: false,
  bottom: false,
  display: undefined,
  children: undefined,
  className: undefined,
};

export { Margin };
