import React from 'react';

import PropTypes from 'prop-types';

import CheckRowsFaceQuality from '../components/CheckRowsFaceQuality';

export default function IsoCheckResults({ results }) {
  const iso = results?.estimations?.[0]?.face?.detection?.iso;
  if (!iso) return null;

  return (
    <CheckRowsFaceQuality
      estimationName="iso"
      estimations={iso.checks}
      filename={results.filename}
      status={iso.status}
    />
  );
}

IsoCheckResults.propTypes = {
  results: PropTypes.objectOf(PropTypes.any).isRequired,
};
