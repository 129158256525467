import React, { useState, useEffect, useCallback } from 'react';

import { apiContainer } from '@vlabs/api-bindings';
import { AgeCard } from '@vlabs/shared/components/attribute-card/AgeCard';
import { DeepfakeCard } from '@vlabs/shared/components/attribute-card/DeepfakeCard';
import { EmotionCard } from '@vlabs/shared/components/attribute-card/EmotionCard';
import { GenderCard } from '@vlabs/shared/components/attribute-card/GenderCard';
import { LivenessCard } from '@vlabs/shared/components/attribute-card/LivenessCard';
import { MaskCard } from '@vlabs/shared/components/attribute-card/MaskCard';
import PropTypes from 'prop-types';

import st from './tabs.module.sass';

export function AttributesTab({
  faceId, eventId,
}) {
  const [attributes, setAttributes] = useState();

  const getAttributes = useCallback(async () => {
    if (faceId) {
      const response = await apiContainer.lunaClient.faces.attributes(faceId).get();
      setAttributes(response?.data?.attributes?.basic_attributes);
    } else if (eventId) {
      const response = await apiContainer.lunaClient.events.get(eventId);
      setAttributes(response?.data);
    }
  }, [eventId, faceId]);

  useEffect(() => {
    getAttributes();
  }, [faceId, eventId, getAttributes]);

  const { gender, age, mask, emotion, liveness, deepfake } = attributes || {};

  return (
    <div className={st.TabPanel}>
      {gender !== undefined && <GenderCard gender={gender} />}
      {age !== null && age !== undefined && <AgeCard age={age} />}
      {typeof mask === 'number' && <MaskCard mask={mask} />}
      {typeof emotion === 'number' && <EmotionCard emotion={emotion} />}
      <LivenessCard liveness={liveness} />
      <DeepfakeCard deepfake={deepfake} />
    </div>
  );
}

AttributesTab.propTypes = {
  faceId: PropTypes.string,
  eventId: PropTypes.string,
};

AttributesTab.defaultProps = {
  faceId: null,
  eventId: null,
};
