const transformEstimation = (estimations) => {
  return {
    faces: estimations
      .filter(({ face }) => face)
      .map(({ face }, i) => ({
        type: 'face',
        id: `face${i}`,
        ...face.detection,
      })),
    bodies: estimations
      .filter(({ body }) => body)
      .map(({ body }, i) => ({
        type: 'body',
        id: `body${i}`,
        ...body.detection,
      })),
  };
};

const formatImageToBase64 = (detections) => detections.all.map((detection) => ({
  ...detection,
  image: `data:image/jpeg;base64,${detection.warp}`,
}));

export const fromAPI = (rawEstimations) => {
  const estimations = transformEstimation(rawEstimations);

  const detections = {
    all: [
      ...estimations.faces,
      ...estimations.bodies,
    ],
    byId: {},
    faces: [],
    bodies: [],
  };

  if (detections.all.length > 0) {
    detections.all = formatImageToBase64(detections);
    detections.all.forEach((detection) => { detections.byId[detection.id] = detection; });
    detections.bodies = detections.all.filter(({ type }) => type === 'body');
    detections.faces = detections.all.filter(({ type }) => type === 'face');
  }

  return detections;
};

export const toAPI = () => {};
