import i18next from 'i18next';

export const DETECTION_TYPES = {
  FACES: 'FACES',
  BODIES: 'BODIES',
};

const optionGetter = {
  get(obj, prop) {
    // The default behavior to return the value; prop is usually an integer
    if (prop in obj) return obj[prop];
    return obj.find(({ value }) => value === prop);
  },
};
export const DETECTION_OPTIONS = new Proxy([
  { value: DETECTION_TYPES.FACES, label: i18next.t('Поиск по лицам') },
], optionGetter);

export const REFERENCES_OPTIONS = new Proxy([
  { value: 'face_external_id', label: i18next.t('Поиск по внешнему ID лица') },
  { value: 'sdk_descriptor', label: i18next.t('Поиск по изображению лица') },
  { value: 'face', label: i18next.t('Поиск по ID лица') },
  { value: 'event', label: i18next.t('Поиск по ID события лица') },
], optionGetter);
