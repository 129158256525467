import { createSlice } from '@reduxjs/toolkit';
import { apiContainer } from '@vlabs/api-bindings';
import i18n from 'i18next';
import { toast } from 'react-toastify';

import { selectAccountById } from './selectors';

const initialState = {
  state: undefined,
  data: [],
  meta: {},
  byId: {},
  filters: [],
  pageIndex: 0,
  pageSize: 25,
  pageSizeOptions: [10, 25, 50, 100],
};

const store = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    setAccounts(state, { payload: { data, meta } }) {
      state.data = data;
      state.meta = meta;
      state.byId = {};
      data.forEach((account) => {
        state.byId[account.id] = account;
      });
      state.state = 'loaded';
    },
    setFilters(state, { payload: { filters } = {} }) {
      if (filters !== undefined) state.filters = filters;
    },
    setPage(state, { payload: { pageIndex } = {} }) {
      if (pageIndex !== undefined) state.pageIndex = pageIndex;
    },
    setPageSize(state, { payload: { pageSize } = {} }) {
      if (pageSize !== undefined) state.pageSize = pageSize;
    },
    resetStore() {
      return initialState;
    },
  },
});

export default store.reducer;

export const { resetStore } = store.actions;

export const fetchAccounts = async (dispatch, getState) => {
  const {
    accounts: { pageIndex, pageSize, filters },
  } = getState();

  const localFilters = [];
  filters.forEach(({ id, value }) => {
    if (typeof value === 'object') {
      localFilters[id] = value.value;
    } else {
      localFilters[id] = value;
    }
  });
  try {
    const { data: accounts } = await apiContainer.clementineAuthClient.accounts
      .getAll({ page: pageIndex + 1, pageSize, ...localFilters });
    dispatch(store.actions.setAccounts(accounts));
  } catch (error) {
    dispatch(store.actions.setAccounts({ data: [] }));
    throw error;
  }
};

export const getAccounts = async (params) => apiContainer.clementineAuthClient.accounts.getAll({ ...params });

export const getAccount = async (id) => apiContainer.lementineAuthClient.accounts.get(id);

export const createAccount = (data) => async (dispatch) => {
  try {
    await apiContainer.clementineAuthClient.accounts.create(data);
    dispatch(fetchAccounts);
    toast.success(i18n.t('accounts:create user.success', { account: data }));
  } catch (error) {
    if (error.response.status === 409) {
      const errorMessage = i18n.t('accounts:update user data.non-unique email', { account: data });
      toast.warning(errorMessage);
      return { email: error };
    }
    throw error;
  }
  return undefined;
};

export const resetPasswordByEmail = (id) => async (dispatch, getState) => {
  const account = selectAccountById(getState(), id);
  await apiContainer.clementineAuthClient.auth.reset(id);
  toast.success(i18n.t('accounts:password recovery.success', { account }));
};

export const updateAccount = (id, data) => async (dispatch, getState) => {
  try {
    await apiContainer.clementineAuthClient.accounts.update(id, data);
    dispatch(fetchAccounts);
    if (data?.password?.length > 0) {
      const account = selectAccountById(getState(), id);
      toast.success(i18n.t('accounts:password update.success', { account }));
    } else {
      const account = { ...data, id };
      toast.success(i18n.t('accounts:update user data.success', { account }));
    }
  } catch (error) {
    if (error.response.status === 409) {
      const message = i18n.t('accounts:update user data.non-unique email', { email: data.email });
      toast.warning(message);
      return { email: message };
    }
    throw error;
  }
  return undefined;
};

export const deleteAccount = (id) => async (dispatch, getState) => {
  const account = selectAccountById(getState(), id);
  await apiContainer.clementineAuthClient.accounts.delete(id);
  toast.success(i18n.t('accounts:delete user.success', { account }));
  dispatch(fetchAccounts);
};

export const setFilters = (params) => async (dispatch) => {
  dispatch(store.actions.setFilters(params));
  dispatch(fetchAccounts);
};

export const setPageSize = (params) => async (dispatch) => {
  dispatch(store.actions.setPageSize(params));
  dispatch(fetchAccounts);
};

export const setPage = (params) => async (dispatch) => {
  dispatch(store.actions.setPage(params));
  dispatch(fetchAccounts);
};
