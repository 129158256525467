import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import './SampleWithoutPermission.sass';

export const SampleWithoutPermission = ({ type = 'face', className }) => {
  const { t } = useTranslation();

  const messageTextByType = {
    face: t('shared:недостаточно прав для просмотра образца лица'),
    body: t('shared:недостаточно прав для просмотра образца тела'),
  };

  return (
    <div className={cn('SampleWithoutPermission', [`SampleWithoutPermission_${type}`], className)}>
      {messageTextByType[type]}
    </div>
  );
};

SampleWithoutPermission.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(['face', 'body']).isRequired,
};

SampleWithoutPermission.defaultProps = {
  className: undefined,
};
