import React from 'react';

import { ageImages } from '@vlabs/shared/assets/images';
import { useTranslation, Trans } from 'react-i18next';

import { Card } from './Card';
import st from './Card.module.sass';

export const AgeCard = ({ age }) => {
  const { t } = useTranslation();
  const ageGroups = [
    { from: 0, to: 17, label: t('constants:младше 18'), image: ageImages.AgeLess18 },
    { from: 18, to: 44, label: t('constants:от 18 до 44'), image: ageImages.Age18_44 },
    { from: 45, to: 60, label: t('constants:от 45 до 60'), image: ageImages.Age45_60 },
    { from: 61, to: 100, label: t('constants:старше 60'), image: ageImages.Age60 },
  ];

  const determinerAgeGroup = (value) => ageGroups.find(({ from, to }) => (from <= value && value <= to));

  const ageTitle = determinerAgeGroup(age).label;
  const ageImg = determinerAgeGroup(age).image;

  return (
    <Card
      back={(
        <div className={st.Tooltip}>
          <Trans i18nKey="events:карточка.возраст" values={{ age }} />
        </div>
      )}
      front={(
        <div className={st.Root}>
          <div
            className={st.ImageBlock}
            data-testid="faceAttributesCell.age"
          >
            {t('age')}
            <img alt={`${age} age category`} className={st.Image} src={ageImg} />
            {ageTitle}
          </div>
        </div>
      )}
    />
  );
};
