import React from 'react';

import validate from '@vlabs/shared/validators';
import { Control, Fold, SettingsItemWrapper } from '@vlabs/uikit';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

function HandlerSubForm() {
  const { t } = useTranslation();
  const { register, formState: { errors } } = useFormContext();
  return (
    <Fold isOpen title={t('lunaStreamsStreams:форма.event_handler.title')}>
      <SettingsItemWrapper title={t('lunaStreamsStreams:форма.event_handler.origin.label')}>
        <Control.Input
          errors={errors}
          {...register('event_handler.origin', {
            required: validate.required(),
          })}
        />
      </SettingsItemWrapper>
      <SettingsItemWrapper title={t('lunaStreamsStreams:форма.event_handler.api_version.label')}>
        <Control.Input
          errors={errors}
          {...register('event_handler.api_version', {
            required: validate.required(),
            min: validate.gte(0),
            validate: validate.integer(),
          })}
        />
      </SettingsItemWrapper>
      <SettingsItemWrapper
        data-tooltip-content={t('lunaStreamsStreams:форма.event_handler.bestshot_handler.handler_id.tooltip')}
        title={t('lunaStreamsStreams:форма.event_handler.bestshot_handler.handler_id.label')}
      >
        <Control.Input
          errors={errors}
          {...register('event_handler.bestshot_handler.handler_id', {
            validate: validate.uuid(),
            required: validate.required(),
          })}
        />
      </SettingsItemWrapper>
      <SettingsItemWrapper title={t('lunaStreamsStreams:форма.event_handler.frame_store.label')}>
        <Control.Input {...register('event_handler.frame_store')} />
      </SettingsItemWrapper>
      <SettingsItemWrapper
        data-tooltip-content={t('lunaStreamsStreams:форма.event_handler.authorization.tooltip')}
        title={t('lunaStreamsStreams:форма.event_handler.authorization.label')}
      >
        <Control.Input {...register('event_handler.authorization.token')} />
      </SettingsItemWrapper>
    </Fold>
  );
}

HandlerSubForm.propTypes = {

};

export default HandlerSubForm;
