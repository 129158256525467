import React from 'react';

import {
  GENDER_OPTIONS,
  EMOTION_OPTIONS,
  MASK_OPTIONS,
  AGE_OPTIONS,
} from '@vlabs/api-bindings/src/constants';
import { EVENT_ENUMS, LIVENESS_OPTIONS } from '@vlabs/api-bindings/src/luna-client/constants';
import { optionPropType } from '@vlabs/shared';
import { START_OF_DAY, END_OF_DAY } from '@vlabs/shared/config';
import { MetaFilterFields } from '@vlabs/shared/legacy-components/filter-forms/meta-filter/MetaFilterFields';
import SelectOptionGroupLabel from '@vlabs/shared/legacy-components/select-option-group-label/SelectOptionGroupLabel';
import { selectIsAppFeatureAvailable } from '@vlabs/shared/selectors/appSelectors';
import validate from '@vlabs/shared/validators';
import { Grid, GridCol, GridRow, Divider, Control, Fold, Group, Step } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { selectHandlerOptions } from '@vlabs/pages/handlers/selectors';
import { selectSourceOptionsByAccount } from '@vlabs/pages/sources/selectors';

function StepEventsFilters({
  camOptions,
  handlerOptions,
  isBodyLicenseAvailable,
}) {
  const { t } = useTranslation();
  const formMethods = useFormContext();
  const { watch, register, control, formState: { errors } } = formMethods;

  const similarityGte = Number(watch('top_similar_object_similarity__gte')) || 0;
  const longitude = watch('origin_longitude');
  const longitudelDelta = watch('longitude_delta');
  const latitude = watch('origin_latitude');
  const latitude_delta = watch('latitude_delta');

  return (
    <Step noBorder>
      <h6>{t('tasks:exportForm.заголовки.фильтры')}</h6>
      <Divider small />

      <Group vertical>
        <Fold
          compact
          data-testid="CommonEventFilters"
          title={t('tasks:exportForm.заголовки.oбщие по событиям')}
          withPadding
        >
          <Grid>
            <GridRow>
              <GridCol cols={6}>
                <Control.Select
                  control={control}
                  formatGroupLabel={SelectOptionGroupLabel}
                  id="content.filters.sources"
                  isMulti
                  label={t('source')}
                  name="content.filters.sources"
                  options={camOptions}
                />
                <Divider small />

                <Control.Input
                  id="content.filters.user_data"
                  label={t('пользовательские данные')}
                  {...register('content.filters.user_data', { maxLength: validate.maxLength(128) })}
                  errors={errors}
                  placeholder={t('введите')}
                />
                <Divider small />

                <Control.Input
                  errors={errors}
                  id="content.filters.face_ids"
                  label={t('идентификаторы лиц')}
                  placeholder={t('введите через запятую')}
                  {...register('content.filters.face_ids', { validate: validate.multipleUuid() })}
                />
                <Divider small />

                <Control.Input
                  id="content.filters.tags"
                  label={t('tags')}
                  placeholder={t('введите через запятую')}
                  {...register('content.filters.tags')}
                />
                <Divider small />

                <Control.DateInput
                  control={control}
                  enableTime
                  id="content.filters.create_time__gte"
                  label={t('дата создания от')}
                  name="content.filters.create_time__gte"
                  options={START_OF_DAY}
                  placeholder={t('выберите даты')}
                />
                <Divider small />
              </GridCol>

              <GridCol cols={6}>
                <Control.Input
                  errors={errors}
                  id="content.filters.event_ids"
                  label={t('идентификаторы событий')}
                  placeholder={t('введите через запятую')}
                  {...register('content.filters.event_ids', { validate: validate.multipleUuid() })}
                />
                <Divider small />

                <Control.Input
                  id="content.filters.external_ids"
                  label={t('внешние идентификаторы событий')}
                  placeholder={t('введите через запятую')}
                  {...register('content.filters.external_ids', {
                    validate: validate.multipleExternalId() })}
                  errors={errors}
                />
                <Divider small />

                <div className="SearchByEvents__AgeFilter">
                  <div>
                    <Control.Input
                      id="content.filters.top_similar_object_similarity__gte"
                      label={t('similarity')}
                      placeholder={t('from')}
                      {...register('content.filters.top_similar_object_similarity__gte', {
                        min: validate.gte(0),
                        max: validate.lte(1),
                        validate: validate.number(),
                      })}
                      errors={errors}
                    />
                  </div>
                  <div className="AgeFilterDivider">:</div>
                  <div>
                    <Control.Input
                      placeholder={t('to')}
                      {...register('content.filters.top_similar_object_similarity__lt', {
                        min: validate.gte(Math.max(similarityGte, 0)),
                        max: validate.lte(1),
                        validate: validate.number(),
                      })}
                      errors={errors}
                    />
                  </div>
                </div>
                <Divider small />

                <Control.Select
                  control={control}
                  errors={errors}
                  id="content.filters.handler_ids"
                  isClearable
                  isCreatable
                  isMulti
                  label={t('сценарии')}
                  name="content.filters.handler_ids"
                  options={handlerOptions}
                  rules={{ validate: validate.uuidOptions }}
                />
                <Divider small />

                <Control.DateInput
                  control={control}
                  enableTime
                  id="content.filters.create_time__lt"
                  label={t('дата создания до')}
                  name="content.filters.create_time__lt"
                  options={END_OF_DAY}
                  placeholder={t('выберите даты')}
                />
                <Divider small />

              </GridCol>
            </GridRow>
          </Grid>
        </Fold>

        <Fold
          compact
          data-testid="AdditionalEventFilters"
          title={t('tasks:exportForm.заголовки.расширенные по событиям')}
          withPadding
        >
          <Grid>
            <GridRow>
              <GridCol cols={6}>
                <Control.DateInput
                  control={control}
                  enableTime
                  id="content.filters.end_time__gte"
                  label={t('дата окончания события от')}
                  name="content.filters.end_time__gte"
                  options={END_OF_DAY}
                  placeholder={t('выберите даты')}
                />
                <Divider small />

                <Control.Input
                  errors={errors}
                  id="content.filters.event_id__gte"
                  label={t('идентификатор первого события')}
                  placeholder={t('введите id')}
                  {...register('content.filters.event_id__gte', { validate: validate.uuid() })}
                />
                <Divider small />

                <Control.Input
                  id="content.filters.track_ids"
                  label={t('идентификаторы треков')}
                  placeholder={t('введите через запятую')}
                  {...register('content.filters.track_ids', {
                    validate: validate.multipleTrackIds() })}
                  errors={errors}
                />
                <Divider small />
              </GridCol>

              <GridCol cols={6}>
                <Control.DateInput
                  control={control}
                  enableTime
                  id="content.filters.end_time__lt"
                  label={t('дата окончания события до')}
                  name="content.filters.end_time__lt"
                  options={END_OF_DAY}
                  placeholder={t('выберите даты')}
                />
                <Divider small />

                <Control.Input
                  errors={errors}
                  id="content.filters.event_id__lt"
                  label={t('идентификатор последнего события')}
                  placeholder={t('введите id')}
                  {...register('content.filters.event_id__lt', { validate: validate.uuid() })}
                />
                <Divider small />
              </GridCol>
            </GridRow>
          </Grid>
        </Fold>

        <Fold
          compact
          data-testid="FaceAttributesFilters"
          title={t('tasks:exportForm.заголовки.атрибуты лица')}
          withPadding
        >
          <Grid>
            <GridRow>
              <GridCol cols={6}>
                <Control.Select
                  control={control}
                  id="content.filters.gender"
                  isClearable
                  label={t('gender')}
                  name="content.filters.gender"
                  options={GENDER_OPTIONS.raw}
                />
                <Divider small />

                <Control.Select
                  control={control}
                  id="content.filters.emotions"
                  isMulti
                  label={t('эмоции')}
                  name="content.filters.emotions"
                  options={EMOTION_OPTIONS.raw}
                />
                <Divider small />

                <Control.Select
                  control={control}
                  id="content.filters.liveness"
                  isMulti
                  label={t('liveness')}
                  name="content.filters.liveness"
                  options={LIVENESS_OPTIONS.raw}
                />
                <Divider small />
              </GridCol>
              <GridCol cols={6}>
                <Control.Select
                  control={control}
                  id="content.filters.age"
                  isClearable
                  label={t('age')}
                  name="content.filters.age"
                  options={AGE_OPTIONS.raw}
                />
                <Divider small />

                <Control.Select
                  control={control}
                  id="content.filters.masks"
                  isClearable
                  isMulti
                  label={t('маска')}
                  name="content.filters.masks"
                  options={MASK_OPTIONS.raw}
                />
                <Divider small />
              </GridCol>
            </GridRow>
          </Grid>
        </Fold>

        {isBodyLicenseAvailable && (
        <Fold
          compact
          data-testid="BodyAttributesFilters"
          title={t('tasks:exportForm.заголовки.атрибуты тела')}
          withPadding
        >
          <Grid>
            <GridRow>
              <GridCol cols={6}>
                <Control.Select
                  control={control}
                  id="content.filters.apparent_gender"
                  isClearable
                  isMulti
                  label={t('events:body basic attributes.apparent gender')}
                  name="content.filters.apparent_gender"
                  options={EVENT_ENUMS.BODY_BASIC_ATTRIBUTES.APPARENT_GENDER.raw}
                />
                <Divider small />

                <Control.Select
                  control={control}
                  id="content.filters.headwear_states"
                  isClearable
                  isMulti
                  label={t('events:headwear')}
                  name="content.filters.headwear_states"
                  options={EVENT_ENUMS.UPPER_BODY.HEADWEAR.raw}
                />
                <Divider small />

                <Control.Select
                  control={control}
                  id="content.filters.sleeve_lengths"
                  isClearable
                  isMulti
                  label={t('events:sleeve')}
                  name="content.filters.sleeve_lengths"
                  options={EVENT_ENUMS.UPPER_BODY.SLEEVE.raw}
                />
                <Divider small />

                <Control.Select
                  control={control}
                  id="content.filters.lower_garment_colors"
                  isMulti
                  label={t('events:bottom_colors')}
                  name="content.filters.lower_garment_colors"
                  options={EVENT_ENUMS.LOWER_BODY.LOWER_GARMENT.COLORS.raw}
                />
                <Divider small />

                <Control.Select
                  control={control}
                  id="content.filters.shoes_apparent_colors"
                  isMulti
                  label={t('events:shoes_colors')}
                  name="content.filters.shoes_apparent_colors"
                  options={EVENT_ENUMS.LOWER_BODY.SHOES.APPARENT_COLOR.raw}
                />
                <Divider small />

              </GridCol>

              <GridCol cols={6}>
                <Control.Select
                  control={control}
                  id="content.filters.apparent_age"
                  isClearable
                  label={t('events:body basic attributes.apparent age')}
                  name="content.filters.apparent_age"
                  options={AGE_OPTIONS.raw}
                />
                <Divider small />

                <Control.Select
                  control={control}
                  id="content.filters.upper_clothing_colors"
                  isClearable
                  isMulti
                  label={t('events:upper_clothing_colors')}
                  name="content.filters.upper_clothing_colors"
                  options={EVENT_ENUMS.UPPER_BODY.UPPER_CLOSING_COLOR.raw}
                />
                <Divider small />

                <Control.Select
                  control={control}
                  id="content.filters.headwear_apparent_colors"
                  isMulti
                  label={t('events:headwear_colors')}
                  name="content.filters.headwear_apparent_colors"
                  options={EVENT_ENUMS.UPPER_BODY.HEADWEAR_COLORS.raw}
                />
                <Divider small />

                <Control.Select
                  control={control}
                  id="content.filters.lower_garment_types"
                  isMulti
                  label={t('events:bottom_type')}
                  name="content.filters.lower_garment_types"
                  options={EVENT_ENUMS.LOWER_BODY.LOWER_GARMENT.TYPE.raw}
                />
                <Divider small />

                <Control.Select
                  control={control}
                  id="content.filters.backpack_states"
                  isClearable
                  isMulti
                  label={t('events:backpack')}
                  name="content.filters.backpack_states"
                  options={EVENT_ENUMS.ACCESSORIES.BACKPACK.raw}
                />
                <Divider small />

              </GridCol>
            </GridRow>
          </Grid>
        </Fold>
        )}

        <Fold
          compact
          data-testid="TopMatchingFilters"
          title={t('tasks:exportForm.заголовки.наилучшее совпадение')}
          withPadding
        >
          <Grid>
            <GridRow>
              <GridCol cols={6}>
                <Control.Input
                  id="content.filters.top_matching_candidates_label"
                  label={t('label')}
                  {...register('content.filters.top_matching_candidates_label', { maxLength: validate.maxLength(36) })}
                  errors={errors}
                  placeholder={t('введите')}
                />
                <Divider small />

                <Control.Input
                  id="content.filters.top_similar_external_ids"
                  label={t('внешние идентификаторы лиц')}
                  placeholder={t('введите через запятую')}
                  {...register('content.filters.top_similar_external_ids', {
                    validate: validate.multipleExternalId() })}
                  errors={errors}
                />
                <Divider small />
              </GridCol>

              <GridCol cols={6}>
                <Control.Input
                  errors={errors}
                  id="content.filters.top_similar_object_ids"
                  label={t('идентификаторы лиц')}
                  placeholder={t('введите через запятую')}
                  {...register('content.filters.top_similar_object_ids', { validate: validate.multipleUuid() })}
                />
                <Divider small />

              </GridCol>
            </GridRow>
          </Grid>
        </Fold>

        <Fold
          compact
          data-testid="GeolocationFilters"
          title={t('tasks:exportForm.заголовки.геолокация')}
          withPadding
        >
          <Grid>
            <GridRow>
              <GridCol cols={6}>
                <Control.Input
                  id="content.filters.districts"
                  label={t('геопозиция.район')}
                  {...register('content.filters.districts', {
                    maxLength: validate.maxLength(36),
                  })}
                  errors={errors}
                  placeholder={t('введите')}
                />
                <Divider small />

                <Control.Input
                  id="content.filters.cities"
                  label={t('геопозиция.город')}
                  {...register('content.filters.cities', {
                    maxLength: validate.maxLength(36),
                  })}
                  errors={errors}
                  placeholder={t('введите')}
                />
                <Divider small />

                <Control.Input
                  id="content.filters.house_numbers"
                  label={t('геопозиция.номер дома')}
                  {...register('content.filters.house_numbers', {
                    maxLength: validate.maxLength(36),
                  })}
                  errors={errors}
                  placeholder={t('введите')}
                />
                <Divider small />

              </GridCol>

              <GridCol cols={6}>
                <Control.Input
                  id="content.filters.areas"
                  label={t('геопозиция.область')}
                  {...register('content.filters.areas', {
                    maxLength: validate.maxLength(36),
                  })}
                  errors={errors}
                  placeholder={t('введите')}
                />
                <Divider small />

                <Control.Input
                  id="content.filters.streets"
                  label={t('геопозиция.улица')}
                  {...register('content.filters.streets', {
                    maxLength: validate.maxLength(36),
                  })}
                  errors={errors}
                  placeholder={t('введите')}
                />
                <Divider small />

              </GridCol>
            </GridRow>
          </Grid>
        </Fold>

        <Fold
          compact
          data-testid="AdditionalGeolocationFilters"
          title={t('tasks:exportForm.заголовки.расширенные по геолокации')}
          withPadding
        >
          <Grid>
            <GridRow>
              <GridCol cols={6}>
                <Control.Input
                  id="content.filters.origin_longitude"
                  label={t('долгота (-180...180)')}
                  {...register('content.filters.origin_longitude', {
                    min: validate.gte(-180),
                    max: validate.lte(180),
                    validate: {
                      number: validate.number(),
                      validateLatitude: (value) => {
                        if (!value && latitude) {
                          return validate.required();
                        }
                        return true;
                      },
                      validateDelta: (value) => {
                        if (!value && longitudelDelta) {
                          return validate.required();
                        }
                        return true;
                      },
                    } })}
                  errors={errors}
                  placeholder={t('введите')}
                />
                <Divider small />

                <Control.Input
                  id="content.filters.origin_latitude"
                  label={t('широта (-90...90)')}
                  {...register('content.filters.origin_latitude', {
                    min: validate.gte(-90),
                    max: validate.lte(90),
                    validate: {
                      number: validate.number(),
                      validateLongitude: (value) => {
                        if (!value && longitude) {
                          return validate.required();
                        }
                        return true;
                      },
                      validateDelta: (value) => {
                        if (!value && latitude_delta) {
                          return validate.required();
                        }
                        return true;
                      },
                    } })}
                  errors={errors}
                  placeholder={t('введите')}
                />
                <Divider small />
              </GridCol>
              <GridCol cols={6}>
                <Control.Input
                  id="content.filters.longitude_delta"
                  label={t('погрешность (0...90)')}
                  {...register('content.filters.longitude_delta', {
                    min: validate.gte(0),
                    max: validate.lte(90),
                    validate: validate.number(),
                  })}
                  errors={errors}
                  placeholder={t('введите')}
                />
                <Divider small />
                <Control.Input
                  id="content.filters.latitude_delta"
                  label={t('погрешность (0...90)')}
                  {...register('content.filters.latitude_delta', {
                    min: validate.gte(0),
                    max: validate.lte(90),
                    validate: validate.number(),
                  })}
                  errors={errors}
                  placeholder={t('введите')}
                />
                <Divider small />
              </GridCol>
            </GridRow>
          </Grid>
        </Fold>

        <Fold
          compact
          data-testid="OtherEventFilters"
          title={t('другое')}
          withPadding
        >
          <MetaFilterFields />
        </Fold>
      </Group>

      <Divider small />
    </Step>
  );
}

StepEventsFilters.fieldNames = [
  'content.filters',
  'meta',
];

StepEventsFilters.propTypes = {
  camOptions: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })),
  handlerOptions: PropTypes.arrayOf(optionPropType),
  isBodyLicenseAvailable: PropTypes.bool,
};

StepEventsFilters.defaultProps = {
  camOptions: [],
  handlerOptions: [],
  isBodyLicenseAvailable: false,
};

export default connect((state) => ({
  camOptions: selectSourceOptionsByAccount(state),
  handlerOptions: selectHandlerOptions(state),
  isBodyLicenseAvailable: selectIsAppFeatureAvailable(state, 'estimations.body_attributes'),
}))(StepEventsFilters);
