import React from 'react';

import { CircleInfoIcon } from '@vlabs/icons';
import { Control, Tooltip } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import st from './HandlerLists.module.sass';

const HandlerLists = ({
  lists,
}) => {
  const { t } = useTranslation();
  const tooltip = t('Списки и пороговые значения задаются в параметрах сравнения в выбранном обработчике сценария. Для редактирования нажмите на иконку карандаша возле выбранного обработчика, либо перейдите в раздел «Сценарии» и отредактируйте выбранный обработчик');

  return (
    <div className={st.Root}>
      <div className={st.Title}>
        {t('Списки для идентификации')}
        <Tooltip
          className="Tooltip_ui"
          data-tooltip-content={tooltip}
          tooltipProps={{ effect: 'solid', place: 'right' }}
        >
          <CircleInfoIcon className="Tooltip_icon" />
        </Tooltip>
      </div>

      <div className={st.Lists}>
        {lists.map(({ list, threshold }) => (
          <div className={st.ListsItem} key={list.value}>
            <Control.Link
              disabled={!list.label}
              to={`/lists/${list.value}`}
            >
              {list.label || list.value}
            </Control.Link>

            <div className={st.ListThreshold}>
              {threshold && t('Пороговое значение: {{threshold}}', { threshold })}
              {!threshold && t('Без порога')}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

HandlerLists.propTypes = {
  lists: PropTypes.arrayOf(PropTypes.shape({
    list: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
    threshold: PropTypes.number,
  })),
};

HandlerLists.defaultProps = {
  lists: [],
};

export {
  HandlerLists,
};
