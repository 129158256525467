import React from 'react';

import { DeepfakeSubForm } from '@vlabs/pages/handlers/forms/DeepfakeSubForm';
import { Step } from '@vlabs/uikit';
import cn from 'classnames';

import st from './shared.module.sass';

function StepDeepfake() {
  return (
    <Step className={cn(st.Step, st.NoTitle, st.FoldedStep)}>
      <DeepfakeSubForm />
    </Step>
  );
}

StepDeepfake.fieldNames = [
  'estiamte_deepfake',
];

export { StepDeepfake };
