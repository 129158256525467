import React from 'react';

import { optionPropType } from '@vlabs/shared';
import { selectListOptions } from '@vlabs/shared/selectors/listSelectors';
import validate from '@vlabs/shared/validators';
import { Divider, Grid, GridCol, GridRow, Control } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useFormContext, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

const StepCrossMatchComponent = ({ listOptions }) => {
  const { t } = useTranslation();
  const { control, register } = useFormContext();
  const { errors } = useFormState();

  return (
    <>
      <h6>{t('настройки задачи')}</h6>
      <Divider small />
      <Grid>
        <GridRow>
          <GridCol cols={6}>
            <Control.Select
              autoFocus
              control={control}
              errors={errors}
              id="referenceListId"
              isCreatable
              label={t('list')}
              name="referenceListId"
              options={listOptions}
              rules={{ required: validate.required(), validate: validate.uuidOptions }}
            />
            <Divider small />

            <Control.Input
              errors={errors}
              id="limit"
              label={t('tasks:максимальное количество похожих')}
              placeholder={t('')}
              {...register('limit', {
                min: validate.gte(1),
                max: validate.lte(30),
                required: validate.required(),
                validate: validate.integer(),
              })}
            />
          </GridCol>

          <GridCol cols={6}>
            <Control.Select
              control={control}
              errors={errors}
              id="candidateListId"
              isCreatable
              label={t('tasks:найти совпадения в')}
              name="candidateListId"
              options={listOptions}
              rules={{ required: validate.required(), validate: validate.uuidOptions }}
            />
            <Divider small />

            <Control.Input
              errors={errors}
              id="threshold"
              label={t('tasks:минимальный порог схожести %')}
              placeholder={t('')}
              {...register('threshold', {
                min: validate.gte(0),
                max: validate.lte(100),
                validate: validate.number(),
              })}
            />
          </GridCol>
        </GridRow>
      </Grid>
      <Divider small />
    </>
  );
};

StepCrossMatchComponent.fieldNames = [
  'candidateListId',
  'referenceListId',
  'limit',
  'threshold',
];

StepCrossMatchComponent.propTypes = {
  listOptions: PropTypes.arrayOf(optionPropType),
};

StepCrossMatchComponent.defaultProps = {
  listOptions: [],
};

export const StepCrossMatch = connect((state) => ({
  listOptions: selectListOptions(state),
}))(StepCrossMatchComponent);
