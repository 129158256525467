import { loadProgressBar } from '@vlabs/axios-progress-bar';
import Axios from 'axios';
import formatISO from 'date-fns/formatISO';
import qs from 'qs';

import '@vlabs/axios-progress-bar/dist/nprogress.css';
import { DEFAULT_HEALTHCHECK_TIMEOUT } from './config';

export class LunaConfigurator {
  constructor() {
    this.baseURL = '/api/configurator';
    this.baseURLWithVersion = `${this.baseURL}/1`;
    this.http = Axios.create({
      baseURL: this.baseURLWithVersion,
      withCredentials: true,
      paramsSerializer: (params) => (
        qs.stringify(params, {
          arrayFormat: 'comma',
          serializeDate: formatISO,
        })),
    });
    loadProgressBar('', this.http);
  }

  async healthcheck() {
    try {
      const version = await this.version();
      let { baseURL } = this;
      if (version.major === 3 && version.minor < 15) {
        baseURL = this.baseURLWithVersion;
      }
      return await this.http.get('/healthcheck', {
        baseURL,
        timeout: DEFAULT_HEALTHCHECK_TIMEOUT,
      });
    } catch (e) {
      if (e?.code === 'ECONNABORTED') throw e;
      if (e?.response?.status >= 500) throw e;
    }
    return undefined;
  }

  async version() {
    const { data: { Version } } = await this.http.get('/version', { baseURL: this.baseURL });
    return Version;
  }

  get configurator() {
    return {
      getConfigs: () => this.http.post('/puller/clementine'),
    };
  }
}
