import * as React from 'react';

import PropTypes from 'prop-types';

import './ErrorMessage.sass';

export const ErrorMessage = ({
  error,
}) => {
  if (!error) return null;

  return (
    <span className="ErrorMessage Subtitle-2">
      { error.message }
    </span>
  );
};

ErrorMessage.propTypes = {
  error: PropTypes.object,
};

ErrorMessage.defaultProps = {
  error: undefined,
};
