import React, { useEffect, useRef, useState } from 'react';

import { fabric } from 'fabric';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Button } from '../controls/button/Button';
import { Divider } from '../divider/Divider';
import { drawRectangle } from '../utils/canvas-drawing';
import { calcMaxModalSizes, calcRectangleCanvasCoords, setCanvasBackground } from '../utils/canvas-utils';
import {
  applyScaleObjPairs,
  denormalizeObjPairs,
  normalizeObjPairs,
} from '../utils/normalize';
import { enablePolygonDrawing } from './polygon-drawing';

import './ROISelector.sass';

const DEFAULT_ROI_COLOR = 'wheat'; // yellow
const DEFAULT_DROI_COLOR = 'dodgerblue'; // blue

/**
 * ROI -- это прямоугольник, который обрабатывается стримом.
 * DROI -- это произвольный полигон в котором детектятся данные (Detection ROI)
 */
const PolygonSelector = ({
  preview,
  onSelect,
  onCancel,
  roi,
  droi: initialDroiPointsProp,
  isNormalized,
  maxModalSizeRatio,
  coordinatesRounding,
  children,
  color,
  editable,
  ...restProps
}) => {
  const { t } = useTranslation();
  const [canvas, setCanvas] = useState(undefined);
  const [droiPoints, setDroiPoints] = useState(undefined); // denormalized
  const [initialDroiPoints, setInitialDroiPoints] = useState(undefined);
  const [rawImageSize, setRawImageSize] = useState(undefined);
  const isMounted = useRef(false);

  const drawRoi = () => {
    if (!roi || !roi.width || !roi.height || roi.width === 0 || roi.height === 0) return;

    const coords = calcRectangleCanvasCoords({ isNormalized, rectangle: roi, canvas });
    const rectangle = drawRectangle({
      id: 'roi',
      left: coords.x,
      top: coords.y,
      width: coords.width,
      height: coords.height,
      fill: DEFAULT_ROI_COLOR,
      selectable: false,
      evented: false,
      strokeWidth: 0,
    });

    canvas.add(rectangle);
  };

  useEffect(() => {
    const $canvas = new fabric.Canvas('PolygonSelectorCanvas', {
      selection: false,
    });

    setCanvas($canvas);
    isMounted.current = true;
    return () => {
      isMounted.current = false;
      // Иногда react удаляет объекты раньше fabric
      // что приводит к ошибкам, в данном случае достаточно
      // просто подавить ошибки
      try {
        $canvas.dispose();
      } catch (e) {
        // pass
      }
    };
  }, []);

  // Изменяем размер канваса в соответствие с подложкой
  useEffect(() => {
    if (!preview || !canvas) return;

    const [maxWidth, maxHeight] = calcMaxModalSizes(maxModalSizeRatio);

    fabric.Image.fromURL(preview, (bg) => {
      if (!isMounted.current) return;
      const { scaleFactor } = setCanvasBackground(canvas, bg, maxWidth, maxHeight);
      drawRoi(bg);

      const $rawImageSize = { rawWidth: bg.width, rawHeight: bg.height };
      setRawImageSize($rawImageSize);

      let $initialDroi;
      if (Array.isArray(initialDroiPointsProp) && initialDroiPointsProp.length) {
        $initialDroi = isNormalized
          ? denormalizeObjPairs(initialDroiPointsProp, canvas)
          : applyScaleObjPairs(initialDroiPointsProp, scaleFactor);
      }

      setInitialDroiPoints($initialDroi);

      enablePolygonDrawing({
        canvas,
        onChange: setDroiPoints,
        polygonFill: color,
        originSize: $rawImageSize,
        initialPolygonPoints: $initialDroi,
        editable,
      });
    });
  }, [preview, canvas, initialDroiPointsProp]);

  useEffect(() => {
    if (!canvas) return;
    const polygons = canvas.getObjects()?.filter(({ type }) => type === 'polygon');
    polygons.forEach((p) => { p.set('fill', color); });
    canvas.renderAll();
  }, [color]);

  const preparePoints = (polygonPoints) => {
    let points = polygonPoints;

    if (isNormalized) {
      points = normalizeObjPairs(points, {
        width: rawImageSize.rawWidth,
        height: rawImageSize.rawHeight,
      });
    }

    const round = (val) => Number(val.toFixed(coordinatesRounding));

    points = points.map((point) => ({
      x: round(point.x),
      y: round(point.y),
    }));

    return points;
  };

  const save = () => {
    if (!onSelect) return;

    onSelect(preparePoints(droiPoints));
  };

  const reset = ({ deleteDroi }) => {
    if (!canvas) return;

    canvas.getObjects().filter(({ id }) => id !== 'roi').forEach((objToRemove) => {
      // При удалении почему-то падает внутренная ошибка fabric во время рендера
      // контролов, так что сначала отключаем сами контролы
      objToRemove.set({ hasControls: false });
      canvas.remove(objToRemove);
    });

    setDroiPoints(undefined);

    enablePolygonDrawing({
      canvas,
      onChange: setDroiPoints,
      polygonFill: color,
      originSize: rawImageSize,
      initialPolygonPoints: deleteDroi ? null : initialDroiPoints,
      editable,
    });

    canvas.renderAll();
  };

  return (
    <div className="ROISelector">
      <canvas
        className="ROISelector__Canvas"
        id="PolygonSelectorCanvas"
        {...restProps}
      />

      <span className="ROISelector__Hint">
        {t('uikit:polygonSelector.подсказка по завершению построения')}
      </span>

      <Divider small />

      <div className="ROISelector__Buttons">
        <Button
          data-testid="polygonSelector.saveButton"
          disabled={!droiPoints?.length}
          fullWidth
          onClick={save}
        >
          {t('uikit:polygonSelector.действия.сохранение')}
        </Button>
        {initialDroiPointsProp && (
          <>
            <Divider small />
            <Button
              data-testid="polygonSelector.resetButton"
              fullWidth
              kind="attention"
              onClick={() => reset({ deleteDroi: false })}
            >
              {t('uikit:polygonSelector.действия.сброс')}
            </Button>
          </>
        )}
        <Divider small />
        <Button
          data-testid="polygonSelector.deleteButton"
          fullWidth
          kind="negative"
          onClick={() => reset({ deleteDroi: true })}
        >
          {t('uikit:polygonSelector.действия.удаление')}
        </Button>
      </div>
    </div>
  );
};

PolygonSelector.propTypes = {
  preview: PropTypes.string,
  onSelect: PropTypes.func,
  onCancel: PropTypes.func,
  roi: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  droi: PropTypes.arrayOf(PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  })),
  isNormalized: PropTypes.bool,
  maxModalSizeRatio: PropTypes.number,
  coordinatesRounding: PropTypes.number,
  children: PropTypes.node,
  color: PropTypes.string,
  editable: PropTypes.bool,
};

PolygonSelector.defaultProps = {
  preview: undefined,
  onSelect: undefined,
  onCancel: undefined,
  roi: undefined,
  droi: undefined,
  isNormalized: true,
  maxModalSizeRatio: 0.8,
  coordinatesRounding: 2,
  children: undefined,
  color: DEFAULT_DROI_COLOR,
  editable: false,
};

export { PolygonSelector };
