import React, { useMemo } from 'react';

import { selectAppFeatures } from '@vlabs/shared/selectors/appSelectors';
import { Page, Panel, Divider, Tabs, Tab, Table, Control } from '@vlabs/uikit';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import st from './FeaturesPage.module.sass';

const StatusCell = ({ value }) => {
  return <strong>{value}</strong>;
};

StatusCell.propTypes = {
  value: PropTypes.string,
};
StatusCell.defaultProps = {
  value: undefined,
};
function FeaturesPageComponent({
  features,
  showLicenseLink,
}) {
  const { t, i18n } = useTranslation();

  const ctaLocalizedHref = useMemo(() => {
    if (i18n.language === 'ru') {
      return 'https://visionlabs.ru/ru/contact-us';
    }
    return 'https://visionlabs.ai/contact-us/';
  }, [i18n.language]);

  const tabsData = useMemo(() => {
    const getTableRecord = (key, prefix = undefined) => {
      const path = prefix ? `${prefix}.${key}` : key;
      const rawValue = get(features, path, false);

      const status = t(`featuresPage:isEnabled.${rawValue}`);
      const technicalName = t(`featuresPage:features.${path}.technicalName`, { defaultValue: '' });
      const name = t(`featuresPage:features.${path}.name`, { defaultValue: key });
      const description = t(`featuresPage:features.${path}.description`, { defaultValue: '' });

      return { name, description, status, technicalName };
    };
    return {
      general: {
        data: [{
          status: t(`featuresPage:features.license.isValid.${features.license}`) || 'license',
        }],
        columns: [{ accessor: 'status', Cell: StatusCell }],
        noHead: true,
      },
      estimations: {
        data: Object.keys(features.estimations).map((k) => getTableRecord(k, 'estimations')),
        columns: [
          { Header: t('featuresPage:table.header.name'), accessor: 'name', width: 100 },
          { Header: t('featuresPage:table.header.description'), accessor: 'description' },
          { Header: t('featuresPage:table.header.technicalName'), accessor: 'technicalName', width: 100 },
          { Header: t('featuresPage:table.header.status'), accessor: 'status', Cell: StatusCell, width: 80 },
        ],
      },
      components: {
        data: Object.keys(features.components).map((k) => getTableRecord(k, 'components')),
        columns: [
          { Header: t('featuresPage:table.header.name'), accessor: 'name', width: 80 },
          { Header: t('featuresPage:table.header.description'), accessor: 'description' },
          { Header: t('featuresPage:table.header.status'), accessor: 'status', Cell: StatusCell, width: 80 },
        ],
      },
    };
  }, [features, t]);

  return (
    <Page className={st.Root} title={t('featuresPage:title')}>
      <Divider />
      <Panel className={st.Panel}>
        <Tabs testId="featuresTabs">
          {Object.entries(tabsData).map(([section, config]) => (
            <Tab
              header={t(`featuresPage:section.${section}.title`)}
              headerClassName={st.TabHeader}
              key={section}
              name={section}
            >
              <Table
                columns={config.columns}
                data={config.data}
                noBottomPanel
                noHead={config?.noHead}
              />
            </Tab>
          ))}
        </Tabs>
        {showLicenseLink && (
          <Control.LinkButton
            className={st.CallToActionLink}
            href={ctaLocalizedHref}
            rel="noopener noreferrer"
            target="_blank"
          >
            {t('featuresPage:cta')}
          </Control.LinkButton>
        )}
      </Panel>
    </Page>
  );
}

FeaturesPageComponent.propTypes = {
  features: PropTypes.shape({
    license: PropTypes.bool,
    estimations: PropTypes.shape({
      face_quality: PropTypes.bool,
      body_attributes: PropTypes.bool,
      liveness: PropTypes.bool,
    }),
    components: PropTypes.shape({
      events: PropTypes.bool,
      tasks: PropTypes.bool,
      sender: PropTypes.bool,
    }),
  }),
  showLicenseLink: PropTypes.bool,
};

FeaturesPageComponent.defaultProps = {
  features: {
    license: false,
    estimations: {},
    components: {},
  },
  showLicenseLink: true,
};

export const FeaturesPage = connect((state) => ({
  features: selectAppFeatures(state),
}))(FeaturesPageComponent);
