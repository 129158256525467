/* eslint-disable no-param-reassign */
const applyRef = (ref, node) => {
  // При unmount'е, ref будет вызван с null, так что скипаем эти фазы
  // чтобы ничего не сломалось
  // https://reactjs.org/docs/refs-and-the-dom.html#callback-refs
  if (!ref || !node) return;

  if (typeof ref === 'function') {
    ref(node);
  } else {
    ref.current = node;
  }
};

export const mergeRefs = (...refs) => {
  return (node) => {
    refs.forEach((ref) => {
      applyRef(ref, node);
    });
  };
};
