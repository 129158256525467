import React, { useCallback, useEffect, useRef, useState } from 'react';

import { MinusIcon, PlusIcon, RotationRightIcon } from '@vlabs/icons';
import cn from 'classnames';
import { fabric } from 'fabric';
import PropTypes from 'prop-types';

import { RoundButton } from '../controls/button/RoundButton';
import { calcMaxModalSizes, setCanvasBackground } from '../utils/canvas-utils';
import { denormalizeCoordinateByFactors, normalizeCoordinateByFactors } from '../utils/normalize';
import { createPoint } from './helpers';

import './ImagesPointsMatcher.sass';

const ZOOM_STEP_IN = 1.15;
const ZOOM_STEP_OUT = 1 / ZOOM_STEP_IN;

const isDrawingKey = (key) => key === 'Control' || key === 'Meta';
const isDrawingModifierPressed = (e) => e.ctrlKey || e.metaKey;

const ImagesPointsMatcherCanvas = ({
  points,
  image,
  maxWidth,
  maxModalSizeRatio,
  isActive,
  id,
  onPointCreate,
  onPointUpdate,
  onPointDelete,
  isZoomable,
}) => {
  const [canvas, setCanvas] = useState(undefined);
  const [zoomFactor, setZoomFactor] = useState(1);
  const [isGrabbing, setIsGrabbing] = useState(false);
  const isMounted = useRef(false);

  // Отступ в канвасе, чтобы маркеры можно было ставить
  // на самый верх картинки, но они все равно нормально отображались бы
  const paddingTop = 40;

  const $normalizePoint = (point, { width, height }) => {
    return normalizeCoordinateByFactors(point, { width, height: height - paddingTop }, 'cartesian');
  };

  const $denormalizePoint = (point, { width, height }) => {
    return denormalizeCoordinateByFactors(point, { width, height: height - paddingTop }, 'cartesian');
  };

  const $createPoint = useCallback((options) => {
    if (!isActive) return;

    let point = canvas.getPointer(options.e);

    if (point.y < paddingTop) return;

    point.y -= paddingTop;

    point = $normalizePoint({
      x: point.x,
      y: point.y,
    }, canvas);

    if (onPointCreate) {
      onPointCreate(point);
    }
  }, [isActive, canvas]);

  const onStartGrabbing = (options) => {
    if (options.target || !isZoomable) return;

    setIsGrabbing(true);
    canvas.setCursor('grabbing');
  };

  const onEndGrabbing = () => {
    setIsGrabbing(false);
    canvas.setCursor('default');
  };

  const onCanvasClick = (options) => {
    if (isDrawingModifierPressed(options.e)) {
      $createPoint(options);
    } else {
      onStartGrabbing(options);
    }
  };

  const onCanvasClickEnd = () => {
    onEndGrabbing();
  };

  const onKeyDown = (e) => {
    if (!isActive) return;

    if (isDrawingKey(e.key)) {
      canvas.setCursor('crosshair');
    }
  };

  const onKeyUp = (e) => {
    if (!isActive) return;

    if (isDrawingKey(e.key)) {
      canvas.setCursor('default');
    }
  };

  const onCanvasDrag = ({ e }) => {
    if (!isGrabbing || zoomFactor === 1 || !e) return;

    // Приходится обновлять, потому что под капотом fabric
    // сам на каждый mouse:move делает setCursor('default')
    canvas.setCursor('grabbing');
    canvas.relativePan(new fabric.Point(e.movementX, e.movementY));
  };

  const onCanvasMove = (options) => {
    if (isActive && isDrawingModifierPressed(options.e)) {
      canvas.setCursor('crosshair');
    } else {
      onCanvasDrag(options);
    }
  };

  // инициализация
  useEffect(() => {
    const $canvas = new fabric.Canvas(id, {
      selection: false,
    });

    setCanvas($canvas);
    isMounted.current = true;

    return () => {
      isMounted.current = false;
      // Иногда react удаляет объекты раньше fabric
      // что приводит к ошибкам, в данном случае достаточно
      // просто подавить ошибки
      try {
        $canvas.dispose();
      } catch (e) {
        // pass
      }
    };
  }, []);

  const onPointMove = (options, i) => {
    let point = {
      x: Math.min(Math.max(options.target.left, 0), canvas.width),
      y: Math.min(Math.max(options.target.top - paddingTop, 0), canvas.height - paddingTop),
    };

    point = $normalizePoint(point, canvas);

    if (onPointUpdate) {
      onPointUpdate(i, point);
    }
  };

  const renderPoints = (_points) => {
    canvas.remove(...canvas.getObjects());

    if (!_points) return;

    _points.forEach(($point, i) => {
      if (!$point) { return; }
      const { x, y } = $denormalizePoint($point, canvas);
      const point = createPoint(canvas, x, y + paddingTop, (i + 1).toString());

      point.on('mousedblclick', () => onPointDelete(i));
      point.on('moved', (options) => onPointMove(options, i));

      point.scale(1 / zoomFactor);
      canvas.add(point);
    });
  };

  // рисуем картинку
  useEffect(() => {
    if (!image || !canvas) return;

    const [maxModalWidth, maxHeight] = calcMaxModalSizes(maxModalSizeRatio);

    fabric.Image.fromObject(image, (bg) => {
      if (!isMounted) return;
      setCanvasBackground(
        canvas,
        bg,
        maxWidth || maxModalWidth,
        maxHeight,
        paddingTop,
      );
      // перерисовываем точки при обновлении картинки
      renderPoints(points);
    });
  }, [canvas, image, maxWidth, points]);

  // вешаем обработчики
  useEffect(() => {
    if (!canvas) return undefined;

    // eslint-disable-next-line no-underscore-dangle
    canvas.__eventListeners = {};
    canvas.on('mouse:down', onCanvasClick);
    canvas.on('mouse:up', onCanvasClickEnd);
    canvas.on('mouse:move', onCanvasMove);

    document.addEventListener('keydown', onKeyDown);
    document.addEventListener('keyup', onKeyUp);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
      document.removeEventListener('keyup', onKeyUp);
    };
  }, [canvas, onCanvasClick]);

  // рисуем точки при редактировании точек
  useEffect(() => {
    if (!canvas) return;
    renderPoints(points);
  }, [canvas, points]);

  const zoom = (factor) => {
    if (!canvas) return;

    setZoomFactor(Math.max(canvas.getZoom() * factor, 1));
  };

  const resetZoom = () => {
    setZoomFactor(1);
    canvas.absolutePan(new fabric.Point(0, 0));
  };

  useEffect(() => {
    if (!canvas) return;
    canvas.setZoom(zoomFactor);

    if (zoomFactor === 1) {
      resetZoom();
    }

    canvas.getObjects().forEach((point) => {
      point.scale(1 / zoomFactor);
    });
  }, [zoomFactor]);

  const zoomIn = () => {
    zoom(ZOOM_STEP_IN);
  };

  const zoomOut = () => {
    if (zoomFactor === 1) return;

    zoom(ZOOM_STEP_OUT);
  };

  return (
    <div className={cn({
      ImagesPointsMatcher__CanvasWrapper: true,
      ImagesPointsMatcher__CanvasWrapper_active: isActive,
    })}
    >
      <canvas
        className="ImagesPointsMatcher__Canvas"
        id={id}
      />

      {
        isZoomable && (
          <div className="CanvasControls">
            <RoundButton
              className="CanvasControls__Button"
              icon={<PlusIcon />}
              onClick={zoomIn}
            />

            <RoundButton
              className="CanvasControls__Button"
              disabled={zoomFactor === 1}
              icon={<MinusIcon />}
              onClick={zoomOut}
            />

            <RoundButton
              className="CanvasControls__Button"
              disabled={zoomFactor === 1}
              icon={<RotationRightIcon />}
              onClick={resetZoom}
            />
          </div>
        )
      }
    </div>
  );
};

ImagesPointsMatcherCanvas.propTypes = {
  points: PropTypes.arrayOf(PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  })),
  image: PropTypes.instanceOf(Image),
  maxWidth: PropTypes.number,
  maxModalSizeRatio: PropTypes.number,
  isActive: PropTypes.bool,
  id: PropTypes.string,
  onPointCreate: PropTypes.func,
  onPointUpdate: PropTypes.func,
  onPointDelete: PropTypes.func,
  isZoomable: PropTypes.bool,
};

ImagesPointsMatcherCanvas.defaultProps = {
  points: undefined,
  image: undefined,
  maxWidth: undefined,
  maxModalSizeRatio: 0.8,
  isActive: false,
  id: undefined,
  onPointCreate: undefined,
  onPointUpdate: undefined,
  onPointDelete: undefined,
  isZoomable: false,
};

export { ImagesPointsMatcherCanvas };
