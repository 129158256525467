import { isUndefined } from 'lodash';

import { Schema, get, isEmpty } from '../../../../json-transform';

const optionValue = (option) => option?.value;
export const create = new Schema({
  name: get('name').asString(),
  input: {
    url: get('input.url').asString(),
    transport: get('input.transport').as(optionValue),
    roi: get('input.roi').asArrayFromString().asArrayOfInt().ifEmpty(undefined),
    droi: get('input.droi').asArrayFromString().asArrayOfInt().ifEmpty(undefined),
    rotation: get('input.rotation').as(optionValue),
    frame_processing_mode: get('input.frameProcessingMode', 'auto').as(optionValue),
    ffmpeg_threads_number: get('input.numberOfFfmpegThreads').asInt().ifNaN(undefined),
  },
  output: {
    url: get('output.url').as(optionValue),
    luna_account_id: get('output.lunaAccountId').asString().ifEmptyString(undefined),
    luna_human_handler_id: get('output.lunaHumanHandlerId').asString().ifEmptyString(undefined),
    luna_dynamic_human_handler_id: get('output.lunaDynamicHumanHandlerId').asString().ifEmptyString(undefined),
    image_store_url: get('output.imageStoreUrl').asString().ifEmptyString(undefined),
  },
  location: new Schema({
    send_location_data: get('location.isEnabled').asBoolean(),
    city: get('location.city').asString().ifEmptyString(undefined),
    area: get('location.area').asString().ifEmptyString(undefined),
    district: get('location.district').asString().ifEmptyString(undefined),
    street: get('location.street').asString().ifEmptyString(undefined),
    house_number: get('location.houseNumber').asString().ifEmptyString(undefined),
    geo_position: new Schema({
      longitude: get('location.geoPosition.longitude').asFloat().ifNaN(undefined),
      latitude: get('location.geoPosition.latitude').asFloat().ifNaN(undefined),
    })
      .omitBy(isUndefined)
      .omitBy(isEmpty),
  })
    .omitBy(isUndefined)
    .omitBy(isEmpty),
  health_check: {
    max_error_count: get('healthcheck.maxErrorCount').asInt().ifNaN(undefined),
    period: get('healthcheck.period').asInt().ifNaN(undefined),
    retry_delay: get('healthcheck.retryDelay').asInt().ifNaN(undefined),
  },
  filtering: {
    min_score: get('filtering.minScore').asFloat().ifNaN(undefined),
    detection_yaw_threshold: get('filtering.detectionYawThreshold').asInt().ifNaN(undefined),
    detection_pitch_threshold: get('filtering.detectionPitchThreshold').asInt().ifNaN(undefined),
    detection_roll_threshold: get('filtering.detectionRollThreshold').asInt().ifNaN(undefined),
    yaw_number: get('filtering.yawNumber').asInt().ifNaN(undefined),
    yaw_collection_mode: get('filtering.yawCollectionMode').asBoolean(),
    mouth_occlusion_threshold: get('filtering.mouthOcclusionThreshold').asFloat().ifNaN(undefined),
  },
  sending: {
    time_period_of_searching: get('sending.timePeriodOfSearching').asInt().ifNaN(undefined),
    silent_period: get('sending.silentPeriod').asInt().ifNaN(undefined),
    type: get('sending.type').as(optionValue),
    number_of_bestshots_to_send: get('sending.numberOfBestshotsToSend').asInt().ifNaN(undefined),
    send_each_frames: get('sending.sendEachFrames').asInt().ifNaN(undefined),
    send_each_milliseconds: get('sending.sendEachMilliseconds').asInt().ifNaN(undefined),
    send_only_full_set: get('sending.sendOnlyFullSet').asBoolean(),
    delete_track_after_sending: get('sending.deleteTrackAfterSending').asBoolean(),
  },
  primary_track_policy: {
    use_primary_track_policy: get('primaryTrackPolicy.usePrimaryTrackPolicy').asBoolean(),
    best_shot_min_size: get('primaryTrackPolicy.bestShotMinSize').asInt().ifNaN(undefined),
    best_shot_proper_size: get('primaryTrackPolicy.bestShotProperSize').asInt().ifNaN(undefined),
  },
  liveness: {
    use_shoulders_liveness_filtration: get('liveness.useShouldersLivenessFiltration').asBoolean(),
    use_mask_liveness_filtration: get('liveness.useMaskLivenessFiltration').asBoolean(),
    use_flying_faces_liveness_filtration: get('liveness.useFlyingFacesLivenessFiltration').asBoolean(),
    liveness_mode: get('liveness.livenessMode').as(optionValue),
    number_of_liveness_checks: get('liveness.numberOfLivenessChecks').asInt().ifNaN(undefined),
    liveness_threshold: get('liveness.livenessThreshold').asFloat().ifNaN(undefined),
    livenesses_weights: get('liveness.livenessesWeights').asArrayOfFloat().ifEmpty(undefined),
    mask_backgrounds_count: get('liveness.maskBackgroundsCount').asInt().ifNaN(undefined),
  },
});
