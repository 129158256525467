import { createSlice } from '@reduxjs/toolkit';
import { apiContainer } from '@vlabs/api-bindings';
import { getEventsQS } from '@vlabs/api-bindings/src/luna-client/events/mappers';
import { roles } from '@vlabs/shared/config';

import { selectAccountRole } from '@vlabs/pages/auth/selectors';
import { selectSourceOptionsByAccount } from '@vlabs/pages/sources/selectors';

const store = createSlice({
  name: 'events',
  initialState: {
    data: [],
    filters: {},
    pageIndex: 0,
    pageSize: 25,
    pageSizeOptions: [10, 25, 50, 100],
    isTopMatchHiding: false,
  },
  reducers: {
    setEvents(state, { payload: { events } }) {
      state.data = events;
    },
    setFilters(state, { payload: { filters = {} } }) {
      state.filters = filters;
      state.pageIndex = 0;
    },
    setPage(state, { payload: { pageIndex } = {} }) {
      if (pageIndex !== undefined) state.pageIndex = pageIndex;
    },
    setPageSize(state, { payload: { pageSize } = {} }) {
      if (pageSize !== undefined) state.pageSize = pageSize;
    },
    setIsTopMatchHiding(state) {
      state.isTopMatchHiding = !state.isTopMatchHiding;
    },
  },
});

export default store.reducer;

export const { setEvents, setFilters, setPageSize, setPage, setIsTopMatchHiding } = store.actions;

export const fetchEvents = async (dispatch, getState) => {
  const isAccountUser = selectAccountRole(getState()) === roles.USER;
  const accountSources = selectSourceOptionsByAccount(getState())
    .reduce((acc, { options }) => [...acc, ...options], [])
    .map(({ value }) => value);

  const {
    events: { pageSize, pageIndex, filters },
  } = getState();

  if (isAccountUser && accountSources.length === 0) {
    dispatch(setEvents({ events: [] }));
    return;
  }

  const localFilters = { ...filters };
  if (isAccountUser && !localFilters.sources?.length) {
    localFilters.sources = accountSources;
  }

  try {
    const events = await apiContainer.lunaClient.events
      .getAll(getEventsQS({ ...localFilters, page: pageIndex + 1, page_size: pageSize }));

    dispatch(setEvents({ events }));
  } catch (error) {
    dispatch(setEvents({ events: [] }));
    throw error;
  }
};

export const updateFilters = (params) => async (dispatch) => {
  dispatch(setFilters(params));
  dispatch(fetchEvents);
};

export const resetFilters = async (dispatch) => {
  dispatch(setFilters({}));
};

export const updatePageSize = (params) => async (dispatch) => {
  dispatch(setPageSize(params));
  dispatch(fetchEvents);
};
export const updatePage = (params) => async (dispatch) => {
  dispatch(setPage(params));
  dispatch(fetchEvents);
};
