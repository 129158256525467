import React, { useState, useEffect, useCallback } from 'react';

import { apiContainer } from '@vlabs/api-bindings';
import { optionPropType } from '@vlabs/shared';
import { DetectionSelector } from '@vlabs/shared/components/detection-selector';
import Loader from '@vlabs/shared/legacy-components/loader/Loader';
import { selectListOptions } from '@vlabs/shared/selectors/listSelectors';
import validate from '@vlabs/shared/validators';
import { Control, Divider, Page } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import st from './CreateFaceBySampleForm.module.sass';

const PhotoUploadForm = ({ sampleUrl }) => {
  const [faceDetection, setFaceDetection] = useState();

  const fetchData = useCallback(async () => {
    const response = await fetch(sampleUrl);
    const image = await response.blob();
    const { data } = await apiContainer.lunaClient.sdk.estimate(image, { detect_face: 1 });
    setFaceDetection([{ ...data.images_estimations?.[0]?.estimations[0]?.face.detection }]);
  }, [sampleUrl]);

  useEffect(() => { fetchData(); }, [fetchData, sampleUrl]);

  return (
    <>
      {!faceDetection && (
        <div className={st.Loader}>
          <Loader />
        </div>
      )}

      {faceDetection && (
        <DetectionSelector
          detections={faceDetection}
          imageRenderer={(
            <Control.ImageFileRenderer
              photo={sampleUrl}
            />
          )}
        />
      )}
    </>
  );
};

const CreateFaceFromDetectionFormComponent = ({
  afterSubmit,
  listOptions,
  detection,
}) => {
  const { t } = useTranslation();
  const form = useForm();

  const createFace = async (values) => {
    await apiContainer.lunaClient.faces.create(
      { ...values, sample_id: [detection.sample_id] }, { extract_basic_attributes: 1 },
    );
    toast.success(`${t('faces:создание.успех')}.`);
    if (afterSubmit) afterSubmit();
  };

  return (
    <FormProvider form={form}>
      <form onSubmit={form.handleSubmit(createFace)}>
        <Page className={st.Root}>
          <h5>{t('events:action.create face')}</h5>
          <Divider small />

          <div className={st.ColumnsContainer}>
            <div className={st.PhotoUploadColumn}>
              <PhotoUploadForm sampleUrl={detection.uiSampleURL} />
            </div>
            <div className={st.FormFieldColumn}>
              <Control.Textarea
                id="user_data"
                label={t('information')}
                rows={2}
                {...form.register('user_data', {
                  maxLength: validate.maxLength(128),
                })}
                errors={form.formState.errors}
              />
              <Divider small />

              <Control.Input
                id="external_id"
                label={t('external id')}
                {...form.register('external_id', {
                  maxLength: validate.maxLength(36),
                })}
                errors={form.formState.errors}
              />
              <Divider small />

              <Control.Select
                control={form.control}
                errors={form.formState.errors}
                id="lists"
                isMulti
                label={t('списки')}
                name="lists"
                options={listOptions}
              />

            </div>
          </div>

          <Divider small />
          <Control.Button fullWidth type="submit">
            {t('форма.submit')}
          </Control.Button>
        </Page>
      </form>
    </FormProvider>
  );
};

CreateFaceFromDetectionFormComponent.propTypes = {
  afterSubmit: PropTypes.func,
  listOptions: PropTypes.arrayOf(optionPropType),
  detection: PropTypes.shape({
    image_origin: PropTypes.string,
    sample_id: PropTypes.string,
    uiSampleURL: PropTypes.string,
  }).isRequired,
};

CreateFaceFromDetectionFormComponent.defaultProps = {
  afterSubmit: undefined,
  listOptions: [],
};

export const CreateFaceFromDetectionForm = connect(
  (state) => ({
    listOptions: selectListOptions(state),
  }),
)(CreateFaceFromDetectionFormComponent);
