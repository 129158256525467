function getFileType(fileRaw) {
  const extension = fileRaw.name.split('.').pop().toLowerCase();
  // формат PPM (Portable Pixmap) не имеет MIME-тип
  if (extension === 'ppm') {
    return 'image/x-portable-pixmap';
  }
  return fileRaw.type;
}

export function addFileType(newFile) {
  const type = getFileType(newFile);
  return new File([newFile], newFile.name, { ...newFile, type });
}
