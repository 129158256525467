import React from 'react';

import { LivenessSubForm } from '@vlabs/pages/handlers/forms/LivenessSubForm';
import { Step } from '@vlabs/uikit';
import cn from 'classnames';

import st from './shared.module.sass';

function StepLiveness() {
  return (
    <Step className={cn(st.Step, st.FoldedStep)}>
      <LivenessSubForm />
    </Step>
  );
}

StepLiveness.fieldNames = [
  'estimate_liveness',
];

export { StepLiveness };
