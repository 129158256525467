import { CleanSchema, get } from '../../../json-transform';

export const CommonEventsFilters = new CleanSchema({
  origin: get('origin').ifEmpty('events'),
  event_ids: get('event_ids').asArrayFromString().ifEmpty(undefined),
  account_id: get('account_id'),
  event_id__gte: get('event_id__gte'),
  event_id__lt: get('event_id__lt'),
  handler_ids: get('handler_ids').asArrayOfOptionValues().ifEmpty(undefined),
  external_ids: get('external_ids').asArrayFromString().ifEmpty(undefined),
  top_matching_candidates_label: get('top_matching_candidates_label').ifEmptyString({}),
  top_similar_object_ids: get('top_similar_object_ids').asArrayFromString().ifEmpty(undefined),
  top_similar_object_similarity__gte: get('top_similar_object_similarity__gte').asFloat().ifNaN(undefined),
  top_similar_object_similarity__lt: get('top_similar_object_similarity__lt').asFloat().ifNaN(undefined),
  age__gte: get('age.value[0]').asInt(),
  age__lt: get('age.value[1]').asInt(),
  gender: get('gender.value').asInt(),
  emotions: get('emotions').asArrayOfOptionValues().asArrayWithOptionalNull().ifEmpty(undefined),
  liveness: get('liveness').asArrayOfOptionValues().asArrayWithOptionalNull().ifEmpty(undefined),
  masks: get('masks').asArrayOfOptionValues().asArrayWithOptionalNull().ifEmpty(undefined),
  ethnic_groups: get('ethnicities').asArrayOfOptionValues().asArrayWithOptionalNull().ifEmpty(undefined),
  face_ids: get('face_ids').asArrayFromString().ifEmpty(undefined),
  user_data: get('user_data').ifEmptyString(undefined),
  sources: get('sources').asArrayOfOptionValues().asArrayWithOptionalNull().ifEmpty(undefined),
  tags: get('tags').asArrayOfOptionValues().ifEmpty(undefined),
  cities: get('cities').asArrayFromString().asArrayWithOptionalNull().ifEmpty(undefined),
  areas: get('areas').asArrayFromString().asArrayWithOptionalNull().ifEmpty(undefined),
  districts: get('districts').asArrayFromString().asArrayWithOptionalNull().ifEmpty(undefined),
  streets: get('streets').asArrayFromString().asArrayWithOptionalNull().ifEmpty(undefined),
  house_numbers: get('house_numbers').asArrayFromString().asArrayWithOptionalNull().ifEmpty(undefined),
  geo_position: {
    origin_longitude: get('origin_longitude').asFloat().ifNaN(undefined),
    origin_latitude: get('origin_latitude').asFloat().ifNaN(undefined),
    longitude_delta: get('longitude_delta').asFloat().ifNaN(undefined),
    latitude_delta: get('latitude_delta').asFloat().ifNaN(undefined),
  },
  track_ids: get('track_ids').asArrayFromString().asArrayWithOptionalNull().ifEmpty(undefined),
  apparent_gender: get('apparent_gender').asArrayOfOptionValues().asArrayWithOptionalNull().ifEmpty(undefined),
  headwear_apparent_colors: get('headwear_apparent_colors').asArrayOfOptionValues().asArrayWithOptionalNull().ifEmpty(undefined),
  shoes_apparent_colors: get('shoes_apparent_colors').asArrayOfOptionValues().asArrayWithOptionalNull().ifEmpty(undefined),
  lower_garment_colors: get('lower_garment_colors').asArrayOfOptionValues().asArrayWithOptionalNull().ifEmpty(undefined),
  lower_garment_types: get('lower_garment_types').asArrayOfOptionValues().asArrayWithOptionalNull().ifEmpty(undefined),
  list_id: get('list_id.value').ifEmpty(undefined),
  apparent_age__gte: get('apparent_age.value[0]').asInt().ifNaN(undefined),
  apparent_age__lt: get('apparent_age.value[1]').asInt().ifNaN(undefined),
  headwear_states: get('headwear_states').asArrayOfOptionValues().asArrayWithOptionalNull().ifEmpty(undefined),
  sleeve_lengths: get('sleeve_lengths').asArrayOfOptionValues().asArrayWithOptionalNull().ifEmpty(undefined),
  upper_clothing_colors: get('upper_clothing_colors').asArrayOfOptionValues().asArrayWithOptionalNull().ifEmpty(undefined),
  backpack_states: get('backpack_states').asArrayOfOptionValues().asArrayWithOptionalNull().ifEmpty(undefined),
  create_time__gte: get('create_time__gte[0]').asISOString(),
  create_time__lt: get('create_time__lt[0]').asISOString(),
  end_time__gte: get('end_time__gte[0]').asISOString(),
  end_time__lt: get('end_time__lt[0]').asISOString(),
  stream_ids: get('stream_ids').asArrayOfOptionValues().asArrayWithOptionalNull().ifEmpty(undefined),
});

const getMetaFilters = ({ meta = [] }) => {
  const updatedMetaObject = {};
  meta.forEach((m) => {
    updatedMetaObject[`${m.key}__${m.operator.value}`] = m.value;
  });
  return updatedMetaObject;
};

export const EventFiltersSubSchema = (obj) => {
  return {
    ...CommonEventsFilters(obj),
    meta: getMetaFilters(obj),
  };
};
