export const defaultFilters = {
  create_time__gte: null,
  create_time__lt: null,
  sources: '',
  event_ids: null,
  external_ids: null,
  tags: null,
  handler_ids: null,
  top_matching_candidates_label: '',
  top_similar_object_similarity__gte: '',
  top_similar_object_similarity__lt: '',
  top_similar_object_ids: '',
  apparent_age: null,
  gender: null,
  age: null,
  liveness: null,
  deepfake: null,
  emotions: null,
  masks: null,
  face_ids: null,
  upper_clothing_colors: null,
  lower_garment_types: null,
  lower_garment_colors: null,
  shoes_apparent_colors: null,
  headwear_states: null,
  headwear_apparent_colors: null,
  backpack_states: null,
  sleeve_lengths: null,
  apparent_gender: null,
  cities: '',
  areas: '',
  districts: '',
  streets: '',
  house_numbers: '',
  origin_longitude: '',
  longitude_delta: '',
  origin_latitude: '',
  latitude_delta: '',
  track_ids: '',
  meta: [],
};
