import React from 'react';

import { CircleInfoIcon } from '@vlabs/icons';
import CheckRow from '@vlabs/pages/checks/components/CheckRow';
import CheckTitle from '@vlabs/pages/checks/components/CheckTitle';
import { checksValueMapperByType } from '@vlabs/pages/checks/utils';
import i18next from 'i18next';

import st from './IsoErrorsTab.module.sass';

const IsoErrorsTab = ({ results }) => {
  if (!results?.detection) {
    return (
      <div className={st.InfoText}>
        <CircleInfoIcon className={st.InfoIcon} />
        <div>{i18next.t('checks:iso tab.no result.tooltip')}</div>
      </div>
    );
  }
  const checksArray = [...(results?.detection?.iso?.checks || [])];
  const sortedChecks = checksArray?.sort((a, b) => a.result - b.result);
  const checks = sortedChecks.map(checksValueMapperByType);
  const status = results?.detection?.iso?.status;
  const errorChecks = checks.filter(({ result, object_value }) => !result && object_value !== null);
  const notEstimatedChecks = checks.filter(({ result, object_value }) => !result && object_value === null);

  return (
    <>
      <CheckTitle
        errorChecks={errorChecks}
        isSmallSize
        notEstimatedChecks={notEstimatedChecks}
        status={status}
      />
      <div className={st.Result__Rows}>
        {errorChecks.map(CheckRow)}
      </div>
    </>
  );
};

export { IsoErrorsTab };
