import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import './Divider.sass';

const Divider = ({
  small,
}) => {
  return (
    <div
      className={cn({
        Divider: true,
        Divider_small: small,
      })}
    />
  );
};

Divider.propTypes = {
  small: PropTypes.bool,
};

Divider.defaultProps = {
  small: false,
};

export { Divider };
