import React from 'react';

import { StopIcon, CircleCheckIcon, CircleInfoIcon, CircleQuestionIcon } from '@vlabs/icons';
import { Tooltip } from '@vlabs/uikit';
import cn from 'classnames';
import PropTypes from 'prop-types';

import { STATUS_MAP } from '../utils';
import st from './CheckRow.module.sass';

const CheckRow = ({ name, object_value, result, tooltipText, checkValue, checkName }) => {
  const statusResult = STATUS_MAP[result];
  return (
    <div
      className={st.Row}
      key={name}
    >
      <div className={st.Column}>
        <div
          className={cn(st.IconWrapper, {
            [st.StateColor_error]: statusResult === 'failed',
            [st.StateColor_success]: statusResult === 'passed',
            [st.StateColor_unknown]: statusResult === 'unknown',
            // для отображения опущенной проверки (без значения) из notEstimatedChecks при кейсе с warp
            [st.StateColor_neutral]: statusResult === 'failed' && object_value === null,
          })}
        >
          {statusResult === 'failed' && <StopIcon className={st.Icon} />}
          {statusResult === 'passed' && <CircleCheckIcon className={st.Icon} />}
          {statusResult === 'unknown' && <CircleQuestionIcon className={st.Icon} />}
        </div>
        {checkName}
      </div>
      <div className={st.Column}>
        {checkValue}
        <div className={st.Tooltip_Cell}>
          {tooltipText && (
            <Tooltip
              className="Tooltip_ui"
              data-tooltip-content={tooltipText}
              tooltipProps={{ effect: 'solid', place: 'bottom-end' }}
            >
              <CircleInfoIcon className="Tooltip_icon" />
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};

CheckRow.propTypes = {
  result: PropTypes.number.isRequired,
  checkValue: PropTypes.string.isRequired,
  checkName: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  object_value: PropTypes.any,
  tooltipText: PropTypes.string,
};

CheckRow.defaultProps = {
  object_value: undefined,
  tooltipText: undefined,
};

export default CheckRow;
