import { Schema, get } from '../../../../json-transform';

const optionValue = (option) => option?.value;
export const create = new Schema({
  name: get('name').asString().ifEmptyString(undefined),
  input: {
    url: get('input.url').asString().ifEmptyString(undefined),
    transport: get('input.transport').as(optionValue),
    roi: get('input.roi').asArrayFromString().asArrayOfInt().ifEmpty(undefined),
    droi: get('input.droi').asArrayFromString().asArrayOfInt().ifEmpty(undefined),
    rotation: get('input.rotation').as(optionValue),
    'frame-processing-mode': get('input.frameProcessingMode', 'auto').as(optionValue),
    numberOfFfmpegThreads: get('input.numberOfFfmpegThreads').asInt().ifNaN(undefined),
  },
  output: {
    url: get('output.url').as(optionValue),
    'luna-account-id': get('output.lunaAccountId').asString().ifEmptyString(undefined),
    image_store_url: get('output.imageStoreUrl').asString().ifEmptyString(undefined),
    login: get('output.login').asString().ifEmptyString(undefined),
    password: get('output.password').asString().ifEmptyString(undefined),
    token: get('output.token').asString().ifEmptyString(undefined),
  },
  health_check: {
    max_error_count: get('healthcheck.maxErrorCount').asInt().ifNaN(undefined),
    period: get('healthcheck.period').asInt().ifNaN(undefined),
    retry_delay: get('healthcheck.retryDelay').asInt().ifNaN(undefined),
  },
  filtering: {
    'min-score': get('filtering.minScore').asFloat().ifNaN(undefined),
    'detection-yaw-threshold': get('filtering.detectionYawThreshold').asInt().ifNaN(undefined),
    'detection-pitch-threshold': get('filtering.detectionPitchThreshold').asInt().ifNaN(undefined),
    'detection-roll-threshold': get('filtering.detectionRollThreshold').asInt().ifNaN(undefined),
    'yaw-number': get('filtering.yawNumber').asInt().ifNaN(undefined),
    'yaw-collection-mode': get('filtering.yawCollectionMode').asBoolean(),
    'mouth-occlusion-threshold': get('filtering.mouthOcclusionThreshold').asFloat().ifNaN(undefined),
  },
  sending: {
    'time-period-of-searching': get('sending.timePeriodOfSearching').asInt().ifNaN(undefined),
    'silent-period': get('sending.silentPeriod').asInt().ifNaN(undefined),
    type: get('sending.type').as(optionValue),
    'number-of-bestshots-to-send': get('sending.numberOfBestshotsToSend').asInt().ifNaN(undefined),
  },
  primary_track_policy: {
    use_primary_track_policy: get('primaryTrackPolicy.usePrimaryTrackPolicy').asBoolean(),
    best_shot_min_size: get('primaryTrackPolicy.bestShotMinSize').asInt().ifNaN(undefined),
    best_shot_proper_size: get('primaryTrackPolicy.bestShotProperSize').asInt().ifNaN(undefined),
  },
  liveness: {
    use_shoulders_liveness_filtration: get('liveness.useShouldersLivenessFiltration').asBoolean(),
    use_mask_liveness_filtration: get('liveness.useMaskLivenessFiltration').asBoolean(),
    use_flying_faces_liveness_filtration: get('liveness.useFlyingFacesLivenessFiltration').asBoolean(),
    liveness_mode: get('liveness.livenessMode').as(optionValue),
    number_of_liveness_checks: get('liveness.numberOfLivenessChecks').asInt().ifNaN(undefined),
    liveness_threshold: get('liveness.livenessThreshold').asFloat().ifNaN(undefined),
    livenesses_weights: get('liveness.livenessesWeights').asArrayOfFloat().ifEmpty(undefined),
    mask_backgrounds_count: get('liveness.maskBackgroundsCount').asInt().ifNaN(undefined),
  },
});
