import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import { NotificationBubble } from '../../notification-bubble/NotificationBubble';

import './NavbarLink.sass';

const NavbarLink = ({
  children,
  icon,
  counter,
  className,
  ...restProps
}) => {
  let bubble = null;

  if (counter !== undefined) {
    if (typeof counter === 'number') {
      bubble = <NotificationBubble count={counter} />;
    } else {
      bubble = counter;
    }
  }

  return (
    <NavLink
      {...restProps}
      activeClassName="NavbarLink_active"
      className={cn('NavbarLink', 'InteractionWrapper', className)}
    >
      {icon && (
        <span className="NavbarLink__Icon">
          {icon}
        </span>
      )}
      {children}
      {bubble}
      <div className="InteractionOverlay" />
    </NavLink>
  );
};

NavbarLink.propTypes = {
  icon: PropTypes.node,
  children: PropTypes.node,
  counter: PropTypes.number,
  className: PropTypes.string,
};

NavbarLink.defaultProps = {
  icon: undefined,
  children: undefined,
  counter: undefined,
  className: undefined,
};

export { NavbarLink };
