import i18n from 'app/translations/i18n';

export const filterColumnsValues = (columnsArr) => {
  const columnsObj = {};

  columnsArr.forEach((key) => {
    columnsObj[key] = true;
  });

  return columnsObj;
};

export const filterColumnsTree = (colArr, type) => {
  const tree = [{
    label: i18n.t('tasks:exportForm.tree.all'),
    value: 'all',
    children: [],
  }];

  const eventData = ['event_id', 'create_time', 'end_time', 'source', 'handler_id', 'tags', 'meta'];
  const faceData = ['face_id', 'face_detections', 'external_id', 'attach_result', 'user_data', 'age', 'gender', 'emotion', 'liveness', 'mask'];
  const bodyData = ['body_detections', 'track_id', 'upper_body', 'body_basic_attributes', 'accessories', 'lower_body'];
  const matchData = ['match_result', 'top_match'];
  const geoposition = ['district', 'area', 'city', 'street', 'house_number', 'geo_position'];

  if (type === 'faces') {
    colArr.forEach((key) => {
      tree[0].children.push({
        label: i18n.t(`tasks:exportForm.checkboxGroup.faces.${key}`),
        value: key,
      });
    });
  } else if (type === 'events') {
    tree[0].children.push({
      label: i18n.t('tasks:exportForm.tree.event_data'),
      value: 'event_data',
      children: eventData.map((value) => ({
        label: i18n.t(`tasks:exportForm.checkboxGroup.${type}.${value}.label`),
        value,
      }),
      ),
    },
    {
      label: i18n.t('tasks:exportForm.tree.face_data'),
      value: 'face_data',
      children: faceData.map((value) => ({
        label: i18n.t(`tasks:exportForm.checkboxGroup.${type}.${value}.label`),
        value,
      }),
      ),
    },
    {
      label: i18n.t('tasks:exportForm.tree.body_data'),
      value: 'body_data',
      children: bodyData.map((value) => ({
        label: i18n.t(`tasks:exportForm.checkboxGroup.${type}.${value}.label`),
        value,
      }),
      ),
    },
    {
      label: i18n.t('tasks:exportForm.tree.match_data'),
      value: 'match_data',
      children: matchData.map((value) => ({
        label: i18n.t(`tasks:exportForm.checkboxGroup.${type}.${value}.label`),
        value,
      }),
      ),
    },
    {
      label: i18n.t('tasks:exportForm.tree.geoposition'),
      value: 'geoposition',
      children: geoposition.map((value) => ({
        label: i18n.t(`tasks:exportForm.checkboxGroup.${type}.${value}.label`),
        value,
      }),
      ),
    });
  }
  return tree;
};

export const filterColumnsKeyMap = (colArr, type) => {
  const columnsObj = {};
  if (type === 'faces') {
    colArr.forEach((key) => {
      columnsObj[key] = i18n.t(`tasks:exportForm.checkboxGroup.faces.${key}`);
    });
  } else if (type === 'events') {
    colArr.forEach((key) => {
      columnsObj[key] = i18n.t(`tasks:exportForm.checkboxGroup.events.${key}.label`);
    });
  }
  return columnsObj;
};
