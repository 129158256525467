import { get, Schema } from '../../../json-transform';

export const createResp = (lunaClient) => new Schema({
  object_id: get('object_id'),
  url: get('url'),
  external_url: get('external_url').as((externalUrl) => {
    if (externalUrl === undefined) {
      return `${window.location.origin}${lunaClient.baseURL}${get('url')}`;
    }
    return externalUrl;
  }),
});
