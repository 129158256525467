import React from 'react';

import { CircleInfoIcon } from '@vlabs/icons';
import { selectIsAppFeatureAvailable } from '@vlabs/shared/selectors/appSelectors';
import { Control, SettingsItemWrapper, Fold, Tooltip } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import st from './FaceQualitySubForm.module.sass';

const PeopleCountSubFormComponent = ({
  isPeopleCountLicenseAvailable,
}) => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  const peopleCountTitle = (
    <div className={st.FoldTitle}>
      <h5>{t('handlers:policies.detect_policy.estimate_people_count.estimate')}</h5>
      {!isPeopleCountLicenseAvailable && (
        <Tooltip
          className="Tooltip_ui"
          data-tooltip-content={t('handlers:policies.detect_policy.tooltip.people count license is required')}
          tooltipProps={{ effect: 'solid' }}
        >
          <CircleInfoIcon />
        </Tooltip>
      )}
    </div>
  );
  return (
    <Fold
      control={control}
      disabled={!isPeopleCountLicenseAvailable}
      isOpen={useWatch({ name: 'estimate_people_count.estimate' })}
      name="estimate_people_count.estimate"
      switch
      testId="estimate_people_count"
      title={peopleCountTitle}
    >
      <SettingsItemWrapper
        title={t('handlers:policies.detect_policy.estimate_people_count.people_coordinates')}
      >
        <Control.Switch
          control={control}
          name="estimate_people_count.people_coordinates"
        />
      </SettingsItemWrapper>
    </Fold>
  );
};

PeopleCountSubFormComponent.propTypes = {
  isPeopleCountLicenseAvailable: PropTypes.bool,
};
PeopleCountSubFormComponent.defaultProps = {
  isPeopleCountLicenseAvailable: false,
};

export const PeopleCountSubForm = connect((state) => ({
  isPeopleCountLicenseAvailable: selectIsAppFeatureAvailable(state, 'estimations.people_counter'),
}))(PeopleCountSubFormComponent);
