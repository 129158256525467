import React, { useEffect, useState } from 'react';

import { apiContainer } from '@vlabs/api-bindings';
import { CircleInfoIcon } from '@vlabs/icons';
import DownloadButton from '@vlabs/shared/legacy-components/download-button/DownloadButton';
import { getEventById } from '@vlabs/shared/requests';
import { downloadJson } from '@vlabs/shared/utils';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import st from './tabs.module.sass';

function LoadableImage({ src, alt, ...props }) {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);

  const handleLoad = () => setLoaded(true);
  const handleError = () => setError(true);

  if (error) return null;

  return (
    <img
      alt={alt}
      className={st.PassportPhoto}
      onError={handleError}
      onLoad={handleLoad}
      src={src}
      style={{ display: loaded ? 'block' : 'none' }}
      {...props}
    />
  );
}

export function CreatedFromEventTab({ eventId }) {
  const { t } = useTranslation();
  const [event, setEvent] = useState(undefined);

  useEffect(() => {
    return () => setEvent(undefined);
  }, []);

  useEffect(() => {
    if (!eventId) return;
    getEventById(eventId).then((evt) => {
      setEvent(evt[eventId]);
    });
  }, [eventId]);

  if (!event || !event.meta) {
    return (
      <div className={st.InfoRoot}>
        <CircleInfoIcon className="StaticIcon" />
        {t('faces:created from event.event does not contain meta')}
      </div>
    );
  }

  if (event.meta.schema_name !== 'mali') {
    return (
      <div className={st.InfoRoot}>
        {t('faces:created from event.download meta')}
        <DownloadButton className={st.DownloadButton} onClick={() => downloadJson(`${eventId}_meta`, event.meta)} />
      </div>
    );
  }

  const { passport_data, passport_saved_images } = event.meta;

  return (
    <div className={st.EventData}>
      <div className={st.Photo}>
        <h4>{t('faces:created from event.passport.photos')}</h4>
        {passport_saved_images.map((imgObj) => (
          <LoadableImage
            alt={`passport_${imgObj.image_id}`}
            key={imgObj.image_id}
            src={apiContainer.lunaClient.images.getURL(imgObj.url)}
          />
        ))}
      </div>
      <div className={st.Data}>
        <h4>{t('faces:created from event.passport.data')}</h4>
        {Object.entries(passport_data).map(([key, { title, value }]) => (
          key && (
            <div className={st.DataEntry} key={title}>
              <span className={st.DataKey}>
                {t(`faces:created from event.meta.${title}`, { defaultValue: title })}
              </span>
              <span className={st.DataValue}>
                {value}
              </span>
            </div>
          )
        ))}
      </div>
    </div>
  );
}

CreatedFromEventTab.propTypes = {
  eventId: PropTypes.string,
};

CreatedFromEventTab.defaultProps = {
  eventId: undefined,
};
