import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import en from './en';
import ru from './ru';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'htmlTag', 'navigator'],
    },
    resources: { en, ru },
    ns: ['shared', 'handlers', 'списки'],
    defaultNS: 'shared',
    fallbackNS: ['shared'],
    debug: false,
    nsSeparator: ':',
  });

export default i18n;
