import { get } from 'react-hook-form';

export const DEFAULT_SUPPORTED_IMAGE_TYPES = ['image/jpeg', 'image/png', 'image/tiff'];
export const DEFAULT_MAX_IMAGE_SIZE = 2 * 1024 * 1024; // 2 MB

export const DEFAULT_SUPPORTED_ARCHIVE_TYPES = ['application/x-zip-compressed', 'application/zip'];
export const DEFAULT_MAX_ARCHIVE_SIZE = 1024 * 1024 * 1024; // 1 GB

export const getError = (errors, name) => {
  if (!errors || !name) return undefined;
  return get(errors, name);
};

export const warnLabelWithoutId = (label, name) => {
  // eslint-disable-next-line no-console
  console.debug('UIKIT: в инпутах если есть лейбл, то должен быть и id. Label --', label, '; name --', name);
};

// счетчик нужен чтобы обойти стандартное поведение когда
// dragleave срабатывает при наведении на дочерний компонент
export const fixDragEnterLeaveEvents = ({ onEnter, onLeave }) => {
  let counter = 0;

  return {
    onEnter: (...args) => {
      counter += 1;

      onEnter(...args);
    },
    onLeave: (...args) => {
      counter -= 1;
      if (counter === 0) {
        onLeave(...args);
      }
    },
    resetCounter: () => { counter = 0; },
  };
};
