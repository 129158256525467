import React from 'react';

import { EVENT_ENUMS } from '@vlabs/api-bindings/src/luna-client/constants';
import { backpackImages } from '@vlabs/shared/assets/images';
import { useTranslation, Trans } from 'react-i18next';

import { Card } from './Card';
import st from './Card.module.sass';

const BACKPACK_IMAGES = {
  0: [backpackImages.NoBackpack],
  1: [backpackImages.Backpack],
  2: [backpackImages.Unknown],
};

export const BackpackCard = ({ backpack }) => {
  const { t } = useTranslation();

  const backpackLabel = EVENT_ENUMS.ACCESSORIES.BACKPACK[backpack].label;
  const image = BACKPACK_IMAGES[backpack];

  return (
    <Card
      back={(
        <div className={st.Tooltip}>
          <Trans i18nKey="events:карточка.рюкзак" values={{ type: backpackLabel }} />
        </div>
      )}
      front={(
        <div className={st.Root}>
          <div
            className={st.ImageBlock}
            data-testid="eventAttributesCell.backpack"
          >
            {t('events:backpack')}
            <img alt="backpack" className={st.Image} src={image} />
            {backpackLabel}
          </div>
        </div>
      )}
    />
  );
};
