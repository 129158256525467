import React, { useContext } from 'react';

import { Panel, Control } from '@vlabs/uikit';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { SearchContext } from '../search-context';
import { selectIsTopMatchHiding } from '../selectors';
import { setIsTopMatchHiding } from '../store';
import EventTable from './EventTable';
import FaceTable from './FaceTable';
import './Results.sass';

const Results = ({
  isTopMatchHiding,
  toggleTopMatchVisibility,
}) => {
  const { t } = useTranslation();

  const {
    matchResults,
    detections,
    candidateOrigin,
    matcherType,
  } = useContext(SearchContext);

  const renderCustomTopPanel = (coutnerTitle) => {
    return (
      <div className="Results__TitleWrapper">
        <div className="Results__Title">
          {coutnerTitle}
        </div>
        <Control.Checkbox
          checked={!isTopMatchHiding}
          label={t('search:отображать фото поиска')}
          onClick={toggleTopMatchVisibility}
        />
      </div>
    );
  };

  return (
    <div className={cn('Results__Table_custom')}>
      {matchResults !== undefined && (
        <Panel className="Search__Table_custom">
          {candidateOrigin === 'events' && (
            <EventTable
              CustomTopPanel={() => renderCustomTopPanel(t('search:счетчик.события', { count: matchResults.length }))}
              data={matchResults}
              detections={detections}
              matcherType={matcherType}
            />
          )}

          {candidateOrigin === 'faces' && (
            <FaceTable
              CustomTopPanel={() => renderCustomTopPanel(t('search:счетчик.лица', { count: matchResults.length }))}
              data={matchResults}
              detections={detections}
            />
          )}
        </Panel>
      )}
    </div>
  );
};

const $Results = connect(
  (state) => ({
    isTopMatchHiding: selectIsTopMatchHiding(state),
  }),
  (dispatch) => ({
    toggleTopMatchVisibility: () => dispatch(setIsTopMatchHiding()),
  }),
)(Results);

export { $Results as Results };
