import React from 'react';

import { Delete2Icon, CircleExclamationIcon } from '@vlabs/icons';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { confirmAlert } from 'react-confirm-alert';
import { Translation } from 'react-i18next';

import { Button } from '../controls/button/Button';
import { Divider } from '../divider/Divider';

import './ConfirmPopup.sass';

const ConfirmPopup = ({
  libProps,
  params,
  className,
  ...restProps
}) => {
  const { onClose } = libProps;

  const {
    title,
    message,
    type,
    onConfirm,
    onCancel,
    cancelLabel,
    confirmLabel,
    noIcon,
    icon,
    cancelButtonProps = {},
    confirmButtonProps = {},
  } = params;

  const $icon = type === 'delete' ? <Delete2Icon /> : <CircleExclamationIcon />;

  const $onCancel = () => {
    if (onCancel) onCancel();
    onClose();
  };

  const $onConfirm = () => {
    onConfirm();
    onClose();
  };

  return (
    <Translation>
      {(t) => (
        <div
          className={cn('ConfirmPopup', className)}
          data-testid="confirmPopup"
          {...restProps}
        >
          <div className="ConfirmPopup__Title">
            {!noIcon && (
              <div className={cn({
                ConfirmPopup__Icon: true,
                ConfirmPopup__Icon_delete: type === 'delete',
                ConfirmPopup__Icon_warning: type === 'warning',
              })}
              >
                {icon || $icon}
              </div>
            )}

            <div className="ConfirmPopup__TitleText">
              {title}
            </div>
          </div>

          <div className="ConfirmPopup__Message">
            {message}
          </div>

          <div className="ConfirmPopup__Buttons">
            <Button
              kind="neutral"
              onClick={$onCancel}
              variant="outlined"
              {...cancelButtonProps}
            >
              {cancelLabel || t('uikit:popup.действия.отмена') }
            </Button>

            <Divider small />

            <Button
              kind={type === 'delete' ? 'negative' : 'primary'}
              onClick={$onConfirm}
              {...confirmButtonProps}
            >
              {
                confirmLabel || (
                  type === 'delete'
                    ? t('uikit:popup.действия.удаление')
                    : t('uikit:popup.действия.подтверждение')
                )
              }
            </Button>
          </div>
        </div>
      )}
    </Translation>
  );
};

ConfirmPopup.propTypes = {
  libProps: PropTypes.object.isRequired,
  className: PropTypes.string,
  params: PropTypes.shape({
    title: PropTypes.string,
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    type: PropTypes.oneOf(['delete', 'warning', 'info']),
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    confirmLabel: PropTypes.string,
    cancelLabel: PropTypes.string,
    icon: PropTypes.node,
    noIcon: PropTypes.bool,
    cancelButtonProps: PropTypes.object,
    confirmButtonProps: PropTypes.object,
  }).isRequired,
};

ConfirmPopup.defaultProps = {
  className: undefined,
};

const openConfirmPopup = (params, props = {}) => {
  return confirmAlert({
    customUI: (libProps) => (
      <ConfirmPopup
        libProps={libProps}
        params={params}
        {...props}
      />
    ),
  });
};

export {
  ConfirmPopup,
  openConfirmPopup,
};
