import React, { forwardRef } from 'react';

import PropTypes from 'prop-types';

import { BaseButton } from './base-button/BaseButton';

const Button = forwardRef((props, externalRef) => {
  return (
    <BaseButton
      {...props}
      ref={externalRef}
    />
  );
});

Button.displayName = 'Button';

// Убрать копипасту когад storybook поддержит динамические propTypes
// https://github.com/storybookjs/storybook/issues/14092
Button.propTypes = {
  variant: PropTypes.oneOf([
    'filled',
    'flat',
    'invisible',
    'outlined',
    'dimmed',
  ]),
  kind: PropTypes.oneOf([
    'primary',
    'negative',
    'attention',
    'positive',
    'neutral',
  ]),
  type: PropTypes.oneOf([
    'button',
    'submit',
    'reset',
  ]),
  children: PropTypes.node,
  className: PropTypes.string,
  icon: PropTypes.node,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
};

Button.defaultProps = {
  variant: 'filled',
  kind: 'primary',
  type: 'button',
  children: undefined,
  className: undefined,
  icon: undefined,
  disabled: undefined,
  fullWidth: false,
};

export { Button };
