import React from 'react';

import { PageWithSidebar } from '@vlabs/shared/components/page-with-sidebar';
import { routes } from '@vlabs/shared/navigation/routes';
import { ExporterTaskWidget } from '@vlabs/shared/widgets/tasks';
import { Page, Table, Panel, Control } from '@vlabs/uikit';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';

import { useEventTableColumns } from '../eventTableColumns';
import FilterForm from './components/FilterForm';
import { selectEvents, selectEventsFilters, selectIsTopMatchHiding } from './selectors';
import { updateFilters, updatePageSize, updatePage, setIsTopMatchHiding } from './store';
import './EventListPage.sass';

function EventListPage({
  filters,
  setFilters,
  setPageSize,
  setPageIndex,
  eventsData,
  isTopMatchHiding,
  toggleTopMatchVisibility,
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const formMethods = useForm({ defaultValues: filters });
  const { watch, handleSubmit } = formMethods;
  const filterFormFields = watch();
  const count = Object.values(filterFormFields).filter((value) => value?.length || value?.label?.length).length;

  const onEventCardClick = ({ event_id: eventId }) => {
    const eventLink = generatePath(routes.events.eventDetails, { eventId });
    history.push(eventLink);
  };

  const onTopMatchCardClick = ({ face_id, event_id }) => {
    if (face_id) {
      history.push(generatePath(routes.facePage, { faceId: face_id }));
    }
    if (event_id) {
      history.push(generatePath(routes.events.eventDetails, { eventId: event_id }));
    }
  };

  const renderCustomTopPanel = () => {
    return (
      <Control.Checkbox
        checked={!isTopMatchHiding}
        label={t('events:отображать наибольшее совпадение')}
        onClick={toggleTopMatchVisibility}
      />
    );
  };

  const columns = useEventTableColumns(onTopMatchCardClick, onEventCardClick, isTopMatchHiding);

  const context = {
    ...formMethods,
    setFilters,
  };

  const extraButtons = () => (
    <ExporterTaskWidget filters={filters} objectType="events" />
  );

  return (
    <FormProvider {...context}>
      <form onSubmit={handleSubmit(setFilters)}>
        <PageWithSidebar
          filterCount={count}
          rigthSidebar={<FilterForm />}
        >
          <Page className="EventsTable__Page">
            <Panel className={cn('TableWrapper', 'Table_custom')}>
              <Table
                columns={columns}
                CustomTopPanel={renderCustomTopPanel}
                data={eventsData.data}
                extraButtons={extraButtons}
                pageIndex={eventsData.pageIndex}
                pageSize={eventsData.pageSize}
                pageSizeOptions={eventsData.pageSizeOptions}
                paginationType="offsetBased"
                rowProps={(row) => ({ 'data-event-id': row.original.event_id })}
                setPageIndex={setPageIndex}
                setPageSize={setPageSize}
              />
            </Panel>
          </Page>
        </PageWithSidebar>
      </form>
    </FormProvider>
  );
}

EventListPage.propTypes = {
  eventsData: PropTypes.objectOf(PropTypes.any),
  filters: PropTypes.objectOf(PropTypes.any),
  setFilters: PropTypes.func,
  setPageSize: PropTypes.func,
  setPageIndex: PropTypes.func,
  isTopMatchHiding: PropTypes.bool,
  toggleTopMatchVisibility: PropTypes.func,
};

EventListPage.defaultProps = {
  eventsData: {},
  filters: {},
  setFilters: undefined,
  setPageSize: undefined,
  setPageIndex: undefined,
  isTopMatchHiding: false,
  toggleTopMatchVisibility: undefined,
};

export default connect(
  (state) => ({
    eventsData: selectEvents(state),
    filters: selectEventsFilters(state),
    isTopMatchHiding: selectIsTopMatchHiding(state),
  }),
  (dispatch) => ({
    setFilters: (filters) => dispatch(updateFilters({ filters })),
    setPageSize: (pageSize) => dispatch(updatePageSize({ pageSize })),
    setPageIndex: (pageIndex) => dispatch(updatePage({ pageIndex })),
    toggleTopMatchVisibility: () => dispatch(setIsTopMatchHiding()),
  }),
)(EventListPage);
