import React, { memo } from 'react';

import { IconInner } from '../../IconInner';

const CircleExclamationIconInner = (props) => (
  <IconInner name="CircleExclamationIcon" viewBox="0 0 24 24" {...props}>
    <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10Zm0-14v4m0 4h.01" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
  </IconInner>
);

export const CircleExclamationIcon = memo(CircleExclamationIconInner);
