import React, { useEffect, useMemo, useState } from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import { TabHeader } from './TabHeader';
import './Tabs.sass';

const Tabs = ({
  className,
  children,
  testId,
  activeTabIndex,
  onTabActivation,
  compactHeaders,
  noHeaders,
}) => {
  const tabs = useMemo(() => React.Children.toArray(children), [children]);
  const initialActiveTab = tabs.findIndex((tab) => !tab.props.disabled);

  const [activeTab, setActiveTab] = useState(initialActiveTab);

  const activateTab = (index, name) => {
    if (tabs[index].props.disabled) return;
    setActiveTab(index);
    if (onTabActivation) onTabActivation(name);
  };

  useEffect(() => {
    if (activeTabIndex !== undefined && activeTabIndex !== activeTab) {
      setActiveTab(activeTabIndex);
    }
  }, [activeTabIndex, tabs, activeTab]);

  useEffect(() => {
    if (onTabActivation && tabs[initialActiveTab] && !tabs[initialActiveTab].props.disabled) {
      onTabActivation(tabs[initialActiveTab].props.name);
    }
  }, []);

  return (
    <div className={cn('Tabs', className)} data-testid={testId}>
      {!noHeaders && (
        <div className={cn('Tabs__Headers', {
          Tabs__Headers_compact: compactHeaders,
        })}
        >
          {tabs.map(({
            props: {
              name,
              header,
              disabled,
              headerClassName,
              title,
            },
          }, index) => (
            <TabHeader
              activeTab={activeTab}
              className={headerClassName}
              disabled={disabled}
              header={header}
              index={index}
              key={`tabHeader-${index + 1}`}
              onTabActivation={() => activateTab(index, name)}
              testId={`tabHeader-${name.replaceAll(' ', '')}-${testId}`}
              title={title}
            />
          ))}
        </div>
      )}

      <div className="Tabs__Bodies">
        {tabs.map((tabItem, index) => (
          <div
            className={cn(
              tabItem.props.bodyClassName,
              {
                Tabs__Body: true,
                Tabs__Body_active: activeTab === index,
              },
            )}
            data-index={index}
            data-testid={`tabContent-${tabItem.props.name.replaceAll(' ', '')}-${testId}`}
            key={`tabContent-${index + 1}`}
          >
            {tabItem}
          </div>
        ))}
      </div>
    </div>
  );
};

Tabs.propTypes = {
  className: PropTypes.any,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  testId: PropTypes.string,
  activeTabIndex: PropTypes.number,
  onTabActivation: PropTypes.func,
  compactHeaders: PropTypes.bool,
  noHeaders: PropTypes.bool,
};
Tabs.defaultProps = {
  className: undefined,
  testId: '',
  activeTabIndex: undefined,
  onTabActivation: undefined,
  compactHeaders: undefined,
  noHeaders: undefined,
};

export { Tabs };
