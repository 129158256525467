import PropTypes from 'prop-types';

export const pageParameters = {
  propTypes: {
    pageParameters: PropTypes.shape({
      pageSize: PropTypes.number,
      pageIndex: PropTypes.number,
      pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
    }),
    setPageIndex: PropTypes.func.isRequired,
    setPageSize: PropTypes.func.isRequired,
  },
  defaultProps: {
    pageParameters: {
      pageSize: 25,
      pageIndex: 0,
      pageSizeOptions: [10, 25, 50],
    },
  },
};

export const pageData = {
  propTypes: {
    pageData: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.object),
      meta: PropTypes.shape({
        count: PropTypes.number,
      }),
    }),
  },
  defaultProps: {
    pageData: undefined,
  },
};

export const optionPropType = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.string,
});
