import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import st from './ImageSelector.module.sass';

const ImageSelector = ({
  id,
  rect,
  type,
  selected,
  onSelect,
}) => {
  const { t } = useTranslation();

  return (
  // eslint-disable-next-line jsx-a11y/interactive-supports-focus
    <div
      aria-label={t('Выбрать')}
      className={cn({
        InteractionWrapper: true,
        [st.Detection]: true,
        [st.Detection_body]: type === 'body',
        [st.Detection_face]: type === 'face',
        [st.Detection_selected]: selected,
      })}
      data-testid="imageSelector.detection"
      label={t('Выбрать')}
      onClick={() => onSelect(id)}
      onKeyPress={() => onSelect(id)}
      role="button"
      style={{
        width: rect?.width || 0,
        height: rect?.height || 0,
        left: rect?.left || 0,
        top: rect?.top || 0,
      }}
    >
      <div className="InteractionOverlay InteractionOverlay_dense" />
    </div>
  );
};

ImageSelector.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  type: PropTypes.string,
  rect: PropTypes.shape({
    left: PropTypes.number.isRequired,
    top: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }),
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.bool,
};

ImageSelector.defaultProps = {
  selected: false,
  rect: undefined,
  type: undefined,
};

export { ImageSelector };
