import { LoginPage } from './login-page/LoginPage';
import { NotFoundPage } from './not-found-page/NotFoundPage';
import { ResetPasswordPage } from './reset-password-page/ResetPasswordPage';
import { UnauthorizedPage } from './unauthorized-page/UnauthorizedPage';

export const Pages = {
  LoginPage,
  ResetPasswordPage,
  NotFoundPage,
  UnauthorizedPage,
};
