import React from 'react';

import { CircleCheckIcon } from '@vlabs/icons';
import { Page, Divider } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import st from './CreatedVerifierForm.module.sass';

export default function CreatedVerifierForm({
  verifierId,
  verifierName,
}) {
  const { t } = useTranslation();

  return (
    <Page className={st.Page}>
      <div className={st.Title}>
        <CircleCheckIcon className={st.Icon} />
        <h5>
          {t('verifiers:createdForm.title', { description: verifierName })}
        </h5>
      </div>

      <Divider small />

      <div className="Body-2">
        <p>
          <Trans
            i18nKey="verifiers:createdForm.text.id"
            values={{ verifierId }}
          />
        </p>

        <p>
          <Trans
            components={{
              a: <Link
                data-testid="linkToHandler"
                to={`/verifiers/${verifierId}/test`}
              />,
            }}
            i18nKey="verifiers:createdForm.text.testing verifier"
          />
        </p>

        <p>
          <Trans
            components={{
              a: <Link
                data-testid="linkToHandler"
                to={`/verifiers/${verifierId}`}
              />,
            }}
            i18nKey="verifiers:createdForm.text.update verifier"
          />
        </p>
      </div>
    </Page>
  );
}

CreatedVerifierForm.propTypes = {
  verifierId: PropTypes.string,
  verifierName: PropTypes.string,
};

CreatedVerifierForm.defaultProps = {
  verifierId: undefined,
  verifierName: undefined,
};
