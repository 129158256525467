import React, { memo } from 'react';

import { IconInner } from '../../IconInner';

const NotificationIconInner = (props) => (
  <IconInner name="NotificationIcon" viewBox="0 0 24 24" {...props}>
    <path d="M18 8A6 6 0 1 0 6 8c0 7-3 9-3 9h18s-3-2-3-9Zm-4.27 13a1.999 1.999 0 0 1-3.46 0" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
  </IconInner>
);

export const NotificationIcon = memo(NotificationIconInner);
