import React, { memo } from 'react';

import { IconInner } from '../../IconInner';

const FilterIconInner = (props) => (
  <IconInner name="FilterIcon" viewBox="0 0 24 24" {...props}>
    <path d="M22 3H2l8 9.46V19l4 2v-8.54L22 3Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
  </IconInner>
);

export const FilterIcon = memo(FilterIconInner);
