import { createSelector } from '@reduxjs/toolkit';

import { SLICE_NAME } from '.';

export const selectSlice = ({ [SLICE_NAME]: slice }) => slice;

export const selectOptions = createSelector(
  selectSlice,
  ({ planOptions, lunaSourcesOptions }) => (
    { planOptions, lunaSourcesOptions }
  ),
);
