import { Content } from './Content';
import { Footer } from './footer/Footer';
import { Layout } from './Layout';
import { NavbarLink } from './navbar-link/NavbarLink';
import { Navbar } from './navbar/Navbar';

const $Layout = {
  Base: Layout,
  Content,
  Navbar,
  NavbarLink,
  Footer,
};

export {
  $Layout as Layout,
};
