import React from 'react';

import {
  Page, Control, Divider,
} from '@vlabs/uikit';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import './CreateSourcePage.sass';

function CreateSourcePage({
  onSubmit,
  defaultValues,
  lunaSourcesOptions,
}) {
  const {
    handleSubmit, register, control, formState: { errors },
  } = useForm({ defaultValues });
  const { t } = useTranslation();

  const title = defaultValues ? t('sources:обновить источник') : t('sources:создание источника');

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Page className="CreateSourcePage__Root" title={title}>
        <Divider />

        <label htmlFor="source">
          {t('sources:source name')}
          <Control.Select
            control={control}
            id="source_name"
            isClearable
            name="source_name"
            options={lunaSourcesOptions}
          />
        </label>
        <Divider small />

        <label htmlFor="role">
          {t('sources:source')}
          <Control.FileInput
            {...register('image', {
            })}
            errors={errors}
          />
        </label>

        <Divider small />

        <Control.Button fullWidth type="submit">
          {t('форма.submit')}
        </Control.Button>
      </Page>
    </form>
  );
}

export default CreateSourcePage;
