import React from 'react';

import PropTypes from 'prop-types';

import './Layout.sass';

const Layout = ({
  children,
  ...restProps
}) => {
  return (
    <div className="App" {...restProps}>
      {children}
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
};

Layout.defaultProps = {
  children: undefined,
};

export { Layout };
