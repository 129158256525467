import { createSlice } from '@reduxjs/toolkit';
import { apiContainer } from '@vlabs/api-bindings';
import i18n from 'i18next';
import { toast } from 'react-toastify';

const initialState = {
  state: undefined,
  departmentOptions: [],
  data: [],
  meta: {},
  pageData: [],
  filters: [],
  byId: {},
  pageIndex: 0,
  pageSize: 25,
  pageSizeOptions: [10, 25, 50, 100],
};

const store = createSlice({
  name: 'departments',
  initialState,
  reducers: {
    setDepartments(state, { payload }) {
      state.data = payload;
      state.departmentOptions = payload.map(({ departmentId, name }) => ({
        value: departmentId,
        label: name,
      }));
      payload.forEach((department) => {
        state.byId[department.departmentId] = department;
      });
      state.state = 'loaded';
    },
    setDepartmentsPage(state, { payload }) {
      state.pageData = payload;
    },
    setFilters(state, { payload: { filters } = {} }) {
      if (filters !== undefined) state.filters = filters;
    },
    setPage(state, { payload: { pageIndex } = {} }) {
      if (pageIndex !== undefined) state.pageIndex = pageIndex;
    },
    setPageSize(state, { payload: { pageSize } = {} }) {
      if (pageSize !== undefined) state.pageSize = pageSize;
    },
    resetStore() {
      return initialState;
    },
  },
});

export default store.reducer;

export const {
  setDepartments, setDepartmentsPage, setDepartmentsOptions,
  setFilters, setPageSize, setPage, resetStore,
} = store.actions;

export const fetchDepartments = async (dispatch) => {
  const data = await apiContainer.clementineDepartmentsClient.departments.getAll();
  dispatch(setDepartments(data.data));
};

export const fetchDepartmentsPage = async (dispatch, getState) => {
  const {
    departments: { pageSize, pageIndex, filters },
  } = getState();

  const flatFilters = [];
  filters.forEach(({ id, value }) => {
    flatFilters[id] = value;
  });
  const { data } = await apiContainer.clementineDepartmentsClient.departments
    .getPage({ page: pageIndex + 1, pageSize, ...flatFilters });
  dispatch(setDepartmentsPage(data));
};

export const getDepartment = async (id) => apiContainer.clementineDepartmentsClient.departments.get(id);

export const createDepartment = (data) => async (dispatch) => {
  await apiContainer.clementineDepartmentsClient.departments.create(data);
  toast.success(i18n.t('departments:api.создание.успех'));
  dispatch(fetchDepartments);
  dispatch(fetchDepartmentsPage);
};

export const updateDepartment = (id, data) => async (dispatch) => {
  await apiContainer.clementineDepartmentsClient.departments.update(id, data);
  toast.success(i18n.t('departments:api.обновление.успех'));
  dispatch(fetchDepartments);
  dispatch(fetchDepartmentsPage);
};

export const deleteDepartment = (id) => async (dispatch) => {
  await apiContainer.clementineDepartmentsClient.departments.delete(id);
  toast.success(i18n.t('departments:api.удаление.успех'));
  dispatch(fetchDepartments);
  dispatch(fetchDepartmentsPage);
};

export const removeCamera = async (id, camId) => {
  return apiContainer.clementineDepartmentsClient.departments.removeCamera(id, camId);
};

export const removeAccount = async (id, accId) => {
  return apiContainer.clementineDepartmentsClient.departments.removeAccount(id, accId);
};

export const addCamera = async (id, camId) => {
  return apiContainer.clementineDepartmentsClient.departments.addCamera(id, camId);
};

export const addAccount = async (id, accId) => {
  return apiContainer.clementineDepartmentsClient.departments.addAccount(id, accId);
};

export const updateFilters = (params) => async (dispatch) => {
  dispatch(setFilters(params));
  dispatch(fetchDepartmentsPage);
};

export const updatePageSize = (params) => async (dispatch) => {
  dispatch(setPageSize(params));
  dispatch(fetchDepartmentsPage);
};
export const updatePage = (params) => async (dispatch) => {
  dispatch(setPage(params));
  dispatch(fetchDepartmentsPage);
};

export const resetDepartments = (dispatch) => dispatch(resetStore());
