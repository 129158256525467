import { createSelector } from '@reduxjs/toolkit';

import { mapMatchResults } from './helpers';

export const selectSearchState = (state) => state.matcherSearch;

export const selectReferences = createSelector(
  selectSearchState,
  ({ references }) => references,
);

export const selectReferenceType = createSelector(
  selectSearchState,
  ({ referenceType }) => referenceType,
);

export const selectCandidateOrigin = createSelector(
  selectSearchState,
  ({ candidateOrigin }) => candidateOrigin,
);

export const selectReferencesPreviewPage = createSelector(
  selectSearchState,
  ({ referencesPreview, pageSize, pageIndex }) => {
    const trimStart = pageIndex * pageSize;
    const trimEnd = trimStart + pageSize;
    return { data: referencesPreview?.slice(trimStart, trimEnd), pageIndex, count: referencesPreview?.length };
  },
);

export const selectMatcherResults = createSelector(
  selectSearchState,
  ({ data, referencesPreview }) => mapMatchResults(data, referencesPreview),
);

export const selectIsTopMatchHiding = createSelector(
  selectSearchState,
  ({ isTopMatchHiding }) => isTopMatchHiding,
);
