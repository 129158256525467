import WSClient from '../WSClient';
import { wsToEvent, eventToEvent } from './events/mappers/index';

export class LunaWSClient extends WSClient {
  constructor(baseURL, lunaClient) {
    super(baseURL);
    this.lunaClient = lunaClient;
    this.eventToEventSchema = eventToEvent(this.lunaClient);
    this.messageMapper = (message) => this.eventToEventSchema(wsToEvent(message));
  }

  set onmessage(func) {
    this.socket.onmessage = (message) => func(this.messageMapper(JSON.parse(message.data)));
  }
}
