import React, { useCallback } from 'react';

import { Fold } from '@vlabs/uikit';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useClearPolicyFilters } from '../hooks';
import GenericPolicySubForm from './GenericPolicySubForm';

const EventPolicySubform = () => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const eventPolicyIsEnabled = useWatch({ name: 'eventPolicy.isEnabled' });

  useClearPolicyFilters('eventPolicy', {
    on: useCallback(() => eventPolicyIsEnabled === false, [eventPolicyIsEnabled]),
  });

  return (
    <Fold
      control={control}
      isOpen={eventPolicyIsEnabled}
      name="eventPolicy.isEnabled"
      switch
      testId="eventPolicy"
      title={t('handlers:setup sections.save event in database')}
    >
      <GenericPolicySubForm
        fieldName="eventPolicy.filters"
        matchTitle={t('handlers:policies.storage_policy.event_policy.match title')}
      />
    </Fold>
  );
};

export default EventPolicySubform;
