import React, { useMemo, useState, useCallback, useEffect } from 'react';

import { apiContainer } from '@vlabs/api-bindings';
import { CircleInfoIcon } from '@vlabs/icons';
import { BaseSample } from '@vlabs/shared/components/sample/BaseSample';
import { GoToCell } from '@vlabs/shared/components/table';
import FaceInfoCell from '@vlabs/shared/legacy-components/face-info-cell/FaceInfoCell';
import { routes } from '@vlabs/shared/navigation/routes';
import { Table, Control } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory, useParams } from 'react-router-dom';

import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_INDEX, PAGE_SIZE_FOR_REQUEST } from '../constants';
import { trimByPage } from '../utils';
import st from './tabs.module.sass';

const Info = ({ title }) => (
  <div className={st.InfoRoot}>
    <CircleInfoIcon className="StaticIcon" />
    <div className={st.Title}>{title}</div>
  </div>
);

const FaceSampleCell = React.memo(({ value }) => <BaseSample className={st.Image} data-testid="sampleImage" imageUrl={value.uiAvatarURL} type="face" />);

export const FacesByExternalIdTab = ({ externalId }) => {
  const { faceId } = useParams();
  const { t } = useTranslation();
  const history = useHistory();
  const [faces, setFaces] = useState({ data: null, count: null, facesByPage: null });

  const fetchFaces = useCallback(async () => {
    const { data } = await apiContainer.lunaClient.faces.getAll({
      external_ids: externalId,
      page: DEFAULT_PAGE_INDEX + 1,
      page_size: PAGE_SIZE_FOR_REQUEST,
    });
    if (!faceId) return;

    const filteredFaces = data?.filter(({ face_id }) => face_id !== faceId);
    setFaces({
      data: filteredFaces,
      count: filteredFaces?.length,
      facesByPage: trimByPage(DEFAULT_PAGE_INDEX, filteredFaces),
    });
  }, [externalId, faceId]);

  useEffect(() => {
    if (!externalId) return;
    fetchFaces();
  }, [fetchFaces, externalId]);

  const onChangePageIndex = useCallback((pageIndex) => {
    setFaces((prevState) => ({
      ...prevState,
      facesByPage: trimByPage(pageIndex, prevState.data),
    }));
  }, []);

  const columns = useMemo(() => [
    {
      id: 1,
      accessor: (value) => value,
      Cell: FaceSampleCell,
      width: 70,
    },
    {
      id: 2,
      Cell: FaceInfoCell,
      width: 170,
    },
    {
      id: 3,
      Cell: GoToCell,
      accessor: ({ face_id }) => (face_id ? `/faces/${face_id}` : undefined),
      width: 10,
    },
  ], []);

  if (!externalId) {
    return <Info title={t('faces:внешний идентификатор отсутствует')} />;
  }

  return (
    <div className={st.TablePanel}>
      {faces.count === 0 && externalId && (
        <Info title={t('faces:лица с тем же внешним id отсутствуют')} />
      )}
      {faces.count > 0 && (
        <>
          <Table
            columns={columns}
            data={faces?.facesByPage}
            data-testid="faceTableTab"
            meta={{ count: faces.count }}
            noHead
            pageIndex={DEFAULT_PAGE_INDEX}
            pageSize={DEFAULT_PAGE_SIZE}
            pageSizeOptions={[DEFAULT_PAGE_SIZE]}
            paginationType="offsetBased"
            setPageIndex={onChangePageIndex}
          />
          <div className={st.GoTo}>
            <Control.Button
              data-testid="faceTableButton"
              onClick={() => history.push(generatePath(routes.externalId.read, { externalId }))}
              variant="outlined"
            >
              {t('faces:посмотреть все лица с тем же внешним id')}
            </Control.Button>
          </div>

        </>
      )}
    </div>
  );
};

FacesByExternalIdTab.propTypes = {
  externalId: PropTypes.string,
};

FacesByExternalIdTab.defaultProps = {
  externalId: undefined,
};
