import React from 'react';

import { Layout, Tooltip } from '@vlabs/uikit';
import cn from 'classnames';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import './NavbarLinkWithPermissions.sass';
import { connect } from 'react-redux';

import { viewerCan } from '@vlabs/pages/auth/selectors';

const NavbarLinkWithPermissionsComponent = ({
  permissions,
  can,
  icon,
  children,
  ...props
}) => {
  const { rules = [], method = 'oneof' } = permissions;

  const hasPermission = can(rules, { method });

  return (
    <Tooltip
      className="NavbarLink__Tooltip"
      data-tooltip-content={!hasPermission ? i18n.t('кнопка.недостаточно прав.title') : null}
    >
      <Layout.NavbarLink
        className={cn({
          NavbarLink_disabled: !hasPermission,
        })}
        icon={icon}
        {...props}
      >
        {children}
      </Layout.NavbarLink>
    </Tooltip>
  );
};

NavbarLinkWithPermissionsComponent.propTypes = {
  permissions: PropTypes.shape({
    rules: PropTypes.arrayOf(PropTypes.string).isRequired,
    method: PropTypes.oneOf(['oneof', 'allof']),
  }),
  icon: PropTypes.node,
  children: PropTypes.node,
  can: PropTypes.func.isRequired,
};

NavbarLinkWithPermissionsComponent.defaultProps = {
  permissions: {},
  icon: undefined,
  children: undefined,
};

export const NavbarLinkWithPermissions = connect((state) => ({
  can: viewerCan(state),
}))(NavbarLinkWithPermissionsComponent);
