import {
  GENDER_OPTIONS,
  ETHNIC_OPTIONS,
  EMOTION_OPTIONS,
  MASK_OPTIONS,
  AGE_OPTIONS,
  TEMPERATURE_TAG_OPTIONS,
} from '../../constants';

export const fromApi = (data) => {
  const { filters, email, telegram, ui } = data;
  const emailTargets = {};
  const telegramTargets = {};
  if (email?.targets) {
    email.targets.forEach((target) => { emailTargets[target] = true; });
  }
  if (telegram?.targets) {
    telegram.targets.forEach((target) => { telegramTargets[target] = true; });
  }
  return {
    ui: {
      enable: ui?.enable,
    },
    email: {
      enable: email?.enable,
      recipients: email?.recipients?.join(', '),
      targets: emailTargets,
    },
    telegram: {
      enable: telegram?.enable,
      recipients: telegram?.recipients?.join(', '),
      targets: telegramTargets,
    },
    filters: {
      ...filters,
      age: filters?.age__gte
        ? AGE_OPTIONS.raw.find(({ value }) => filters?.age__gte === value[0])
        : null,
      gender: filters?.gender === 0 || filters?.gender === 1
        ? GENDER_OPTIONS.raw.find(({ value }) => filters?.gender === value)
        : null,
      sources: filters?.sources
        ? filters?.sources.map((i) => ({ value: i, label: i }))
        : null,
      emotions: filters?.emotions
        ? EMOTION_OPTIONS.raw.filter(({ value }) => filters.emotions.some((i) => i === value))
        : null,
      masks: filters?.masks
        ? MASK_OPTIONS.raw.filter(({ value }) => filters.masks.some((i) => i === value))
        : null,
      ethnic_groups: filters?.ethnic_groups
        ? ETHNIC_OPTIONS.raw.filter(({ value }) => filters.ethnic_groups.some((i) => i === value))
        : null,
      tags: filters?.tags
        ? filters?.tags.map(
          (tag) => TEMPERATURE_TAG_OPTIONS.raw.find(
            ({ value }) => tag === value,
          ) ?? { value: tag, label: tag },
        )
        : undefined,
    },
  };
};
