import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import './FieldWrapper.sass';

export const FieldWrapper = ({
  children,
  className,
}) => {
  return (
    <div
      className={cn(
        'FieldWrapper',
        'Subtitle-2',
        className,
      )}
    >
      {children}
    </div>
  );
};

FieldWrapper.propTypes = {
  children: PropTypes.node,
  className: PropTypes.any,
};
FieldWrapper.defaultProps = {
  children: undefined,
  className: undefined,
};
