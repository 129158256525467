import React, { memo } from 'react';

import { IconInner } from '../../IconInner';

const ListIconInner = (props) => (
  <IconInner name="ListIcon" viewBox="0 0 24 24" {...props}>
    <path d="M8 6h13M8 12h13M8 18h13M3 6h.01M3 12h.01M3 18h.01" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
  </IconInner>
);

export const ListIcon = memo(ListIconInner);
