import React, { memo } from 'react';

import { IconInner } from '../../IconInner';

const CircleInfoIconInner = (props) => (
  <IconInner name="CircleInfoIcon" viewBox="0 0 24 24" {...props}>
    <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10Zm0-6v-4m0-4h.01" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
  </IconInner>
);

export const CircleInfoIcon = memo(CircleInfoIconInner);
