import React, { memo } from 'react';

import { IconInner } from '../../IconInner';

const SunIconInner = (props) => (
  <IconInner name="SunIcon" viewBox="0 0 24 24" {...props}>
    <g clipPath="url(#a)"><path d="M12 17a5 5 0 1 0 0-10 5 5 0 0 0 0 10Zm0-16v2m0 18v2M4.22 4.22l1.42 1.42m12.72 12.72 1.42 1.42M1 12h2m18 0h2M4.22 19.78l1.42-1.42M18.36 5.64l1.42-1.42" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" /></g>
  </IconInner>
);

export const SunIcon = memo(SunIconInner);
