import React, { useCallback } from 'react';

import { Control, Fold, SettingsItemWrapper } from '@vlabs/uikit';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useClearPolicyFilters } from '../hooks';
import GenericPolicySubForm from './GenericPolicySubForm';
import { TTLSelectField } from './TTLSelectField/TTLSelectField';

const ImageOriginPolicySubForm = () => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const imageOriginPolicyIsEnabled = useWatch({ name: 'imageOriginPolicy.isEnabled' });

  useClearPolicyFilters('imageOriginPolicy', {
    on: useCallback(() => imageOriginPolicyIsEnabled === false, [imageOriginPolicyIsEnabled]),
  });

  return (
    <Fold
      control={control}
      isOpen={imageOriginPolicyIsEnabled}
      name="imageOriginPolicy.isEnabled"
      switch
      testId="imageOriginPolicy"
      title={t('handlers:setup sections.save original image in database')}
    >
      <SettingsItemWrapper
        title={t('handlers:policies.imageOrigin.useExternalReference')}
      >
        <Control.Switch
          control={control}
          name="imageOriginPolicy.useExternalReference"
        />
      </SettingsItemWrapper>

      <GenericPolicySubForm
        fieldName="imageOriginPolicy.filters"
        matchTitle={t('handlers:policies.storage_policy.image_origin_policy.match title')}
      >
        <TTLSelectField fieldName="imageOriginPolicy" />
      </GenericPolicySubForm>
    </Fold>
  );
};

export default ImageOriginPolicySubForm;
