import { Schema, get } from '../../../json-transform';

export const getCommonFasesFiltersQS = new Schema({
  account_id: get('account_id').ifEmptyString(undefined),
  create_time__lt: get('create_time__lt').switch((time) => Array.isArray(time), {
    true: (time) => time[0],
    default: (v) => v,
  }).ifNull(undefined).asISOString(),
  create_time__gte: get('create_time__gte').switch((time) => Array.isArray(time), {
    true: (time) => time[0],
    default: (v) => v,
  }).ifNull(undefined).asISOString(),
  // FIXME: проверить face_ids: get('face_ids').asString().ifEmptyString(undefined) => была в darkside
  face_ids: get('face_ids').asArrayFromString().ifEmpty(undefined),
  face_id__lt: get('face_id__lt').asString().ifEmptyString(undefined),
  face_id__gte: get('face_id__gte').asString().ifEmptyString(undefined),
  event_id: get('event_id').asString().ifEmptyString(undefined),
  external_ids: get('external_ids').asString().ifEmptyString(undefined),
  user_data: get('user_data').asString().ifEmptyString(undefined),
  list_id: get('list_id').switch((value) => value instanceof Object, {
    true: (value) => value.value,
    default: (v) => v,
  }).ifNull(undefined).ifEmptyString(undefined),
});

export const getFasesFiltersQS = new Schema({
  page: get('page').asInt(),
  page_size: get('page_size').asInt(),
  targets: get('targets').asArrayFromString().ifEmpty(undefined),
});

export const getFacesQS = (obj) => {
  return {
    ...getCommonFasesFiltersQS(obj),
    ...getFasesFiltersQS(obj),
  };
};
