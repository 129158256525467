/* eslint-disable camelcase */
import React, { useEffect } from 'react';

import { optionPropType } from '@vlabs/shared';
import SelectOptionGroupLabel from '@vlabs/shared/legacy-components/select-option-group-label/SelectOptionGroupLabel';
import { selectAppServices } from '@vlabs/shared/selectors/appSelectors';
import validate from '@vlabs/shared/validators';
import {
  Control,
  Grid,
  GridCol,
  FieldWrapper,
  GridRow,
  Fold,
  Divider,
  Page,
  SettingsItemWrapper,
} from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import './NotificationForm.sass';

function NotificationForm({
  data,
  notificationId,
  camOptions,
  handlerOptions,
  listOptions,
  genderOptions,
  emotionOptions,
  maskOptions,
  tagsOptions,
  ageOptions,
  onSubmit,
}) {
  const { t } = useTranslation();

  const $data = {
    ...data,
    filters: {
      ...data.filters,
      handlers: data?.filters?.handlers?.map((handlerId) => handlerOptions?.find(({ value }) => handlerId === value)),
      lists: data?.filters?.lists?.map(
        (listId) => listOptions.find(({ value }) => listId === value),
      ),
    },
  };

  const {
    control,
    register,
    getValues,
    reset,
    watch,
    handleSubmit,
    formState: {
      errors,
    } } = useForm({ defaultValues: $data });

  // eslint-disable-next-line
  useEffect(() => { reset($data); }, [data, reset, handlerOptions]);

  const { vlNotifier: { versionNum: version } } = useSelector(selectAppServices);
  const versionMoreThan0_1_6 = version?.major >= 0 && version?.minor >= 1 && version?.patch >= 6;

  const temperatureSimilarityGte = watch('filters.temperature__gte') || 0;
  const topSimilarObjectSimilarityGte = watch('filters.top_similar_object_similarity__gte');
  const pageTitle = t('vlNotifier:форма.заголовок', { context: notificationId === 'create' ? 'создание' : 'обновление' });

  const recipientsValidator = (v) => {
    if (v && !v?.match(/^[0-9, -]+$/)) {
      return t('vlNotifier:валидация.недопустимые значения');
    }
    return true;
  };

  const onAction = (
    <Control.Button type="submit" variant="outlined">
      {t('vlNotifier:форма.submit')}
    </Control.Button>
  );

  useEffect(() => {
    const sub = watch((formData, { name }) => {
      if (name === 'telegram.enable' && formData.telegram.enable) {
        register('telegram.recipients', { required: validate.required() });
      }

      if (name === 'telegram.enable' && !formData.telegram.enable) {
        reset({ ...getValues(), 'telegram.recipients': undefined });
      }
    });

    return () => sub.unsubscribe();
    // eslint-disable-next-line
  }, [watch]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Page actions={onAction} title={pageTitle}>
        <Divider />
        <Grid>
          <GridRow>
            <GridCol cols={6}>
              <Fold isOpen title={t('vlNotifier:general')}>
                <SettingsItemWrapper title={t('vlNotifier:filters.name')}>
                  <FieldWrapper>
                    <Control.Input
                      {...register('filters.name', {
                        required: validate.required(),
                      })}
                      errors={errors}
                    />
                  </FieldWrapper>
                </SettingsItemWrapper>
              </Fold>
              <Divider />
              <Fold isOpen title={t('vlNotifier:rules')}>

                <SettingsItemWrapper contentClassName="NotificationForm__Dropdown" title={t('vlNotifier:filters.handlers')}>
                  <Control.Select
                    control={control}
                    isClearable
                    isMulti
                    name="filters.handlers"
                    options={handlerOptions}
                  />
                </SettingsItemWrapper>

                <SettingsItemWrapper contentClassName="NotificationForm__Dropdown" title={t('vlNotifier:filters.lists')}>
                  <Control.Select
                    control={control}
                    isClearable
                    isMulti
                    name="filters.lists"
                    options={listOptions}
                  />
                </SettingsItemWrapper>

                <SettingsItemWrapper title={t('vlNotifier:filters.top_similar_object_similarity')}>
                  <div className="NotificationForm__FromToContainer">
                    <div className="NotificationForm__FromTo">
                      <Control.Input
                        placeholder={t('vlNotifier:filters.top_similar_object_similarity__gte')}
                        {...register('filters.top_similar_object_similarity__gte', {
                          min: validate.gte(0),
                          max: validate.lte(1),
                          validate: validate.number(),
                        })}
                        errors={errors}
                      />
                    </div>
                    <div className="NotificationForm__Divider">:</div>
                    <div className="NotificationForm__FromTo">
                      <Control.Input
                        placeholder={t('vlNotifier:filters.top_similar_object_similarity__lt')}
                        {...register('filters.top_similar_object_similarity__lt', {
                          min: validate.gte(Math.max(topSimilarObjectSimilarityGte, 0)),
                          max: validate.lte(1),
                          validate: validate.number(),
                        })}
                        errors={errors}
                      />
                    </div>
                  </div>
                </SettingsItemWrapper>

                <SettingsItemWrapper
                  contentClassName="NotificationForm__Dropdown"
                  title={t('vlNotifier:filters.gender')}
                >
                  <Control.Select
                    control={control}
                    isClearable
                    name="filters.gender"
                    options={genderOptions}
                  />
                </SettingsItemWrapper>

                <SettingsItemWrapper
                  contentClassName="NotificationForm__Dropdown"
                  title={t('vlNotifier:filters.source')}
                >
                  <Control.Select
                    control={control}
                    formatGroupLabel={SelectOptionGroupLabel}
                    isCreatable
                    isMulti
                    name="filters.sources"
                    options={camOptions}
                  />
                </SettingsItemWrapper>

                <SettingsItemWrapper
                  contentClassName="NotificationForm__Dropdown"
                  title={t('vlNotifier:filters.age')}
                >
                  <Control.Select
                    control={control}
                    isClearable
                    name="filters.age"
                    options={ageOptions}
                  />
                </SettingsItemWrapper>

                <SettingsItemWrapper
                  contentClassName="NotificationForm__Dropdown"
                  title={t('vlNotifier:filters.emotion')}
                >
                  <Control.Select
                    control={control}
                    isMulti
                    name="filters.emotions"
                    options={emotionOptions}
                  />
                </SettingsItemWrapper>

                <SettingsItemWrapper
                  contentClassName="NotificationForm__Dropdown"
                  title={t('vlNotifier:filters.mask')}
                >
                  <Control.Select
                    control={control}
                    isMulti
                    name="filters.masks"
                    options={maskOptions}
                  />
                </SettingsItemWrapper>

                <SettingsItemWrapper
                  contentClassName="NotificationForm__Dropdown"
                  title={t('vlNotifier:filters.tags')}
                >
                  <Control.Select
                    control={control}
                    disabled={!versionMoreThan0_1_6}
                    formatGroupLabel={SelectOptionGroupLabel}
                    isClearable
                    isCreatable
                    isMulti
                    name="filters.tags"
                    options={tagsOptions}
                  />
                </SettingsItemWrapper>

                <SettingsItemWrapper
                  contentClassName="NotificationForm__FromToContainer"
                  disabled={!versionMoreThan0_1_6}
                  title={t('vlNotifier:filters.temperature')}
                >
                  <div className="NotificationForm__FromToContainer">
                    <div className="NotificationForm__FromTo">
                      <Control.Input
                        placeholder={t('vlNotifier:filters.temperature__gte')}
                        {...register('filters.temperature__gte', {
                          min: validate.gte(35),
                          max: validate.lte(45),
                          validate: validate.number(),
                        })}
                        errors={errors}
                      />
                    </div>
                    <div className="NotificationForm__Divider">:</div>
                    <div className="NotificationForm__FromTo">
                      <Control.Input
                        placeholder={t('vlNotifier:filters.temperature__lt')}
                        {...register('filters.temperature__lt', {
                          min: validate.gte(Math.max(temperatureSimilarityGte, 35)),
                          max: validate.lte(45),
                          validate: validate.number(),
                        })}
                        errors={errors}
                      />
                    </div>
                  </div>
                </SettingsItemWrapper>
              </Fold>
            </GridCol>

            <GridCol>
              <Fold
                control={control}
                disabled
                name="ui.enable"
                nameForm="ui.enable"
                switch
                testId="ui"
                title={t('vlNotifier:ui.enable')}
              >
                <Divider />
              </Fold>

              <Divider />

              <Fold
                control={control}
                isOpen={watch('telegram.enable')}
                name="telegram.enable"
                switch
                testId="telegram"
                title={t('vlNotifier:telegram.enable')}
              >
                <SettingsItemWrapper title={t('vlNotifier:telegram.recipients')}>
                  <Control.Input
                    {...register('telegram.recipients', {
                      validate: {
                        recipientsValidator,
                      },
                    })}
                    errors={errors}
                  />
                </SettingsItemWrapper>

                <div className="NotificationForm__TargetsContainer">
                  <div className="NotificationForm__TargetsTitle">{t('vlNotifier:data')}</div>
                  <Divider />
                  <Grid>
                    <GridRow>
                      <GridCol>
                        <Control.Checkbox
                          id="telegram.targets.create_time"
                          label={t('vlNotifier:targets.create_time')}
                          {...register('telegram.targets.create_time')}
                        />
                      </GridCol>

                      <GridCol>
                        <Control.Checkbox
                          id="telegram.targets.event_id"
                          label={t('vlNotifier:targets.event_id')}
                          {...register('telegram.targets.event_id')}
                        />
                      </GridCol>

                      <GridCol>
                        <Control.Checkbox
                          id="telegram.targets.external_id"
                          label={t('vlNotifier:targets.external_id')}
                          {...register('telegram.targets.external_id')}
                        />
                      </GridCol>
                    </GridRow>
                    <GridRow>
                      <GridCol>
                        <Control.Checkbox
                          id="telegram.targets.source"
                          label={t('vlNotifier:targets.source')}
                          {...register('telegram.targets.source')}
                        />
                      </GridCol>
                      <GridCol>
                        <Control.Checkbox
                          id="telegram.targets.sample"
                          label={t('vlNotifier:targets.sample')}
                          {...register('telegram.targets.sample')}
                        />
                      </GridCol>
                      <GridCol>
                        <Control.Checkbox
                          id="telegram.targets.top_match_sample"
                          label={t('vlNotifier:targets.top_match_sample')}
                          {...register('telegram.targets.top_match_sample')}
                        />
                      </GridCol>
                    </GridRow>
                    <GridRow>
                      <GridCol>
                        <Control.Checkbox
                          id="telegram.targets.top_match_similarity"
                          label={t('vlNotifier:targets.top_match_similarity')}
                          {...register('telegram.targets.top_match_similarity')}
                        />
                      </GridCol>
                      <GridCol>
                        <Control.Checkbox
                          id="telegram.targets.gender"
                          label={t('vlNotifier:targets.gender')}
                          {...register('telegram.targets.gender')}
                        />
                      </GridCol>
                      <GridCol>
                        <Control.Checkbox
                          id="telegram.targets.age"
                          label={t('vlNotifier:targets.age')}
                          {...register('telegram.targets.age')}
                        />
                      </GridCol>
                    </GridRow>
                    <GridRow>
                      <GridCol cols={4}>
                        <Control.Checkbox
                          id="telegram.targets.emotion"
                          label={t('vlNotifier:targets.emotion')}
                          {...register('telegram.targets.emotion')}
                        />
                      </GridCol>
                      <GridCol cols={4}>
                        <Control.Checkbox
                          id="telegram.targets.mask"
                          label={t('vlNotifier:targets.mask')}
                          {...register('telegram.targets.mask')}
                        />
                      </GridCol>
                      <GridCol cols={4}>
                        <Control.Checkbox
                          id="telegram.targets.tags"
                          label={t('vlNotifier:targets.tags')}
                          {...register('telegram.targets.tags')}
                          disabled={!(version?.major >= 0 && version?.minor >= 1 && version?.patch >= 6)}
                        />
                      </GridCol>
                    </GridRow>
                    <GridRow>
                      <GridCol cols={4}>
                        <Control.Checkbox
                          id="telegram.targets.temperature"
                          label={t('vlNotifier:targets.temperature')}
                          {...register('telegram.targets.temperature')}
                          disabled={!(version?.major >= 0 && version?.minor >= 1 && version?.patch >= 6)}
                        />
                      </GridCol>
                      <GridCol cols={4}>
                        <Control.Checkbox
                          id="telegram.targets.top_similar_object_user_data"
                          label={t('vlNotifier:targets.top_similar_object_user_data')}
                          {...register('telegram.targets.top_similar_object_user_data')}
                        />
                      </GridCol>
                      <GridCol cols={4}>
                        <Control.Checkbox
                          id="telegram.targets.handler"
                          label={t('vlNotifier:targets.handler')}
                          {...register('telegram.targets.handler')}
                        />
                      </GridCol>
                    </GridRow>
                    <GridRow>
                      <GridCol cols={4}>
                        <Control.Checkbox
                          id="telegram.targets.lists"
                          label={t('vlNotifier:targets.lists')}
                          {...register('telegram.targets.lists')}
                        />
                      </GridCol>
                    </GridRow>
                  </Grid>
                </div>
              </Fold>

              <Divider />

              <Fold
                control={control}
                disabled
                isOpen={watch('email.enable')}
                name="email.enable"
                switch
                testId="email"
                title={t('vlNotifier:email.enable')}
              >
                <SettingsItemWrapper title={t('vlNotifier:email.recipients')}>
                  <Control.Input
                    {...register('email.recipients')}
                    errors={errors}
                  />
                </SettingsItemWrapper>
                <div className="NotificationForm__TargetsContainer">
                  <div className="NotificationForm__TargetsTitle">{t('vlNotifier:data')}</div>
                  <Divider />
                  <Grid>
                    <GridRow>
                      <GridCol>
                        <Control.Checkbox
                          id="email.targets.create_time"
                          label={t('vlNotifier:targets.create_time')}
                          {...register('email.targets.create_time')}
                        />
                      </GridCol>

                      <GridCol>
                        <Control.Checkbox
                          id="email.targets.event_id"
                          label={t('vlNotifier:targets.event_id')}
                          {...register('email.targets.event_id')}
                        />
                      </GridCol>

                      <GridCol>
                        <Control.Checkbox
                          id="email.targets.external_id"
                          label={t('vlNotifier:targets.external_id')}
                          {...register('email.targets.external_id')}
                        />
                      </GridCol>
                    </GridRow>
                    <GridRow>
                      <GridCol>
                        <Control.Checkbox
                          id="email.targets.source"
                          label={t('vlNotifier:targets.source')}
                          {...register('email.targets.source')}
                        />
                      </GridCol>
                      <GridCol>
                        <Control.Checkbox
                          id="email.targets.sample"
                          label={t('vlNotifier:targets.sample')}
                          {...register('email.targets.sample')}
                        />
                      </GridCol>
                      <GridCol>
                        <Control.Checkbox
                          id="email.targets.top_match_sample"
                          label={t('vlNotifier:targets.top_match_sample')}
                          {...register('email.targets.top_match_sample')}
                        />
                      </GridCol>
                    </GridRow>
                    <GridRow>
                      <GridCol>
                        <Control.Checkbox
                          id="email.targets.top_match_similarity"
                          label={t('vlNotifier:targets.top_match_similarity')}
                          {...register('email.targets.top_match_similarity')}
                        />
                      </GridCol>
                      <GridCol>
                        <Control.Checkbox
                          id="email.targets.gender"
                          label={t('vlNotifier:targets.gender')}
                          {...register('email.targets.gender')}
                        />
                      </GridCol>
                      <GridCol>
                        <Control.Checkbox
                          id="email.targets.age"
                          label={t('vlNotifier:targets.age')}
                          {...register('email.targets.age')}
                        />
                      </GridCol>
                    </GridRow>
                    <GridRow>
                      <GridCol cols={4}>
                        <Control.Checkbox
                          id="email.targets.emotion"
                          label={t('vlNotifier:targets.emotion')}
                          {...register('email.targets.emotion')}
                        />
                      </GridCol>
                      <GridCol cols={4}>
                        <Control.Checkbox
                          id="email.targets.mask"
                          label={t('vlNotifier:targets.mask')}
                          {...register('email.targets.mask')}
                        />
                      </GridCol>
                      <GridCol cols={4}>
                        <Control.Checkbox
                          id="email.targets.tags"
                          label={t('vlNotifier:targets.tags')}
                          {...register('email.targets.tags')}
                          disabled={!(version?.major >= 0 && version?.minor >= 1 && version?.patch >= 6)}
                        />
                      </GridCol>
                    </GridRow>
                    <GridRow>
                      <GridCol cols={4}>
                        <Control.Checkbox
                          id="email.targets.temperature"
                          label={t('vlNotifier:targets.temperature')}
                          {...register('email.targets.temperature')}
                          disabled={!(version?.major >= 0 && version?.minor >= 1 && version?.patch >= 6)}
                        />
                      </GridCol>
                      <GridCol cols={4}>
                        <Control.Checkbox
                          id="email.targets.top_similar_object_user_data"
                          label={t('vlNotifier:targets.top_similar_object_user_data')}
                          {...register('email.targets.top_similar_object_user_data')}
                        />
                      </GridCol>
                      <GridCol cols={4}>
                        <Control.Checkbox
                          id="email.targets.handler"
                          label={t('vlNotifier:targets.handler')}
                          {...register('email.targets.handler')}
                        />
                      </GridCol>
                    </GridRow>
                    <GridRow>
                      <GridCol cols={4}>
                        <Control.Checkbox
                          id="email.targets.lists"
                          label={t('vlNotifier:targets.lists')}
                          {...register('email.targets.lists')}
                        />
                      </GridCol>
                    </GridRow>
                  </Grid>
                </div>
              </Fold>
            </GridCol>
          </GridRow>
        </Grid>
      </Page>
    </form>
  );
}

NotificationForm.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
  notificationId: PropTypes.string.isRequired,
  camOptions: PropTypes.arrayOf(optionPropType),
  handlerOptions: PropTypes.arrayOf(optionPropType),
  listOptions: PropTypes.arrayOf(optionPropType),
  genderOptions: PropTypes.arrayOf(optionPropType),
  emotionOptions: PropTypes.arrayOf(optionPropType),
  maskOptions: PropTypes.arrayOf(optionPropType),
  tagsOptions: PropTypes.arrayOf(optionPropType),
  ageOptions: PropTypes.arrayOf(optionPropType),
  onSubmit: PropTypes.func,
};

NotificationForm.defaultProps = {
  data: {},
  camOptions: [],
  handlerOptions: [],
  listOptions: [],
  genderOptions: [],
  emotionOptions: [],
  maskOptions: [],
  tagsOptions: [],
  ageOptions: [],
  onSubmit: undefined,
};

export default NotificationForm;
