/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';

import { Tooltip, Control } from '@vlabs/uikit';
import cn from 'classnames';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import './ButtonToggleWithPermissions.sass';

import { viewerCan } from '@vlabs/pages/auth/selectors';

// FIXME: это удалится, кода раздел Поиск разделится на разные части
const ButtonToggleWithPermissionsComponent = ({
  can,
  children,
  testId,
  onChange,
  value,
  className,
  itemClassName,
}) => {
  const childrenArray = React.Children.toArray(children);

  return (
    <div
      className={cn('ButtonToggleGroupWithPermissions', className)}
      data-testid={testId}
    >
      {
        childrenArray.map((child, index) => {
          const {
            title,
            disabled,
            permissions,
            ...childProps
          } = child.props;

          const { rules = [], method = 'oneof' } = permissions || {};
          const hasPermission = rules.length ? can(rules, { method }) : true;
          const isDisabled = !hasPermission || disabled;

          return (
            <Tooltip
              className="ButtonToggle__Tooltip"
              data-tooltip-content={isDisabled ? i18n.t('кнопка.недостаточно прав.title') : null}
              key={`tooltip-${index + 1}`}
            >
              <Control.Button
                className={cn(
                  itemClassName, 'ButtonToggleGroupWithPermissions__Item', {
                    ButtonToggleGroupWithPermissions__Item_selected: !isDisabled && value === childProps.value,
                  },
                )}
                data-testid={`button-${index + 1}-${testId}`}
                disabled={isDisabled}
                icon={childProps.icon}
                onClick={() => onChange(childProps.value)}
                title={title}
                value={childProps.value}
                variant={(!isDisabled && value === childProps.value) ? 'filled' : 'outlined'}
                {...childProps}
              >

                {childProps.children}
              </Control.Button>
            </Tooltip>

          );
        })
      }
    </div>
  );
};

ButtonToggleWithPermissionsComponent.propTypes = {
  children: PropTypes.node,
  testId: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  className: PropTypes.string,
  itemClassName: PropTypes.string,
  can: PropTypes.func.isRequired,
};

ButtonToggleWithPermissionsComponent.defaultProps = {
  children: undefined,
  testId: '',
  className: undefined,
  itemClassName: undefined,
};

export const ButtonToggleWithPermissions = connect(
  (state) => ({
    can: viewerCan(state),
  }))(ButtonToggleWithPermissionsComponent);
