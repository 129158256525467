import React from 'react';

import PropTypes from 'prop-types';

import { Link } from '../controls/link/Link';

import './Breadcrumbs.sass';

const Breadcrumb = ({
  link,
  caption,
}) => {
  if (!link) return <span>{caption}</span>;

  return (
    <Link to={link}>
      {caption}
    </Link>
  );
};

const BreadcrumbPropType = {
  caption: PropTypes.string.isRequired,
  link: PropTypes.string,
};

const BreadcrumbArrayPropType = PropTypes.arrayOf(
  PropTypes.shape(BreadcrumbPropType),
);

Breadcrumb.propTypes = BreadcrumbPropType;

Breadcrumb.defaultProps = {
  link: undefined,
};

export {
  Breadcrumb,
  BreadcrumbPropType,
  BreadcrumbArrayPropType,
};
