import React, { useMemo } from 'react';

import { BaseSample } from '@vlabs/shared/components/sample/BaseSample';
import { GoToCell } from '@vlabs/shared/components/table';
import InfoRow from '@vlabs/shared/legacy-components/info-row/InfoRow';
import ListLink from '@vlabs/shared/legacy-components/list-link/ListLink';
import { parseISOToLocale, calcSimilarityPercent, actionColumnProps, calcBadgeType } from '@vlabs/shared/utils';
import { Table, Badge } from '@vlabs/uikit';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { selectIsTopMatchHiding } from '../selectors';
import st from './FaceTable.module.sass';

const FaceCell = ({ value }) => (
  <div className={st.FaceTopMatchCell} data-testid="faceTable.sampleCell">
    <BaseSample
      imageUrl={value}
      type="face"
    />
  </div>
);

const FaceInfoCell = ({
  row: { original },
}) => {
  const { t } = useTranslation();

  return (
    <div className={st.InfoRow_wrapper}>
      <Badge
        className={st.SimilarityBadge}
        data-testid="topMatchCell.lists"
        kind={calcBadgeType(original?.similarity)}
        value={calcSimilarityPercent(original?.similarity)}
      />
      <div className={st.RowInfoWidth}>
        {original.create_time && (
          <InfoRow
            columnDirection
            data-testid="tab.faceInfoCell.createTime"
            title={t('date created')}
            value={parseISOToLocale(original?.create_time)}
          />
        )}
        {original?.lists?.length > 0 && (
          <InfoRow
            columnDirection
            data-testid="tab.faceInfoCell.lists"
            title={t('search:счетчик.списки', { count: original?.lists?.length || 0 })}
            value={<ListLink lists={original?.lists} />}
          />
        )}
      </div>
    </div>
  );
};

const FaceAttributesCell = ({ row: { original } }) => {
  const { t } = useTranslation();
  return (
    <>
      {original?.external_id && (
      <InfoRow
        columnDirection
        data-testid="tab.faceInfoCell.externalId"
        title={t('external id')}
        value={original?.external_id}
      />
      )}
      {original?.user_data && (
      <InfoRow
        columnDirection
        data-testid="tab.faceInfoCell.userData"
        title={t('information')}
        value={original?.user_data}
      />
      )}
    </>
  );
};

function FaceTable({
  data,
  detections,
  isTopMatchHiding,
  CustomTopPanel,
}) {
  const { t } = useTranslation();

  const buildFaceLink = (matchResult) => {
    return matchResult.face_id ? `/faces/${matchResult.face_id}` : undefined;
  };

  const columns = useMemo(() => {
    const tableCells = [
      {
        Header: t('search:table header.search result'),
        accessor: 'uiAvatarURL',
        Cell: FaceCell,
        width: 50,
      },
      {
        Header: t('search:table header.details'),
        Cell: FaceInfoCell,
        width: 60,
      },
      {
        id: 'attributes',
        Cell: FaceAttributesCell,
      },
      actionColumnProps({
        id: 'goToFace',
        Cell: GoToCell,
        accessor: buildFaceLink,
        width: 50,
      }),
    ];
    if (isTopMatchHiding) return tableCells;

    const topMatchCell = {
      Header: t('search:table header.search image'),
      accessor: (matchResult) => {
        const detectionImage = detections?.byId?.[matchResult.reference.id]?.image;
        return detectionImage || matchResult.referenceImage || matchResult.reference.id;
      },
      Cell: FaceCell,
      minWidth: 50,
      width: 50,
      maxWidth: 50,
    };
    tableCells.splice(1, 0, topMatchCell);
    return tableCells;
    // FIXME: пересмотреть зависимости
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, isTopMatchHiding]);

  return (
    <div className={st.TabPanel}>
      <Table
        columns={columns}
        CustomTopPanel={CustomTopPanel}
        data={data}
      />
    </div>
  );
}

export default connect(
  (state) => ({
    isTopMatchHiding: selectIsTopMatchHiding(state),
  }),
)(FaceTable);
