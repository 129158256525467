import constants_en from './constants_en.json';
import constants_ru from './constants_ru.json';
import lunaApi_en from './lunaApi_en.json';
import lunaApi_ru from './lunaApi_ru.json';

export const api = {
  en: {
    lunaApi: lunaApi_en,
    constants: constants_en,
  },
  ru: {
    lunaApi: lunaApi_ru,
    constants: constants_ru,
  },
};
