import React, { memo } from 'react';

import { IconInner } from '../../IconInner';

const CrosshairIconInner = (props) => (
  <IconInner name="CrosshairIcon" viewBox="0 0 24 24" {...props}>
    <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10Zm10-10h-4M6 12H2m10-6V2m0 20v-4" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
  </IconInner>
);

export const CrosshairIcon = memo(CrosshairIconInner);
