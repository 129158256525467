import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Select } from '../../controls/select/Select';

export function buildOneOfFilter(params = {}) {
  const { options } = params;
  if (!options) return null;

  const OneOfFilter = ({
    column,
  }) => {
    const { t } = useTranslation();
    const { filterValue, setFilter } = column;

    const selectOptions = [
      { value: undefined, label: t('uikit:table.фильтры.все') },
      ...options.map((option) => {
        if (typeof option === 'object' && option !== null) return option;
        return { value: option, label: option };
      }),
    ];

    return (
      <Select
        inputStyle="implicit"
        onChange={(option) => {
          setFilter(option);
        }}
        options={selectOptions}
        placeholder={t('uikit:table.фильтры.все')}
        value={filterValue}
      />
    );
  };

  OneOfFilter.propTypes = {
    column: PropTypes.any.isRequired,
  };

  return OneOfFilter;
}
