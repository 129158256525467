import { get, CleanSchema } from '../../../json-transform';
import { getEventsQS } from '../../../luna-client/events/mappers/getEventsQS';
import { REPORT_TYPE } from '../../constants';

export const createReport = new CleanSchema({
  filters: get('filters').asSchema(getEventsQS),
  plan_id: get('plan').asOptionValue(),
  luna_account_id: get('luna_account_id').asString(),
  author: get('author').asString(),
  description: get('description').asString(),
  type: get('type').asOptionValue(REPORT_TYPE),
});
