import { Schema, get } from '../../json-transform';

export const getStreamsQS = new Schema({
  account_id: get('account_id').ifEmptyString(undefined),
  stream_ids: get('stream_ids').asArrayFromString().ifEmpty(undefined),
  names: get('names').asArrayFromString().ifEmpty(undefined),
  reference: get('reference').asString().ifEmptyString(undefined),
  statuses: get('statuses').asArrayFromString().ifEmptyString(undefined),
  create_time__gte: get('create_time__gte[0]').asISOString(),
  create_time__lt: get('create_time__lt[0]').asISOString(),
  group_name: get('group_name').asString().ifEmptyString(undefined),
  order: get('order').asString().ifEmptyString(undefined),
  page: get('page').asInt(),
  page_size: get('page_size').asInt(),
});
