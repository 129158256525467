export const defaultFilters = {
  match: [],
  ethnicities: [],
  gender: null,
  age: null,
  liveness: [],
};

export const defaultMatch = {
  label: undefined,
  similarityGte: '',
  similarityLte: '',
};

export const defaultTag = {
  tag: '',
  filters: defaultFilters,
};
