import React, { useCallback, useEffect, useRef, useState } from 'react';

import { DeleteIcon, EditIcon, PlusIcon } from '@vlabs/icons';
import { Control, openConfirmPopup, Margin, Modal, SettingsItemWrapper } from '@vlabs/uikit';
import { isEqual } from 'lodash';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useUpdateField } from '../hooks';
import MatchPolicyForm from './MatchPolicyForm';

const useSetNewValueForAllPolicies = ({ value, on, updateIf }) => {
  useUpdateField({ path: 'attributesPolicy.filters.match', value, on, updateIf });
  useUpdateField({ path: 'facePolicy.filters.match', value, on, updateIf });
  useUpdateField({ path: 'eventPolicy.filters.match', value, on, updateIf });
  useUpdateField({ path: 'notificationPolicy.filters.match', value, on, updateIf });
  useUpdateField({ path: 'faceSamplePolicy.filters.match', value, on, updateIf });
  useUpdateField({ path: 'bodySamplePolicy.filters.match', value, on, updateIf });
  useUpdateField({ path: 'imageOriginPolicy.filters.match', value, on, updateIf });
  useUpdateField({ path: 'faceToListPolicy.lists.@.filters.match', value, on, updateIf });
  useUpdateField({ path: 'tagPolicy.@.filters.match', value, on, updateIf });
  useUpdateField({ path: 'callbacksPolicy.@.filters.match', value, on, updateIf });
};

const useDeleteMatchPolicy = () => {
  const matchPolicy = useWatch({ name: 'matchPolicy' });
  const prev = useRef(matchPolicy);
  const matchPolicyLabels = matchPolicy.map(({ label }) => label);
  const value = useCallback(
    (currentValue) => currentValue?.filter(({ label }) => matchPolicyLabels.includes(label?.value)),
    [matchPolicyLabels],
  );
  const updateIf = useCallback((currentValue) => !isEqual(currentValue, value(currentValue)), [value]);
  const on = useCallback(() => prev.current.length > matchPolicy.length, [prev, matchPolicy]);

  useSetNewValueForAllPolicies({ value, on, updateIf });
  useEffect(() => { prev.current = matchPolicy; }, [prev, matchPolicy]);
};

const useUpdateMatchPolicy = () => {
  const matchPolicy = useWatch({ name: 'matchPolicy' });
  const prev = useRef(matchPolicy);
  const matchPolicyLabels = matchPolicy.map(({ label }) => label);
  const value = useCallback(
    (currentValue) => {
      return currentValue.map((cv) => {
        if (matchPolicyLabels.includes(cv.label?.value)) return cv;
        const newValue = matchPolicyLabels.find((label) => !currentValue
          .map((v) => v.label.value)
          .includes(label));
        return { ...cv, label: { label: newValue, value: newValue } };
      });
    },
    [matchPolicyLabels],
  );
  const updateIf = useCallback((currentValue) => !isEqual(currentValue, value(currentValue)), [value]);
  const on = useCallback(
    () => prev.current.length === matchPolicy.length && !isEqual(prev.current, matchPolicy),
    [prev, matchPolicy],
  );

  useSetNewValueForAllPolicies({ value, on, updateIf });
  useEffect(() => { prev.current = matchPolicy; }, [prev, matchPolicy]);
};

const MatchPolicySubform = () => {
  const { t } = useTranslation();
  const { control, handlerOptions, listOptions, setValue } = useFormContext();
  const { fields, remove, update, append } = useFieldArray({ control, name: 'matchPolicy' });
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(undefined);

  const isFaceDescriptorExtract = useWatch({ name: 'extract.faceDescriptor' });
  const isBodyDescriptorExtract = useWatch({ name: 'extract.bodyDescriptor' });
  const matchPolicyList = useWatch({ name: 'matchPolicy' });
  const matchPolicyLabels = matchPolicyList.map(({ label }) => label);

  useDeleteMatchPolicy();
  useUpdateMatchPolicy();

  const closeModal = () => {
    setSelectedIndex(undefined);
    setModalIsOpen(false);
  };

  const onOpenModal = (index) => {
    setSelectedIndex(index);
    setModalIsOpen(true);
  };

  const onSubmit = (value) => {
    if (selectedIndex !== undefined) {
      update(selectedIndex, value);
    } else {
      append(value);
    //   if (selectedMatchPolicy.label !== item.label) {
    //     const updatedValues = JSON.parse(JSON.stringify(values));
    //     updatedValues.policies.match_policy[selectedIndex] = item;
    //     // replaceNestedLabels(updatedValues, selectedMatchPolicy.label, item.label);
    //     setValues(updatedValues);
    //   } else {
    //     setFieldValue(`policies.match_policy[${selectedIndex}]`, item);
    //   }
    // } else {
    //   setFieldValue('policies.match_policy', [...values.policies.match_policy, item]);
    }
    closeModal();
  };

  const removeAfterConfirmation = (i, name) => {
    const message = (
      <p>
        {t('handlers:параметр сравнения.подтверждение.текст', {
          context: 'удаление',
          name,
        })}
      </p>
    );

    const onConfirm = () => { remove(i); };

    openConfirmPopup({
      title: t('handlers:параметр сравнения.подтверждение.заголовок', { context: 'удаление' }),
      message,
      type: 'delete',
      onConfirm,
    });
  };

  useEffect(() => {
    //     if (name === 'matchPolicy') {
  //       if (prevForm.current.matchPolicy.length > original.matchPolicy.length) {
  //         clearMatchPolicyDependantFields(original, prevForm.current);
  //       } else {
  //         original.matchPolicy.forEach((match, i) => {
  //           const oldLabel = prevForm.current.matchPolicy[i].label;
  //           const newLabel = match.label;
  //           if (oldLabel !== newLabel) updateMatchLabels(original, oldLabel, newLabel);
  //         });
  //       }
  //     }
  }, [setValue]);

  return (
    <>
      <Modal
        appElement={document.getElementById('root')}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
      >
        <MatchPolicyForm
          estimate={useWatch({ name: 'estimate' })}
          extract={useWatch({ name: 'extract' })}
          handlerOptions={handlerOptions}
          initialValues={{ ...matchPolicyList[selectedIndex] }}
          isSelectedMatch={selectedIndex !== undefined}
          listOptions={listOptions}
          matchPolicyList={matchPolicyList}
          onSubmit={onSubmit}
        />
      </Modal>

      <>
        {matchPolicyList && fields.map((field, i) => (
          <SettingsItemWrapper
            key={field.id}
            title={matchPolicyLabels[i]}
          >
            <div className="MatchPolicy_Margin">
              <Control.RoundButton
                data-testid="matchPolicy.editIcon"
                icon={<EditIcon />}
                kind="primary"
                onClick={() => onOpenModal(i)}
                title={t('кнопка.редактировать')}
                variant="dimmed"
              />
              <Margin left />
              <Control.RoundButton
                data-testid="matchPolicy.deleteIcon"
                icon={<DeleteIcon />}
                kind="negative"
                onClick={() => removeAfterConfirmation(i, matchPolicyLabels[i])}
                title={t('кнопка.удалить')}
                variant="dimmed"
              />
            </div>
          </SettingsItemWrapper>
        ))}

        <SettingsItemWrapper
          title={!isFaceDescriptorExtract && !isBodyDescriptorExtract && (
            <div className="MatchPolicy__Title">
              {t('Для использования включите параметр "Биометрический шаблон лица" или "Биометрический шаблон тела"')}
            </div>
          )}
        >
          {(isFaceDescriptorExtract || isBodyDescriptorExtract) && (
          <div className="MatchPolicy_Margin">
            <Control.RoundButton
              data-testid="matchPolicy.plusIcon"
              icon={<PlusIcon />}
              kind="primary"
              onClick={() => onOpenModal()}
              title={t('кнопка.добавить')}
              variant="dimmed"
            />
          </div>
          )}
        </SettingsItemWrapper>
      </>
    </>
  );
};

export default MatchPolicySubform;
