import React, { memo } from 'react';

import { IconInner } from '../../IconInner';

const DeleteIconInner = (props) => (
  <IconInner name="DeleteIcon" viewBox="0 0 24 24" {...props}>
    <path d="M3 6h18M8 6V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2m3 0v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6h14Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
  </IconInner>
);

export const DeleteIcon = memo(DeleteIconInner);
