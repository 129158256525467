import { apiContainer } from '@vlabs/api-bindings';

export const getFaceDetectionsFromFile = async (file) => {
  const { data: { images: [{ detections: { samples } }] } } = await apiContainer.lunaClient.detector.detectFaces(file);

  const { data: { images_estimations } } = await apiContainer.lunaClient.sdk.estimateFace(file);

  const detectionList = [];
  if (!samples.length) return detectionList;

  images_estimations.forEach(({ estimations }) => estimations.forEach(({ face }, index) => {
    detectionList.push({
      sdk_descriptor: face.detection.attributes.descriptor.sdk_descriptor,
      type: 'face',
      id: samples[index].face.sample_id,
      rect: samples[index].face.detection.rect,
    });
  }));

  return detectionList;
};
