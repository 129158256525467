import React, { useEffect } from 'react';

import { POLICIES } from '@vlabs/api-bindings/src/luna-client/handlers/constants';
import validate from '@vlabs/shared/validators';
import { Control, Grid, GridRow, GridCol } from '@vlabs/uikit';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import st from './CallbackPolicyForm.module.sass';
import { defaultHttpValues, defaultTelegramValues } from './defaultValues';
import { HttpCallbackPolicyForm } from './HttpCallbackPolicyForm';
import { TelegramNotificationForm } from './TelegramNotificationForm';

function setFormValues(values, prefix, setValue) {
  Object.entries(values).forEach(([key, value]) => {
    setValue(`${prefix}${key}`, value);
  });
}

export const CallbackPolicyForm = ({ callbackTypeOptions, fieldName }) => {
  const { t } = useTranslation();
  const prefix = fieldName ? `${fieldName}.` : '';
  const form = useFormContext();
  const callbackType = useWatch({ name: `${prefix}type` })?.value;

  useEffect(() => {
    form.unregister(`${prefix}authorization`);
    form.unregister(`${prefix}url`);
    form.unregister(`${prefix}params`);

    if (callbackType === 'http') {
      setFormValues(defaultHttpValues, prefix, form.setValue);
    } else if (callbackType === 'telegram') {
      setFormValues(defaultTelegramValues, prefix, form.setValue);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callbackType, form.unregister]);

  return (
    <div className={st.Root}>
      <Grid>
        <GridRow>
          <GridCol>
            <Control.Select
              control={form.control}
              data-testid="callback.type"
              label={t('handlers:policies.storage_policy.callbacks.callback.type')}
              name={`${prefix}type`}
              options={callbackTypeOptions || POLICIES.CALLBACK.type.raw}
              rules={{ required: validate.required() }}
            />
          </GridCol>
        </GridRow>
        {callbackType === 'http' && <HttpCallbackPolicyForm fieldName={fieldName} />}
        {callbackType === 'telegram' && <TelegramNotificationForm fieldName={fieldName} />}
      </Grid>
    </div>
  );
};
