import React, { useCallback } from 'react';

import { Control } from '@vlabs/uikit';
import { useFormContext, useWatch } from 'react-hook-form';

import { useUpdateField } from '../hooks';

export function ExtractFaceDescriptorToggle() {
  const { control } = useFormContext();
  const estimateFace = useWatch({ name: 'estimate.face' });
  const extractFaceDescriptor = useWatch({ name: 'extract.faceDescriptor' });

  const faceDescriptorMatchFilter = useCallback((matches) => {
    return matches.filter(({ descriptorType }) => descriptorType?.value !== 'face');
  }, []);
  useUpdateField({ path: 'matchPolicy',
    value: faceDescriptorMatchFilter,
    on: useCallback(() => extractFaceDescriptor === false, [extractFaceDescriptor]),
    updateIf: useCallback(
      (currentValue) => faceDescriptorMatchFilter(currentValue).length !== currentValue.length,
      [faceDescriptorMatchFilter],
    ),
  });

  return (
    <Control.Switch
      control={control}
      disabled={!estimateFace}
      name="extract.faceDescriptor"
    />
  );
}
