import React, { useMemo } from 'react';

import { ImageIcon, ZoomInIcon, MoreHorizontalIcon } from '@vlabs/icons';
import { ButtonWithPermissions } from '@vlabs/shared/components/button-with-permissions/ButtonWithPermissions';
import { FullFrame } from '@vlabs/shared/components/images/FullFrame';
import { Track } from '@vlabs/shared/components/images/Track';
import { permissions } from '@vlabs/shared/config';
import { useImageCheck } from '@vlabs/shared/hooks';
import { ButtonScrimGroup, ButtonScrimItem, useModal } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { viewerCan } from '@vlabs/pages/auth/selectors';

function useTrackModal(props) {
  const modal = useModal();
  modal.wrap(Track);
  return {
    Component: modal.wrap(<Track {...props} />),
    isOpen: modal.isOpen,
    open: modal.open,
    close: modal.close,
  };
}

function useFullFrameModal(props) {
  const modal = useModal();

  return {
    Component: modal.wrap(<FullFrame {...props} />),
    isOpen: modal.isOpen,
    open: modal.open,
    close: modal.close,
  };
}

const EventActionsComponent = ({
  sampleURL,
  fullFrameURL,
  track,
  rect,
  extraActions,
  can,
  type = 'face',
}) => {
  const { t } = useTranslation();
  const samplePermissionByType = useMemo(() => {
    return type === 'face' ? [permissions.faceSample.view] : [permissions.bodySample.view];
  }, [type]);

  const urls = useMemo(() => ({ sample: sampleURL, fullFrame: fullFrameURL }), [sampleURL, fullFrameURL]);
  const error = useImageCheck(urls);
  const trackModal = useTrackModal({ detections: track });
  const imageOriginModal = useFullFrameModal({ fullFrameURL, rect });

  const isSampleDisabled = !sampleURL || error.sample || !can([permissions.faceSample.view]);
  const isViewTrackDisabled = !track || track.length <= 1 || error.fullFrame;
  const isFullFrameDisabled = !fullFrameURL || error.fullFrame;

  return (
    <>
      {imageOriginModal.Component}
      {trackModal.Component}

      <ButtonScrimGroup
        childrenAs={ButtonWithPermissions}
      >
        <ButtonScrimItem
          as={isSampleDisabled ? 'div' : 'a'}
          disabled={isSampleDisabled}
          href={sampleURL}
          icon={<ZoomInIcon />}
          permissions={{ rules: samplePermissionByType }}
          round
          title={t('посмотреть в полном размере')}
          variant="dimmed"
        />

        <ButtonScrimItem
          data-testid="eventActions.viewTrackButton"
          disabled={isViewTrackDisabled}
          icon={<MoreHorizontalIcon />}
          onClick={trackModal.open}
          permissions={{ rules: samplePermissionByType }}
          round
          title={t('просмотр трека')}
          variant="dimmed"
        />

        <ButtonScrimItem
          data-testid="eventActions.fullFrameButton"
          disabled={isFullFrameDisabled}
          icon={<ImageIcon />}
          onClick={imageOriginModal.open}
          permissions={{ rules: [permissions.image.view] }}
          round
          title={t('полный кадр')}
          variant="dimmed"
        />
        {extraActions}
      </ButtonScrimGroup>
    </>
  );
};

EventActionsComponent.propTypes = {
  can: PropTypes.func.isRequired,
  type: PropTypes.string,
  extraActions: PropTypes.node,
  sampleURL: PropTypes.string,
  track: PropTypes.arrayOf(PropTypes.shape({
    uiSampleURL: PropTypes.string,
  })),
  fullFrameURL: PropTypes.string,
  rect: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
  }),
};
EventActionsComponent.defaultProps = {
  extraActions: undefined,
  sampleURL: undefined,
  track: undefined,
  type: undefined,
  fullFrameURL: undefined,
  rect: undefined,
};

export const EventActions = connect((state) => ({
  can: viewerCan(state),
}))(EventActionsComponent);

export const EventFaceActions = ({
  sampleURL,
  fullFrameURL,
  track,
  rect,
  extraActions,
}) => {
  return (
    <EventActions
      extraActions={extraActions}
      fullFrameURL={fullFrameURL}
      rect={rect}
      sampleURL={sampleURL}
      track={track}
      type="face"
    />
  );
};

export const EventBobyActions = ({
  sampleURL,
  fullFrameURL,
  track,
  rect,
  extraActions,
}) => {
  return (
    <EventActions
      extraActions={extraActions}
      fullFrameURL={fullFrameURL}
      rect={rect}
      sampleURL={sampleURL}
      track={track}
      type="body"
    />
  );
};
