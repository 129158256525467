import React, { useEffect, useState, useMemo } from 'react';

import { Control, Divider, Page, Panel, Margin, ImagesPointsMatcher } from '@vlabs/uikit';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { selectPlan } from '../plans/selectors';
import * as plansStore from '../plans/store';
import { selectCurrentSource } from './selectors';
import { createBreadcrumbs } from './sourcesHelpers';
import * as sourcesStore from './store';

function SourcePageComponent({
  onValidate,
  fetchData,
  calibrateSource,
  breadcrumbs,
  initialPoints,
  sourceImageURL,
  planeImageURL,
}) {
  const { t } = useTranslation();
  const [points, setPoints] = useState(initialPoints);
  const images = useMemo(() => ([
    { source: sourceImageURL, isZoomable: true },
    { source: planeImageURL, isZoomable: true },
  ]), [sourceImageURL, planeImageURL]);

  useEffect(() => { fetchData(); }, [fetchData]);
  useEffect(() => { setPoints(initialPoints); }, [initialPoints]);

  const onSubmit = () => { calibrateSource(points); };

  const actions = (
    <>
      <Margin right>
        <Control.Button
          disabled={points.length < 4}
          onClick={onSubmit}
        >
          {t('форма.submit')}
        </Control.Button>
      </Margin>

      <Margin right>
        <Control.Button
          disabled={points?.length === 0}
          kind="negative"
          onClick={() => setPoints([])}
        >
          {t('button.reset')}
        </Control.Button>
      </Margin>

      <Control.Button
        disabled={points.length < 4}
        onClick={() => onValidate()}
      >
        {t('sources:валидация')}
      </Control.Button>
    </>
  );

  if (!images[0].source || !images[1].source) return null;

  return (
    <Page
      actions={actions}
      breadcrumbs={breadcrumbs}
    >
      <Divider small />
      <Panel>
        <div className="ImagesPointsDetector_Wrapper">
          <ImagesPointsMatcher
            images={images}
            isRow
            onChange={(newPoints) => { if (!isEqual(newPoints, points)) setPoints(newPoints); }}
            points={points}
          />
        </div>
      </Panel>
    </Page>
  );
}

SourcePageComponent.propTypes = {
  fetchData: PropTypes.func.isRequired,
  calibrateSource: PropTypes.func.isRequired,
  sourceImageURL: PropTypes.string,
  planeImageURL: PropTypes.string,
  breadcrumbs: PropTypes.arrayOf(PropTypes.shape({})),
  onValidate: PropTypes.func.isRequired,
  initialPoints: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({}),
    ),
  ),
};

SourcePageComponent.defaultProps = {
  sourceImageURL: undefined,
  planeImageURL: undefined,
  breadcrumbs: [],
  initialPoints: [],
};

export const SourcePage = connect((state, ownProps) => ({
  sourceImageURL: selectCurrentSource(state)?.source_image_url,
  planeImageURL: selectPlan(state)?.plan_image_url,
  breadcrumbs: createBreadcrumbs({
    route: ownProps.match,
    plan: selectPlan(state),
    sourceName: selectCurrentSource(state)?.source_name?.value,
  }),
  initialPoints: selectCurrentSource(state)?.points || [],
}), (dispatch, ownProps) => ({
  fetchData: () => {
    dispatch(sourcesStore.fetchSource(ownProps.match.params.calibrationId));
    dispatch(plansStore.fetchPlan(ownProps.match.params.planId));
  },
  calibrateSource: (points) => {
    dispatch(sourcesStore.calibrate(ownProps.match.params.calibrationId, ownProps.match.params.planId, points));
  },
  onValidate: () => {
    const { planId } = ownProps.match.params;
    const { calibrationId } = ownProps.match.params;
    ownProps.history.push(`/plans/${planId}/calibrations/${calibrationId}/validation`);
  },
}))(SourcePageComponent);
