import React, { useContext } from 'react';

import { START_OF_DAY, END_OF_DAY } from '@vlabs/shared/config';
import validate from '@vlabs/shared/validators';
import { Control, Group } from '@vlabs/uikit';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SearchContext } from '../search-context';
import st from './shared.module.sass';

const FaceFiltersForm = () => {
  const { t } = useTranslation();
  const { register, control, formState: { errors } } = useFormContext();

  const { listOptions } = useContext(SearchContext);

  // инпуты обёрнуты в дивы чтобы объединить их с лейблами
  // и они корректно располагались по сетке

  return (
    <div className={st.Wrapper}>
      <Group gapSize="5px" vertical>
        <div>
          <Control.DateInput
            control={control}
            data-testid="createTimeGte"
            enableTime
            id="create_time__gte"
            label={t('дата создания от')}
            name="create_time__gte"
            options={START_OF_DAY}
          />
        </div>

        <div>
          <Control.DateInput
            control={control}
            data-testid="createTimeLt"
            enableTime
            id="create_time__lt"
            label={t('дата создания до')}
            name="create_time__lt"
            options={END_OF_DAY}
          />
        </div>

        <div>
          <Control.Input
            id="external_ids"
            label={t('внешние id событий')}
            {...register('external_ids', {
              validate: validate.multipleExternalId(),
            })}
            errors={errors}
          />
        </div>

        <div>
          <Control.Input
            id="threshold"
            label={t('search:фильтры.схожесть')}
            {...register('threshold', {
              min: validate.gte(0),
              max: validate.lte(100),
              validate: validate.number(),
            })}
            errors={errors}
            placeholder={t('не задано')}
          />
        </div>

        <div>
          <Control.Select
            control={control}
            data-testid="face.lists"
            id="list_id"
            isClearable
            label={t('списки')}
            name="list_id"
            options={listOptions}
          />
        </div>

        <div>
          <Control.Input
            id="user_data"
            label={t('search:фильтры.лица.пользовательские данные')}
            {...register('user_data', {
              maxLength: validate.maxLength(128),
            })}
            errors={errors}
          />
        </div>
      </Group>
    </div>
  );
};

export { FaceFiltersForm };
