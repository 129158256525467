import React, { useMemo } from 'react';

import { apiContainer } from '@vlabs/api-bindings';
import { PlayerPlayIcon } from '@vlabs/icons';
import { TableCellsWithPermissions } from '@vlabs/shared/components/table/cells/index';
import { TableBottomPanelWithPermissions } from '@vlabs/shared/components/table/TableBottomPanelWithPermissions';
import LoadingPage from '@vlabs/shared/components/loading-page/LoadingPage';
import { permissions } from '@vlabs/shared/config';
import { useApiRequest, usePagination } from '@vlabs/shared/hooks';
import { routes } from '@vlabs/shared/navigation/routes';
import { actionColumnProps } from '@vlabs/shared/utils';
import { Control, Page, Panel, Table, openConfirmPopup } from '@vlabs/uikit';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

const VerifyCell = ({ row }) => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <Control.RoundButton
      data-testid="linkCell"
      icon={<PlayerPlayIcon />}
      kind="primary"
      onClick={() => history.push(generatePath(routes.verifiers.verify, { verifierId: row.original.verifierId }))}
      title={t('verifiers:таблица.кнопки.тестировать верификатор')}
      variant="dimmed"
    />
  );
};

function VerifierListPage() {
  const { t } = useTranslation();
  const history = useHistory();
  const { pageIndex, pageSize, pageSizeOptions, setPageIndex, setPageSize } = usePagination();
  const { state: verifiers, isLoading, fetchData: fetchVerifiers } = useApiRequest({
    apiRequest: apiContainer.lunaClient.verifiers.getPage,
    parameters: useMemo(() => ({ page_size: pageSize, page: pageIndex + 1 }), [pageIndex, pageSize]),
  });

  const onDeleteVerifier = (verifier) => {
    const message = <span>{t('verifiers:таблица.подтверждение.удаление.текст', { verifier })}</span>;

    const onConfirm = async () => {
      await apiContainer.lunaClient.verifiers.delete(verifier.verifierId);
      fetchVerifiers();
      toast.success(`${t('verifiers:api.успешно удален')}.`);
    };

    openConfirmPopup({
      title: t('verifiers:таблица.подтверждение.удаление.заголовок'),
      message,
      type: 'delete',
      onConfirm,
    });
  };

  const onEditVerifier = ({ verifierId }) => history.push(generatePath(routes.verifiers.read, { verifierId }));

  const onCreateVerifier = () => history.push(generatePath(routes.verifiers.create));

  const columns = useMemo(() => ([
    { Header: t('verifiers:таблица.колонки.description'), accessor: 'description' },
    { Header: t('verifiers:таблица.колонки.verifier_id'), accessor: 'verifierId' },
    actionColumnProps({ id: 'verify', Cell: VerifyCell }),
    actionColumnProps({ id: 'edit', Cell: TableCellsWithPermissions.EditCell }),
    actionColumnProps({ id: 'delete', Cell: TableCellsWithPermissions.DeleteCell }),
  ]
  ), [t]);

  if (isLoading || !verifiers.data) return <LoadingPage />;

  return (
    <Page className="Global__Table_fullScreen_wrapper">
      <Panel className="Global__Table_fullScreen">
        <Table
          columns={columns}
          CustomBottomPanel={TableBottomPanelWithPermissions}
          data={verifiers.data}
          meta={verifiers.meta}
          onActions={{
            onAddRow: { handler: onCreateVerifier, permissions: { rules: [permissions.verifier.creation] } },
            onEditRow: { handler: onEditVerifier },
            onDeleteRow: { handler: onDeleteVerifier, permissions: { rules: [permissions.verifier.deletion] } },
          }}
          pageIndex={pageIndex}
          pageSize={pageSize}
          pageSizeOptions={pageSizeOptions}
          paginationType="offsetBased"
          setPageIndex={setPageIndex}
          setPageSize={setPageSize}
        />
      </Panel>
    </Page>
  );
}

export default VerifierListPage;
