import React, { useContext } from 'react';

import { EventFiltersForm, FaceFiltersForm } from '@vlabs/pages/search/forms';
import { defaultFilterValues } from '@vlabs/pages/search/helpers';
import { SearchContext } from '@vlabs/pages/search/search-context';
import { ButtonToggleWithPermissions } from '@vlabs/shared/components/button-toggle-with-permissions/ButtonToggleWithPermissions';
import { permissions } from '@vlabs/shared/config';
import { Control, ButtonToggle, Tooltip } from '@vlabs/uikit';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { viewerCan } from '@vlabs/pages/auth/selectors';

import st from './Filters.module.sass';

const FiltersComponent = ({ can }) => {
  const { t } = useTranslation();
  const {
    setFilters,
    resetMatchResults,
    setCandidateOrigin,
    candidateOrigin,
    matcherType,
  } = useContext(SearchContext);

  const formMethods = useForm({ defaultValues: defaultFilterValues });
  const { reset, handleSubmit } = formMethods;
  const canFilterBody = can([permissions.event.view, permissions.event.matching], { method: 'allof' });

  const onResetFilters = () => {
    resetMatchResults();
    reset(defaultFilterValues);
  };

  const onChangeCandidateOrigin = (value) => {
    resetMatchResults();
    reset(defaultFilterValues);
    setCandidateOrigin(value);
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(setFilters)}>
        <div className={st.Root}>
          <div>
            <ButtonToggleWithPermissions
              className={st.ButtonToggle}
              itemClassName={st.ButtonToggle__Item}
              onChange={onChangeCandidateOrigin}
              testId="filtersButtonGroup"
              value={candidateOrigin}
            >
              <ButtonToggle permissions={{ rules: [permissions.event.view, permissions.event.matching], method: 'allof' }} value="events">
                {t('search:искать события')}
              </ButtonToggle>
              <ButtonToggle
                disabled={matcherType && matcherType === 'body'}
                permissions={{ rules: [permissions.face.view, permissions.face.matching], method: 'allof' }}
                value="faces"
              >
                {t('search:искать лица')}
              </ButtonToggle>
            </ButtonToggleWithPermissions>
            {candidateOrigin === 'events' && <EventFiltersForm />}
            {candidateOrigin === 'faces' && <FaceFiltersForm />}
          </div>

          <div className="StickyBottomContainer">
            <div className={st.ButtonPanel}>
              <Tooltip
                data-tooltip-content={matcherType === 'body' && !canFilterBody ? t('кнопка.недостаточно прав.title') : null}
              >
                <Control.Button
                  data-testid="button.onFilter"
                  disabled={matcherType === 'body' && !canFilterBody}
                  type="submit"
                >
                  {t('button.filter')}
                </Control.Button>
              </Tooltip>

              <Control.Button
                data-testid="button.onReset"
                kind="attention"
                onClick={onResetFilters}
                type="button"
              >
                {t('button.reset')}
              </Control.Button>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export const Filters = connect(
  (state) => ({
    can: viewerCan(state),
  }),
)(FiltersComponent);
