import React from 'react';

import validate from '@vlabs/shared/validators';
import { Page, Control, Divider } from '@vlabs/uikit';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export function CreateDynamicHandlerFrom({ onSubmit }) {
  const { t } = useTranslation();
  const formMethods = useForm({ defaultValues: {
    isDynamic: true,
  } });
  const { register, handleSubmit, formState: { errors } } = formMethods;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Page title={t('handlers:заголовок.создание')}>
        <Divider />
        <Control.Input
          autoFocus
          errors={errors}
          label={t('handlers:policy name')}
          {...register('description', {
            maxLength: validate.maxLength(128),
            required: validate.required(),
          })}
        />
        <Divider small />

        <Control.Button fullWidth type="submit">
          {t('lists:форма.submit')}
        </Control.Button>
      </Page>
    </form>
  );
}
