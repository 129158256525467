import { DEFAULT_HEALTHCHECK_TIMEOUT } from '../config';
import { loadProgressBar } from '@vlabs/axios-progress-bar';
import Axios from 'axios';
import formatISO from 'date-fns/formatISO';
import qs from 'qs';

import '@vlabs/axios-progress-bar/dist/nprogress.css';

import { toApi } from './mappers/reportToApi';

export class VLTimeTrackerClient {
  constructor() {
    this.baseURL = '/api/vltimetracker';
    this.http = Axios.create({
      baseURL: this.baseURL,
      withCredentials: true,
      paramsSerializer: (params) => (
        qs.stringify(params, {
          arrayFormat: 'comma',
          serializeDate: formatISO,
        })),
    });
    loadProgressBar('', this.http);
  }

  healthcheck() {
    return this.http.get('/version', {
      timeout: DEFAULT_HEALTHCHECK_TIMEOUT,
    }).catch((e) => {
      if (e?.code === 'ECONNABORTED') throw e;
      if (e?.response?.status >= 500) throw e;
    });
  }

  async version() {
    const { data: { timeTrackerVersion } } = await this.http.get('/version');
    return timeTrackerVersion;
  }

  get reports() {
    return {
      get: (params) => this.http.get('/getReports', { params }),
      createReportsEntry: (params) => this.http.post('/createReportsEntry', toApi(params, 'entry')),
      createReportsExit: (params) => this.http.post('/createReportsExit', toApi(params, 'exit')),
      createReportsActivity: (params) => this.http.post('/createReportsActivity', toApi(params, 'activity')),
      downloadCSV: (file) => this.http.get(`${file}`, {
        headers: { Accept: 'application/csv' },
        responseType: 'blob',
      }),
    };
  }
}
