import React, { useContext } from 'react';

import { DeleteIcon } from '@vlabs/icons';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { RoundButton } from '../../controls/button/RoundButton';
import { TableContext } from '../table-context';

const DeleteCell = ({
  row,
  buttonProps,
}) => {
  const { actions } = useContext(TableContext);
  const onDelete = actions.onDeleteRow?.getHandlerIfCan?.(row.original);
  const label = actions.onDeleteRow?.label;
  const { t } = useTranslation();

  if (!onDelete) return null;

  return (
    <RoundButton
      data-testid="deleteCell"
      icon={<DeleteIcon />}
      kind="negative"
      onClick={() => onDelete(row.original)}
      title={label || t('uikit:table.действия.удаление элемента')}
      variant="dimmed"
      {...(buttonProps || {})}
    />
  );
};

DeleteCell.propTypes = ({
  row: PropTypes.objectOf(PropTypes.any),
  buttonProps: PropTypes.object,
});

DeleteCell.defaultProps = ({
  row: undefined,
  buttonProps: undefined,
});

export { DeleteCell };
