import React from 'react';

import { optionPropType } from '@vlabs/shared';
import { Can } from '@vlabs/shared/components/can/Can';
import { permissions } from '@vlabs/shared/config';
import { routes } from '@vlabs/shared/navigation/routes';
import { selectListOptions } from '@vlabs/shared/selectors/listSelectors';
import { Control } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { generatePath } from 'react-router-dom';

import st from './ListLink.module.sass';

const ListLink = ({
  lists,
  listOptions,
}) => {
  if (!lists?.length) {
    return (<div>-</div>);
  }

  return (
    <div className={st.ListLink}>
      <Can
        no={lists.map((id) => <div>{id}</div>)}
        permissions={{ rules: [permissions.list.view] }}
        yes={lists.map((listId, index) => (
          <Control.Link
            data-testid={`listLink${index + 1}`}
            key={listId}
            to={generatePath(routes.lists.read, { listId })}
          >
            {listOptions?.find(({ value }) => value === listId)?.label || listId}
          </Control.Link>
        ))}
      />
    </div>
  );
};

ListLink.propTypes = {
  lists: PropTypes.arrayOf(PropTypes.string),
  listOptions: PropTypes.arrayOf(optionPropType),
};
ListLink.defaultProps = {
  lists: [],
  listOptions: [],
};

export default connect((state) => ({
  listOptions: selectListOptions(state),
}))(ListLink);
