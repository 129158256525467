/* eslint-disable no-param-reassign */

export function collapse(element) {
  // 1 потому что для предотвращения схлопывания у body padding-bottom 1px
  if (element.clientHeight === 0 || element.clientHeight === 1) {
    element.style.overflow = 'hidden';
    element.style.height = '0px';
    return;
  }

  element.style.overflow = 'hidden';

  requestAnimationFrame(() => {
    // при изменения размеров % -> px transition не сработает,
    // поэтому сначала железно прописываем высоту
    element.style.height = `${element.scrollHeight}px`;

    requestAnimationFrame(() => {
      element.style.height = '0px';
    });
  });

  element.$_isCollapsing = true;

  const onEnd = () => {
    element.style.overflow = 'hidden';
    element.$_isCollapsing = false;
    element.removeEventListener('transitionend', onEnd);
  };

  element.addEventListener('transitionend', onEnd);
}

export function expand(element) {
  // scrollHeight -- нормальный размер, clientHeight -- фактический
  if (element.scrollHeight === element.clientHeight) return;

  element.style.height = `${element.scrollHeight}px`;

  const onEnd = () => {
    element.style.overflow = null;
    // Фикс, на случай если состояние переключается
    // быстрее чем анимации заканчиваются
    if (!element.$_isCollapsing) {
      element.style.height = null;
    }

    element.removeEventListener('transitionend', onEnd);
  };

  element.addEventListener('transitionend', onEnd);
}
