import React, { memo } from 'react';

import { IconInner } from '../../IconInner';

const CircleCheckIconInner = (props) => (
  <IconInner name="CircleCheckIcon" viewBox="0 0 24 24" {...props}>
    <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
    <path d="M22 4 12 14.01l-3-3" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
  </IconInner>
);

export const CircleCheckIcon = memo(CircleCheckIconInner);
