import { createSelector } from '@reduxjs/toolkit';

// eslint-disable-next-line import/prefer-default-export
export const selectAccounts = (state) => state.accounts;

export const selectAccountById = createSelector(
  selectAccounts,
  (_, id) => id,
  ({ byId }, accountId) => byId[accountId],
);
