import { selectSimilarityThresholds } from '@vlabs/shared/selectors/appSelectors';
import { selectListOptions } from '@vlabs/shared/selectors/listSelectors';

import { getState } from '@vlabs/shared/redux/store';

export const getGroupedMatchesByListsAndExternalId = ({ matches }) => {
  const listOptions = selectListOptions(getState());
  const similarityThresholds = selectSimilarityThresholds(getState());

  const externalIdMatches = [];
  const listIdMatches = [];

  for (let index = 0; index < matches?.length; index++) {
    const face = matches[index]?.result?.[0]?.face;
    if (!face) break;

    const filters = matches[index]?.filters;
    const similarity = matches[index]?.result?.[0]?.similarity || 0;
    // FIXME: вынести в хэлперы или утилиты функцию для конвертации из нормализованного значения в процент
    const similarityPercentage = Number(((similarity) * 100).toFixed(2));

    if (filters.external_ids?.length && similarity < similarityThresholds.medium) {
      externalIdMatches.push({ ...face, similarity: similarityPercentage });
    }

    if (filters?.list_id && similarity > similarityThresholds.medium) {
      const matchListLabel = listOptions.find((list) => list.value === filters.list_id).label;
      listIdMatches.push({ ...face, similarity: similarityPercentage, matchListLabel });
    }
  }

  return {
    externalIdMatches,
    listIdMatches,
  };
};
