/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */

import { loadProgressBar } from '@vlabs/axios-progress-bar';
import Axios from 'axios';
import { isEmpty as _isEmpty, cloneDeep } from 'lodash';
import '@vlabs/axios-progress-bar/dist/nprogress.css';
import qs from 'qs';

import { BaseLunaClient } from '../BaseLunaClient';
import { LunaError } from './LunaError';
import { LunaWSClient } from './LunaWSClient';
import { buildSchemaProxy } from './schemas';

export class LunaClient extends BaseLunaClient {
  constructor(baseUrl = '') {
    super({
      apiVersion: 6,
      baseUrl,
    });
    this.shs = buildSchemaProxy(this);
    loadProgressBar('', this.http);
    this.addInterceptors();
  }

  async version() {
    if (this.state.version) return this.state.version;
    const { data } = await this.http.get('/version', { baseURL: this.baseURL });

    return data['LUNA PLATFORM'];
  }

  addInterceptors() {
    this.http.interceptors.response.use(
      (response) => response,
      (error) => {
        if (
          error?.response?.data?.error_code
          && error?.response?.data?.desc
          && error?.response?.data?.detail
          && error?.response?.data?.link
        ) {
          return Promise.reject(new LunaError(error));
        }
        return Promise.reject(error);
      },
    );
  }

  get login() {
    return {
      login: ({ token }) => this.http.post('login', null, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
      logout: () => {
        try {
          return this.http.delete('login');
        } catch (error) {
          if (error instanceof LunaError) {
            if (error.error_code === 12013) {
              return undefined;
            }
          }
          throw error;
        }
      },
    };
  }

  get accounts() {
    return {
      getAll: async () => {
        const resp = await this.http.get('accounts');
        return {
          ...resp,
          data: {
            data: resp.data.accounts,
            meta: {
              count: resp.data.total_count,
            },
          },
        };
      },
    };
  }

  get account() {
    return {
      get: () => this.http.get('account', {
        headers: {
          'Exclude-Header': 'Www-Authenticate',
        },
      }),
      getCurrentSession: async () => {
        const cookies = document.cookie.split('; ');
        let lunaAccountToken = cookies.find((c) => c.startsWith('LUNA_AUTH_TOKEN'));
        if (!lunaAccountToken) return undefined;
        [, lunaAccountToken] = lunaAccountToken.split('Bearer ');
        let tokenData = lunaAccountToken.split('.')[1];
        tokenData = atob(tokenData);
        tokenData = JSON.parse(tokenData);
        tokenData = this.token(tokenData.tokenId).get();
        let account = this.account.get();
        [{ data: tokenData }, { data: account }] = await Promise.all([tokenData, account]);
        return {
          ...tokenData,
          ...account,
        };
      },
    };
  }

  get tokens() {
    return {
      getAll: () => this.http.get('tokens'),
      create: ({ payload, config, query }) => {
        const resp = this.http.post(
          `tokens${qs.stringify(query, { addQueryPrefix: true })}`,
          this.shs.tokens.post(payload),
          config,
        );

        return resp;
      },
    };
  }

  token(tokenId) {
    return {
      get: async () => {
        const resp = await this.http.get(`/tokens/${tokenId}`);
        return {
          ...resp,
          data: this.shs.tokens.get(resp.data),
        };
      },
    };
  }

  get credentialsVerifier() {
    return {
      verify: (credentials) => this.http.post('credentials_verifier', credentials),
    };
  }

  get sdk() {
    const prefix = 'sdk';
    return {
      estimate: (payload, params = {}) => {
        const formData = new FormData();
        formData.append('image', payload);
        return this.http.post(`/${prefix}`, formData, {
          headers: { 'Content-type': 'multipart/form-data' },
          params,
        });
      },
      estimateBody: (payload) => {
        return this.sdk.estimate(payload, {
          estimate_body_descriptor: 1,
          detect_body: 1,
          estimate_body_warp: 1,
        });
      },
      estimateFace: (payload) => {
        return this.sdk.estimate(payload, {
          estimate_face_descriptor: 1,
          detect_face: 1,
          estimate_face_warp: 1,
        });
      },
      estimateAggregateDescriptor: async (originalImage, compressedImage, detectionType) => {
        const params = {
          face: {
            estimate_face_descriptor: 1,
            aggregate_attributes: 1,
            image_type: 1,
          },
          body: {
            estimate_body_descriptor: 1,
            aggregate_attributes: 1,
            image_type: 2,
          },
        };
        const original = await fetch(originalImage).then((img) => img.blob());
        const compressed = await fetch(compressedImage).then((img) => img.blob());

        const formData = new FormData();
        formData.append('image', original, 'originalImage');
        formData.append('image', compressed, 'compressedImage');

        const { data: { aggregate_estimations } } = await this.http.post(`/${prefix}`, formData, {
          headers: { 'Content-type': 'multipart/form-data' },
          params: params[detectionType],
        });

        const descriptor = aggregate_estimations[detectionType].attributes.descriptor.sdk_descriptor;

        return descriptor;
      },
    };
  }

  get events() {
    const prefix = 'events';
    return {
      getAll: async (params) => {
        const { data: { events: rawEvents } } = await this.http.get(`/${prefix}`, { params });
        const events = rawEvents.map((event) => this.shs.events.read(event));
        return events;
      },
      get: async (eventId) => {
        const resp = await this.http.get(`/${prefix}/${eventId}`);
        return Object.assign(resp, { data: this.shs.events.read(resp.data) });
      },
      getStatistic: async () => {
        const { data: { stats } } = await this.http.post(`/${prefix}/statistic`, {
          targets: [
            { column: 'source', aggregator: 'group_by' },
            { column: 'event_id' },
          ],
        });
        return stats.map((source) => ({ value: source[0], label: source[0] }));
      },
      isExist: async (eventId) => {
        try {
          const response = await this.http.head(`/${prefix}/${eventId}`);
          if (response.status === 200) return true;
        } catch (e) {
          if (e?.response?.status === 404) return false;
          throw e;
        }
        return false;
      },
      getGenerateURL: (handlerId) => `${this.baseURLWithVersion}/handlers/${handlerId}/events`,
      getImageOriginURL: (imageOriginUrl) => {
        if (imageOriginUrl) {
          return `${this.baseURL}${imageOriginUrl}`;
        }
        return undefined;
      },
      emit: async (handlerId, file, payload) => {
        // смотреть events generate
        const formData = new FormData();
        formData.append('image', file);
        if (payload.policies) {
          const { policies } = payload;
          formData.append('policies', JSON.stringify(policies));
        }
        const { data: raw } = await this.http.post(`/handlers/${handlerId}/events`, formData, {
          headers: { 'Content-type': 'multipart/form-data' },
        });

        const response = {
          _raw: raw,
          events: raw.events.map((event) => this.shs.events.readEmitEvent(event)),
        };
        return response;
      },
    };
  }

  get lists() {
    const prefix = 'lists';
    return {
      getAll: (params) => this.http.get(`/${prefix}`, { params }),
      get: (listId) => this.http.get(`/${prefix}/${listId}`),
      create: (payload) => this.http.post(`/${prefix}`, payload),
      update: (listId, payload) => this.http.patch(`/${prefix}/${listId}`, payload),
      delete: (listId, params) => this.http.delete(`/${prefix}/${listId}`, { params }),
      deleteMany: (list_ids, params) => this.http.delete(`/${prefix}`, { params, data: { list_ids } }),
      updateFaces: (listId, payload) => this.http.patch(`/${prefix}/${listId}/faces`, payload),
    };
  }

  get iso() {
    return {
      check: async (value, params) => {
        const formData = new FormData();
        if (value instanceof FileList || Array.isArray(value)) {
          Array.from(value).forEach((file) => {
            formData.append('image', file, file.name);
          });
        } else if (typeof value === 'object') {
          formData.append('image', value, value.name);
        }

        const { data: raw } = await this.http.post('/iso', formData, {
          headers: { 'Content-type': 'multipart/form-data' },
          params: this.shs.iso.isoQS(params),
        });

        return { images: raw.images.map(this.shs.iso.read), raw };
      },
    };
  }

  get handlers() {
    const prefix = 'handlers';
    return {
      getPage: async (params) => {
        const { data } = await this.http.get(`/${prefix}`, { params });
        return data.map(this.shs.handlers.read);
      },
      getAll: async (params) => {
        const page_size = 100;
        const { data: { handlers_count: count } } = await this.handlers.getCount();

        const requests = [];
        for (let page = 1; page < Math.ceil(count / page_size) + 1; page += 1) {
          requests.push(this.handlers.getPage({ ...params, page_size, page }));
        }
        let result = await Promise.all(requests);
        result = result.reduce((acc, page) => [...acc, ...page], []);

        const isDynamicHandlerInResults = result.find(({ isDynamic, description }) => isDynamic && (description === 'clementine'));

        if (!isDynamicHandlerInResults) {
          const dynamicHandlers = await this.handlers.getPage({
            description: 'clementine',
            is_dynamic: 1,
          });

          if (dynamicHandlers.length !== 0) {
            result.push(dynamicHandlers[0]);
          } else {
            const { handler_id } = await this.handlers.create({
              description: 'clementine',
              isDynamic: true,
            });
            const newDynamicHandler = await this.handlers.get(handler_id);
            result.push(newDynamicHandler.data);
          }
        }

        return { data: result, meta: { count } };
      },
      getCount: () => this.http.get(`/${prefix}/count`),
      create: async (params) => {
        const { data } = await this.http.post(`/${prefix}`, this.shs.handlers.create(params));
        return data;
      },
      rawCreate: (params) => this.http.post(`/${prefix}`, params),
      update: (handlerId, params) => this.http.put(`/${prefix}/${handlerId}`, this.shs.handlers.create(params)),
      get: async (handlerId) => {
        const { data } = await this.http.get(`/${prefix}/${handlerId}`);
        return { data: this.shs.handlers.read(data) };
      },
      delete: (handlerId) => this.http.delete(`/${prefix}/${handlerId}`),
    };
  }

  get verifiers() {
    return {
      getPage: async (params) => {
        const { data } = await this.http.get('/verifiers', { params });
        const { data: { verifiers_count: count } } = await this.verifiers.getCount();
        return { data: data.map(this.shs.verifiers.read), meta: { count } };
      },
      get: async (verifierId) => {
        const { data } = await this.http.get(`/verifiers/${verifierId}`);
        return this.shs.verifiers.read(data);
      },
      getCount: () => this.http.get('/verifiers/count'),
      create: async (params) => {
        const { data } = await this.http.post('/verifiers', this.shs.verifiers.create(params));
        return data;
      },
      update: (verifierId, params) => this.http.put(`/verifiers/${verifierId}`, this.shs.verifiers.create(params)),
      delete: (verifierId) => this.http.delete(`/verifiers/${verifierId}`),
      verify: async (verifierId, { file, ...params }) => {
        const formData = new FormData();
        formData.append('image', file[0]);

        const { data: { images: result } } = await this.http.post(`/verifiers/${verifierId}/verifications`, formData, {
          headers: { 'Content-type': 'multipart/form-data' },
          params,
        });

        const addSampleUiUrl = (arr) => arr.map(({ sampleId, ...data }) => ({
          ...data,
          sampleUiUrl: this.samples.getURL('faces', sampleId),
        }));

        const uiResult = result.map(
          ({ detections: { face_detections } }) => face_detections?.map(this.shs.verifiers.readVerifyResults),
        ).flat();

        return {
          data: addSampleUiUrl(uiResult),
          rawData: result,
        };
      },
    };
  }

  get samples() {
    const prefix = 'samples';
    return {
      get: (sampleType, sampleId) => this.http.get(`/${prefix}/${sampleType}/${sampleId}`),
      getURL: (sampleType, sampleId) => {
        if (sampleId) {
          return `${this.baseURLWithVersion}/${prefix}/${sampleType}/${sampleId}`;
        }
        return undefined;
      },
      getImage: async (sampleType, sampleId) => {
        let response;
        const url = `${this.baseURLWithVersion}/${prefix}/${sampleType}/${sampleId}`;

        try {
          response = await Axios.get(url, { responseType: 'blob' });
        } catch (e) {
          if (e?.code === 'ECONNABORTED') throw e;
          if (e?.response?.status >= 500) throw e;
        }

        return response?.data;
      },
    };
  }

  get images() {
    const prefix = 'images';
    return {
      get: (imageId) => this.http.get(`/${prefix}/${imageId}`),
      getURL: (url) => {
        if (!url) return undefined;
        if (url?.startsWith('http') || url?.startsWith('//')) return url;
        return `${this.baseURL}${url}`;
      },
      getRescaleFactor: async (imgUrl) => {
        const resp = await this.http.head(imgUrl, {
          params: { with_meta: 1 },
          baseURL: '',
        });
        const rescale = resp.headers['x-luna-meta-rescale'];
        if (rescale !== undefined) {
          return parseFloat(rescale);
        } return 1.0;
      },
    };
  }

  get faces() {
    const prefix = 'faces';
    return {
      getAll: async (filters, params) => {
        const { data: { faces } } = await this.http.get(`/${prefix}`, { params: this.shs.faces.facesQS(filters) });
        const data = faces?.map((face) => this.shs.faces.read(face));

        if (params?.withCount) {
          const { data: { faces_count } } = await this.faces.count({ ...filters });

          return { data, meta: { count: faces_count } };
        }

        return { data };
      },
      get: async (faceId, params) => {
        const response = await this.http.get(`/${prefix}/${faceId}`, { params });
        response.data.uiAvatarURL = this.faces.getAvatarURL(response.data.avatar);
        return response;
      },
      isExist: async (faceId) => {
        try {
          const response = await this.http.head(`/${prefix}/${faceId}`);
          if (response.status === 200) return true;
        } catch (e) {
          if (e?.response?.status === 404) return false;
          throw e;
        }
        return false;
      },
      getAvatarURL: (avatar) => {
        if (avatar?.startsWith('http') || avatar?.startsWith('//')) {
          return avatar;
        } if (avatar) {
          return `${this.baseURL}${avatar}`;
        }
        return undefined;
      },
      count: (params) => this.http.get(`/${prefix}/count`, { params: this.shs.faces.facesCountQS(params) }),
      create: async (payload, params) => {
        const $payload = cloneDeep(payload);

        if (!_isEmpty(params)) {
          const { data: [{ attribute_id }] } = await this.extractor.extractAttributes(payload.sample_id, params);
          Object.assign($payload, { attribute: { attribute_id } });
        }

        return this.http.post(`/${prefix}`, this.shs.faces.create($payload));
      },
      patch: (faceId, payload) => this.http.patch(`/${prefix}/${faceId}`, payload),
      delete: (faceId) => this.http.delete(`/${prefix}/${faceId}`),
      deleteMany: (face_ids) => this.http.delete(`/${prefix}`, { data: { face_ids } }),
      attributes: (faceId) => {
        const attributesPrefix = 'attributes';
        return {
          get: () => this.http.get(`/${prefix}/${faceId}/${attributesPrefix}`),
          put: (params) => this.http.put(`/${prefix}/${faceId}/${attributesPrefix}`, params),
          getSamples: async () => {
            const { data: { samples } } = await this.http.get(`/${prefix}/${faceId}/${attributesPrefix}/samples`);

            // Возвращаем массив готовых семплов лица
            return samples.map((sampleId) => this.samples.getURL('faces', sampleId));
          },
        };
      },
    };
  }

  get detector() {
    const prefix = 'detector';
    return {
      detectFaces: (payload, params = {}) => {
        const formData = new FormData();
        formData.append('image', payload);
        return this.http.post(`/${prefix}`, formData, {
          headers: { 'Content-type': 'multipart/form-data' },
          params,
        });
      },
    };
  }

  get extractor() {
    const prefix = 'extractor';
    return {
      extractAttributes: (sampleIds, params) => this.http.post(`/${prefix}`,
        Array.isArray(sampleIds) ? sampleIds : [sampleIds],
        { params }),
    };
  }

  get matcher() {
    const prefix = 'matcher';
    return {
      faces: async (payload) => {
        const { data } = await this.http.post(`/${prefix}/faces`, this.shs.matcher.searchFiltersMapper(payload));
        const response = data.map((event) => this.shs.matcher.read(event));
        return response;
      },
      bodies: async (payload) => {
        const { data } = await this.http.post(`/${prefix}/bodies`, this.shs.matcher.searchFiltersMapper(payload));
        const response = data.map((event) => this.shs.matcher.read(event));
        return response;
      },
      raw: (payload) => this.http.post(`/${prefix}/raw`, payload),
    };
  }

  get tasks() {
    const prefix = 'tasks';
    return {
      exporter: (params) => this.http.post('/tasks/exporter', this.shs.tasks.exporter(params)),
      crossmatch: (params) => this.http.post(`/${prefix}/cross_match`, this.shs.tasks.crossmatch(params)),
      getAll: (params) => this.http.get(`/${prefix}`, { params }),
      getCount: (params) => this.http.get(`/${prefix}/count`, { params }),
      get: (taskId) => this.http.get(`/${prefix}/${taskId}`),
      cancel: (taskId) => this.http.patch(`/${prefix}/${taskId}`),
      delete: (taskId) => this.http.delete(`/${prefix}/${taskId}`),
      estimator: (params) => this.http.post(`/${prefix}/estimator`, this.shs.tasks.estimator(params)),
      createGCTask: (params) => this.http.post('/tasks/gc', this.shs.tasks.postGCTask(params)),
      result: (taskId, config) => {
        return this.http.get(`/${prefix}/${taskId}/result`, config);
      },
      getSubtasks: (taskId) => this.http.get(`/${prefix}/${taskId}/subtasks`),
    };
  }

  get objects() {
    const prefix = 'objects';
    return {
      get: (objectId) => this.http.get(`/${prefix}/${objectId}`),
      getUrl: (objectId) => `${this.baseURLWithVersion}/${prefix}/${objectId}`,
      create: async (payload, config) => {
        const resp = await this.http.post(`/${prefix}`, payload, config);
        resp.data = this.shs.objects.create(resp.data);
        return resp;
      },
      delete: (objectId) => this.http.delete(`/${prefix}/${objectId}`),
    };
  }

  get features() {
    return {
      get: () => this.http.get('/features'),
    };
  }

  get plugins() {
    return {
      get: () => this.http.get('/plugins'),
    };
  }

  get ws() {
    const prefix = 'ws';
    const that = this;

    if (!this.wsSocket) {
      that.wsSocket = new LunaWSClient(
        `${that.baseURLWithVersion}/${prefix}`,
        this,
      );
    }

    return this.wsSocket;
  }
}
