import React from 'react';

import validate from '@vlabs/shared/validators';
import { Control, GridRow, GridCol } from '@vlabs/uikit';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const TelegramNotificationForm = ({ fieldName }) => {
  const { t } = useTranslation();
  const prefix = fieldName ? `${fieldName}.` : '';
  const form = useFormContext();

  return (
    <>
      <GridRow>
        <GridCol>
          <Control.Input
            id="chat_id"
            label={t('handlers:policies.storage_policy.callbacks.callback.chat_id.label')}
            placeholder={t('handlers:policies.storage_policy.callbacks.callback.chat_id.placeholder')}
            {...form.register(`${prefix}chat_id`, {
              required: validate.required(),
              validate: validate.integer(),
            })}
            autoFocus
            data-testid="callback.chat_id"
            errors={form.formState.errors}
          />
        </GridCol>
      </GridRow>
      <GridRow>
        <GridCol>
          <Control.Input
            id="token"
            label={t('handlers:policies.storage_policy.callbacks.callback.token.label')}
            placeholder={t('handlers:policies.storage_policy.callbacks.callback.token.placeholder')}
            {...form.register(`${prefix}token`, {
              required: validate.required(),
            })}
            data-testid="callback.token"
            errors={form.formState.errors}
          />
        </GridCol>
      </GridRow>
      <GridRow>
        <GridCol>
          <Control.Input
            id="params.timeout"
            label={t('handlers:policies.storage_policy.callbacks.callback.params.timeout.label')}
            placeholder={t('handlers:policies.storage_policy.callbacks.callback.params.timeout.placeholder')}
            {...form.register(`${prefix}params.timeout`, {
              validate: validate.integer(),
            })}
            data-testid="callback.params.timeout"
            errors={form.formState.errors}
          />
        </GridCol>
      </GridRow>
    </>
  );
};
