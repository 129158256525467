import React from 'react';

import PropTypes from 'prop-types';

const Tab = ({ children }) => <div>{children}</div>;

Tab.propTypes = {
  children: PropTypes.node,
  // eslint-disable-next-line react/no-unused-prop-types
  name: PropTypes.string.isRequired,
};

Tab.defaultProps = {
  children: undefined,
};

export { Tab };
