import React, { useCallback } from 'react';

import { apiContainer } from '@vlabs/api-bindings';
import { ButtonWithPermissions } from '@vlabs/shared/components/button-with-permissions/ButtonWithPermissions';
import { permissions } from '@vlabs/shared/config';
import { useModal } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { FaceEditForm } from '../forms';

export const EditFaceInfoWidget = ({
  faceId,
  defaultValues,
  afterSubmit,
}) => {
  const { t } = useTranslation();
  const modal = useModal();

  const submitForm = useCallback(async ({ lists, ...params }) => {
    await apiContainer.lunaClient.faces.patch(faceId, params);

    const listsToAttach = lists?.filter((listId) => !defaultValues.lists.includes(listId));
    const listsToDetach = defaultValues?.lists.filter((listId) => !lists.includes(listId));
    await Promise.all([
      listsToAttach.map((listId) => apiContainer.lunaClient.lists.updateFaces(listId, {
        action: 'attach',
        face_ids: [faceId],
      })),
      listsToDetach.map((listId) => apiContainer.lunaClient.lists.updateFaces(listId, {
        action: 'detach',
        face_ids: [faceId],
      })),
    ]);
    if (afterSubmit) {
      // Для корректного обновления данных нужен маленький таймаут, не удалять
      setTimeout(() => {
        afterSubmit();
      }, 100);
    }
    modal.close();
    toast.success(`${t('Информация успешно обновлена')}.`);
  }, [afterSubmit, modal, t, faceId, defaultValues?.lists]);

  return (
    <>
      {modal.wrap(<FaceEditForm
        data-testid="faceEditForm"
        defaultValues={defaultValues}
        onSubmit={submitForm}
      />)}
      <ButtonWithPermissions
        data-testid="faceActionsEditButton"
        fullWidth
        onClick={modal.open}
        permissions={{ rules: [permissions.face.modification] }}
        variant="outlined"
      >
        {t('faces:редактировать данные')}
      </ButtonWithPermissions>
    </>
  );
};

EditFaceInfoWidget.propTypes = {
  afterSubmit: PropTypes.func,
  faceId: PropTypes.string,
  defaultValues: PropTypes.objectOf(PropTypes.any),
};

EditFaceInfoWidget.defaultProps = {
  afterSubmit: undefined,
  faceId: undefined,
  defaultValues: undefined,
};
