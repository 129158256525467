import { TASKS } from '@vlabs/api-bindings/src/luna-client/constants';

export const getDefaultValues = (sourceTypes, accountEmail) => ({
  description: accountEmail,
  content: {
    source: {
      source_type: sourceTypes.file,
      ftp: {
        reference: {
          port: 21,
          max_sessions: 8,
        },
        authorization: {
          user: 'anonymous',
          password: 'anonymous',
        },
        recursive: true,
        image_type: TASKS.ESTIMATOR.SOURCE.IMAGE_TYPE[0],
      },
      samba: {
        reference: {
          port: 445,
        },
        recursive: true,
        image_type: TASKS.ESTIMATOR.SOURCE.IMAGE_TYPE[0],
      },
      network_disk: {
        reference: {
          follow_links: false,
        },
        recursive: true,
        image_type: TASKS.ESTIMATOR.SOURCE.IMAGE_TYPE[0],
      },
      s3: {
        authorization: {
          signature_version: TASKS.ESTIMATOR.SOURCE.SIGNATURE_VERSION.s3v4,
        },
        recursive: true,
        save_origin: true,
        image_type: TASKS.ESTIMATOR.SOURCE.IMAGE_TYPE[0],
      },
      zip: {
        image_type: TASKS.ESTIMATOR.SOURCE.IMAGE_TYPE[0],
        recursive: true,
      },
    },
  },
  notification_policy: {
    task_callbacks: [],
  },
});
