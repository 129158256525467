import React, { useMemo } from 'react';

import { AttributesCell } from '@vlabs/pages/events/cells';
import { BaseSample } from '@vlabs/shared/components/sample/BaseSample';
import { GoToCell } from '@vlabs/shared/components/table';
import { EventInfoCell } from '@vlabs/shared/legacy-components/event-info-cell/EventInfoCell';
import { actionColumnProps, calcSimilarityPercent, calcBadgeType } from '@vlabs/shared/utils';
import { Table, Badge } from '@vlabs/uikit';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { selectIsTopMatchHiding } from '../selectors';
import st from './EventTable.module.sass';

const EventMatchCell = ({ row: { original } }) => {
  return (
    <div
      className={st.EventMatchCell}
      data-testid="eventTable.sampleCell"
    >
      {original?.uiFaceDetection !== null && (
        <BaseSample
          dataTip={i18n.t('events:проверьте настройки сценария')}
          imageUrl={original?.uiFaceDetection?.uiSampleURL}
          type="face"
        />
      )}
      {original?.uiBodyDetection !== null && (
        <div>
          {original?.uiFaceDetection !== null && <div className={st.Divider} />}
          <BaseSample
            dataTip={i18n.t('events:проверьте настройки сценария')}
            imageUrl={original?.uiBodyDetection?.uiSampleURL}
            type="body"
          />
        </div>
      )}
    </div>
  );
};

const EventMatchResultCell = ({ value: { image, type } }) => (
  <div
    className={st.EventMatchCell}
    data-testid="eventTable.matchCell"
  >
    {type === 'body' && (
      <BaseSample
        imageUrl={image}
        type="body"
      />
    )}
    {type === 'face' && (
      <BaseSample
        imageUrl={image}
        type="face"
      />
    )}
  </div>
);

const LocalEventInfoCell = ({ row }) => (
  <div className={st.InfoRow_wrapper}>
    <Badge
      className={st.SimilarityBadge}
      kind={calcBadgeType(row?.original?.similarity)}
      value={calcSimilarityPercent(row?.original?.similarity)}
    />
    <EventInfoCell {...row} />
  </div>
);

function EventTable({
  data,
  detections,
  matcherType,
  isTopMatchHiding,
  CustomTopPanel,
}) {
  const { t } = useTranslation();

  const buildEventLink = (matchResult) => {
    return matchResult.event_id ? `/events/${matchResult.event_id}` : undefined;
  };

  const columns = useMemo(() => {
    const tableCells = [
      {
        Header: t('search:table header.search result'),
        Cell: EventMatchCell,
        width: 50,
      },
      {
        Header: t('search:table header.details'),
        id: 'eventInfo',
        Cell: LocalEventInfoCell,
        width: 60,
      },
      {
        id: 'attributes',
        Cell: AttributesCell,
      },
      actionColumnProps({
        id: 'goToEvent',
        Cell: GoToCell,
        accessor: buildEventLink,
        width: 50,
      }),
    ];
    if (isTopMatchHiding) return tableCells;

    const topMatchCell = {
      Header: t('search:table header.search image'),
      accessor: (matchResult) => {
        const detectionImage = detections?.byId?.[matchResult.reference.id]?.image;
        const image = detectionImage || matchResult.referenceImage || matchResult.reference.id;
        return {
          image: matcherType === 'face' ? image : matchResult.referenceBodyImage || image,
          type: matcherType,
        };
      },
      Cell: EventMatchResultCell,
      minWidth: 50,
      width: 50,
      maxWidth: 50,
    };
    tableCells.splice(1, 0, topMatchCell);
    return tableCells;
    // FIXME: пересмотреть зависимости
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, isTopMatchHiding]);

  return (
    <Table
      columns={columns}
      CustomTopPanel={CustomTopPanel}
      data={data}
      rowProps={(row) => ({ 'data-event-id': row.original.event_id })}
    />
  );
}

export default connect(
  (state) => ({
    isTopMatchHiding: selectIsTopMatchHiding(state),
  }),
)(EventTable);
