import React from 'react';

import { POLICIES } from '@vlabs/api-bindings/src/luna-client/handlers/constants';
import { Control, GridRow, GridCol } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

function TTLSelectField({ fieldName }) {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const snakeCaseFieldName = fieldName.replace(/([A-Z])/g, '_$1').toLowerCase();

  return (
    <>
      <GridRow>
        <GridCol>
          <div className="Subtitle-1">{t(`handlers:policies.storage_policy.${snakeCaseFieldName}.ttl.label`)}</div>
        </GridCol>
      </GridRow>
      <GridRow>
        <GridCol>
          <Control.Select
            control={control}
            isClearable
            name={`${fieldName}.ttl`}
            options={POLICIES.TTL.raw}
            placeholder={t(`handlers:policies.storage_policy.${snakeCaseFieldName}.ttl.placeholder`)}
          />
        </GridCol>
      </GridRow>
    </>

  );
}

TTLSelectField.propTypes = {
  fieldName: PropTypes.string.isRequired,
};

export { TTLSelectField };
