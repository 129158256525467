/* eslint-disable react/prop-types */
import React from 'react';

import { SearchIcon } from '@vlabs/icons';
import { LinkWithPermissions } from '@vlabs/shared/components/link-with-permissions/LinkWithPermissions';
import { permissions } from '@vlabs/shared/config';
import { routes } from '@vlabs/shared/navigation/routes';
import PropTypes from 'prop-types';
import qs from 'qs';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';

import st from './SearchLink.module.sass';

export function SearchLink({
  event,
}) {
  const { t } = useTranslation();
  if (!event) return <div className={st.Root} />;
  const referenceType = event?.eventType || 'event';

  return (
    <div className={st.Root}>
      <div>{t('events:find similar')}</div>

      <LinkWithPermissions
        className={st.EventLink}
        permissions={{ rules: [permissions.event.matching, permissions.event.matching], method: 'allof' }}
        to={generatePath(`${routes.search}?${qs.stringify({ references: [{
          id: referenceType === 'event' ? event.event_id : event.face_id,
          type: referenceType,
          referenceType,
          candidateOrigin: 'events',
        }] })}`)}
      >
        {t('events:events')}
        &nbsp;
        <SearchIcon className={st.Icon} />
      </LinkWithPermissions>

      {((referenceType === 'event' && event?.face_detections?.length) || (referenceType === 'face' && event?.face_id)) && (
        <LinkWithPermissions
          className={st.EventLink}
          permissions={{ rules: [permissions.face.view, permissions.face.matching], method: 'allof' }}
          to={generatePath(`${routes.search}?${qs.stringify({ references: [{
            id: referenceType === 'event' ? event.event_id : event.face_id,
            type: referenceType,
            referenceType,
            candidateOrigin: 'faces',
          }] })}`)}
        >
          {t('events:faces')}
          <SearchIcon className={st.Icon} />
        </LinkWithPermissions>
      )}
    </div>
  );
}

SearchLink.propTypes = {
  event: PropTypes.shape({}),
};
SearchLink.defaultProps = {
  event: {},
};
