/* eslint-disable react/prop-types */
import React from 'react';

import { CircleInfoIcon } from '@vlabs/icons';
import { SampleWithActions } from '@vlabs/shared/components/sample/SampleWithActions';
import { permissions } from '@vlabs/shared/config';
import { useTranslation } from 'react-i18next';

import './SampleCell.sass';

export const SampleCell = ({ row: { original } }) => {
  const { t } = useTranslation();

  if (original?.isDeleted) {
    return (
      <div className="SampleCell__DeletedPhoto">
        <CircleInfoIcon className="StaticIcon" />
        {t('events:match table.deleted')}
      </div>
    );
  }

  if (original.type === 'face') {
    return (
      <SampleWithActions
        data-testid="sampleImage"
        imageUrl={original.uiAvatarURL}
        permissions={{ rules: [permissions.face.view, permissions.faceSample.view], method: 'allof' }}
        type="face"
      />
    );
  }

  return (
    <>
      {original.face_detections && <SampleWithActions data-testid="sampleImage" imageUrl={original?.uiFaceDetection?.uiSampleURL} type="face" />}
      {original.body_detections && <SampleWithActions data-testid="sampleImage" imageUrl={original?.uiBodyDetection?.uiSampleURL} type="body" />}
    </>
  );
};
