import React from 'react';

import { apiContainer } from '@vlabs/api-bindings';
import { selectSimilarityThresholds } from '@vlabs/shared/selectors/appSelectors';

import { getState } from '@vlabs/shared/redux/store';

import MatchList from '../components/MatchList';
import { getGroupedMatchesByListsAndExternalId } from '../requests/getGroupedMatchesByListsAndExternalId';

export const buildMatchList = async ({ formValues, detections, sampleId }) => {
  const similarityThresholds = selectSimilarityThresholds(getState());

  const candidates = [];
  const references = detections.filter(({ id }) => id === sampleId[0]).map(({ sdk_descriptor, id }) => ({
    data: sdk_descriptor, type: 'sdk_descriptor', id,
  }));

  if (formValues?.external_id) {
    candidates.push({
      filters: { origin: 'faces', external_ids: formValues.external_id },
      limit: 1,
    });
  }

  if (formValues?.lists?.length > 0) {
    formValues.lists.forEach(({ value }) => candidates.push({
      filters: { origin: 'faces', list_id: { value } },
      limit: 1,
      threshold: similarityThresholds.medium,
    }));
  }

  const [{ matches }] = await apiContainer.lunaClient.matcher.faces({ references, candidates });
  const { externalIdMatches, listIdMatches } = getGroupedMatchesByListsAndExternalId({ matches });

  if (!externalIdMatches?.length && !listIdMatches?.length) return null;

  return <MatchList externalIdMatches={externalIdMatches} listIdMatches={listIdMatches} />;
};
