// В данном случае окей вызывать хук по условию,
// потому что пропы control, isAsync и isCreatable
// никогда не меняется динамически между рендерами,
// они либо всегда есть либо их никогда нет
/* eslint-disable react-hooks/rules-of-hooks */
import React, { forwardRef } from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useStateManager } from 'react-select';
import { useAsync } from 'react-select/async';
import BaseSelect from 'react-select/base';
import { useCreatable } from 'react-select/creatable';

import { mergeRefs } from '../../utils/mergeRefs';
import { ErrorMessage } from '../error-message/ErrorMessage';
import { getError, warnLabelWithoutId } from '../utils';
import { compareOptions } from './utils';

import './Select.sass';

const $sortOptions = (sort, options) => {
  if (sort === true) {
    return options.sort(compareOptions);
  }
  if (typeof sortOptions === 'function') {
    return options.sort(sort);
  }

  return options;
};

const Select = forwardRef(({
  isCreatable,
  isAsync,
  control,
  rules,
  formatCreateLabel,
  ...otherProps
}, externalRef) => {
  const $props = { ...otherProps };

  const { t } = useTranslation();
  const defaultFormatCreateLabel = ((value) => t('uikit:control.select.создать опцию', { value }));

  let controllerRef;
  if (control) {
    const { field } = useController({
      name: $props.name,
      control,
      defaultValue: $props.defaultValue,
      rules,
    });

    $props.onChange = field.onChange;
    $props.onBlur = field.onBlur;
    $props.value = field.value;
    controllerRef = field.ref;
  }

  // Послойно пропускаем пропы через разные обработчики
  const $props1 = isAsync ? useAsync($props) : $props;
  const $props2 = useStateManager($props1);
  const $props3 = isCreatable ? useCreatable({
    ...$props2,
    formatCreateLabel: formatCreateLabel ?? defaultFormatCreateLabel,
  }) : $props2;

  const {
    id,
    name,
    value,
    defaultValue,
    options,
    onChange,
    onBlur,
    disabled,
    inputStyle,
    isMulti,
    className,
    sortOptions,
    errors,
    hasError,
    errorMessage,
    label,
    ...restProps
  } = $props3;

  const error = getError(errors, name);

  if (label && !id) {
    warnLabelWithoutId(label, name);
  }

  return (
    <>
      {label && (
        <label
          className="Input__Label"
          htmlFor={id}
        >
          {label}
        </label>
      )}

      <BaseSelect
        className={cn(
          className,
          {
            Select: true,
            Select_error: hasError || error,
            [`Select_${inputStyle}`]: inputStyle,
          },
        )}
        classNamePrefix="Select"
        isDisabled={disabled}
        isMulti={isMulti}
        name={name}
        noOptionsMessage={() => t('uikit:control.select.сообщение об отсутствии опций')}
        onBlur={onBlur}
        onChange={onChange}
        options={$sortOptions(sortOptions, options)}
        placeholder={t('uikit:control.select.плейсхолдер')}
        ref={mergeRefs(externalRef, controllerRef)}
        value={value}
        {...restProps}
      />

      <ErrorMessage error={errorMessage ? { message: errorMessage } : error} />
    </>
  );
});

Select.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  control: PropTypes.objectOf(PropTypes.any),
  value: PropTypes.any,
  defaultValue: PropTypes.any,
  options: PropTypes.arrayOf(PropTypes.any),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  rules: PropTypes.any,
  errors: PropTypes.object,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  inputStyle: PropTypes.oneOf([
    'default',
    'implicit',
  ]),
  isMulti: PropTypes.bool,
  className: PropTypes.string,
  sortOptions: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.func,
  ]),
  isCreatable: PropTypes.bool,
  isAsync: PropTypes.bool,
  defaultOptions: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.array,
  ]),
  label: PropTypes.string,
  formatCreateLabel: PropTypes.func,
};

Select.defaultProps = {
  id: undefined,
  name: undefined,
  control: undefined,
  value: undefined,
  defaultValue: undefined,
  options: [],
  onChange: undefined,
  disabled: undefined,
  rules: undefined,
  errors: undefined,
  hasError: undefined,
  errorMessage: undefined,
  inputStyle: 'default',
  isMulti: false,
  className: undefined,
  sortOptions: undefined,
  isCreatable: undefined,
  isAsync: undefined,
  defaultOptions: true,
  label: undefined,
  formatCreateLabel: undefined,
};

Select.displayName = 'Select';

export { Select };
