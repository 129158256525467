import React from 'react';

import st from './Loader.module.sass';

// FIXME: перенести в кит
export default function Loader() {
  return (
    <span className={st.Loader} />
  );
}
