import React from 'react';

import { ArrowInCircleRightIcon } from '@vlabs/icons';
import { LinkButtonWithPermissions } from '@vlabs/shared/components/link-button-with-permissions/LinkButtonWithPermissions';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

const GoToCell = ({
  value,
  permissions,
  buttonProps,
}) => {
  if (value === undefined) return null;

  return (
    <LinkButtonWithPermissions
      as={RouterLink}
      data-testid="GoToCell"
      icon={<ArrowInCircleRightIcon />}
      kind="primary"
      permissions={permissions}
      round
      title={i18n.t('перейти')}
      to={value}
      variant="dimmed"
      {...(buttonProps || {})}
    />
  );
};

GoToCell.propTypes = {
  value: PropTypes.string,
  buttonProps: PropTypes.object,
  permissions: PropTypes.shape({
    rules: PropTypes.arrayOf(PropTypes.string).isRequired,
    method: PropTypes.oneOf(['oneof', 'allof']),
  }),
};

GoToCell.defaultProps = {
  value: undefined,
  buttonProps: undefined,
  permissions: {},
};

export { GoToCell };
