import React from 'react';

import { MASK_OPTIONS } from '@vlabs/api-bindings/src/constants';
import { maskImages } from '@vlabs/shared/assets/images';
import { useTranslation, Trans } from 'react-i18next';

import { Card } from './Card';
import st from './Card.module.sass';

const MASK_IMAGES = {
  1: maskImages.NoMask,
  2: maskImages.Mask,
  3: maskImages.MouthOcclusion,
};

export const MaskCard = ({ mask }) => {
  const { t } = useTranslation();

  const maskTitle = MASK_OPTIONS[mask]?.label;
  const maskImg = MASK_IMAGES[mask];
  return (
    <Card
      back={(
        <div className={st.Tooltip}>
          <Trans i18nKey="events:карточка.маска" values={{ mask: maskTitle }} />
        </div>
      )}
      front={(
        <div className={st.Root}>
          <div
            className={st.ImageBlock}
            data-testid="faceAttributesCell.mask"
          >
            {t('mask')}
            <img alt="mask icon" className={st.Image} src={maskImg} />
            {maskTitle}
          </div>
        </div>
      )}
    />
  );
};
