import { Schema, get } from '../../../json-transform';
import { checkSchema } from '../../faceQuality/checkSchema';

export const eventDetection = new Schema({
  samples: {
    face: {
      detection: {
        face_quality: {
          status: get('samples.face.detection.face_quality.status'),
          checks: get('samples.face.detection.face_quality.checks').asArrayOf(checkSchema),
        },
        rect: get('samples.face.detection.rect'),
      },
    },
  },
  filename: get('filename'),
});

export const readEmitEvent = (event) => {
  const detections = event?.detections?.map((detection) => eventDetection(detection)) || null;

  return {
    ...event,
    detections,
  };
};
