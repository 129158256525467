/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import {
  Page, Control, Divider,
} from '@vlabs/uikit';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import st from './PlansForm.module.sass';

function PlansForm({
  onSubmit,
  defaultValues,
}) {
  const { t } = useTranslation();
  const {
    handleSubmit, register, formState: { errors },
  } = useForm({ defaultValues });

  const title = defaultValues ? t('plans:обновить план') : t('plans:создание плана');

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Page className={st.Root} title={title}>
        <Divider />

        <label htmlFor="role">
          {t('plans:plan name')}
          <Control.Input
            {...register('name', {
              required: t('валидация.обязательно для заполнения'),
            })}
            errors={errors}
          />
        </label>

        <Divider small />
        <label htmlFor="role">
          {t('plans:plan')}
          <Control.FileInput
            {...register('image', {
            })}
            errors={errors}
          />
        </label>

        <Divider small />

        <Control.Button fullWidth type="submit">
          {t('форма.submit')}
        </Control.Button>
      </Page>
    </form>
  );
}

export default PlansForm;
