import { Schema, get } from '../../../json-transform';

export const readVerifyResults = new Schema({
  sampleId: get('sample.face.sample_id').ifNull(undefined).asString(),
  status: get('verifications[0].status').as((v) => (v ? 'success' : 'failed')),
  similarity: get('verifications[0].similarity').asFloat().ifNaN(undefined),
  faceId: get('verifications[0].face.face_id').ifEmptyString(undefined).asString(),
  eventId: get('verifications[0].event.event_id').ifEmptyString(undefined).asString(),
  externalId: get('verifications[0].face.external_id').ifEmptyString(undefined).asString(),
  attributeId: get('verifications[0].attribute.attribute_id').ifEmptyString(undefined).asString(),
});
