import FileSaver from 'file-saver';
import i18next from 'i18next';

export const STATUS_MAP = {
  0: 'failed',
  1: 'passed',
  2: 'unknown',
};

const valueByType = {
  boolean: (v) => v,
  percent: (v) => (v === undefined ? undefined : (v * 100).toFixed(2)),
  degrees: (v) => v?.toFixed(2),
  pixels: (v) => v?.toFixed(),
  Mb: (v) => v?.toFixed(),
  number: (v) => (v === 0 ? 0 : v?.toFixed(4)),
  default: (v) => v?.label || i18next.t('checks:no result value'),
};

const thresholdByType = {
  enum: (v) => ({ value: v.map(({ label }) => label).join(', ') }),
  percent: ({ min, max }) => ({ min: (min * 100).toFixed(2), max: (max * 100).toFixed(2) }),
  min: (v) => v,
  minMax: (v) => v,
  bool: (v) => ({ value: v }),
  default: (v) => v,
};

export const checksValueMapperByType = (values) => {
  const { name, object_value, threshold_value, valueType, thresholdType } = values;
  const checkName = i18next.t(`checks:checks.${name}`);
  const checkValue = i18next.t('checks:check result', { value: valueByType[valueType](object_value), context: valueType });
  const tooltipText = i18next.t('checks:check tooltip', { context: thresholdType, ...thresholdByType[thresholdType](threshold_value) });
  return { ...values, checkName, checkValue, tooltipText };
};

export const onDownloadResults = (results, name) => {
  const blob = new Blob([JSON.stringify(results, null, 2)], { type: 'application/json' });
  FileSaver.saveAs(blob, name);
};
