import React, { memo } from 'react';

import { IconInner } from '../../IconInner';

const UploadIconInner = (props) => (
  <IconInner name="UploadIcon" viewBox="0 0 24 24" {...props}>
    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4m14-7-5-5-5 5m5-5v12" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
  </IconInner>
);

export const UploadIcon = memo(UploadIconInner);
