/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';

import { StopIcon, CircleCheckIcon, CircleInfoIcon, DownloadIcon, CircleQuestionIcon } from '@vlabs/icons';
import { selectAppServices } from '@vlabs/shared/selectors/appSelectors';
import { Control } from '@vlabs/uikit';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { STATUS_MAP } from '../utils';
import st from './CheckTitle.module.sass';

const CheckTitle = ({
  status,
  notEstimatedChecks,
  onDownloadResults,
  title,
  isSmallSize,
  className,
  lpVersion,
}) => {
  const { t } = useTranslation();

  const { major, minor, patch } = lpVersion;
  const API_DOCUMENTATION_URL = `https://docs.visionlabs.ai/luna/v.${major}.${minor}.${patch}/ReferenceManuals/APIReferenceManual.html#operation/checkISO`;
  const statusResult = STATUS_MAP[status];

  const handleDownloadClick = (e) => {
    e.stopPropagation();
    onDownloadResults();
  };

  return (
    <div className={cn(
      className,
      st.Title,
      {
        [st.Title_sm]: isSmallSize,
      })}
    >
      <div className={st.Title__Wrapper}>
        <div className={cn(
          st.Title,
          {
            [st.Title_error]: statusResult === 'failed',
            [st.Title_success]: statusResult === 'passed',
            [st.Title_unknown]: statusResult === 'unknown',
          })}
        >
          {statusResult === 'failed' && <StopIcon className={st.Title__Icon} />}
          {statusResult === 'passed' && <CircleCheckIcon className={st.Title__Icon} />}
          {statusResult === 'unknown' && <CircleQuestionIcon className={st.Title__Icon} />}
          <span>{title || t(`checks:status.${status}`)}</span>
        </div>

        {onDownloadResults && (
          <Control.RoundButton
            data-testid="downloadIcon"
            icon={<DownloadIcon />}
            kind="primary"
            onClick={handleDownloadClick}
            title={t('checks:downloadIcon.title')}
            variant="dimmed"
          />
        )}
      </div>

      {notEstimatedChecks.length > 0 && (
        <>
          <div className={st.Subtitle}>
            {t('checks:error.skippedCheckCounter', { count: notEstimatedChecks?.length })}
          </div>

          <div className={st.InfoText}>
            <CircleInfoIcon className={st.InfoIcon} />
            <div>
              {t('checks:warp.tooltip')}
              <a
                href={API_DOCUMENTATION_URL}
                rel="noopener noreferrer"
                target="_blank"
              >
                {t('checks:warp.link')}
              </a>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

CheckTitle.propTypes = {
  status: PropTypes.number.isRequired,
  notEstimatedChecks: PropTypes.arrayOf(PropTypes.any),
  onDownloadResults: PropTypes.func,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  isSmallSize: PropTypes.bool,
  className: PropTypes.string,
  lpVersion: PropTypes.shape({
    major: PropTypes.number,
    minor: PropTypes.number,
    patch: PropTypes.number,
  }),
};

CheckTitle.defaultProps = {
  notEstimatedChecks: [],
  onDownloadResults: undefined,
  title: undefined,
  isSmallSize: false,
  className: '',
  lpVersion: undefined,
};

export default connect((state) => ({
  lpVersion: selectAppServices(state)?.lunaPlatform?.versionNum,
}))(CheckTitle);
