import React from 'react';

import { SlidersIcon, PersonIcon, FolderIcon, ListIcon, VideoIcon } from '@vlabs/icons';
import HandlerLink from '@vlabs/shared/legacy-components/handler-link/HandlerLink';
import InfoRow from '@vlabs/shared/legacy-components/info-row/InfoRow';
import ListLink from '@vlabs/shared/legacy-components/list-link/ListLink';
import StreamLink from '@vlabs/shared/legacy-components/stream-link/StreamLink';
import { routes } from '@vlabs/shared/navigation/routes';
import { Control } from '@vlabs/uikit';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { generatePath } from 'react-router-dom';

import st from './AdditionalCell.module.sass';

export const AdditionalCell = ({ row: { original } }) => {
  let externalIdComponent = null;

  const {
    face_id,
    event_id,
    source,
    handler_id,
    user_data,
    lists,
    external_id,
    stream_id,
  } = original;

  if (external_id) {
    externalIdComponent = (
      <Control.Link
        data-testid={`ExternalIdLink${external_id}`}
        to={generatePath(routes.externalId.read, { externalId: external_id })}
      >
        {external_id}
      </Control.Link>
    );
  }

  return (
    <>
      {face_id && (
        <>
          <InfoRow
            className={st.RowInfoWidth}
            data-testid="eventTable.userData"
            icon={<FolderIcon className="StaticIcon" />}
            title={i18next.t('information')}
            value={user_data}
          />
          {!!lists?.length && (
            <InfoRow
              className={st.RowInfoWidth}
              data-testid="eventTable.lists"
              icon={<ListIcon className="StaticIcon" />}
              title={`${i18next.t('списки')} (${lists?.length})`}
              value={<ListLink lists={lists} />}
            />
          )}
          <InfoRow
            className={st.RowInfoWidth}
            data-testid="eventTable.externalId"
            icon={<PersonIcon className="StaticIcon" />}
            title={i18next.t('external id')}
            value={externalIdComponent}
          />
        </>
      )}
      {event_id && (
        <>
          <InfoRow
            className={st.RowInfoWidth}
            data-testid="eventTable.handlerId"
            icon={<SlidersIcon className="StaticIcon" />}
            title={i18next.t('handler')}
            value={<HandlerLink handlerId={handler_id} />}
          />
          {source && (
            <InfoRow
              className={st.RowInfoWidth}
              data-testid="eventTable.source"
              title={i18next.t('source')}
              value={source}
            />
          )}
          {stream_id && (
            <InfoRow
              columnDirection
              data-testid="eventTable.videoStream"
              icon={<VideoIcon className="StaticIcon" />}
              title={i18next.t('video stream')}
              value={<StreamLink streamId={stream_id} />}
            />
          )}
        </>
      )}
    </>
  );
};

AdditionalCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      face_id: PropTypes.string,
      event_id: PropTypes.string,
      stream_id: PropTypes.string,
      source: PropTypes.string,
      handler_id: PropTypes.string,
      user_data: PropTypes.string,
      external_id: PropTypes.string,
      lists: PropTypes.arrayOf(PropTypes.any),
    }),
  }),

};

AdditionalCell.defaultProps = {
  row: {
    original: {
      face_id: undefined,
      event_id: undefined,
      stream_id: undefined,
      source: undefined,
      handler_id: undefined,
      user_data: undefined,
      external_id: undefined,
      lists: [],
    },
  },
};
