import { useState, useEffect } from 'react';

const checkImage = (url) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => resolve(true);
    img.onerror = () => resolve(false);
    img.src = url;
  });
};

export const useImageCheck = (urls) => {
  const [error, setError] = useState({});

  useEffect(() => {
    const checkUrls = async () => {
      const urlEntries = Object.entries(urls);
      const results = await Promise.all(
        urlEntries.map(([key, url]) => checkImage(url).then((result) => [key, result])),
      );
      const newError = results.reduce((acc, [key, result]) => {
        if (!result) acc[key] = true;
        if (result) acc[key] = false;
        return acc;
      }, {});
      setError(newError);
    };

    checkUrls();
  }, [urls]);

  return error;
};
