import React, { useState, useEffect, useCallback } from 'react';

import { apiContainer } from '@vlabs/api-bindings';
import { PersonIcon } from '@vlabs/icons';
import { PageWithSidebar } from '@vlabs/shared/components/page-with-sidebar';
import { useIntersectionObserver } from '@vlabs/shared/hooks';
import { FaceCard } from '@vlabs/shared/legacy-components/face-card/FaceCard';
import Loader from '@vlabs/shared/legacy-components/loader/Loader';
import { CreateFaceWidget } from '@vlabs/shared/widgets/faces';
import { useTranslation } from 'react-i18next';

import st from './FacesPage.module.sass';
import { FacesFilterForm } from './forms/FacesFilterForm';

const INITIAL_PAGE_NUMBER = 1;
const INITIAL_FACE_COUNT = 0;
const REQUESTED_PAGE_SIZE = 20;

function FacesPage() {
  const { t } = useTranslation();

  const [fetching, setFetching] = useState(false);
  const [facesData, setFacesData] = useState([]);
  const [totalFaceCount, setTotalFaceCount] = useState(INITIAL_FACE_COUNT);
  const [currentPage, setCurrentPage] = useState(INITIAL_PAGE_NUMBER);
  const [filters, setFilters] = useState();

  const fetchFaces = useCallback(async () => {
    setFetching(true);
    try {
      const { data, meta } = await apiContainer.lunaClient.faces.getAll(
        { page_size: REQUESTED_PAGE_SIZE, page: currentPage, ...filters },
        { withCount: true },
      );
      setFacesData([...facesData, ...data]);
      setTotalFaceCount(meta.count);
    } catch (error) {
      setFacesData([]);
      setTotalFaceCount(INITIAL_FACE_COUNT);
      throw error;
    } finally {
      setFetching(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, filters]);

  useEffect(() => {
    if (!fetching) fetchFaces();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, totalFaceCount, filters]);

  const handleScroll = useCallback(() => {
    if (!fetching && facesData.length < totalFaceCount) {
      setCurrentPage((prevState) => prevState + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facesData, totalFaceCount]);

  const ref = useIntersectionObserver(handleScroll);

  const afterSubmit = () => {
    setTotalFaceCount(INITIAL_FACE_COUNT);
    setCurrentPage(INITIAL_PAGE_NUMBER);
    setFacesData([]);
  };

  const onSubmit = (value) => {
    setFilters(value);
    setCurrentPage(INITIAL_PAGE_NUMBER);
    setTotalFaceCount(INITIAL_FACE_COUNT);
    setFacesData([]);
  };

  return (
    <PageWithSidebar rigthSidebar={<FacesFilterForm onSubmit={onSubmit} />}>
      <div className={st.Content}>
        <div className={st.Header}>
          <h4>{t('faces:page.title', { facesCount: totalFaceCount })}</h4>
          <CreateFaceWidget afterSubmit={afterSubmit} />

        </div>
        <div className={st.Body}>
          {facesData?.map((face) => (
            <FaceCard
              face={face}
              key={face.face_id}
            >
              {face?.external_id && (
                <div className={st.InfoRow} title={t('external id')}>
                  <div className={st.Icon}><PersonIcon className="StaticIcon" /></div>
                  <span className={st.InfoRowValue}>{face.external_id}</span>
                </div>
              )}
            </FaceCard>
          ))}

          {!facesData?.length && !fetching && (
            <div className={st.EmptyPage}>
              {t('no data available')}
            </div>
          )}

          <div className={st.Loader} ref={ref}>
            {fetching && <Loader />}
          </div>
        </div>
      </div>
    </PageWithSidebar>
  );
}

export default FacesPage;
