import { Schema, get } from '../../../json-transform';
import { getCommonEventsFiltersQS } from './getEventsQS';

const getWsFiltersQS = new Schema({
  matching_candidates_labels: get('top_matching_candidates_label').ifEmptyString(undefined),
  object_similarity__gte: get('top_similar_object_similarity__gte').asFloat().toNormalizedPercent().ifNaN(undefined),
  object_similarity__lt: get('top_similar_object_similarity__lt').asFloat().toNormalizedPercent().ifNaN(undefined),
});

const getMetaFilters = ({ meta = [] }) => {
  const updatedMetaObject = {};
  meta.forEach((m) => {
    updatedMetaObject[`meta.${m.key}__${m.operator.value}:${m.type.value}`] = m.value;
  });
  return updatedMetaObject;
};

export const getWsEventsQS = (obj) => {
  return {
    ...getMetaFilters(obj),
    ...getWsFiltersQS(obj),
    ...getCommonEventsFiltersQS(obj),
  };
};
