import { createSelector } from '@reduxjs/toolkit';

import { SLICE_NAME } from '.';

export const selectSlice = (state) => {
  if (!state.vlAccess) return {};

  const { [SLICE_NAME]: vlaccess } = state;

  return vlaccess;
};
export const selectState = createSelector(
  selectSlice,
  ({ state }) => state,
);
export const selectPageData = createSelector(
  selectSlice,
  (slice) => slice.pageData,
);
export const selectPageParameters = createSelector(
  selectSlice,
  (slice) => ({
    pageSizeOptions: slice.pageSizeOptions,
    pageSize: slice.pageSize,
    pageIndex: slice.pageIndex,
  }),
);
export const selectSourceOptions = createSelector(
  selectSlice,
  ({ sourceOptions }) => {
    return sourceOptions || [];
  },
);
