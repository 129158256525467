import React, { memo } from 'react';

import { IconInner } from '../../IconInner';

const EditIconInner = (props) => (
  <IconInner name="EditIcon" viewBox="0 0 24 24" {...props}>
    <path d="M17 3a2.827 2.827 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
  </IconInner>
);

export const EditIcon = memo(EditIconInner);
