import React from 'react';

import { MULTIFACE_POLICY_OPTIONS, MASK_OPTIONS_STRICT, DEEPFAKE_STATE_OPTIONS_STRICT } from '@vlabs/api-bindings/src/constants';
import { LIVENESS_OPTIONS_STRICT } from '@vlabs/api-bindings/src/luna-client/constants';
import validate from '@vlabs/shared/validators';
import { Control, SettingsItemWrapper } from '@vlabs/uikit';
import { useFormContext, useWatch, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const FiltersSubForm = () => {
  const { t } = useTranslation();
  const { register, control } = useFormContext();
  const { errors } = useFormState();

  return (
    <>
      <SettingsItemWrapper title={t('handlers:filters.multifacePolicy')}>
        <div className="MatchPolicy_Dropdown">
          <Control.Select
            control={control}
            name="filters.multifacePolicy"
            options={MULTIFACE_POLICY_OPTIONS.raw}
          />
        </div>
      </SettingsItemWrapper>

      <SettingsItemWrapper
        contentClassName="MatchPolicy_Input"
        title={t('handlers:filters.faceDescriptorScoreThreshold')}
      >
        <Control.Input
          {...register('filters.faceDescriptorScoreThreshold', {
            min: validate.gte(0),
            max: validate.lte(1),
            validate: validate.number(),
          })}
          errors={errors}
        />
      </SettingsItemWrapper>

      <SettingsItemWrapper
        data-tooltip-content={!useWatch({ name: 'estimate.mask' }) && t('handlers:filters.masks.tooltip')}
        disabled={!useWatch({ name: 'estimate.mask' })}
        title={t('handlers:filters.masks.title')}
      >
        <div className="MatchPolicy_Dropdown">
          <Control.Select
            control={control}
            disabled={!useWatch({ name: 'estimate.mask' })}
            isMulti
            name="filters.masks"
            options={MASK_OPTIONS_STRICT.raw}
          />
        </div>
      </SettingsItemWrapper>

      <SettingsItemWrapper
        contentClassName="MatchPolicy_Input"
        data-tooltip-content={!useWatch({ name: 'estimate.headPose' }) && t('handlers:filters.yawThreshold.tooltip')}
        disabled={!useWatch({ name: 'estimate.headPose' })}
        title={t('handlers:filters.yawThreshold.title')}
      >
        <Control.Input
          {...register('filters.yawThreshold', {
            min: validate.gte(0),
            max: validate.lte(180),
            validate: validate.integer(),
          })}
          disabled={!useWatch({ name: 'estimate.headPose' })}
          errors={errors}
        />
      </SettingsItemWrapper>

      <SettingsItemWrapper
        contentClassName="MatchPolicy_Input"
        data-tooltip-content={!useWatch({ name: 'estimate.headPose' }) && t('handlers:estimate.headPose')}
        disabled={!useWatch({ name: 'estimate.headPose' })}
        title={t('handlers:filters.rollThreshold')}
      >
        <Control.Input
          {...register('filters.rollThreshold', {
            min: validate.gte(0),
            max: validate.lte(180),
            validate: validate.integer(),
          })}
          disabled={!useWatch({ name: 'estimate.headPose' })}
          errors={errors}
        />
      </SettingsItemWrapper>

      <SettingsItemWrapper
        contentClassName="MatchPolicy_Input"
        data-tooltip-content={!useWatch({ name: 'estimate.headPose' }) && t('handlers:estimate.headPose')}
        disabled={!useWatch({ name: 'estimate.headPose' })}
        title={t('handlers:filters.pitchThreshold')}
      >
        <Control.Input
          {...register('filters.pitchThreshold', {
            min: validate.gte(0),
            max: validate.lte(180),
            validate: validate.integer(),
          })}
          disabled={!useWatch({ name: 'estimate.headPose' })}
          errors={errors}
        />
      </SettingsItemWrapper>

      <SettingsItemWrapper
        data-tooltip-content={!useWatch({ name: 'estimate_liveness.estimate' }) && t('handlers:estimate.liveness')}
        disabled={!useWatch({ name: 'estimate_liveness.estimate' })}
        title={t('handlers:filters.liveness_states')}
      >
        <div className="MatchPolicy_Dropdown">
          <Control.Select
            control={control}
            disabled={!useWatch({ name: 'estimate_liveness.estimate' })}
            isMulti
            name="filters.liveness_states"
            options={LIVENESS_OPTIONS_STRICT.raw}
          />
        </div>
      </SettingsItemWrapper>

      <SettingsItemWrapper
        data-tooltip-content={!useWatch({ name: 'estimate_deepfake.estimate' }) && t('handlers:estimate.deepfake')}
        disabled={!useWatch({ name: 'estimate_deepfake.estimate' })}
        title={t('handlers:filters.deepfake_states')}
      >
        <div className="MatchPolicy_Dropdown">
          <Control.Select
            control={control}
            disabled={!useWatch({ name: 'estimate_deepfake.estimate' })}
            isMulti
            name="filters.deepfake_states"
            options={DEEPFAKE_STATE_OPTIONS_STRICT.raw}
          />
        </div>
      </SettingsItemWrapper>

      <SettingsItemWrapper
        data-tooltip-content={!useWatch({ name: 'estimate.faceQuality' }) && t('handlers:filters.faceQuality.tooltip')}
        disabled={!useWatch({ name: 'estimate.faceQuality' })}
        title={t('handlers:filters.faceQuality.title')}
      >
        <Control.Switch
          control={control}
          disabled={!useWatch({ name: 'estimate.faceQuality' })}
          name="filters.faceQuality"
        />
      </SettingsItemWrapper>
    </>
  );
};

export default FiltersSubForm;
