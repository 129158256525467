import React from 'react';

import PropTypes from 'prop-types';

const Content = ({
  children,
  ...restProps
}) => {
  return (
    <div className="AppContent" {...restProps}>
      {children}
    </div>
  );
};

Content.propTypes = {
  children: PropTypes.node,
};

Content.defaultProps = {
  children: undefined,
};

export { Content };
