import React, { forwardRef } from 'react';

import PropTypes from 'prop-types';

import { BaseButton } from './base-button/BaseButton';

// Компонент `LinkButton` может функционировать либо как стандартная ссылка (тег `a`), либо как маршрутизирующая ссылка:
// 1. Как ссылка (`a`): Используйте свойство `href` для указания URL. Это дефолтное поведение.
// 2. Как `Link` из `react-router-dom`: Установите свойство `as` в `Link` для навигации внутри приложения, которая интегрируется с `basename` маршрутизатора. Используйте свойство `to` для указания пути навигации.
const LinkButton = forwardRef((props, externalRef) => {
  const { as = 'a' } = props;
  return (
    <BaseButton
      {...props}
      as={as}
      ref={externalRef}
      type={null}
    />
  );
});

LinkButton.displayName = 'LinkButton';

// Убрать копипасту когад storybook поддержит динамические propTypes
// https://github.com/storybookjs/storybook/issues/14092
LinkButton.propTypes = {
  variant: PropTypes.oneOf([
    'filled',
    'flat',
    'outlined',
    'dimmed',
  ]),
  kind: PropTypes.oneOf([
    'primary',
    'negative',
    'attention',
    'positive',
    'neutral',
  ]),
  type: PropTypes.oneOf([
    'button',
    'submit',
    'reset',
  ]),
  children: PropTypes.node,
  className: PropTypes.string,
  icon: PropTypes.node,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  href: PropTypes.string,
  to: PropTypes.string,
  as: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

LinkButton.defaultProps = {
  variant: 'filled',
  kind: 'primary',
  type: 'button',
  children: undefined,
  className: undefined,
  icon: undefined,
  disabled: undefined,
  fullWidth: false,
  href: undefined,
  to: undefined,
  as: 'a',
};

export { LinkButton };
