import { get, CleanSchema } from '../../../json-transform';
import { notificationPolicySchema } from './notificationPolicySchema';

export const crossmatch = new CleanSchema({
  description: get('email').asString(),
  notification_policy: get('notification_policy').asSchema(notificationPolicySchema),
  content: {
    filters: {
      reference_type: 'faces',
      candidate_type: 'faces',
      reference_filters: {
        list_id: get('referenceListId.value'),
      },
      candidate_filters: {
        list_id: get('candidateListId.value'),
      },
    },
    threshold: get('threshold').asFloat().toNormalizedPercent().ifNaN(undefined),
    limit: get('limit').asInt().ifNaN(undefined),
  },
});
