import React, { memo } from 'react';

import { IconInner } from '../../IconInner';

const ChevronDoubleLeftIconInner = (props) => (
  <IconInner name="ChevronDoubleLeftIcon" viewBox="0 0 24 24" {...props}>
    <path d="m11 17-5-5 5-5m7 10-5-5 5-5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
  </IconInner>
);

export const ChevronDoubleLeftIcon = memo(ChevronDoubleLeftIconInner);
