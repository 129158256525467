import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import nextId from 'react-id-generator';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import './Tooltip.sass';

const ID_PREFIX = 'tooltip-';

const Tooltip = ({
  id,
  children,
  tooltipProps,
  // FIXME: DEPRECATED
  'data-tip': dataTip,
  'data-tooltip-content': dataTooltipContent,
  ...props
}) => {
  const [localId, setLocalId] = useState(id || nextId(ID_PREFIX));

  useEffect(() => {
    setLocalId(id || nextId(ID_PREFIX));
  }, [id]);

  if (dataTip) console.warn('data-tip prop is deprecated, use data-tooltip-content');

  return (
    <>
      <div
        className="Tooltip__Wrapper"
        data-tooltip-content={dataTooltipContent || dataTip}
        data-tooltip-id={localId}
        id={id}
        {...props}
      >
        {children}
      </div>

      <ReactTooltip
        className="Tooltip"
        id={localId}
        {...tooltipProps}
      />
    </>
  );
};

Tooltip.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node.isRequired,
  tooltipProps: PropTypes.objectOf(PropTypes.any),
  'data-tooltip-content': PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  'data-tip': PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

Tooltip.defaultProps = {
  id: undefined,
  tooltipProps: {},
  'data-tooltip-content': undefined,
  'data-tip': undefined,
};

export { Tooltip };
