import { fabric } from 'fabric';

import { getCssVar } from '../../helpers';

const pointColor = getCssVar('--color-primary');
const innerColor = getCssVar('--color-on-primary');
const strokeColor = '#000';

const pointSize = 13;
const pointStrokeSize = 2;

const getOptions = (opts) => ({
  originX: 'center',
  originY: 'center',
  hasBorders: false,
  hasControls: false,
  selectable: false,
  ...opts,
});

export const createPoint = (canvas, left, top, label) => {
  const pointBody = new fabric.Circle(getOptions({
    radius: pointSize,
    fill: pointColor,
    stroke: strokeColor,
    top: pointSize,
    strokeWidth: pointStrokeSize,
  }));

  const pointText = new fabric.Text(label, getOptions({
    fontSize: 16,
    fill: innerColor,
    top: pointSize,
    fontWeight: '600',
  }));

  const legHeight = pointSize * 2;
  const pointLeg = new fabric.Polygon(
    [
      { x: left, y: top },
      { x: left - pointSize, y: top - legHeight },
      { x: left + pointSize, y: top - legHeight },
    ],
    getOptions({
      stroke: strokeColor,
      strokeWidth: pointStrokeSize,
      fill: innerColor,
      left: 0,
      top: legHeight,
      originX: 'top',
      originY: 'left',
    }),
  );

  return new fabric.Group([
    pointLeg,
    pointBody,
    pointText,
  ], getOptions({
    left,
    top,
    originX: 'center',
    originY: 'bottom',
    subTargetCheck: true,
    selectable: true,
    hoverCursor: 'move',
    perPixelTargetFind: true,
  }));
};
