import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import './ButtonToggleGroup.sass';
import { Button } from '../controls/button/Button';

const ButtonToggleGroup = ({
  children,
  testId,
  onChange,
  value,
  className,
  itemClassName,
}) => {
  const childrenArray = React.Children.toArray(children);

  return (
    <div
      className={cn('ButtonToggleGroup', className)}
      data-testid={testId}
    >
      {
        childrenArray.map(({ props: childProps = {} }, index) => (
          <Button
            className={cn(
              itemClassName,
              {
                ButtonToggleGroup__Item: true,
                ButtonToggleGroup__Item_selected: !childProps.disabled && value === childProps.value,
              },
            )}
            data-testid={`button-${index + 1}-${testId}`}
            disabled={childProps.disabled}
            icon={childProps.icon}
            key={`button-${index + 1}`}
            onClick={() => onChange(childProps.value)}
            title={childProps.title}
            value={childProps.value}
            variant={(!childProps.disabled && value === childProps.value) ? 'filled' : 'outlined'}
          >
            {childProps.children}
          </Button>
        ))
      }
    </div>
  );
};

ButtonToggleGroup.propTypes = {
  children: PropTypes.node,
  testId: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  className: PropTypes.string,
  itemClassName: PropTypes.string,
};

ButtonToggleGroup.defaultProps = {
  children: undefined,
  testId: '',
  className: undefined,
  itemClassName: undefined,
};

export { ButtonToggleGroup };
