import React, { memo } from 'react';

import { IconInner } from '../../IconInner';

const LoaderIconInner = (props) => (
  <IconInner name="LoaderIcon" viewBox="0 0 24 24" {...props}>
    <path d="M12 2v4m0 12v4M4.93 4.93l2.83 2.83m8.48 8.48 2.83 2.83M2 12h4m12 0h4M4.93 19.07l2.83-2.83m8.48-8.48 2.83-2.83" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
  </IconInner>
);

export const LoaderIcon = memo(LoaderIconInner);
