import React from 'react';

import validate from '@vlabs/shared/validators';
import { Control, SettingsItemWrapper } from '@vlabs/uikit';
import i18n from 'i18next';
import { useFormContext } from 'react-hook-form';

const CommonSubForm = () => {
  const { register, formState: { errors } } = useFormContext();

  return (
    <>
      <SettingsItemWrapper title={i18n.t('verifiers:form.description.label')}>
        <Control.Input
          errors={errors}
          {...register('description', {
            maxLength: validate.maxLength(128),
            required: validate.required(),
          })}
        />
      </SettingsItemWrapper>

      <SettingsItemWrapper
        data-tooltip-content={i18n.t('verifiers:form.verificationThreshold.tooltip')}
        title={i18n.t('verifiers:form.verificationThreshold.label')}
      >
        <Control.Input
          errors={errors}
          {...register('verificationThreshold', {
            min: validate.gte(0),
            max: validate.lte(1),
            validate: validate.number(),
          })}
        />
      </SettingsItemWrapper>
    </>

  );
};

export default CommonSubForm;
