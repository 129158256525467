import React from 'react';

import { PlusIcon } from '@vlabs/icons';
import { MetaFilter } from '@vlabs/shared/legacy-components/filter-forms/meta-filter/MetaFilter';
import { Control } from '@vlabs/uikit';
import { useFieldArray, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import st from '../FilterForms.module.sass';

const defaultMeta = {
  key: '',
  type: undefined,
  operator: undefined,
  value: '',
};

export function MetaFilterFields() {
  const { t } = useTranslation();
  const { fields, append, remove, replace } = useFieldArray({ name: 'meta' });

  return (
    <>
      {useWatch({ name: 'meta' }) && fields.map((field, i) => (
        <MetaFilter
          i={i}
          key={field.id}
          remove={remove}
          replace={replace}
        />
      ))}

      <Control.Button
        data-testid="meta.plusIcon"
        icon={<PlusIcon />}
        onClick={() => append(defaultMeta)}
        title={t('кнопка.добавить')}
        variant="dimmed"
      >
        <p className={st.Subtitle}>{t('events:добавить фильтр по meta')}</p>
      </Control.Button>
    </>
  );
}
