import React from 'react';

import { CameraOffIcon, CircleInfoIcon, ArrowInCircleRightIcon } from '@vlabs/icons';
import { ButtonScrimGroup, ButtonScrimItem, Tooltip } from '@vlabs/uikit';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import st from './EmptyImage.module.sass';

export const EmptyImage = ({
  className,
  messageText,
  dataTip,
  onImageClick,
  icon,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={cn(st.Root, className)}
      {...props}
    >
      {icon || <CameraOffIcon />}
      <span>{messageText || t('изображение отсутствует')}</span>

      {dataTip && (
        <Tooltip
          className={st.Tooltip}
          data-tooltip-content={dataTip}
          tooltipProps={{ effect: 'solid' }}
        >
          <CircleInfoIcon className={st.Tooltip_icon} />
        </Tooltip>
      )}

      {onImageClick && (
        <ButtonScrimGroup
          className={st.GoTo}
          data-testid="emptyImage.goToButton"
        >
          <ButtonScrimItem
            icon={<ArrowInCircleRightIcon />}
            onClick={onImageClick}
          />
        </ButtonScrimGroup>
      )}

    </div>
  );
};

EmptyImage.propTypes = {
  className: PropTypes.string,
  messageText: PropTypes.string,
  dataTip: PropTypes.string,
  onImageClick: PropTypes.func,
  icon: PropTypes.element,
};
EmptyImage.defaultProps = {
  className: undefined,
  messageText: undefined,
  dataTip: undefined,
  onImageClick: undefined,
  icon: undefined,
};
