import React from 'react';

import { selectListOptions } from '@vlabs/shared/selectors/listSelectors';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { selectHandlerOptions, selectHandlerById } from '@vlabs/pages/handlers/selectors';

import { EditHandlerForm } from './EditHandlerForm';
import { submitHandlerData } from './store';

const EditHandlerPageContainer = ({
  values,
  listOptions,
  handlerOptions,
  onSubmit,
}) => {
  return (
    <EditHandlerForm
      handlerOptions={handlerOptions}
      listOptions={listOptions}
      onSubmit={onSubmit}
      values={values}
    />
  );
};

EditHandlerPageContainer.propTypes = {
  values: PropTypes.objectOf(PropTypes.any),
  handlerOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })),
  listOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })),
  onSubmit: PropTypes.func.isRequired,
};
EditHandlerPageContainer.defaultProps = {
  values: undefined,
  handlerOptions: [],
  listOptions: [],
};

export const EditHandlerPage = connect((state, { match: { params: { handlerId } } }) => ({
  values: selectHandlerById(state, handlerId),
  handlerOptions: selectHandlerOptions(state),
  listOptions: selectListOptions(state),
}),
(dispatch, { match: { params: { handlerId } } }) => ({
  onSubmit: (data) => dispatch(submitHandlerData(handlerId, data)),
}))(EditHandlerPageContainer);
