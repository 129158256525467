import React from 'react';

import { Fold } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { onDownloadResults } from '../utils';
import CheckRow from './CheckRow';
import st from './CheckRow.module.sass';
import CheckTitle from './CheckTitle';

export default function CheckRowSdk({ estimations, filename, status, estimationName }) {
  const { t } = useTranslation();

  // приводим к виду данных с проверками face quality
  // (объект перебираем в массив проверок, где каждая проверка - объект)
  const buildArrayWithChecksObjects = (obj) => {
    return Object.keys(obj).reduce((acc, name) => {
      let tooltipText;
      let checkValue;

      if (typeof obj[name] === 'object' && obj[name] !== null) {
        acc.push(...buildArrayWithChecksObjects(obj[name]));
      } else {
        if (name === 'prediction') {
          tooltipText = t('checks:check tooltip_bool', { value: 1 });
          checkValue = t(`checks:prediction values.${status}`);
        } else {
          tooltipText = null;
          checkValue = obj[name];
        }
        const checkName = t(`checks:${estimationName}.${name}`);
        acc.push({ name, checkName, checkValue, tooltipText, result: status });
      }
      return acc;
    }, []);
  };

  const checks = buildArrayWithChecksObjects(estimations);
  const resultFilename = `${filename}-${estimationName}Result.json`;

  const title = (
    <CheckTitle
      onDownloadResults={() => onDownloadResults(estimations, resultFilename)}
      status={status}
      title={`${t(`checks:estimations.${estimationName}.title`)}: ${t(`checks:status.${status}`)}`}
    />
  );

  return (
    <Fold
      className={st.Fold}
      compact
      testId={`${estimationName}Fold`}
      title={title}
    >
      <div className={st.Result__Rows}>
        {checks.map(CheckRow)}
      </div>
    </Fold>
  );
}

CheckRowSdk.propTypes = {
  estimations: PropTypes.objectOf(PropTypes.any).isRequired,
  status: PropTypes.number.isRequired,
  estimationName: PropTypes.string.isRequired,
  filename: PropTypes.string,
};

CheckRowSdk.defaultProps = {
  filename: '',
};
