import React from 'react';

import { CopyButton, Divider } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import st from './errors.module.sass';

const ErrorToast = ({
  error,
}) => {
  const { t } = useTranslation();

  const copyValue = JSON.stringify({
    message: error.message,
    stack: error.stack,
  });

  return (
    <div className={st.Error}>
      <h5>{t('error.unknown')}</h5>

      <p>
        <strong>{t('error.message.title')}</strong>
        {' '}
        {error.message}
      </p>

      <p>
        <strong>{t('error.stack.title')}</strong>
      </p>

      <pre>
        {error.stack}
      </pre>

      <Divider small />

      <p>
        <CopyButton label={t('error.copy')} value={copyValue} />
      </p>
    </div>
  );
};

ErrorToast.propTypes = {
  error: PropTypes.instanceOf(Error).isRequired,
};

export { ErrorToast };
export const showErrorToast = (error) => {
  toast.error(<ErrorToast error={error} />);
};
