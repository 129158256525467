import React from 'react';

import PropTypes from 'prop-types';

import CheckRowsSdk from '../components/CheckRowsSdk';

const LIVENESS_MAP = {
  spoof: 0,
  real: 1,
  unknown: 2,
};

export default function LivenessCheckResults({ results }) {
  const livenessEstimations = results?.estimations[0]?.face?.detection?.attributes?.liveness;
  if (!livenessEstimations) return null;

  const status = LIVENESS_MAP[livenessEstimations.prediction];

  return (
    <CheckRowsSdk
      estimationName="liveness"
      estimations={livenessEstimations}
      filename={results.filename}
      status={status}
    />
  );
}

LivenessCheckResults.propTypes = {
  results: PropTypes.objectOf(PropTypes.any).isRequired,
};
