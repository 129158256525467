import React, { useEffect, useRef, useState } from 'react';

import { CircleInfoIcon, ChevronDownIcon } from '@vlabs/icons';
import cn from 'classnames';
import PropTypes from 'prop-types';

import { Switch } from '../controls/switch/Switch';
import { Tooltip } from '../tooltip/Tooltip';
import { collapse, expand } from './fold-utils';

import './Fold.sass';

export const Fold = ({
  children,
  title,
  isOpen: isOpenProp,
  testId,
  disabled,
  className,
  switchProps,
  control,
  name,
  tooltip,
  tooltipIcon,
  onToggle,
  compact,
  withPadding,
  ...restProps
}) => {
  const isSwitch = !!control && !!name;

  const isFirstRender = useRef(true);

  const [isOpen, setIsOpen] = useState(isOpenProp);
  const bodyRef = useRef();

  const toggle = (shouldOpen) => {
    setIsOpen(shouldOpen);
    if (shouldOpen) {
      expand(bodyRef.current);
    } else {
      collapse(bodyRef.current);
    }
  };

  useEffect(() => {
    isFirstRender.current = false;
  }, []);

  useEffect(() => {
    toggle(!!isOpenProp);
  }, [isOpenProp]);

  const onHeaderInteraction = (e) => {
    // 32 -- space
    if (disabled || (typeof e.keyCode === 'number' && e.keyCode !== 32)) return;

    toggle(!isOpen);
    if (onToggle) onToggle(!isOpen);
  };

  const Header = isSwitch ? 'label' : 'div';
  const headerProps = isSwitch ? { } : {
    role: 'button',
    tabIndex: 0,
    onClick: onHeaderInteraction,
    onKeyDown: onHeaderInteraction,
  };

  return (
    <div
      className={cn(
        className,
        {
          Fold: true,
          Fold_isOpened: isOpen,
          Fold_isClosed: !isOpen,
          Fold_compact: compact,
          Fold_withPadding: withPadding,
        },
      )}
      data-testid={testId}
      htmlFor={isSwitch ? name : undefined}
      {...restProps}
    >
      <Header
        className={cn({
          Fold__Header: true,
          Fold__Header_isDisabled: disabled,
          InteractionWrapper: true,
        })}
        {...headerProps}
      >

        {typeof title === 'string' && (
          <h5 className="Fold__Title">
            {title}
          </h5>
        )}

        {typeof title !== 'string' && (
          <>
            {title}

            {tooltip && (
              <Tooltip
                className="Fold__Tooltip"
                data-tooltip-content={tooltip}
                tooltipProps={{ effect: 'solid' }}
              >
                {tooltipIcon || <CircleInfoIcon />}
              </Tooltip>
            )}

            <div className="grow" />
          </>
        )}

        {isSwitch && (
          <Switch
            control={control}
            defaultValue={false}
            disabled={disabled}
            name={name}
            {...(switchProps || {})}
          />
        )}

        {!isSwitch && (
          <ChevronDownIcon
            className={cn({
              Fold__ToggleIcon: true,
              Fold__ToggleIcon_isOpened: isOpen,
            })}
          />
        )}

        <div className="InteractionOverlay" />
      </Header>

      <div
        className={cn({
          Fold__Body: true,
          Fold__Body_collapsed: !!isFirstRender.current && !isOpen,
          // notransition: !!isFirstBodyRender.current,
        })}
        ref={bodyRef}
      >
        {/* BodyInner нужен чтобы паддинги не торчали наружу при 0 высоте */}
        <div className="Fold__BodyInner">
          {children}
        </div>
      </div>
    </div>
  );
};

Fold.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
  testId: PropTypes.string.isRequired,
  name: PropTypes.string,
  isOpen: PropTypes.bool,
  control: PropTypes.any,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  switchProps: PropTypes.objectOf(PropTypes.any),
  tooltip: PropTypes.string,
  tooltipIcon: PropTypes.node,
  onToggle: PropTypes.func,
  compact: PropTypes.bool,
  withPadding: PropTypes.bool,
};

Fold.defaultProps = {
  name: undefined,
  isOpen: false,
  control: undefined,
  disabled: false,
  className: undefined,
  switchProps: undefined,
  tooltip: undefined,
  tooltipIcon: undefined,
  onToggle: undefined,
  compact: undefined,
  withPadding: undefined,
};
