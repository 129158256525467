import React from 'react';

import { EVENT_ENUMS } from '@vlabs/api-bindings/src/luna-client/constants';
import { headwearImages } from '@vlabs/shared/assets/images';
import cn from 'classnames';
import { useTranslation, Trans } from 'react-i18next';

import { Card } from './Card';
import st from './Card.module.sass';

const HEADWEAR_IMAGES = {
  0: headwearImages.NoHeadwear,
  1: headwearImages.Headwear,
  2: headwearImages.Unknown,
};

export const HeadwearCard = ({ headwear, colors }) => {
  const { t } = useTranslation();

  const headwearLabel = EVENT_ENUMS.UPPER_BODY.HEADWEAR[headwear]?.label;
  const img = HEADWEAR_IMAGES[headwear];
  const colorsLabel = EVENT_ENUMS.UPPER_BODY.HEADWEAR_COLORS[colors]?.label;

  return (
    <Card
      back={(
        <div className={st.Tooltip}>
          <Trans i18nKey="events:карточка.головной убор" values={{ type: headwearLabel, color: colorsLabel }} />
        </div>
      )}
      front={(
        <div className={st.Root}>
          <div
            className={st.ImageBlock}
            data-testid="eventAttributesCell.headwear"
          >
            {t('events:headwear')}
            <img alt="headwear" className={st.Image} src={img} />
            {headwearLabel}
          </div>
          <div className={st.ColorBlock}>
            <div className={cn(st.Color, st[`Color_${colors}`])} />
            <div className={st.ColorLabel}>
              {EVENT_ENUMS.UPPER_BODY.HEADWEAR_COLORS[colors]?.label}
            </div>
          </div>
        </div>
      )}
    />
  );
};
