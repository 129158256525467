import React from 'react';

import { PeopleCountSubForm } from '@vlabs/pages/handlers/forms/PeopleCountSubForm';
import { Step } from '@vlabs/uikit';
import cn from 'classnames';

import st from './shared.module.sass';

function StepPeopleCount() {
  return (
    <Step className={cn(st.Step, st.FoldedStep)}>
      <PeopleCountSubForm />
    </Step>
  );
}

StepPeopleCount.fieldNames = [
  'estimate_people_count',
];

export { StepPeopleCount };
