import React, { useState, useEffect } from 'react';

import { apiContainer } from '@vlabs/api-bindings';
import { CircleInfoIcon } from '@vlabs/icons';
import { PhotoUploadForm } from '@vlabs/shared/components/photo-upload-form';
import { Control, Tooltip, Divider, Page } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import st from './PhotoForm.module.sass';

export function PhotoForm({ onSubmit }) {
  const { t } = useTranslation();
  const [detections, setDetections] = useState([]);
  const [sampleId, setSampleId] = useState();
  const [file, setFile] = useState(undefined);

  const { handleSubmit, setError, reset, register, formState: { errors } } = useForm({});
  async function processFile() {
    const {
      data: { images: [{ detections: { samples } }] },
    } = await apiContainer.lunaClient.detector.detectFaces(file.raw);

    if (samples.length > 0) {
      setDetections(samples.map(({ face }) => ({
        id: face.sample_id,
        type: 'face',
        rect: face.detection.rect,
      })));
    } else {
      setError('file', { message: t('выбранное изображение не содержит лиц') });
    }
  }

  useEffect(() => {
    if (!file) return;
    processFile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  const onFileSelect = (newFile) => {
    if (!newFile) return;
    setFile(newFile);
  };

  const onResetFile = () => {
    reset({ file: undefined });
    setFile(undefined);
    setDetections([]);
  };

  const submitHandler = () => {
    if (!sampleId?.length) {
      setError('file', { message: t('faces:необходимо выбрать хотя бы одно лицо') });
      return;
    }
    onSubmit({ sample_id: sampleId });
  };

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <Page className={st.Root}>
        <div className={st.TitltContainer}>
          <h6>{t('faces:обновить фотографию')}</h6>
          <Tooltip
            className="Tooltip_ui"
            data-testid="photoFormTooltip"
            data-tooltip-content={t('faces:обновление фотографии не затронет все существующие события сравнения с данным лицом. Существующие события сравнения с данным лицом будут показывать степень схожести, полученную при использовании старой фотографии.')}
            tooltipProps={{ effect: 'solid', place: 'bottom' }}
          >
            <CircleInfoIcon className="Tooltip_icon" />
          </Tooltip>
        </div>
        <Divider />
        <div className={st.FileDropzone__Container}>
          <PhotoUploadForm
            detections={detections}
            errorProps={{ errors, register }}
            file={file}
            onFileSelect={onFileSelect}
            onResetFile={onResetFile}
            onSelectSampleId={(id) => setSampleId(...id)}
          />
        </div>

        <Divider small />
        <Control.Button
          data-testid="photoFormSaveButton"
          fullWidth
          type="submit"
        >
          {t('форма.submit')}
        </Control.Button>
      </Page>
    </form>
  );
}

PhotoForm.propTypes = {
  onSubmit: PropTypes.func,
};

PhotoForm.defaultProps = {
  onSubmit: undefined,
};
