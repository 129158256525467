import React, { useMemo } from 'react';

import {
  TopMatchCell,
  EventSampleCell,
  AttributesCell,
} from '@vlabs/pages/events/cells';
import { EventInfoCell } from '@vlabs/shared/legacy-components/event-info-cell/EventInfoCell';
import i18next from 'i18next';

// FIXME: пока добавила мемо, чтобы избежать ререндеров, вообще надо функцию выкинуть и сделать просто компонент с колонками
export const useEventTableColumns = (onTopMatchCardClick, onEventCardClick, isTopMatchHidden) => {
  return useMemo(() => {
    const columns = [
      {
        Header: i18next.t('events:table header.event image'),
        accessor: (value) => value,
        width: 165,
        Cell: ({ value }) => (
          <EventSampleCell
            onEventClick={() => { onEventCardClick(value); }}
            original={value}
          />
        ),
      },
    ];

    if (!isTopMatchHidden) {
      columns.push(
        {
          Header: i18next.t('events:table header.top match'),
          accessor: (value) => value,
          width: 250,
          Cell: ({ value }) => (
            <TopMatchCell
              onTopMatchCardClick={() => { onTopMatchCardClick(value?.top_match); }}
              topMatch={value?.top_match}
            />
          ),
        },
      );
    }

    columns.push(
      {
        Header: i18next.t('events:table header.event details'),
        id: 'eventInfo',
        accessor: (value) => value,
        Cell: ({ value }) => (
          <EventInfoCell
            original={value}
          />
        ),
        width: 80,
      },
      {
        id: 'attributes',
        Cell: AttributesCell,
        // width: 50,
      },
    );

    return columns;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTopMatchHidden]);
};
