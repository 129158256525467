import React, { memo } from 'react';

import { IconInner } from '../../IconInner';

const VideoIconInner = (props) => (
  <IconInner name="VideoIcon" viewBox="0 0 24 24" {...props}>
    <path d="m23 7-7 5 7 5V7Zm-9-2H3a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h11a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
  </IconInner>
);

export const VideoIcon = memo(VideoIconInner);
