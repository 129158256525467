export const processInteger = (number) => {
  if (typeof number !== 'string' && typeof number !== 'number') return null;

  return typeof number === 'string'
    ? Number.parseFloat(number, 10)
    : Math.trunc(number) ?? null;
};

export const processFloat = (number) => {
  if (typeof number !== 'string' && typeof number !== 'number') return null;

  return typeof number === 'string'
    ? Number.parseFloat(number)
    : number;
};
