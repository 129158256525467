import React, { memo } from 'react';

import { IconInner } from '../../IconInner';

const RotationRightIconInner = (props) => (
  <IconInner name="RotationRightIcon" viewBox="0 0 24 24" {...props}>
    <path d="M23 4v6h-6" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
    <path d="M20.49 15a9 9 0 1 1-2.12-9.36L23 10" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
  </IconInner>
);

export const RotationRightIcon = memo(RotationRightIconInner);
