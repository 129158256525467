/* eslint-disable camelcase */
import { Schema, get } from '../../../json-transform';
import { checkSchema } from '../../faceQuality/checkSchema';

export const read = new Schema({
  filename: get('filename'),
  status: get('status'),
  error: {
    error_code: get('error.error_code'),
    desc: get('error.desc'),
    detail: get('error.detail'),
    link: get('error.link'),
  },
  estimations: get('estimations').asArrayOf(new Schema({
    face: {
      detection: {
        iso: {
          status: get('face.detection.iso.status'),
          checks: get('face.detection.iso.checks').asArrayOf(checkSchema),
        },
        rect: get('face.detection.rect'),
      },
    },
  })),
});
