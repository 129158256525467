import React from 'react';

import st from './shared.module.sass';

export const ImageFileRenderer = ({
  photo,
  setRenderedImage,
}) => {
  return (
    <img
      alt="Фотография"
      className={st.Image}
      data-testid="fileRenderer.image"
      onChange={setRenderedImage ? ({ target }) => setRenderedImage(target) : undefined}
      onLoad={setRenderedImage ? ({ target }) => setRenderedImage(target) : undefined}
      src={photo}
    />
  );
};
