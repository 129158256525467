import React from 'react';

import { MatchPolicySubForm } from '@vlabs/pages/handlers/forms';
import StepTitle from '@vlabs/shared/legacy-components/steps/StepTitle';
import { Step } from '@vlabs/uikit';
import i18next from 'i18next';

import st from './shared.module.sass';

function StepMatchPolicy() {
  return (
    <Step
      className={st.Step}
      title={(
        <StepTitle>
          {i18next.t('handlers:необходимо ли выполнить сравнение по параметрам?')}
        </StepTitle>
      )}
    >
      <MatchPolicySubForm />
    </Step>
  );
}

StepMatchPolicy.fieldNames = [
  'matchPolicy',
];

export { StepMatchPolicy };
