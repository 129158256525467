import React from 'react';

import StepTitle from '@vlabs/shared/legacy-components/steps/StepTitle';
import { Step } from '@vlabs/uikit';
import i18next from 'i18next';

import { StoragePolicySubForm } from '../forms';
import st from './VerifierStep.module.sass';

const StepSaveFaceAndAttributes = () => {
  return (
    <Step
      className={st.Step}
      title={(
        <StepTitle>
          {i18next.t('verifiers:form.stepTitles.step5')}
        </StepTitle>
      )}
    >
      <StoragePolicySubForm />
    </Step>
  );
};

StepSaveFaceAndAttributes.fieldNames = [
  'faceSamplePolicy',
  'attributesPolicy',
];

export { StepSaveFaceAndAttributes };
