import React from 'react';

import { Can } from '@vlabs/shared/components/can/Can';
import { permissions } from '@vlabs/shared/config';
import { routes } from '@vlabs/shared/navigation/routes';
import { Control } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { generatePath } from 'react-router-dom';

import { viewerCan } from '@vlabs/pages/auth/selectors';
import { selectHandlerOptions } from '@vlabs/pages/handlers/selectors';

const HandlerLink = ({
  handlerId,
  handlerOptions,
  can,
}) => {
  const { t } = useTranslation();
  if (!handlerId) return null;

  const handlerLabel = handlerOptions?.find((handler) => handler.value === handlerId)?.label;
  if (!handlerLabel) return t('удалён');

  return (
    <Can
      no={<div>{handlerId}</div>}
      permissions={{ rules: [permissions.handler.view] }}
      yes={(
        <Control.Link
          data-testid={`HandlerLink${handlerId}`}
          to={generatePath(routes.handlers.read, { handlerId })}
        >
          {handlerLabel}
        </Control.Link>
      )}
    />
  );
};

HandlerLink.propTypes = {
  handlerId: PropTypes.string,
  handlerOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })),
  can: PropTypes.func.isRequired,
};
HandlerLink.defaultProps = {
  handlerId: undefined,
  handlerOptions: [],
};

export default connect(
  (state) => ({
    handlerOptions: selectHandlerOptions(state),
    can: viewerCan(state),
  }),
)(HandlerLink);
