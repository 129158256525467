import { get, Schema } from '../../json-transform';
import { notificationPolicySchema } from '../../luna-client/tasks/schemes/notificationPolicySchema';
import { LUNA_TASK_ENUMS as ENUMS } from '../constants';
import {
  postEventDescriptorsSchema, postEventsSchema,
  postFaceDescriptorsSchema, postFacesSchema,
} from './postTaskSchemas';

export default new Schema({
  description: get('description').ifEmptyString(undefined),
  notification_policy: get('notification_policy').asSchema(notificationPolicySchema),
  content: get('content').switch(({ target }) => target?.value, {
    [ENUMS.TARGET.events.value]: postEventsSchema,
    [ENUMS.TARGET.event_descriptors.value]: postEventDescriptorsSchema,
    [ENUMS.TARGET.faces.value]: postFacesSchema,
    [ENUMS.TARGET.face_descriptors.value]: postFaceDescriptorsSchema,
    default: (value) => { throw Error(`${value} target does not exist`); },
  }),
});
