import React from 'react';

import cn from 'classnames';

import st from './StepTitle.module.sass';

const StepTitle = ({ children, className }) => (
  <div className={cn(st.StepTitle, className)}>
    {children}
  </div>
);

export default StepTitle;
