import React from 'react';

import { Divider } from '@vlabs/uikit';

import AdditionalPoliciesSubForm from './AdditionalPoliciesSubForm';
import FilteringPolicySubForm from './FilteringPolicySubForm';
import HealthcheckPolicySubForm from './HealthcheckPolicySubForm';
import LivenessPolicySubForm from './LivenessPolicySubForm';
import PrimaryTrackPolicySubForm from './PrimaryTrackPolicySubForm';
import SendingPolicySubForm from './SendingPolicySubForm';

function PoliciesSubForm() {
  return (
    <>
      <SendingPolicySubForm />
      <Divider />
      <PrimaryTrackPolicySubForm />
      <Divider />
      <HealthcheckPolicySubForm />
      <Divider />
      <FilteringPolicySubForm />
      <Divider />
      <LivenessPolicySubForm />
      <Divider />
      <AdditionalPoliciesSubForm />
    </>
  );
}

PoliciesSubForm.propTypes = {

};

export default PoliciesSubForm;
