import React, { useCallback } from 'react';

import { CircleInfoIcon } from '@vlabs/icons';
import { Fold, Tooltip } from '@vlabs/uikit';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useClearPolicyFilters } from '../hooks';
import GenericPolicySubForm from './GenericPolicySubForm';
import st from './NotificationPolicySubForm.module.sass';

const NotificationPolicySubform = () => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const notificationPolicyIsEnabled = useWatch({ name: 'notificationPolicy.isEnabled' });

  useClearPolicyFilters('notificationPolicy', {
    on: useCallback(() => notificationPolicyIsEnabled === false, [notificationPolicyIsEnabled]),
  });

  const title = (
    <div className={st.TitleWrapper}>
      <div className={st.Title}>
        {t('handlers:setup sections.receive and display an event in the Last events section.title')}
      </div>
      <Tooltip
        className="Tooltip_ui"
        data-tooltip-content={t('handlers:setup sections.receive and display an event in the Last events section.tooltip')}
        tooltipProps={{ effect: 'solid', place: 'right' }}
      >
        <CircleInfoIcon className="Tooltip_icon" />
      </Tooltip>
    </div>
  );

  return (
    <Fold
      control={control}
      isOpen={notificationPolicyIsEnabled}
      name="notificationPolicy.isEnabled"
      switch
      testId="notificationPolicy"
      title={title}
    >
      <GenericPolicySubForm
        fieldName="notificationPolicy.filters"
        filtersTitle={t('handlers:policies.storage_policy.notification_policy.filters title')}
        matchTitle={t('handlers:policies.storage_policy.notification_policy.match title')}
      />
    </Fold>
  );
};

export default NotificationPolicySubform;
