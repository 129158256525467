import React from 'react';

import { CircleCheckIcon, CircleCrossIcon } from '@vlabs/icons';

import './ProgressCell.sass';

const ProgressCell = (status) => (
  <div className="ProgressCell">
    {status === 'error' && <CircleCrossIcon className="ProgressCell_canceled" />}
    {status === 'done' && <CircleCheckIcon className="ProgressCell_finished" />}
  </div>
);

export default ProgressCell;
