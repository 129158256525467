import React, { useEffect } from 'react';

import { Pages } from '@vlabs/uikit';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { resetPasswordLogout, resetPassword } from './store';

export function ResetPasswordPage() {
  const { t } = useTranslation();
  const history = useHistory();
  const { token } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetPasswordLogout);
  }, [dispatch]);

  const resetHandler = async ({ password }) => {
    try {
      await resetPassword({ token, password });
      history.push('/');
    } catch (error) {
      toast.error(t('auth:пароль не изменен'));
    }
  };

  return <Pages.ResetPasswordPage onSubmit={resetHandler} />;
}
