import React from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '../../controls/button/Button';
import { Input } from '../../controls/input/Input';
import { Divider } from '../../divider/Divider';

const ResetPasswordForm = ({
  password,
  passwordConfirmation,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const { register, handleSubmit, getValues, formState: { errors } } = useForm({
    defaultValues: {
      password,
      passwordConfirmation,
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Input
        errors={errors}
        placeholder={t('uikit:формы.поля.новый пароль')}
        type="password"
        {...register('password', {
          required: t('uikit:валидация.required'),
        })}
      />

      <Divider small />

      <Input
        errors={errors}
        placeholder={t('uikit:формы.поля.подтверждение нового пароля')}
        type="password"
        {...register('passwordConfirmation', {
          required: t('uikit:валидация.required'),
          validate: (val) => val === getValues().password || t('uikit:валидация.одинаковые пароли'),
        })}
      />

      <Divider small />

      <Button
        fullWidth
        type="submit"
      >
        {t('uikit:формы.действия.сохранение нового пароля')}
      </Button>
    </form>
  );
};

ResetPasswordForm.propTypes = {
  password: PropTypes.string,
  passwordConfirmation: PropTypes.string,
  onSubmit: PropTypes.func,
};

ResetPasswordForm.defaultProps = {
  password: '',
  passwordConfirmation: '',
  onSubmit: () => {},
};

export { ResetPasswordForm };
