import React from 'react';

import {
  PlayerPauseInCircleIcon,
  PlayerStopInCircleIcon,
  PlayerPlayInCircleIcon,
  VideoIcon,
  LoaderIcon,
  ClockIcon,
  CheckIcon,
  PlayerPauseIcon,
  RefreshRightIcon,
  TriangleExclamationIcon,
  CloudOffIcon,
  EyeOffIcon,
  Delete2Icon,
  CrossIcon,
  EyeIcon,
} from '@vlabs/icons';
import { Control } from '@vlabs/uikit';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import st from './cells.module.sass';
import * as store from './store';

const buttonPropTypes = {
  onClick: PropTypes.func.isRequired,
  value: PropTypes.shape({
    streamId: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
  }).isRequired,
};

const defaultButtonPropTypes = {};

function StartCellComponent({
  onClick,
  value: {
    streamId,
    disabled,
  },
}) {
  const { t } = useTranslation();
  return (
    <Control.RoundButton
      data-testid="button-play"
      disabled={disabled}
      icon={<PlayerPlayInCircleIcon data-testid="icon-play" />}
      kind="neutral"
      onClick={() => onClick(streamId)}
      title={t('lunaStreamsStreams:таблица.действия.старт')}
      variant="dimmed"
    />
  );
}

StartCellComponent.propTypes = buttonPropTypes;
StartCellComponent.defaultProps = defaultButtonPropTypes;

export const StartCell = connect(() => ({}), (dispatch) => ({
  onClick: (streamId) => dispatch(store.startStream(streamId)),
}))(StartCellComponent);

function PauseCellComponent({
  onClick,
  value: {
    streamId,
    disabled,
  },
}) {
  const { t } = useTranslation();
  return (
    <Control.RoundButton
      data-testid="button-pause"
      disabled={disabled}
      icon={<PlayerPauseInCircleIcon data-testid="icon-pause" />}
      kind="neutral"
      onClick={() => onClick(streamId)}
      title={t('lunaStreamsStreams:таблица.действия.пауза')}
      variant="dimmed"
    />
  );
}

PauseCellComponent.propTypes = buttonPropTypes;
PauseCellComponent.defaultProps = defaultButtonPropTypes;

export const PauseCell = connect(() => ({}), (dispatch) => ({
  onClick: (streamId) => dispatch(store.pauseStream(streamId)),
}))(PauseCellComponent);

function StopCellComponent({
  onClick,
  value: {
    streamId,
    disabled,
  },
}) {
  const { t } = useTranslation();
  return (
    <Control.RoundButton
      data-testid="button-stop"
      disabled={disabled}
      icon={<PlayerStopInCircleIcon data-testid="icon-stop" />}
      kind="neutral"
      onClick={() => onClick(streamId)}
      title={t('lunaStreamsStreams:таблица.действия.стоп')}
      variant="dimmed"
    />
  );
}

StopCellComponent.propTypes = buttonPropTypes;
StopCellComponent.defaultProps = defaultButtonPropTypes;

export const StopCell = connect(
  () => ({}),
  (dispatch) => ({
    onClick: (streamId) => dispatch(store.stopStream(streamId)),
  }),
)(StopCellComponent);

function WatchLiveStreamCell({
  value,
}) {
  const { t } = useTranslation();

  return (
    <Control.LinkButton
      aria-label="VideoIcon"
      href={value}
      kind="neutral"
      rel="noopener noreferrer"
      round
      target="_blank"
      title={t('lunaStreamsStreams:таблица.действия.liveStream')}
      variant="dimmed"
    >
      <VideoIcon className="Button__Icon" />
    </Control.LinkButton>
  );
}

WatchLiveStreamCell.defaultProps = {
  value: undefined,
};

export { WatchLiveStreamCell };

function ViewStreamCell({
  value,
}) {
  const { t } = useTranslation();
  return (
    <Control.LinkButton
      aria-label="VideoIcon"
      disabled={!value}
      href={value}
      rel="noopener noreferrer"
      round
      target="_blank"
      title={t('lunaStreamsStreams:таблица.действия.view')}
      variant="dimmed"
    >
      <EyeIcon className="Button__Icon" />
    </Control.LinkButton>
  );
}

ViewStreamCell.defaultProps = {
  value: undefined,
};

export { ViewStreamCell };

function StatusCell({ value }) {
  const iconsMap = {
    pending: <ClockIcon className={cn(st.Icon)} />,
    in_progress: <LoaderIcon className={cn(st.Icon, st.success)} />,
    done: <CheckIcon className={cn(st.Icon, st.success)} />,
    pause: <PlayerPauseIcon className={cn(st.Icon)} />,
    restart: <RefreshRightIcon className={cn(st.Icon)} />,
    cancel: <CrossIcon className={cn(st.Icon)} />,
    failure: <TriangleExclamationIcon className={cn(st.Icon, st.error)} />,
    handler_lost: <CloudOffIcon className={cn(st.Icon, st.error)} />,
    not_found: <EyeOffIcon className={cn(st.Icon, st.error)} />,
    deleted: <Delete2Icon className={cn(st.Icon, st.error)} />,
  };

  if (iconsMap[value.value] === undefined) {
    return (
      <div className={st.StatusCell}>
        {value.label}
      </div>
    );
  }

  return (
    <div className={st.StatusCell} title={value.label}>
      {iconsMap[value.value]}
      {value.label}
    </div>
  );
}

StatusCell.propTypes = {
  value: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }).isRequired,
};

export { StatusCell };
