import { apiContainer } from '@vlabs/api-bindings';

export async function getEventById(eventIds = []) {
  if (eventIds.length === 0) return {};

  const events = await apiContainer.lunaClient.events
    .getAll({ event_ids: eventIds, targets: 'face_detections', page_size: eventIds.length });
  const eventObj = {};
  events.forEach((event) => {
    eventObj[event.event_id] = event;
  });
  return eventObj;
}
