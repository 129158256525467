import React, { useState, useCallback, useEffect } from 'react';

import { apiContainer } from '@vlabs/api-bindings';
import { PersonIcon, ListIcon, FolderIcon, CircleInfoIcon } from '@vlabs/icons';
import { MatchTypeImage } from '@vlabs/shared/assets/images';
import { SampleWithActions } from '@vlabs/shared/components/sample/SampleWithActions';
import InfoRow from '@vlabs/shared/legacy-components/info-row/InfoRow';
import ListLink from '@vlabs/shared/legacy-components/list-link/ListLink';
import { routes } from '@vlabs/shared/navigation/routes';
import { calcSimilarityPercent, calcBadgeType } from '@vlabs/shared/utils';
import { Badge, Control } from '@vlabs/uikit';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { generatePath } from 'react-router-dom';

import { selectHandlers } from '@vlabs/pages/handlers/selectors';

import './FaceTopMatchCell.sass';

const ExternalIdLink = ({ external_id }) => {
  return (
    <Control.Link to={generatePath(routes.externalId.read, { externalId: external_id })}>
      {external_id}
    </Control.Link>
  );
};

const FaceTopMatchCellComponent = ({
  topMatch,
  onClick,
  handlerList,
}) => {
  const { t } = useTranslation();
  const [face, setFace] = useState({ ...topMatch, isExist: true });

  const fetchFace = useCallback(async () => {
    try {
      const { data } = await apiContainer.lunaClient.faces.get(topMatch.face_id);

      // Это нужно чтобы получить список в котором числится топМач
      const handler = handlerList?.find(({ handlerId }) => handlerId === topMatch.handler_id);
      const matchPolicy = handler?.matchPolicy;
      const listId = matchPolicy?.filter(({ label }) => label === topMatch?.label)
        .map(({ listId }) => listId)
        .filter((id) => id !== undefined);

      setFace({ ...data, topMatchList: listId, ...face });
    } catch ({ error }) {
      // Если лицо удалено - 'Object not found'
      if (error.response.data.error_code === 22002) {
        setFace({ ...face, isExist: false });
        console.error('Face not found:', error);
      } else {
        throw error;
      }
    }
  }, [topMatch?.face_id]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { fetchFace(); }, []);

  return (
    <>
      {face?.isExist === true && (
        <div className="FaceTopMatch__Sample">
          <SampleWithActions
            data-testid="topMatchSample"
            dataTip={t('events:проверьте настройки сценария')}
            imageUrl={face?.uiAvatarURL}
            onGoToClick={onClick}
            type="face"
          />
        </div>
      )}
      <div className="FaceTopMatch__Info">
        <Badge
          className="FaceTopMatch__SimilarityBadge"
          data-testid="TopMatch__SimilarityBadge"
          kind={calcBadgeType(face?.similarity)}
          value={calcSimilarityPercent(topMatch?.similarity)}
        />
        <InfoRow
          columnDirection
          data-testid="topMatchCell.matchType"
          icon={<img alt="match type icon" className="StaticIcon" src={MatchTypeImage} />}
          title={t('events:match type')}
          value={t('face')}
        />
        {face?.external_id && (
          <InfoRow
            columnDirection
            data-testid="topMatchCell.externalId"
            icon={<PersonIcon className="StaticIcon" />}
            title={t('external id')}
            value={<ExternalIdLink {...face} />}
          />
        )}
        {face?.user_data && (
          <InfoRow
            columnDirection
            data-testid="topMatchCell.userData"
            icon={<FolderIcon className="StaticIcon" />}
            title={t('information')}
            value={face.user_data}
          />
        )}
        {face?.topMatchList?.length > 0 && (
          <InfoRow
            columnDirection
            data-testid="topMatchCell.lists"
            icon={<ListIcon className="StaticIcon" />}
            title={t('list')}
            value={<ListLink lists={face?.topMatchList} />}
          />
        )}
        {!face?.isExist && (
          <div className="FaceTopMatchCell__DeletedFace">
            <CircleInfoIcon className="StaticIcon" />
            {t('faces:лицо удалено')}
          </div>
        )}
      </div>
    </>
  );
};

export const FaceTopMatchCell = connect(
  (state) => ({
    handlerList: selectHandlers(state).data,
  }))(FaceTopMatchCellComponent);
