import { apiContainer } from '@vlabs/api-bindings';

export const defaultFilterValues = {
  user_data: '',
  external_ids: '',
  event_ids: '',
  top_matching_candidates_label: '',
  top_similar_object_ids: '',
  face_ids: '',
  apparent_age: null,
  cities: '',
  areas: '',
  districts: '',
  streets: '',
  house_numbers: '',
  origin_longitude: '',
  longitude_delta: '',
  origin_latitude: '',
  latitude_delta: '',
  sources: [],
  handler_ids: [],
  liveness: [],
  deepfake: null,
  tags: [],
  gender: null,
  age: null,
  emotions: [],
  masks: [],
  upper_clothing_colors: [],
  lower_garment_types: [],
  lower_garment_colors: [],
  shoes_apparent_colors: [],
  headwear_states: [],
  headwear_apparent_colors: [],
  backpack_states: [],
  sleeve_lengths: [],
  apparent_gender: [],
  list_id: null,
  threshold: 80,
  limit: 100,
  track_ids: '',
};

export const extractReferenceInfo = async (reference) => {
  const { id, face_id: faceId, type } = reference;

  if (type === 'event') {
    const result = await apiContainer.lunaClient.events.get(id);

    if (result.length === 0) return [];

    return [result.data];
  }

  if (type === 'face_external_id') {
    const { data } = await apiContainer.lunaClient.faces.getAll({
      external_ids: id,
      page: 1,
      page_size: 100,
    });

    return data;
  }

  if (type === 'face') {
    const result = await apiContainer.lunaClient.faces.get(faceId || id);
    if (result.length === 0) return [];

    return [result.data];
  }

  return undefined;
};

export const mapMatchResults = (matchResults, referenceInfo) => {
  let referenceImage = referenceInfo?.[0]?.uiFaceDetection?.uiSampleURL || referenceInfo?.[0]?.uiAvatarURL;
  const referenceBodyImage = referenceInfo?.[0]?.uiBodyDetection?.uiSampleURL;
  const results = [];

  const handleMatches = (match, reference) => {
    if (!match.result) return [];
    if (reference.type === 'face_external_id') {
      referenceImage = referenceInfo?.find((info) => info.face_id === reference.face_id)?.uiAvatarURL;
    }

    const result = match.result.map((originalMatch) => ({
      ...originalMatch.face,
      ...originalMatch.event,
      similarity: originalMatch.similarity,
      reference,
      referenceImage,
      referenceBodyImage,
    }));
    results.push(...result);

    return undefined;
  };

  if (matchResults === undefined || matchResults?.length === 0) return matchResults;

  matchResults.forEach(({ matches, reference }) => matches.map((match) => handleMatches(match, reference)));

  return results.sort((a, b) => b.similarity - a.similarity);
};
