export const roles = {
  USER: 0,
  ADMIN: 2,
};

export const rbacRules = {
  [roles.USER]: {
    face: ['view', 'matching'],
    list: ['view'],
    event: ['view', 'matching'],
    verifier: ['view'],
    source: ['view'],
    streams: ['view'],
    face_sample: ['view'],
    body_sample: ['view'],
    resources: ['iso', 'liveness', 'sdk'],
    attribute: ['view'],
    handler: ['view'],
  },

  [roles.ADMIN]: {
    appInfo: ['view'],
    department: ['view'],
    thermo: ['read'],
    notification: ['view'],
    account: ['view'],
    face: ['matching', 'creation', 'view', 'deletion', 'modification'],
    list: ['deletion', 'creation', 'modification', 'view'],
    event: ['matching', 'creation', 'view'],
    attribute: ['matching', 'creation', 'view', 'deletion', 'modification'],
    handler: ['deletion', 'creation', 'modification', 'view'],
    verifier: ['deletion', 'creation', 'modification', 'view'],
    task: ['deletion', 'creation', 'modification', 'view'],
    face_sample: ['deletion', 'creation', 'view'],
    body_sample: ['deletion', 'creation', 'view'],
    image: ['deletion', 'creation', 'view'],
    object: ['deletion', 'creation', 'view'],
    token: ['deletion', 'creation', 'modification', 'view'],
    resources: ['iso', 'liveness', 'sdk'],
    emit_events: {
      allowed: 1,
      black_list: null,
      white_list: null,
    },
    lambdas: ['deletion', 'creation', 'modification', 'view'],
    streams: ['view', 'creation', 'modification', 'deletion'],
    source: ['view', 'creation', 'modification', 'deletion'],
  },
};
