import { apiContainer } from '@vlabs/api-bindings';

export const fetchAdditionalMatchData = async (matchList) => {
  if (!matchList) return;
  const results = await Promise.all(matchList.map(async (item) => {
    try {
      if (item.type === 'face') {
        const { data } = await apiContainer.lunaClient.faces.get(item.face_id, { targets: 'avatar,lists' });
        return { ...item, ...data };
      } if (item.type === 'event') {
        const { data } = await apiContainer.lunaClient.events.get(item.event_id, { targets: 'face_detections' });
        return { ...item, ...data };
      }
    } catch ({ error }) {
      if (error.response?.data?.error_code === 22002 || error.response?.data?.error_code === 23001) {
        return { ...item, isDeleted: true };
      }
      throw error;
    }
  }));

  return results;
};
