import React, { useState, useCallback, useEffect } from 'react';

import { optionPropType } from '@vlabs/shared';
import { PhotoUploadForm } from '@vlabs/shared/components/photo-upload-form';
import { Control, Divider, openConfirmPopup, Page } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useForm, FormProvider, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { getFaceDetectionsFromFile } from '../requests/getFaceDetectionsFromFile';
import { buildMatchList } from './buildMatchList';
import st from './FaceCreateForm.module.sass';
import { IsoQualityCheckForm } from './IsoQualityCheckForm';

export const FaceCreateForm = ({
  formFieldComponent = <IsoQualityCheckForm />,
  onSubmit,
  defaultValues,
}) => {
  const { t } = useTranslation();
  const [sampleId, setSampleId] = useState([]);
  const [detections, setDetections] = useState([]);
  const [isoResult, setIsoResult] = useState();
  const [shouldCheckISO, setShouldCheckISO] = useState();
  const form = useForm({ defaultValues });
  const file = useWatch({ name: 'file', control: form.control });

  useEffect(() => {
    if (!file) return;

    const fetchFaceDetections = async () => {
      const faceDetections = await getFaceDetectionsFromFile(file.raw);
      if (faceDetections.length) {
        setDetections(faceDetections);
      } else {
        form.setError('file', { message: t('выбранное изображение не содержит лиц') });
      }
    };

    fetchFaceDetections();
  }, [file, form, t]);

  const onFileSelect = (newFile) => {
    if (!newFile) return;
    form.setValue('file', newFile);
  };

  const onResetFile = useCallback(() => {
    form.clearErrors('file');
    form.setValue('file', undefined);
    setDetections([]);
    setSampleId([]);
  }, [form]);

  const createFace = useCallback(async (values) => {
    if (!sampleId?.length) {
      form.setError('file', { message: t('faces:необходимо выбрать хотя бы одно лицо') });
      return;
    }

    if (values.external_id || values.lists?.length) {
      const matchList = await buildMatchList({ formValues: values, detections, sampleId });
      if (matchList) {
        openConfirmPopup({
          title: t('faces:face creation.duplicate photos'),
          message: matchList,
          type: 'warning',
          onConfirm: () => onSubmit({ ...values, sample_id: sampleId }),
        });
        return;
      }
    }

    onSubmit({ ...values, sample_id: sampleId });
  }, [onSubmit, sampleId, detections, form, t]);

  const context = {
    detections,
    sampleId,
    isoResult,
    setIsoResult,
    shouldCheckISO,
    setShouldCheckISO,
  };

  return (
    <FormProvider form={form} {...context}>
      <form onSubmit={form.handleSubmit(createFace)}>
        <Page className={st.Root}>
          <h5>{t('faces:форма создания.заголовок')}</h5>
          <Divider small />

          <div className={st.ColumnsContainer}>
            <div className={st.PhotoUploadColumn}>
              <PhotoUploadForm
                detections={detections}
                errorProps={{ errors: form.formState.errors, register: form.register }}
                file={file}
                onFileSelect={onFileSelect}
                onResetFile={onResetFile}
                onSelectSampleId={(id) => setSampleId(id)}
              />
            </div>
            <div className={st.FormFieldColumn}>
              {formFieldComponent}
            </div>
          </div>

          <Divider small />
          <Control.Button
            disabled={!isoResult?.status && shouldCheckISO}
            fullWidth
            type="submit"
          >
            {t('форма.submit')}
          </Control.Button>
        </Page>
      </form>
    </FormProvider>
  );
};

FaceCreateForm.propTypes = {
  formFieldComponent: PropTypes.node.isRequired,
  onSubmit: PropTypes.func.isRequired,
  defaultValues: PropTypes.shape({
    external_id: PropTypes.string,
    lists: PropTypes.arrayOf(optionPropType),
  }),
};

FaceCreateForm.defaultProps = {
  defaultValues: {},
};
