import { createSlice } from '@reduxjs/toolkit';
import { apiContainer } from '@vlabs/api-bindings';
import i18next from 'i18next';
import { toast } from 'react-toastify';

import { SLICE_NAME } from '.';

const initialState = {
  current: undefined,
  lunaSourcesOptions: [],
};

const store = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setCurrentSource(state, { payload }) {
      state.current = payload;
    },
    setLunaSourcesOptions(state, { payload }) {
      state.lunaSourcesOptions = payload;
    },
  },
});

export default store.reducer;

export const fetchSource = (sourceId) => async (dispatch) => {
  const source = await apiContainer.vaReporterClient.sources.get(sourceId);

  dispatch(store.actions.setCurrentSource(source));
};

export const fetchLunaSourcesOptions = async (dispatch) => {
  const lunaSources = await apiContainer.lunaClient.events.getStatistic();

  dispatch(store.actions.setLunaSourcesOptions(lunaSources));
};

export const submitSource = (plan_id, { id, image, ...values }) => async () => {
  let source;

  if (id) {
    source = await apiContainer.vaReporterClient.sources.replace(id, values);
    toast.success(i18next.t('sources:api.успех.обновление'));
  } else {
    source = await apiContainer.vaReporterClient.sources.create({ plan_id, ...values });
    toast.success(i18next.t('sources:api.успех.создание'));
  }

  if (image[0]) {
    await apiContainer.vaReporterClient.sources.uploadImage(source?.id, image[0]);
  }
};

export const calibrate = (source_id, plan_id, points) => async (dispatch, getState) => {
  const { [SLICE_NAME]: {
    current: { points: _, ...current },
  } } = getState();
  const params = {
    ...current,
    point_mappings: points.map((pair) => ({
      source_x: pair[0].x,
      source_y: pair[0].y,
      plan_x: pair[1].x,
      plan_y: pair[1].y,
    })),
    source_id,
    plan_id,
  };

  const data = await apiContainer.vaReporterClient.sources.calibrate(source_id, params);

  toast.success(i18next.t('sources:api.успех.калибровка'));
  dispatch(store.actions.setCurrentSource(data));
};

export const deleteSource = async (sourceId) => {
  await apiContainer.vaReporterClient.sources.delete(sourceId);
  toast.success(i18next.t('sources:api.успех.удаление'));
};
