import React, { useMemo } from 'react';

import { ZoomInIcon } from '@vlabs/icons';
import { ButtonWithPermissions } from '@vlabs/shared/components/button-with-permissions/ButtonWithPermissions';
import { permissions } from '@vlabs/shared/config';
import { useImageCheck } from '@vlabs/shared/hooks';
import { ButtonScrimGroup, ButtonScrimItem } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export const FaceAction = ({
  imageUrl,
}) => {
  const { t } = useTranslation();
  const url = useMemo(() => ({ avatar: imageUrl }), [imageUrl]);
  const error = useImageCheck(url);

  const isAvatarDisabled = !imageUrl || error.avatar;

  return (
    <ButtonScrimGroup childrenAs={ButtonWithPermissions}>
      <ButtonScrimItem
        as={imageUrl ? 'a' : 'div'}
        disabled={isAvatarDisabled}
        href={imageUrl}
        icon={<ZoomInIcon />}
        permissions={{ rules: [permissions.faceSample.view] }}
        round
        title={t('посмотреть в полном размере')}
        variant="dimmed"
      />
    </ButtonScrimGroup>
  );
};

FaceAction.propTypes = {
  imageUrl: PropTypes.string,
};
FaceAction.defaultProps = {
  imageUrl: undefined,
};
