import React from 'react';

const IconInner = ({
  name,
  viewBox,
  ...restProps
}) => {
  const [, , width, height] = (viewBox || '0 0 24 24').split(' ');

  return (
    <svg
      aria-label={name}
      data-testid={name}
      fill="none"
      height={height}
      viewBox={viewBox}
      width={width}
      {...restProps}
    />
  );
};

IconInner.displayName = 'Icon';

export { IconInner };
