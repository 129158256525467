import i18next from 'i18next';

export const generatePlanName = (currentPlanId, plan) => {
  let planName = i18next.t('sources:источники плана');
  if (currentPlanId === plan?.id) {
    planName = plan?.name;
  }

  return planName;
};

export const createBreadcrumbs = ({ route, plan, sourceName = '' }) => {
  const { path, params: { planId, calibrationId } } = route;

  const result = [
    { caption: i18next.t('планы'), link: '/plans' },
    { caption: generatePlanName(planId, plan), link: `/plans/${planId}/calibrations` },
  ];
  if (path.startsWith('/plans/:planId/calibrations/:calibrationId')) {
    result.push(
      { caption: i18next.t('sources:калибрация источника', { sourceName }), link: `/plans/${planId}/calibrations/${calibrationId}` },
    );
  }
  if (path.startsWith('/plans/:planId/calibrations/:calibrationId/validation')) {
    result.push(
      { caption: i18next.t('sources:валидация'), link: `/plans/${planId}/calibrations/${calibrationId}/validation` },
    );
  }

  result[result.length - 1].link = undefined;

  return result;
};
