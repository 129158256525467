import React from 'react';

import PropTypes from 'prop-types';

import { Panel } from '../panel/Panel';

import './Stepper.sass';

const Step = ({
  children,
  title,
  stepName, // stepName используется в компоненте Stepper
  ...restProps
}) => {
  return (
    <>
      {typeof title !== 'string' && title}

      {typeof title === 'string' && (
        <h5 className="Stepper__PageTitle">
          {title}
        </h5>
      )}

      <Panel {...restProps}>
        {children}
      </Panel>
    </>
  );
};

Step.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  stepName: PropTypes.string,
  className: PropTypes.string,
};

Step.defaultProps = {
  title: undefined,
  stepName: undefined,
  className: undefined,
};

export { Step };
