import React, { useState, useEffect } from 'react';

import { CameraOffIcon } from '@vlabs/icons';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import st from './PreviewCell.module.sass';

const STREAM_REFRESH_TIMEOUT_MS = 15000;

function PreviewCell({ value }) {
  const { t } = useTranslation();
  const [isPreviewExists, setIsPreviewExists] = useState(!!value?.uiUrl);
  const [previewUrl, setPreviewUrl] = useState(value?.uiUrl);

  useEffect(() => {
    if (!value?.uiUrl || !isPreviewExists) return undefined;
    let time = 0;
    const intervalId = setInterval(() => {
      setPreviewUrl(`${value?.uiUrl}?t=${time}`);
      time += 1;
    }, STREAM_REFRESH_TIMEOUT_MS);

    return () => { clearInterval(intervalId); };
  }, [value, isPreviewExists]);

  if (!isPreviewExists) {
    return (
      <div
        className={cn(st.Preview, st.Preview_noPhoto)}
        data-testid="streamPreviewNoPhoto"
      >
        <CameraOffIcon />
        {t('превью.отсутствует')}
      </div>
    );
  }

  return (
    <img
      alt={t('превью.alt')}
      className={st.Preview}
      data-testid="streamPreview"
      key={previewUrl}
      onError={() => { setIsPreviewExists(false); }}
      src={previewUrl}
    />
  );
}

PreviewCell.propTypes = {
  value: PropTypes.shape({
    uiUrl: PropTypes.string,
  }),
};

PreviewCell.defaultProps = {
  value: undefined,
};

export { PreviewCell };
