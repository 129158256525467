import React, { useEffect } from 'react';

import { EVENT_OPTIONS } from '@vlabs/api-bindings/src/constants';
import { archiveTypes } from '@vlabs/shared/config';
import SearchByEvents from '@vlabs/shared/legacy-components/search-by/SearchByEvents';
import SearchByFaces from '@vlabs/shared/legacy-components/search-by/SearchByFaces';
import validate from '@vlabs/shared/validators';
import { Divider, Control, Grid, GridCol, GridRow } from '@vlabs/uikit';
import { useFormContext, useFormState, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { matchPolicyByOrigin } from './defaultValues';

export const StepPackageIdentification = () => {
  const { t } = useTranslation();
  const { control, register, unregister, setValue } = useFormContext();
  const { errors } = useFormState();
  const candidatesOriginValue = useWatch({ name: 'origin' })?.value;

  useEffect(() => {
    unregister('content.handler.policies.matchPolicy');
    setValue('content.handler.policies.matchPolicy', matchPolicyByOrigin[candidatesOriginValue]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidatesOriginValue, unregister]);

  return (
    <>
      <h6>{t('настройки задачи')}</h6>
      <Divider />

      {/* FIXME убрать проверку на архив когда обновим компонент в ките */}
      <Control.FileInput
        label={t('tasks:lp.packageIdentification.source.label')}
        {...register('file', {
          required: validate.required(),
          validate: (file) => {
            if (!archiveTypes.includes(file[0].type)) {
              return t('валидация.неподходящий формат файла');
            }
            return true;
          },
        })}
        accept=".zip,.rar,.7zip"
        errors={errors}
      />
      <Divider small />

      <Control.Select
        autoFocus
        control={control}
        id="content.handler.policies.matchPolicy.origin"
        label={t('tasks:lp.packageIdentification.content.handler.policies.matchPolicy.origin.label')}
        name="origin"
        options={EVENT_OPTIONS.raw}
      />

      <Divider small />
      <div className="Subtitle-1">{t('tasks:lp.packageIdentification.headers.candidates filters')}</div>
      <Divider small />

      {candidatesOriginValue === 'faces' && <SearchByFaces filterName="content.handler.policies.matchPolicy" />}
      {candidatesOriginValue === 'events' && <SearchByEvents filterName="content.handler.policies.matchPolicy" />}

      <Divider small />
      <div className="Subtitle-1">{t('tasks:lp.packageIdentification.headers.additional candidates filters')}</div>
      <Divider small />

      <Grid>
        <GridRow>
          <GridCol>
            <Control.Input
              id="content.handler.policies.matchPolicy.threshold"
              label={t('tasks:lp.packageIdentification.content.handler.policies.matchPolicy.threshold.label')}
              placeholder={t('tasks:lp.packageIdentification.content.handler.policies.matchPolicy.threshold.placeholder')}
              {...register('content.handler.policies.matchPolicy.threshold', {
                min: validate.gte(0),
                max: validate.lte(100),
                validate: validate.number(),
              })}
              errors={errors}
            />
          </GridCol>

          <GridCol>
            <Control.Input
              id="content.handler.policies.matchPolicy.limit"
              label={t('tasks:lp.packageIdentification.content.handler.policies.matchPolicy.limit.label')}
              placeholder={t('tasks:lp.packageIdentification.content.handler.policies.matchPolicy.limit.placeholder')}
              {...register('content.handler.policies.matchPolicy.limit', {
                min: validate.gte(1),
                max: validate.lte(100),
                validate: validate.integer(),
              })}
              errors={errors}
            />
          </GridCol>
        </GridRow>
      </Grid>
      <Divider small />
    </>
  );
};

StepPackageIdentification.fieldNames = [
  'file',
  'content.handler',
];
