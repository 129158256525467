import React from 'react';

import { DEEPFAKE_STATE_OPTIONS, GENDER_OPTIONS, EMOTION_OPTIONS, MASK_OPTIONS, AGE_OPTIONS } from '@vlabs/api-bindings/src/constants';
import { LIVENESS_OPTIONS } from '@vlabs/api-bindings/src/luna-client/constants';
import validate from '@vlabs/shared/validators';
import { Control, Divider } from '@vlabs/uikit';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export function FaceAttributesFilterForm() {
  const { t } = useTranslation();
  const { register, control, formState: { errors } } = useFormContext();

  return (
    <>
      <Control.Select
        control={control}
        id="gender"
        isClearable
        label={t('gender')}
        name="gender"
        options={GENDER_OPTIONS.raw}
      />
      <Divider small />

      <Control.Select
        control={control}
        id="age"
        isClearable
        label={t('age')}
        name="age"
        options={AGE_OPTIONS.raw}
      />
      <Divider small />

      <Control.Select
        control={control}
        id="emotions"
        isClearable
        isMulti
        label={t('emotion')}
        name="emotions"
        options={EMOTION_OPTIONS.raw}
      />
      <Divider small />

      <Control.Select
        control={control}
        id="masks"
        isClearable
        isMulti
        label={t('mask')}
        name="masks"
        options={MASK_OPTIONS.raw}
      />
      <Divider small />

      <Control.Select
        control={control}
        id="liveness"
        isMulti
        label={t('Liveness')}
        name="liveness"
        options={LIVENESS_OPTIONS.raw}
      />
      <Divider small />

      <Control.Select
        control={control}
        id="deepfake"
        isMulti
        label={t('deepfake')}
        name="deepfake"
        options={DEEPFAKE_STATE_OPTIONS.raw}
      />
      <Divider small />

      <Control.Input
        id="face_ids"
        label={t('id лиц событий')}
        {...register('face_ids', { validate: validate.multipleUuid() })}
        errors={errors}
      />
    </>
  );
}
