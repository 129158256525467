import { loadProgressBar } from '@vlabs/axios-progress-bar';
import Axios from 'axios';
import formatISO from 'date-fns/formatISO';
import qs from 'qs';

import { createReport } from './mappers/schemas/createReport';
import { putCalibration } from './mappers/schemas/putCalibration';

const pointsFromApi = (data) => data.point_mappings?.map(
  (point) => [
    { x: point.source_x, y: point.source_y },
    { x: point.plan_x, y: point.plan_y },
  ],
) || [];

const calibrationFromApi = (data, urlBuilder) => {
  const res = { ...data };

  if (data?.source_image_url) res.source_image_url = urlBuilder(data.source_image_url);
  if (data?.source_name) res.source_name = { value: data.source_name, label: data.source_name };
  res.points = pointsFromApi(data);
  return res;
};

const plansFromApi = (data, urlBuilder) => {
  const res = { ...data };

  if (data?.image_url) res.plan_image_url = urlBuilder(data.image_url);
  res.points = pointsFromApi(data);
  return res;
};

export class VAReporterClient {
  constructor() {
    this.baseURL = '/api/va-reporter';
    this.http = Axios.create({
      baseURL: this.baseURL,
      withCredentials: true,
      paramsSerializer: (params) => (
        qs.stringify(params, {
          arrayFormat: 'comma',
          serializeDate: formatISO,
        })),
    });
    loadProgressBar('', this.http);
  }

  healthcheck() {
    return this.http.head('/').catch((e) => {
      if (e.response.status >= 500) throw e;
    });
  }

  get plans() {
    const prefix = 'plans';
    return {
      getAll: async (params) => {
        const { data: { plans } } = await this.http.get(`/${prefix}`, { params });

        return plans?.map((plan) => plansFromApi(plan, this.plans.getImageURL));
      },
      get: async (id) => {
        const { data } = await this.http.get(`/${prefix}/${id}`);
        return plansFromApi(data, this.plans.getImageURL);
      },
      create: (params) => this.http.post(`/${prefix}`, params),
      update: (id, params) => this.http.put(`/${prefix}/${id}`, params),
      uploadImage: (id, params) => this.http.put(`/${prefix}/${id}/image`, params, {
        headers: { 'Content-type': params.type },
      }),
      delete: (id) => this.http.delete(`/${prefix}/${id}`),
      getImageURL: (url) => {
        if (url) {
          return `${this.baseURL}${url}`;
        }
        return undefined;
      },
      getFullPlanImageURL: (id) => {
        if (id) {
          return `${this.baseURL}/plans/${id}/image`;
        }
        return undefined;
      },
    };
  }

  get sources() {
    const prefix = 'calibrations';
    return {
      getAll: async (params) => {
        const { data: { calibrations } } = await this.http.get(`/${prefix}`, { params });
        return calibrations?.map((calibration) => calibrationFromApi(calibration, this.sources.getImageURL));
      },
      get: async (calibrationId) => {
        const { data } = await this.http.get(`/${prefix}/${calibrationId}`);

        return calibrationFromApi(data, this.sources.getImageURL);
      },
      create: async (payload) => {
        const { data } = await this.http.post(`/${prefix}`, putCalibration(payload));
        return data;
      },
      replace: async (calibrationId, payload) => {
        const { data } = await this.http.put(`/${prefix}/${calibrationId}`, putCalibration(payload));
        return data;
      },
      uploadImage: (calibrationId, params) => this.http.put(`/${prefix}/${calibrationId}/source-image`, params, {
        headers: { 'Content-type': params.type },
      }),
      calibrate: async (calibrationId, params) => {
        const { data } = await this.http.put(`/${prefix}/${calibrationId}`, putCalibration(params));

        return calibrationFromApi(data, this.sources.getImageURL);
      },
      delete: (calibrationId) => this.http.delete(`/${prefix}/${calibrationId}`),
      getImageURL: (url) => {
        if (url) {
          return `${this.baseURL}${url}`;
        }
        return undefined;
      },
      transform: async (calibrationId, params) => {
        const { data: { source, plan } } = await this.http.post(`/${prefix}/${calibrationId}/transform`, params);
        return {
          source: source.map(([x, y]) => ({ x, y })),
          plan: plan.map(([x, y]) => ({ x, y })),
        };
      },
    };
  }

  get reports() {
    const prefix = 'reports';
    return {
      getAll: async (params) => {
        const { data: { reports } } = await this.http.get(`/${prefix}`, { params });

        return reports.map((report) => ({
          ...report,
          image_url: this.reports.getImageURL(report.image_url),
          progress: `${report.progress * 100}%`,
        }));
      },
      create: (params) => this.http.post(`/${prefix}`, createReport(params)),
      delete: (id) => this.http.delete(`/${prefix}/${id}`),
      getImageURL: (imageUrl) => {
        if (imageUrl) {
          return `${this.baseURL}/${imageUrl}`;
        }
        return undefined;
      },
    };
  }
}
