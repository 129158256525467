import { EVENT_OPTIONS, DESCRIPTOR_TYPE_OPTIONS } from '@vlabs/api-bindings/src/constants';

import { filterColumnsTree, filterColumnsValues, filterColumnsKeyMap } from './helpers';

const defaultsFacesColumns = ['face_id', 'user_data', 'external_id', 'create_time', 'avatar', 'event_id'];
const allFacesColumns = [
  ...defaultsFacesColumns,
  'lists',
];
const defaultsEventsColumns = [
  'event_id', 'create_time', 'end_time', 'source', 'handler_id',
  'face_id', 'external_id', 'user_data', 'age', 'gender', 'emotion', 'mask',
  'track_id',
  'top_match',
  'district', 'area', 'city', 'street', 'house_number', 'geo_position',
];
const allEventsColumns = [
  ...defaultsEventsColumns,
  'tags', 'meta', 'face_detections', 'attach_result',
  'liveness', 'body_detections', 'upper_body', 'body_basic_attributes',
  'accessories', 'lower_body', 'match_result',
];

const getDefaultValuesForFaces = (filters = {}) => ({
  content: {
    objects_type: EVENT_OPTIONS.faces,
    columns: filterColumnsValues(defaultsFacesColumns),
    csv_delimiter: ',',
    save_images: 0,
    filters,
    descriptor: {
      type: DESCRIPTOR_TYPE_OPTIONS.face,
    },
  },
  columnsSettings: {
    all: allFacesColumns,
    defaults: defaultsFacesColumns,
    keyMap: filterColumnsKeyMap(allFacesColumns, EVENT_OPTIONS.faces.value),
    tree: filterColumnsTree(allFacesColumns, EVENT_OPTIONS.faces.value),
  },
  notification_policy: {
    task_callbacks: [],
  },
});

const getDefaultValuesForEvents = (filters = {}) => ({
  content: {
    objects_type: EVENT_OPTIONS.events,
    columns: filterColumnsValues(defaultsEventsColumns),
    csv_delimiter: ',',
    save_images: 0,
    filters,
    descriptor: {
      type: DESCRIPTOR_TYPE_OPTIONS.face,
    },
  },
  columnsSettings: {
    all: allEventsColumns,
    defaults: defaultsEventsColumns,
    keyMap: filterColumnsKeyMap(allEventsColumns, EVENT_OPTIONS.events.value),
    tree: filterColumnsTree(allEventsColumns, EVENT_OPTIONS.events.value),
  },
  notification_policy: {
    task_callbacks: [],
  },
  meta: [],
});

export const getDefaultValuesByObjectType = (objectType, filters) => {
  if (objectType === 'faces') return getDefaultValuesForFaces(filters);
  return getDefaultValuesForEvents(filters);
};
