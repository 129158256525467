import React from 'react';

import { EVENT_ENUMS } from '@vlabs/api-bindings/src/luna-client/constants';
import { upperBodyImages } from '@vlabs/shared/assets/images';
import cn from 'classnames';
import { Trans, useTranslation } from 'react-i18next';

import { Card } from './Card';
import st from './Card.module.sass';

const UPPER_BODY_IMAGES = {
  long: upperBodyImages.LongSleeve,
  short: upperBodyImages.ShortSleeve,
};

export const UpperBodyCard = ({ values }) => {
  const { t } = useTranslation();

  const img = UPPER_BODY_IMAGES[values.sleeve.length] || upperBodyImages.UnknownSleeves;
  const sleeveType = EVENT_ENUMS.UPPER_BODY.SLEEVE[values?.sleeve?.length];
  const upperColorsLabel = (values.upper_clothing.colors || []).map(
    (color) => EVENT_ENUMS.UPPER_BODY.UPPER_CLOSING_COLOR[color]?.label,
  ).join(', ');

  return (
    <Card
      back={(
        <div className={st.Tooltip}>
          <Trans i18nKey="events:карточка.верх" values={{ type: sleeveType?.label, color: upperColorsLabel }} />
        </div>
    )}
      front={(
        <div className={st.Root}>
          <div
            className={st.ImageBlock}
            data-testid="eventAttributesCell.upperClothingColors"
          >
            {t('events:upper_clothing_colors')}
            <img alt={`${sleeveType.value} sleeve`} className={st.Image} src={img} />
            {sleeveType?.label}
          </div>
          {(values.upper_clothing.colors || []).slice(0, 2).map(
            (color) => (
              <div className={st.ColorBlock}>
                <div className={cn(st.Color, st[`Color_${color}`])} />
                <div className={st.ColorLabel}>{EVENT_ENUMS.UPPER_BODY.UPPER_CLOSING_COLOR[color]?.label}</div>
              </div>
            ))}
        </div>
    )}
    />
  );
};
