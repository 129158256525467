import React from 'react';

import { apiContainer } from '@vlabs/api-bindings';
import { PersonCrossIcon } from '@vlabs/icons';
import { ButtonWithPermissions } from '@vlabs/shared/components/button-with-permissions/ButtonWithPermissions';
import { GarbageCollectionStepperForm } from '@vlabs/shared/widgets/tasks/garbage-collection/GarbageCollectionStepperForm';
import { useModal, openConfirmPopup } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

function DeleteFacesFromListWidget({ afterSubmit, listId, permissions }) {
  const { t } = useTranslation();
  const deleteFacesModal = useModal();

  const onDeleteAllFacesInListFormSubmit = (params) => {
    openConfirmPopup({
      title: t('tasks:GCTask.подтверждение.заголовок'),
      message: t('tasks:GCTask.подтверждение.сообщение'),
      type: 'delete',
      onConfirm: async () => {
        await apiContainer.lunaClient.tasks.createGCTask(params);
        toast.info(t('tasks:api.GCTask.создание.успех'));
        deleteFacesModal.close();
        if (afterSubmit)afterSubmit();
      },
      confirmLabel: t('кнопка.ок'),
    });
  };

  return (
    <>
      {deleteFacesModal.wrap(<GarbageCollectionStepperForm
        filters={{ list_id: listId }}
        onSubmit={onDeleteAllFacesInListFormSubmit}
      />)}
      <ButtonWithPermissions
        data-testid="deleteFacesCell"
        icon={<PersonCrossIcon />}
        kind="negative"
        onClick={deleteFacesModal.open}
        permissions={permissions}
        round
        title={t('lists:таблица.кнопки.удалить все лица из списка')}
        variant="dimmed"
      />
    </>

  );
}

DeleteFacesFromListWidget.propTypes = {
  afterSubmit: PropTypes.func,
  listId: PropTypes.string.isRequired,
  permissions: PropTypes.shape({
    rules: PropTypes.arrayOf(PropTypes.string).isRequired,
    method: PropTypes.oneOf(['oneof', 'allof']),
  }),
};

DeleteFacesFromListWidget.defaultProps = {
  afterSubmit: undefined,
  permissions: {},
};

export default DeleteFacesFromListWidget;
