import React from 'react';

import { EstimationsFaceSubForm } from '@vlabs/pages/handlers/forms';
import StepTitle from '@vlabs/shared/legacy-components/steps/StepTitle';
import { Step } from '@vlabs/uikit';
import i18next from 'i18next';

import st from './shared.module.sass';

function StepFaceEstimations() {
  return (
    <Step
      className={st.Step}
      title={(
        <StepTitle>
          {i18next.t('handlers:какие атрибуты лица необходимо определять?')}
        </StepTitle>
      )}
    >
      <EstimationsFaceSubForm />
    </Step>
  );
}

StepFaceEstimations.fieldNames = [
  'estimate',
  'extract',
];

export { StepFaceEstimations };
