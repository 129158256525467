import { api } from '@vlabs/api-bindings/src/i18n';
import accounts from '@vlabs/pages/accounts/i18n/accounts_en.json';
import featuresPage from '@vlabs/pages/app/i18n/featuresPage_en.json';
import info from '@vlabs/pages/app/i18n/info_en.json';
import plugins from '@vlabs/pages/app/i18n/plugins_en.json';
import auth from '@vlabs/pages/auth/i18n/auth_en.json';
import checks from '@vlabs/pages/checks/i18n/checks_en.json';
import departments from '@vlabs/pages/departments/i18n/departments_en.json';
import events from '@vlabs/pages/events/i18n/events_en.json';
import externalID from '@vlabs/pages/external-id/i18n/externalID_en.json';
import faces from '@vlabs/pages/faces/i18n/faces_en.json';
import handlers from '@vlabs/pages/handlers/i18n/handlers_en.json';
import lists from '@vlabs/pages/lists/i18n/lists_en.json';
import vlNotifier from '@vlabs/pages/notifications/i18n/vlNotifier_ru.json';
import searchByDocuments from '@vlabs/pages/search-by-documents/i18n/searchByDocuments_en.json';
import search from '@vlabs/pages/search/i18n/search_en.json';
import sources from '@vlabs/pages/sources/i18n/sources_en.json';
import lunaStreams from '@vlabs/pages/sources/luna-streams/translations/en';
import tasks from '@vlabs/pages/tasks/i18n/tasks_en.json';
import timeTracking from '@vlabs/pages/time-tracking-tasks/i18n/timeTracking_en.json';
import { vaReporter } from '@vlabs/pages/va-reporter/i18n';
import verifiers from '@vlabs/pages/verifiers/i18n/verifiers_en.json';
import detectionSelector from '@vlabs/shared/components/detection-selector/detectionSelector_en.json';
import { shared } from '@vlabs/shared/i18n';
import { i18n as uikitI18n } from '@vlabs/uikit';

export default {
  accounts,
  auth,
  departments,
  events,
  externalID,
  faces,
  featuresPage,
  lists,
  handlers,
  info,
  checks,
  search,
  searchByDocuments,
  detectionSelector,
  sources,
  tasks,
  verifiers,
  vlNotifier,
  plugins,
  timeTracking,
  ...lunaStreams,
  ...api.en,
  ...vaReporter.en,
  ...shared.en,
  ...uikitI18n.en,
};
