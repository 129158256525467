import React, { memo } from 'react';

import { IconInner } from '../../IconInner';

const ChevronLeftIconInner = (props) => (
  <IconInner name="ChevronLeftIcon" viewBox="0 0 24 24" {...props}>
    <path d="m15 18-6-6 6-6" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
  </IconInner>
);

export const ChevronLeftIcon = memo(ChevronLeftIconInner);
