import React from 'react';

import { EVENT_ENUMS } from '@vlabs/api-bindings/src/luna-client/constants';
import { shoesImages } from '@vlabs/shared/assets/images';
import cn from 'classnames';
import { useTranslation, Trans } from 'react-i18next';

import { Card } from './Card';
import st from './Card.module.sass';

export const ShoesCard = ({ shoes: { apparent_color } }) => {
  const { t } = useTranslation();

  const shoesColor = EVENT_ENUMS.LOWER_BODY.SHOES.APPARENT_COLOR[apparent_color].label;
  return (
    <Card
      back={(
        <div className={st.Tooltip}>
          <Trans i18nKey="events:карточка.цвет обуви" values={{ color: shoesColor }} />
        </div>
      )}
      front={(
        <div className={st.Root}>
          <div
            className={st.ImageBlock}
            data-testid="eventAttributesCell.shoes"
          >
            {t('events:shoes_colors')}
            <img alt="shoes icon" className={st.Image} src={shoesImages.Shoes} />
            <div />
          </div>
          <div className={st.ColorBlock}>
            <div className={cn(st.Color, st[`Color_${apparent_color}`])} />
            <div className={st.ColorLabel}>
              {shoesColor}
            </div>
          </div>
        </div>
      )}
    />
  );
};
