import { isUndefined } from 'lodash';

import { Schema, get, isEmpty } from '../../json-transform';

export const createStream = new Schema({
  account_id: get('account_id').asString(),
  name: get('name').ifEmptyString(undefined),
  description: get('description').ifEmptyString(undefined),
  group_name: get('group_name.value').asString().ifEmptyString(undefined),
  status: get('status.value').asString(),
  data: {
    type: get('data.type.value').asString(),
    reference: get('data.reference').asString(),
    mask: get('data.mask').asString().ifEmptyString(undefined),
    roi: get('data.roi').ifEmpty(undefined),
    droi: get('data.droi').ifEmpty(undefined),
    rotation: get('data.rotation.value').asInt(),
    preferred_program_stream_frame_width: get('data.preferred_program_stream_frame_width').asInt().ifNaN(undefined),
    endless: get('data.endless').asBoolean(),
  },
  event_handler: new Schema({
    origin: get('event_handler.origin').asString(),
    api_version: get('event_handler.api_version').asInt(),
    bestshot_handler: {
      handler_id: get('event_handler.bestshot_handler.handler_id').ifEmptyString(undefined),
    },
    detection_handler: new Schema({
      handler_id: get('event_handler.detection_handler.handler_id').ifEmptyString(undefined),
    }).omitBy(isUndefined)
      .omitBy(isEmpty),
    frame_store: get('event_handler.frame_store').ifEmptyString(undefined),
    authorization: new Schema({
      token: get('event_handler.authorization.token').ifEmptyString(undefined),
    }).omitBy(isUndefined)
      .omitBy(isEmpty),
  }).omitBy(isUndefined)
    .omitBy(isEmpty),
  policies: {
    sending: {
      time_period_of_searching: get('policies.sending.time_period_of_searching').asInt().ifNaN(undefined),
      silent_period: get('policies.sending.silent_period').asInt().ifNaN(undefined),
      type: get('policies.sending.type.value').asString(),
      number_of_bestshots_to_send: get('policies.sending.number_of_bestshots_to_send').asInt().ifNaN(undefined),
      send_only_full_set: get('policies.sending.send_only_full_set').asBoolean(),
      delete_track_after_sending: get('policies.sending.delete_track_after_sending').asBoolean(),
    },
    primary_track_policy: {
      use_primary_track_policy: get('policies.primary_track_policy.use_primary_track_policy').asBoolean(),
      best_shot_min_size: get('policies.primary_track_policy.best_shot_min_size').asInt().ifNaN(undefined),
      best_shot_proper_size: get('policies.primary_track_policy.best_shot_proper_size').asInt().ifNaN(undefined),
    },
    liveness: {
      use_mask_liveness_filtration: get('policies.liveness.use_mask_liveness_filtration').asBoolean(),
      use_flying_faces_liveness_filtration: get('policies.liveness.use_flying_faces_liveness_filtration').asBoolean(),
      liveness_mode: get('policies.liveness.liveness_mode.value').asInt(),
      number_of_liveness_checks: get('policies.liveness.number_of_liveness_checks').asInt().ifNaN(undefined),
      liveness_threshold: get('policies.liveness.liveness_threshold').asFloat().ifNaN(undefined),
      livenesses_weights: get('policies.liveness.livenesses_weights').asArrayFromString().asArrayOfFloat().ifEmpty(undefined),
      mask_backgrounds_count: get('policies.liveness.mask_backgrounds_count').asInt().ifNaN(undefined),
    },
    filtering: {
      min_score: get('policies.filtering.min_score').asFloat().ifNaN(undefined),
      detection_yaw_threshold: get('policies.filtering.detection_yaw_threshold').asFloat().ifNaN(undefined),
      detection_pitch_threshold: get('policies.filtering.detection_pitch_threshold').asFloat().ifNaN(undefined),
      detection_roll_threshold: get('policies.filtering.detection_roll_threshold').asFloat().ifNaN(undefined),
      yaw_number: get('policies.filtering.yaw_number').asInt().ifNaN(undefined),
      yaw_collection_mode: get('policies.filtering.yaw_collection_mode').asBoolean(),
      mouth_occlusion_threshold: get('policies.filtering.mouth_occlusion_threshold').asFloat().ifNaN(undefined),
      min_body_size_threshold: get('policies.filtering.min_body_size_threshold').asFloat().ifNaN(undefined),
    },
    frame_processing_mode: get('policies.frame_processing_mode.value').asString(),
    real_time_mode_fps: get('policies.real_time_mode_fps').asInt().ifNaN(undefined),
    ffmpeg_threads_number: get('policies.ffmpeg_threads_number').asInt().ifNaN(undefined),
    healthcheck: {
      max_error_count: get('policies.healthcheck.max_error_count').asInt().ifNaN(undefined),
      period: get('policies.healthcheck.period').asInt().ifNaN(undefined),
      retry_delay: get('policies.healthcheck.retry_delay').asInt().ifNaN(undefined),
      timeout: get('policies.healthcheck.timeout').asInt().ifNaN(undefined),
    },
  },
  location: new Schema({
    city: get('location.city').ifEmptyString(undefined),
    area: get('location.area').ifEmptyString(undefined),
    district: get('location.district').ifEmptyString(undefined),
    street: get('location.street').ifEmptyString(undefined),
    house_number: get('location.house_number').ifEmptyString(undefined),
    geo_position: new Schema({
      longitude: get('location.geo_position.longitude').asFloat().ifNaN(undefined),
      latitude: get('location.geo_position.latitude').asFloat().ifNaN(undefined),
    }).omitBy(isUndefined)
      .omitBy(isEmpty),
  }).omitBy(isUndefined)
    .omitBy(isEmpty),
  autorestart: {
    restart: get('autorestart.restart').asInt(),
    attempt_count: get('autorestart.attempt_count').asInt().ifNaN(undefined),
    delay: get('autorestart.delay').asInt().ifNaN(undefined),
  },
}).omitBy(isEmpty)
  .omitBy(isUndefined);
