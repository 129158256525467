import React, { useState, useEffect, useMemo } from 'react';

import { DESCRIPTOR_TYPE_OPTIONS } from '@vlabs/api-bindings/src/constants';
import { ChevronDownIcon, ChevronRightIcon, CircleInfoIcon, SquareCheckIcon, SquareEmptyIcon, SquareMinusIcon, SquarePlusIcon } from '@vlabs/icons';
import SelectOptionGroupLabel from '@vlabs/shared/legacy-components/select-option-group-label/SelectOptionGroupLabel';
import validate from '@vlabs/shared/validators';
import { Control, Divider, Tooltip, Tabs, Tab, Step } from '@vlabs/uikit';
import cn from 'classnames';
import CheckboxTree from 'react-checkbox-tree';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { filterColumnsValues } from '../helpers';
import { OrderSelector } from './components/OrderSelector';
import st from './StepColumns.module.sass';

function StepColumns() {
  const { register, control, setValue, clearErrors, setError, formState: { errors } } = useFormContext();
  const settings = useWatch({ name: 'columnsSettings' });

  const { t } = useTranslation();
  const [checkedColumns, setCheckedColumns] = useState(settings.defaults);
  const [expandedColumns, setExpandedColumns] = useState(['all']);

  useEffect(() => {
    if (checkedColumns.length === 0) {
      setError('content.columns', { type: 'custom', message: t('tasks:выберите хотя бы одну колонку') });
      return;
    }
    clearErrors('content.columns');
    setValue('content.columns', filterColumnsValues(checkedColumns));
  }, [checkedColumns, clearErrors, setValue, setError, t]);

  const icons = useMemo(() => ({
    check: <SquareCheckIcon />,
    uncheck: <SquareEmptyIcon />,
    halfCheck: <SquareMinusIcon />,
    expandClose: <ChevronRightIcon />,
    expandOpen: <ChevronDownIcon />,
    expandAll: <SquarePlusIcon />,
    collapseAll: <SquareMinusIcon />,
  }), []);

  return (
    <Step noBorder>
      <h6>{t('tasks:exportForm.заголовки.дополнительные параметры')}</h6>
      <div className={cn(st.Border, {
        [st.Border__error]: errors.content?.columns,
      })}
      >
        <span className={st.ErrorMessage}>
          {errors.content?.columns?.message}
        </span>
        <Tabs testId="exportColumns">
          <Tab
            header={t('tasks:exportForm.заголовки.колонки')}
            key="columns"
            name="columns"
          >
            <CheckboxTree
              checked={checkedColumns}
              expanded={expandedColumns}
              expandOpen
              icons={icons}
              nodes={settings.tree}
              onCheck={(checked) => setCheckedColumns(checked)}
              onExpand={(expanded) => setExpandedColumns(expanded)}
              showExpandAll
              showNodeIcon={false}
            />
          </Tab>
          <Tab
            header={t('tasks:exportForm.заголовки.порядок')}
            key="order"
            name="order"
          >
            <OrderSelector
              keyMap={settings.keyMap}
              list={checkedColumns}
              setList={setCheckedColumns}
            />
          </Tab>
        </Tabs>
      </div>
      <Divider small />
      <div className={st.TooltipWrapper}>
        <Control.Switch
          control={control}
          id="content.save_images"
          label={t('tasks:cохранять изображения лиц')}
          name="content.save_images"
        />
        <Tooltip
          className="Tooltip_ui"
          data-tooltip-content={t('tasks:exportForm.tool-tip save images')}
          tooltipProps={{ effect: 'solid' }}
        >
          <CircleInfoIcon className="Tooltip_icon" />
        </Tooltip>
      </div>
      <Divider small />
      <Control.Select
        autoFocus
        control={control}
        formatGroupLabel={SelectOptionGroupLabel}
        id="content.descriptor.type"
        label={t('tasks:тип биометрического шаблона')}
        name="content.descriptor.type"
        options={DESCRIPTOR_TYPE_OPTIONS.raw}
      />
      <Divider small />
      <Control.Input
        id="content.csv_delimiter"
        label={t('tasks:используемый разделитель для .csv')}
        name="content.csv_delimiter"
        {...register('content.csv_delimiter', {
          required: validate.required(),
          maxLength: validate.maxLength(1),
        })}
        errors={errors}
      />
      <Divider small />
    </Step>
  );
}

StepColumns.fieldNames = [
  'content.columns',
  'content.csv_delimiter',
];

export { StepColumns };
