import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import { Control } from '../../controls/index';

import './PaginationButton.sass';

const PaginationButton = ({
  children,
  active,
  ...props
}) => {
  return (
    <Control.Button
      className={cn({
        PaginationButton: true,
        PaginationButton_active: active,
        InteractionWrapper_disabled: active,
      })}
      data-testid="paginationButton"
      kind={active ? 'primary' : 'neutral'}
      variant="outlined"
      {...props}
    >
      {children}
    </Control.Button>
  );
};

PaginationButton.propTypes = {
  children: PropTypes.node,
  active: PropTypes.bool,
};

PaginationButton.defaultProps = {
  children: undefined,
  active: false,
};

export { PaginationButton };
