import { createSelector } from '@reduxjs/toolkit';
import { selectAppServiceState } from '@vlabs/shared/selectors/appSelectors';
import { selectListsState } from '@vlabs/shared/selectors/listSelectors';

import { selectHandlersState } from '@vlabs/pages/handlers/selectors';

export const selectLatestEvents = (state) => state.latestEvents;
export const selectLatestEventsFilters = (state) => state.latestEvents.filters;
export const selectSound = (state) => ({
  isSoundOn: state.latestEvents.isSoundOn,
  soundThreshold: state.latestEvents.soundThreshold,
});
export const selectIsTopMatchHiding = (state) => state.latestEvents.isTopMatchHiding;

export const selectLatestEventsState = createSelector(
  (state) => selectAppServiceState(state, 'lunaPlatform'),
  selectHandlersState,
  selectListsState,
  (...args) => {
    if (args.every((i) => i === 'loaded')) {
      return 'loaded';
    }
    return 'failed';
  },
);
