import React from 'react';

import { CalendarIcon, SlidersIcon, VideoIcon, TagIcon, FolderIcon } from '@vlabs/icons';
import DownloadButton from '@vlabs/shared/legacy-components/download-button/DownloadButton';
import HandlerLink from '@vlabs/shared/legacy-components/handler-link/HandlerLink';
import InfoRow from '@vlabs/shared/legacy-components/info-row/InfoRow';
import StreamLink from '@vlabs/shared/legacy-components/stream-link/StreamLink';
import { parseISOToLocale } from '@vlabs/shared/utils';
import { Divider, Margin } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export const CommonEventInfo = ({
  event,
  onDownloadHandler,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <h6>{t('информация о событии')}</h6>
      <Divider small />
      <div data-testid="detector.eventInfo">
        <InfoRow
          data-testid="event.createTime"
          icon={<CalendarIcon className="StaticIcon" />}
          title={t('date created')}
          value={parseISOToLocale(event?.create_time)}
        />
        <InfoRow
          copy
          data-testid="event.eventId"
          icon={<FolderIcon className="StaticIcon" />}
          title={t('event')}
          toCopy={event?.event_id}
          value={t('id события')}
        />
        {event?.track_id && (
          <InfoRow
            copy
            data-testid="event.trackId"
            title={t('трек')}
            toCopy={event?.track_id}
            value={t('id трека')}
          />
        )}
        <InfoRow
          data-testid="event.handlerId"
          icon={<SlidersIcon className="StaticIcon" />}
          title={t('handler')}
          value={<HandlerLink handlerId={event?.handler_id} />}
        />
        {event?.source && (
          <InfoRow
            data-testid="event.source"
            title={t('source')}
            value={event.source}
          />
        )}
        {event?.stream_id && (
          <InfoRow
            data-testid="event.stream_id"
            icon={<VideoIcon className="StaticIcon" />}
            title={t('video stream')}
            value={<StreamLink streamId={event.stream_id} />}
          />
        )}
        {event?.uiTags && (
          <InfoRow
            data-testid="event.tags"
            icon={<TagIcon className="StaticIcon" />}
            title={t('tags')}
            value={event?.uiTags}
          />
        )}

        {event?.meta && (
          <InfoRow
            data-testid="event.meta"
            title={t('events:meta')}
            value={(
              <>
                {t('events:download')}
                <Margin right small />
                <DownloadButton onClick={() => onDownloadHandler(event)} />
              </>
            )}
          />
        )}
      </div>
    </>
  );
};

CommonEventInfo.propTypes = {
  event: PropTypes.objectOf(PropTypes.any),
  onDownloadHandler: PropTypes.func,
};

CommonEventInfo.defaultProps = {
  event: undefined,
  onDownloadHandler: undefined,
};
