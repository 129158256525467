import apiConstants_en from './apiConstants_en.json';
import apiConstants_ru from './apiConstants_ru.json';
import plans_en from './plans_en.json';
import plans_ru from './plans_ru.json';
import reports_en from './reports_en.json';
import reports_ru from './reports_ru.json';
import sources_en from './sources_en.json';
import sources_ru from './sources_ru.json';

export const vaReporter = {
  en: {
    reports: reports_en,
    vaReporter: apiConstants_en,
    plans: plans_en,
    sources: sources_en,
  },
  ru: {
    reports: reports_ru,
    vaReporter: apiConstants_ru,
    plans: plans_ru,
    sources: sources_ru,
  },
};
