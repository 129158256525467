import React, { memo } from 'react';

import { IconInner } from '../../IconInner';

const PersonPlusIconInner = (props) => (
  <IconInner name="PersonPlusIcon" viewBox="0 0 24 24" {...props}>
    <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2m7.5-10a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM20 8v6m3-3h-6" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
  </IconInner>
);

export const PersonPlusIcon = memo(PersonPlusIconInner);
