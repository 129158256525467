import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './Brand.sass';

const Brand = ({
  className,
  ...otherProps
}) => {
  return (
    <Link
      className={cn(className, 'Brand')}
      to="/"
      {...otherProps}
    />
  );
};

Brand.propTypes = {
  className: PropTypes.string,
};

Brand.defaultProps = {
  className: undefined,
};

export { Brand };
