import React, { useEffect, useCallback, useState } from 'react';

import { Control, Margin } from '@vlabs/uikit';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export const useSteppedForm = ({
  form,
  canGoNextStep = () => true,
  steps,
  onSubmit,
  buttonsClass,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const { t } = useTranslation();

  const { trigger: validateForm, formState: { errors: formErrors }, getValues } = form;
  // FIXME: надо придумать что-то получше, используется чтобы если открыт календарь форма не переключалась на следующий шаг
  const isCalendarOrSelectMenuOpen = useCallback(() => (document.querySelector('.flatpickr-calendar.open') || document.querySelector('.Select__menu')), []);

  const controls = ({
    prevStep,
    nextStep,
    isLastStep,
    isFirstStep,
  }) => {
    const onNextStep = async () => {
      await validateForm();

      const firstErrorField = steps[activeStep].fieldNames
        .find((fieldName) => get(formErrors, fieldName));

      if (!firstErrorField) {
        setActiveStep(activeStep + 1);
        nextStep();
      }
    };

    const onPrevStep = () => {
      setActiveStep(activeStep - 1);
      prevStep();
    };
    return (
      <div className={buttonsClass}>
        <Margin right>
          <Control.Button
            data-testid="stepper.prevStepButton"
            disabled={isFirstStep}
            onClick={onPrevStep}
            variant="outlined"
          >
            {t('handlers:форма.назад')}
          </Control.Button>
        </Margin>

        {!isLastStep && (
          <Control.Button
            data-testid="stepper.nextStepButton"
            onClick={onNextStep}
          >
            {t('handlers:форма.далее')}
          </Control.Button>
        )}

        {isLastStep && (
          <Control.Button
            data-testid="stepper.submitButton"
            type="submit"
          >
            {t('форма.submit')}
          </Control.Button>
        )}
      </div>
    );
  };

  useEffect(() => {
    async function keyDown(e) {
      if (!canGoNextStep()) return;
      if (isCalendarOrSelectMenuOpen()) return;

      // FIXME: выглядит как очень грязный хак, надо придумать что-то получше
      if (e.key === 'Enter') {
        e.preventDefault();
        e.stopPropagation();

        await validateForm();

        const firstErrorField = steps[activeStep].fieldNames
          .find((fieldName) => get(formErrors, fieldName));

        if ((activeStep === steps.length - 1) && (firstErrorField === undefined)) {
          await onSubmit(getValues());
          return;
        }
        if (firstErrorField === undefined) {
          setActiveStep(activeStep + 1);
        }
      }
    }
    document.removeEventListener('keydown', keyDown, true);
    document.addEventListener('keydown', keyDown, true);
    return () => {
      document.removeEventListener('keydown', keyDown, true);
    };
  }, [validateForm, formErrors, canGoNextStep, activeStep, onSubmit, getValues, steps]);

  return {
    controls,
    activeStep,
  };
};

useSteppedForm.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  onSubmit: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.func).isRequired,
  canGoNextStep: PropTypes.func,
  buttonsClass: PropTypes.string,
};

useSteppedForm.defaultProps = {
  canGoNextStep: () => true,
  buttonsClass: undefined,
};
