import React, { memo } from 'react';

import { IconInner } from '../../IconInner';

const EyeOffIconInner = (props) => (
  <IconInner name="EyeOffIcon" viewBox="0 0 24 24" {...props}>
    <g clipPath="url(#a)"><path d="M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.494 18.494 0 0 1-2.16 3.19m-6.72-1.07a2.998 2.998 0 0 1-5.194-2.098A3 3 0 0 1 9.88 9.88m8.06 8.06A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94l11.88 11.88ZM1 1l22 22" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" /></g>
  </IconInner>
);

export const EyeOffIcon = memo(EyeOffIconInner);
