import React, { memo } from 'react';

import { IconInner } from '../../IconInner';

const CrossIconInner = (props) => (
  <IconInner name="CrossIcon" viewBox="0 0 24 24" {...props}>
    <path d="M18 6 6 18M6 6l12 12" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
  </IconInner>
);

export const CrossIcon = memo(CrossIconInner);
