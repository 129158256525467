import React from 'react';

import { Fold } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { checksValueMapperByType, onDownloadResults } from '../utils';
import CheckRow from './CheckRow';
import st from './CheckRow.module.sass';
import CheckTitle from './CheckTitle';

export default function CheckRowFaceQuality({ estimations, filename, estimationName, status }) {
  const { t } = useTranslation();

  const checks = estimations.map(checksValueMapperByType);
  const errorChecks = checks.filter(({ result, object_value }) => !result && object_value !== null);
  const notEstimatedChecks = checks.filter(({ result, object_value }) => !result && object_value === null);
  const resultFilename = `${filename}-${estimationName}Result.json`;

  const title = (
    <CheckTitle
      notEstimatedChecks={notEstimatedChecks}
      onDownloadResults={() => onDownloadResults(estimations, resultFilename)}
      status={status}
      title={`${t(`checks:estimations.${estimationName}.title`)}: ${t(`checks:status.${status}`)}`}
    />
  );

  return (
    <Fold
      className={st.Fold}
      compact
      testId={`${estimationName}Fold`}
      title={title}
    >
      {errorChecks?.length > 0 && (
        <div className={st.Subtitle}>
          {t('checks:error.errorCounter', { count: errorChecks.length })}
        </div>
      )}
      <div className={st.Result__Rows}>
        {checks.map(CheckRow)}
      </div>
    </Fold>
  );
}

CheckRowFaceQuality.propTypes = {
  estimations: PropTypes.arrayOf(PropTypes.object).isRequired,
  status: PropTypes.number.isRequired,
  estimationName: PropTypes.string.isRequired,
  filename: PropTypes.string,
};

CheckRowFaceQuality.defaultProps = {
  filename: '',
};
