export const getFactors = ({ width, height }, mode) => {
  let factorX;
  let factorY;
  if (mode === 'abs') {
    factorX = width;
    factorY = height;
  }
  if (mode === 'cartesian') {
    const factor = Math.max(width, height);
    factorX = factor;
    factorY = factor;
  }
  return {
    x: factorX,
    y: factorY,
  };
};

export const normalizeRect = (rect, { width, height, denominator = 1 }) => ({
  x: Math.min((rect.x / width) * denominator, denominator),
  y: Math.min((rect.y / height) * denominator),
  width: Math.min((rect.width / width) * denominator),
  height: Math.min((rect.height / height) * denominator),
});

export const denormalizeRect = (rect, { width, height, denominator = 1 }) => ({
  x: (rect.x / denominator) * width,
  y: (rect.y / denominator) * height,
  width: (rect.width / denominator) * width,
  height: (rect.height / denominator) * height,
});

export const fixDenormalizedValuesRect = (rect, { width, height }) => ({
  x: rect.x || 0,
  y: rect.y || 0,
  width: rect.width || width,
  height: rect.height || height,
});

export const applyScaleRect = (rect, scaleFactor) => ({
  x: rect.x * scaleFactor,
  y: rect.y * scaleFactor,
  width: rect.width * scaleFactor,
  height: rect.height * scaleFactor,
});

export const normalizeObjPair = ({ x, y }, { width, height }) => {
  return { x: x / width, y: y / height };
};

export const normalizeCoordinateByFactors = (coord, canvas, mode) => {
  const factor = getFactors(canvas, mode);
  return {
    x: coord.x / factor.x,
    y: coord.y / factor.y,
  };
};

export const normalizeObjPairs = (coords, canvas) => {
  return coords.map((coord) => normalizeObjPair(coord, canvas));
};

export const denormalizeObjPair = ({ x, y }, { width, height }) => {
  return { x: x * width, y: y * height };
};

export const denormalizeObjPairs = (coords, canvas) => {
  return coords.map((coord) => denormalizeObjPair(coord, canvas));
};

export const denormalizeCoordinateByFactors = (coord, canvas, mode) => {
  const factor = getFactors(canvas, mode);
  return {
    x: coord.x * factor.x,
    y: coord.y * factor.y,
  };
};

export const applyScaleObjPair = ({ x, y }, scaleFactor) => {
  return { x: Number(x * scaleFactor), y: Number(y * scaleFactor) };
};

export const applyScaleObjPairs = (coords, scaleFactor) => {
  return coords.map((coord) => applyScaleObjPair(coord, scaleFactor));
};
