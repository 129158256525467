export const PAGE_SIZE = 10;
export const INITIAL_PAGE_INDEX = 0;

export const paginateData = (data, pageIndex = INITIAL_PAGE_INDEX) => {
  const start = pageIndex * PAGE_SIZE;
  const end = start + PAGE_SIZE;
  return data?.slice(start, end);
};

export const transformEventData = (data) => {
  return (data || []).flatMap((item) => item.candidates.map((candidate) => {
    const { event, face } = candidate;
    return {
      label: item.label,
      similarity: candidate.similarity,
      type: event ? 'event' : 'face',
      ...event,
      ...face,
    };
  }));
};
