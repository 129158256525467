import React from 'react';

import { DEEPFAKE_STATE_OPTIONS_STRICT } from '@vlabs/api-bindings/src/constants';
import { deepfakeImages } from '@vlabs/shared/assets/images';
import { optionsProxy } from '@vlabs/uikit';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Card } from './Card';
import st from './Card.module.sass';

const DEEPFAKE_IMAGES = {
  0: deepfakeImages.DeepfakeImageFail,
  1: deepfakeImages.DeepfakeImageSuccess,
};

const DEEPFAKE_ESTIMATION_STATES = optionsProxy.create([
  { value: 0, label: i18n.t('events:attribute card.deepfake card.front.values.fail') },
  { value: 1, label: i18n.t('events:attribute card.deepfake card.front.values.success') },
]);

export const DeepfakeCard = ({ deepfake }) => {
  const { t } = useTranslation();

  if (!DEEPFAKE_ESTIMATION_STATES[deepfake]) return null;

  return (
    <Card
      back={(
        <div className={st.Tooltip}>
          {t('events:attribute card.deepfake card.back.text')}
          <span className={st.TooltipValue}>
            {DEEPFAKE_STATE_OPTIONS_STRICT[deepfake]?.label }
          </span>
        </div>
      )}
      front={(
        <div className={st.Root}>
          <div
            className={st.ImageBlock}
            data-testid="faceAttributesCell.deepfake"
          >
            {t('events:attribute card.deepfake card.front.title')}
            <img alt="deepfake icon" className={st.Image} src={DEEPFAKE_IMAGES[deepfake]} />
            {DEEPFAKE_ESTIMATION_STATES[deepfake].label}
          </div>
        </div>
      )}
    />
  );
};

DeepfakeCard.propTypes = {
  deepfake: PropTypes.oneOf([0, 1]).isRequired,
};
