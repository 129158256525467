/* eslint-disable camelcase */

export function selectElementsEvenly(items, count) {
  if (!items) return null;
  if (items.length <= count) return items.map((_, idx) => idx);

  const step = (items.length - 1) / (count - 1);
  const result = [];
  for (let i = 0; i < count; i++) {
    result.push(Math.round(step * i));
  }

  return result;
}

const getTopMatch = (event) => {
  if (!event?.top_match) return null;
  const topMatchObjectId = event.top_match.face_id || event.top_match.event_id;
  // eslint-disable-next-line no-restricted-syntax
  for (const { candidates } of event.match_result) {
    // eslint-disable-next-line no-restricted-syntax
    for (const candidate of candidates) {
      if (
        candidate.face?.face_id === topMatchObjectId
        || candidate.event?.event_id === topMatchObjectId
      ) {
        return {
          ...event.top_match,
          user_data: candidate.face?.user_data || candidate?.event?.user_data,
          external_id: candidate.face?.external_id || candidate?.event?.external_id,
          handler_id: event.handler_id,
        };
      }
    }
  }
  return null;
};

export const eventToEvent = (lunaClient) => (event) => {
  const getFullFrame = (detection) => {
    if (!detection?.image_origin) return undefined;
    return {
      ...detection,
      uiFullFrame: lunaClient.images.getURL(detection.image_origin),
    };
  };

  const body_detections = event?.body_detections?.map((detection) => ({
    ...detection,
    uiSampleURL: lunaClient.samples.getURL('bodies', detection.sample_id),
  })) || null;
  const uiBodyDetection = body_detections?.find(({ uiSampleURL }) => uiSampleURL) || null;

  const face_detections = event?.face_detections?.map((detection) => ({
    ...detection,
    uiSampleURL: lunaClient.samples.getURL('faces', detection?.sample_id),
  })) || null;
  const uiFaceDetection = face_detections?.find(({ uiSampleURL }) => uiSampleURL) || null;

  const faceDetectionWithFullFrame = face_detections?.find(({ image_origin }) => image_origin?.startsWith('/6/images')) || null;
  const bodyDetectionWithFullFrame = body_detections?.find(({ image_origin }) => image_origin?.startsWith('/6/images')) || null;
  const detectionWithUiFullFrame = getFullFrame(faceDetectionWithFullFrame) || getFullFrame(bodyDetectionWithFullFrame);

  return {
    ...event,
    face_detections,
    body_detections,
    uiFaceDetection,
    uiBodyDetection,
    detectionWithUiFullFrame,
    // FIXME: Чего делать с треками? Необходимо уточнить требования к uiFaceTrack и uiBodyTrack.
    uiFaceTrack: selectElementsEvenly(face_detections, 7),
    // FIXME: это просто заглушка, мы отображаем треки только если их больше одного
    uiBodyTrack: [uiBodyDetection],
    top_match: getTopMatch(event),
    uiTags: event.tags?.length > 0 ? event.tags.join(', ') : null,
  };
};
