import React, { memo } from 'react';

import { IconInner } from '../../IconInner';

const PercentIconInner = (props) => (
  <IconInner name="PercentIcon" viewBox="0 0 24 24" {...props}>
    <path d="M19 5 5 19M6.5 9a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Zm11 11a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
  </IconInner>
);

export const PercentIcon = memo(PercentIconInner);
