import React, { memo } from 'react';

import { IconInner } from '../../IconInner';

const TagIconInner = (props) => (
  <IconInner name="TagIcon" viewBox="0 0 24 24" {...props}>
    <path d="m20.59 13.41-7.17 7.17a1.998 1.998 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82v0ZM7 7h.01" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
  </IconInner>
);

export const TagIcon = memo(TagIconInner);
