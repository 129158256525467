export const findParentNode = (target, nodeName) => {
  if (!target || !nodeName || target.parentElement.nodeName === 'BODY') {
    return false;
  }

  if (target.parentElement.nodeName === nodeName) {
    return target.parentElement;
  }

  return findParentNode(target.parentElement, nodeName);
};
