import { createSlice } from '@reduxjs/toolkit';
import { apiContainer } from '@vlabs/api-bindings';

import { SLICE_NAME } from '.';

const store = createSlice({
  name: SLICE_NAME,
  initialState: {
    state: undefined,
    pageData: {
      data: [],
      meta: {
        count: 0,
      },
    },
    byId: {},
    pageIndex: 0,
    pageSize: 25,
    pageSizeOptions: [10, 25, 50, 100],
    sourceOptions: [],
  },
  reducers: {
    setSources(state, { payload }) {
      if (!Array.isArray(payload)) return;
      state.pageData = {
        data: payload.map((name) => ({
          name,
        })),
        meta: { count: payload.length },
      };
      state.sourceOptions = payload?.map((name) => ({
        value: name,
        label: name,
      }));
      state.state = 'loaded';
    },
    setPage(state, { payload }) {
      if (payload !== undefined) state.pageIndex = payload;
    },
    setPageSize(state, { payload }) {
      if (payload !== undefined) state.pageSize = payload;
    },
  },
});

export default store.reducer;

export const { actions } = store;

export const fetchSources = async (dispatch) => {
  try {
    const { data: sources } = await apiContainer.vlAccessClient.sources.getAll();
    dispatch(actions.setSources(sources));
  } catch (error) {
    dispatch(actions.setSources([]));
    throw error;
  }
};

export const init = (dispatch) => {
  dispatch(fetchSources);
};
