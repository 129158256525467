import { buildDateFilter } from './DateFilter';
import { buildOneOfFilter } from './OneOfFilter';
import { buildRangeFilter } from './RangeFilter';
import { buildTextFilter } from './TextFilter';

const TableFilters = {
  buildDateFilter,
  buildTextFilter,
  buildOneOfFilter,
  buildRangeFilter,
};

export {
  TableFilters,
};
