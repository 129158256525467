import React from 'react';

import { useTranslation } from 'react-i18next';

import './UnauthorizedPage.sass';

const UnauthorizedPage = () => {
  const { t } = useTranslation();

  return (
    <div className="UnauthorizedPage">
      <div className="UnauthorizedPage__Content">
        {t('uikit:заголовки.недостаточно прав для просмотра раздела')}
      </div>
    </div>
  );
};

export { UnauthorizedPage };
