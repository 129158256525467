import React from 'react';

import { CircleCrossIcon } from '@vlabs/icons';
import { EmptyImage } from '@vlabs/shared/components/empty-image';
import { SampleWithActions } from '@vlabs/shared/components/sample/SampleWithActions';
import { permissions } from '@vlabs/shared/config';
import { calcBadgeType } from '@vlabs/shared/utils';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { viewerCan } from '@vlabs/pages/auth/selectors';

import './EventSampleCell.sass';

export const EventSampleCellComponent = ({
  original,
  onEventClick,
  can,
}) => {
  const { t } = useTranslation();
  const badgeColor = calcBadgeType(original?.top_match?.similarity);
  const hasFacePermission = can([permissions.face.view]);

  const isTopMatchFaceEvent = original?.top_match?.eventType === 'face';
  const hasPermissionToShowFace = isTopMatchFaceEvent && hasFacePermission;

  return (
    <div
      className={cn('EventSampleCell__Root', 'TopMatch_wrapper', {
        [`EventSampleCell_color_${badgeColor}`]: original?.top_match || hasPermissionToShowFace,
      })}
      data-testid="eventSampleCell"
    >
      {original.face_detections && (
        <SampleWithActions
          data-testid="eventSample"
          dataTip={t('events:проверьте настройки сценария')}
          imageUrl={original?.uiFaceDetection?.uiSampleURL}
          onGoToClick={onEventClick}
          type="face"
        />
      )}
      {original.body_detections && (
        <>
          {original.face_detections && <span className="EventSample__Divider" />}
          <SampleWithActions
            data-testid="eventSample"
            dataTip={t('events:проверьте настройки сценария')}
            imageUrl={original?.uiBodyDetection?.uiSampleURL}
            onGoToClick={onEventClick}
            type="body"
          />
        </>

      )}
      {!original.face_detections && !original.body_detections && (
        <EmptyImage
          data-testid="eventSample"
          icon={<CircleCrossIcon />}
          messageText={t('объект не обнаружен')}
          onImageClick={onEventClick}
        />
      )}
    </div>
  );
};

EventSampleCellComponent.propTypes = {
  original: PropTypes.objectOf(PropTypes.any),
  onEventClick: PropTypes.func,
  can: PropTypes.func.isRequired,
};

EventSampleCellComponent.defaultProps = {
  original: {},
  onEventClick: undefined,
};

export const EventSampleCell = connect((state) => ({
  can: viewerCan(state),
}))(EventSampleCellComponent);
