import React from 'react';

import { LinkBrokenIcon } from '@vlabs/icons';
import { ButtonWithPermissions } from '@vlabs/shared/components/button-with-permissions/ButtonWithPermissions';
import i18n from 'i18next';
import PropTypes from 'prop-types';

export const DeleteAndDetachCell = ({ onClick, row, permissions, buttonProps }) => {
  const { rules = [], method = 'oneof' } = permissions || {};

  return (
    <ButtonWithPermissions
      data-testid="linkBrokenCell"
      icon={<LinkBrokenIcon />}
      kind="negative"
      onClick={() => onClick(row.original)}
      permissions={{ rules, method }}
      round
      title={i18n.t('lists:таблица.кнопки.удалить список и открепить лица')}
      variant="dimmed"
      {...(buttonProps || {})}
    />
  );
};

DeleteAndDetachCell.propTypes = ({
  row: PropTypes.objectOf(PropTypes.any).isRequired,
  buttonProps: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  permissions: PropTypes.shape({
    rules: PropTypes.arrayOf(PropTypes.string).isRequired,
    method: PropTypes.oneOf(['oneof', 'allof']),
  }),
});

DeleteAndDetachCell.defaultProps = ({
  buttonProps: undefined,
  permissions: {},
});
