import {
  FRAME_PROCESSING_MODE_OPTIONS, LIVENESS_MODE_OPTIONS,
  ROTATION_OPTIONS, SENDING_TYPE_OPTIONS, STREAM_STATUS_OPTIONS,
  STREAM_TYPE_OPTIONS,
} from '@vlabs/api-bindings/src/luna-streams/constants';

export const defaultStreamData = {
  name: '',
  description: '',
  data: {
    type: STREAM_TYPE_OPTIONS.udp,
    roi: { x: 0, y: 0, width: 0, height: 0, mode: 'abs' },
    droi: { x: 0, y: 0, width: 0, height: 0, mode: 'abs' },
    rotation: ROTATION_OPTIONS[0],
    preferred_program_stream_frame_width: 800,
    endless: true,
  },
  event_handler: {},
  policies: {
    sending: {
      time_period_of_searching: -1,
      silent_period: 0,
      type: SENDING_TYPE_OPTIONS.sec,
      number_of_bestshots_to_send: 1,
      send_only_full_set: true,
      delete_track_after_sending: false,
    },
    primary_track_policy: {
      use_primary_track_policy: false,
      best_shot_min_size: 70,
      best_shot_proper_size: 140,
    },
    liveness: {
      use_mask_liveness_filtration: false,
      use_flying_faces_liveness_filtration: false,
      liveness_mode: LIVENESS_MODE_OPTIONS[0],
      number_of_liveness_checks: 0,
      liveness_threshold: 0,
      livenesses_weights: '0, 0, 0',
      mask_backgrounds_count: 0,
    },
    filtering: {
      min_score: 0.5187,
      detection_yaw_threshold: 40,
      detection_pitch_threshold: 40,
      detection_roll_threshold: 30,
      yaw_number: 1,
      yaw_collection_mode: false,
      mouth_occlusion_threshold: 0,
      min_body_size_threshold: 0,
    },
    frame_processing_mode: FRAME_PROCESSING_MODE_OPTIONS.auto,
    real_time_mode_fps: 0,
    ffmpeg_threads_number: 0,
    healthcheck: {
      max_error_count: 10,
      period: 3600,
      retry_delay: 5,
    },
  },
  location: {},
  autorestart: {
    restart: true,
    attempt_count: 10,
    delay: 60,
  },
  status: STREAM_STATUS_OPTIONS.pending,
};
