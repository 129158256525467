import React, { useCallback, useMemo, useState } from 'react';

import { apiContainer } from '@vlabs/api-bindings';
import { GoToCell } from '@vlabs/shared/components/table';
import { routes } from '@vlabs/shared/navigation/routes';
import { Page, Table, Panel, Divider, Control, Grid, GridCol, GridRow } from '@vlabs/uikit';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom/cjs/react-router-dom.min';

import st from './SearchByDocuments.module.sass';

const FacePhotoCell = ({ value }) => {
  if (!value) return null;
  return <img alt="face" className={st.FacePhoto} src={value} />;
};

const PassportPhotoCell = ({ value }) => {
  if (!value) return null;
  return <img alt="passport" className={st.PassportPhoto} src={apiContainer.lunaClient.images.getURL(value)} />;
};

const SearchForm = ({ onSubmit, onReset }) => {
  const { t } = useTranslation();
  const form = useForm();
  const onResetForm = () => {
    onReset();
    form.reset({
      document_number: '',
      personal_number: '',
      surname: '',
    });
  };

  return (
    <form onReset={onResetForm} onSubmit={form.handleSubmit(onSubmit)}>
      <Grid>
        <GridRow>
          <GridCol cols={6}>
            <Panel className={st.Page}>
              <Control.Input {...form.register('document_number')} id="document_number" label={t('searchByDocuments:form.fields.by document number')} />
              <Divider small />
              <Control.Input {...form.register('personal_number')} id="personal_number" label={t('searchByDocuments:form.fields.by personal number')} />
              <Divider small />
              <Control.Input {...form.register('surname')} id="surname" label={t('searchByDocuments:form.fields.by surname')} />
              <Divider small />
              <div className={st.Controls}>
                <Control.Button type="submit">{t('searchByDocuments:form.buttons.search')}</Control.Button>
                <Control.Button kind="warning" type="reset">{t('searchByDocuments:form.buttons.reset')}</Control.Button>
              </div>
            </Panel>
          </GridCol>
        </GridRow>
      </Grid>
    </form>
  );
};

function getMetaFiltersFromValues(values) {
  const meta = {};

  Object.entries(values)
    .filter(([_, value]) => value)
    .map(([key, value]) => ({
      key: `passport_data.${key}.value`,
      operator: {
        value: 'like',
      },
      type: {
        value: 'string',
      },
      value: `%${value}%`,
    }))
    .forEach((m) => {
      meta[`meta.${m.key}__${m.operator.value}:${m.type.value}`] = m.value;
    });

  return meta;
}

function getFacesData(faceIds) {
  const validFaceIds = faceIds.filter((id) => id !== null);

  return Promise.all(validFaceIds.map(async (faceId) => {
    try {
      const { data } = await apiContainer.lunaClient.faces.get(faceId);
      return data;
    } catch {
      return undefined;
    }
  }));
}

function isEmptyObject(values) {
  if (Object.keys(values).length === 0) return true;
  return Object.values(values).every((value) => value === null || value === undefined || value === '');
}

export function SearchByDocuments() {
  const { t } = useTranslation();
  const [tableData, setTableData] = useState([]);
  const [isRequested, setIsRequested] = useState(false);

  const onSubmit = useCallback(async (values) => {
    if (isEmptyObject(values)) return;

    const metaFilters = getMetaFiltersFromValues(values);

    const events = await apiContainer.lunaClient.events.getAll({ ...metaFilters, page_size: 25 });
    const faces = await getFacesData(events.map(({ face_id }) => face_id));
    let eventsWithFaces = [];

    if (faces && faces.length) {
      eventsWithFaces = events
        .filter(({ meta }) => meta.schema_name === 'mali')
        .map((event, index) => ({ ...event, face: faces[index] }));
    }

    setIsRequested(true);
    setTableData(eventsWithFaces);
  }, []);

  const resetTableData = useCallback(() => {
    setTableData([]);
    setIsRequested(false);
  }, []);

  const columns = useMemo(() => ([
    {
      Header: t('searchByDocuments:table.headers.photo'),
      accessor: 'face.uiAvatarURL',
      width: 250,
      Cell: FacePhotoCell,
    },
    {
      Header: t('searchByDocuments:table.headers.document'),
      accessor: 'meta.passport_saved_images.0.url',
      Cell: PassportPhotoCell,
    },
    {
      Header: t('searchByDocuments:table.headers.info'),
      accessor: ({ user_data }) => ({ userData: user_data }),
      Cell: ({ value }) => value?.userData || null,
    },
    {
      id: 'table.headers.link to face details',
      accessor: ({ face_id }) => generatePath(routes.facePage, { faceId: face_id }),
      Cell: GoToCell,
      width: 10,
    },
  ]), [t]);

  return (
    <Page>
      <SearchForm onReset={resetTableData} onSubmit={onSubmit} />
      {isRequested && (
        <>
          <Divider small />
          <Panel>
            <Table
              columns={columns}
              data={tableData}
            />
          </Panel>
        </>
      )}
    </Page>
  );
}
