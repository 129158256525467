import React from 'react';

import { TEMPERATURE_BY_GROUP_OPTIONS } from '@vlabs/api-bindings/src/constants';
import SelectOptionGroupLabel from '@vlabs/shared/legacy-components/select-option-group-label/SelectOptionGroupLabel';
import validate from '@vlabs/shared/validators';
import { Control, Divider } from '@vlabs/uikit';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export function CommonLatestEventFilterForm({ camOptions, streamIdsOptions, handlerOptions }) {
  const { t } = useTranslation();
  const { control, formState: { errors } } = useFormContext();

  return (
    <>
      <Control.Select
        control={control}
        formatGroupLabel={SelectOptionGroupLabel}
        id="sources"
        isClearable
        isCreatable
        isMulti
        label={t('source')}
        name="sources"
        options={camOptions}
      />
      <Divider small />

      <Control.Select
        control={control}
        errors={errors}
        id="stream_ids"
        isClearable
        isCreatable
        isMulti
        label={t('video stream')}
        name="stream_ids"
        options={streamIdsOptions}
        rules={{ validate: validate.uuidOptions }}
      />
      <Divider small />

      <Control.Select
        control={control}
        errors={errors}
        id="handler_ids"
        isClearable
        isCreatable
        isMulti
        label={t('сценарии')}
        name="handler_ids"
        options={handlerOptions}
        rules={{ validate: validate.uuidOptions }}
      />
      <Divider small />

      <Control.Select
        control={control}
        formatGroupLabel={SelectOptionGroupLabel}
        id="tags"
        isClearable
        isCreatable
        isMulti
        label={t('tags')}
        name="tags"
        options={TEMPERATURE_BY_GROUP_OPTIONS.raw}
      />
      <Divider small />
    </>
  );
}
