import { createSlice } from '@reduxjs/toolkit';
import { apiContainer } from '@vlabs/api-bindings';
import { selectAppState } from '@vlabs/shared/selectors/appSelectors';
import i18next from 'i18next';
import { toast } from 'react-toastify';

import { extractReferenceInfo } from './helpers';

const referenceTypeList = ['face', 'face_external_id', 'event', 'sdk_descriptor'];

const initialState = {
  data: undefined,
  referencesPreview: undefined,
  references: [],
  candidates: [],
  candidateOrigin: 'events',
  referenceType: 'sdk_descriptor',
  matcherType: 'face',
  pageSize: 2,
  pageIndex: 0,
  isTopMatchHiding: true,
};

const store = createSlice({
  name: 'matcherSearch',
  initialState,
  reducers: {
    setResults(state, { payload: { results } }) {
      state.data = results;
    },
    setReferencesPreview(state, { payload }) {
      state.referencesPreview = payload;
    },
    setCandidates(state, { payload: { candidates } }) {
      state.candidates = candidates;
    },
    setReferences(state, { payload: { references } }) {
      state.references = references;
    },
    setReferenceType(state, { payload: { referenceType } }) {
      state.referenceType = referenceType;
    },
    setMatcherType(state, { payload: { matcherType } }) {
      state.matcherType = matcherType;
    },
    setInputType(state, { payload: { referenceType } }) {
      state.referenceType = referenceType;
    },
    setCandidateOrigin(state, { payload }) {
      state.candidateOrigin = payload;
    },
    setPageIndex(state, { payload: { pageIndex } = {} }) {
      if (pageIndex !== undefined) state.pageIndex = pageIndex;
    },
    resetSearchState(state) {
      state.data = undefined;
      state.referencesPreview = undefined;
      state.references = [];
      state.pageIndex = 0;
      state.matcherType = 'face';
      state.candidates = [
        { filters: { origin: 'events' }, limit: 100, threshold: 0.8 },
      ];
    },
    resetMatchResults(state) {
      state.data = undefined;
    },
    setIsTopMatchHiding(state) {
      state.isTopMatchHiding = !state.isTopMatchHiding;
    },
  },
});

export default store.reducer;

export const {
  setResults,
  setReferencesPreview,
  setCandidates,
  setCandidateOrigin,
  setReferenceType,
  setMatcherType,
  setPageIndex,
  resetSearchState,
  resetMatchResults,
  setIsTopMatchHiding,
} = store.actions;

export const search = async (dispatch, getState) => {
  if (selectAppState(getState()) !== 'loaded') return;

  const {
    matcherSearch: {
      references,
      candidates,
      referenceType,
      matcherType,
    },
  } = getState();

  if (!(references?.length > 0 && candidates?.length > 0)) {
    toast.warning(i18next.t('search:подсказка.необходимо выбрать объект поиска'));
    return;
  }

  if (!referenceTypeList.includes(referenceType)) {
    dispatch(setResults({ results: undefined }));
    return;
  }

  let results;
  if (matcherType === 'body') {
    results = await apiContainer.lunaClient.matcher.bodies({ references, candidates });
  } else {
    results = await apiContainer.lunaClient.matcher.faces({ references, candidates });
  }

  dispatch(setResults({ results }));
};

export const setReferences = (references) => async (dispatch, getState) => {
  const { matcherSearch: { referenceType } } = getState();

  dispatch(store.actions.setReferences({ references }));

  if (!(references?.length > 0)) {
    dispatch(setReferencesPreview(undefined));
    return;
  }

  let extendedReferenceInfo;
  try {
    extendedReferenceInfo = await extractReferenceInfo(references?.[0]);
  } catch (error) {
    if (referenceType !== 'sdk_descriptor') {
      dispatch(resetSearchState());
    }
    throw error;
  }

  dispatch(setReferencesPreview(extendedReferenceInfo));
};

export const setFilters = ({ limit, threshold, ...filters }) => (dispatch, getState) => {
  const { matcherSearch: { candidateOrigin } } = getState();

  dispatch(setCandidates({ candidates: [{ filters: { origin: candidateOrigin, ...filters }, limit, threshold }] }));
  dispatch(search);
};

export const searchFaceBySample = async ({
  sample_id,
  sdkDescriptor,
  candidates,
}) => apiContainer.lunaClient.matcher.faces({
  references: [{
    type: 'sdk_descriptor',
    id: sample_id,
    data: sdkDescriptor,
  }],
  candidates,
});

export const searchByRawDescriptor = async ({
  faceId,
  personAttributes,
  selectedCandidates,
  candidates,
}) => {
  const { data: { matches } } = await apiContainer.lunaClient.matcher.raw({
    references: [
      {
        type: 'raw_descriptor',
        id: faceId,
        data: {
          descriptor: personAttributes.face_descriptor.descriptor,
          version: personAttributes.face_descriptor.descriptor_version,
        },
      },
    ],
    candidates: [
      {
        type: 'sdk_descriptor',
        id: selectedCandidates,
        data: candidates?.byId[selectedCandidates]?.attributes?.descriptor?.sdk_descriptor,
      },
    ],
  });
  return matches;
};
