import React, { memo } from 'react';

import { IconInner } from '../../IconInner';

const CalendarIconInner = (props) => (
  <IconInner name="CalendarIcon" viewBox="0 0 24 24" {...props}>
    <path d="M19 4H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2Zm-3-2v4M8 2v4m-5 4h18" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
  </IconInner>
);

export const CalendarIcon = memo(CalendarIconInner);
