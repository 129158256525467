import { createSlice } from '@reduxjs/toolkit';
import { apiContainer } from '@vlabs/api-bindings';
import i18next from 'app/translations/i18n';
import { toast } from 'react-toastify';

import { SLICE_NAME } from '.';

const initialState = {
  pageData: [],
  plan: undefined,
  pageIndex: 0,
  pageSize: 25,
  pageSizeOptions: [10, 25, 50, 100],
};

const store = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setPage(state, { payload: { pageIndex } }) {
      if (pageIndex !== undefined) state.pageIndex = pageIndex;
    },
    setPlan(state, { payload }) {
      state.plan = payload;
    },
    setPageSize(state, { payload: { pageSize } }) {
      if (pageSize !== undefined) state.pageSize = pageSize;
    },
    setPageData(state, { payload }) {
      state.pageData = payload;
    },

  },
});

export default store.reducer;

export const fetchPlans = async (dispatch, getState) => {
  const {
    [SLICE_NAME]: { pageIndex, pageSize },
  } = getState();

  try {
    const plans = await apiContainer.vaReporterClient.plans
      .getAll({ page: pageIndex + 1, page_size: pageSize });

    dispatch(store.actions.setPageData(plans));
  } catch (error) {
    dispatch(store.actions.setPageData([]));
    throw error;
  }
};

export const fetchPlan = (planId) => async (dispatch, getState) => {
  const { [SLICE_NAME]: { plan } } = getState();

  if (plan?.id !== planId || planId === undefined) {
    const $plan = await apiContainer.vaReporterClient.plans.get(planId);
    dispatch(store.actions.setPlan($plan));
  }
};

export const submitPlan = ({ image, id, ...rest }) => async (dispatch) => {
  let plan;

  if (id) {
    plan = await apiContainer.vaReporterClient.plans.update(id, { ...rest });
  } else {
    plan = await apiContainer.vaReporterClient.plans.create({ ...rest });
  }

  if (image[0]) {
    await apiContainer.vaReporterClient.plans.uploadImage(plan.data?.id, image[0]);
  }

  dispatch(fetchPlans);
};

export const deletePlan = (id) => async (dispatch) => {
  await apiContainer.vaReporterClient.plans.delete(id);
  toast.success(i18next.t('plans:api.успех.удаление'));
  dispatch(fetchPlans);
};

export const setPageSize = (params) => async (dispatch) => {
  dispatch(store.actions.setPageSize(params));
  dispatch(fetchPlans);
};

export const setPage = (params) => async (dispatch) => {
  dispatch(store.actions.setPage(params));
  dispatch(fetchPlans);
};
