import React, { useState, useCallback, useEffect } from 'react';

import { apiContainer } from '@vlabs/api-bindings';
import { SearchIcon } from '@vlabs/icons';
import { FaceAction } from '@vlabs/shared/components/action-button-groups/FaceAction';
import { LinkWithPermissions } from '@vlabs/shared/components/link-with-permissions/LinkWithPermissions';
import { SampleWithActions } from '@vlabs/shared/components/sample/SampleWithActions';
import { TabsWithPermissions } from '@vlabs/shared/components/tabs-with-permissions/TabsWithPermissions';
import { permissions } from '@vlabs/shared/config';
import { AdditionalPropertiesTab } from '@vlabs/shared/legacy-components/tabs/AdditionalPropertiesTab';
import { AttributesTab } from '@vlabs/shared/legacy-components/tabs/AttributesTab';
import { routes } from '@vlabs/shared/navigation/routes';
import { Page, Pages, Panel, Divider, Grid, GridRow, GridCol, Tab } from '@vlabs/uikit';
import qs from 'qs';
import { useTranslation } from 'react-i18next';
import { generatePath, useParams } from 'react-router-dom';

import { FaceInfo } from './components/FaceInfo';
import st from './FacePage.module.sass';
import { CreatedFromEventTab } from './tabs/CreatedFromEventTab';
import { LastEventsByFaceIdTab, FacesByExternalIdTab } from './tabs/index';
import { UpdatePhotoWidget, EditFaceInfoWidget, DeleteFaceWidget } from './widgets';

export default function FacePage() {
  const { faceId } = useParams();
  const { t } = useTranslation();
  const [face, setFace] = useState();

  const fetchFace = useCallback(async () => {
    try {
      const [{ data: faceData }, { data: { attributes: attributesData } }] = await Promise.all([
        apiContainer.lunaClient.faces.get(faceId),
        apiContainer.lunaClient.faces.attributes(faceId).get(),
      ]);
      return setFace({ ...faceData, ...attributesData });
    } catch ({ error }) {
      if (error?.response?.data?.error_code === 22002) {
        return setFace(null);
      }
      throw error;
    }
  }, [faceId]);

  useEffect(() => { fetchFace(); }, [fetchFace]);

  const afterSubmit = () => fetchFace();

  if (face === null) return <Pages.NotFoundPage />;

  return (
    <Page
      data-testid="facePage"
      title={t('faces:детали лица')}
    >
      <Divider small />
      <Grid>
        <GridRow>
          <GridCol cols={5}>
            <Divider />
            <Grid>
              <Panel>
                <GridRow>
                  <GridCol cols={5}>
                    <div className={st.ImageWrapper}>
                      <SampleWithActions
                        actions={<FaceAction imageUrl={face?.uiAvatarURL} />}
                        className={st.ImageWrapper}
                        data-testid="sampleImage"
                        imageUrl={face?.uiAvatarURL}
                        type="face"
                      />
                    </div>
                  </GridCol>
                  <GridCol cols={7}>
                    <LinkWithPermissions
                      data-testid="faceActionsSearchLink"
                      icon={<SearchIcon />}
                      permissions={{ rules: [permissions.face.matching] }}
                      to={generatePath(`${routes.search}?${qs.stringify({
                        references: [{
                          id: faceId,
                          type: 'face',
                          referenceType: 'face',
                          candidateOrigin: 'faces',
                        }],
                      })}`)}
                    >
                      {t('faces:найти схожие лица')}
                    </LinkWithPermissions>
                    <Divider small />

                    <UpdatePhotoWidget afterSubmit={afterSubmit} faceId={faceId} />
                    <Divider small />
                    <EditFaceInfoWidget afterSubmit={afterSubmit} defaultValues={face} faceId={faceId} />
                    <Divider small />
                    <DeleteFaceWidget faceId={faceId} />
                  </GridCol>
                </GridRow>
                <GridRow>
                  <FaceInfo data-testid="faceInfo" face={face} />
                  <TabsWithPermissions testId="faceInfoTabs">
                    <Tab
                      header={t('атрибуты лица')}
                      headerType="fullWidth"
                      name="basic attributes"
                      permissions={{ rules: [permissions.attribute.view] }}
                    >
                      <AttributesTab faceId={faceId} />
                    </Tab>
                    <Tab
                      header={t('дополнительные свойства')}
                      headerType="fullWidth"
                      name="additional properties"
                      permissions={{ rules: [permissions.resources.sdk] }}
                    >
                      <AdditionalPropertiesTab sampleId={face?.face_descriptor_samples?.[0]} />
                    </Tab>
                  </TabsWithPermissions>
                </GridRow>
              </Panel>
            </Grid>
          </GridCol>

          <GridCol cols={7}>
            <Panel>
              <TabsWithPermissions testId="tableTabs">
                {face?.event_id && (
                  <Tab className={st.TabHeader} header={t('faces:created from event.title')} headerType="fullWidth" name="created from event">
                    <CreatedFromEventTab eventId={face?.event_id} />
                  </Tab>
                )}

                <Tab
                  className={st.TabHeader}
                  header={t('faces:лица с тем же внешним id')}
                  headerType="fullWidth"
                  name="face with the same externalId"
                >
                  <FacesByExternalIdTab externalId={face?.external_id} />
                </Tab>
                <Tab
                  className={st.TabHeader}
                  header={t('faces:последние события с данным лицом')}
                  headerType="fullWidth"
                  name="last events with the same face"
                  permissions={{ rules: [permissions.event.view] }}
                >
                  <LastEventsByFaceIdTab faceId={faceId} />
                </Tab>
              </TabsWithPermissions>
            </Panel>
          </GridCol>
        </GridRow>
      </Grid>
    </Page>
  );
}
