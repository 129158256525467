import React, { memo } from 'react';

import { IconInner } from '../../IconInner';

const FileIconInner = (props) => (
  <IconInner name="FileIcon" viewBox="0 0 24 24" {...props}>
    <path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9l-7-7Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
    <path d="M13 2v7h7" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
  </IconInner>
);

export const FileIcon = memo(FileIconInner);
