import React from 'react';

import { TASKS } from '@vlabs/api-bindings/src/luna-client/constants';
import validate from '@vlabs/shared/validators';
import { Control, GridCol, GridRow, SettingsItemWrapper } from '@vlabs/uikit';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

function Samba() {
  const { t } = useTranslation();
  const { register, formState: { errors }, control, watch } = useFormContext();

  const authUser = watch('content.source.samba.authorization.user');
  const authPassword = watch('content.source.samba.authorization.password');

  return (
    <>
      <GridRow>
        <GridCol>
          <Control.Input
            id="content.source.samba.reference.host"
            label={t('tasks:lp.estimator task.fields.content.source.samba.reference.host.label')}
            placeholder={t('tasks:lp.estimator task.fields.content.source.samba.reference.host.placeholder')}
            {...register('content.source.samba.reference.host', {
              required: validate.required(),
            })}
            errors={errors}
          />
        </GridCol>
        <GridCol cols={2}>
          <Control.Input
            id="content.source.samba.reference.port"
            label={t('tasks:lp.estimator task.fields.content.source.samba.reference.port.label')}
            placeholder={t('tasks:lp.estimator task.fields.content.source.samba.reference.port.placeholder')}
            {...register('content.source.samba.reference.port', {
              validate: {
                isInteger: validate.integer(),
              },
              min: validate.gte(1),
              max: validate.lte(65535),
            })}
            errors={errors}
          />
        </GridCol>
      </GridRow>

      <GridRow>
        <GridCol>
          <Control.Input
            id="content.source.samba.reference.path"
            label={t('tasks:lp.estimator task.fields.content.source.samba.reference.path.label')}
            placeholder={t('tasks:lp.estimator task.fields.content.source.samba.reference.path.placeholder')}
            {...register('content.source.samba.reference.path')}
            errors={errors}
          />
        </GridCol>
      </GridRow>

      <GridRow>
        <GridCol>
          <Control.Input
            id="content.source.samba.reference.prefix"
            label={t('tasks:lp.estimator task.fields.content.source.samba.reference.prefix.label')}
            placeholder={t('tasks:lp.estimator task.fields.content.source.samba.reference.prefix.placeholder')}
            {...register('content.source.samba.reference.prefix')}
            errors={errors}
          />
        </GridCol>
        <GridCol>
          <Control.Input
            id="content.source.samba.reference.postfix"
            label={t('tasks:lp.estimator task.fields.content.source.samba.reference.postfix.label')}
            placeholder={t('tasks:lp.estimator task.fields.content.source.samba.reference.postfix.placeholder')}
            {...register('content.source.samba.reference.postfix')}
            errors={errors}
          />
        </GridCol>
      </GridRow>

      <GridRow>
        <GridCol>
          <Control.Input
            id="content.source.samba.authorization.user"
            label={t('tasks:lp.estimator task.fields.content.source.samba.authorization.user.label')}
            placeholder={t('tasks:lp.estimator task.fields.content.source.samba.authorization.user.placeholder')}
            {...register('content.source.samba.authorization.user', {
              validate: {
                passwordRequired: (value) => (!value && authPassword
                  ? validate.required()
                  : undefined),
              },
            })}
            errors={errors}
          />
        </GridCol>
        <GridCol>
          <Control.Input
            id="content.source.samba.authorization.password"
            label={t('tasks:lp.estimator task.fields.content.source.samba.authorization.password.label')}
            placeholder={t('tasks:lp.estimator task.fields.content.source.samba.authorization.password.placeholder')}
            {...register('content.source.samba.authorization.password', {
              validate: {
                userRequired: (value) => (!value && authUser
                  ? validate.required()
                  : undefined),
              },
            })}
            errors={errors}
          />
        </GridCol>
      </GridRow>

      <GridRow>
        <GridCol>
          <SettingsItemWrapper title={t('tasks:lp.estimator task.fields.content.source.samba.recursive.label')}>
            <Control.Switch
              control={control}
              name="content.source.samba.recursive"
            />
          </SettingsItemWrapper>

        </GridCol>
      </GridRow>
      <GridRow>
        <GridCol>
          <Control.Select
            control={control}
            errors={errors}
            id="content.source.samba.image_type"
            label={t('tasks:lp.estimator task.fields.content.source.samba.image_type.label')}
            name="content.source.samba.image_type"
            options={TASKS.ESTIMATOR.SOURCE.IMAGE_TYPE.raw}
          />
        </GridCol>
      </GridRow>
    </>
  );
}

export { Samba };
