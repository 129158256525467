import React from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '../../controls/button/Button';
import { Input } from '../../controls/input/Input';
import { Divider } from '../../divider/Divider';
import { regexp } from '../../utils/regexps';

// TODO: объединить формы логина и восстановления пароля, чтобы не использовать модалки

const ForgotPasswordForm = ({
  login,
  onSubmit,
  useEmailAsLogin,
  loginPlaceholder,
}) => {
  const { t } = useTranslation();
  const { handleSubmit, register, formState: { errors } } = useForm({
    defaultValues: { login },
  });

  const $loginPlaceholder = loginPlaceholder || (
    useEmailAsLogin
      ? t('uikit:формы.поля.email')
      : t('uikit:формы.поля.логин')
  );
  const loginValidation = {
    required: t('uikit:валидация.required'),
  };

  if (useEmailAsLogin) {
    loginValidation.pattern = {
      value: regexp.email,
      message: t('uikit:валидация.email'),
    };
  }

  return (
    <form
      data-testid="forgot-password-form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Input
        data-testid="loginInput"
        errors={errors}
        placeholder={$loginPlaceholder}
        {...register('login', loginValidation)}
      />

      <Divider small />

      <Button
        fullWidth
        type="submit"
      >
        {t('uikit:формы.действия.восстановление пароля')}
      </Button>
    </form>
  );
};

ForgotPasswordForm.propTypes = {
  login: PropTypes.string,
  onSubmit: PropTypes.func,
  useEmailAsLogin: PropTypes.bool,
  loginPlaceholder: PropTypes.string,
};

ForgotPasswordForm.defaultProps = {
  login: '',
  onSubmit: () => {},
  useEmailAsLogin: undefined,
  loginPlaceholder: undefined,
};

export { ForgotPasswordForm };
