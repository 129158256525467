import React, { memo } from 'react';

import { IconInner } from '../../IconInner';

const UploadCloudIconInner = (props) => (
  <IconInner name="UploadCloudIcon" viewBox="0 0 24 24" {...props}>
    <g clipPath="url(#a)">
      <path d="m16 16-4-4-4 4m4-4v9" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
      <path d="M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
      <path d="m16 16-4-4-4 4" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
    </g>
  </IconInner>
);

export const UploadCloudIcon = memo(UploadCloudIconInner);
