import React from 'react';

import { ImageIcon, PackageIcon, PlusIcon } from '@vlabs/icons';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export const DefaultCaption = () => {
  const { t } = useTranslation();
  return (
    <div className="FileDropzone__Caption">
      <PlusIcon />

      <span className="FileDropzone__CaptionTitle Subtitle-2">
        {t('uikit:control.fileDropzone.заголовок')}
      </span>

      <span className="Small-2">
        {t('uikit:control.fileDropzone.подзаголовок')}
      </span>
    </div>
  );
};

export const ImagesOnlyCaption = () => {
  const { t } = useTranslation();
  return (
    <div className="FileDropzone__Caption">
      <ImageIcon />
      <span className="FileDropzone__CaptionTitle Subtitle-2">
        {t('uikit:control.fileDropzone.заголовок')}
      </span>
    </div>
  );
};

export const ArchivesOnlyCaption = () => {
  const { t } = useTranslation();
  return (
    <div className="FileDropzone__Caption">
      <PackageIcon />
      <span className="FileDropzone__CaptionTitle Subtitle-2">
        {t('uikit:control.fileDropzone.заголовок')}
      </span>
    </div>
  );
};

export const ImageSelectedCaption = ({ name }) => {
  const { t } = useTranslation();
  return (
    <div className="FileDropzone__Caption FileDropzone__Caption_filled">
      <ImageIcon />
      <span className="FileDropzone__CaptionTitle Subtitle-2">
        {`${t('uikit:control.fileDropzone.выбрано изображение')}: ${name}`}
      </span>
    </div>
  );
};
ImageSelectedCaption.propTypes = {
  name: PropTypes.string.isRequired,
};

export const ArchiveSelectedCaption = ({ name }) => {
  const { t } = useTranslation();
  return (
    <div className="FileDropzone__Caption FileDropzone__Caption_filled">
      <PackageIcon />
      <span className="FileDropzone__CaptionTitle Subtitle-2">
        {`${t('uikit:control.fileDropzone.выбран архив')}: ${name}`}
      </span>
    </div>
  );
};
ArchiveSelectedCaption.propTypes = {
  name: PropTypes.string.isRequired,
};
