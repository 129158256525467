import postGarbageCollectionTask from '../luna-tasks/schemas/postGarbageCollectionTask';
import { eventToEvent, readEmitEvent } from './events/mappers';
import { schemes as facesSchemes } from './faces';
import { schemes as handlerSchemes } from './handlers/mappers/schemes';
import { schemes as isoSchemes } from './iso';
import { schemes as matcherSchemes } from './matcher';
import { schemes as objectsSchemes } from './objects';
import { schemes as taskSchemes } from './tasks';
import { getToken } from './tokens/getToken';
import { postToken } from './tokens/postToken';
import { schemes as verifierSchemes } from './verifiers';

export function buildSchemaProxy(client) {
  return {
    verifiers: {
      read: verifierSchemes.read,
      create: verifierSchemes.create,
      readVerifyResults: verifierSchemes.readVerifyResults,
    },
    tasks: {
      exporter: taskSchemes.exporter,
      estimator: taskSchemes.estimator,
      crossmatch: taskSchemes.crossmatch,
      postGCTask: postGarbageCollectionTask,
    },
    objects: {
      create: objectsSchemes.createResp(client),
    },
    matcher: {
      searchFiltersMapper: matcherSchemes.searchFiltersMapper,
      read: matcherSchemes.read(client),
    },
    iso: {
      read: isoSchemes.read,
      isoQS: isoSchemes.isoQS,
    },
    handlers: {
      read: handlerSchemes.read,
      create: handlerSchemes.create,
    },
    faces: {
      read: facesSchemes.read(client),
      create: facesSchemes.create,
      facesQS: facesSchemes.getFacesQS,
      facesCountQS: facesSchemes.getFacesCountQS,
    },
    events: {
      read: eventToEvent(client),
      readEmitEvent,
    },
    tokens: {
      post: postToken,
      get: getToken,
    },
  };
}
