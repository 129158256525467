import React, { memo } from 'react';

import { IconInner } from '../../IconInner';

const GridIconInner = (props) => (
  <IconInner name="GridIcon" viewBox="0 0 24 24" {...props}>
    <path d="M10 3H3v7h7V3Zm11 0h-7v7h7V3Zm0 11h-7v7h7v-7Zm-11 0H3v7h7v-7Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
  </IconInner>
);

export const GridIcon = memo(GridIconInner);
