import React, { memo } from 'react';

import { IconInner } from '../../IconInner';

const MinusIconInner = (props) => (
  <IconInner name="MinusIcon" viewBox="0 0 24 24" {...props}>
    <path d="M5 12h14" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
  </IconInner>
);

export const MinusIcon = memo(MinusIconInner);
