import React, { useEffect } from 'react';

import { Control, Divider, Grid, GridCol, GridRow, Page } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { defaultStreamData } from './defaultStreamData';
import st from './StreamEditForm.module.sass';
import AutorestartSubForm from './subforms/AutorestartSubForm';
import CommonSubForm from './subforms/CommonSubForm';
import DataSubForm from './subforms/DataSubForm';
import HandlerSubForm from './subforms/HandlerSubForm';
import LocationSubForm from './subforms/LocationSubForm';
import PoliciesSubForm from './subforms/PoliciesSubForm';

function StreamEditForm({
  state,
  onSubmit,
  onDelete,
  values,
}) {
  const { t } = useTranslation();
  const formMethods = useForm({ defaultValues: defaultStreamData });
  const { handleSubmit, reset } = formMethods;

  useEffect(() => { reset(values); }, [reset, values]);

  const onAction = (
    <>
      <Control.Button type="submit">
        {t('lunaStreamsStreams:форма.submit', { context: (state) })}
      </Control.Button>
      {state === 'edit' && (
        <>
          <Divider small />
          <Control.Button
            kind="negative"
            onClick={() => onDelete()}
            type="button"
          >
            {t('lunaStreamsStreams:форма.delete')}
          </Control.Button>
        </>
      )}
    </>
  );
  const context = { ...formMethods, state };
  return (
    <FormProvider {...context}>
      <form className={st.Root} onSubmit={handleSubmit(onSubmit)}>
        <Page
          actions={onAction}
          title={t('lunaStreamsStreams:форма.заголовок', { context: (state) })}
        >
          <Divider />
          <Grid>
            <GridRow>
              <GridCol cols={6}>
                <CommonSubForm />
                <Divider />
                <DataSubForm />
                <Divider />
                <HandlerSubForm />
                <Divider />
                <LocationSubForm />
                <Divider />
                <AutorestartSubForm />
              </GridCol>
              <GridCol cols={6}>
                <PoliciesSubForm />
              </GridCol>
            </GridRow>
          </Grid>
        </Page>

      </form>
    </FormProvider>
  );
}

StreamEditForm.propTypes = {
  state: PropTypes.oneOf(['create', 'edit']),
  values: PropTypes.shape({}),
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};
StreamEditForm.defaultProps = {
  state: 'create',
  values: undefined,
};

export default StreamEditForm;
