import React, { memo } from 'react';

import { IconInner } from '../../IconInner';

const TriangleExclamationIconInner = (props) => (
  <IconInner name="TriangleExclamationIcon" viewBox="0 0 24 24" {...props}>
    <path d="M10.29 3.86 1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0v0ZM12 9v4m0 4h.01" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
  </IconInner>
);

export const TriangleExclamationIcon = memo(TriangleExclamationIconInner);
