export const prepareActions = (actionHandlers) => {
  const actions = {};

  Object.entries(actionHandlers).forEach(([actionName, actionData]) => {
    if (!actionData) return;

    const { handler, can } = actionData;

    actions[actionName] = {
      label: actionData.label,
      getHandlerIfCan: (canData) => {
        return (!can || can(canData)) ? handler : undefined;
      },
    };
  });

  return actions;
};
