import React from 'react';

import { Control, Tooltip } from '@vlabs/uikit';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { viewerCan } from '@vlabs/pages/auth/selectors';

const LinkWithPermissionsComponent = ({
  permissions,
  disabled,
  children,
  can,
  ...props
}) => {
  const { rules, method = 'oneof' } = permissions;

  const hasPermission = can(rules, { method });
  const isDisabled = !hasPermission || disabled;

  return (
    <Tooltip
      data-tooltip-content={!hasPermission ? i18n.t('кнопка.недостаточно прав.title') : null}
    >
      <Control.Link
        disabled={isDisabled}
        {...props}
      >
        {children}
      </Control.Link>
    </Tooltip>
  );
};

LinkWithPermissionsComponent.propTypes = {
  permissions: PropTypes.shape({
    rules: PropTypes.arrayOf(PropTypes.string).isRequired,
    method: PropTypes.oneOf(['oneof', 'allof']),
  }),
  can: PropTypes.func.isRequired,
  children: PropTypes.node,
  disabledTitle: PropTypes.string,
  disabled: PropTypes.bool,
};

LinkWithPermissionsComponent.defaultProps = {
  children: undefined,
  permissions: { rules: [], method: 'oneof' },
  disabledTitle: i18n.t('кнопка.недостаточно прав.title'),
  disabled: false,
};

export const LinkWithPermissions = connect((state) => ({
  can: viewerCan(state),
}))(LinkWithPermissionsComponent);
