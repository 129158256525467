import React, { useContext } from 'react';

import { ArrowStraightDownIcon, ArrowStraightUpIcon, ArrowStraightUpDownIcon } from '@vlabs/icons';
import cn from 'classnames';
import PropTypes from 'prop-types';

import { addCellStyles } from './styles';
import { TableContext } from './table-context';
import './Table.sass';

const SortIcon = ({
  column: {
    isSorted,
    isSortedDesc,
  },
}) => {
  if (!isSorted) {
    return (
      <>
        &nbsp;
        <ArrowStraightUpDownIcon className="TableHead__BaseSortIcon" />
      </>
    );
  }

  const Icon = isSortedDesc ? ArrowStraightDownIcon : ArrowStraightUpIcon;

  return (
    <>
      &nbsp;
      <Icon className="TableHead__SortIcon" />
    </>
  );
};

SortIcon.propTypes = {
  column: PropTypes.object,
};

SortIcon.defaultProps = {
  column: {},
};

const TableHead = () => {
  const { headerGroups } = useContext(TableContext);

  const headerProps = (props, { column }) => addCellStyles(props, column);

  return (
    <div
      className="TableHead"
      data-testid="table.head"
    >
      {headerGroups.map((headerGroup) => (
        <div
          className="TableRow"
          {...headerGroup.getHeaderGroupProps()}
          data-tesetid="table.headRow"
        >
          {headerGroup.headers.map((column) => (
            <div
              {...column.getHeaderProps(headerProps)}
              className={cn({
                TableCell: true,
                TableCell_action: column.type === 'action',
              })}
              data-tesetid="table.headCell"
            >
              {column.canFilter && column.Filter && (
                <div
                  className="TableHead__FilterContainer"
                  data-tesetid="table.columnFilter"
                >
                  {column.render('Filter')}
                </div>
              )}

              <div
                {...(column.getSortByToggleProps && column.getSortByToggleProps())}
                className={cn({
                  TableCell_canSort: column.canSort,
                })}
                data-tesetid="table.headTitle"
              >
                {column.render('Header')}
                {column.canSort && <SortIcon column={column} />}
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

TableHead.propTypes = {
  headerGroups: PropTypes.arrayOf(PropTypes.any),
};
TableHead.defaultProps = {
  headerGroups: [],
};

export { TableHead };
