import { createSelector } from '@reduxjs/toolkit';
import { selectListOptions, selectLists } from '@vlabs/shared/selectors/listSelectors';
import i18n from 'i18next';
import _ from 'lodash';

import { defaultHandler } from './defaultHandler';

export const selectHandlers = (state) => state.handlers;
export const selectHandlersData = ({ handlers: slice }) => slice.data;
export const selectHandlerListsById = (handlerId) => createSelector(
  selectHandlers,
  selectListOptions,
  ({ byId }, lists) => {
    const handler = byId[handlerId];
    if (!handler) return undefined;
    return handler
      .matchPolicy
      .filter(({ listId }) => listId)
      .map(({ listId, threshold }) => ({
        list: lists.find((v) => v.value === listId) || { value: listId },
        threshold,
      }));
  },
);

export const selectHandlerOptions = (state) => state.handlers.handlerOptions;

export const selectHandlerOptionsForReports = createSelector(
  selectHandlerOptions,
  (options) => options.map(({ label }) => ({ value: label, label })),
);

export const selectHandlerLinkOptions = createSelector(
  selectHandlerOptions,
  (options) => options
    .map(({ label, value, linkValueForStreams }) => ({ value: linkValueForStreams, label, handlerId: value })),
);

export const selectStaticHandlerOptions = createSelector(
  selectHandlerOptions,
  (options) => options.filter(({ isDynamic }) => !isDynamic),
);

export const selectDynamicHandlerId = (state) => state.handlers.dynamicHandlerId;
export const selectHandlersState = ({ handlers: { state } }) => state;
export const selectPageData = ({ handlers: {
  pageData,
  meta,
  pageIndex,
  pageSize,
  pageSizeOptions,
} }) => ({ data: pageData, meta, pageIndex, pageSize, pageSizeOptions });

export const selectHandlerStateById = createSelector(
  selectHandlers,
  (state, handlerId) => handlerId,
  ({ byId: handlersById }, handlerId) => {
    if (handlersById[handlerId] === undefined) return 'notFound';
    return 'loaded';
  },
);
export const selectHandlerById = createSelector(
  selectHandlers,
  selectLists,
  (state, handlerId) => handlerId,
  ({ byId: handlersById }, { byId: listsById }, handlerId) => {
    const refineHandler = (handler) => {
      const result = _.cloneDeep(handler);
      result.matchPolicy.forEach((policy, i) => {
        if (policy?.origin?.value === 'events') {
          _.set(result, `matchPolicy.${i}.handlers`, policy.handlerIds?.map(($handlerId) => ({
            value: $handlerId,
            label: handlersById[$handlerId]?.description || $handlerId,
          })));

          _.set(result, `matchPolicy.${i}.sources`, policy.sources?.map(($source) => ({
            value: $source,
            label: $source,
          })));
        }
        if (policy?.origin?.value === 'faces' && policy?.listId) {
          _.set(result, `matchPolicy.${i}.list`, {
            value: policy.listId,
            label: listsById[policy.listId]?.user_data || i18n.t('handlers:список удален'),
            isDeleted: listsById[policy.listId] === undefined,
          });
        }
      });
      result.faceToListPolicy.lists.forEach((list, i) => {
        _.set(result, `faceToListPolicy.lists.${i}.list`, {
          value: list.listId,
          label: listsById[list.listId]?.user_data || i18n.t('handlers:список удален'),
          isDeleted: listsById[list.listId] === undefined,
        });
      });
      return result;
    };

    if (handlerId === 'create' || handlerId === undefined) return defaultHandler;
    if (handlersById[handlerId] === undefined) return {};
    return refineHandler(handlersById[handlerId]);
  },
);

export const selectHandlersWithLists = createSelector(
  selectHandlersData,
  selectListOptions,
  (handlers, listOptions) => handlers.map(({ handlerId, description, matchPolicy }) => ({
    handlerId,
    option: { value: handlerId, label: description },
    listOptions: matchPolicy
      ?.map(({ listId }) => listOptions.find(({ value }) => listId === value)).filter((v) => v),
  })).reduce((result, item) => {
    const value = item?.handlerId;
    if (item.listOptions?.length > 0) {
      // FIXME: Какая-то непонятная муть 🤷
      // eslint-disable-next-line no-param-reassign
      result[value] = item;
    }
    return result;
  }, {}),
);
