import {
  applyScaleRect,
  denormalizeRect,
  fixDenormalizedValuesRect,
} from './normalize';

// Изменяет размер канваса в соответствие с контейнером
export const setCanvasBackground = (canvas, bg, maxWidth, maxHeight, topPadding = 0) => {
  let scaleFactor = 1;

  if (bg.width > maxWidth || bg.height > maxHeight) {
    canvas.setWidth(maxWidth);
    scaleFactor = maxWidth / bg.width;
    canvas.setHeight(bg.height * scaleFactor + topPadding);
  } else {
    canvas.setWidth(bg.width);
    canvas.setHeight(bg.height + topPadding);
  }

  canvas.setBackgroundImage(bg, canvas.renderAll.bind(canvas), {
    originX: 'left',
    originY: 'top',
    scaleX: scaleFactor,
    scaleY: scaleFactor,
    left: 0,
    top: topPadding,
  });

  return { scaleFactor };
};

export const calcMaxModalSizes = (ratio = 0.8) => [
  ratio * document.documentElement.clientWidth,
  ratio * document.documentElement.clientHeight,
];

export const calcRectangleCanvasCoords = ({ rectangle, canvas, isNormalized }) => {
  let rect;
  if (isNormalized) {
    rect = denormalizeRect(rectangle, canvas);
  } else {
    rect = applyScaleRect(rectangle, canvas.width / canvas.backgroundImage.width);
  }

  return fixDenormalizedValuesRect(rect, canvas);
};
