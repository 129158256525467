import React, { useEffect, useState } from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';

import './AccountMenuItem.sass';

const AccountMenuLink = ({
  children,
  className,
  icon,
  noReactRouter,
  ...props
}) => {
  const LinkComponent = noReactRouter ? 'a' : Link;

  const [selectedItem, setSelectedItem] = useState('');
  const location = useLocation();

  useEffect(() => {
    setSelectedItem(location.pathname);
  }, [location.pathname]);

  const { to } = props;
  const isThisItemSelected = selectedItem === to;

  return (
    <LinkComponent
      className={cn(
        className,
        'InteractionWrapper',
        'Body-2',
        {
          AccountMenuItem: true,
          AccountMenuItem_link: true,
          AccountMenuItem_noIcon: !icon,
        },
      )}
      {...props}
    >
      {icon && (
        <span className="AccountMenuItem__Icon">
          {icon}
        </span>
      )}

      {children}

      {isThisItemSelected && <div className="SelectedItemOverlay" />}

      <div className="InteractionOverlay" />
    </LinkComponent>
  );
};

AccountMenuLink.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  icon: PropTypes.node,
  noReactRouter: PropTypes.bool,
  to: PropTypes.string.isRequired,
};

AccountMenuLink.defaultProps = {
  children: undefined,
  className: undefined,
  icon: undefined,
  noReactRouter: false,
};

export { AccountMenuLink };
