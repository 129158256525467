import React, { useContext } from 'react';

import { EditIcon } from '@vlabs/icons';
import { ButtonWithPermissions } from '@vlabs/shared/components/button-with-permissions/ButtonWithPermissions';
import { TableContext } from '@vlabs/uikit/src/components/table/table-context';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const EditCell = ({
  row,
  buttonProps,
}) => {
  const { t } = useTranslation();
  const { actions, onActions } = useContext(TableContext);
  const onEdit = actions.onEditRow?.getHandlerIfCan?.(row.original);
  if (!onEdit) return null;

  const permissions = onActions?.onEditRow?.permissions;
  const label = actions.onEditRow?.label;

  return (
    <ButtonWithPermissions
      data-testid="editCell"
      icon={<EditIcon />}
      kind="primary"
      onClick={() => onEdit(row.original)}
      permissions={permissions}
      round
      title={label || t('uikit:table.действия.редактирование элемента')}
      variant="dimmed"
      {...(buttonProps || {})}
    />
  );
};

EditCell.propTypes = ({
  row: PropTypes.objectOf(PropTypes.any),
  buttonProps: PropTypes.object,
});

EditCell.defaultProps = ({
  row: undefined,
  buttonProps: undefined,
});

export { EditCell };
