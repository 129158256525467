import React from 'react';

import { archiveTypes } from '@vlabs/shared/config';
import validate from '@vlabs/shared/validators';
import { Control, GridCol, GridRow } from '@vlabs/uikit';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

function File() {
  const { t } = useTranslation();
  const { register, formState: { errors } } = useFormContext();

  /* FIXME убрать проверку на архив когда обновим компонент в ките */
  return (
    <GridRow>
      <GridCol>
        <Control.FileInput
          label={t('tasks:lp.estimator task.fields.content.source.file.reference.label')}
          {...register('content.source.file.reference', {
            required: validate.required(),
            validate: (file) => {
              if (!archiveTypes.includes(file[0].type)) {
                return t('валидация.неподходящий формат файла');
              }
              return undefined;
            },
          })}
          accept=".zip,.rar,.7zip"
          errors={errors}
        />
      </GridCol>
    </GridRow>
  );
}

export { File };
