import { get, CleanSchema } from '../../../json-transform';
import { EventFiltersSubSchema } from '../../events/mappers/eventFilters';

const DEFAULT_THRESHOLD_VALUE = 0.9;
const DEFAULT_LIMIT_VALUE = 3;

const CandidateSchema = new CleanSchema({
  filters: get('filters').asSchema(EventFiltersSubSchema),
  order: get('order'),
  limit: get('limit').asInt().ifNaN(DEFAULT_LIMIT_VALUE),
  targets: get('targets'),
  threshold: get('threshold').asFloat().toNormalizedPercent().ifNaN(DEFAULT_THRESHOLD_VALUE),
});

export const searchFiltersMapper = new CleanSchema({
  references: get('references'),
  candidates: get('candidates', {})
    .as((candidates) => candidates.map(CandidateSchema)),
});
