import React, { memo } from 'react';

import { IconInner } from '../../IconInner';

const SquareMinusIconInner = (props) => (
  <IconInner name="SquareMinusIcon" viewBox="0 0 24 24" {...props}>
    <path d="M19 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2ZM8 12h8" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
  </IconInner>
);

export const SquareMinusIcon = memo(SquareMinusIconInner);
