export const imageFromSource = (src) => new Promise((resolve, reject) => {
  const img = new Image();
  img.onload = ({ target }) => {
    resolve(target);
  };
  img.onerror = reject;
  img.src = src;
});

export const reorganizeInternalPoints = (points) => {
  if (points[0].length >= points[1].length) {
    return points[0].map((p1, i) => [p1, points[1][i]]);
  }
  return points[1].map((p1, i) => [points[0][i], p1]);
};

export const reorganizeExternalPoints = (points) => {
  return [
    points?.map((p) => p?.[0]) || [],
    points?.map((p) => p?.[1]) || [],
  ];
};
