import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { fabric } from 'fabric';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import nextId from 'react-id-generator';

import { Button } from '../controls/button/Button';
import { Divider } from '../divider/Divider';
import { drawRectangle } from '../utils/canvas-drawing';
import { calcMaxModalSizes, calcRectangleCanvasCoords, setCanvasBackground } from '../utils/canvas-utils';
import { denormalizeRect, normalizeRect } from '../utils/normalize';
import { enableRectDrawing } from './rect-drawing';

import './ROISelector.sass';

const DEFAULT_ROI_COLOR = 'wheat';

const RectangleSelector = ({
  preview,
  onSelect,
  onReset,
  onCancel,
  roi: inputROI,
  isNormalized,
  normalizeBy,
  maxModalSizeRatio,
  coordinatesRounding,
  sizeRounding,
  hintText,
  ...props
}) => {
  const { t } = useTranslation();
  const canvasRef = useRef(null);
  const [canvas, setCanvas] = useState(undefined);
  const [internalROI, setInternalROI] = useState(undefined);
  const isMounted = useRef(false);

  useEffect(() => {
    if (!canvasRef.current) return undefined;

    // Иногда реакт переподключается и не видит уже созданный dom-узел
    // что приводит к повторной инициализации fabric и поломке
    // поэтому используется костыль в виде уникального id на канвасе
    if (!canvasRef.current.getAttribute('id')) {
      const canvasId = nextId('RectSelectorCanvas');
      canvasRef.current.setAttribute('id', canvasId);
      setCanvas(new fabric.Canvas(canvasId));
    }

    isMounted.current = true;

    return () => {
      isMounted.current = false;
      if (canvas) {
        // Иногда react удаляет объекты раньше fabric
        // что приводит к ошибкам, в данном случае достаточно
        // просто подавить ошибки
        try {
          canvas.dispose();
        } catch (e) {
          // pass
        }
      }
    };
  }, [canvasRef.current]);

  const addRectControl = useCallback((coords) => {
    if (!canvas || !setInternalROI) return;

    // Так как входные координаты изменились - обновляем все контролы на канвасе
    canvas.remove(...canvas.getObjects());

    const canvasCoords = calcRectangleCanvasCoords({ isNormalized, rectangle: coords, canvas });
    const rectangle = drawRectangle({
      id: 'roi',
      left: canvasCoords.x,
      top: canvasCoords.y,
      width: canvasCoords.width,
      height: canvasCoords.height,
      fill: DEFAULT_ROI_COLOR,
    });

    rectangle.controls = {
      ...fabric.Rect.prototype.controls,
      mtr: new fabric.Control({ visible: false }),
    };
    canvas.add(rectangle);

    // После отрисовки контрола навешиваем на него обработчики для рисования
    // и прокидываем обработчики для завершения построения и визуализации координаты
    enableRectDrawing(canvas, setInternalROI);
  }, [canvas, setInternalROI]);

  useEffect(() => {
    if (!inputROI) return;
    if (!canvas || !preview) return;

    async function update() {
      if (!canvas.backgroundImage) {
        await new Promise((resolve) => {
          fabric.Image.fromURL(preview, (backgroundImage) => {
            if (!isMounted) return;
            const [maxWidth, maxHeight] = calcMaxModalSizes(maxModalSizeRatio);
            // Устанавливаем изображение в качестве подложки для канваса
            setCanvasBackground(canvas, backgroundImage, maxWidth, maxHeight);
            resolve(backgroundImage);
          });
        });
      }

      // Рассчитываем новые координаты в зависимости от параметра isNormalized
      let newCoords = internalROI;
      if (isNormalized) {
        newCoords = denormalizeRect(inputROI, {
          width: canvas.backgroundImage.width,
          height: canvas.backgroundImage.height,
          denominator: normalizeBy,
        });
      } else {
        newCoords = inputROI;
      }

      // Проверяем изменились ли пересчитанные координаты или нет
      if (!isEqual(internalROI, newCoords)) {
        // Если изменились - обновляем их
        setInternalROI(newCoords);
      }

      addRectControl(newCoords);
    }

    update();
  }, [inputROI, setInternalROI, isNormalized, canvas]);

  // Сброс в полный кадр
  const resetRoi = useCallback(() => {
    const newCoords = { x: 0, y: 0, width: 0, height: 0 };
    setInternalROI(newCoords);
    addRectControl(newCoords);
  }, [setInternalROI, addRectControl]);

  // Форматирование возвращаемых и видимых пользователю координат
  const formatOutputROI = useCallback(() => {
    if (!canvas) return undefined;
    if (!internalROI) return undefined;

    let formattedROI = internalROI;
    if (isNormalized) {
      formattedROI = normalizeRect(
        internalROI,
        {
          width: canvas.backgroundImage.width,
          height: canvas.backgroundImage.height,
          denominator: normalizeBy,
        },
      );
    }
    const round = (val, precision) => Number(val.toFixed(precision));

    return {
      x: round(formattedROI.x, coordinatesRounding),
      y: round(formattedROI.y, coordinatesRounding),
      width: round(formattedROI.width, sizeRounding),
      height: round(formattedROI.height, sizeRounding),
    };
  }, [isNormalized, canvas, coordinatesRounding, sizeRounding, internalROI]);

  // Обработчик сохранения ROI
  const onSubmit = useCallback(() => {
    if (!onSelect) return;

    onSelect(formatOutputROI(internalROI));
  }, [onSelect, formatOutputROI, internalROI]);

  const isFullFrameButtonDisabled = useMemo(
    () => () => {
      return internalROI && Object.values(internalROI).every((coord) => coord === 0);
    },
    [internalROI],
  );

  return (
    <div className="ROISelector">
      <span className="ROISelector__Hint">
        {hintText}
      </span>

      <canvas
        className="ROISelector__Canvas"
        ref={canvasRef}
        {...props}
      />

      {canvas?.backgroundImage && (
        <>
          <Divider small />
          <span className="ROISelector__RawImageSize">
            {`${t('uikit:roi.размер кадра')}: ${canvas.backgroundImage.width} x ${canvas.backgroundImage.height}`}
          </span>

          <Divider small />
          <pre className="ROISelector__Coordinates">
            {JSON.stringify(formatOutputROI(internalROI), null, ' ')}
          </pre>

          <Divider small />

          <div className="ROISelector__Buttons">
            <Button
              data-testid="rectangleSelector.saveButton"
              fullWidth
              onClick={onSubmit}
            >
              {t('uikit:roi.действия.сохранение')}
            </Button>

            <Divider small />

            <Button
              data-testid="rectangleSelector.fullFrameButton"
              disabled={isFullFrameButtonDisabled()}
              fullWidth
              kind="attention"
              onClick={() => resetRoi()}
            >
              {t('uikit:roi.действия.полный кадр')}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

RectangleSelector.propTypes = {
  preview: PropTypes.string,
  onSelect: PropTypes.func,
  onReset: PropTypes.func,
  onCancel: PropTypes.func,
  roi: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.any)]),
  isNormalized: PropTypes.bool,
  normalizeBy: PropTypes.number,
  maxModalSizeRatio: PropTypes.number,
  coordinatesRounding: PropTypes.number,
  sizeRounding: PropTypes.number,
  hintText: PropTypes.string,
};

RectangleSelector.defaultProps = {
  preview: undefined,
  onSelect: undefined,
  onReset: undefined,
  onCancel: undefined,
  roi: { x: 0, y: 0, width: 0, height: 0 },
  isNormalized: true,
  normalizeBy: 1,
  maxModalSizeRatio: 0.8,
  coordinatesRounding: 2,
  sizeRounding: 2,
  hintText: undefined,
};

export { RectangleSelector };
