import React, { useEffect, useState } from 'react';

import { apiContainer } from '@vlabs/api-bindings';
import { actionColumnProps } from '@vlabs/shared/utils';
import {
  Page, Panel, Divider, Grid, GridCol, GridRow,
  Table, TableCells, openConfirmPopup, Modal,
} from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { selectStreamOptions } from '@vlabs/pages/sources/luna-streams/features/streams/selectors';
import { fetchStreams } from '@vlabs/pages/sources/luna-streams/features/streams/store';

import DepartmentAccountForm from './DepartmentAccountForm';
import DepartmentCameraForm from './DepartmentCameraForm';
import { getDepartment, removeCamera, removeAccount, addCamera, addAccount } from './store';

function DepartmentPageComponent({
  streamOptions,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { departmentId } = useParams();
  const [department, setDepartment] = useState({});
  const [cameraModalIsOpen, setCameraModalIsOpen] = useState(false);
  const [accountModalIsOpen, setAccountModalIsOpen] = useState(false);
  const [accounts, setAccounts] = useState([]);

  const compareNames = ({ name }, { name: compareName }) => String(name).localeCompare(String(compareName));

  const onFetch = async () => {
    const { data: { data } } = await getDepartment(departmentId);
    setDepartment(data);
    let rawAccounts = [];
    if (data?.accountIds) {
      rawAccounts = await Promise.all(
        data.accountIds.map((accountId) => apiContainer.clementineAuthClient.accounts.get(accountId)),
      );
    }
    setAccounts(rawAccounts
      .map(({ data: { data: account } }) => account)
      .sort(compareNames));
  };

  useEffect(() => {
    onFetch();
    dispatch(fetchStreams);
    // eslint-disable-next-line
  }, []);

  const cameras = department?.cameraIds?.map(
    (departmentCameraId) => ({
      id: departmentCameraId,
      name: streamOptions?.find(({ streamId }) => streamId === departmentCameraId)?.label,
    }),
  ).sort(compareNames);

  const loadAccountOptions = async (inputValue) => {
    const params = {};
    if (inputValue) {
      params.name = inputValue;
    }
    const accountOptions = apiContainer.clementineAuthClient.accounts.getAll({ ...params })
      .then(({ data: { data } }) => data
        .map(({ id, name }) => ({ value: id, label: name }))
        .filter(({ value }) => accounts?.every(({ id }) => value !== id)));
    return accountOptions;
  };

  const filteredCamOptions = streamOptions.filter(
    ({ value }) => cameras?.every(({ id }) => value !== id),
  );

  const onAddCamera = () => { setCameraModalIsOpen(true); };
  const onAddAccount = () => { setAccountModalIsOpen(true); };
  const closeCameraModal = () => { setCameraModalIsOpen(false); };
  const closeAccountModal = () => { setAccountModalIsOpen(false); };

  const onRemoveCamera = (camera) => {
    const message = (
      <span>
        {t('departments:camera.confirmation.message', {
          context: 'delete',
          item: camera.name || camera.id,
        })}
      </span>
    );

    const onConfirm = async () => {
      await removeCamera(departmentId, camera.id);
      onFetch();
    };

    openConfirmPopup({
      title: t('departments:camera.confirmation.title', { context: 'delete' }),
      message,
      type: 'delete',
      onConfirm,
    });
  };

  const onRemoveAccount = (account) => {
    const message = (
      <span>
        {t('departments:user.confirmation.message', {
          context: 'delete',
          item: account.name,
        })}
      </span>
    );

    const onConfirm = async () => {
      await removeAccount(departmentId, account.id);
      onFetch();
    };

    openConfirmPopup({
      title: t('departments:user.confirmation.title', { context: 'delete' }),
      message,
      type: 'delete',
      onConfirm,
    });
  };

  const onCameraFormSubmit = ({ camera }) => {
    addCamera(departmentId, { cameraId: camera.streamId })
      .then(() => {
        setCameraModalIsOpen(false);
        onFetch();
      });
  };

  const onAccountFormSubmit = ({ accountId }) => {
    addAccount(departmentId, { accountId: accountId.value })
      .then(() => {
        setAccountModalIsOpen(false);
        onFetch();
      });
  };

  return (
    <Page
      className="Global__Table_fullScreen_wrapper"
      title={department?.name}
    >
      <Modal
        appElement={document.getElementById('root')}
        isOpen={cameraModalIsOpen}
        onRequestClose={closeCameraModal}
      >
        <DepartmentCameraForm
          camsOptions={filteredCamOptions}
          onSubmit={onCameraFormSubmit}
        />
      </Modal>
      <Modal
        appElement={document.getElementById('root')}
        isOpen={accountModalIsOpen}
        onRequestClose={closeAccountModal}
      >
        <DepartmentAccountForm
          loadAccountOptions={loadAccountOptions}
          onSubmit={onAccountFormSubmit}
        />
      </Modal>
      <Divider small />
      <Grid>
        <GridRow>
          <GridCol>
            <Panel className="Global__Table_fullScreen">
              <Table
                columns={[
                  { Header: t('departments:table.user name'), accessor: 'name' },
                  { Header: t('departments:table.user id'), accessor: 'id' },
                  actionColumnProps({ id: 'action1', Cell: TableCells.DeleteCell, width: 20 }),
                ]}
                data={accounts}
                onActions={{
                  onAddRow: { handler: onAddAccount },
                  onDeleteRow: { handler: onRemoveAccount },
                }}
              />
            </Panel>
          </GridCol>
          <GridCol>
            <Panel className="Global__Table_fullScreen">
              <Table
                columns={[
                  { Header: t('departments:camera.table.source'), accessor: 'name' },
                  { Header: t('departments:camera.table.source id'), accessor: 'id' },
                  actionColumnProps({ id: 'action1', Cell: TableCells.DeleteCell }),
                ]}
                data={cameras}
                onActions={{
                  onAddRow: { handler: onAddCamera },
                  onDeleteRow: { handler: onRemoveCamera },
                }}
              />
            </Panel>
          </GridCol>
        </GridRow>
      </Grid>
    </Page>
  );
}

DepartmentPageComponent.propTypes = {
  streamOptions: PropTypes.arrayOf(PropTypes.shape({
    streamId: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string,
  })),
};

DepartmentPageComponent.defaultProps = {
  streamOptions: [],
};

export const DepartmentPage = connect(
  (state) => ({
    streamOptions: selectStreamOptions(state),
  }),
)(DepartmentPageComponent);
