import { useEffect, useRef } from 'react';

export function useIntersectionObserver(func) {
  const ref = useRef(null);

  useEffect(() => {
    const node = ref?.current;

    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) func();
    });

    observer.observe(node);

    return () => observer.disconnect();
  }, [func]);

  return ref;
}
