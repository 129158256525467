import React from 'react';

import PropTypes from 'prop-types';

import st from './Menu.module.sass';

const Menu = ({
  children,
  title,
}) => {
  return (
    <div className={st.Container}>
      <div className={st.Header}>
        <h5>{title}</h5>
      </div>

      <div>
        {children}
      </div>
    </div>
  );
};

Menu.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export { Menu };
