import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import './AccountMenuItem.sass';

const AccountMenuButton = ({
  children,
  className,
  icon,
  ...props
}) => {
  return (
    <button
      className={cn(
        className,
        'InteractionWrapper',
        'Body-2',
        {
          AccountMenuItem: true,
          AccountMenuItem_button: true,
          AccountMenuItem_noIcon: !icon,
        },
      )}
      type="button"
      {...props}
    >
      {icon && (
        <span className="AccountMenuItem__Icon">
          {icon}
        </span>
      )}

      {children}
      <div className="InteractionOverlay" />
    </button>
  );
};

AccountMenuButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  icon: PropTypes.node,
};

AccountMenuButton.defaultProps = {
  children: undefined,
  className: undefined,
  icon: undefined,
};

export { AccountMenuButton };
