import { DEFAULT_HEALTHCHECK_TIMEOUT } from '../config';
import { loadProgressBar } from '@vlabs/axios-progress-bar';
import Axios from 'axios';
import formatISO from 'date-fns/formatISO';
import qs from 'qs';

import { fromApi } from './mappers/notifierFromApi';
import { toApi } from './mappers/notifierToApi';

import '@vlabs/axios-progress-bar/dist/nprogress.css';

export class NotifierClient {
  constructor() {
    this.baseURL = '/api/vlnotifier';
    this.http = Axios.create({
      baseURL: this.baseURL,
      withCredentials: true,
      paramsSerializer: (params) => (
        qs.stringify(params, {
          arrayFormat: 'comma',
          serializeDate: formatISO,
        })),
    });
    loadProgressBar('', this.http);
  }

  healthcheck() {
    return this.http.get('/version', {
      timeout: DEFAULT_HEALTHCHECK_TIMEOUT,
    }).catch((e) => {
      if (e?.code === 'ECONNABORTED') throw e;
      if (e?.response?.status >= 500) throw e;
    });
  }

  async version() {
    const { data } = await this.http.get('/version');
    return data;
  }

  get filters() {
    const prefix = 'filters';
    return {
      create: (params) => this.http.post(`/${prefix}`, { ...toApi({ ...params }) }),
      getAll: (params) => this.http.get(`/${prefix}`, { params }),
      get: async (filterId) => {
        const { data } = await this.http.get(`/${prefix}/${filterId}`);
        return { data: fromApi(data) };
      },
      replace: (filterId, params) => this.http.put(`/${prefix}/${filterId}`, { ...toApi({ ...params }) }),
      delete: (filterId) => this.http.delete(`/${prefix}/${filterId}`),
    };
  }
}
