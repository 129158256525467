import React from 'react';

import validate from '@vlabs/shared/validators';
import { Control, Fold, SettingsItemWrapper } from '@vlabs/uikit';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

function HealthcheckPolicySubForm() {
  const { t } = useTranslation();
  const { register, formState: { errors } } = useFormContext();
  return (
    <Fold isOpen title={t('lunaStreamsStreams:форма.healthcheck policy.заголовок')}>
      <SettingsItemWrapper title={t('lunaStreamsStreams:форма.healthcheck policy.max_error_count')}>
        <Control.Input
          {...register('policies.healthcheck.max_error_count', {
            min: validate.gte(0),
            max: validate.lte(2147483647),
            validate: validate.integer(),
          })}
          errors={errors}
        />
      </SettingsItemWrapper>
      <SettingsItemWrapper title={t('lunaStreamsStreams:форма.healthcheck policy.period')}>
        <Control.Input
          {...register('policies.healthcheck.period', {
            min: validate.gte(0),
            max: validate.lte(2147483647),
            validate: validate.integer(),
          })}
          errors={errors}
        />
      </SettingsItemWrapper>
      <SettingsItemWrapper title={t('lunaStreamsStreams:форма.healthcheck policy.retry_delay')}>
        <Control.Input
          {...register('policies.healthcheck.retry_delay', {
            min: validate.gte(0),
            max: validate.lte(2147483647),
            validate: validate.integer(),
          })}
          errors={errors}
        />
      </SettingsItemWrapper>
      <SettingsItemWrapper title={t('lunaStreamsStreams:форма.healthcheck policy.timeout')}>
        <Control.Input
          {...register('policies.healthcheck.timeout', {
            min: validate.gte(0),
            max: validate.lte(2147483647),
            validate: validate.integer(),
          })}
          errors={errors}
        />
      </SettingsItemWrapper>
    </Fold>
  );
}

HealthcheckPolicySubForm.propTypes = {

};

export default HealthcheckPolicySubForm;
