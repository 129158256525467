import React, { memo } from 'react';

import { IconInner } from '../../IconInner';

const ChevronUpIconInner = (props) => (
  <IconInner name="ChevronUpIcon" viewBox="0 0 24 24" {...props}>
    <path d="m18 15-6-6-6 6" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
  </IconInner>
);

export const ChevronUpIcon = memo(ChevronUpIconInner);
