import React, { memo } from 'react';

import { IconInner } from '../../IconInner';

const LockedIconInner = (props) => (
  <IconInner name="LockedIcon" viewBox="0 0 24 24" {...props}>
    <path d="M19 11H5a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7a2 2 0 0 0-2-2ZM7 11V7a5 5 0 1 1 10 0v4" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
  </IconInner>
);

export const LockedIcon = memo(LockedIconInner);
