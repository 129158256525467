import { useCallback, useMemo } from 'react';

const actions = {
  init: 'init',
  resetPage: 'resetPage',
  gotoPage: 'gotoPage',
  setPageSize: 'setPageSize',
  setFilter: 'setFilter',
};

function reducer(state, action) {
  if (action.type === actions.init) {
    return {
      pageSize: 25,
      since: undefined,
      until: undefined,
      ...state,
    };
  }

  if (action.type === actions.setPageSize) {
    const { pageSize } = action;

    return {
      ...state,
      pageSize,
      pageSince: undefined,
      pageUntil: undefined,
    };
  }

  if (action.type === actions.gotoPage) {
    return {
      ...state,
      pageSince: action.since,
      pageUntil: action.until,
    };
  }

  return undefined;
}

function useInstance(instance) {
  const {
    rows,
    pageCount,
    sinceAvailable,
    untilAvailable,
    state: {
      pageSize,
      pageIndex,
      pageSince,
      pageUntil,
    },
    dispatch,
  } = instance;

  const pageOptions = useMemo(
    () => {
      if (pageCount <= 0) return [];

      return [...new Array(pageCount)].fill(null).map((d, i) => i);
    },
    [pageCount],
  );

  const page = useMemo(() => rows, [
    pageIndex,
    pageSize,
    rows,
  ]);

  const setPageSize = useCallback(
    (newPageSize) => {
      dispatch({ type: actions.setPageSize, pageSize: newPageSize });
    },
    [dispatch],
  );

  const gotoPage = useCallback((args) => {
    dispatch({ type: actions.gotoPage, ...args });
  }, []);

  const canPreviousPage = sinceAvailable === undefined
    ? ((pageSince || pageUntil) && page.length !== 0)
    : sinceAvailable === true;

  const canFirstPage = !!(pageSince || pageUntil);

  const canNextPage = untilAvailable === undefined
    ? page.length >= pageSize
    : untilAvailable === true;

  const firstPage = useCallback(() => gotoPage(), [gotoPage]);

  const previousPage = useCallback(() => {
    if (page.length > 0) {
      gotoPage({ until: page[0].original });
    }
  }, [gotoPage, page]);

  const nextPage = useCallback(
    () => {
      if (page.length > 0) {
        gotoPage({ since: page[page.length - 1].original });
      }
    },
    [gotoPage, page],
  );

  Object.assign(instance, {
    pageOptions,
    pageCount,
    page,
    gotoPage,
    canFirstPage,
    canPreviousPage,
    canNextPage,
    firstPage,
    previousPage,
    nextPage,
    setPageSize,
  });
}

export const useTimePagination = (hooks) => {
  hooks.stateReducers.push(reducer);
  hooks.useInstance.push(useInstance);
};

useTimePagination.pluginName = 'usePagination';
