import { LunaError } from '@vlabs/api-bindings/src/luna-client/LunaError';
import { showAPIErrorToast, showErrorToast, showLunaErrorToast } from '@vlabs/shared/components/error-toasts';
import axios from 'axios';

function handleError(error) {
  console.error(error);
  if (error instanceof LunaError) {
    showLunaErrorToast(error);
  } else if (axios.isAxiosError(error)) {
    showAPIErrorToast(error);
  } else if (error instanceof Error) {
    showErrorToast(error);
  }
}

export const registerErrorHandlers = () => {
  window.addEventListener('error', (event) => {
    handleError(event.reason);
    event.preventDefault();
  });

  window.addEventListener('unhandledrejection', (event) => {
    handleError(event.reason);
    event.preventDefault();
  });
};
