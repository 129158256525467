import React, { useState, useEffect } from 'react';

import { actionColumnProps } from '@vlabs/shared/utils';
import { Page, Panel, Table, TableCells, Modal, openConfirmPopup } from '@vlabs/uikit';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { selectHandlerOptionsForReports } from '@vlabs/pages/handlers/selectors';

import CreateReportForm from './CreateReportForm';
import { DownloadCell } from './DownloadCell';
import ProgressCell from './ProgressCell';
import { selectSlice, selectOptions } from './selectors';
import * as reportsStore from './store';

function ReportListPageComponent({
  reports,
  fetchReports,
  deleteReport,
  createReport,
  setPageIndex,
  setPageSize,
  fetchPlansOptions,
  fetchLunaSourcesOptions,
  options,
  handlerOptions,
}) {
  const { t } = useTranslation();

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  useEffect(() => {
    fetchReports();
    fetchPlansOptions();
    fetchLunaSourcesOptions();
  }, [fetchReports, fetchLunaSourcesOptions, fetchPlansOptions]);

  const onFormSubmit = (newValues) => {
    createReport(newValues);
    setModalIsOpen(false);
  };

  const $deleteReport = ({ id }) => {
    const message = t('reports:подтверждение.удаление.message');

    const onConfirm = async () => {
      deleteReport(id);
    };

    openConfirmPopup({
      title: t('reports:подтверждение.удаление.title'),
      message,
      type: 'delete',
      onConfirm,
    });
  };

  return (
    <Page>
      <Modal
        appElement={document.getElementById('root')}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
      >
        <CreateReportForm
          handlerOptions={handlerOptions}
          lunaSourcesOptions={options.lunaSourcesOptions}
          onSubmit={onFormSubmit}
          planOptions={options.planOptions}
        />
      </Modal>
      <Panel>
        <Table
          columns={[
            { Header: t('reports:table.columns.date'), accessor: 'created_at', Cell: TableCells.DateCell },
            { Header: t('reports:table.columns.author'), accessor: 'author' },
            {
              Header: t('reports:table.columns.description'),
              accessor: 'description',
            },
            {
              Header: t('reports:table.columns.processed events'),
              accessor: 'processed_events',
            },
            {
              Header: t('reports:table.columns.progress'),
              accessor: 'progress',
            },
            {
              Header: t('reports:table.columns.status'),
              accessor: 'status',
              Cell: ({ value }) => ProgressCell(value),
            },
            actionColumnProps({
              id: 'download',
              type: 'action',
              accessor: ({ image_url: url, description }) => ({ url, description }),
              Cell: DownloadCell,
              width: 30,
            }),
            actionColumnProps({
              id: 'action3',
              type: 'action',
              Cell: TableCells.DeleteCell,
              width: 30,
            }),
          ]}
          data={reports.pageData}
          onActions={{
            onAddRow: { handler: openModal },
            onDeleteRow: { handler: $deleteReport },
          }}
          pageIndex={reports.pageIndex}
          pageSize={reports.pageSize}
          pageSizeOptions={reports.pageSizeOptions}
          paginationType="offsetBased"
          setPageIndex={setPageIndex}
          setPageSize={setPageSize}
        />

      </Panel>
    </Page>
  );
}

export const ReportListPage = connect(
  (state) => ({
    reports: selectSlice(state),
    options: selectOptions(state),
    handlerOptions: selectHandlerOptionsForReports(state),
  }),
  (dispatch) => ({
    fetchReports: () => dispatch(reportsStore.fetchReports),
    fetchPlansOptions: () => dispatch(reportsStore.fetchPlansOptions),
    fetchLunaSourcesOptions: () => dispatch(reportsStore.fetchLunaSourcesOptions),
    deleteReport: (id) => dispatch(reportsStore.deleteReport(id)),
    createReport: (params) => dispatch(reportsStore.createReport(params)),
    setPageSize: (pageSize) => dispatch(reportsStore.setPageSize({ pageSize })),
    setPageIndex: (pageIndex) => dispatch(reportsStore.setPage({ pageIndex })),
  }),
)(ReportListPageComponent);
