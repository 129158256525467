import React, { useEffect, useCallback, useState, useMemo } from 'react';

import { apiContainer } from '@vlabs/api-bindings';
import { CircleInfoIcon } from '@vlabs/icons';
import { SimilarityCell } from '@vlabs/pages/events/event-page/cells';
import { EventActions } from '@vlabs/shared/components/action-button-groups/EventActions';
import { SampleWithActions } from '@vlabs/shared/components/sample/SampleWithActions';
import { GoToCell } from '@vlabs/shared/components/table';
import { EventInfoCell } from '@vlabs/shared/legacy-components/event-info-cell/EventInfoCell';
import { routes } from '@vlabs/shared/navigation/routes';
import { Table, Control } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';

import { updateFilters } from '@vlabs/pages/events/events/store';

import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_INDEX, PAGE_SIZE_FOR_REQUEST } from '../constants';
import { trimByPage } from '../utils';
import st from './tabs.module.sass';

const EventSampleCell = React.memo(({ value }) => (
  <SampleWithActions
    actions={(
      <EventActions
        fullFrameURL={value?.detectionWithUiFullFrame?.uiFullFrame}
        rect={value?.detectionWithUiFullFrame?.detection?.rect}
        sampleURL={value?.uiFaceDetection?.uiSampleURL}
        track={value?.uiFaceTrack?.map((i) => value?.face_detections[i])}
      />
    )}
    className={st.Image}
    data-testid="sampleImage"
    imageUrl={value?.uiFaceDetection?.uiSampleURL}
    type="face"
  />
),
);

const InfoCell = React.memo(({ row }) => (
  <>
    <div className={st.SimilarityCell}>
      <SimilarityCell value={row.original?.top_match?.similarity} />
    </div>
    <EventInfoCell
      className={st.RowInfoWidth}
      {...row}
    />
  </>
),
);

const $LastEventsByFaceIdTab = ({ setEventsFilters, faceId }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [events, setEvents] = useState({ data: null, count: null, eventsByPage: null });

  const buildEventLink = (result) => (result.event_id ? `/events/${result.event_id}` : undefined);

  const fetchEvents = useCallback(async () => {
    try {
      const eventsByFaceId = await apiContainer.lunaClient.events.getAll({
        top_similar_object_ids: faceId,
        page: DEFAULT_PAGE_INDEX + 1,
        page_size: PAGE_SIZE_FOR_REQUEST,
      });
      setEvents({
        data: eventsByFaceId,
        count: eventsByFaceId?.length,
        eventsByPage: trimByPage(DEFAULT_PAGE_INDEX, eventsByFaceId) });
    } catch ({ error }) {
      throw error;
    }
  }, [faceId]);

  useEffect(() => { fetchEvents(); }, [fetchEvents]);

  const onChangePageIndex = useCallback((pageIndex) => {
    setEvents((prevState) => ({
      ...prevState,
      eventsByPage: trimByPage(pageIndex, prevState.data),
    }));
  }, []);

  const columns = useMemo(() => [
    {
      id: 1,
      accessor: (value) => value,
      Cell: EventSampleCell,
      width: 70,
    },
    {
      id: 2,
      accessor: (value) => value,
      Cell: InfoCell,
      width: 170,
    },
    {
      id: 3,
      Cell: GoToCell,
      accessor: buildEventLink,
      width: 10,
    },
  ], []);

  return (
    <div className={st.TablePanel}>
      {events?.count === 0 && (
        <div className={st.InfoRoot}>
          <CircleInfoIcon className="StaticIcon" />
          <div className={st.Title}>{t('faces:события с данным лицом отсутствуют')}</div>
        </div>
      )}

      {events?.count > 0 && (
        <>
          <Table
            columns={columns}
            data={events?.eventsByPage}
            data-testid="eventTableTab"
            meta={{ count: events.count }}
            noHead
            pageIndex={DEFAULT_PAGE_INDEX}
            pageSize={DEFAULT_PAGE_SIZE}
            pageSizeOptions={[DEFAULT_PAGE_SIZE]}
            paginationType="offsetBased"
            setPageIndex={onChangePageIndex}
          />

          {events?.count > 1 && (
            <div className={st.GoTo}>
              <Control.Button
                data-testid="eventTableButton"
                onClick={() => {
                  setEventsFilters({ top_similar_object_ids: faceId });
                  history.push(generatePath(routes.events.archive));
                }}
                variant="outlined"
              >
                {t('faces:посмотреть все события с данным лицом')}
              </Control.Button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

$LastEventsByFaceIdTab.propTypes = {
  faceId: PropTypes.string,
  events: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.any),
    pageIndex: PropTypes.number,
    count: PropTypes.number,
  }),
  setEventsFilters: PropTypes.func,
};

$LastEventsByFaceIdTab.defaultProps = {
  faceId: undefined,
  events: {},
  setEventsFilters: undefined,
};

const mapDispatchToProps = (dispatch) => ({
  setEventsFilters: (filters) => dispatch(updateFilters({ filters })),
});

export const LastEventsByFaceIdTab = connect(null, mapDispatchToProps)($LastEventsByFaceIdTab);
