import React, { useState } from 'react';

import { DeleteIcon, EditIcon, PlusIcon } from '@vlabs/icons';
import { openConfirmPopup, Control, Margin, Modal, SettingsItemWrapper } from '@vlabs/uikit';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import TagForm from './TagForm';

const TagPolicySubForm = () => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const { fields, remove, update, append } = useFieldArray({ control, name: 'tagPolicy' });
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState();
  const tagPolicyList = useWatch({ name: 'tagPolicy' });
  const tagPolicyTags = tagPolicyList.map(({ tag }) => tag);

  const closeModal = () => {
    setSelectedIndex(undefined);
    setModalIsOpen(false);
  };

  const onOpenModal = (index) => {
    setSelectedIndex(index);
    setModalIsOpen(true);
  };

  const onSubmit = (value) => {
    if (selectedIndex !== undefined) {
      update(selectedIndex, value);
    } else {
      append(value);
    }
    closeModal();
  };

  const removeAfterConfirmation = (i, name) => {
    // TODO: возможно сделать асбтракцию над confirmation попапами,
    //  потому что конструирование каждый раз тега и
    //  формулирование вопроса вручную это не оч хорошо
    const message = (
      <p>
        {t('Вы уверены, что хотите удалить тег')}
        &nbsp;
        {name}
        ?
      </p>
    );

    openConfirmPopup({
      title: t('handlers:удаление тега'),
      message,
      type: 'delete',
      onConfirm: () => remove(i),
    });
  };

  return (
    <>
      <Modal
        appElement={document.getElementById('root')}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
      >
        <TagForm
          initialValues={tagPolicyList[selectedIndex]}
          isSelectedTag={selectedIndex !== undefined}
          onSubmit={onSubmit}
        />
      </Modal>

      {tagPolicyList && fields.map((field, i) => (
        <SettingsItemWrapper
          key={field.id}
          title={tagPolicyTags[i]}
        >
          <div className="MatchPolicy_Margin">
            <Control.RoundButton
              data-testid="tagPolicy.editIcon"
              icon={<EditIcon />}
              kind="primary"
              onClick={() => onOpenModal(i)}
              title={t('кнопка.редактировать')}
              variant="dimmed"
            />
            <Margin left />
            <Control.RoundButton
              data-testid="tagPolicy.deleteIcon"
              icon={<DeleteIcon />}
              kind="negative"
              onClick={() => removeAfterConfirmation(i, tagPolicyTags[i])}
              title={t('кнопка.удалить')}
              variant="dimmed"
            />
          </div>
        </SettingsItemWrapper>
      ))}
      <SettingsItemWrapper>
        <div className="MatchPolicy_Margin">
          <Control.RoundButton
            data-testid="tagPolicy.plusIcon"
            icon={<PlusIcon />}
            kind="primary"
            onClick={() => onOpenModal()}
            title={t('кнопка.добавить')}
            variant="dimmed"
          />
        </div>
      </SettingsItemWrapper>
    </>
  );
};

export default TagPolicySubForm;
