import React from 'react';

import { Table } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

// FIXME: проверить необходимость компонента PreviewCell, похожий есть в luna-streams
import PreviewCell from './PreviewCell';
import { selectPageData, selectPageParameters } from './selectors';
import * as store from './store';

const SourceListTable = ({
  pageData,
  pageProps,
  setPageIndex,
  setPageSize,
}) => {
  const { t } = useTranslation();
  return (
    <div>
      <Table
        {...pageData}
        {...pageProps}
        columns={[
          {
            Header: t('sources:preview'),
            accessor: 'previewUri',
            Cell: ({ row: { original } }) => PreviewCell({ getLastFrameURL: () => undefined, original }),
          },
          { Header: t('название'), accessor: 'name' },
        ]}
        paginationType="offsetBased"
        setPageIndex={setPageIndex}
        setPageSize={setPageSize}
      />
    </div>
  );
};

SourceListTable.propTypes = {
  pageData: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.object),
    meta: PropTypes.shape({
      count: PropTypes.number,
    }),
  }),
  pageProps: PropTypes.shape({
    pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
    pageSize: PropTypes.number,
    pageIndex: PropTypes.number,
  }),
  setPageIndex: PropTypes.func,
  setPageSize: PropTypes.func,
};
SourceListTable.defaultProps = {
  pageData: undefined,
  pageProps: undefined,
  setPageSize: undefined,
  setPageIndex: undefined,
};

export default connect((state) => ({
  pageData: selectPageData(state),
  pageProps: selectPageParameters(state),
}), (dispatch) => ({
  setPageIndex: (pageIndex) => dispatch(store.actions.setPage(pageIndex)),
  setPageSize: (pageSize) => dispatch(store.actions.setPageSize(pageSize)),
}))(SourceListTable);
