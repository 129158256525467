import React from 'react';

import { GENDER_OPTIONS, AGE_OPTIONS } from '@vlabs/api-bindings/src/constants';
import { REPORT_TYPE } from '@vlabs/api-bindings/src/va-reporter-client/constants';
import { START_OF_DAY, END_OF_DAY } from '@vlabs/shared/config';
import {
  Page, Control, Divider, Grid, GridRow, GridCol,
} from '@vlabs/uikit';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import st from './CreateReportForm.module.sass';

function CreateReportForm({
  onSubmit,
  planOptions,
  lunaSourcesOptions,
  handlerOptions,
}) {
  const { t } = useTranslation();

  const {
    handleSubmit, control, register, formState: { errors },
  } = useForm();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Page
        className={st.Root}
        title={t('reports:форма.заголовок')}
      >
        <Divider small />

        <label htmlFor="description">
          {t('reports:форма.description.label')}
          <Control.Input
            {...register('description', {
              required: t('валидация.обязательно для заполнения'),
              minLength: { value: 5, message: t('валидация.число.больше или равно', { value: 5 }) },
              maxLength: { value: 120, message: t('валидация.число.меньше или равно', { value: 120 }) },
            })}
            errors={errors}
          />
        </label>
        <Divider small />

        <Grid>
          <GridRow>
            <GridCol>
              <label htmlFor="type">
                {t('reports:форма.type.label')}
                <Control.Select
                  control={control}
                  errors={errors}
                  id="type"
                  isClearable
                  name="type"
                  options={REPORT_TYPE.raw}
                  rules={{ required: t('валидация.обязательно для заполнения') }}
                />
              </label>
              <Divider small />

              <label htmlFor="plan">
                {t('reports:форма.plan.label')}
                <Control.Select
                  control={control}
                  errors={errors}
                  id="plan"
                  isClearable
                  name="plan"
                  options={planOptions}
                  rules={{ required: t('валидация.обязательно для заполнения') }}
                />
              </label>
              <Divider small />

              <label htmlFor="role">
                {t('reports:форма.filters.create_time__gte.label')}
                <Control.DateInput
                  control={control}
                  dateFormat="d.m.Y H:i"
                  enableTime
                  name="filters.create_time__gte"
                  options={START_OF_DAY}
                  time_24hr
                />
              </label>
              <Divider small />

              <label htmlFor="role">
                {t('reports:форма.filters.create_time__lt.label')}
                <Control.DateInput
                  control={control}
                  dateFormat="d.m.Y H:i"
                  enableTime
                  name="filters.create_time__lt"
                  options={END_OF_DAY}
                  time_24hr
                />
              </label>

            </GridCol>

            <GridCol>

              <label htmlFor="sources">
                {t('reports:форма.sources.label')}
                <Control.Select
                  control={control}
                  id="sources"
                  isClearable
                  isMulti
                  name="filters.sources"
                  options={lunaSourcesOptions}
                />
              </label>
              <Divider small />

              <label htmlFor="gender">
                {t('reports:форма.gender.label')}
                <Control.Select
                  control={control}
                  id="gender"
                  isClearable
                  name="filters.gender"
                  options={GENDER_OPTIONS.raw}
                />
              </label>
              <Divider small />

              <label htmlFor="age">
                {t('reports:форма.age.label')}
                <Control.Select
                  control={control}
                  id="age"
                  isClearable
                  name="filters.age"
                  options={AGE_OPTIONS.raw}
                />
              </label>
              <Divider small />

              <label htmlFor="tags">
                {t('reports:форма.tags.label')}
                <Control.Select
                  control={control}
                  id="tags"
                  isClearable
                  isCreatable
                  isMulti
                  name="filters.tags"
                  options={handlerOptions}
                />
              </label>
            </GridCol>
          </GridRow>
        </Grid>

        <Divider small />

        <Control.Button fullWidth type="submit">
          {t('форма.submit')}
        </Control.Button>

      </Page>
    </form>
  );
}

export default CreateReportForm;
