import parseISO from 'date-fns/parseISO';

const DateCell = ({ value }) => {
  if (!value) return '';
  if (value instanceof Date) return value.toLocaleString();

  try {
    return parseISO(value).toLocaleString();
  } catch (error) {
    return '';
  }
};

export { DateCell };
