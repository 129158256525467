import { createSlice } from '@reduxjs/toolkit';
import { apiContainer } from '@vlabs/api-bindings';
import i18n from 'app/translations/i18n';
import { toast } from 'react-toastify';

import { SLICE_NAME } from '.';
import { fetchStreams } from '../streams/store';

const initialState = {
  pageData: {
    data: [],
    meta: {
      count: 0,
    },
  },
  groups: [],
  groupOptions: [],
  byId: {},
  pageIndex: 0,
  pageSize: 25,
  pageSizeOptions: [10, 25, 50],
  filters: {},
  state: 'notAsked',
};

const store = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setPageData(state, { payload }) {
      state.pageData = payload;
      state.byId = {};
      (payload.data || []).forEach((group) => {
        state.byId[group.group_id] = group;
      });
      state.state = 'loaded';
    },
    setGroups(state, { payload }) {
      state.data = payload;
      state.byId = {};
      (payload.data || []).forEach((group) => {
        state.byId[group.group_id] = group;
      });
      state.groupOptions = payload.map(({ group_name, group_id, account_id }) => ({
        label: group_name,
        value: group_name,
        groupId: group_id,
        accountId: account_id,
      }));
      state.state = 'loaded';
    },
    setGroupOptions(state, { payload }) {
      state.groupOptions = payload;
    },
    setPageIndex(state, { payload }) {
      state.pageIndex = payload;
    },
    setPageSize(state, { payload }) {
      state.pageSize = payload;
    },
  },
});

export default store.reducer;

export const fetchGroups = async (dispatch) => {
  try {
    const groups = await apiContainer.lunaStreamsClient.groups.getAll();

    dispatch(store.actions.setGroups(groups));
  } catch (error) {
    if (error.response.status === 404) {
      console.error('Группы отсутсвуют в плагинах');
      return;
    }

    throw error;
  }
};

export const fetchPageData = async (dispatch, getState) => {
  const { [SLICE_NAME]: { pageSize, pageIndex, filters } } = getState();
  dispatch(fetchStreams);
  try {
    const pageData = await apiContainer.lunaStreamsClient.groups.getPage({
      page: pageIndex + 1,
      page_size: pageSize,
      ...filters,
    }, { withCount: true });
    let streams = await apiContainer.lunaStreamsClient.streams.getAll();
    streams = streams.reduce((acc, stream) => {
      stream.groups.forEach(({ value: group_name }) => {
        if (acc[group_name] === undefined) acc[group_name] = [];
        acc[group_name].push(stream);
      });
      return acc;
    }, {});
    pageData.data.forEach((group) => { group.streams = streams[group.group_name] || []; });
    dispatch(store.actions.setPageData(pageData));
  } catch (error) {
    dispatch(store.actions.setPageData([]));
    throw error;
  }
};
export const setPageIndex = (pageIndex) => (dispatch) => {
  dispatch(store.actions.setPageIndex(pageIndex));
  dispatch(fetchPageData);
};
export const setPageSize = (pageSize) => (dispatch) => {
  dispatch(store.actions.setPageSize(pageSize));
  dispatch(fetchPageData);
};

export const createGroup = (params) => async (dispatch) => {
  try {
    const { group_name, group_id } = await apiContainer.lunaStreamsClient.groups.create(params);
    dispatch(fetchPageData);
    toast.success(i18n.t('lunaStreamsGroups:api.создание.успех', { group: { ...params, group_name, group_id } }));
    return group_id;
  } catch (error) {
    if (error.response.status === 409) {
      const toastMessage = i18n.t('lunaStreamsGroups:api.создание.валидация.неуникальный account_id', { group: { ...params } });
      toast.warning(toastMessage);
      return undefined;
    }
    throw error;
  }
};

export const updateGroup = (group_id, params) => async (dispatch) => {
  await apiContainer.lunaStreamsClient.groups.update(group_id, params);
  dispatch(fetchPageData);
  toast.success(i18n.t('lunaStreamsGroups:обновление.успех', { group: { ...params, group_id } }));
};

export const deleteGroup = (group_id) => async (dispatch, getState) => {
  const { [SLICE_NAME]: { byId: groupById } } = getState();
  const group_name = groupById[group_id]?.group_name;
  await apiContainer.lunaStreamsClient.groups.delete(group_id);
  dispatch(fetchPageData);
  toast.success(i18n.t('lunaStreamsGroups:удаление.успех', { group: { group_name, group_id } }));
};
