import { get, CleanSchema } from '../../../json-transform';

export const pointMappingSchema = new CleanSchema({
  source_x: get('source_x').asFloat().ifNaN(undefined),
  source_y: get('source_y').asFloat().ifNaN(undefined),
  plan_x: get('plan_x').asFloat().ifNaN(undefined),
  plan_y: get('plan_y').asFloat().ifNaN(undefined),
});

export const putCalibration = new CleanSchema({
  point_mappings: get('point_mappings').asArrayOf((v) => pointMappingSchema(v)),
  plan_id: get('plan_id').asString(),
  source_id: get('source_id'),
  source_name: get('source_name').asOptionValue(),
  active_time__gte: get('active_time__gte').ifNull(undefined),
  active_time__lt: get('active_time__lt').ifNull(undefined),
});
