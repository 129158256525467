import { createSelector } from '@reduxjs/toolkit';

import { SLICE_NAME } from '.';

export const selectSlice = ({ [SLICE_NAME]: streams }) => streams;
export const selectStreams = createSelector(
  selectSlice,
  ({ streams }) => streams,
);
export const selectState = createSelector(
  selectSlice,
  ({ state }) => state,
);
export const selectPageData = createSelector(
  selectSlice,
  (slice) => slice.pageData,
);
export const selectPageParameters = createSelector(
  selectSlice,
  (slice) => ({
    pageSizeOptions: slice.pageSizeOptions,
    pageSize: slice.pageSize,
    pageIndex: slice.pageIndex,
  }),
);
export const selectStreamOptions = createSelector(
  selectSlice,
  (slice) => (slice ? slice.streamOptions : []),
);
export const selectStreamsByGroupName = createSelector(
  selectStreams,
  (_, group_name) => group_name,
  (streams, group_name) => streams.filter(({ groups }) => groups.map(({ value }) => value).includes(group_name)),
);
export const selectStreamOptionsByGroupName = createSelector(
  selectStreamOptions,
  (_, group_name) => group_name,
  (streamOptions, group_name) => streamOptions.filter(({ groups }) => groups.includes(group_name)),
);
