export class LunaError extends Error {
  constructor(error) {
    if (!error.response) throw error;

    const {
      desc,
      error_code,
      detail,
      link,
    } = error.response.data;

    super(desc);

    this.name = 'LunaError';
    this.error_code = error_code;
    this.desc = desc;
    this.detail = detail;
    this.link = link;
    this.status = error.response.status;
    this.error = error;
  }
}
