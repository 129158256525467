import React from 'react';

import { TASKS } from '@vlabs/api-bindings/src/luna-client/constants';
import validate from '@vlabs/shared/validators';
import { Control, GridCol, GridRow, SettingsItemWrapper } from '@vlabs/uikit';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

function S3() {
  const { t } = useTranslation();
  const { register, formState: { errors }, control, watch } = useFormContext();

  const publicAccessKey = watch('content.source.s3.authorization.public_access_key');
  const secretAccessKey = watch('content.source.s3.authorization.secret_access_key');

  return (
    <>
      <GridRow>
        <GridCol>
          <Control.Input
            id="content.source.s3.reference.endpoint"
            label={t('tasks:lp.estimator task.fields.content.source.s3.reference.endpoint.label')}
            placeholder={t('tasks:lp.estimator task.fields.content.source.s3.reference.endpoint.placeholder')}
            {...register('content.source.s3.reference.endpoint', {
              validate: {
                uri: validate.uri(),
              },
            })}
            errors={errors}
          />
        </GridCol>
      </GridRow>

      <GridRow>
        <GridCol>
          <Control.Input
            id="content.source.s3.reference.bucket_name"
            label={t('tasks:lp.estimator task.fields.content.source.s3.reference.bucket_name.label')}
            placeholder={t('tasks:lp.estimator task.fields.content.source.s3.reference.bucket_name.placeholder')}
            {...register('content.source.s3.reference.bucket_name', {
              required: validate.required(),
            })}
            errors={errors}
          />
        </GridCol>
      </GridRow>

      <GridRow>
        <GridCol>
          <Control.Input
            id="content.source.s3.reference.prefix"
            label={t('tasks:lp.estimator task.fields.content.source.s3.reference.prefix.label')}
            placeholder={t('tasks:lp.estimator task.fields.content.source.s3.reference.prefix.placeholder')}
            {...register('content.source.s3.reference.prefix')}
            errors={errors}
          />
        </GridCol>
        <GridCol>
          <Control.Input
            id="content.source.s3.reference.region"
            label={t('tasks:lp.estimator task.fields.content.source.s3.reference.region.label')}
            placeholder={t('tasks:lp.estimator task.fields.content.source.s3.reference.region.placeholder')}
            {...register('content.source.s3.reference.region')}
            errors={errors}
          />
        </GridCol>
      </GridRow>

      <GridRow>
        <GridCol>
          <Control.Input
            id="content.source.s3.authorization.public_access_key"
            label={t('tasks:lp.estimator task.fields.content.source.s3.authorization.public_access_key.label')}
            placeholder={t('tasks:lp.estimator task.fields.content.source.s3.authorization.public_access_key.placeholder')}
            {...register('content.source.s3.authorization.public_access_key', {
              validate: {
                secretKeyIsNotEmpty: (v) => (secretAccessKey && !v ? validate.required() : undefined),
              },
            })}
            errors={errors}
          />
        </GridCol>
        <GridCol>
          <Control.Input
            id="content.source.s3.authorization.secret_access_key"
            label={t('tasks:lp.estimator task.fields.content.source.s3.authorization.secret_access_key.label')}
            placeholder={t('tasks:lp.estimator task.fields.content.source.s3.authorization.secret_access_key.placeholder')}
            {...register('content.source.s3.authorization.secret_access_key', {
              validate: {
                publicKeyIsNotEmpty: (v) => (publicAccessKey && !v ? validate.required() : undefined),
              },
            })}
            errors={errors}
          />
        </GridCol>
        <GridCol>
          <Control.Select
            control={control}
            errors={errors}
            id="content.source.s3.authorization.signature_version"
            label={t('tasks:lp.estimator task.fields.content.source.s3.authorization.signature_version.label')}
            name="content.source.s3.authorization.signature_version"
            options={TASKS.ESTIMATOR.SOURCE.SIGNATURE_VERSION.raw}
          />
        </GridCol>
      </GridRow>

      <GridRow>
        <GridCol>
          <SettingsItemWrapper title={t('tasks:lp.estimator task.fields.content.source.s3.recursive.label')}>
            <Control.Switch
              control={control}
              name="content.source.s3.recursive"
            />
          </SettingsItemWrapper>
        </GridCol>
      </GridRow>

      <GridRow>
        <GridCol>
          <SettingsItemWrapper title={t('tasks:lp.estimator task.fields.content.source.s3.save_origin.label')}>
            <Control.Switch
              control={control}
              name="content.source.s3.save_origin"
            />
          </SettingsItemWrapper>
        </GridCol>
      </GridRow>

      <GridRow>
        <GridCol>
          <Control.Select
            control={control}
            errors={errors}
            id="content.source.s3.image_type"
            label={t('tasks:lp.estimator task.fields.content.source.s3.image_type.label')}
            name="content.source.s3.image_type"
            options={TASKS.ESTIMATOR.SOURCE.IMAGE_TYPE.raw}
          />
        </GridCol>
      </GridRow>
    </>
  );
}

export { S3 };
