import React, { forwardRef, useEffect, useState } from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';
import nextId from 'react-id-generator';

import './Radio.sass';
import { ErrorMessage } from '../error-message/ErrorMessage';
import { getError } from '../utils';

const Radio = forwardRef(({
  id: idProp,
  label,
  name,
  onChange,
  onChangeValue,
  errors,
  hasError,
  errorMessage,
  className,
  ...inputProps
}, externalRef) => {
  const [id, setId] = useState(undefined);

  const error = getError(errors, name);

  // Уникальный id приходится генерировать вручную,
  // так как у группы радио-кнопок будет один id на всех
  useEffect(() => {
    setId(idProp || nextId());
  }, [idProp]);

  const $onChange = (e) => {
    e.stopPropagation();
    if (onChange) onChange(e);
    if (onChangeValue) onChangeValue(e.target.value);
  };

  return (
    <>
      <div className={cn(
        className,
        {
          Radio: true,
          Radio_error: hasError || error,
        },
      )}
      >
        <input
          className="Radio__Input"
          id={id}
          name={name}
          onChange={$onChange}
          ref={externalRef}
          type="radio"
          {...inputProps}
        />

        <label
          className="Input__Label"
          htmlFor={id}
        >
          {label}
        </label>
      </div>

      <ErrorMessage error={errorMessage ? { message: errorMessage } : error} />
    </>
  );
});

Radio.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onChangeValue: PropTypes.func,
  errors: PropTypes.object,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
};

Radio.defaultProps = {
  id: undefined,
  label: null,
  name: undefined,
  className: undefined,
  onChange: undefined,
  onChangeValue: undefined,
  errors: undefined,
  hasError: undefined,
  errorMessage: undefined,
};

Radio.displayName = 'Radio';

export { Radio };
