import React, { useState, useEffect, useCallback, useMemo } from 'react';

import { apiContainer } from '@vlabs/api-bindings';
import { Page, Panel, ImagesPointsDetector, Divider, Control } from '@vlabs/uikit';
import { isEqual, map } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { selectPlan } from '../plans/selectors';
import * as plansStore from '../plans/store';
import { selectCurrentSource } from './selectors';
import { createBreadcrumbs } from './sourcesHelpers';
import * as sourcesStore from './store';

import './CreateSourcePage.sass';

const mapPointsToApi = (points, i) => map(points, i).filter((v) => v).map((point) => ([point.x, point.y]));

const ValidationPageComponent = ({
  fetchData,
  validatePoint,
  breadcrumbs,
  sourceImageURL,
  planeImageURL,
}) => {
  const { t } = useTranslation();
  const [validationPoints, setValidationPoints] = useState([]);
  const images = useMemo(() => ([
    { source: sourceImageURL, isZoomable: true },
    { source: planeImageURL, isZoomable: true },
  ]), [sourceImageURL, planeImageURL]);

  useEffect(() => { fetchData(); }, [fetchData]);

  const validate = useCallback(async (values) => {
    if ((values?.length ?? 0) === 0) {
      setValidationPoints([]);
      return;
    }
    const newSourcePoints = mapPointsToApi(values, 0);
    const newPlanPoints = mapPointsToApi(values, 1);

    if (newSourcePoints?.length > newPlanPoints?.length) {
      const resp = await validatePoint({ source: newSourcePoints });
      setValidationPoints(resp.plan.map((p, i) => [resp.source[i], p]));
      return;
    }
    if (newPlanPoints?.length > newSourcePoints?.length) {
      const resp = await validatePoint({ plan: newPlanPoints });
      setValidationPoints(resp.source.map((p, i) => [p, resp.plan[i]]));
      return;
    }

    const oldSourcePoints = mapPointsToApi(validationPoints, 0);
    const oldPlanPoints = mapPointsToApi(validationPoints, 1);
    if (!isEqual(newSourcePoints, oldSourcePoints)) {
      const resp = await validatePoint({ source: newSourcePoints });
      setValidationPoints(resp.plan.map((p, i) => [resp.source[i], p]));
      return;
    }
    if (!isEqual(newPlanPoints, oldPlanPoints)) {
      const resp = await validatePoint({ plan: newPlanPoints });
      setValidationPoints(resp.source.map((p, i) => [p, resp.plan[i]]));
    }
  }, [validationPoints, validatePoint]);

  const actions = (
    <Control.Button
      disabled={validationPoints?.length === 0}
      kind="negative"
      onClick={() => setValidationPoints([])}
    >
      {t('button.reset')}
    </Control.Button>
  );

  if (!images[0].source || !images[1].source) return null;

  return (
    <Page
      actions={actions}
      breadcrumbs={breadcrumbs}
    >
      <Divider small />
      <Panel>
        <div className="ImagesPointsDetector_Wrapper">
          <ImagesPointsDetector
            images={images}
            isRow
            onChange={validate}
            points={validationPoints}
          />
        </div>
      </Panel>
    </Page>
  );
};

ValidationPageComponent.propTypes = {
  fetchData: PropTypes.func.isRequired,
  validatePoint: PropTypes.func.isRequired,
  breadcrumbs: PropTypes.arrayOf(PropTypes.shape({})),
  sourceImageURL: PropTypes.string,
  planeImageURL: PropTypes.string,
};

ValidationPageComponent.defaultProps = {
  sourceImageURL: undefined,
  planeImageURL: undefined,
  breadcrumbs: [],
};

export const ValidationPage = connect((state, ownProps) => ({
  sourceImageURL: selectCurrentSource(state)?.source_image_url,
  planeImageURL: selectPlan(state)?.plan_image_url,
  breadcrumbs: createBreadcrumbs({
    route: ownProps.match,
    plan: selectPlan(state),
    sourceName: selectCurrentSource(state)?.source_name?.value,
  }),
}),
(dispatch, ownProps) => ({
  fetchData: () => {
    dispatch(sourcesStore.fetchSource(ownProps.match.params.calibrationId));
    dispatch(plansStore.fetchPlan(ownProps.match.params.planId));
  },
  validatePoint: async (params) => apiContainer.vaReporterClient.sources.transform(
    ownProps.match.params.calibrationId, params,
  ),
}))(ValidationPageComponent);
