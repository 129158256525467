import React from 'react';

import { Control, SettingsItemWrapper } from '@vlabs/uikit';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { BasicAttributesToggle } from './BasicAttributesToggle';
import { EstimateFaceToggle } from './EstimateFaceToggle';
import { ExtractFaceDescriptorToggle } from './ExtractFaceDescriptorToggle';

const EstimationsSubForm = () => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const estimateFace = useWatch({ name: 'estimate.face' });

  return (
    <>
      <SettingsItemWrapper title={t('handlers:policies.detect_policy.detect_face')}>
        <EstimateFaceToggle />
      </SettingsItemWrapper>

      <SettingsItemWrapper
        disabled={!estimateFace}
        title={t('handlers:policies.extract_policy.extract_face_descriptor')}
      >
        <ExtractFaceDescriptorToggle />
      </SettingsItemWrapper>

      <SettingsItemWrapper
        disabled={!estimateFace}
        title={t('handlers:policies.extract_policy.extract_basic_attributes')}
      >
        <BasicAttributesToggle />
      </SettingsItemWrapper>

      <SettingsItemWrapper
        data-tooltip-content={t('handlers:policies.detect_policy.estimate_head_pose.tooltip')}
        title={t('handlers:policies.detect_policy.estimate_head_pose.label')}
      >
        <Control.Switch
          control={control}
          name="estimate.headPose"
        />
      </SettingsItemWrapper>

      <SettingsItemWrapper title={t('handlers:policies.detect_policy.estimate_emotions')}>
        <Control.Switch
          control={control}
          name="estimate.emotion"
        />
      </SettingsItemWrapper>

      <SettingsItemWrapper title={t('handlers:policies.detect_policy.estimate_mask')}>
        <Control.Switch
          control={control}
          name="estimate.mask"
        />
      </SettingsItemWrapper>

      <SettingsItemWrapper title={t('handlers:policies.detect_policy.estimate_quality')}>
        <Control.Switch
          control={control}
          name="estimate.quality"
        />
      </SettingsItemWrapper>

      <SettingsItemWrapper
        data-tooltip-content={t('handlers:policies.detect_policy.estimate_gaze.tooltip')}
        title={t('handlers:policies.detect_policy.estimate_gaze.label')}
      >
        <Control.Switch
          control={control}
          name="estimate.gaze"
        />
      </SettingsItemWrapper>

      <SettingsItemWrapper title={t('handlers:policies.detect_policy.estimate_glasses')}>
        <Control.Switch
          control={control}
          name="estimate.glasses"
        />
      </SettingsItemWrapper>

      <SettingsItemWrapper title={t('handlers:policies.detect_policy.estimate_eyes_attributes')}>
        <Control.Switch
          control={control}
          name="estimate.eyes"
        />
      </SettingsItemWrapper>

      <SettingsItemWrapper
        title={t('handlers:policies.detect_policy.estimate_mouth_attributes')}
      >
        <Control.Switch
          control={control}
          name="estimate.mouth"
        />
      </SettingsItemWrapper>

      <SettingsItemWrapper
        data-tooltip-content={t('handlers:policies.detect_policy.detect_landmarks68.tooltip')}
        title={t('handlers:policies.detect_policy.detect_landmarks68.label')}
      >
        <Control.Switch
          control={control}
          name="estimate.landmarks68"
        />
      </SettingsItemWrapper>

      <SettingsItemWrapper title={t('handlers:policies.detect_policy.extract_exif')}>
        <Control.Switch
          control={control}
          name="extract.exif"
        />
      </SettingsItemWrapper>
    </>
  );
};

export default EstimationsSubForm;
