import React, { useCallback } from 'react';

import validate from '@vlabs/shared/validators';
import { Control, Fold, SettingsItemWrapper } from '@vlabs/uikit';
import { useFormContext, useWatch, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useClearPolicyFilters } from '../hooks';
import GenericPolicySubForm from './GenericPolicySubForm';

const AttributesPolicySubform = () => {
  const { t } = useTranslation();
  const { control, register } = useFormContext();
  const { errors } = useFormState();
  const attributesPolicyIsEnabled = useWatch({ name: 'attributesPolicy.isEnabled' });

  useClearPolicyFilters('attributesPolicy', {
    on: useCallback(() => attributesPolicyIsEnabled === false, [attributesPolicyIsEnabled]),
  });

  return (
    <Fold
      control={control}
      isOpen={attributesPolicyIsEnabled}
      name="attributesPolicy.isEnabled"
      switch
      testId="attributesPolicy"
      title={t('handlers:setup sections.save face attributes in database')}
    >
      <GenericPolicySubForm
        fieldName="attributesPolicy.filters"
        matchTitle={t('handlers:policies.storage_policy.attribute_policy.filters.match.title')}
      />
      <SettingsItemWrapper
        contentClassName="MatchPolicy_Input"
        data-tooltip-content={t('handlers:policies.storage_policy.attribute_policy.ttl.dataTip')}
        title={t('handlers:policies.storage_policy.attribute_policy.ttl.title')}
      >
        <Control.Input
          {...register('attributesPolicy.ttl', {
            min: validate.gte(1),
            max: validate.lte(86400),
            validate: validate.integer(),
          })}
          errors={errors}
        />
      </SettingsItemWrapper>
    </Fold>
  );
};

export default AttributesPolicySubform;
