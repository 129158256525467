import React from 'react';

import { CircleCrossIcon, PersonPlusIcon } from '@vlabs/icons';
import { SearchLink } from '@vlabs/pages/events/event-page/components';
import { EventActions } from '@vlabs/shared/components/action-button-groups/EventActions';
import { EmptyImage } from '@vlabs/shared/components/empty-image';
import { SampleWithActions } from '@vlabs/shared/components/sample/SampleWithActions';
import { TabsWithPermissions } from '@vlabs/shared/components/tabs-with-permissions/TabsWithPermissions';
import { permissions } from '@vlabs/shared/config';
import { AdditionalPropertiesTab } from '@vlabs/shared/legacy-components/tabs/AdditionalPropertiesTab';
import { AttributesTab } from '@vlabs/shared/legacy-components/tabs/AttributesTab';
import { BodyAttributesTab } from '@vlabs/shared/legacy-components/tabs/BodyAttributesTab';
import { Tab, Panel, Divider, useModal, ButtonScrimItem } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { CreateFaceFromDetectionForm } from './CreateFaceFromDetectionForm';
import st from './DetectionCard.module.sass';

export const DetectionCard = ({ event }) => {
  const { t } = useTranslation();
  const createFaceModal = useModal();

  return (
    <Panel className={st.Root}>
      {createFaceModal.wrap(<CreateFaceFromDetectionForm
        afterSubmit={createFaceModal.close}
        detection={event.uiFaceDetection}
      />)}

      <div className={st.TitleBlock}>
        <h6>{t('events:event card.title')}</h6>
        <SearchLink event={event} />
      </div>

      <Divider small />

      <div className={st.Content}>
        <div>
          {event.face_detections && (
            <SampleWithActions
              actions={(
                <EventActions
                  extraActions={(
                    <ButtonScrimItem
                      data-testid="eventActions.createFaceButton"
                      icon={<PersonPlusIcon />}
                      onClick={createFaceModal.open}
                      permissions={{ rules: [permissions.face.creation] }}
                      round
                      title={t('events:action.create face')}
                      variant="dimmed"
                    />
                  )}
                  fullFrameURL={event?.detectionWithUiFullFrame?.uiFullFrame}
                  rect={event?.detectionWithUiFullFrame?.detection?.rect}
                  sampleURL={event?.uiFaceDetection?.uiSampleURL}
                  track={event?.uiFaceTrack?.map((i) => event?.face_detections[i])}
                />
              )}
              className={st.FaceSample__Size_large}
              data-testid="sampleImage"
              imageUrl={event?.uiFaceDetection?.uiSampleURL}
              type="face"
            />
          )}
          {event.body_detections && (
            <SampleWithActions
              actions={(
                <EventActions
                  fullFrameURL={event?.detectionWithUiFullFrame?.uiFullFrame}
                  rect={event?.detectionWithUiFullFrame?.detection?.rect}
                  sampleURL={event.uiBodyDetection?.uiSampleURL}
                  // FIXME: это заглушка, в массиве uiBodyTrack всегда один элемент
                  // мы никогда не показываем треки для тел, потому что просмотр трека подразумевает больше чем один элемент
                  track={event?.uiBodyTrack}
                />
              )}
              className={st.BodySample__Size_large}
              data-testid="sampleImage"
              imageUrl={event?.uiBodyDetection?.uiSampleURL}
              type="body"
            />
          )}

          {!event.face_detections && !event.body_detections && (
            <EmptyImage
              className={st.Empty_Image}
              icon={<CircleCrossIcon />}
              messageText={t('объект не обнаружен')}
            />
          )}
        </div>

        <div>
          <TabsWithPermissions testId="detectionInfoTabs">
            <Tab header={t('атрибуты')} headerClassName={st.TabHeader} name="detectionAttributes">
              {event.face_detections && <AttributesTab eventId={event?.event_id} />}
              {event.body_detections && <BodyAttributesTab event={event} />}
            </Tab>
            {event?.uiFaceDetection?.sample_id && (
              <Tab
                header={t('дополнительные свойства')}
                headerClassName={st.TabHeader}
                name="detectionAdditionalProperties"
                permissions={{ rules: [permissions.resources.sdk] }}
              >
                <AdditionalPropertiesTab sampleId={event?.uiFaceDetection?.sample_id} />
              </Tab>
            )}
          </TabsWithPermissions>
        </div>
      </div>
    </Panel>
  );
};

DetectionCard.propTypes = {
  event: PropTypes.objectOf(PropTypes.any),
  can: PropTypes.func.isRequired,
};

DetectionCard.defaultProps = {
  event: {},
};
