import React, { useEffect } from 'react';

import { EVENT_OPTIONS, AGE_OPTIONS, DESCRIPTOR_TYPE_OPTIONS, GENDER_OPTIONS_STRICT, DEEPFAKE_STATE_OPTIONS_STRICT } from '@vlabs/api-bindings/src/constants';
import { LIVENESS_OPTIONS_STRICT } from '@vlabs/api-bindings/src/luna-client/constants';
import SearchByEvents from '@vlabs/shared/legacy-components/search-by/SearchByEvents';
import SearchByFaces from '@vlabs/shared/legacy-components/search-by/SearchByFaces';
import { selectIsAppFeatureAvailable } from '@vlabs/shared/selectors/appSelectors';
import validate from '@vlabs/shared/validators';
import { Page, Control, Divider, Grid, GridRow, GridCol } from '@vlabs/uikit';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useForm, FormProvider, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import st from './MatchPolicyForm.module.sass';

function MatchPolicyForm({
  onSubmit,
  isSelectedMatch,
  handlerOptions,
  listOptions,
  extract,
  matchPolicyList,
  initialValues,
  isBodyLicenseAvailable,
}) {
  const { t } = useTranslation();

  const isFaceDescriptorExtract = useWatch({ name: 'extract.faceDescriptor' });
  const isBodyDescriptorExtract = useWatch({ name: 'extract.bodyDescriptor' });
  const livenessIsEnabled = useWatch({ name: 'estimate_liveness.estimate' });
  const deepfakeIsEnabled = useWatch({ name: 'estimate_deepfake.estimate' });

  const formMethods = useForm({
    defaultValues: {
      ...initialValues,
      origin: initialValues.origin || EVENT_OPTIONS.faces,
      descriptorType: initialValues.descriptorType || DESCRIPTOR_TYPE_OPTIONS.face,
    },
  });

  const labelValidation = (value) => {
    const isLabelExist = matchPolicyList?.find(
      ({ label }) => label === value && label !== initialValues.label,
    );

    if (!isLabelExist) {
      return true;
    }

    return t('Параметр сравнения с таким названием уже существует в рамках данного сценария');
  };

  const {
    watch,
    control,
    handleSubmit: originalHandlerSubmit,
    register,
    setValue,
    formState: {
      errors,
    },
  } = formMethods;

  const faceSearchType = watch('origin')?.value === 'faces';
  const eventSearchType = watch('origin')?.value === 'events';
  const bodyDescriptorType = watch('descriptorType').value === 'body';
  const faceDescriptorType = watch('descriptorType').value === 'face';

  // Предотвращает сабмит основной формы
  const handleSubmit = (func) => (e) => {
    if (e?.preventDefault) e.preventDefault();
    if (e?.stopPropagation) e.stopPropagation();
    originalHandlerSubmit(func)(e);
  };

  useEffect(() => {
    const sub = watch((data, { name }) => {
      if (name === 'origin' && data.origin.value === 'faces') {
        setValue('descriptorType', DESCRIPTOR_TYPE_OPTIONS.face);
      }
    });
    return () => sub.unsubscribe();
  }, [watch, setValue]);

  const context = { handlerOptions, listOptions, isBodyLicenseAvailable, ...formMethods };

  const commonFilters = (
    <>
      <div>
        <Divider small />
        <div className={st.Subtitle}>
          {t('handlers:отфильтровать результат поиска по')}
        </div>
        <Divider small />
        <div className={st.BasicAttributesFilter}>
          <div className={st.Width_2}>
            <Control.Select
              control={control}
              disabled={!extract?.basicAttributes}
              isClearable
              name="filters.gender"
              options={GENDER_OPTIONS_STRICT.raw}
              placeholder={t('gender')}
            />
          </div>
          <div className={st.Width_2}>
            <Control.Select
              control={control}
              disabled={!extract?.basicAttributes}
              isClearable
              name="filters.age"
              options={AGE_OPTIONS.raw}
              placeholder={t('age')}
            />
          </div>

          <div className={st.Width_2}>
            <Control.Select
              control={control}
              disabled={!livenessIsEnabled}
              isMulti
              name="filters.liveness"
              options={LIVENESS_OPTIONS_STRICT.raw}
              placeholder={t('Liveness')}
            />
          </div>
          <div className={st.Width_2}>
            <Control.Select
              control={control}
              disabled={!deepfakeIsEnabled}
              isMulti
              name="filters.deepfake"
              options={DEEPFAKE_STATE_OPTIONS_STRICT.raw}
              placeholder={t('Deepfake')}
            />
          </div>
        </div>
      </div>
      <Divider small />
      <div>
        <div className={st.Subtitle}>
          {t('handlers:дополнительные параметры поиска')}
        </div>
        <Divider small />
        <Grid>
          <GridRow>
            <GridCol>
              <Control.Input
                label={t('handlers:максимальное количество похожих в результатах поиска')}
                {...register('limit', {
                  min: validate.gte(1),
                  max: validate.lte(100),
                  validate: {
                    isInteger: (v) => Number.isInteger(Number(v)) || t('валидация.число.целое'),
                  },
                })}
                errors={errors}
                placeholder={t('не задано')}
              />
            </GridCol>
            <GridCol>
              <Control.Input
                label={t('handlers:пороговое значение точности')}
                {...register('threshold', {
                  min: validate.gte(0),
                  max: validate.lte(1),
                  validate: validate.number(),
                })}
                errors={errors}
                placeholder={t('не задано')}
              />
            </GridCol>
          </GridRow>
        </Grid>
      </div>
    </>
  );

  return (
    <FormProvider {...context}>
      <Page
        className={st.Page}
        title={isSelectedMatch
          ? t('handlers:изменить параметр сравнения')
          : t('handlers:добавить новый параметр сравнения')}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={cn(st.BlocksWrapper, st.BlocksWrapper_flex)}>
            <div className={st.Subtitle}>
              {t('handlers:policies.match_policy.label')}
            </div>
            <div className={st.Width_1}>
              <Control.Input
                errors={errors}
                {...register('label', {
                  required: validate.required(),
                  validate: labelValidation,
                  maxLength: validate.maxLength(36),
                })}
              />
            </div>
          </div>

          <div className={cn(st.BlocksWrapper, st.BlocksWrapper_flex)}>
            <div className={st.Subtitle}>
              {t('handlers:идентифицировать среди')}
            </div>
            <div className={st.Width_1}>
              <Control.Select
                control={control}
                name="origin"
                options={EVENT_OPTIONS.raw}
              />
            </div>
          </div>
          <div className={cn(st.BlocksWrapper, st.BlocksWrapper_flex)}>
            <div className={st.Subtitle}>
              {t('handlers:policies.match_policy.search for biometric template')}
            </div>
            <div className={st.Width_1}>
              <Control.Select
                control={control}
                disabled={faceSearchType}
                name="descriptorType"
                options={DESCRIPTOR_TYPE_OPTIONS.raw}
              />
            </div>
          </div>

          <div className={st.BlocksWrapper}>
            <div className={st.Subtitle}>
              {t('handlers:выполнять поиск по')}
            </div>
            <Divider small />
            {isFaceDescriptorExtract && faceSearchType && (
              <>
                <SearchByFaces />
                {commonFilters}
              </>
            )}
            {eventSearchType && isFaceDescriptorExtract && faceDescriptorType && (
              <>
                <SearchByEvents />
                {commonFilters}
              </>
            )}

            {eventSearchType && isBodyDescriptorExtract && bodyDescriptorType && (
              <>
                <SearchByEvents />
                {commonFilters}
              </>
            )}

            {!isFaceDescriptorExtract && faceDescriptorType && (
              <div>{t('handlers:policies.match.enable biometric face template extraction')}</div>
            )}
            {!isBodyDescriptorExtract && bodyDescriptorType && (
              <div>{t('handlers:policies.match.enable biometric body template extraction')}</div>
            )}
          </div>

          <Divider small />
          <Control.Button
            disabled={
              (!isBodyDescriptorExtract && bodyDescriptorType)
              || (!isFaceDescriptorExtract && faceDescriptorType)
            }
            fullWidth
            type="submit"
          >
            {isSelectedMatch ? t('кнопка.изменить') : t('кнопка.добавить')}
          </Control.Button>
        </form>
      </Page>
    </FormProvider>
  );
}

const optionPropType = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.any,
});

MatchPolicyForm.propTypes = {
  isBodyLicenseAvailable: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  isSelectedMatch: PropTypes.bool,
  handlerOptions: PropTypes.arrayOf(optionPropType),
  listOptions: PropTypes.arrayOf(optionPropType),
  extract: PropTypes.shape({
    basicAttributes: PropTypes.bool,
  }),
  matchPolicyList: PropTypes.arrayOf(optionPropType),
  initialValues: PropTypes.shape({
    label: PropTypes.string,
    origin: optionPropType,
    descriptorType: optionPropType,
  }),
};
MatchPolicyForm.defaultProps = {
  isBodyLicenseAvailable: false,
  isSelectedMatch: false,
  handlerOptions: [],
  listOptions: [],
  matchPolicyList: [],
  extract: {},
  initialValues: {},
};

export default connect((state) => ({
  isBodyLicenseAvailable: selectIsAppFeatureAvailable(state, 'estimations.body_attributes'),
}))(MatchPolicyForm);
