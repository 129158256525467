import React, { useEffect, useRef } from 'react';

import { apiContainer } from '@vlabs/api-bindings';
import { ButtonWithPermissions } from '@vlabs/shared/components/button-with-permissions/ButtonWithPermissions';
import { permissions } from '@vlabs/shared/config';
import { useSteppedForm } from '@vlabs/shared/hooks';
import { useModal, Stepper, Page } from '@vlabs/uikit';
import cn from 'classnames';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import { selectAccountEmail, selectAccountLogin } from '@vlabs/pages/auth/selectors';
import { selectDynamicHandlerId } from '@vlabs/pages/handlers/selectors';

import { showResourceIntensiveTaskPopup } from '../showResourceIntensiveTaskPopup';
import { StepCallbacksPolicy } from '../steps/StepCallbacksPolicy';
import { getDefaultPackageIdentificationValues } from './defaultValues';
import { StepPackageIdentification } from './StepPackageIdentification';
import '../styles/shared.styles.sass';

// Разделение на два компонента необходимо чтобы избежать смешивания логики и
// чтобы избежать ошибок с активным шагом, который не сбрасывается при закрытии модального окна

const StepperModalComponent = ({ email, dynamicHandlerId, onSubmit }) => {
  const { t } = useTranslation();
  const defaultValues = getDefaultPackageIdentificationValues('faces', email, dynamicHandlerId);
  const form = useForm({ defaultValues, mode: 'onChange' });
  const prevForm = useRef(defaultValues);
  const steps = [StepPackageIdentification, StepCallbacksPolicy];

  useEffect(() => {
    prevForm.current = defaultValues;
    form.reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.reset, prevForm]);
  const stepper = useSteppedForm({ form, steps, onSubmit });

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Page className={cn('TaskStepperModal__Page', 'PackageIdentificationForm')} title={t('tasks:lp.packageIdentification.title')}>
          <Stepper {...stepper} className={cn('TaskStepperModal__StepperContent')}>
            {steps.map((Step) => <Step key={Step.name} />)}
          </Stepper>
        </Page>
      </form>
    </FormProvider>
  );
};

const StepperModal = connect((state) => ({
  email: selectAccountLogin(state) ?? selectAccountEmail(state),
  dynamicHandlerId: selectDynamicHandlerId(state),
}))(StepperModalComponent);

export const PackageIdentificationWidget = ({ afterSubmit }) => {
  const { t } = useTranslation();
  const modal = useModal();
  const packageIdentificationTask = async (values) => {
    const formValues = values;
    const { data } = await apiContainer.lunaClient.objects.create(formValues.file[0]);
    formValues.content.source.zip = { reference: data.external_url };

    formValues.content.source.source_type = { value: 'zip' };
    await apiContainer.lunaClient.tasks.estimator(formValues);
    toast.info(i18next.t('tasks:подтверждение.пакетная идентификация.успех'));
  };

  const submitForm = (values) => {
    const onConfirm = async () => {
      await packageIdentificationTask(values);
      modal.close();
      if (afterSubmit) afterSubmit();
    };
    showResourceIntensiveTaskPopup(onConfirm);
  };
  return (
    <>
      {modal.wrap(<StepperModal onSubmit={submitForm} />)}
      <ButtonWithPermissions
        data-testid="packageIdentificationButton"
        onClick={modal.open}
        permissions={{ rules: [permissions.task.creation] }}
      >
        {t('tasks:пакетная идентификация')}
      </ButtonWithPermissions>
    </>
  );
};

PackageIdentificationWidget.propTypes = {
  afterSubmit: PropTypes.func,
};

PackageIdentificationWidget.defaultProps = {
  afterSubmit: undefined,
};
