import React from 'react';

import { CircleInfoIcon } from '@vlabs/icons';
import { useTranslation } from 'react-i18next';

import st from './shared.module.sass';

export const TextFileRenderer = ({ text }) => {
  const { t } = useTranslation();
  const defaultText = t('uikit:control.fileDropzone.ошибки.формат файла не поддерживается браузером');
  return (
    <div className={st.InfoPanel}>
      <CircleInfoIcon className={st.Icon} />
      {text || defaultText}
    </div>
  );
};
