import { createSlice } from '@reduxjs/toolkit';
import { apiContainer } from '@vlabs/api-bindings';
import { STREAM_STATUS_OPTIONS } from '@vlabs/api-bindings/src/luna-streams/constants';
import i18n from 'app/translations/i18n';
import { toast } from 'react-toastify';

import { SLICE_NAME } from '.';
import { selectGroupOptions } from '../groups/selectors';

const initialState = {
  pageData: {
    data: [],
    meta: {
      count: 0,
    },
  },
  streams: [],
  streamOptions: [],
  byId: {},
  filters: [],
  pageIndex: 0,
  pageSize: 25,
  pageSizeOptions: [10, 25, 50, 100],
  state: 'notAsked',
};

const store = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setPageData(state, { payload }) {
      state.pageData = payload;
    },
    setStreams(state, { payload }) {
      state.streams = payload;
      state.byId = {};
      (payload || []).forEach((stream) => {
        state.byId[stream.stream_id] = stream;
      });

      state.streamOptions = payload.map(({ stream_id, name, preview }) => ({
        streamId: stream_id,
        preview,
        value: name,
        label: name,
      }));
      state.state = 'loaded';
    },
    setFilters(state, { payload: { filters } = {} }) {
      if (filters !== undefined) state.filters = filters;
    },
    setPageIndex(state, { payload }) {
      if (payload !== undefined) state.pageIndex = payload;
    },
    setPageSize(state, { payload }) {
      if (payload !== undefined) state.pageSize = payload;
    },
  },
});

export default store.reducer;

export const fetchStreams = async (dispatch, getState) => {
  const state = getState();
  const params = {};
  if (state.account) {
    params.account_id = state.account.currentDomainToken?.account_id;
    params.account_id = params.account_id || state.account.model?.account_id;
  } try {
    const streams = await apiContainer.lunaStreamsClient.streams.getAll(params);
    dispatch(store.actions.setStreams(streams));
  } catch (error) {
    dispatch(store.actions.setStreams([]));
    throw error;
  }
};

export const fetchPageData = async (dispatch, getState) => {
  const state = getState();
  const { [SLICE_NAME]: { pageIndex, pageSize, filters } } = state;

  const localFilters = [];
  filters.forEach(({ id, value }) => {
    if (typeof value === 'object') {
      localFilters[id] = value.value;
    } else {
      localFilters[id] = value;
    }
  });

  const params = {
    page: pageIndex + 1,
    page_size: pageSize,
    order: 'desc',
    ...localFilters,
  };
  if (state.account) {
    params.account_id = state.account.currentDomainToken?.account_id;
    params.account_id = params.account_id || state.account.model?.account_id;
  }
  try {
    const pageData = await apiContainer.lunaStreamsClient.streams.getPage(params);
    dispatch(store.actions.setPageData(pageData));
  } catch (error) {
    dispatch(store.actions.setPageData([]));
    // error_code === 12012 это ошибка падает если ввели некорректные данные для квери запроса, например, невалидный stream_ids
    // FIXME: Изменить обработку фильтрации после апдйта кита и удалить эту проверку. Ожидается, что добавив валидацию мы не будем попадать в этот блок
    if (error.error_code === 12012) {
      return;
    }
    throw error;
  }
};

export const startStream = (streamId) => async (dispatch) => {
  await apiContainer.lunaStreamsClient.streams.update(streamId, {
    status: STREAM_STATUS_OPTIONS.pending.value,
  });
  dispatch(fetchPageData);
};
export const pauseStream = (streamId) => async (dispatch) => {
  await apiContainer.lunaStreamsClient.streams.update(streamId, {
    status: STREAM_STATUS_OPTIONS.pause.value,
  });
  dispatch(fetchPageData);
};
export const stopStream = (streamId) => async (dispatch) => {
  await apiContainer.lunaStreamsClient.streams.update(streamId, {
    status: STREAM_STATUS_OPTIONS.cancel.value,
  });
  dispatch(fetchPageData);
};

export const setFilters = (params) => async (dispatch) => {
  dispatch(store.actions.setFilters(params));
  dispatch(fetchPageData);
};

export const setPageSize = (params) => async (dispatch) => {
  dispatch(store.actions.setPageSize(params));
  dispatch(fetchPageData);
};
export const setPageIndex = (params) => async (dispatch) => {
  dispatch(store.actions.setPageIndex(params));
  dispatch(fetchPageData);
};

const processGroup = async ({ groups, account_id, group_name }) => {
  if (group_name === undefined) return;

  const isGroupExists = groups
    .find(({ value: existedGroupName }) => existedGroupName === group_name);
  if (!isGroupExists) {
    await apiContainer.lunaStreamsClient.groups.create({ account_id, group_name });
  }
};

export const createStream = (params) => async (_, getState) => {
  processGroup({
    groups: selectGroupOptions(getState()),
    account_id: params.account_id,
    group_name: params?.group_name?.value,
  });

  const { stream_id } = await apiContainer.lunaStreamsClient.streams.create(params);
  toast.success(i18n.t('lunaStreamsStreams:создание.успех', { stream: { ...params, stream_id } }));
  return stream_id;
};

export const updateStream = (stream_id, params) => async (_, getState) => {
  processGroup({
    groups: selectGroupOptions(getState()),
    account_id: params.account_id,
    group_name: params.group_name?.value,
  });

  await apiContainer.lunaStreamsClient.streams.replace(stream_id, params);
  toast.success(i18n.t('lunaStreamsStreams:обновление.успех', { stream: { ...params, stream_id } }));
};

export const deleteStream = (stream_id) => async (dispatch) => {
  await apiContainer.lunaStreamsClient.streams.delete(stream_id);
  dispatch(fetchPageData);
  toast.success(i18n.t('lunaStreamsStreams:удаление.успех', { stream: { stream_id } }));
};

export const init = (dispatch) => {
  dispatch(fetchStreams);
};
