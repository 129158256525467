import React from 'react';

import { Control, SettingsItemWrapper } from '@vlabs/uikit';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const StoragePolicySubForm = () => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  return (
    <>
      <SettingsItemWrapper
        title={t('verifiers:form.faceSamplePolicy.label')}
      >
        <Control.Switch
          control={control}
          name="faceSamplePolicy.isEnabled"
        />
      </SettingsItemWrapper>

      <SettingsItemWrapper
        title={t('verifiers:form.attributesPolicy.label')}
      >
        <Control.Switch
          control={control}
          name="attributesPolicy.isEnabled"
        />
      </SettingsItemWrapper>
    </>
  );
};

export default StoragePolicySubForm;
