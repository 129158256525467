import React from 'react';

import PropTypes from 'prop-types';

import '../FormPage.sass';
import { Brand } from '../../components/brand/Brand';
import { Forms } from '../../components/forms';
import { Panel } from '../../components/panel/Panel';

const ResetPasswordPage = ({
  onSubmit,
}) => {
  return (
    <div className="FormPage">
      <div className="FormPage__Content">
        <Brand className="FormPage__Logo" />

        <Panel className="FormPage__Panel">
          <Forms.ResetPasswordForm onSubmit={onSubmit} />
        </Panel>
      </div>
    </div>
  );
};

ResetPasswordPage.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export { ResetPasswordPage };
