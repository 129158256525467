/* eslint-disable react/prop-types */
import React from 'react';

import { calcSimilarityPercent, calcBadgeType } from '@vlabs/shared/utils';
import { Badge } from '@vlabs/uikit';

export const SimilarityCell = ({ value }) => {
  return (
    <Badge
      kind={calcBadgeType(value)}
      value={calcSimilarityPercent(value)}
    />
  );
};
