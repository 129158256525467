import React, { forwardRef, useEffect, useRef, useState } from 'react';

import { CrossIcon, PlusIcon, UploadCloudIcon, FileIcon } from '@vlabs/icons';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { findParentNode } from '../../utils/findParentNode';
import { mergeRefs } from '../../utils/mergeRefs';
import { RoundButton } from '../button/RoundButton';
import { ErrorMessage } from '../error-message/ErrorMessage';
import { fixDragEnterLeaveEvents, getError } from '../utils';

import './FileInput.sass';

const readAsUrl = (image) => new Promise((resolve) => {
  const reader = new FileReader();
  reader.onloadend = () => resolve(reader.result);
  reader.readAsDataURL(image);
});

const STATES = {
  empty: 'empty',
  selected: 'selected',
  uploading: 'uploading',
  uploaded: 'uploaded',
};

// TODO: при очистке формы через встроенный метод reset это нельзя отследить
//   внутри компонента, поэтому визуально он не меняется. Единственный вариант
//   это отслеживать событие reset на форме

const FileInput = forwardRef(({
  id,
  label,
  name,
  errors,
  hasError,
  errorMessage,
  className,
  onChange,
  onChangeValue,
  onUpload,
  uploadFunction: uploadFile,
  forceUpload,
  value,
  disabled,
  ...inputProps
}, externalRef) => {
  const { t } = useTranslation();
  const [state, setState] = useState(STATES.empty);
  const [file, setFile] = useState('');
  const [preview, setPreview] = useState(undefined);
  const mainWrapperRef = useRef();
  const dragNDropCurtainRef = useRef();

  const internalRef = useRef();

  const error = getError(errors, name);

  const clear = () => {
    setFile('');
    setState(STATES.empty);
    setPreview(undefined);
    if (internalRef.current) internalRef.current.value = '';
  };

  const $uploadFile = async (fileForUpload) => {
    if (!uploadFile) { return; }

    setState(STATES.uploading);

    await uploadFile(fileForUpload);
    setState(STATES.uploaded);
    if (onUpload) onUpload();
  };

  const onUploadButtonClick = (e) => {
    e.preventDefault();
    $uploadFile(internalRef.current.files[0]);
  };

  const onClearButtonClick = (e) => {
    e.preventDefault();
    clear();
    internalRef.current.dispatchEvent(new Event('change', { bubbles: true }));
    if (onChangeValue) onChangeValue('');
  };

  const $onChange = (event) => {
    event.stopPropagation();
    const { target: { files } } = event;

    setPreview(undefined);

    if (!files.length) {
      if (onChange) onChange(event);
      if (onChangeValue) onChangeValue('');
      return;
    }

    setState(STATES.selected);

    const newFile = files[0];

    setFile(newFile);

    if (newFile.type.startsWith('image/')) {
      readAsUrl(newFile).then(setPreview);
    }

    if (forceUpload) $uploadFile(newFile);
    if (onChange) onChange(event);
    if (onChangeValue) onChangeValue(newFile);
  };

  useEffect(() => {
    if (!value) {
      clear();
    }
  }, [value]);

  const openFileDialog = (clickEvent) => {
    if (file || disabled || !internalRef.current) {
      return;
    }

    // Если компонент обернут в <label> с пустым, или совпадающим for, то
    // клик и так сработает, поэтому обрываем чтобы
    // окно выбора файла не открывалось дважды
    const parentLabel = findParentNode(clickEvent.target, 'LABEL');
    if (
      parentLabel
      && (!parentLabel.htmlFor || parentLabel.htmlFor === internalRef.current.id)
    ) return;

    internalRef.current.click();
  };

  //
  // Drag'n'Drop
  //

  const startWaitingForDrop = () => {
    if (disabled) return;

    if (dragNDropCurtainRef.current) dragNDropCurtainRef.current.classList.remove('hidden');
  };

  const stopWaitingForDrop = () => {
    if (dragNDropCurtainRef.current) dragNDropCurtainRef.current.classList.add('hidden');
  };

  const {
    onEnter: onDragEnterGlobal,
    onLeave: onDragLeaveGlobal,
    resetCounter: resetGlobalDragNDropCounter,
  } = fixDragEnterLeaveEvents({
    onEnter: startWaitingForDrop,
    onLeave: stopWaitingForDrop,
  });

  const onDragOver = (event) => {
    event.preventDefault();
  };

  const curtainHighlightedClass = 'FileInput__DragNDropCurtain_highlighted';
  const $onDragEnter = () => {
    if (dragNDropCurtainRef.current) {
      dragNDropCurtainRef.current.classList.add(curtainHighlightedClass);
    }
  };

  const $onDragLeave = () => {
    if (dragNDropCurtainRef.current) {
      dragNDropCurtainRef.current.classList.remove(curtainHighlightedClass);
    }
  };

  const {
    onEnter: onDragEnter,
    onLeave: onDragLeave,
    resetCounter: resetDragNDropCounter,
  } = fixDragEnterLeaveEvents({
    onEnter: $onDragEnter,
    onLeave: $onDragLeave,
  });

  const onDrop = (event) => {
    if (disabled || !internalRef.current) return;

    event.preventDefault();

    internalRef.current.files = event.dataTransfer.files;
    internalRef.current.dispatchEvent(new Event('change', { bubbles: true }));

    resetDragNDropCounter();
    resetGlobalDragNDropCounter();
    stopWaitingForDrop();
  };

  useEffect(() => {
    document.body.addEventListener('dragenter', onDragEnterGlobal, true);
    document.body.addEventListener('dragleave', onDragLeaveGlobal, true);

    return () => {
      document.body.removeEventListener('dragenter', onDragEnterGlobal);
      document.body.removeEventListener('dragleave', onDragLeaveGlobal);
    };
  }, []);

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div
        className={cn(
          className,
          'Subtitle-2',
          'InteractionWrapper',
          {
            FileInput: true,
            FileInput_disabled: disabled,
            FileInput_error: hasError || error,
            FileInput_process: (uploadFile && state === STATES.uploading),
            FileInput_success: state === STATES.uploaded,
            FileInput_empty: state === STATES.empty,
          },
        )}
        onClick={openFileDialog}
        ref={mainWrapperRef}
      >
        <input
          className="FileInput__Input"
          disabled={disabled}
          id={id}
          name={name}
          onChange={$onChange}
          ref={mergeRefs(internalRef, externalRef)}
          type="file"
          value={value}
          {...inputProps}
        />

        {preview && <img alt={file.name} className="FileInput__Preview" src={preview} />}
        {!preview && (
          <div className="FileInput__SelectButton">
            {state === STATES.empty && <PlusIcon />}
            {state !== STATES.empty && <FileIcon />}
          </div>
        )}

        <div className="FileInput__File">
          <span className="FileInput__Bar">
            <span className="FileInput__Name Input__Label">
              {file && (
                label ? `${label}: ${file.name}` : file.name
              )}
              {!file && (label || t('uikit:control.file.плейсхолдер'))}
            </span>

            {file && uploadFile && !forceUpload && state === STATES.selected && (
              <RoundButton
                data-testid="fileInput.uploadButton"
                icon={<UploadCloudIcon />}
                kind="positive"
                onClick={onUploadButtonClick}
                onKeyPress={onUploadButtonClick}
                variant="flat"
              />
            )}

            {file && !disabled && (
              <RoundButton
                data-testid="fileInput.clearButton"
                icon={<CrossIcon />}
                kind="negative"
                onClick={onClearButtonClick}
                onKeyPress={onClearButtonClick}
                variant="flat"
              />
            )}
          </span>

          <div className="FileInput__Progress">
            <div className="FileInput__ProgressValue" />
          </div>
        </div>

        <div
          className="FileInput__DragNDropCurtain hidden"
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
          onDragOver={onDragOver}
          onDrop={onDrop}
          ref={dragNDropCurtainRef}
        >
          <PlusIcon className="FileInput__DragNDropIcon" />
          {label || t('uikit:control.file.сообщение для дропа файла')}
        </div>

        <div className="InteractionOverlay" />
      </div>

      <ErrorMessage error={errorMessage ? { message: errorMessage } : error} />
    </>
  );
});

FileInput.displayName = 'FileInput';

FileInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  uploadFunction: PropTypes.func,
  forceUpload: PropTypes.bool,
  onChange: PropTypes.func,
  onChangeValue: PropTypes.func,
  onUpload: PropTypes.func,
  errors: PropTypes.object,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  value: PropTypes.any,
  disabled: PropTypes.bool,
};

FileInput.defaultProps = {
  id: undefined,
  label: undefined,
  name: undefined,
  className: undefined,
  uploadFunction: undefined,
  forceUpload: false,
  onChange: undefined,
  onChangeValue: undefined,
  onUpload: undefined,
  errors: undefined,
  hasError: undefined,
  errorMessage: undefined,
  value: undefined,
  disabled: undefined,
};

export { FileInput };
