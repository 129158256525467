import React from 'react';

import { useTranslation } from 'react-i18next';

import './NotFoundPage.sass';

const NotFoundPage = () => {
  const { t } = useTranslation();

  return (
    <div className="NotFoundPage">
      <div className="NotFoundPage__Content">
        {t('uikit:заголовки.страница не найдена')}
      </div>
    </div>
  );
};

export { NotFoundPage };
