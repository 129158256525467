import React from 'react';

import { DownloadIcon } from '@vlabs/icons';
import { Control } from '@vlabs/uikit';
import i18n from 'i18next';
import PropTypes from 'prop-types';

const DownloadButton = ({
  title,
  onClick,
  className,
}) => {
  return (
    <Control.RoundButton
      className={className}
      data-testid="downloadButton"
      icon={<DownloadIcon />}
      kind="primary"
      onClick={onClick}
      title={title || i18n.t('button.download')}
      variant="dimmed"
    />
  );
};

DownloadButton.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

DownloadButton.defaultProps = {
  className: undefined,
  title: undefined,
};

export default DownloadButton;
