import React from 'react';

import validate from '@vlabs/shared/validators';
import {
  Page, Control, Margin, Grid, GridRow, GridCol,
} from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import st from './CreateHandlerForm.module.sass';

function TemplateRegistrationForm({
  listOptions,
  onSubmit = () => {},
}) {
  const { t } = useTranslation();
  const { register, control, handleSubmit, formState: { errors } } = useForm();

  return (
    <form
      data-testid="modal.templateRegistration"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Page className={st.Page}>
        <Grid>
          <GridRow>
            <GridCol>
              <Margin display="block">
                <span className={st.Description}>
                  {t('handlers:сценарий "Регистрация эталонного биометрического шаблона (с сохранением в список)" позволяет обнаружить лицо на кадре, проверить Liveness и сохранить лицо в заданный список.')}
                </span>
              </Margin>
              <Margin display="block">
                <div className="Body-2">
                  {t('handlers:форма.наименование сценария')}
                </div>
                <Control.Input
                  {...register('description', {
                    maxLength: validate.maxLength(128),
                    required: validate.required(),
                  })}
                  errors={errors}
                />
              </Margin>
              <Margin display="block">
                <div className="Body-2">
                  {t('handlers:список для сохранения биометрического шаблона')}
                </div>
                <Control.Select
                  control={control}
                  errors={errors}
                  name="listId"
                  options={listOptions}
                  rules={{ required: validate.required() }}
                />
              </Margin>
            </GridCol>
          </GridRow>
        </Grid>
        <Margin display="block">
          <Control.Button
            fullWidth
            type="submit"
          >
            {t('handlers:создать')}
          </Control.Button>
        </Margin>
      </Page>
    </form>
  );
}

TemplateRegistrationForm.propTypes = {
  listOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })),
  onSubmit: PropTypes.func.isRequired,
};

TemplateRegistrationForm.defaultProps = {
  listOptions: [],
};

export default TemplateRegistrationForm;
