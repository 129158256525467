import React, { useContext } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { TableContext } from './table-context';
import './Table.sass';
import { TableRow } from './TableRow';

const TableBody = ({
  CustomRow,
}) => {
  const { t } = useTranslation();
  const context = useContext(TableContext);
  const {
    prepareRow,
    rows,
    columns,
  } = context;

  return (
    <div className="TableBody">
      {rows.map((row) => {
        prepareRow(row);
        return (
          CustomRow
            ? <CustomRow context={context} row={row} />
            : (
              <TableRow
                key={JSON.stringify(row.original)}
                row={row}
              />
            )
        );
      })}

      {!rows.length && (
        <div className="TableRow TableRow_Empty">
          <div
            className="TableCell"
            colSpan={columns.length}
            style={{ textAlign: 'center' }}
          >
            {t('uikit:table.сообщение пустой таблицы')}
          </div>
        </div>
      ) }
    </div>
  );
};

TableBody.propTypes = {
  CustomRow: PropTypes.func,
};

TableBody.defaultProps = {
  CustomRow: undefined,
};

export { TableBody };
