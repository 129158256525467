import React from 'react';

import { SENDING_TYPE_OPTIONS } from '@vlabs/api-bindings/src/luna-streams/constants';
import validate from '@vlabs/shared/validators';
import { Control, Fold, SettingsItemWrapper } from '@vlabs/uikit';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

function PoliciesSubForm() {
  const { t } = useTranslation();
  const { register, control, formState: { errors } } = useFormContext();
  return (
    <Fold isOpen title={t('lunaStreamsStreams:форма.sending policy.заголовок')}>
      <SettingsItemWrapper
        data-tooltip-content={t('lunaStreamsStreams:форма.sending policy.time_period_of_searching.подсказка')}
        title={t('lunaStreamsStreams:форма.sending policy.time_period_of_searching.название')}
      >
        <Control.Input
          {...register('policies.sending.time_period_of_searching', {
            min: validate.gte(-1),
            max: validate.lte(2147483647),
            validate: validate.integer(),
          })}
          errors={errors}
        />
      </SettingsItemWrapper>
      <SettingsItemWrapper
        data-tooltip-content={t('lunaStreamsStreams:форма.sending policy.silent_period.подсказка')}
        title={t('lunaStreamsStreams:форма.sending policy.silent_period.название')}
      >
        <Control.Input
          {...register('policies.sending.silent_period', {
            min: validate.gte(-1),
            max: validate.lte(2147483647),
            validate: validate.integer(),
          })}
          errors={errors}
        />
      </SettingsItemWrapper>
      <SettingsItemWrapper title={t('lunaStreamsStreams:форма.sending policy.type')}>
        <Control.Select
          control={control}
          name="policies.sending.type"
          options={SENDING_TYPE_OPTIONS.raw}
        />
      </SettingsItemWrapper>
      <SettingsItemWrapper
        data-tooltip-content={t('lunaStreamsStreams:форма.sending policy.number_of_bestshots_to_send.подсказка')}
        title={t('lunaStreamsStreams:форма.sending policy.number_of_bestshots_to_send.название')}
      >
        <Control.Input
          {...register('policies.sending.number_of_bestshots_to_send', {
            min: validate.gte(0),
            max: validate.lte(2147483647),
            validate: validate.integer(),
          })}
          errors={errors}
        />
      </SettingsItemWrapper>
      <SettingsItemWrapper title={t('lunaStreamsStreams:форма.sending policy.send_only_full_set')}>
        <Control.Switch control={control} name="policies.sending.send_only_full_set" />
      </SettingsItemWrapper>
      <SettingsItemWrapper title={t('lunaStreamsStreams:форма.sending policy.delete_track_after_sending')}>
        <Control.Switch control={control} name="policies.sending.delete_track_after_sending" />
      </SettingsItemWrapper>
    </Fold>
  );
}

PoliciesSubForm.propTypes = {

};

export default PoliciesSubForm;
