import React, { memo } from 'react';

import { IconInner } from '../../IconInner';

const SlidersIconInner = (props) => (
  <IconInner name="SlidersIcon" viewBox="0 0 24 24" {...props}>
    <path d="M4 21v-7m0-4V3m8 18v-9m0-4V3m8 18v-5m0-4V3M1 14h6m2-6h6m2 8h6" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
  </IconInner>
);

export const SlidersIcon = memo(SlidersIconInner);
