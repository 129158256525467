import React, { memo } from 'react';

import { IconInner } from '../../IconInner';

const ThermometerIconInner = (props) => (
  <IconInner name="ThermometerIcon" viewBox="0 0 24 24" {...props}>
    <g clipPath="url(#a)"><path d="M14 14.76V3.5a2.5 2.5 0 0 0-5 0v11.26a4.5 4.5 0 1 0 5 0Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" /></g>
  </IconInner>
);

export const ThermometerIcon = memo(ThermometerIconInner);
