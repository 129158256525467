import React, { useCallback } from 'react';

import PropTypes from 'prop-types';

import { PolygonSelector } from './PolygonSelector';
import { RectangleSelector } from './RectangleSelector';

const ROI = ({
  onROISave,
  onCancel,
  isRectangle,
  ...props
}) => {
  const $onROISave = useCallback((coords) => {
    if (onROISave) onROISave(coords);
  }, [onROISave]);

  return (
    <>
      {isRectangle && (
        <RectangleSelector
          {...props}
          onCancel={onCancel}
          onSelect={$onROISave}
        />
      )}

      {!isRectangle && (
        <PolygonSelector
          {...props}
          onCancel={onCancel}
          onSelect={$onROISave}
        />
      )}
    </>
  );
};

ROI.propTypes = {
  onROISave: PropTypes.func,
  onCancel: PropTypes.func,
  isRectangle: PropTypes.bool,
};

ROI.defaultProps = {
  onROISave: undefined,
  onCancel: undefined,
  isRectangle: false,
};

export { ROI };
