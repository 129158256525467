import PropTypes from 'prop-types';

const ButtonScrimItem = ({
  children,
}) => {
  return children;
};

ButtonScrimItem.propTypes = {
  children: PropTypes.node,
};

ButtonScrimItem.defaultProps = {
  children: undefined,
};

export {
  ButtonScrimItem,
};
