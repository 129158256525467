import React, { useEffect, useReducer, useRef, useState } from 'react';

import cn from 'classnames';
import { isEqual, clone } from 'lodash-es';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ImagesPointsMatcherCanvas } from '../ImagesPointsCanvas';
import '../ImagesPointsMatcher.sass';
import { imageFromSource, reorganizeExternalPoints, reorganizeInternalPoints } from '../utils';

const initialState = {
  state: 'init',
  step: 0,
  points: [[], []],
  images: undefined,
};

const actionTypes = {
  SET_IMAGES: 'set-images',
  SET_POINTS: 'set-points',
  CREATE_POINT: 'create-point',
  UPDATE_POINT: 'update-point',
  DELETE_POINT: 'delete-point',
};

function reducer(previousState, action) {
  const areImagesLoaded = !!previousState.images?.every(({ obj }) => obj);

  switch (action.type) {
    // Устанавливаем изображения
    case actionTypes.SET_IMAGES: {
      if (action.payload === undefined) {
        return {
          ...previousState,
          images: undefined,
          state: 'init',
        };
      }
      return {
        ...previousState,
        images: action.payload,
        state: 'ready',
      };
    // Устанавливаем точки извне
    } case actionTypes.SET_POINTS: {
      // Извне устанавливается пустой массив точек,
      // т.е. происходит либо инициализация либо сброс значений
      if (!action.payload || (Array.isArray(action.payload) && action.payload.length === 0)) {
        // Возвращаем состояние к началу отрисовки
        return { ...previousState, points: [[], []], step: 0 };
      }

      const points = reorganizeExternalPoints(action.payload);

      // Извне приходит непустой массив точек
      // Необходимо проверить есть ли отличия от уже имеющегося
      // Точки неодинаковые, значит они принудительно обновляются извне.
      if (!isEqual(points, previousState.points)) {
        return {
          ...previousState,
          points,
          state: areImagesLoaded ? 'ready' : 'init',
          step: 0,
        };
      }

      return previousState;
    }
    // Добавляем точку на одно из изображений
    case actionTypes.CREATE_POINT: {
      const { step, point } = action.payload;
      const points = clone(previousState.points);
      points[step] = [...previousState.points[step], point];
      return { ...previousState,
        points,
        step: (step + 1) % 2,
      };
    }
    // Обновляем одну из ранее установленных точек
    case actionTypes.UPDATE_POINT: {
      const { step, index, point } = action.payload;
      const points = clone(previousState.points);
      points[step] = previousState.points[step].map((p, i) => (i === index ? point : p));
      return { ...previousState, points };
      // Удаляем одну из установленных ранее точек
    } case actionTypes.DELETE_POINT: {
      const { index } = action.payload;
      const points = previousState.points.map((col) => col.filter((_, i) => i !== index));
      points[0].length = points[1].length;
      return {
        ...previousState,
        points,
        step: 0,
      };
    }
    default: {
      throw new Error(`action ${action.type} is not registered`);
    }
  }
}

/**
 * Компонент для калибрации сопоставителя точек на 2 изображениях
 *
 * Пользователь ставит пары точек на 1 и 2 изображении, по ним потом будет построена
 * карта соответствия точек на изображениях, ее проверят через ImagesPointDetector
 * и используют отдельно.
 */
const ImagesPointsMatcher = ({
  points,
  images,
  onChange,
  isRow,
}) => {
  const [store, dispatch] = useReducer(reducer, initialState);

  const [maxCanvasWidth, setMaxCanvasWidth] = useState(null);
  const containerRef = useRef(null);

  const { t } = useTranslation();

  useEffect(() => {
    dispatch({ type: 'set-images' });
    async function loadImages() {
      const payload = await Promise.all(images.map(async (img) => {
        const obj = await imageFromSource(img.source);
        return ({ ...img, obj });
      }));
      dispatch({ type: 'set-images', payload });
    }
    loadImages();
  }, [images]);

  useEffect(() => { dispatch({ type: 'set-points', payload: points }); }, [points]);

  // отправляем результаты куда следует
  useEffect(() => {
    if (store.state !== 'ready') return;
    // но только если обе точки поставлены
    // FIXME: Если поставлена одна точка слева, то точки нельзя будет двигать
    if (store.points[0].length > store.points[1].length) return;
    if (isEqual(store.points, reorganizeExternalPoints(store.points))) return;

    if (onChange) onChange(reorganizeInternalPoints(store.points));
  }, [store.state, store.points]);

  useEffect(() => {
    if (!containerRef.current) return;

    const maxWidth = isRow
      ? containerRef.current.clientWidth / 2 - 15
      : undefined;

    setMaxCanvasWidth(maxWidth);
  }, [containerRef, isRow]);

  return (
    <div className="ImagesPointsMatcher">
      <div
        className={cn({
          ImagesPointsMatcher__Images: true,
          ImagesPointsMatcher__Images_row: isRow,
        })}
        ref={containerRef}
      >
        {store.state === 'init' && (
          <span className="ImagesPointsState_loading">
            {t('uikit:images-points-canvas.state.init')}
          </span>
        )}
        {store.state === 'ready' && (
        <>
          <ImagesPointsMatcherCanvas
            id="ImagesPointsMatcherCanvas1"
            image={store.images[0].obj}
            isActive={store.step === 0}
            isZoomable={store.images[0]?.isZoomable}
            maxWidth={maxCanvasWidth}
            onPointCreate={(point) => dispatch({ type: 'create-point', payload: { step: 0, point } })}
            onPointDelete={(index) => dispatch({ type: 'delete-point', payload: { step: 0, index } })}
            onPointUpdate={(index, point) => dispatch({ type: 'update-point', payload: { step: 0, index, point } })}
            points={store.points[0]}
          />
          <ImagesPointsMatcherCanvas
            id="ImagesPointsMatcherCanvas2"
            image={store.images[1].obj}
            isActive={store.step === 1}
            isZoomable={store.images[1]?.isZoomable}
            maxWidth={maxCanvasWidth}
            onPointCreate={(point) => dispatch({ type: 'create-point', payload: { step: 1, point } })}
            onPointDelete={(index) => dispatch({ type: 'delete-point', payload: { step: 1, index } })}
            onPointUpdate={(index, point) => dispatch({ type: 'update-point', payload: { step: 1, index, point } })}
            points={store.points[1]}
          />
        </>
        )}

      </div>

      <p className="ImagesPointsMatcher__Tip">
        {t('uikit:imagesPointsMatcher.подсказка по управлению')}
      </p>
    </div>
  );
};

ImagesPointsMatcher.propTypes = {
  points: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }))),
  images: PropTypes.arrayOf(PropTypes.shape({
    isZoomable: PropTypes.bool.isRequired,
    source: PropTypes.string.isRequired,
  })).isRequired,
  onChange: PropTypes.func,
  isRow: PropTypes.bool,
};

ImagesPointsMatcher.defaultProps = {
  points: undefined,
  onChange: undefined,
  isRow: true,
};

export { ImagesPointsMatcher };
