import React, { forwardRef, useEffect, useState } from 'react';

import { CheckIcon, MinusIcon } from '@vlabs/icons';
import cn from 'classnames';
import PropTypes from 'prop-types';
import nextId from 'react-id-generator';

import { refType } from '../../utils/types';
import { ErrorMessage } from '../error-message/ErrorMessage';
import { getError } from '../utils';

import './Checkbox.sass';

const Checkbox = forwardRef(({
  label,
  inputRef,
  indeterminate,
  id: inputId,
  style,
  className,
  onChange,
  onChangeValue,
  name,
  errors,
  hasError,
  errorMessage,
  ...inputProps
}, externalRef) => {
  const { disabled } = inputProps;
  const [id, setId] = useState();

  const error = getError(errors, name);

  // Уникальный id приходится генерировать вручную,
  // так как у группы чекбокосов будет один id на всех
  useEffect(() => {
    setId(inputId || nextId());
  }, [inputId]);

  const $onChange = (e) => {
    if (onChange) onChange(e);
    if (onChangeValue) onChangeValue(e.target.checked);
  };

  return (
    <>
      <div className={cn(
        className,
        {
          Checkbox: true,
          Checkbox_disabled: disabled,
          Checkbox_indeterminate: !!indeterminate,
          Checkbox_error: hasError || error,
        },
      )}
      >
        <input
          className="Checkbox__Input"
          data-testid="checkbox"
          id={id}
          // eslint-disable-next-line react/no-unknown-property
          indeterminate={indeterminate ? 'indeterminate' : undefined}
          name={name}
          onChange={$onChange}
          ref={externalRef}
          type="checkbox"
          {...inputProps}
        />
        <label
          className={cn({
            Input__Label: true,
            Checkbox__Label: true,
            Checkbox__Label_withText: !!label,
          })}
          htmlFor={id}
          style={style}
        >
          <div className="Checkbox__Box">
            <div className="Checkbox__BoxOverlay" />
            {indeterminate ? <MinusIcon /> : <CheckIcon />}
          </div>
          {label}
        </label>
      </div>

      <ErrorMessage error={errorMessage ? { message: errorMessage } : error} />
    </>
  );
});

Checkbox.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  indeterminate: PropTypes.bool,
  inputRef: refType,
  style: PropTypes.oneOfType([
    PropTypes.shape(),
    PropTypes.string,
  ]),
  value: PropTypes.any,
  checked: PropTypes.any,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onChangeValue: PropTypes.func,
  errors: PropTypes.object,
  hasError: PropTypes.bool,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
};

Checkbox.defaultProps = {
  id: undefined,
  label: null,
  name: undefined,
  indeterminate: undefined,
  inputRef: undefined,
  style: undefined,
  value: undefined,
  checked: undefined,
  className: undefined,
  onChange: undefined,
  onChangeValue: undefined,
  errors: undefined,
  hasError: undefined,
  disabled: undefined,
  errorMessage: undefined,
};

Checkbox.displayName = 'Checkbox';

export { Checkbox };
