import { createSelector } from '@reduxjs/toolkit';

export const selectAccount = (state) => state.account || {};

export const selectIsAuthenticated = createSelector(
  selectAccount,
  ({ isAuthenticated }) => isAuthenticated,
);
export const selectAccountModel = createSelector(
  selectAccount,
  ({ model }) => model,
);
export const selectAccountRole = createSelector(
  selectAccountModel,
  (model) => {
    // Сделано для проверки роли luna
    if (model?.account_type && model.account_type === 'admin') {
      return 2;
    }

    return model?.role || 0;
  },
);

// Email luna
export const selectAccountLogin = createSelector(
  selectAccountModel,
  (model) => (model ? model.login : null),
);

export const selectAccountEmail = createSelector(
  selectAccountModel,
  (model) => (model ? model.email : null),
);

export const viewerCan = createSelector(
  selectAccountModel,
  (accountModel) => (permissions, options = {}) => {
    const { method = 'oneof' } = options;
    const checkPermissionRule = (permissionRule) => {
      const [feature, permission] = permissionRule.split(':');
      if (feature === 'emit_events') {
        return accountModel.permissionsRaw?.[feature]?.allowed === parseInt(permission, 10);
      }
      return accountModel.permissionsRaw?.[feature]?.includes(permission);
    };

    switch (method) {
      case 'oneof':
        return permissions.some(checkPermissionRule);
      case 'allof':
        return permissions.every(checkPermissionRule);
      default:
        console.error(`Unknown method: ${method}`);
    }
  },
);
