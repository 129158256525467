export const getDefaultValues = (handleId, email) => ({
  description: `${email} packageImport`,
  content: {
    handler: {
      handler_id: handleId,
      policies: {
        detect_policy: {
          detect_face: 1,
          face_quality: {
            estimate: false,
            filter: 1,
          },
        },
        storage_policy: {
          face_policy: {
            store_face: 1,
            link_to_lists_policy: [],
          },
          attribute_policy: {
            store_attribute: 1,
          },
          notification_policy: {
            send_notification: 0,
          },
        },
        extract_policy: {
          extract_basic_attributes: 1,
        },
      },
    },
    source: {
      source_type: { value: 'file' },
      file: {
        reference: undefined,
      },
    },
  },
  notification_policy: {
    task_callbacks: [],
  },
});
