export const compareOptions = (a, b) => {
  const aNum = parseInt(a.label, 10);
  const bNum = parseInt(b.label, 10);

  if (!Number.isNaN(aNum) && !Number.isNaN(bNum)) {
    return aNum - bNum;
  }

  return String(a.label).localeCompare(String(b.label));
};

const filterValues = (options, string) => {
  if (!string?.length) return options;

  return options.filter((option) => {
    return option.label.toLowerCase().includes(string.toLowerCase());
  });
};

export const createOptionsFilter = (options) => (string) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(filterValues(options, string));
    }, 300);
  });
};
