export const templateRegistration = ({ description, listId, liveness }) => ({
  description,
  policies: {
    detect_policy: {
      detect_face: 1,
      multiface_policy: 0,
      estimate_head_pose: 1,
      estimate_quality: 1,
      estimate_mask: 1,
      yaw_threshold: 30,
      roll_threshold: 40,
      pitch_threshold: 20,
      mask_states: [
        1, 3,
      ],
      estimate_liveness: liveness
        ? {
          estimate: 1,
          quality_threshold: 0.5,
          liveness_threshold: 0.88,
        }
        : undefined,
    },
    extract_policy: {
      extract_basic_attributes: 1,
      extract_face_descriptor: 1,
      fd_score_threshold: 0.6,
    },
    storage_policy: {
      face_sample_policy: {
        store_sample: 1,
        filters: {
          liveness: liveness ? [1] : undefined,
        },
      },
      face_policy: {
        store_face: 1,
        filters: {
          liveness: liveness ? [1] : undefined },
        set_sample_as_avatar: 1,
        link_to_lists_policy: [
          {
            list_id: listId.value,
            filters: {
              liveness: liveness ? [1] : undefined,
            },
          },
        ],
      },
      event_policy: {
        store_event: 1,
      },
      notification_policy: {
        send_notification: 1,
      },
    },
  },
});

export const biometricIdentification = ({ description, listId, liveness }) => ({
  description,
  policies: {
    detect_policy: {
      detect_face: 1,
      multiface_policy: 1,
      estimate_head_pose: 1,
      estimate_quality: 1,
      estimate_mask: 1,
      yaw_threshold: 30,
      roll_threshold: 30,
      pitch_threshold: 30,
      estimate_liveness: liveness
        ? {
          estimate: 1,
          quality_threshold: 0.5,
          liveness_threshold: 0.88,
        }
        : undefined,
    },
    extract_policy: {
      extract_basic_attributes: 1,
      extract_face_descriptor: 1,
      fd_score_threshold: 0.6,
    },
    match_policy: [
      {
        label: 'list',
        candidates: {
          origin: 'faces',
          list_id: listId.value,
        },
        limit: 3,
        threshold: 0.5,
      },
    ],
    storage_policy: {
      event_policy: {
        store_event: 1,
      },
      notification_policy: {
        send_notification: 1,
      },
    },
  },
});

export const saveIdentified = ({ description, fromListId, toListId, liveness }) => ({
  description,
  policies: {
    detect_policy: {
      detect_face: 1,
      multiface_policy: 1,
      estimate_head_pose: 1,
      estimate_quality: 1,
      estimate_mask: 1,
      yaw_threshold: 30,
      roll_threshold: 30,
      pitch_threshold: 30,
      mask_states: [
        1, 3,
      ],
      estimate_liveness: liveness
        ? {
          estimate: 1,
          quality_threshold: 0.5,
          liveness_threshold: 0.88,
        }
        : undefined,
    },
    extract_policy: {
      extract_basic_attributes: 1,
      extract_face_descriptor: 1,
      fd_score_threshold: 0.6,
    },
    match_policy: [
      {
        label: 'list',
        candidates: {
          origin: 'faces',
          list_id: fromListId.value,
        },
        limit: 3,
        threshold: 0.5,
      },
    ],
    storage_policy: {
      face_sample_policy: {
        store_sample: 1,
        filters: {
          match: [
            {
              label: 'list',
              similarity__gte: 0.95,
            },
          ],
          liveness: liveness ? [1] : undefined,
        },
      },
      face_policy: {
        store_face: 1,
        filters: {
          liveness: liveness ? [1] : undefined,
          match: [
            {
              label: 'list',
              similarity__gte: 0.95,
            },
          ],
        },
        set_sample_as_avatar: 1,
        link_to_lists_policy: [
          {
            list_id: toListId.value,
            filters: {
              liveness: liveness ? [1] : undefined,
              match: [
                {
                  label: 'list',
                  similarity__gte: 0.95,
                },
              ],
            },
          },
        ],
      },
      event_policy: {
        store_event: 1,
      },
      notification_policy: {
        send_notification: 1,
      },
    },
  },
});

export const defineAttributes = ({ description, liveness }) => ({
  description,
  policies: {
    detect_policy: {
      detect_face: 1,
      multiface_policy: 1,
      estimate_emotions: 1,
      estimate_head_pose: 1,
      estimate_mask: 1,
      yaw_threshold: 30,
      roll_threshold: 30,
      pitch_threshold: 30,
      estimate_liveness: liveness
        ? {
          estimate: 1,
          quality_threshold: 0.5,
          liveness_threshold: 0.88,
        }
        : undefined,
    },
    extract_policy: {
      extract_basic_attributes: 1,
      extract_face_descriptor: 0,
      fd_score_threshold: 0.1,
    },
    storage_policy: {
      face_sample_policy: {
        store_sample: 0,
      },
      face_policy: {
        store_face: 0,
      },
      event_policy: {
        store_event: 1,
      },
      notification_policy: {
        send_notification: 1,
      },
    },
  },
});

export const saveUnique = ({ description, listId }) => ({
  description,
  policies: {
    detect_policy: {
      detect_face: 1,
      multiface_policy: 1,
      estimate_head_pose: 1,
      estimate_quality: 1,
      estimate_mask: 1,
      yaw_threshold: 30,
      roll_threshold: 30,
      pitch_threshold: 30,
      mask_states: [
        1, 3,
      ],
    },
    extract_policy: {
      extract_basic_attributes: 1,
      extract_face_descriptor: 1,
      fd_score_threshold: 0.6,
    },
    match_policy: [
      {
        label: 'uniqueLabel',
        candidates: {
          origin: 'faces',
          list_id: listId.value,
        },
        limit: 3,
      },
    ],
    storage_policy: {
      face_sample_policy: {
        store_sample: 1,
        filters: {
          match: [
            {
              label: 'uniqueLabel',
              similarity__lte: 0.95,
            },
          ],
        },
      },
      face_policy: {
        store_face: 1,
        filters: {
          match: [
            {
              label: 'uniqueLabel',
              similarity__lte: 0.95,
            },
          ],
        },
        set_sample_as_avatar: 1,
        link_to_lists_policy: [
          {
            list_id: listId.value,
            filters: {
              match: [
                {
                  label: 'uniqueLabel',
                  similarity__lte: 0.95,
                },
              ],
            },
          },
        ],
      },
      event_policy: {
        store_event: 1,
      },
      notification_policy: {
        send_notification: 1,
      },
    },
  },
}
);

export const identificationFaceQuality = ({ description, listId }) => ({
  description,
  policies: {
    detect_policy: {
      detect_face: 1,
      detect_body: 0,
      multiface_policy: 0,
      estimate_head_pose: 1,
      estimate_emotions: 0,
      estimate_mask: 0,
      estimate_quality: 0,
      estimate_gaze: 0,
      estimate_eyes_attributes: 0,
      estimate_mouth_attributes: 0,
      detect_landmarks68: 0,
      extract_exif: 1,
      yaw_threshold: 5,
      roll_threshold: 5,
      pitch_threshold: 8,
      estimate_liveness: {
        estimate: 1,
        quality_threshold: 0.7,
        liveness_threshold: 0.7,
      },
      liveness_states: [
        1,
      ],
      face_quality: {
        estimate: 1,
        filter: 0,
        checks: {
          image_format: {
            estimate: 1,
            threshold: [
              'JPEG',
              'JPEG2000',
              'PNG',
            ],
          },
          illumination_quality: {
            estimate: 1,
            threshold: {
              min: 0.3,
              max: 1,
            },
          },
          specularity_quality: {
            estimate: 1,
            threshold: {
              min: 0.3,
              max: 1,
            },
          },
          blurriness_quality: {
            estimate: 1,
            threshold: {
              min: 0.61,
              max: 1,
            },
          },
          dark_quality: {
            estimate: 1,
            threshold: {
              min: 0.5,
              max: 1,
            },
          },
          light_quality: {
            estimate: 1,
            threshold: {
              min: 0.57,
              max: 1,
            },
          },
          head_yaw: {
            estimate: 1,
            threshold: {
              min: -5,
              max: 5,
            },
          },
          head_pitch: {
            estimate: 1,
            threshold: {
              min: -5,
              max: 5,
            },
          },
          head_roll: {
            estimate: 1,
            threshold: {
              min: -8,
              max: 8,
            },
          },
          gaze_yaw: {
            estimate: 0,
            threshold: {
              min: -5,
              max: 5,
            },
          },
          gaze_pitch: {
            estimate: 0,
            threshold: {
              min: -5,
              max: 5,
            },
          },
          mouth_smiling: {
            estimate: 1,
            threshold: {
              min: 0,
              max: 0.5,
            },
          },
          mouth_occluded: {
            estimate: 1,
            threshold: {
              min: 0,
              max: 0.5,
            },
          },
          mouth_open: {
            estimate: 1,
            threshold: {
              min: 0,
              max: 0.5,
            },
          },
          glasses: {
            estimate: 1,
            threshold: [
              'no_glasses',
              'eyeglasses',
            ],
          },
          left_eye: {
            estimate: 1,
            threshold: [
              'open',
            ],
          },
          right_eye: {
            estimate: 1,
            threshold: [
              'open',
            ],
          },
          head_horizontal_center: {
            estimate: 0,
            threshold: {
              min: 0.45,
              max: 0.55,
            },
          },
          head_vertical_center: {
            estimate: 0,
            threshold: {
              min: 0.3,
              max: 0.5,
            },
          },
          head_width: {
            estimate: 0,
            threshold: {
              min: 0.5,
              max: 0.75,
            },
          },
          head_height: {
            estimate: 0,
            threshold: {
              min: 0.6,
              max: 0.9,
            },
          },
          eye_distance: {
            estimate: 1,
            threshold: {
              min: 45,
              max: 120,
            },
          },
          image_width: {
            estimate: 0,
            threshold: {
              min: 180,
              max: 1920,
            },
          },
          image_height: {
            estimate: 0,
            threshold: {
              min: 180,
              max: 1080,
            },
          },
          aspect_ratio: {
            estimate: 0,
            threshold: {
              min: 0.74,
              max: 0.8,
            },
          },
          face_width: {
            estimate: 0,
            threshold: {
              min: 180,
              max: 1920,
            },
          },
          face_height: {
            estimate: 0,
            threshold: {
              min: 180,
              max: 1920,
            },
          },
          indent_left: {
            estimate: 1,
            threshold: {
              min: 20,
            },
          },
          indent_right: {
            estimate: 1,
            threshold: {
              min: 20,
            },
          },
          indent_upper: {
            estimate: 1,
            threshold: {
              min: 20,
            },
          },
          indent_lower: {
            estimate: 1,
            threshold: {
              min: 20,
            },
          },
          image_size: {
            estimate: 0,
            threshold: {
              min: 5120,
              max: 2097152,
            },
          },
          eyebrows_state: {
            estimate: 1,
            threshold: [
              'neutral',
            ],
          },
          headwear_type: {
            estimate: 0,
            threshold: [
              'none',
            ],
          },
          smile_properties: {
            estimate: 1,
            threshold: [
              'none',
            ],
          },
          face_color_type: {
            estimate: 0,
            threshold: [
              'color',
            ],
          },
          natural_light: {
            estimate: 1,
            threshold: 1,
          },
          radial_distortion: {
            estimate: 0,
            threshold: 0,
          },
          red_eyes: {
            estimate: 0,
            threshold: 0,
          },
          illumination_uniformity: {
            estimate: 1,
            threshold: {
              min: 0.5,
              max: 1,
            },
          },
          dynamic_range: {
            estimate: 0,
            threshold: {
              min: 0.5,
              max: 1,
            },
          },
          background_uniformity: {
            estimate: 1,
            threshold: {
              min: 0.5,
              max: 1,
            },
          },
          background_lightness: {
            estimate: 1,
            threshold: {
              min: 0.2,
              max: 1,
            },
          },
        },
      },
    },
    extract_policy: {
      extract_basic_attributes: 1,
      extract_face_descriptor: 1,
      fd_score_threshold: 0.6,
      extract_body_descriptor: 0,
    },
    storage_policy: {
      face_sample_policy: {
        store_sample: 1,
      },
      face_policy: {
        store_face: 1,
        set_sample_as_avatar: 1,
        link_to_lists_policy: [
          {
            list_id: listId.value,
          },
        ],
      },
      event_policy: {
        store_event: 1,
        wait_saving: 0,
      },
      notification_policy: {
        send_notification: 1,
      },
    },
  },
  is_dynamic: false,

});
