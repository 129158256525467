import React from 'react';

import validate from '@vlabs/shared/validators';
import { Control } from '@vlabs/uikit';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { MetaFilterFields } from './meta-filter/MetaFilterFields';

export function OtherFilterForm() {
  const { t } = useTranslation();
  const { register, formState: { errors } } = useFormContext();

  return (
    <>
      <Control.Input
        id="track_ids"
        label={t('идентификаторы треков через запятую')}
        {...register('track_ids', {
          validate: validate.multipleTrackIds(),
        })}
        errors={errors}
      />
      <MetaFilterFields />
    </>
  );
}
