import React from 'react';

import PropTypes from 'prop-types';

import CheckRowsFaceQuality from '../components/CheckRowsFaceQuality';

export default function MindigitalCheckResults({ results }) {
  const faceQuality = results?.detections?.[0]?.samples?.face?.detection?.face_quality;
  if (!faceQuality) return null;

  return (
    <CheckRowsFaceQuality
      estimationName="mindigital"
      estimations={faceQuality.checks}
      filename={results.detections[0].filename}
      status={faceQuality.status}
    />
  );
}

MindigitalCheckResults.propTypes = {
  results: PropTypes.objectOf(PropTypes.any).isRequired,
};
