import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import './Group.sass';

const Group = ({
  children,
  gapSize,
  className,
  vertical,
  ...props
}) => {
  return (
    <div
      className={cn(
        className,
        {
          Group: true,
          Group_vertical: vertical,
        },
      )}
      style={{ '--gapSize': gapSize }}
      {...props}
    >
      {children}
    </div>
  );
};

Group.propTypes = {
  children: PropTypes.node,
  gapSize: PropTypes.string,
  className: PropTypes.string,
  vertical: PropTypes.bool,
};

Group.defaultProps = {
  children: undefined,
  gapSize: undefined,
  className: undefined,
  vertical: undefined,
};

export {
  Group,
};
