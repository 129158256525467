import React, { useState, useEffect } from 'react';

import { apiContainer } from '@vlabs/api-bindings';
import { DETECTION_TYPES } from '@vlabs/shared/components/detection-selector/constants';
import detectOnImageRequest from '@vlabs/shared/components/detection-selector/detectOnImageRequest';
import { EmptyImage } from '@vlabs/shared/components/empty-image';
import { PhotoUploadForm } from '@vlabs/shared/components/photo-upload-form';
import { calcSimilarityPercent, calcBadgeType } from '@vlabs/shared/utils';
import { Badge, Control, Margin, Divider } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { searchByRawDescriptor } from '@vlabs/pages/search/store';
// FIXME: требуется рефакторинг, или вынести в другое место или выкинуть
// FIXME: обработать ошибку на верификацию при невыбранном лице (если несколько кандидатов - выберите сначала лицо)
import './VerifyForm.sass';

// FIXME: это не компонент формы, перепроверить и поменять название чтобы не путаться
export function VerifyForm({ photo, faceId }) {
  const { t } = useTranslation();
  const [file, setFile] = useState(undefined);
  const [candidates, setCandidates] = useState([]);
  const [selectedCandidates, setSelectedCandidates] = useState([]);
  const [matchResultSimilarity, setMatchResultSimilarity] = useState();
  const [isEmpty, setIsEmpty] = useState(!photo);

  const [isVerified, setIsVerified] = useState(false);

  useEffect(() => {
    if (!file) return;
    async function run() {
      const imageDetections = await detectOnImageRequest(
        file,
        [DETECTION_TYPES.FACES],
        apiContainer.lunaClient.sdk.estimateFace,
        apiContainer.lunaClient.sdk.estimateBody,
      );
      setCandidates(imageDetections);
    }
    run();
  }, [file]);

  const onFileSelect = async (newFile) => {
    if (!newFile) return;

    setFile(newFile);
  };

  const onReset = () => {
    setFile(undefined);
    setCandidates(undefined);
    setSelectedCandidates([]);
    setIsVerified(false);
    setMatchResultSimilarity(undefined);
  };

  const onMatch = async () => {
    const {
      data: {
        attributes: personAttributes,
      },
    } = await apiContainer.lunaClient.faces.attributes(faceId).get(faceId);

    const matches = await searchByRawDescriptor({ faceId, personAttributes, selectedCandidates, candidates });
    setIsVerified(true);
    setMatchResultSimilarity(matches?.[0].matches?.[0].similarity);
  };

  const renderResultBlock = () => {
    if (!isVerified) {
      return (
        <PhotoUploadForm
          detections={candidates?.all}
          errorProps={{ errors: { message: (file && candidates?.length === 0) && t('выбранное изображение не содержит лиц') } }}
          file={file}
          onFileSelect={onFileSelect}
          onResetFile={onReset}
          onSelectSampleId={([facesId]) => facesId && setSelectedCandidates(facesId)}
        />
      );
    }

    return (
      <>
        <img alt="фотография" className="VerifyFormFaceHolder" data-testid="image" src={file.binary || null} />
        <Badge
          className="FaceCardBadge"
          kind={calcBadgeType(matchResultSimilarity)}
          value={calcSimilarityPercent(matchResultSimilarity)}
        />
      </>
    );
  };

  return (
    <div className="VerifyForm">
      <h5>{t('externalID:modal.verification.title')}</h5>
      <Divider small />

      <div className="VerifyFormPhotoContainer">
        <div className="FacePhotoContainer">
          {photo && !isEmpty && (
            <img
              alt="фотография"
              className="VerifyFormFaceHolder"
              data-testid="image"
              onError={() => setIsEmpty(true)}
              src={photo || null}
            />
          )}
          {isEmpty && <EmptyImage className="EmptyFaceMessage" />}
        </div>
        <Margin right />
        <div className="FacePhotoContainer">{renderResultBlock()}</div>
      </div>
      <Divider />

      <div className="VerifyFormBottom">
        <Control.Button disabled={candidates?.length === 0} onClick={onMatch} type="button">
          {t('externalID:action.verify')}
        </Control.Button>

        <Margin right />
        <Control.Button disabled={file === null || file === undefined} kind="negative" onClick={onReset}>
          {t('externalID:button.reset')}
        </Control.Button>
      </div>
    </div>
  );
}

VerifyForm.propTypes = {
  photo: PropTypes.string.isRequired,
  faceId: PropTypes.string.isRequired,
};
