import React from 'react';

import validate from '@vlabs/shared/validators';
import { Control, Divider } from '@vlabs/uikit';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import st from './ComparisonParamsFilterForm.module.sass';

export function ComparisonParamsFilterForm() {
  const { t } = useTranslation();
  const { register, watch, formState: { errors } } = useFormContext();
  const similarityGte = Number(watch('top_similar_object_similarity__gte'));

  return (
    <>
      <Control.Input
        id="top_matching_candidates_label"
        label={t('label')}
        {...register('top_matching_candidates_label')}
      />
      <Divider small />

      <div className={st.Filter_flex}>
        <div className={st.FilterInputs_width}>
          <Control.Input
            id="top_similar_object_similarity__gte"
            label={t('similarity %')}
            placeholder={t('from')}
            {...register('top_similar_object_similarity__gte', {
              min: validate.gte(0),
              max: validate.lte(100),
              validate: validate.number(),
            })}
            errors={errors}
          />
        </div>
        <div className={st.FilterDivider}>:</div>
        <div className={st.FilterInputs_width}>
          <Control.Input
            placeholder={t('to')}
            {...register('top_similar_object_similarity__lt', {
              min: validate.gte(Math.max(similarityGte, 0)),
              max: validate.lte(100),
              validate: validate.number(),
            })}
            errors={errors}
          />
        </div>
      </div>
      <Divider small />
    </>
  );
}
