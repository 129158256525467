import React, { forwardRef, useRef } from 'react';

import { CalendarIcon } from '@vlabs/icons';
import endOfDay from 'date-fns/endOfDay';
import isEqual from 'date-fns/isEqual';
import startOfDay from 'date-fns/startOfDay';
import l10n from 'flatpickr/dist/l10n';
import 'flatpickr/dist/themes/light.css';
import PropTypes from 'prop-types';
import Flatpickr from 'react-flatpickr';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { bundleEventHandlers } from '../../utils/bundleEventHandlers';
import { mergeRefs } from '../../utils/mergeRefs';
import { ErrorMessage } from '../error-message/ErrorMessage';
import { Input } from '../input/Input';
import { getError, warnLabelWithoutId } from '../utils';

import './DateInput.sass';

const DateInput = forwardRef(({
  id,
  name,
  mode,
  dateFormat,
  enableTime,
  time24hr,
  inputStyle,
  errors,
  hasError,
  errorMessage,
  onClear,
  onClose,
  onChange,
  onChangeRaw,
  placeholder,
  options,
  control,
  value,
  defaultValue,
  rules,
  className,
  inputProps,
  label,
  ...props
}, externalRef) => {
  const { i18n } = useTranslation();
  const internalRef = useRef();

  const $dateFormat = dateFormat ?? (enableTime ? 'd.m.Y H:i' : 'd.m.Y');

  const error = getError(errors, name);

  let lastStringValue = null;

  let controllerRef;
  let controllerOnChange;
  const dynamicProps = {
    value,
  };

  if (control) {
    // В данном случае окей вызывать хук по условию, потому что проп control
    // никогда не меняется динамически между рендерами,
    // он либо всегда есть либо его никогда нет
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { field } = useController({
      name,
      control,
      defaultValue,
      rules,
    });

    dynamicProps.onBlur = field.onBlur;
    dynamicProps.value = field.value;
    controllerRef = field.ref;
    controllerOnChange = field.onChange;
  }

  const $onChange = bundleEventHandlers(onChange, controllerOnChange);

  // flatpickr бросает событие onChange даже на промежуточные действия.
  // Например, если стоит выбор range + время, то onChange сработает при выборе
  // первой даты, второй даты, и времени для обеих. То есть 4 раза вместо 1
  //
  // Эти промежуточные результаты ломают работу компонента, потому что если сделать
  // setValue на промежуточное событие, то компонент перерендерится, и все сбросится.
  //
  // Поэтому наружу нормальный onChange мы бросаем только при закрытии, и внутренний,
  // сырой, идет под другим именем на всякий случай
  const $onChangeRaw = (...params) => {
    if (onChangeRaw) onChangeRaw(...params);
  };

  const $onClear = () => {
    if (internalRef.current) {
      internalRef.current.flatpickr.clear();
    }
    if (onClear) onClear();
    $onChange(mode === 'range' ? [] : null);
  };

  const $onClose = (newValue, newValueString) => {
    if (!internalRef.current) return;
    let $newValue = newValue;

    if (mode === 'range' && $newValue) {
      if ($newValue.length < 2) {
        internalRef.current.flatpickr.clear();
        $newValue = [];
      } else {
        // Если обе даты в range в один день, то ставим одну на
        // 0 часов, а вторую на 23:59
        const [from, to] = $newValue;
        if (isEqual(from, to)) {
          $newValue = [startOfDay(from), endOfDay(to)];
          internalRef.current.flatpickr.setDate($newValue);
        }
      }
    }

    if (lastStringValue !== newValueString) {
      $onChange($newValue);
    }

    lastStringValue = newValueString;
    if (onClose) onClose($newValue);
  };

  if (label && !id) {
    warnLabelWithoutId(label, name);
  }

  return (
    <>
      {label && (
        <label
          className="Input__Label"
          htmlFor={id}
        >
          {label}
        </label>
      )}

      <Flatpickr
        options={{
          mode,
          dateFormat: $dateFormat,
          locale: l10n[i18n.language || 'ru'],
          enableTime,
          time_24hr: time24hr,
          ...(options || {}),
        }}
        {...dynamicProps}
        onChange={$onChangeRaw}
        onClose={$onClose}
        ref={mergeRefs(externalRef, internalRef, controllerRef)}
        {...props}
        render={(_, ref) => (
          <Input
            className={className}
            hasError={hasError || error}
            icon={<CalendarIcon />}
            id={id}
            inputStyle={inputStyle}
            isClearable
            name={name}
            onClear={$onClear}
            placeholder={placeholder}
            ref={ref}
            {...(inputProps || {})}
          />
        )}
      />

      <ErrorMessage error={errorMessage ? { message: errorMessage } : error} />
    </>
  );
});

DateInput.displayName = 'DateInput';

DateInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  control: PropTypes.objectOf(PropTypes.any),
  value: PropTypes.any,
  defaultValue: PropTypes.any,
  rules: PropTypes.any,
  mode: PropTypes.oneOf([
    'single',
    'multiple',
    'range',
  ]),
  dateFormat: PropTypes.string,
  enableTime: PropTypes.bool,
  time24hr: PropTypes.bool,
  inputStyle: PropTypes.oneOf(['default', 'implicit']),
  errors: PropTypes.object,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  onChangeRaw: PropTypes.func,
  onClear: PropTypes.func,
  placeholder: PropTypes.string,
  options: PropTypes.objectOf(PropTypes.any),
  inputProps: PropTypes.objectOf(PropTypes.any),
  className: PropTypes.string,
  label: PropTypes.string,
};

DateInput.defaultProps = {
  id: undefined,
  name: undefined,
  control: undefined,
  value: undefined,
  defaultValue: undefined,
  rules: undefined,
  mode: 'single',
  dateFormat: undefined,
  enableTime: false,
  time24hr: true,
  inputStyle: undefined,
  errors: undefined,
  hasError: undefined,
  errorMessage: undefined,
  onOpen: () => {},
  onClose: undefined,
  onChange: undefined,
  onChangeRaw: undefined,
  onClear: undefined,
  placeholder: undefined,
  options: undefined,
  inputProps: undefined,
  className: undefined,
  label: undefined,
};

export {
  DateInput,
};
