import React, { memo } from 'react';

import { IconInner } from '../../IconInner';

const PlayerPauseIconInner = (props) => (
  <IconInner name="PlayerPauseIcon" viewBox="0 0 24 24" {...props}>
    <path d="M10 4H6v16h4V4Zm8 0h-4v16h4V4Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
  </IconInner>
);

export const PlayerPauseIcon = memo(PlayerPauseIconInner);
