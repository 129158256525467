/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';

import { GENDER_OPTIONS_STRICT, EMOTION_OPTIONS_STRICT, MASK_OPTIONS_STRICT, AGE_OPTIONS, TEMPERATURE_BY_GROUP_OPTIONS } from '@vlabs/api-bindings/src/constants';
import { routes } from '@vlabs/shared/navigation/routes';
import { selectListOptions } from '@vlabs/shared/selectors/listSelectors';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams, generatePath, useHistory } from 'react-router-dom';

import { selectHandlerOptions } from '@vlabs/pages/handlers/selectors';
import { selectSourceOptionsByAccount } from '@vlabs/pages/sources/selectors';

import NotificationForm from './NotificationForm';
import { defaultNotification, getNotification, updateNotification } from './store';

function NotificationPageComponent({ listOptions, camOptions, handlerOptions, onSubmitNotification }) {
  const history = useHistory();
  const { notificationId } = useParams();
  const [notification, setNotification] = useState(defaultNotification);

  useEffect(() => {
    if (notificationId !== 'create') {
      getNotification(notificationId).then(({ data }) => {
        setNotification(data);
      });
    }
  }, [notificationId]);

  const onSubmit = (data) => {
    onSubmitNotification(notificationId, data, () => history.push(generatePath(routes.notifications.all)));
  };

  return (
    <NotificationForm
      ageOptions={AGE_OPTIONS.raw}
      camOptions={camOptions}
      data={notification}
      emotionOptions={EMOTION_OPTIONS_STRICT.raw}
      genderOptions={GENDER_OPTIONS_STRICT.raw}
      handlerOptions={handlerOptions}
      listOptions={listOptions}
      maskOptions={MASK_OPTIONS_STRICT.raw}
      notificationId={notificationId}
      onSubmit={onSubmit}
      tagsOptions={TEMPERATURE_BY_GROUP_OPTIONS.raw}
    />
  );
}

NotificationPageComponent.propTypes = {
  listOptions: PropTypes.arrayOf(PropTypes.object),
  camOptions: PropTypes.arrayOf(PropTypes.object),
  handlerOptions: PropTypes.arrayOf(PropTypes.object),
  onSubmitNotification: PropTypes.func.isRequired,
};

NotificationPageComponent.defaultProps = {
  listOptions: [],
  camOptions: [],
  handlerOptions: [],
};

export const NotificationPage = connect((state) => ({
  handlerOptions: selectHandlerOptions(state),
  listOptions: selectListOptions(state),
  camOptions: selectSourceOptionsByAccount(state),
}), (dispatch) => ({
  onSubmitNotification: (id, data, fanc) => dispatch(updateNotification(id, data, fanc)),
}))(NotificationPageComponent);
