import React from 'react';

import { CircleCheckIcon } from '@vlabs/icons';
import { Page, Margin } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import st from './CreateHandlerForm.module.sass';

const CreatedHandlerForm = ({
  handlerId,
  handlerName,
}) => {
  const { t } = useTranslation();

  return (
    <Page className={st.Page}>
      <Margin display="block">
        <div className={st.HeaderContainer}>
          <CircleCheckIcon className={st.Icon} />
          <h5>
            {t('handlers:создание.успех.заголовок', { description: handlerName })}
          </h5>
        </div>
      </Margin>

      <Margin display="block">
        <div className="Body-2">
          <p>
            <Trans
              i18nKey="handlers:создание.успех.текст"
              values={{ handlerId }}
            />
          </p>
          <p>
            <Trans
              components={{
                a: <Link
                  data-testid="linkToHandler"
                  to={`/handlers/${handlerId}`}
                />,
              }}
              i18nKey="handlers:создание.успех.описание сценария"
            />
          </p>
        </div>
      </Margin>
    </Page>
  );
};

CreatedHandlerForm.propTypes = {
  handlerId: PropTypes.string,
  handlerName: PropTypes.string,
};

CreatedHandlerForm.defaultProps = {
  handlerId: undefined,
  handlerName: undefined,
};

export { CreatedHandlerForm };
