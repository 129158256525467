import React, { memo } from 'react';

import { IconInner } from '../../IconInner';

const MoonIconInner = (props) => (
  <IconInner name="MoonIcon" viewBox="0 0 24 24" {...props}>
    <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
  </IconInner>
);

export const MoonIcon = memo(MoonIconInner);
