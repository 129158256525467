import React, { useCallback } from 'react';

import { Fold } from '@vlabs/uikit';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useClearPolicyFilters } from '../hooks';
import GenericPolicySubForm from './GenericPolicySubForm';
import { TTLSelectField } from './TTLSelectField/TTLSelectField';

const FaceSamplePolicySubForm = () => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const faceSamplePolicyIsEnabled = useWatch({ name: 'faceSamplePolicy.isEnabled' });

  useClearPolicyFilters('faceSamplePolicy', {
    on: useCallback(() => faceSamplePolicyIsEnabled === false, [faceSamplePolicyIsEnabled]),
  });

  return (
    <Fold
      control={control}
      isOpen={faceSamplePolicyIsEnabled}
      name="faceSamplePolicy.isEnabled"
      switch
      testId="faceSamplePolicy"
      title={t('handlers:setup sections.save face image')}
    >
      <GenericPolicySubForm
        fieldName="faceSamplePolicy.filters"
        matchTitle={t('handlers:policies.storage_policy.face_sample_policy.match title')}
      >
        <TTLSelectField fieldName="faceSamplePolicy" />
      </GenericPolicySubForm>
    </Fold>
  );
};

export default FaceSamplePolicySubForm;
