import React, { useCallback } from 'react';

import { ButtonWithPermissions } from '@vlabs/shared/components/button-with-permissions/ButtonWithPermissions';
import { TableCellsWithPermissions } from '@vlabs/shared/components/table/cells/index';
import { TableBottomPanelWithPermissions } from '@vlabs/shared/components/table/TableBottomPanelWithPermissions';
import { permissions } from '@vlabs/shared/config';
import { routes } from '@vlabs/shared/navigation/routes';
import { actionColumnProps } from '@vlabs/shared/utils';
import { Page, Panel, Table, openConfirmPopup, useModal } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';

import { CreateDynamicHandlerFrom } from './forms/CreateDynamicHandlerFrom';
import { selectPageData } from './selectors';
import * as storeActions from './store';

function HandlerListPage({
  data,
  createHandler,
  deleteHandler,
  setPageSize,
  setPageIndex,
}) {
  const { t } = useTranslation();
  const modal = useModal();
  const history = useHistory();

  const onEditPolicy = ({ handlerId }) => {
    history.push(generatePath(routes.handlers.read, { handlerId }));
  };

  const onCreatePolicy = () => {
    history.push(generatePath(routes.handlers.create));
  };

  const onDeleteHandler = ({ description, handlerId }) => {
    const message = t('handlers:подтверждение.удаление.текст', {
      handler: description,
    });

    const onConfirm = async () => {
      await deleteHandler(handlerId);
    };

    openConfirmPopup({
      title: t('handlers:удаление сценария'),
      message,
      type: 'delete',
      onConfirm,
    });
  };

  const onCreateHandler = async (values) => {
    await createHandler(values);
    modal.close();
  };

  const extraButtons = useCallback(() => (
    <ButtonWithPermissions
      data-testid="table.createDynamicHandlerButton"
      onClick={modal.open}
      permissions={{ rules: [permissions.handler.creation] }}
    >
      {t('handlers:таблица.кнопки.добавить динамический')}
    </ButtonWithPermissions>
  ), [t, modal.open]);

  return (
    <Page className="Global__Table_fullScreen_wrapper">
      {modal.wrap(<CreateDynamicHandlerFrom
        onSubmit={onCreateHandler}
      />)}
      <Panel className="Global__Table_fullScreen">
        <Table
          columns={[
            { Header: t('handlers:описание'), accessor: 'description' },
            { Header: t('handlers:id сценария'), accessor: 'handlerId' },
            {
              Header: t('handlers:таблица.колонки.тип сценария'),
              accessor: 'isDynamic',
              Cell: ({ value }) => t('handlers:таблица.колонки.тип сценария', { context: value ? 'динамический' : 'статический' }),
            },
            actionColumnProps({
              id: 'action2',
              Cell: TableCellsWithPermissions.EditCell,
            }),
            actionColumnProps({
              id: 'action3',
              Cell: TableCellsWithPermissions.DeleteCell,
            }),
          ]}
          CustomBottomPanel={TableBottomPanelWithPermissions}
          data={data.data}
          extraButtons={extraButtons}
          meta={data.meta}
          onActions={{
            onAddRow: {
              handler: onCreatePolicy,
              label: t('handlers:таблица.кнопки.добавить статический'),
              permissions: { rules: [permissions.handler.creation] },
            },
            onEditRow: { handler: onEditPolicy },
            onDeleteRow: { handler: onDeleteHandler, permissions: { rules: [permissions.handler.deletion] } },
          }}
          pageIndex={data.pageIndex}
          pageSize={data.pageSize}
          pageSizeOptions={data.pageSizeOptions}
          paginationType="offsetBased"
          setPageIndex={setPageIndex}
          setPageSize={setPageSize}
        />
      </Panel>
    </Page>
  );
}

HandlerListPage.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
  createHandler: PropTypes.func.isRequired,
  deleteHandler: PropTypes.func.isRequired,
  setPageSize: PropTypes.func.isRequired,
  setPageIndex: PropTypes.func.isRequired,
};

HandlerListPage.defaultProps = {
  data: {},
};

export default connect(
  (state) => ({
    data: selectPageData(state),
  }),
  (dispatch) => ({
    createHandler: (data) => dispatch(storeActions.submitHandlerData(undefined, data)),
    deleteHandler: (id) => dispatch(storeActions.deleteHandler(id)),
    setPageSize: (pageSize) => dispatch(storeActions.updatePageSize({ pageSize })),
    setPageIndex: (pageIndex) => dispatch(storeActions.updatePage({ pageIndex })),
  }),
)(HandlerListPage);
