/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import formatISO from 'date-fns/formatISO';
import parseISO from 'date-fns/parseISO';
import _ from 'lodash';
import qs from 'qs';

const isDropdownObj = ({ value, label }) => value && label;

export const filterEmptyKeys = (obj) => {
  // хак для "глубокой" копии объекта
  const res = JSON.parse(JSON.stringify(obj));

  const dangerousFilter = (value) => {
    Object.entries(value).forEach(([k, v]) => {
      if (typeof v === 'object' && v !== null) {
        dangerousFilter(v);
        if (Object.keys(v).length === 0 && !(v instanceof Date)) delete value[k];
      }
      if (Array.isArray(v) && v.length === 0) delete value[k];
      if (typeof v === 'string' && v.length === 0) delete value[k];
      if (v === undefined || v === null) delete value[k];
      if (Array.isArray(v) && v.length > 0 && v.every(isDropdownObj)) {
        value[k] = v.map(({ value: val }) => val);
      }
    });
    if (Object.keys(res).length === 0) return undefined;
    return res;
  };

  return dangerousFilter(res);
};

export const paramsSerializer = (initialParams) => {
  const params = filterEmptyKeys(initialParams);
  return qs.stringify(params, {
    arrayFormat: 'comma',
    serializeDate: formatISO,
  });
};

export const pipe = (...args) => _.flow([...args]);
export const CASTS = {
  NOOP: (v) => v,
  STRING: (v) => (v === undefined || v === null ? v : String(v)),
  NUMBER: (v) => (Number.isNaN(Number(v) || v === '') ? undefined : Number(v)),
  INTEGER: (v) => (Number.isNaN(parseInt(v, 10)) ? undefined : parseInt(v, 10)),
  FLOAT: parseFloat,
  BOOLEAN: Boolean,
  BOOLEANINT: Number,
  OPTIONCONST: (proxy) => (v) => {
    if (v === null) return null;
    if (Array.isArray(v)) return v.map((el) => proxy[el]);
    return proxy[v];
  },
  ONEOF: (...options) => (v) => {
    if (v === undefined) return undefined;
    if (!options.includes(v)) {
      throw Error(`ONEOF Cast error: ${v} is not allowed in ${options.join(', ')}`);
    }
    return v;
  },
  ANYOF: (...options) => (array) => {
    if (!Array.isArray(array)) return [];
    array.forEach((v) => {
      if (!options.includes(v)) {
        throw Error(`ONEOF Cast error: ${v} is not allowed in ${options.join(', ')}`);
      }
    });
    return array;
  },
  OPTIONVALUE: (option) => option?.value,
  OPTIONFROMVALUE: (value) => (
    value === undefined || value === null
      ? undefined
      : ({ value, label: value })
  ),
  OPTIONARRAYVALUE: (options) => (
    Array.isArray(options) && options.length > 0
      ? options.map(({ value }) => value)
      : undefined
  ),
  DATE: {
    fromISOString: parseISO,
    toISOString: formatISO,
  },
  ARRAY: {
    fromString: (arr) => {
      if (typeof arr !== 'string') {
        console.error(`ARRAY.fromString requires "string" type, given: ${arr}(${typeof arr})`);
      }
      return arr.split(',').map((v) => v.trim());
    },
    fromOptions: (arr) => arr.map(({ value }) => value),
    toInt: (arr) => arr.map(CASTS.INTEGER),
    toFloat: (arr) => arr.map(CASTS.FLOAT),
  },
  UNDEFYIFEMPTY: (value) => {
    if (typeof value === 'object' || Array.isArray(value)) {
      return _.every(value, (item) => {
        if (_.isNumber(item)) return false;
        return _.isEmpty(item);
      }) ? undefined : value;
    }
    if (typeof value === 'string') {
      return value === '' ? undefined : value;
    }
    return value === undefined || value === null ? undefined : value;
  },

  NESTED: (schema, {
    after = undefined,
  } = {}) => ({
    ...schema,
    ...(after && { __after: [...(schema.__after ?? []), after] }),
  }),
};

export const get = (schema, cast, ...args) => (object) => {
  const caster = (value) => {
    if (value === undefined || value === null) return args.length === 1 ? args[0] : undefined;
    if (typeof cast === 'string') {
      if (cast === 'string') return String(value);
      if (cast === 'boolean') return Boolean(value);
    }
    return cast(value);
  };

  const value = _.get(object, schema);
  const casted = caster(value);
  if (casted === undefined) return args.length === 1 ? args[0] : undefined;
  return casted;
};

export const getArray = (arrayPath, conditional, ...args) => (object) => {
  const items = get(arrayPath, CASTS.NOOP)(object);
  const itemCount = items?.length;
  const defaultValue = args.length ? args[0] : [];

  if (!itemCount) return defaultValue;

  const schemas = [];
  for (let i = 0; i < itemCount; i += 1) {
    const subSchema = conditional(i, items);
    if (subSchema !== undefined) schemas.push(subSchema);
  }

  schemas.__uncalled = true;
  return schemas;
};

export const createMapSchema = (schema) => (obj) => {
  const mapper = (partialSchema) => {
    let result = {};
    Object.entries(partialSchema).forEach(([k, v]) => {
      if (k.startsWith('__')) return;
      if (typeof v === 'function') {
        let _result = v;
        while (typeof _result === 'function') {
          _result = _result(obj);
        }

        if (_result?.__uncalled) _result = _result.map(mapper);
        result[k] = _result;
      }
      if (typeof v === 'object' && !Array.isArray(v) && v !== null) {
        result[k] = mapper(v);
      }
    });

    if (!_.isEmpty(partialSchema.__after)) {
      partialSchema.__after.forEach((f) => { result = f(result); });
    }

    return result;
  };
  return mapper(schema);
};

export const versionGetter = {
  get(target, prop) {
    const [major] = prop.split('.');
    const key = Object.keys(target).find((version) => {
      const depth = String(version).match(/\./)?.length ?? 0;
      if (depth === 0 && major === version) return true;
      return false;
    });

    if ('_' in target && key === undefined) return target._[prop];
    return target[key] ?? target._;
  },
};

export const setSessionObject = (sessionKey, data) => {
  sessionStorage.setItem(sessionKey, JSON.stringify(data));
};

export const getSessionObject = (sessionKey) => {
  const data = sessionStorage.getItem(sessionKey);
  if (!data) return undefined;
  return JSON.parse(data);
};
