import { createSelector } from '@reduxjs/toolkit';

export const selectListsSlice = (state) => state.lists;
export const selectListsState = ({ lists: { state } }) => state;

export const selectLists = createSelector(
  selectListsSlice,
  ({ byId, allIds }) => ({ byId, allIds }),
);

export const selectListById = (listId) => createSelector(
  selectListsSlice,
  (lists) => lists?.byId?.[listId] || null,
);

export const selectListOptions = (state) => state.lists.listOptions;
