import React, { memo } from 'react';

import { IconInner } from '../../IconInner';

const FileTextIconInner = (props) => (
  <IconInner name="FileTextIcon" viewBox="0 0 24 24" {...props}>
    <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8l-6-6Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
    <path d="M14 2v6h6m-4 5H8m8 4H8m2-8H8" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
  </IconInner>
);

export const FileTextIcon = memo(FileTextIconInner);
