import React from 'react';

import { FiltersSubForm } from '@vlabs/pages/handlers/forms';
import StepTitle from '@vlabs/shared/legacy-components/steps/StepTitle';
import { Step } from '@vlabs/uikit';
import i18next from 'i18next';

import st from './shared.module.sass';

function StepFilters() {
  return (
    <Step
      className={st.Step}
      title={(
        <StepTitle>
          {i18next.t('handlers:необходимо ли фильтровать изображения?')}
        </StepTitle>
      )}
    >
      <FiltersSubForm />
    </Step>
  );
}

StepFilters.fieldNames = [
  'filters',
];

export { StepFilters };
