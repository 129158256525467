import React, { memo } from 'react';

import { IconInner } from '../../IconInner';

const SquarePlusIconInner = (props) => (
  <IconInner name="SquarePlusIcon" viewBox="0 0 24 24" {...props}>
    <path d="M19 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2Zm-7 5v8m-4-4h8" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
  </IconInner>
);

export const SquarePlusIcon = memo(SquarePlusIconInner);
