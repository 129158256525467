import { createSlice } from '@reduxjs/toolkit';
import { apiContainer } from '@vlabs/api-bindings';
import i18next from 'app/translations/i18n';
import { toast } from 'react-toastify';

import { selectAccountEmail } from '@vlabs/pages/auth/selectors';

import { SLICE_NAME } from '.';

const initialState = {
  pageIndex: 0,
  pageSize: 25,
  pageSizeOptions: [10, 25, 50, 100],
  pageData: [],
  planOptions: [],
  lunaSourcesOptions: [],
};

const store = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setPageData(state, { payload }) {
      state.pageData = payload;
    },
    setPage(state, { payload: { pageIndex } }) {
      if (pageIndex !== undefined) state.pageIndex = pageIndex;
    },
    setPageSize(state, { payload: { pageSize } }) {
      if (pageSize !== undefined) state.pageSize = pageSize;
    },
    setPlanOptions(state, { payload }) {
      state.planOptions = payload.map((plan) => ({ label: plan.name, value: plan.id }));
    },
    setLunaSourcesOptions(state, { payload }) {
      state.lunaSourcesOptions = payload;
    },
  },
});

export default store.reducer;

export const fetchLunaSourcesOptions = async (dispatch) => {
  const lunaSources = await apiContainer.lunaClient.events.getStatistic();
  dispatch(store.actions.setLunaSourcesOptions(lunaSources));
};

export const fetchPlansOptions = async (dispatch) => {
  const plans = await apiContainer.vaReporterClient.plans.getAll();
  dispatch(store.actions.setPlanOptions(plans));
};

export const fetchReports = async (dispatch, getState) => {
  const {
    [SLICE_NAME]: { pageIndex, pageSize },
  } = getState();

  try {
    const reports = await apiContainer.vaReporterClient.reports
      .getAll({ page: pageIndex + 1, page_size: pageSize });

    dispatch(store.actions.setPageData(reports));
  } catch (error) {
    dispatch(store.actions.setPageData([]));
    throw error;
  }
};

export const createReport = (params) => async (dispatch, getState) => {
  const author = selectAccountEmail(getState());

  await apiContainer.vaReporterClient.reports.create({ ...params, author });
  dispatch(fetchReports);
  toast.success(i18next.t('reports:api.успех.создание'));
};

export const deleteReport = (id) => async (dispatch) => {
  await apiContainer.vaReporterClient.reports.delete(id);
  toast.success(i18next.t('reports:api.успех.удаление'));

  dispatch(fetchReports);
};

export const setPageSize = (params) => async (dispatch) => {
  dispatch(store.actions.setPageSize(params));
  dispatch(fetchReports);
};

export const setPage = (params) => async (dispatch) => {
  dispatch(store.actions.setPage(params));
  dispatch(fetchReports);
};
