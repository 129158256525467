import { optionsProxy } from '@vlabs/uikit';
import i18n from 'i18next';

export const STREAM_TYPE_OPTIONS = optionsProxy.create([
  { value: 'udp', label: i18n.t('lunaStreams:константы.stream type.udp') },
  { value: 'tcp', label: i18n.t('lunaStreams:константы.stream type.tcp') },
  { value: 'videofile', label: i18n.t('lunaStreams:константы.stream type.videofile') },
  { value: 'images', label: i18n.t('lunaStreams:константы.stream type.images') },
]);

export const SENDING_TYPE_OPTIONS = optionsProxy.create([
  { value: 'sec', label: i18n.t('lunaStreams:константы.sending type.sec') },
  { value: 'frames', label: i18n.t('lunaStreams:константы.sending type.frames') },
]);

export const FRAME_PROCESSING_MODE_OPTIONS = optionsProxy.create([
  { value: 'auto', label: i18n.t('lunaStreams:константы.frame processing mode.auto') },
  { value: 'full', label: i18n.t('lunaStreams:константы.frame processing mode.full') },
  { value: 'scale', label: i18n.t('lunaStreams:константы.frame processing mode.scale') },
]);

export const LIVENESS_MODE_OPTIONS = optionsProxy.create([
  { value: 0, label: i18n.t('lunaStreams:константы.liveness mode.0') },
  { value: 1, label: i18n.t('lunaStreams:константы.liveness mode.1') },
  { value: 2, label: i18n.t('lunaStreams:константы.liveness mode.2') },
]);

export const STREAM_STATUS_OPTIONS = optionsProxy.create([
  { value: 'pending', label: i18n.t('lunaStreams:константы.stream status.pending') },
  { value: 'in_progress', label: i18n.t('lunaStreams:константы.stream status.in_progress') },
  { value: 'done', label: i18n.t('lunaStreams:константы.stream status.done') },
  { value: 'pause', label: i18n.t('lunaStreams:константы.stream status.pause') },
  { value: 'restart', label: i18n.t('lunaStreams:константы.stream status.restart') },
  { value: 'cancel', label: i18n.t('lunaStreams:константы.stream status.cancel') },
  { value: 'failure', label: i18n.t('lunaStreams:константы.stream status.failure') },
  { value: 'handler_lost', label: i18n.t('lunaStreams:константы.stream status.handler_lost') },
  { value: 'not_found', label: i18n.t('lunaStreams:константы.stream status.not_found') },
  { value: 'deleted', label: i18n.t('lunaStreams:константы.stream status.deleted') },
]);

export const CREATE_STREAM_STATUS_OPTIONS = optionsProxy.create([
  { value: 'pending', label: i18n.t('lunaStreams:константы.stream status.pending') },
  { value: 'pause', label: i18n.t('lunaStreams:константы.stream status.pause') },
]);

export const ROTATION_OPTIONS = optionsProxy.create([
  { value: 0, label: '0' },
  { value: 90, label: '90' },
  { value: 180, label: '180' },
  { value: 270, label: '270' },
]);
