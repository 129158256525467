import React, { memo } from 'react';

import { IconInner } from '../../IconInner';

const MapIconInner = (props) => (
  <IconInner name="MapIcon" viewBox="0 0 24 24" {...props}>
    <path d="M1 6v16l7-4 8 4 7-4V2l-7 4-8-4-7 4Zm7-4v16m8-12v16" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
  </IconInner>
);

export const MapIcon = memo(MapIconInner);
