import React, { memo } from 'react';

import { IconInner } from '../../IconInner';

const ChevronDoubleUpIconInner = (props) => (
  <IconInner name="ChevronDoubleUpIcon" viewBox="0 0 24 24" {...props}>
    <path d="m17 11-5-5-5 5m10 7-5-5-5 5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
  </IconInner>
);

export const ChevronDoubleUpIcon = memo(ChevronDoubleUpIconInner);
