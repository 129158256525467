import { useEffect, useState, useCallback } from 'react';

import axios from 'axios';

export const useTableHook = (onFetch, params = {}) => {
  const [fetchData, setFetchData] = useState(undefined);
  const [paginationType, setPaginationType] = useState(params.paginationType);
  const [pageIndex, setPageIndex] = useState(undefined);
  const [since, setSince] = useState(undefined);
  const [until, setUntil] = useState(undefined);
  const [pageSize, setPageSize] = useState(params.pageSize || 25);
  const [filters, setFilters] = useState([]);
  const pageSizeOptions = [10, 25, 50, 100];
  const [cancelToken, setCancelToken] = useState(undefined);
  const onFetchHandler = () => {
    if (cancelToken) cancelToken.cancel();
    const { CancelToken } = axios;
    setCancelToken(CancelToken.source());
  };

  useEffect(() => {
    onFetchHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onFetch, pageIndex, since, until, pageSize, filters]);

  useEffect(() => {
    if (!cancelToken) return;
    const flatFilters = {};
    filters.forEach(({ id, value }) => {
      if (Array.isArray(value)) {
        flatFilters[id] = value;
      } else if (typeof value === 'object') {
        flatFilters[id] = value.value;
      } else {
        flatFilters[id] = value;
      }
    });
    const pageParams = { pageSize };
    if (paginationType === 'offsetBased') {
      pageParams.page = pageIndex;
    } else if (paginationType === 'timeBased') {
      const { timePaginationField } = params;
      if (since) pageParams.since = since[timePaginationField];
      if (until) pageParams.until = until[timePaginationField];
    }
    onFetch({ ...pageParams, ...flatFilters }, cancelToken.token)
      .then((data) => {
        setFetchData(data);
        setCancelToken(undefined);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancelToken]);

  useEffect(() => () => {
    if (cancelToken) cancelToken.close();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onReset = useCallback(() => {
    setFetchData(undefined);
    setPageIndex(0);
    setFilters([]);
    if (cancelToken) cancelToken.cancel();
    setCancelToken(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    ...fetchData,
    pageIndex,
    setPageIndex,
    pageSize,
    setPageSize,
    paginationType,
    setPaginationType,
    setSince,
    setUntil,
    filters,
    setFilters,
    pageSizeOptions,
    onFetch: onFetchHandler,
    onReset,
  };
};
