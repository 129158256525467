import React, { memo } from 'react';

import { IconInner } from '../../IconInner';

const CameraOffIconInner = (props) => (
  <IconInner name="CameraOffIcon" viewBox="0 0 24 24" {...props}>
    <g clipPath="url(#a)"><path d="m1 1 22 22M9 3h6l2 3h4a2 2 0 0 1 2 2v9.34m-7.72-2.06a4 4 0 1 1-5.56-5.56M21 21H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h3l15 15Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" /></g>
  </IconInner>
);

export const CameraOffIcon = memo(CameraOffIconInner);
