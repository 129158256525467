import React from 'react';

import { TEMPERATURE_BY_GROUP_OPTIONS } from '@vlabs/api-bindings/src/constants';
import { START_OF_DAY, END_OF_DAY } from '@vlabs/shared/config';
import SelectOptionGroupLabel from '@vlabs/shared/legacy-components/select-option-group-label/SelectOptionGroupLabel';
import validate from '@vlabs/shared/validators';
import { Control, Divider } from '@vlabs/uikit';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export function CommonEventFilterForm({ camOptions, streamIdsOptions, handlerOptions }) {
  const { t } = useTranslation();
  const { register, control, formState: { errors } } = useFormContext();

  return (
    <>
      <Control.DateInput
        control={control}
        enableTime
        id="create_time__gte"
        label={t('дата события от')}
        name="create_time__gte"
        options={START_OF_DAY}
      />
      <Divider small />

      <Control.DateInput
        control={control}
        enableTime
        id="create_time__lt"
        label={t('дата события до')}
        name="create_time__lt"
        options={END_OF_DAY}
      />
      <Divider small />

      <Control.Select
        control={control}
        formatGroupLabel={SelectOptionGroupLabel}
        id="sources"
        isClearable
        isCreatable
        isMulti
        label={t('source')}
        name="sources"
        options={camOptions}
      />
      <Divider small />

      <Control.Select
        control={control}
        errors={errors}
        id="stream_ids"
        isClearable
        isCreatable
        isMulti
        label={t('video stream')}
        name="stream_ids"
        options={streamIdsOptions}
        rules={{ validate: validate.uuidOptions }}
      />
      <Divider small />

      <Control.Select
        control={control}
        errors={errors}
        id="handler_ids"
        isClearable
        isCreatable
        isMulti
        label={t('сценарии')}
        name="handler_ids"
        options={handlerOptions}
        rules={{ validate: validate.uuidOptions }}
      />
      <Divider small />

      <Control.Select
        control={control}
        formatGroupLabel={SelectOptionGroupLabel}
        id="tags"
        isClearable
        isCreatable
        isMulti
        label={t('tags')}
        name="tags"
        options={TEMPERATURE_BY_GROUP_OPTIONS.raw}
      />
      <Divider small />

      <Control.Input
        id="event_ids"
        label={t('id событий')}
        {...register('event_ids', { validate: validate.multipleUuid() })}
        errors={errors}
      />
      <Divider small />

      <Control.Input
        id="external_ids"
        label={t('внешние id событий')}
        {...register('external_ids', {
          validate: validate.multipleExternalId(),
        })}
        errors={errors}
      />
    </>
  );
}
