import React from 'react';

import { AGE_OPTIONS } from '@vlabs/api-bindings/src/constants';
import { EVENT_ENUMS } from '@vlabs/api-bindings/src/luna-client/constants';
import { Control, Divider } from '@vlabs/uikit';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export function BodyAttributesFilterForm() {
  const { t } = useTranslation();
  const { control } = useFormContext();

  return (
    <>
      <Control.Select
        control={control}
        id="upper_clothing_colors"
        isClearable
        isMulti
        label={t('events:upper_clothing_colors')}
        name="upper_clothing_colors"
        options={EVENT_ENUMS.UPPER_BODY.UPPER_CLOSING_COLOR.raw}
      />
      <Divider small />

      <Control.Select
        control={control}
        id="lower_garment_types"
        isMulti
        label={t('events:bottom_type')}
        name="lower_garment_types"
        options={EVENT_ENUMS.LOWER_BODY.LOWER_GARMENT.TYPE.raw}
      />
      <Divider small />

      <Control.Select
        control={control}
        id="lower_garment_colors"
        isMulti
        label={t('events:bottom_colors')}
        name="lower_garment_colors"
        options={EVENT_ENUMS.LOWER_BODY.LOWER_GARMENT.COLORS.raw}
      />
      <Divider small />

      <Control.Select
        control={control}
        id="shoes_apparent_colors"
        isMulti
        label={t('events:shoes_colors')}
        name="shoes_apparent_colors"
        options={EVENT_ENUMS.LOWER_BODY.SHOES.APPARENT_COLOR.raw}
      />
      <Divider small />

      <Control.Select
        control={control}
        id="headwear_states"
        isClearable
        isMulti
        label={t('events:headwear')}
        name="headwear_states"
        options={EVENT_ENUMS.UPPER_BODY.HEADWEAR.raw}
      />
      <Divider small />

      <Control.Select
        control={control}
        id="headwear_apparent_colors"
        isMulti
        label={t('events:headwear_colors')}
        name="headwear_apparent_colors"
        options={EVENT_ENUMS.UPPER_BODY.HEADWEAR_COLORS.raw}
      />
      <Divider small />

      <Control.Select
        control={control}
        id="backpack_states"
        isClearable
        isMulti
        label={t('events:backpack')}
        name="backpack_states"
        options={EVENT_ENUMS.ACCESSORIES.BACKPACK.raw}
      />
      <Divider small />

      <Control.Select
        control={control}
        id="sleeve_lengths"
        isClearable
        isMulti
        label={t('events:sleeve')}
        name="sleeve_lengths"
        options={EVENT_ENUMS.UPPER_BODY.SLEEVE.raw}
      />
      <Divider small />

      <Control.Select
        control={control}
        id="apparent_gender"
        isClearable
        isMulti
        label={t('events:body basic attributes.apparent gender')}
        name="apparent_gender"
        options={EVENT_ENUMS.BODY_BASIC_ATTRIBUTES.APPARENT_GENDER.raw}
      />
      <Divider small />

      <Control.Select
        control={control}
        id="apparent_age"
        isClearable
        label={t('events:body basic attributes.apparent age')}
        name="apparent_age"
        options={AGE_OPTIONS.raw}
      />
      <Divider small />
    </>
  );
}
