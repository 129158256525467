import React from 'react';

import validate from '@vlabs/shared/validators';
import { Control, Divider } from '@vlabs/uikit';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import st from './FilterForms.module.sass';

export function GeolocationFilterForm() {
  const { t } = useTranslation();
  const { register, watch, formState: { errors } } = useFormContext();

  const longitude = watch('origin_longitude');
  const longitudelDelta = watch('longitude_delta');
  const latitude = watch('origin_latitude');
  const latitudeDelta = watch('latitude_delta');

  return (
    <>
      <Control.Input
        id="cities"
        label={t('геопозиция.город')}
        {...register('cities', {
          maxLength: validate.maxLength(36),
        })}
        errors={errors}
      />
      <Divider small />

      <Control.Input
        id="areas"
        label={t('геопозиция.область')}
        {...register('areas', {
          maxLength: validate.maxLength(36),
        })}
        errors={errors}
      />
      <Divider small />

      <Control.Input
        id="districts"
        label={t('геопозиция.район')}
        {...register('districts', {
          maxLength: validate.maxLength(36),
        })}
        errors={errors}
      />
      <Divider small />

      <Control.Input
        id="streets"
        label={t('геопозиция.улица')}
        {...register('streets', {
          maxLength: validate.maxLength(36),
        })}
        errors={errors}
      />
      <Divider small />

      <Control.Input
        id="house_numbers"
        label={t('геопозиция.номер дома')}
        {...register('house_numbers', {
          maxLength: validate.maxLength(36),
        })}
        errors={errors}
      />
      <Divider small />

      <div className={st.flex}>
        <div className={st.width}>
          <Control.Input
            id="origin_longitude"
            label={t('долгота (-180...180)')}
            {...register('origin_longitude', {
              min: validate.gte(-180),
              max: validate.lte(180),
              validate: {
                number: validate.number(),
                validateLatitude: (value) => {
                  if (!value && latitude) {
                    return validate.required();
                  }
                  return true;
                },
                validateDelta: (value) => {
                  if (!value && longitudelDelta) {
                    return validate.required();
                  }
                  return true;
                },
              } })}
            errors={errors}
          />
        </div>
        <Divider small />
        <div className={st.width}>
          <Control.Input
            id="longitude_delta"
            label={t('погрешность (0...90)')}
            {...register('longitude_delta', {
              min: validate.gte(0),
              max: validate.lte(90),
              validate: validate.number(),
            })}
            errors={errors}
          />
        </div>
      </div>
      <Divider small />

      <div className={st.flex}>
        <div className={st.width}>
          <Control.Input
            id="origin_latitude"
            label={t('широта (-90...90)')}
            {...register('origin_latitude', {
              min: validate.gte(-90),
              max: validate.lte(90),
              validate: {
                number: validate.number(),
                validateLongitude: (value) => {
                  if (!value && longitude) {
                    return validate.required();
                  }
                  return true;
                },
                validateDelta: (value) => {
                  if (!value && latitudeDelta) {
                    return validate.required();
                  }
                  return true;
                },
              } })}
            errors={errors}
          />
        </div>
        <Divider small />
        <div className={st.width}>
          <Control.Input
            id="latitude_delta"
            label={t('погрешность (0...90)')}
            {...register('latitude_delta', {
              min: validate.gte(0),
              max: validate.lte(90),
              validate: validate.number(),
            })}
            errors={errors}
          />
        </div>
      </div>
    </>
  );
}
