import React, { useCallback } from 'react';

import { apiContainer } from '@vlabs/api-bindings';
import { RefreshRightIcon } from '@vlabs/icons';
import {
  Control,
} from '@vlabs/uikit';
import { useTranslation } from 'react-i18next';

export const RefreshFaceCountCell = (({ listId: list_id, setFaceCount }) => {
  const { t } = useTranslation();

  const handleClick = useCallback(async (e) => {
    e.stopPropagation();
    const { statusText, data: { faces_count } } = await apiContainer.lunaClient.faces.count({ list_id });
    if (statusText === 'OK' && setFaceCount) {
      setFaceCount((prevState) => ({ ...prevState, [list_id]: faces_count }));
    }
  }, [list_id, setFaceCount]);

  return (
    <Control.RoundButton
      data-testid="faceCountButton"
      icon={<RefreshRightIcon />}
      kind="primary"
      onClick={(e) => handleClick(e)}
      title={t('lists:таблица.кнопки.узнать количество лиц')}
      variant="dimmed"
    />
  );
});
