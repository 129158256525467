import React from 'react';

import { selectListOptions } from '@vlabs/shared/selectors/listSelectors';
import validate from '@vlabs/shared/validators';
import { Control, Divider } from '@vlabs/uikit';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

const DefaultFaceFormFieldsComponent = ({
  listOptions,
}) => {
  const { t } = useTranslation();

  const { form } = useFormContext();
  return (
    <>
      <Control.Textarea
        id="user_data"
        label={t('information')}
        rows={2}
        {...form.register('user_data', {
          maxLength: validate.maxLength(128),
        })}
        errors={form.formState.errors}
      />
      <Divider small />

      <Control.Input
        id="external_id"
        label={t('external id')}
        {...form.register('external_id', {
          maxLength: validate.maxLength(36),
        })}
        errors={form.formState.errors}
      />
      <Divider small />

      <Control.Select
        control={form.control}
        errors={form.formState.errors}
        id="lists"
        isMulti
        label={t('списки')}
        name="lists"
        options={listOptions}
      />
    </>
  );
};

export const DefaultFaceFormFields = connect(
  (state) => ({
    listOptions: selectListOptions(state),
  }),
)(DefaultFaceFormFieldsComponent);
