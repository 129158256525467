import React from 'react';

import { PlayerStopInCircleIcon } from '@vlabs/icons';
import { Control } from '@vlabs/uikit';
import i18n from 'i18next';

export const CancelCell = ({
  onCancelHandler,
  row,
}) => {
  const { original: { task_status } } = row;
  if (![0, 1].includes(task_status)) return null;
  return (
    <div title={i18n.t('tasks:подтверждение.остановка задачи.title')}>
      <Control.RoundButton
        data-testid="cancelCell"
        icon={<PlayerStopInCircleIcon />}
        kind="primary"
        onClick={() => onCancelHandler(row.original)}
        variant="dimmed"
      />
    </div>
  );
};
