import React from 'react';

import {
  FaceSamplePolicySubForm,
  AttributesPolicySubForm,
  FacePolicySubForm,
  EventPolicySubForm,
  NotificationPolicySubForm,
  ImageOriginPolicySubForm,
  BodySamplePolicySubForm,
} from '@vlabs/pages/handlers/forms';
import StepTitle from '@vlabs/shared/legacy-components/steps/StepTitle';
import { Step } from '@vlabs/uikit';
import cn from 'classnames';
import i18next from 'i18next';

import st from './shared.module.sass';

function StepStoragePolicies() {
  return (
    <Step
      className={cn(st.Step, st.Step_hollow, st.Step_sparse)}
      title={(
        <StepTitle className={st.StepTitle_separate}>
          {i18next.t('handlers:сохранять данные о лице, теле и атрибутах?')}
        </StepTitle>
      )}
    >
      <FaceSamplePolicySubForm />
      <BodySamplePolicySubForm />
      <AttributesPolicySubForm />
      <ImageOriginPolicySubForm />
      <FacePolicySubForm />
      <EventPolicySubForm />
      <NotificationPolicySubForm />
    </Step>
  );
}

StepStoragePolicies.fieldNames = [
  'faceSamplePolicy',
  'notificationPolicy',
  'eventPolicy',
  'faceToListPolicy',
  'facePolicy',
  'attributesPolicy',
];

export { StepStoragePolicies };
