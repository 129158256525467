import React, { memo } from 'react';

import { IconInner } from '../../IconInner';

const ServerIconInner = (props) => (
  <IconInner name="ServerIcon" viewBox="0 0 24 24" {...props}>
    <path d="M20 2H4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2Zm0 12H4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2ZM6 6h.01M6 18h.01" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
  </IconInner>
);

export const ServerIcon = memo(ServerIconInner);
