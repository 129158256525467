import { parseISO } from 'date-fns';

import { Schema, get } from '../../json-transform';
import { Token } from '../constants';

export const permissionsSchema = new Schema({
  face: get('face').asArrayOfOptions(Token.Permissions.crudm),
  list: get('list').asArrayOfOptions(Token.Permissions.crud),
  event: get('event').asArrayOfOptions(Token.Permissions.crm),
  attribute: get('attribute').asArrayOfOptions(Token.Permissions.crudm),
  handler: get('handler').asArrayOfOptions(Token.Permissions.crud),
  verifier: get('verifier').asArrayOfOptions(Token.Permissions.crud),
  task: get('task').asArrayOfOptions(Token.Permissions.crud),
  face_sample: get('face_sample').asArrayOfOptions(Token.Permissions.crd),
  body_sample: get('body_sample').asArrayOfOptions(Token.Permissions.crd),
  image: get('image').asArrayOfOptions(Token.Permissions.crd),
  object: get('object').asArrayOfOptions(Token.Permissions.crd),
  token: get('token').asArrayOfOptions(Token.Permissions.crud),
  resources: get('resources').asArrayOfOptions(Token.Permissions.resources),
  emit_events: {
    allowed: get('emit_events.allowed').asInt().ifNaN(undefined),
    white_list: get('emit_events.white_list').ifNull([]).asArrayOfOptions(),
    black_list: get('emit_events.black_list').ifNull([]).asArrayOfOptions(),
  },
  lambdas: get('lambdas').asArrayOfOptions(Token.Permissions.crud),
});

export const getToken = new Schema({
  account_id: get('account_id').asString(),
  description: get('description').asString(),
  expiration_time: get('expiration_time').switch(
    (v) => (v === null ? 'null' : typeof v),
    {
      null: () => null,
      string: (v) => parseISO(v),
    },
  ),
  permissions: get('permissions').asSchema(permissionsSchema),
  permissionsRaw: get('permissions'),
  visibility_area: get('visibility_area').asOption(Token.VisibilityArea),
  token: get('token').asString(),
  token_id: get('token_id').asString(),
  create_time: get('create_time').asDate(),
  last_update_time: get('last_update_time').asDate(),
});
