import { optionsProxy } from '@vlabs/uikit';
import i18n from 'i18next';

export const FACE_QUALITY_IMAGE_FORMAT = optionsProxy.create([
  { value: 'JPEG', label: i18n.t('constants:image format.JPEG') },
  { value: 'JPEG2000', label: i18n.t('constants:image format.JPEG2000') },
  { value: 'PNG', label: i18n.t('constants:image format.PNG') },
]);

export const FACE_QUALITY_GLASSES = optionsProxy.create([
  { value: 'no_glasses', label: i18n.t('constants:glasses.no_glasses') },
  { value: 'eyeglasses', label: i18n.t('constants:glasses.eyeglasses') },
  { value: 'sunglasses', label: i18n.t('constants:glasses.sunglasses') },
]);

export const FACE_QUALITY_SHOULDERS_POSITION = optionsProxy.create([
  { value: 'parallel', label: i18n.t('constants:shoulders.parallel') },
  { value: 'hidden', label: i18n.t('constants:shoulders.hidden') },
  { value: 'non-parallel', label: i18n.t('constants:shoulders.non-parallel') },
]);

export const FACE_QUALITY_EYE = optionsProxy.create([
  { value: 'open', label: i18n.t('constants:eye.open') },
  { value: 'occluded', label: i18n.t('constants:eye.occluded') },
  { value: 'closed', label: i18n.t('constants:eye.closed') },
]);

export const FACE_QUALITY_EYEBROWS_STATE = optionsProxy.create([
  { value: 'neutral', label: i18n.t('constants:eyebrows state.neutral') },
  { value: 'squinting', label: i18n.t('constants:eyebrows state.squinting') },
  { value: 'frowning', label: i18n.t('constants:eyebrows state.frowning') },
  { value: 'raised', label: i18n.t('constants:eyebrows state.raised') },
]);

export const FACE_QUALITY_HEADWEAR_TYPE = optionsProxy.create([
  { value: 'none', label: i18n.t('constants:headwear type.none') },
  { value: 'baseball_cap', label: i18n.t('constants:headwear type.baseball_cap') },
  { value: 'beanie', label: i18n.t('constants:headwear type.beanie') },
  { value: 'peaked_cap', label: i18n.t('constants:headwear type.peaked_cap') },
  { value: 'shawl', label: i18n.t('constants:headwear type.shawl') },
  { value: 'hat_with_earflaps', label: i18n.t('constants:headwear type.hat_with_earflaps') },
  { value: 'helmet', label: i18n.t('constants:headwear type.helmet') },
  { value: 'hood', label: i18n.t('constants:headwear type.hood') },
  { value: 'hat', label: i18n.t('constants:headwear type.hat') },
  { value: 'other', label: i18n.t('constants:headwear type.other') },
]);

export const FACE_QUALITY_SMILE_PROPERTIES = optionsProxy.create([
  { value: 'none', label: i18n.t('constants:smile properties.none') },
  { value: 'smile_lips', label: i18n.t('constants:smile properties.smile_lips') },
  { value: 'smile_teeth', label: i18n.t('constants:smile properties.smile_teeth') },
]);

export const FACE_QUALITY_FACE_COLOR_TYPES = optionsProxy.create([
  { value: 'color', label: i18n.t('constants:face color type.color') },
  { value: 'grayscale', label: i18n.t('constants:face color type.grayscale') },
  { value: 'infrared', label: i18n.t('constants:face color type.infrared') },
]);

export const FACE_QUALITY_YES_NO = optionsProxy.create([
  { value: 0, label: i18n.t('constants:да нет.0') },
  { value: 1, label: i18n.t('constants:да нет.1') },
]);

export const POLICIES = {
  CALLBACK: {
    type: optionsProxy.create([
      { value: 'http', label: i18n.t('constants:http') },
    ]),
    authorization: {
      type: optionsProxy.create([
        { value: 'basic', label: i18n.t('constants:basic') },
      ]),
    },
    params: {
      content_type: optionsProxy.create([
        { value: 'application/json', label: i18n.t('constants:application/json') },
        { value: 'application/msgpack', label: i18n.t('constants:application/msgpack') },
      ]),
    },
  },
  TTL: optionsProxy.create([
    { value: null, label: i18n.t('constants:ttl.null') },
    { value: -1, label: i18n.t('constants:ttl.infinite') },
    { value: 1, label: i18n.t('constants:ttl.key', { count: 1 }) },
    { value: 2, label: i18n.t('constants:ttl.key', { count: 2 }) },
    { value: 3, label: i18n.t('constants:ttl.key', { count: 3 }) },
    { value: 4, label: i18n.t('constants:ttl.key', { count: 4 }) },
    { value: 5, label: i18n.t('constants:ttl.key', { count: 5 }) },
    { value: 6, label: i18n.t('constants:ttl.key', { count: 6 }) },
    { value: 7, label: i18n.t('constants:ttl.key', { count: 7 }) },
    { value: 14, label: i18n.t('constants:ttl.key', { count: 14 }) },
    { value: 30, label: i18n.t('constants:ttl.key', { count: 30 }) },
    { value: 60, label: i18n.t('constants:ttl.key', { count: 60 }) },
    { value: 90, label: i18n.t('constants:ttl.key', { count: 90 }) },
    { value: 180, label: i18n.t('constants:ttl.key', { count: 180 }) },
    { value: 365, label: i18n.t('constants:ttl.key', { count: 360 }) },
  ]),
};
