import React from 'react';

import validate from '@vlabs/shared/validators';
import { Control, Divider, Page } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import st from './newPasswordForm.module.sass';

function NewPasswordForm({
  onSubmit,
  defaultValues,
}) {
  const { t } = useTranslation();
  const formMethods = useForm({ defaultValues });
  const { handleSubmit, register, watch, formState: { errors } } = formMethods;

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Page className={st.Form} title={t('accounts:password update.title')}>

          <Divider small />
          <Control.Input
            id="password"
            label={t('accounts:new password')}
            type="password"
            {...register('password', {
              required: validate.required(),
              minLength: validate.minLength(5),
            })}
            errors={errors}
          />

          <Divider small />
          <Control.Input
            id="passwordConfiramtion"
            label={t('accounts:password confirmation')}
            type="password"
            {...register('passwordConfirmation', {
              validate: (value) => {
                return value === watch('password') || t('валидация.пароли не совпадают');
              },
            })}
            errors={errors}
          />

          <Divider small />
          <Control.Button fullWidth type="submit">
            {t('форма.submit')}
          </Control.Button>
        </Page>
      </form>
    </FormProvider>
  );
}

NewPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  defaultValues: PropTypes.shape({}),
};

NewPasswordForm.defaultProps = {
  defaultValues: {},
};

export default NewPasswordForm;
