import React from 'react';

import FaceQualitySubForm from '@vlabs/pages/handlers/forms/FaceQualitySubForm';
import { Step } from '@vlabs/uikit';
import cn from 'classnames';

import st from './shared.module.sass';

function StepFaceQuality() {
  return (
    <Step
      className={cn(st.Step, st.NoTitle, st.FoldedStep)}
      withPadding={false}
    >
      <FaceQualitySubForm />
    </Step>
  );
}

StepFaceQuality.fieldNames = [
  'faceQuality',
];

export { StepFaceQuality };
