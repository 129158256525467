import React, { memo } from 'react';

import { IconInner } from '../../IconInner';

const PersonIconInner = (props) => (
  <IconInner name="PersonIcon" viewBox="0 0 24 24" {...props}>
    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2m8-10a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
  </IconInner>
);

export const PersonIcon = memo(PersonIconInner);
