import React, { useCallback } from 'react';

import { ArrowInCircleRightIcon } from '@vlabs/icons';
import { ButtonScrimGroup, ButtonScrimItem } from '@vlabs/uikit';
import PropTypes from 'prop-types';

import { BaseSample } from './BaseSample';
import st from './SampleWithActions.module.sass';

export const SampleWithActions = ({ onGoToClick, actions, ...props }) => {
  const onClick = useCallback((e) => {
    if (e.code === 'Enter' || e.code === undefined) {
      e.stopPropagation();
      onGoToClick(e);
    }
  }, [onGoToClick]);

  return (
    <div className={st.Root}>
      {onGoToClick && (
        <ButtonScrimGroup className={st.GoToButton}>
          <ButtonScrimItem
            icon={<ArrowInCircleRightIcon />}
            onClick={onClick}
          />
        </ButtonScrimGroup>
      )}

      <BaseSample {...props} />

      {actions && (
        <div className={st.ActionsGroup}>
          {actions}
        </div>
      )}
    </div>
  );
};

SampleWithActions.propTypes = {
  actions: PropTypes.node,
  onGoToClick: PropTypes.func,
};

SampleWithActions.defaultProps = {
  actions: undefined,
  onGoToClick: undefined,
};
