import React from 'react';

import { CallbacksPolicySubForm } from '@vlabs/pages/handlers/forms';
import StepTitle from '@vlabs/shared/legacy-components/steps/StepTitle';
import { Step } from '@vlabs/uikit';
import i18next from 'i18next';

import st from './shared.module.sass';

function StepCallbacksPolicy() {
  return (
    <Step
      className={st.Step}
      title={(
        <StepTitle>
          {i18next.t('handlers:вызывать колбэк при появлении события?')}
        </StepTitle>
      )}
    >
      <CallbacksPolicySubForm />
    </Step>
  );
}

StepCallbacksPolicy.fieldNames = [
  'callbacksPolicy',
];

export { StepCallbacksPolicy };
