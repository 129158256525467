export const getDefaultValues = (listOption) => ({
  description: '',
  content: {
    target: { value: 'faces' },
    remove_samples: true,
    store_results: true,
    filters: {
      account_id: undefined,
      create_time__lt: undefined,
      create_time__gte: undefined,
      list_id: listOption || undefined,
      user_data: undefined,
    },
  },
  notification_policy: {
    task_callbacks: [],
  },
});
