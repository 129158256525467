import React from 'react';

import { LIVENESS_OPTIONS_STRICT } from '@vlabs/api-bindings/src/luna-client/constants';
import { livenessImages } from '@vlabs/shared/assets/images';
import { optionsProxy } from '@vlabs/uikit';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Card } from './Card';
import st from './Card.module.sass';

const LIVENESS_IMAGES = {
  0: livenessImages.LivenessImageFail,
  1: livenessImages.LivenessImageSuccess,
  2: livenessImages.LivenessImageUnknown,
};

const LIVENESS_ESTIMATION_STATES = optionsProxy.create([
  { value: 0, label: i18n.t('events:attribute card.liveness card.front.values.fail') },
  { value: 1, label: i18n.t('events:attribute card.liveness card.front.values.success') },
  { value: 2, label: i18n.t('events:attribute card.liveness card.front.values.unknown result') },
]);

export const LivenessCard = ({ liveness }) => {
  const { t } = useTranslation();

  if (!LIVENESS_ESTIMATION_STATES[liveness]) return null;

  return (
    <Card
      back={(
        <div className={st.Tooltip}>
          {t('events:attribute card.liveness card.back.text')}
          <span className={st.TooltipValue}>
            {LIVENESS_OPTIONS_STRICT[liveness]?.label }
          </span>
        </div>
      )}
      front={(
        <div className={st.Root}>
          <div
            className={st.ImageBlock}
            data-testid="faceAttributesCell.liveness"
          >
            {t('events:attribute card.liveness card.front.title')}
            <img alt="liveness icon" className={st.Image} src={LIVENESS_IMAGES[liveness]} />
            {LIVENESS_ESTIMATION_STATES[liveness].label}
          </div>
        </div>
      )}
    />
  );
};

LivenessCard.propTypes = {
  liveness: PropTypes.oneOf([0, 1, 2]).isRequired,
};
