import React, { useMemo } from 'react';

import { STREAM_STATUS_OPTIONS } from '@vlabs/api-bindings/src/luna-streams/constants';
import * as pt from '@vlabs/shared';
import { routes } from '@vlabs/shared/navigation/routes';
import { actionColumnProps } from '@vlabs/shared/utils';
import { Control, openConfirmPopup, Table, TableCells, TableFilters } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { generatePath } from 'react-router-dom';

import { StartCell, PauseCell, StopCell, StatusCell, ViewStreamCell } from './cells';
import { GroupsCell } from './GroupsCell';
import { PreviewCell } from './PreviewCell';
import { selectPageParameters, selectPageData } from './selectors';
import * as store from './store';

function StreamListTable({
  pageData,
  setFilters,
  pageParameters,
  setPageIndex,
  setPageSize,
  deleteStream,
  withActionButtons,
  onAddStreamClick,
  onEditStreamClick,
  hiddenColumns,
}) {
  const { t } = useTranslation();

  const onDeleteRowHandler = (stream) => {
    const message = (
      <span>
        {t('lunaStreamsStreams:таблица.удаление.текст', { stream })}
      </span>
    );

    openConfirmPopup({
      title: t('lunaStreamsStreams:таблица.удаление.заголовок'),
      message,
      type: 'delete',
      onConfirm: () => deleteStream(stream.stream_id),
    });
  };

  let renderButtons;
  if (withActionButtons) {
    renderButtons = () => (
      <Control.Button onClick={onAddStreamClick}>
        {t('lunaStreamsStreams:таблица.добавить')}
      </Control.Button>
    );
  }

  const columns = useMemo(
    () => [
      actionColumnProps({
        id: 'start',
        accessor: (row) => ({ streamId: row?.stream_id }),
        Cell: StartCell,
      }),
      actionColumnProps({
        id: 'pause',
        accessor: (row) => ({ streamId: row?.stream_id }),
        Cell: PauseCell,
      }),
      actionColumnProps({
        id: 'stop',
        accessor: (row) => ({ streamId: row?.stream_id }),
        Cell: StopCell,
      }),
      { Header: t('lunaStreamsStreams:таблица.колонки.preview'), accessor: 'preview.last_frame', Cell: PreviewCell, width: 120 },
      {
        id: 'statuses',
        Header:
        (<TableCells.TableHeaderCell
          label={t('lunaStreamsStreams:таблица.колонки.status.header.label')}
          title={t('lunaStreamsStreams:таблица.колонки.status.header.title')}
        />),
        Filter: TableFilters.buildOneOfFilter({ options: STREAM_STATUS_OPTIONS.raw }),
        accessor: 'status',
        Cell: StatusCell,
        width: 80,
      },
      {
        id: 'stream_ids',
        Header:
        (<TableCells.TableHeaderCell
          label={t('lunaStreamsStreams:таблица.колонки.stream_id.header.label')}
          title={t('lunaStreamsStreams:таблица.колонки.stream_id.header.title')}
        />),
        accessor: 'stream_id',
        width: 80,
        Filter: TableFilters.buildTextFilter(),
      },
      {
        id: 'names',
        Header:
        (<TableCells.TableHeaderCell
          label={t('lunaStreamsStreams:таблица.колонки.name.header.label')}
          title={t('lunaStreamsStreams:таблица.колонки.name.header.title')}
        />),
        accessor: 'name',
        width: 80,
        Filter: TableFilters.buildTextFilter(),
      },
      {
        Header: t('lunaStreamsStreams:таблица.колонки.description'),
        accessor: 'description',
        width: 80,
      },
      {
        id: 'group_name',
        Header:
        (<TableCells.TableHeaderCell
          label={t('lunaStreamsStreams:таблица.колонки.groups.header.label')}
          title={t('lunaStreamsStreams:таблица.колонки.groups.header.title')}
        />),
        accessor: 'groups',
        Cell: GroupsCell,
        width: 100,
        Filter: TableFilters.buildTextFilter(),
      },
      actionColumnProps({ id: 'view',
        accessor: (row) => {
          if (!row?.stream_id) return undefined;
          return generatePath(routes.sources.lunaStreams.streams.view, { streamId: row.stream_id });
        },
        Cell: ViewStreamCell }),
      actionColumnProps({ id: 'edit', Cell: TableCells.EditCell }),
      actionColumnProps({ id: 'delete', Cell: TableCells.DeleteCell }),
    ].filter(({ id }) => !hiddenColumns.includes(id)),
    [hiddenColumns, t],
  );

  return (
    <Table
      {...pageData}
      {...pageParameters}
      columns={columns}
      extraButtons={renderButtons}
      onActions={{
        onEditRow: { handler: ({ stream_id }) => onEditStreamClick(stream_id) },
        onDeleteRow: { handler: onDeleteRowHandler },
      }}
      paginationType="offsetBased"
      setFilters={setFilters}
      setPageIndex={setPageIndex}
      setPageSize={setPageSize}
    />
  );
}

StreamListTable.propTypes = {
  deleteStream: PropTypes.func,
  withActionButtons: PropTypes.bool,
  onAddStreamClick: PropTypes.func.isRequired,
  onEditStreamClick: PropTypes.func.isRequired,
  hiddenColumns: PropTypes.arrayOf(PropTypes.string),
  ...pt.pageData.propTypes,
  ...pt.pageParameters.propTypes,
};

StreamListTable.defaultProps = {
  deleteStream: undefined,
  withActionButtons: false,
  hiddenColumns: [],
  ...pt.pageData.defaultProps,
  ...pt.pageParameters.defaultProps,
};

export default connect((state) => ({
  pageData: selectPageData(state),
  pageParameters: selectPageParameters(state),
}), (dispatch) => ({
  setFilters: (filters) => dispatch(store.setFilters({ filters })),
  setPageIndex: (pageIndex) => dispatch(store.setPageIndex(pageIndex)),
  setPageSize: (pageSize) => dispatch(store.setPageSize(pageSize)),
  deleteStream: (streamId) => dispatch(store.deleteStream(streamId)),
}))(StreamListTable);
