import React from 'react';

import { defaultFilters } from '@vlabs/pages/events/defaultFilters';
import { optionPropType } from '@vlabs/shared';
import {
  GeolocationFilterForm,
  CommonEventFilterForm,
  ComparisonParamsFilterForm,
  FaceAttributesFilterForm,
  BodyAttributesFilterForm,
  OtherFilterForm,
} from '@vlabs/shared/legacy-components/filter-forms';
import { Control, Fold, Group } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { selectHandlerOptions } from '@vlabs/pages/handlers/selectors';
import { selectStreamIdOptionsByAccount, selectVLAccessOptionsByAccount } from '@vlabs/pages/sources/selectors';

import st from './FilterForm.module.sass';

const FilterForm = ({
  camOptions,
  handlerOptions,
  streamIdsOptions,
}) => {
  const { t } = useTranslation();
  const { reset, setFilters } = useFormContext();

  const resetForm = () => {
    reset(defaultFilters);
    setFilters({});
  };

  return (
    <div
      className={st.FilterForm}
      data-testid="filtersPanel"
    >

      <Group gapSize="5px" vertical>
        <Fold
          className={st.Fold}
          compact
          isOpen
          title={t('general')}
          withPadding
        >
          <CommonEventFilterForm
            camOptions={camOptions}
            handlerOptions={handlerOptions}
            streamIdsOptions={streamIdsOptions}
          />
        </Fold>

        <Fold
          className={st.Fold}
          compact
          title={t('labels')}
          withPadding
        >
          <ComparisonParamsFilterForm />
        </Fold>

        <Fold
          className={st.Fold}
          compact
          title={t('events:filter form.face attributes and properties')}
          withPadding
        >
          <FaceAttributesFilterForm />
        </Fold>

        <Fold
          className={st.Fold}
          compact
          title={t('events:filter form.body attributes and properties')}
          withPadding
        >
          <BodyAttributesFilterForm />
        </Fold>

        <Fold
          className={st.Fold}
          compact
          title={t('location')}
          withPadding
        >
          <GeolocationFilterForm />
        </Fold>

        <Fold
          className={st.Fold}
          compact
          title={t('другое')}
          withPadding
        >
          <OtherFilterForm />
        </Fold>
      </Group>

      <div className="StickyBottomContainer">
        <div className={st.ButtonPanel}>
          <Control.Button type="submit">
            {t('button.filter')}
          </Control.Button>

          <Control.Button kind="attention" onClick={resetForm}>
            {t('button.reset')}
          </Control.Button>
        </div>
      </div>
    </div>
  );
};

FilterForm.propTypes = {
  camOptions: PropTypes.arrayOf(optionPropType),
  handlerOptions: PropTypes.arrayOf(optionPropType),
  streamIdsOptions: PropTypes.arrayOf(optionPropType),
};

FilterForm.defaultProps = {
  camOptions: [],
  handlerOptions: [],
  streamIdsOptions: [],
};

export default connect(
  (state) => ({
    camOptions: selectVLAccessOptionsByAccount(state),
    streamIdsOptions: selectStreamIdOptionsByAccount(state),
    handlerOptions: selectHandlerOptions(state),
  }),
)(FilterForm);
