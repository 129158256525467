import React, { useEffect } from 'react';

import { POLICIES } from '@vlabs/api-bindings/src/luna-client/handlers/constants';
import { CallbackPolicyForm } from '@vlabs/shared/legacy-components/callback-form/CallbackPolicyForm';
import { Page, Control, Divider } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useForm, FormProvider, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import './TagForm.sass';

import st from './CallbackForm.module.sass';
import GenericPolicySubForm from './GenericPolicySubForm';

const defaultValues = {
  type: POLICIES.CALLBACK.type.http,
  authorization: {},
  url: '',
  params: {
    timeout: 60,
    content_type: POLICIES.CALLBACK.params.content_type['application/json'],
    headers: '',
  },
  filters: {},
  enable: true,
};

function CallbackForm({
  onSubmit,
  isSelected,
  initialValues,
}) {
  const { t } = useTranslation();
  const [estimate_liveness, estimate_deepfake, extract, matchPolicy] = useWatch({ name: ['estimate_liveness', 'estimate_deepfake', 'extract', 'matchPolicy'] });
  // приходится пробрасывать отдельно, иначе GenericPolicy подпишется на
  // этот параметр во внутренней форме, и поля будут дизейблиться не везде
  const faceDescriptorIsEnabled = useWatch({ name: 'extract.faceDescriptor' });

  const form = useForm({ defaultValues: {
    ...(initialValues || defaultValues),
    matchPolicy,
    estimate_liveness,
    estimate_deepfake,
    extract,
  } });

  useEffect(() => {
    form.reset({
      ...(initialValues || defaultValues),
      estimate_liveness,
      estimate_deepfake,
      extract,
      matchPolicy,
    });
  }, [initialValues, estimate_liveness, estimate_deepfake, extract, form, form.reset, matchPolicy]);

  // Предотвращает сабмит основной формы
  const handleSubmit = (func) => (e) => {
    if (e?.preventDefault) e.preventDefault();
    if (e?.stopPropagation) e.stopPropagation();
    form.handleSubmit(func)(e);
  };

  return (
    <FormProvider {...form}>
      <Page
        className={st.Root}
        title={isSelected
          ? t('handlers:policies.storage_policy.callbacks.edit callback')
          : t('handlers:policies.storage_policy.callbacks.add callback')}
      >
        <form data-testid="callback.form" onSubmit={handleSubmit(onSubmit)}>
          <CallbackPolicyForm />

          <GenericPolicySubForm
            faceFieldsDisabled={!faceDescriptorIsEnabled}
            fieldName="filters"
            filtersTitle={t('handlers:policies.storage_policy.callbacks.filter if')}
            matchTitle={t('handlers:policies.storage_policy.callbacks.save if')}
          />

          <Divider small />
          <Control.Button fullWidth type="submit">
            {isSelected
              ? t('кнопка.изменить')
              : t('кнопка.добавить')}
          </Control.Button>
        </form>
      </Page>
    </FormProvider>
  );
}

CallbackForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  initialValues: PropTypes.objectOf(PropTypes.any),
};

CallbackForm.defaultProps = {
  initialValues: undefined,
};

export { CallbackForm };
