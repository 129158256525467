import React, { useEffect, useState } from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import '../FormPage.sass';
import { Brand } from '../../components/brand/Brand';
import { Forms } from '../../components/forms';
import { Panel } from '../../components/panel/Panel';

const LoginPage = ({
  onSubmit,
  error,
  loginPlaceholder,
  tooltipText,
  CustomBrand,
  ...props
}) => {
  const { onForgotPassword } = props;
  const [isAnimationOn, setIsAnimationOn] = useState(false);

  useEffect(() => {
    const animationTimer = setTimeout(() => setIsAnimationOn(false), 1000);
    return () => {
      clearTimeout(animationTimer);
    };
  }, [isAnimationOn]);

  useEffect(() => {
    if (error) setIsAnimationOn(true);
  }, [error]);

  return (
    <div className="FormPage">
      <div className="FormPage__Content">
        {CustomBrand || <Brand className="FormPage__Logo" />}

        <Panel className={cn({
          FormPage__Panel: true,
          FormPage__Panel_withError: isAnimationOn,
          FormPage__Panel_withBottomButton: onForgotPassword,
        })}
        >
          <Forms.LoginForm
            error={error}
            loginPlaceholder={loginPlaceholder}
            onSubmit={onSubmit}
            tooltipText={tooltipText}
            {...props}
          />
        </Panel>
      </div>
    </div>
  );
};

LoginPage.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onForgotPassword: PropTypes.func,
  error: PropTypes.string,
  loginPlaceholder: PropTypes.string,
  tooltipText: PropTypes.string,
  CustomBrand: PropTypes.element,
};

LoginPage.defaultProps = {
  onForgotPassword: undefined,
  error: '',
  loginPlaceholder: '',
  tooltipText: undefined,
  CustomBrand: null,
};

export { LoginPage };
