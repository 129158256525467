import React from 'react';

import { CopyButton, Divider } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import st from './errors.module.sass';

const APIErrorToast = ({
  service,
  error,
  children,
}) => {
  const { t } = useTranslation();

  const method = error.config.method.toUpperCase();
  const path = error.config.baseURL + error.config.url;
  const response = error.response?.data;
  const status = error.response?.status;

  const copyValue = {
    message: error.message,
    code: error.code,
    config: error.config,
  };
  delete copyValue.config.data;

  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    // console.log(error.response.data);
    // console.log(error.response.status);
    // console.log(error.response.headers);

    copyValue.response = JSON.parse(JSON.stringify(error.response));
    delete copyValue.response.config.data;
  } if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
  }
  // Something happened in setting up the request that triggered an Error
  // console.log('Error', error.message);

  let responseBlock;
  if (children) {
    responseBlock = children;
  } else if (status === 413) {
    responseBlock = (
      <>
        <p>
          <strong>
            {t('error.api.response.title')}
          </strong>
        </p>
        <pre>
          {t('error.api.response.error 413.title')}
        </pre>
      </>
    );
  } else if (response) {
    responseBlock = (
      <>
        <p>
          <strong>
            {t('error.api.response.title')}
          </strong>
        </p>
        <pre>
          {JSON.stringify(response, null, 2)}
        </pre>
      </>
    );
  } else {
    responseBlock = (
      <>
        <p>
          <strong>{t('error.api.response.title')}</strong>
        </p>
        <pre>
          {error.message}
        </pre>
      </>
    );
  }

  return (
    <div className={st.Error}>
      <h5>{t('error.api.title', { service })}</h5>

      {status && (
        <p>
          <strong>{t('error.api.http.status.title')}</strong>
          {' '}
          {status}
        </p>
      )}

      <p>
        <strong>{t('error.api.http.method.title')}</strong>
        {' '}
        {method}
      </p>

      <p>
        <strong>{t('error.api.http.request url.title')}</strong>
        {' '}
        {path}
      </p>

      {responseBlock}

      <Divider small />
      <p>
        <CopyButton label={t('error.copy')} value={JSON.stringify(copyValue)} />
      </p>
    </div>
  );
};

APIErrorToast.propTypes = {
  service: PropTypes.string,
  error: PropTypes.shape({
    response: PropTypes.shape({
      data: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      status: PropTypes.number,
      headers: PropTypes.shape({}),
    }),
    request: PropTypes.instanceOf(XMLHttpRequest),
    message: PropTypes.string,
    code: PropTypes.string,
    config: PropTypes.shape({
      method: PropTypes.string,
      url: PropTypes.string,
      baseURL: PropTypes.string,
    }),
  }).isRequired,
  children: PropTypes.node,
};

APIErrorToast.defaultProps = {
  service: 'API',
  children: undefined,
};

export { APIErrorToast };
export const showAPIErrorToast = (error) => {
  toast.error(<APIErrorToast error={error} />);
};
