import React from 'react';

import { CopyButton } from '@vlabs/uikit';
import cn from 'classnames';
import i18next from 'i18next';
import PropTypes from 'prop-types';

import st from './InfoRow.module.sass';

export default function InfoRow({
  value,
  toCopy,
  title,
  icon,
  className,
  'data-testid': testid,
  columnDirection,
}) {
  if (!value) return null;

  return (
    <div
      className={cn(
        st.Root,
        className,
        {
          [st.Root_column]: columnDirection,
        },
      )}
      data-testid={testid}
    >
      <div className={st.Title}>
        {icon && <div className={st.TitleIcon}>{icon}</div>}
        <div>{title}</div>
      </div>

      <div className={cn(
        st.Value,
        {
          [st.Value_column]: columnDirection,
        },
      )}
      >
        {value}

        {toCopy && (
          <CopyButton
            className={st.Icon}
            data-testid={`${testid}-copy`}
            round
            title={i18next.t('скопировать')}
            value={toCopy}
          />
        )}
      </div>
    </div>
  );
}

InfoRow.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  icon: PropTypes.node,
  toCopy: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  className: PropTypes.string,
  'data-testid': PropTypes.string,
  columnDirection: PropTypes.bool,
};

InfoRow.defaultProps = {
  value: undefined,
  icon: undefined,
  toCopy: undefined,
  title: undefined,
  className: undefined,
  'data-testid': undefined,
  columnDirection: false,
};
