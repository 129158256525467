import React, { useCallback } from 'react';

import { Control } from '@vlabs/uikit';
import { useFormContext, useWatch } from 'react-hook-form';

import { useUpdateAllPoliciesField } from '../hooks';

export function BasicAttributesToggle() {
  const { control } = useFormContext();
  const extractBasicAttributes = useWatch({ name: 'extract.basicAttributes' });
  const estimateFace = useWatch({ name: 'estimate.face' });

  useUpdateAllPoliciesField({
    on: useCallback(() => extractBasicAttributes === false, [extractBasicAttributes]),
    path: 'filters.age',
    value: null,
  });
  useUpdateAllPoliciesField({
    on: useCallback(() => extractBasicAttributes === false, [extractBasicAttributes]),
    path: 'filters.gender',
    value: null,
  });

  return (
    <Control.Switch
      control={control}
      disabled={!estimateFace}
      name="extract.basicAttributes"
    />
  );
}
