import { EVENT_OPTIONS } from '@vlabs/api-bindings/src/constants';

const defaultFaces = {
  limit: 3,
  threshold: 80,
  origin: EVENT_OPTIONS.faces,
  list: undefined,
  userData: '',
  createTimeGte: null,
  createTimeLt: null,
  faceIds: '',
  externalIds: '',
};

const defaultEvents = {
  limit: 3,
  threshold: 80,
  origin: EVENT_OPTIONS.events,
  faceIds: '',
  externalIds: '',
  userData: '',
  account_id: '',
  list: undefined,
  createTimeGte: null,
  createTimeLt: null,
  eventIds: '',
  handlers: undefined,
  age: undefined,
  gender: undefined,
  emotions: undefined,
  masks: undefined,
  sources: undefined,
  topSimilarObjectSimilarityGte: '',
  topSimilarObjectSimilarityLt: '',
  cities: '',
  areas: '',
  districts: '',
  streets: '',
  houseNumbers: '',
  trackIds: '',
  tags: '',
  geo_position: {},
};

export const matchPolicyByOrigin = {
  faces: defaultFaces,
  events: defaultEvents,
};

export const getDefaultPackageIdentificationValues = (origin, email, handlerId) => ({
  origin: EVENT_OPTIONS.faces,
  description: `${email} packageIdentification`,
  content: {
    handler: {
      handler_id: handlerId,
      policies: {
        extract_policy: {
          extract_basic_attributes: 1,
        },
        matchPolicy: matchPolicyByOrigin[origin],
      },
    },
    source: {},
  },
  notification_policy: {
    task_callbacks: [],
  },
});
