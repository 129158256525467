import React, { memo } from 'react';

import { IconInner } from '../../IconInner';

const LogOutIconInner = (props) => (
  <IconInner name="LogOutIcon" viewBox="0 0 24 24" {...props}>
    <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4m7 14 5-5-5-5m5 5H9" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
  </IconInner>
);

export const LogOutIcon = memo(LogOutIconInner);
