import { selectSimilarityThresholds } from '@vlabs/shared/selectors/appSelectors';

import { getState } from '@vlabs/shared/redux/store';

export const calcBadgeType = (similarity) => {
  const { low, medium } = selectSimilarityThresholds(getState());
  let badgeType = 'positive';

  if (similarity < medium) badgeType = 'attention';
  if (similarity < low) badgeType = 'negative';
  return badgeType;
};
