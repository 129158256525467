import React, { forwardRef } from 'react';

import { Control, Tooltip } from '@vlabs/uikit';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { viewerCan } from '@vlabs/pages/auth/selectors';

const ButtonWithPermissionsComponent = forwardRef(({
  permissions,
  disabled,
  title,
  icon,
  can,
  ...props
}, externalRef) => {
  const { rules = [], method = 'oneof' } = permissions || {};

  const hasPermission = rules.length ? can(rules, { method }) : true;
  const isDisabled = !hasPermission || disabled;

  return (
    <Tooltip
      data-tooltip-content={!hasPermission ? i18n.t('кнопка.недостаточно прав.title') : null}
    >
      <Control.BaseButton
        disabled={isDisabled}
        icon={icon}
        ref={externalRef}
        title={title}
        {...props}
      >
        {props.children}
      </Control.BaseButton>
    </Tooltip>

  );
});

ButtonWithPermissionsComponent.propTypes = {
  permissions: PropTypes.shape({
    rules: PropTypes.arrayOf(PropTypes.string).isRequired,
    method: PropTypes.oneOf(['oneof', 'allof']),
  }),
  can: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  title: PropTypes.string,
  icon: PropTypes.element,
};

ButtonWithPermissionsComponent.defaultProps = {
  permissions: { rules: [], method: 'oneof' },
  disabled: false,
  title: '',
  icon: null,
};

export const ButtonWithPermissions = connect((state) => ({
  can: viewerCan(state),
}))(ButtonWithPermissionsComponent);
