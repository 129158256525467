import React, { useState } from 'react';

import { apiContainer } from '@vlabs/api-bindings';
import VLTimeTrackingTasksTab from '@vlabs/pages/time-tracking-tasks/VLTimeTrackingTasksTab';
import { selectAppServices } from '@vlabs/shared/selectors/appSelectors';
import { Page, Panel, Tabs, Tab } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { selectReports } from '@vlabs/pages/time-tracking-tasks/selectors';

import LPTasksTab from './LPTasksTab';

import './TaskListPage.sass';

function TaskListPage({
  timetrackerData,
  timetrackerIsAvailable,
}) {
  const { t } = useTranslation();
  const [lpTasksCount, setLpTasksCount] = useState(0);

  const fetchTasksCount = async () => {
    const { data: { count } } = await apiContainer.lunaClient.tasks.getCount();
    setLpTasksCount(count);
  };

  return (
    <Page className="Global__Table_fullScreen_wrapper">
      <Panel className="TaskListPage__Table_fullScreen">
        <Tabs testId="tasksTabs">
          <Tab header={t('tasks:заголовок LP', { count: lpTasksCount })} name="LPTasks">
            <LPTasksTab fetchTasksCount={fetchTasksCount} />
          </Tab>
          {timetrackerIsAvailable && (
            <Tab header={t('tasks:заголовок Timetracker', { count: timetrackerData?.meta?.count ?? 0 })} name="VLTimeTrackingTasks">
              <VLTimeTrackingTasksTab />
            </Tab>
          )}
        </Tabs>
      </Panel>
    </Page>
  );
}

TaskListPage.propTypes = {
  timetrackerData: PropTypes.objectOf(PropTypes.any),
  timetrackerIsAvailable: PropTypes.bool,
};
TaskListPage.defaultProps = {
  timetrackerData: undefined,
  timetrackerIsAvailable: false,
};

export default connect(
  (state) => ({
    timetrackerData: selectReports(state),
    timetrackerIsAvailable: selectAppServices(state)?.vlTimetracker?.state === 'loaded',
  }),
)(TaskListPage);
