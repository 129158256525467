/* eslint-disable react-hooks/rules-of-hooks */
import React, { useMemo } from 'react';

import { Tabs, Tooltip } from '@vlabs/uikit';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import './TabsWithPermissions.sass';
import { connect } from 'react-redux';

import { viewerCan } from '@vlabs/pages/auth/selectors';

const getTabTitle = (header, hasPermission) => (
  <>
    {!hasPermission && (
      <Tooltip
        className="ButtonToggle__Tooltip"
        data-tooltip-content={i18n.t('кнопка.недостаточно прав.title')}
      >
        {header}
      </Tooltip>
    )}
    {hasPermission && header}
  </>
);

const TabsWithPermissionsComponent = ({
  can,
  children,
  ...props
}) => {
  const tabs = useMemo(() => React.Children.toArray(children), [children]);

  return (
    <Tabs {...props}>
      {tabs.map((tab) => {
        const {
          header,
          disabled,
          permissions,
          ...otherProps
        } = tab.props;

        const { rules = [], method = 'oneof' } = permissions || {};
        const hasPermission = rules.length ? can(rules, { method }) : true;
        const isDisabled = !hasPermission || disabled;

        return React.cloneElement(tab, {
          ...otherProps,
          header: getTabTitle(header, hasPermission),
          disabled: isDisabled,
        });
      })}
    </Tabs>
  );
};

TabsWithPermissionsComponent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  can: PropTypes.func.isRequired,
};

export const TabsWithPermissions = connect(
  (state) => ({
    can: viewerCan(state),
  }))(TabsWithPermissionsComponent);
