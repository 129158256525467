import React from 'react';

import { GENDER_OPTIONS } from '@vlabs/api-bindings/src/constants';
import { genderImages } from '@vlabs/shared/assets/images';
import { useTranslation, Trans } from 'react-i18next';

import { Card } from './Card';
import st from './Card.module.sass';

const GENDER_IMAGES = {
  0: genderImages.Women,
  1: genderImages.Men,
};

export const GenderCard = ({ gender }) => {
  const { t } = useTranslation();

  const genderTitle = GENDER_OPTIONS[gender]?.label;
  const genderImg = typeof gender === 'number' ? GENDER_IMAGES[gender] : genderImages.Unknown;
  return (
    <Card
      back={(
        <div className={st.Tooltip}>
          <Trans i18nKey="events:карточка.пол" values={{ gender: genderTitle }} />
        </div>
      )}
      front={(
        <div className={st.Root}>
          <div
            className={st.ImageBlock}
            data-testid="faceAttributesCell.gender"
          >
            {t('gender')}
            <img alt="gender" className={st.Image} src={genderImg} />
            {genderTitle}
          </div>
        </div>
      )}
    />
  );
};
