/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { viewerCan } from '@vlabs/pages/auth/selectors';

const CanComponent = ({
  yes,
  no,
  can,
  permissions,
}) => {
  const { rules = [], method = 'oneof' } = permissions;
  if (can(rules, { method })) {
    return yes || null;
  }
  return no || null;
};

CanComponent.propTypes = {
  yes: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  no: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  can: PropTypes.func.isRequired,
  permissions: PropTypes.shape({
    rules: PropTypes.arrayOf(PropTypes.string).isRequired,
    method: PropTypes.oneOf(['oneof', 'allof']),
  }),
};

CanComponent.defaultProps = {
  permissions: {},
  yes: null,
  no: null,
};

export const Can = connect((state) => ({
  can: viewerCan(state),
}))(CanComponent);
