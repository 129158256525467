import { versionGetter } from '../../../../utils';
import { create } from './create';
import { read } from './read';

export const schemes = {
  create,
  read,
};

export default new Proxy({
  _: {
    read,
    create,
  },
}, versionGetter);
