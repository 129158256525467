import React from 'react';

import { Panel } from '@vlabs/uikit';
import PropTypes from 'prop-types';

import st from './Track.module.sass';

const Track = ({
  detections,
}) => (
  <Panel>
    <div className={st.Track}>
      <div className={st.Row}>
        {detections?.map(({ uiSampleURL }) => {
          return (
            <img
              alt={uiSampleURL}
              className={st.TrackImage}
              data-testid="eventBodyImage"
              key={uiSampleURL}
              src={uiSampleURL}
            />
          );
        })}
      </div>
    </div>
  </Panel>
);

Track.propTypes = {
  detections: PropTypes.arrayOf(PropTypes.shape({
    uiSampleURL: PropTypes.string,
  })),
};

Track.defaultProps = {
  detections: [],
};

export { Track };
