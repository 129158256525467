import React from 'react';

import PropTypes from 'prop-types';

import './IdCell.sass';

const IdCell = ({
  value,
}) => {
  if (!value) return null;

  return (
    <div className="IdCell">
      {value}
    </div>
  );
};

IdCell.propTypes = {
  value: PropTypes.string,
};

IdCell.defaultProps = {
  value: undefined,
};

export { IdCell };
