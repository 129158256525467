import React, { useCallback } from 'react';

import { selectIsAppFeatureAvailable } from '@vlabs/shared/selectors/appSelectors';
import { Control } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useFormContext, useWatch } from 'react-hook-form';
import { connect } from 'react-redux';

import { useUpdateField } from '../hooks';

function ExtractBodyDescriptorToggleComponent({
  isBodyLicenseAvailable,
}) {
  const { control } = useFormContext();
  const estimateBody = useWatch({ name: 'estimate.body' });
  const extractBodyDescriptor = useWatch({ name: 'extract.bodyDescriptor' });

  const bodyMatchFilter = useCallback((matches) => {
    return matches.filter(({ descriptorType }) => descriptorType?.value !== 'body');
  }, []);
  useUpdateField({ path: 'matchPolicy',
    value: bodyMatchFilter,
    on: useCallback(() => extractBodyDescriptor === false, [extractBodyDescriptor]),
    updateIf: useCallback(
      (currentValue) => bodyMatchFilter(currentValue).length !== currentValue.length,
      [bodyMatchFilter],
    ),
  });

  return (
    <Control.Switch
      control={control}
      disabled={!estimateBody || !isBodyLicenseAvailable}
      name="extract.bodyDescriptor"
    />
  );
}

ExtractBodyDescriptorToggleComponent.propTypes = {
  isBodyLicenseAvailable: PropTypes.bool,
};
ExtractBodyDescriptorToggleComponent.defaultProps = {
  isBodyLicenseAvailable: false,
};

export const ExtractBodyDescriptorToggle = connect((state) => ({
  isBodyLicenseAvailable: selectIsAppFeatureAvailable(state, 'estimations.body_attributes'),
}))(ExtractBodyDescriptorToggleComponent);
