import React, { useEffect, useState } from 'react';

import { EYE_STATUS_OPTIONS, MOUTH_STATUS_OPTIONS } from '@vlabs/api-bindings/src/constants';
import InfoRow from '@vlabs/shared/legacy-components/info-row/InfoRow';
import { estimateFaceAttributesBySampleId } from '@vlabs/shared/requests';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import st from './tabs.module.sass';

const AdditionalPropertiesTab = ({ sampleId }) => {
  const { t } = useTranslation();
  const [faceAttributes, setFaceAttributes] = useState();

  useEffect(() => {
    const fetchAttributes = async () => {
      const data = await estimateFaceAttributesBySampleId(sampleId);
      setFaceAttributes({ ...data?.attributes, ...data?.quality });
    };

    if (sampleId) {
      fetchAttributes();
    }
  }, [sampleId]);

  let mouthComponent = null;
  let eyesComponent = null;

  if (faceAttributes?.mouth_attributes && Object.keys(faceAttributes.mouth_attributes).length !== 0) {
    const { score, ...attribute } = faceAttributes.mouth_attributes;

    const maxAttribute = Object.entries(attribute).sort((attr1, attr2) => attr2[1] - attr1[1])[0];
    mouthComponent = <div>{`${MOUTH_STATUS_OPTIONS[maxAttribute[0]].label} (${maxAttribute[1]})`}</div>;
  }

  if (faceAttributes?.eyes_attributes) {
    eyesComponent = (
      <div className={st.AttributesWrapper}>
        <div className={st.AttributesWrapper_margin}>
          {`${t('левый')} (${EYE_STATUS_OPTIONS[faceAttributes.eyes_attributes.left_eye?.state]?.label})`}
        </div>
        <div>{`${t('правый')} (${EYE_STATUS_OPTIONS[faceAttributes.eyes_attributes.right_eye?.state]?.label})`}</div>
      </div>
    );
  }

  return (
    <div className={st.AdditionalPropertiesTab}>
      <InfoRow title={t('наклон головы (roll)')} value={faceAttributes?.head_pose?.roll} />
      <InfoRow title={t('наклон головы (pitch)')} value={faceAttributes?.head_pose?.pitch} />
      <InfoRow title={t('наклон головы (yaw)')} value={faceAttributes?.head_pose?.yaw} />
      <InfoRow title={t('направление взгляда (pitch)')} value={faceAttributes?.gaze?.pitch} />
      <InfoRow title={t('направление взгляда (yaw)')} value={faceAttributes?.gaze?.yaw} />
      <InfoRow title={t('засвеченность')} value={faceAttributes?.light} />
      <InfoRow title={t('затемненность')} value={faceAttributes?.dark} />
      <InfoRow title={t('размытость')} value={faceAttributes?.blurriness} />
      <InfoRow title={t('наличие бликов')} value={faceAttributes?.specularity} />
      <InfoRow title={t('равномерность освещения')} value={faceAttributes?.illumination} />
      <InfoRow title={t('статус рта')} value={mouthComponent} />
      <InfoRow title={t('статус глаз')} value={eyesComponent} />
    </div>
  );
};

AdditionalPropertiesTab.propTypes = {
  sampleId: PropTypes.string.isRequired,
};

export { AdditionalPropertiesTab };
