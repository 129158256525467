import React, { useContext } from 'react';

import { UploadIcon } from '@vlabs/icons';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { RoundButton } from '../../controls/button/RoundButton';
import { TableContext } from '../table-context';

const ExportCell = ({ buttonProps }) => {
  const { actions, selectedFlatRows } = useContext(TableContext);

  const onExport = actions.onExport?.getHandlerIfCan?.();
  const label = actions.onExport?.label;

  const selectedRows = selectedFlatRows.map(({ original }) => original);
  const { t } = useTranslation();

  if (!onExport) return null;

  return (
    <RoundButton
      data-testid="deleteCell"
      icon={<UploadIcon />}
      kind="primary"
      onClick={() => onExport(selectedRows)}
      title={label || t('uikit:table.действия.экспорт')}
      variant="dimmed"
      {...(buttonProps || {})}
    />
  );
};

ExportCell.propTypes = {
  buttonProps: PropTypes.object,
};

ExportCell.defaultProps = {
  buttonProps: undefined,
};

export { ExportCell };
