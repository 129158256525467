import React from 'react';

import cn from 'classnames';

import st from './Card.module.sass';

export function Card({ className, back, front }) {
  return (
    <div>
      <div className={cn(st.Card, className)}>
        <div className={st.Front}>
          {front}
        </div>
        <div className={st.Back}>
          {back}
        </div>
      </div>
    </div>
  );
}
