import { fabric } from 'fabric';

export const drawVertex = (props = {}) => (
  new fabric.Circle({
    radius: 7,
    fill: '#fff',
    stroke: '#111111',
    strokeWidth: 3,
    selectable: false,
    hasBorders: false,
    hasControls: false,
    originX: 'center',
    originY: 'center',
    hoverCursor: 'pointer',
    ...props,
  })
);

export const drawLine = ({ points, ...restProps } = {}) => (
  new fabric.Line(points, {
    strokeWidth: 2,
    fill: '#999999',
    stroke: '#999999',
    originX: 'center',
    originY: 'center',
    selectable: false,
    hasBorders: false,
    hasControls: false,
    evented: false,
    ...restProps,
  })
);

export const drawPolygon = ({ id, points, ...restProps } = {}) => (
  new fabric.Polygon(points, {
    stroke: '#333333',
    strokeWidth: 1,
    fill: '#fff',
    opacity: 0.5,
    selectable: false,
    hasBorders: false,
    hasControls: false,
    objectCaching: false,
    transparentCorners: false,
    hoverCursor: 'default',
    ...restProps,
  })
);

export const drawRectangle = ({ id, ...restProps } = {}) => (
  new fabric.Rect({
    type: 'rectangle',
    id,
    left: 0,
    top: 0,
    width: 0,
    height: 0,
    fill: '#fff',
    selectable: true,
    evented: true,
    angle: 0,
    opacity: 0.5,
    stroke: '#333333',
    transparentCorners: false,
    lockScalingFlip: true,
    ...restProps,
  })
);
