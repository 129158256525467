export const addCellStyles = (props, column) => {
  let justifyContent = 'flex-start';
  if (column?.align === 'center') justifyContent = 'center';
  if (column?.align === 'right') justifyContent = 'flex-end';

  return [
    props,
    {
      style: {
        justifyContent,
      },
    },
  ];
};
