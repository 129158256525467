import React, { memo } from 'react';

import { IconInner } from '../../IconInner';

const ZoomInIconInner = (props) => (
  <IconInner name="ZoomInIcon" viewBox="0 0 24 24" {...props}>
    <path d="M11 19a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm10 2-4.35-4.35M11 8v6m-3-3h6" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
  </IconInner>
);

export const ZoomInIcon = memo(ZoomInIconInner);
