import React from 'react';

import { TASKS_STATUS_OPTIONS } from '@vlabs/api-bindings/src/constants';
import {
  CircleCheckIcon,
  CircleCrossIcon,
  LoaderIcon,
  PlayerStopInCircleIcon,
  PlayerPauseInCircleIcon,
} from '@vlabs/icons';

import './LPTasksProgressCell.sass';

export const LPTasksProgressCell = (status) => {
  const iconsMap = {
    0: <PlayerPauseInCircleIcon className="ProgressCell" />,
    1: <LoaderIcon className="ProgressCell" />,
    2: <PlayerStopInCircleIcon className="ProgressCell" />,
    3: <CircleCrossIcon className="ProgressCell_canceled" />,
    4: <LoaderIcon className="ProgressCell" />,
    5: <CircleCheckIcon className="ProgressCell_finished" />,
  };

  return (
    <div className="ProgressCell">
      <div title={TASKS_STATUS_OPTIONS[status].label}>
        {iconsMap[status]}
      </div>
    </div>
  );
};
