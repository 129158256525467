import React from 'react';

import { Divider } from '@vlabs/uikit';
import { useTranslation } from 'react-i18next';

import { MatchItem } from './MatchItem';
import st from './MatchList.module.sass';

const MatchList = ({
  externalIdMatches,
  listIdMatches,
}) => {
  const { t } = useTranslation();

  return (
    <div className={st.Root}>
      {externalIdMatches.length > 0 && (
        <>
          <span>
            {t('faces:face creation.faces with the same external id')}
            :&nbsp;
          </span>
          <div className={st.Matches}>
            {externalIdMatches.map((match) => {
              return (
                <div className={st.Margin} key={match.face_id}>
                  <MatchItem avatar={match.uiAvatarURL} faceId={match.face_id} similarity={match.similarity}>
                    <span className={st.MatchValue}>{match.external_id}</span>
                  </MatchItem>
                </div>
              );
            })}
          </div>
          <Divider small />
        </>
      )}

      {listIdMatches.length > 0 && (
        <>
          <span>{t('faces:face creation.similar face in the lists')}</span>
          :&nbsp;
          <div className={st.Matches}>
            {listIdMatches.map((match) => {
              return (
                <div className={st.Margin} key={match.face_id}>
                  <MatchItem avatar={match.uiAvatarURL} faceId={match.face_id} similarity={match.similarity}>
                    <span className={st.MatchValue}>{match.matchListLabel}</span>
                  </MatchItem>
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default MatchList;
