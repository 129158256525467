import React, { memo } from 'react';

import { IconInner } from '../../IconInner';

const FolderIconInner = (props) => (
  <IconInner name="FolderIcon" viewBox="0 0 24 24" {...props}>
    <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2v11Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
  </IconInner>
);

export const FolderIcon = memo(FolderIconInner);
