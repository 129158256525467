import React from 'react';

import PropTypes from 'prop-types';

import CheckRowsSdk from '../components/CheckRowsSdk';

const DEEPFAKE_MAP = {
  fake: 0,
  real: 1,
};

export default function DeepfakeCheckResults({ results }) {
  const deepfakeEstimations = results?.estimations[0]?.face?.detection?.attributes?.deepfake;
  if (!deepfakeEstimations) return null;

  const status = DEEPFAKE_MAP[deepfakeEstimations.prediction];

  return (
    <CheckRowsSdk
      estimationName="deepfake"
      estimations={deepfakeEstimations}
      filename={results.filename}
      status={status}
    />
  );
}

DeepfakeCheckResults.propTypes = {
  results: PropTypes.objectOf(PropTypes.any).isRequired,
};
