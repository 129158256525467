import React, { memo } from 'react';

import { IconInner } from '../../IconInner';

const BookIconInner = (props) => (
  <IconInner name="BookIcon" viewBox="0 0 24 24" {...props}>
    <path d="M4 19.5A2.5 2.5 0 0 1 6.5 17H20" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
    <path d="M6.5 2H20v20H6.5A2.5 2.5 0 0 1 4 19.5v-15A2.5 2.5 0 0 1 6.5 2v0Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
  </IconInner>
);

export const BookIcon = memo(BookIconInner);
