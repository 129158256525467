import PropTypes from 'prop-types';

const ButtonToggle = ({
  children,
  // eslint-disable-next-line no-unused-vars
  icon,
}) => {
  return children;
};

ButtonToggle.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.node,
};

ButtonToggle.defaultProps = {
  children: undefined,
  icon: undefined,
};

export {
  ButtonToggle,
};
