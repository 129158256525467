import {
  CAMERA_PROTOCOL_OPTIONS,
  CAMERA_ROTATION_OPTIONS,
  LIVENESS_MODE_OPTIONS,
  TIME_TYPE_OPTIONS,
} from '../../../../constants';
import { Schema, get } from '../../../../json-transform';

export const read = new Schema({
  id: get('id').asString(),
  name: get('name').asString(),
  previewUrl: get('preview_url').asString(),
  alive: get('alive').asBoolean(),
  input: {
    url: get('input.url').asString(),
    transport: get('input.transport').asOption(CAMERA_PROTOCOL_OPTIONS),
    roi: get('input.roi').asString(),
    droi: get('input.droi').asString(),
    uiFormatROI: get('input.roi').as(([x, y, width, height]) => ({ x, y, width, height })),
    uiFormatDROI: get('input.droi').as(([x, y, width, height]) => ({ x, y, width, height })),
    rotation: get('input.rotation').asOption(CAMERA_ROTATION_OPTIONS),
    frameProcessingMode: get('input.frame-processing-mode').asOption(),
    numberOfFfmpegThreads: get('input.numberOfFfmpegThreads').asInt(),
  },
  output: {
    url: get('output.url').asString(),
    lunaAccountId: get('output.luna-account-id').asString(),
    imageStoreUrl: get('output.image_store_url').asString(),
    login: get('output.login').asString(),
    password: get('output.password').asString(),
    token: get('output.token').asString(),
  },
  healthcheck: {
    maxErrorCount: get('health_check.max_error_count').asInt(),
    period: get('health_check.period').asInt(),
    retryDelay: get('health_check.retry_delay').asInt(),
  },
  filtering: {
    minScore: get('filtering.min-score').asFloat(),
    detectionYawThreshold: get('filtering.detection-yaw-threshold').asInt(),
    detectionPitchThreshold: get('filtering.detection-pitch-threshold').asInt(),
    detectionRollThreshold: get('filtering.detection-roll-threshold').asInt(),
    yawNumber: get('filtering.yaw-number').asInt(),
    yawCollectionMode: get('filtering.yaw-collection-mode').asBoolean(),
    mouthOcclusionThreshold: get('filtering.mouth-occlusion-threshold').asInt(),
  },
  sending: {
    timePeriodOfSearching: get('sending.time-period-of-searching').asInt(),
    silentPeriod: get('sending.silent-period').asInt(),
    type: get('sending.type').asOption(TIME_TYPE_OPTIONS),
    numberOfBestshotsToSend: get('sending.number-of-bestshots-to-send').asInt(),
  },
  primaryTrackPolicy: {
    usePrimaryTrackPolicy: get('primary_track_policy.use_primary_track_policy').asBoolean(),
    bestShotMinSize: get('primary_track_policy.best_shot_min_size').asInt(),
    bestShotProperSize: get('primary_track_policy.best_shot_proper_size').asInt(),
  },
  liveness: {
    useShouldersLivenessFiltration: get('liveness.use_shoulders_liveness_filtration').asBoolean(),
    useMaskLivenessFiltration: get('liveness.use_mask_liveness_filtration').asBoolean(),
    useFlyingFacesLivenessFiltration: get('liveness.use_flying_faces_liveness_filtration').asBoolean(),
    livenessMode: get('liveness.liveness_mode').asOption(LIVENESS_MODE_OPTIONS),
    numberOfLivenessChecks: get('liveness.number_of_liveness_checks').asInt(),
    livenessThreshold: get('liveness.liveness_threshold').asFloat(),
    livenessesWeights: get('liveness.livenesses_weights').asArrayOfFloat(),
    maskBackgroundsCount: get('liveness.mask_backgrounds_count').asInt(),
  },
  videoInfo: {
    width: get('video_info.width').asInt(),
    height: get('video_info.height').asInt(),
    frameRate: get('video_info.frame_rate').asInt(),
    bitRate: get('video_info.bit_rate').asInt(),
    gopSize: get('video_info.gop_size').asInt(),
    startTime: get('video_info.start_time').asDate(),
  },
});
