import React, { useCallback } from 'react';

import { apiContainer } from '@vlabs/api-bindings';
import { ButtonWithPermissions } from '@vlabs/shared/components/button-with-permissions/ButtonWithPermissions';
import { permissions } from '@vlabs/shared/config';
import { openConfirmPopup } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

export const DeleteFaceWidget = ({ faceId }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const onSubmit = useCallback(async () => {
    const message = t('faces:вы уверены что хотите удалить лицо');

    const onConfirm = async () => {
      const response = await apiContainer.lunaClient.faces.delete(faceId);

      if (response.status === 204) {
        toast.success(t('faces:лицо успешно удалено'));

        if (history.length > 2) {
          history.goBack();
        } else {
          history.push('/');
        }
      }
    };

    openConfirmPopup({
      title: t('faces:удаление лица'),
      message,
      type: 'delete',
      onConfirm,
    });
  }, [history, t, faceId]);

  return (
    <ButtonWithPermissions
      data-testid="faceActionsDeleteButton"
      fullWidth
      kind="negative"
      onClick={onSubmit}
      permissions={{ rules: [permissions.face.deletion] }}
      variant="outlined"
    >
      {t('faces:удалить лицо')}
    </ButtonWithPermissions>
  );
};

DeleteFaceWidget.propTypes = {
  faceId: PropTypes.string,
};

DeleteFaceWidget.defaultProps = {
  faceId: undefined,
};
