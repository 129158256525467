import React, { memo } from 'react';

import { IconInner } from '../../IconInner';

const PlayerPauseInCircleIconInner = (props) => (
  <IconInner name="PlayerPauseInCircleIcon" viewBox="0 0 24 24" {...props}>
    <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10Zm-2-7V9m4 6V9" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
  </IconInner>
);

export const PlayerPauseInCircleIcon = memo(PlayerPauseInCircleIconInner);
