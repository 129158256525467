import React from 'react';

import { TASKS } from '@vlabs/api-bindings/src/luna-client/constants';
import validate from '@vlabs/shared/validators';
import { Control, GridCol, GridRow, SettingsItemWrapper } from '@vlabs/uikit';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

function ZIP() {
  const { t } = useTranslation();
  const { register, formState: { errors }, control } = useFormContext();

  return (
    <>
      <GridRow>
        <GridCol>
          <Control.Input
            id="content.source.zip.reference.url"
            label={t('tasks:lp.estimator task.fields.content.source.zip.reference.url.label')}
            placeholder={t('tasks:lp.estimator task.fields.content.source.zip.reference.url.placeholder')}
            {...register('content.source.zip.reference.url', {
              required: validate.required(),
              validate: {
                uri: validate.uri(),
              },
            })}
            errors={errors}
          />
        </GridCol>
        <GridCol cols={4}>
          <Control.Input
            id="content.source.zip.authorization.password"
            label={t('tasks:lp.estimator task.fields.content.source.zip.authorization.password.label')}
            placeholder={t('tasks:lp.estimator task.fields.content.source.zip.authorization.password.placeholder')}
            {...register('content.source.zip.authorization.password')}
            errors={errors}
          />
        </GridCol>
      </GridRow>

      <GridRow>
        <GridCol>
          <Control.Input
            id="content.source.zip.reference.prefix"
            label={t('tasks:lp.estimator task.fields.content.source.zip.reference.prefix.label')}
            placeholder={t('tasks:lp.estimator task.fields.content.source.zip.reference.prefix.placeholder')}
            {...register('content.source.zip.reference.prefix')}
            errors={errors}
          />
        </GridCol>
        <GridCol>
          <Control.Input
            id="content.source.zip.reference.postfix"
            label={t('tasks:lp.estimator task.fields.content.source.zip.reference.postfix.label')}
            placeholder={t('tasks:lp.estimator task.fields.content.source.zip.reference.postfix.placeholder')}
            {...register('content.source.zip.reference.postfix')}
            errors={errors}
          />
        </GridCol>
      </GridRow>

      <GridRow>
        <GridCol>
          <SettingsItemWrapper title={t('tasks:lp.estimator task.fields.content.source.zip.recursive.label')}>
            <Control.Switch
              control={control}
              name="content.source.zip.recursive"
            />
          </SettingsItemWrapper>
        </GridCol>
      </GridRow>

      <GridRow>
        <GridCol>
          <Control.Select
            control={control}
            errors={errors}
            id="content.source.zip.image_type"
            label={t('tasks:lp.estimator task.fields.content.source.zip.image_type.label')}
            name="content.source.zip.image_type"
            options={TASKS.ESTIMATOR.SOURCE.IMAGE_TYPE.raw}
          />
        </GridCol>
      </GridRow>
    </>
  );
}

export { ZIP };
