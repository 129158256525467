import React, { memo } from 'react';

import { IconInner } from '../../IconInner';

const Delete2IconInner = (props) => (
  <IconInner name="Delete2Icon" viewBox="0 0 24 24" {...props}>
    <path d="M3 6h18M8 6V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2m3 0v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6h14Zm-9 5v6m4-6v6" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
  </IconInner>
);

export const Delete2Icon = memo(Delete2IconInner);
