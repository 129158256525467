// FIXME ВРЕМЕННОЕ РЕШЕНИЕ ОБРАБОТКИ ФИЛЬТРОВ ДЛЯ ЗАПРОСОВ К API

export const prepareFilters = (filters) => {
  const flatFilters = [];
  filters.forEach(({ id, value }) => {
    if (id === 'create_time') {
      flatFilters.create_time__gte = value?.[0] || undefined;
      flatFilters.create_time__lt = value?.[1] || undefined;
    } else if (id === 'external_id') {
      flatFilters.external_ids = value;
    } else {
      flatFilters[id] = value;
    }
  });
  return flatFilters;
};
