import React, { useCallback } from 'react';

import { MULTIFACE_POLICY_OPTIONS } from '@vlabs/api-bindings/src/constants';
import { Control } from '@vlabs/uikit';
import { useFormContext, useWatch } from 'react-hook-form';

import { useUpdateField } from '../hooks';

export function EstimateFaceToggle() {
  const { control } = useFormContext();
  const estimateFace = useWatch({ name: 'estimate.face' });
  const onDisableEstimateFaceCallback = useCallback(() => estimateFace === false, [estimateFace]);

  useUpdateField({ path: 'extract.faceDescriptor', value: false, on: onDisableEstimateFaceCallback });
  useUpdateField({ path: 'extract.basicAttributes', value: false, on: onDisableEstimateFaceCallback });
  useUpdateField({ path: 'filters.multifacePolicy', value: MULTIFACE_POLICY_OPTIONS[1], on: onDisableEstimateFaceCallback });
  useUpdateField({ path: 'estimate.body', value: true, on: onDisableEstimateFaceCallback });

  return (
    <Control.Switch
      control={control}
      name="estimate.face"
    />
  );
}
