import React, { useEffect, useRef } from 'react';

import { optionPropType } from '@vlabs/shared';
import { useSteppedForm } from '@vlabs/shared/hooks';
import { selectListOptions } from '@vlabs/shared/selectors/listSelectors';
import { Stepper, Page } from '@vlabs/uikit';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { StepCallbacksPolicy } from '../steps/StepCallbacksPolicy';
import { getDefaultValues } from './defaultValues';
import { StepGarbageCollection } from './StepGarbageCollection';
import '../styles/shared.styles.sass';

const GarbageCollectionStepperFormComponent = ({ filters, onSubmit, listOptions }) => {
  const { t } = useTranslation();
  const steps = [StepGarbageCollection, StepCallbacksPolicy];
  const defaultListOption = listOptions.find(({ value }) => filters?.list_id === value);
  const defaultValues = getDefaultValues(defaultListOption);

  const form = useForm({ defaultValues, mode: 'onChange' });
  const prevForm = useRef(defaultValues);

  useEffect(() => {
    prevForm.current = defaultValues;
    form.reset(defaultValues);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.reset, prevForm]);

  const stepper = useSteppedForm({ form, steps, onSubmit });
  const context = { ...form, defaultListOption };

  return (
    <FormProvider {...context}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Page className={cn('TaskStepperModal__Page', 'GarbageCollectionForm')} title={t('tasks:GCTask.заголовок.удаление лиц из списка')}>
          <Stepper {...stepper} className={cn('TaskStepperModal__StepperContent')}>
            {steps.map((Step) => <Step key={Step.name} />)}
          </Stepper>
        </Page>
      </form>
    </FormProvider>
  );
};

GarbageCollectionStepperFormComponent.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  listOptions: PropTypes.arrayOf(optionPropType),
  filters: PropTypes.shape({
    list_id: PropTypes.string,
  }),
};

GarbageCollectionStepperFormComponent.defaultProps = {
  listOptions: [],
  filters: {},
};

export const GarbageCollectionStepperForm = connect((state) => ({
  listOptions: selectListOptions(state),
}))(GarbageCollectionStepperFormComponent);
