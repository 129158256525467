import React from 'react';

import { TASKS } from '@vlabs/api-bindings/src/luna-client/constants';
import validate from '@vlabs/shared/validators';
import { Control, GridCol, GridRow, SettingsItemWrapper } from '@vlabs/uikit';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

function FTP() {
  const { t } = useTranslation();
  const { register, formState: { errors }, control } = useFormContext();

  return (
    <>
      <GridRow>
        <GridCol>
          <Control.Input
            id="content.source.ftp.reference.host"
            label={t('tasks:lp.estimator task.fields.content.source.ftp.reference.host.label')}
            placeholder={t('tasks:lp.estimator task.fields.content.source.ftp.reference.host.placeholder')}
            {...register('content.source.ftp.reference.host', {
              required: validate.required(),
            })}
            errors={errors}
          />
        </GridCol>

        <GridCol cols={2}>
          <Control.Input
            id="content.source.ftp.reference.port"
            label={t('tasks:lp.estimator task.fields.content.source.ftp.reference.port.label')}
            placeholder={t('tasks:lp.estimator task.fields.content.source.ftp.reference.port.placeholder')}
            {...register('content.source.ftp.reference.port', {
              validate: {
                isInteger: validate.integer(),
              },
              min: validate.gte(1),
              max: validate.lte(65535),
            })}
            errors={errors}
          />
        </GridCol>

        <GridCol cols={4}>
          <Control.Input
            id="content.source.ftp.reference.max_sessions"
            label={t('tasks:lp.estimator task.fields.content.source.ftp.reference.max_sessions.label')}
            placeholder={t('tasks:lp.estimator task.fields.content.source.ftp.reference.max_sessions.placeholder')}
            {...register('content.source.ftp.reference.max_sessions', {
              validate: {
                isInteger: validate.integer(),
              },
              min: validate.gte(1),
              max: validate.lte(65535),
            })}
            errors={errors}
          />
        </GridCol>
      </GridRow>

      <GridRow>
        <GridCol>
          <Control.Input
            id="content.source.ftp.reference.path"
            label={t('tasks:lp.estimator task.fields.content.source.ftp.reference.path.label')}
            placeholder={t('tasks:lp.estimator task.fields.content.source.ftp.reference.path.placeholder')}
            {...register('content.source.ftp.reference.path')}
            errors={errors}
          />
        </GridCol>
      </GridRow>

      <GridRow>
        <GridCol>
          <Control.Input
            id="content.source.ftp.reference.prefix"
            label={t('tasks:lp.estimator task.fields.content.source.ftp.reference.prefix.label')}
            placeholder={t('tasks:lp.estimator task.fields.content.source.ftp.reference.prefix.placeholder')}
            {...register('content.source.ftp.reference.prefix')}
            errors={errors}
          />
        </GridCol>

        <GridCol>
          <Control.Input
            id="content.source.ftp.reference.postfix"
            label={t('tasks:lp.estimator task.fields.content.source.ftp.reference.postfix.label')}
            placeholder={t('tasks:lp.estimator task.fields.content.source.ftp.reference.postfix.placeholder')}
            {...register('content.source.ftp.reference.postfix')}
            errors={errors}
          />
        </GridCol>
      </GridRow>

      <GridRow>
        <GridCol>
          <Control.Input
            id="content.source.ftp.authorization.user"
            label={t('tasks:lp.estimator task.fields.content.source.ftp.authorization.user.label')}
            placeholder={t('tasks:lp.estimator task.fields.content.source.ftp.authorization.user.placeholder')}
            {...register('content.source.ftp.authorization.user', {
              required: validate.required(),
            })}
            errors={errors}
          />
        </GridCol>

        <GridCol>
          <Control.Input
            id="content.source.ftp.authorization.password"
            label={t('tasks:lp.estimator task.fields.content.source.ftp.authorization.password.label')}
            placeholder={t('tasks:lp.estimator task.fields.content.source.ftp.authorization.password.placeholder')}
            {...register('content.source.ftp.authorization.password', {
              required: validate.required(),
            })}
            errors={errors}
          />
        </GridCol>
      </GridRow>

      <GridRow>
        <GridCol>
          <SettingsItemWrapper title={t('tasks:lp.estimator task.fields.content.source.ftp.recursive.label')}>
            <Control.Switch
              control={control}
              name="content.source.ftp.recursive"
            />
          </SettingsItemWrapper>
        </GridCol>
      </GridRow>

      <GridRow>
        <GridCol>
          <Control.Select
            control={control}
            errors={errors}
            id="content.source.ftp.image_type"
            label={t('tasks:lp.estimator task.fields.content.source.ftp.image_type.label')}
            name="content.source.ftp.image_type"
            options={TASKS.ESTIMATOR.SOURCE.IMAGE_TYPE.raw}
          />
        </GridCol>
      </GridRow>
    </>
  );
}

export { FTP };
