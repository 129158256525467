import React, { useState, useEffect } from 'react';

import { apiContainer } from '@vlabs/api-bindings';
import { LunaError } from '@vlabs/api-bindings/src/luna-client/LunaError';
import { EditFormIcon } from '@vlabs/icons';
import { permissions } from '@vlabs/shared/config';
import { routes } from '@vlabs/shared/navigation/routes';
import { Control, Divider, Page } from '@vlabs/uikit';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';

import st from './streamStyles.module.sass';

function StreamPageComponent({
  streamId,
  can,
}) {
  const { t } = useTranslation();
  const [stream, setStream] = useState(undefined);
  const [isStreamExists, setIsStreamExists] = useState(true);
  const [isLiveAvailable, setIsLiveAvailable] = useState(true);
  const history = useHistory();

  useEffect(() => {
    (async () => {
      try {
        const data = await apiContainer.lunaStreamsClient.streams.get(streamId);
        setStream(data);
      } catch (error) {
        if (error instanceof LunaError) {
          if (error.error_code === 39001) {
            setIsStreamExists(false);
            return;
          }
        }
        throw error;
      }
    })();
  }, [streamId]);

  useEffect(() => {
    const keyDown = (e) => {
      if (e.key === 'Escape') {
        history.push(generatePath(routes.sources.main));
      }
    };
    document.removeEventListener('keydown', keyDown);
    document.addEventListener('keydown', keyDown);
    return () => {
      document.removeEventListener('keydown', keyDown);
    };
    // FIXME: пересмотреть зависимости
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [streamId]);

  if (!isStreamExists) {
    return (
      <div className={cn('NotFoundPage', st.PageMessage)}>
        {t('lunaStreamsStreams:api.notFound', { stream: { stream_id: streamId } })}
      </div>
    );
  }

  let pageActions = null;
  if (can && can([permissions.source.modification])) {
    pageActions = (
      <Control.Button
        icon={<EditFormIcon />}
        onClick={() => history.push(generatePath(routes.sources.lunaStreams.streams.edit, { streamId }))}
      >
        {t('lunaStreamsStreams:stream_page.edit')}
      </Control.Button>
    );
  }

  return (
    <Page
      actions={pageActions}
      title={t('lunaStreamsStreams:stream_page.title', { stream })}
    >
      <Divider />
      {isLiveAvailable && (
        <img
          alt="video stream"
          onError={() => { setIsLiveAvailable(false); }}
          src={apiContainer.lunaStreamsClient.streams.liveURL(streamId)}
        />
      )}
      {!isLiveAvailable && (
        <div className={cn('NotFoundPage', st.PageMessage)}>
            {t('lunaStreamsStreams:api.live.unavailable', { stream: { stream_id: streamId } })}
        </div>
      )}
    </Page>
  );
}

StreamPageComponent.propTypes = {
  streamId: PropTypes.string.isRequired,
  can: PropTypes.func,
};

StreamPageComponent.defaultProps = {
  can: undefined,
};

export const StreamPage = StreamPageComponent;
