import React, { useCallback } from 'react';

import { CopyIcon } from '@vlabs/icons';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { Control } from '../controls/index';

import './CopyButton.sass';

const CopyButton = ({
  label,
  value,
  className,
  ...restProps
}) => {
  const { t } = useTranslation();

  const writeToClipboard = useCallback(() => {
    const el = document.createElement('textarea');
    el.value = value;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    toast.info(() => (
      <p style={{
        wordBreak: 'break-word',
        margin: 0,
      }}
      >
        {t('uikit:copyButton.успех')}
        <br />
        <span style={{ wordBreak: 'break-all', fontWeight: 'bold' }}>
          {value.length > 50 && (
            <>
              {value.substr(0, 49)}
              &hellip;
            </>
          )}
          {value.length <= 50 && (value)}
        </span>
      </p>
    ));
  }, [value, t]);

  return (
    <Control.Button
      className={cn(
        'CopyButton',
        className,
      )}
      icon={<CopyIcon className="CopyButton__Icon" />}
      kind="primary"
      onClick={writeToClipboard}
      type="button"
      variant="dimmed"
      {...restProps}
    >
      {label}
    </Control.Button>
  );
};

CopyButton.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any.isRequired,
  className: PropTypes.string,
};

CopyButton.defaultProps = {
  label: undefined,
  className: undefined,
};

export { CopyButton };
