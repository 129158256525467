import React from 'react';

import { optionPropType } from '@vlabs/shared';
import { archiveTypes } from '@vlabs/shared/config';
import { selectIsAppFeatureAvailable } from '@vlabs/shared/selectors/appSelectors';
import { selectListOptions } from '@vlabs/shared/selectors/listSelectors';
import validate from '@vlabs/shared/validators';
import { Divider, Control, SettingsItemWrapper } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useFormContext, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import './StepPackageImport.sass';

const StepPackageImportComponent = ({ listOptions, isFaceQualityAvailable }) => {
  const { t } = useTranslation();
  const { control, register } = useFormContext();
  const { errors } = useFormState();
  return (
    <>
      <h6>{t('настройки задачи')}</h6>
      <Divider />

      {/* FIXME убрать проверку на архив когда обновим компонент в ките */}
      <Control.FileInput
        label={t('tasks:lp.packageIdentification.source.label')}
        {...register('content.source.file.reference', {
          required: validate.required(),
          validate: (file) => {
            if (!archiveTypes.includes(file[0].type)) {
              return t('валидация.неподходящий формат файла');
            }
            return true;
          },
        })}
        accept=".zip,.rar,.7zip"
        errors={errors}
      />

      <Divider small />

      <Control.Select
        autoFocus
        control={control}
        errors={errors}
        id="content.handler.policies.storage_policy.face_policy.link_to_lists_policy.0.list_id"
        isCreatable
        label={t('list')}
        name="content.handler.policies.storage_policy.face_policy.link_to_lists_policy.0.list_id"
        options={listOptions}
        rules={{ required: validate.required(), validate: validate.uuidOptions }}
      />

      <Divider small />

      {isFaceQualityAvailable && (
        <SettingsItemWrapper className="Switch" title={t('tasks:importForm.button label.add photo if icao compliant')} titleClassName="Switch__Label">
          <Control.Switch
            control={control}
            name="content.handler.policies.detect_policy.face_quality.estimate"
          />
        </SettingsItemWrapper>
      )}

      <Divider small />
    </>
  );
};

StepPackageImportComponent.fieldNames = [
  'content.source',
  'content.handler',
];

StepPackageImportComponent.propTypes = {
  isFaceQualityAvailable: PropTypes.bool,
  listOptions: PropTypes.arrayOf(optionPropType),
};

StepPackageImportComponent.defaultProps = {
  listOptions: [],
  isFaceQualityAvailable: false,
};

export const StepPackageImport = connect((state) => ({
  listOptions: selectListOptions(state),
  isFaceQualityAvailable: selectIsAppFeatureAvailable(state, 'estimations.face_quality'),
}))(StepPackageImportComponent);
