import { isEmpty } from 'lodash';

import { Schema, get } from '../../../json-transform';

const EMOTION_MAP = {
  anger: 1,
  disgust: 2,
  fear: 3,
  happiness: 4,
  neutral: 5,
  sadness: 6,
  surprise: 7,
};

const MASK_MAP = {
  missing: 1,
  medical_mask: 2,
  occluded: 3,
};

const LIVENESS_MAP = {
  spoof: 0,
  real: 1,
  unknown: 2,
};
const DEEPFAKE_MAP = {
  fake: 0,
  real: 1,
};

const getBodyAttributes = (event, type) => {
  let attributes = null;

  if (!(isEmpty(event.aggregate_estimations?.body?.attributes?.[type]))) {
    attributes = event.aggregate_estimations?.body?.attributes?.[type];
  }

  if (!(isEmpty(attributes))) return attributes;

  // eslint-disable-next-line no-unused-expressions
  event?.detections.forEach(({ samples }) => {
    if (samples.body?.detection?.attributes?.[type]) {
      attributes = samples.body?.detection?.attributes?.[type];
    }
  });

  return attributes;
};

const getLiveness = (event) => {
  let liveness = null;
  // Проверяем наличие агрегированных атрибутов параметра liveness
  if (event.aggregate_estimations?.face?.attributes?.liveness) {
    liveness = LIVENESS_MAP[
      event.aggregate_estimations
        .face
        .attributes
        .liveness
        .prediction
    ];
  }

  if (liveness) return liveness;

  // eslint-disable-next-line no-unused-expressions
  event?.detections.forEach(({ samples }) => {
    if (liveness) return;
    if (samples.face?.detection?.attributes?.liveness?.prediction) {
      liveness = LIVENESS_MAP[
        samples
          .face
          .detection
          .attributes
          .liveness
          .prediction
      ];
    }
  });

  return liveness;
};
const getDeepfake = (event) => {
  let deepfake = null;
  // Проверяем наличие агрегированных атрибутов параметра liveness
  if (event.aggregate_estimations?.face?.attributes?.deepfake) {
    deepfake = DEEPFAKE_MAP[
      event.aggregate_estimations
        .face
        .attributes
        .deepfake
        .prediction
    ];
  }

  if (deepfake) return deepfake;

  // eslint-disable-next-line no-unused-expressions
  event?.detections.forEach(({ samples }) => {
    if (deepfake) return;
    if (samples.face?.detection?.attributes?.deepfake?.prediction) {
      deepfake = LIVENESS_MAP[
        samples
          .face
          .detection
          .attributes
          .deepfake
          .prediction
      ];
    }
  });

  return deepfake;
};

const getMaskEstimation = (event) => {
  let mask = null;
  // Проверяем наличие агрегированных атрибутов маски
  if (event.aggregate_estimations?.face?.attributes?.mask) {
    mask = MASK_MAP[
      event.aggregate_estimations
        .face
        .attributes
        .mask
        .predominant_mask
    ];
  }

  if (mask) return mask;

  // eslint-disable-next-line no-unused-expressions
  event?.detections.forEach(({ samples }) => {
    if (mask) return;
    if (samples.face?.detection?.attributes?.mask?.predominant_mask) {
      mask = MASK_MAP[
        samples
          .face
          .detection
          .attributes
          .mask
          .predominant_mask
      ];
    }
  });

  return mask;
};

const getEmotionEstimation = (event) => {
  let emotion = null;
  // Проверяем наличие агрегированных атрибутов маски
  if (event.aggregate_estimations?.face?.attributes?.emotions) {
    emotion = EMOTION_MAP[
      event.aggregate_estimations
        .face
        .attributes
        .emotions
        .predominant_emotion
    ];
  }

  if (emotion) return emotion;

  // eslint-disable-next-line no-unused-expressions
  event?.detections.forEach(({ samples }) => {
    if (emotion) return;
    if (samples.face?.detection?.attributes?.emotions?.predominant_emotion) {
      emotion = EMOTION_MAP[
        samples
          .face
          .detection
          .attributes
          .emotions
          .predominant_emotion
      ];
    }
  });

  return emotion;
};

const getDetectionsByType = (values, type) => {
  let detections = null;
  values.forEach(({ samples, detect_time, image_origin }) => {
    if (samples?.[type]) {
      if (!detections) detections = [];
      const sampleId = samples[type]?.sample_id;

      // Добавляем детекцию
      const d = {
        sample_id: sampleId,
        detect_time,
        image_origin,
      };
      if (samples[type]?.detection?.rect) {
        d.detection = { rect: samples[type].detection.rect };
      }
      detections.push(d);
    }
  });

  return detections;
};

const getTopMatch = (event) => {
  if (!Array.isArray(event?.matches)) return null;
  if (event?.matches.length === 0) return null;

  // Конвертируем матчи

  const topMatch = { similarity: -1 };
  event.matches.forEach(({ candidates, label }) => {
    candidates.forEach((candidate) => {
      const { event: candidateEvent, face, similarity } = candidate;
      // Проверяем является ли новый кандидат топ матчем?
      if (topMatch?.similarity < similarity) {
        topMatch.similarity = similarity;
        topMatch.label = label;
        if (candidateEvent) {
          topMatch.external_id = candidateEvent.external_id || undefined;
          topMatch.event_id = candidateEvent.event_id;
          topMatch.face_id = undefined;
        }
        if (face) {
          topMatch.external_id = face.external_id || undefined;
          topMatch.face_id = face.face_id;
          topMatch.event_id = undefined;
        }
      }
    });
  });
  return topMatch;
};

const getMatchResults = (matches) => {
  if (!Array.isArray(matches)) return null;
  let matchResult = null;

  // Конвертируем матчи
  matches.forEach(({ candidates, label }) => {
    if (!matchResult) matchResult = [];

    // По каждому лейблу конвертируем кандидатов
    const mappedCandidates = [];
    candidates.forEach((candidate) => {
      const { event: candidateEvent, face, similarity } = candidate;
      const result = { similarity };
      // Если кандидатом является событие
      if (candidateEvent) {
        result.event = {
          event_id: candidateEvent.event_id,
          user_data: candidateEvent.user_data,
          create_time: candidateEvent.create_time,
          external_id: candidateEvent.external_id,
          handler_id: candidateEvent.handler_id,
          source: candidateEvent.source,
        };
      }
      // Если кандидатом является лицо
      if (face) {
        result.face = {
          face_id: face.face_id,
          user_data: face.user_data,
          create_time: face.create_time,
          external_id: face.external_id,
        };
      }
      mappedCandidates.push(result);
    });
    matchResult.push({
      candidates: mappedCandidates,
      label,
    });
  });

  return matchResult;
};

export const wsToEvent = new Schema({
  create_time: get('event-create-time'),
  event_id: get('event.event_id').asString(),
  handler_id: get('handler_id').asString(),
  external_id: get('event.external_id').asString().ifEmptyString(undefined),
  source: get('event.source').ifEmptyString(undefined),
  top_match: get('event').as(getTopMatch),
  match_result: get('event.matches').as(getMatchResults),
  face_detections: get('event.detections').as((detections) => getDetectionsByType(detections, 'face')),
  body_detections: get('event.detections').as((detections) => getDetectionsByType(detections, 'body')),
  face_id: get('event.face.face_id').asString(),
  attach_result: get('event.face.lists', []),
  gender: get('event.face_attributes.basic_attributes.gender'),
  age: get('event.face_attributes.basic_attributes.age'),
  emotion: get('event').as(getEmotionEstimation),
  mask: get('event').as(getMaskEstimation),
  liveness: get('event').as(getLiveness),
  deepfake: get('event').as(getDeepfake),
  tags: get('event.tags', []),
  user_data: get('event.user_data').asString().ifEmptyString(undefined),
  location: get('event.location').ifEmpty(null).asSchema(new Schema({
    city: get('city'),
    area: get('area'),
    district: get('district'),
    street: get('street'),
    houseNumber: get('house_number'),
    geo_position: get('geo_position').ifEmpty(null).asSchema(new Schema({
      longitude: get('longitude').asFloat().ifNaN(undefined),
      latitude: get('latitude').asFloat().ifNaN(undefined),
    })),
  })),
  track_id: get('event.track_id').ifEmptyString(null),
  body_basic_attributes: get('event').as((e) => getBodyAttributes(e, 'basic_attributes')),
  upper_body: get('event').as((e) => getBodyAttributes(e, 'upper_body')),
  lower_body: get('event').as((e) => getBodyAttributes(e, 'lower_body')),
  accessories: get('event').as((e) => getBodyAttributes(e, 'accessories')),
});
