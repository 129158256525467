import React, { useState, useEffect } from 'react';

import { actionColumnProps } from '@vlabs/shared/utils';
import { Page, Panel, Table, TableCells, Modal, openConfirmPopup } from '@vlabs/uikit';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { SimilarityCell } from '../components/image-cell/SimilarityCell';
import PlansForm from './PlansForm';
import { selectSlice } from './selectors';
import * as plansStore from './store';

function PlansListPageComponent({
  plans,
  fetchPlans,
  submitPlan,
  deletePlan,
  setPageIndex,
  setPageSize,
}) {
  const { t } = useTranslation();
  const history = useHistory();

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(undefined);

  const closeModal = () => {
    setSelectedPlan(undefined);
    setModalIsOpen(false);
  };

  const openModal = (plan) => {
    setSelectedPlan(plan);
    setModalIsOpen(true);
  };

  useEffect(() => { fetchPlans(); }, [fetchPlans]);

  const onFormSubmit = (values) => {
    submitPlan(values);
    setModalIsOpen(false);
  };

  const $deletePlan = ({ id }) => {
    const message = t('plans:подтверждение.удаление.message');

    const onConfirm = async () => {
      deletePlan(id);
    };

    openConfirmPopup({
      title: t('plans:подтверждение.удаление.title'),
      message,
      type: 'delete',
      onConfirm,
    });
  };

  return (
    <Page breadcrumbs={[
      { caption: t('plans:page title') },
    ]}
    >
      <Modal
        appElement={document.getElementById('root')}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
      >
        <PlansForm
          defaultValues={selectedPlan}
          onSubmit={onFormSubmit}
        />
      </Modal>
      <Panel>
        <Table
          columns={[
            {
              Header: t('plans:plan id'),
              accessor: 'id',
            },
            {
              Header: t('plans:plan name'),
              accessor: 'name',
            },
            {
              Header: t('plans:plan'),
              accessor: 'plan_image_url',
              Cell: ({ value }) => SimilarityCell(value),
            },
            actionColumnProps({
              id: 'action2',
              type: 'action',
              Cell: TableCells.EditCell,
            }),
            actionColumnProps({
              id: 'action3',
              type: 'action',
              Cell: TableCells.DeleteCell,
            })]}
          data={plans.pageData}
          onActions={{
            onAddRow: { handler: openModal },
            onEditRow: { handler: openModal },
            onClickRow: { handler: (data) => history.push(`/plans/${data.id}/calibrations`) },
            onDeleteRow: { handler: $deletePlan },
          }}
          pageIndex={plans.pageIndex}
          pageSize={plans.pageSize}
          pageSizeOptions={plans.pageSizeOptions}
          paginationType="offsetBased"
          setPageIndex={setPageIndex}
          setPageSize={setPageSize}
        />

      </Panel>
    </Page>
  );
}

export const PlansListPage = connect(
  (state) => ({
    plans: selectSlice(state),
  }),
  (dispatch) => ({
    fetchPlans: () => dispatch(plansStore.fetchPlans),
    submitPlan: (params) => dispatch(plansStore.submitPlan(params)),
    deletePlan: (id) => dispatch(plansStore.deletePlan(id)),
    setPageSize: (pageSize) => dispatch(plansStore.setPageSize({ pageSize })),
    setPageIndex: (pageIndex) => dispatch(plansStore.setPage({ pageIndex })),
  }),
)(PlansListPageComponent);
