import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

const TabHeader = ({
  className,
  testId,
  index,
  header,
  activeTab,
  onTabActivation,
  disabled,
  title,
  ...props
}) => {
  const $onTabActivation = () => {
    if (disabled) return;
    onTabActivation(index);
  };

  return (
    <div
      className={cn(
        className,
        {
          Tabs__Header: true,
          Tabs__Header_active: activeTab === index,
          Tabs__Header_disabled: disabled,
          InteractionWrapper: !disabled,
        },
      )}
      data-index={index}
      data-testid={testId}
      onClick={$onTabActivation}
      onKeyUp={$onTabActivation}
      role="button"
      tabIndex="0"
      title={title}
      {...props}
    >
      <div className="InteractionOverlay" />
      {header}
    </div>
  );
};

TabHeader.propTypes = {
  className: PropTypes.string,
  testId: PropTypes.string,
  index: PropTypes.number.isRequired,
  header: PropTypes.string.isRequired,
  activeTab: PropTypes.number.isRequired,
  onTabActivation: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  title: PropTypes.string,
};

TabHeader.defaultProps = {
  className: '',
  testId: '',
  disabled: false,
  title: undefined,
};

export { TabHeader };
