import React, { memo } from 'react';

import { IconInner } from '../../IconInner';

const HomeIconInner = (props) => (
  <IconInner name="HomeIcon" viewBox="0 0 24 24" {...props}>
    <path d="m3 9 9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V9Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
    <path d="M9 22V12h6v10" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
  </IconInner>
);

export const HomeIcon = memo(HomeIconInner);
