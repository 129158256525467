const defaultGetter = {
  get(target, prop) {
    if (prop === 'raw') return target;

    const strictValue = target.find(({ value }) => value === prop);
    if (strictValue) return strictValue;

    // eslint-disable-next-line eqeqeq
    const looseValue = target.find(({ value }) => value == prop);
    if (looseValue) return looseValue;

    return undefined;
  },
};

const create = (
  options,
  getter = defaultGetter,
) => {
  return new Proxy(options, getter);
};

export const optionsProxy = {
  create,
};
