import React, { memo } from 'react';

import { IconInner } from '../../IconInner';

const ChevronRightIconInner = (props) => (
  <IconInner name="ChevronRightIcon" viewBox="0 0 24 24" {...props}>
    <path d="m9 18 6-6-6-6" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
  </IconInner>
);

export const ChevronRightIcon = memo(ChevronRightIconInner);
