import { loadProgressBar } from '@vlabs/axios-progress-bar';
import Axios from 'axios';
import formatISO from 'date-fns/formatISO';
import qs from 'qs';

import { copyEvent } from './mappers/copyEvent';

export class VAToolboxClient {
  constructor() {
    this.baseURL = '/api/va-toolbox';
    this.http = Axios.create({
      baseURL: this.baseURL,
      withCredentials: true,
      paramsSerializer: (params) => (
        qs.stringify(params, {
          arrayFormat: 'comma',
          serializeDate: formatISO,
        })),
    });
    loadProgressBar('', this.http);
  }

  healthcheck() {
    return this.http.head('/').catch((e) => {
      if (e.response.status >= 500) throw e;
    });
  }

  get events() {
    return {
      delete: async (eventId) => this.http.delete(`/events/${eventId}`),
      copy: async (eventId, params) => this.http.post(`/events/${eventId}`, copyEvent(params)),
    };
  }
}
