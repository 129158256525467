/* eslint-disable react/prop-types */
import React, { useState, useCallback, useEffect, useMemo } from 'react';

import { apiContainer } from '@vlabs/api-bindings';
import { DownloadIcon } from '@vlabs/icons';
import { AdditionalCell, SampleCell, SimilarityCell } from '@vlabs/pages/events/event-page/cells';
import { DetectionCard, CommonEventInfo, TopMatchCard, LocationInfo } from '@vlabs/pages/events/event-page/components';
import { GoToCell } from '@vlabs/shared/components/table';
import { permissions } from '@vlabs/shared/config';
import { downloadJson } from '@vlabs/shared/utils';
import { Divider, Grid, GridCol, GridRow, Page, Panel, Table, TableCells, openConfirmPopup } from '@vlabs/uikit';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { viewerCan } from '@vlabs/pages/auth/selectors';

import st from './EventPage.module.sass';
import { fetchAdditionalMatchData } from './utils/apiUtils';
import { PAGE_SIZE, INITIAL_PAGE_INDEX, paginateData, transformEventData } from './utils/dataUtils';

const EventPage = ({ can }) => {
  const { t } = useTranslation();
  const { eventId } = useParams();
  const [event, setEvent] = useState();
  const [matchListByPage, setMatchListByPage] = useState();

  const filterRowsByPermissions = (matchList) => {
    return matchList?.filter((match) => {
      if (match?.type === 'face' && !can([permissions.face.view])) {
        return false;
      }
      return true;
    });
  };

  const fetchEvent = useCallback(async () => {
    try {
      const { data } = await apiContainer.lunaClient.events.get(eventId);
      const matchList = transformEventData(data?.match_result)?.sort((a, b) => b.similarity - a.similarity);
      setEvent({ ...data, matchList });

      const initialMatchList = paginateData(matchList, INITIAL_PAGE_INDEX);
      const enrichedData = await fetchAdditionalMatchData(initialMatchList);
      setMatchListByPage(enrichedData);
    } catch ({ error }) {
      if (error?.response?.data?.error_code === 23001) {
        return setEvent(null);
      }
      throw error;
    }
  }, []);

  useEffect(() => { fetchEvent(); }, [eventId, fetchEvent]);
  const filteredMatchListByPage = useMemo(() => filterRowsByPermissions(matchListByPage), [matchListByPage]);

  const updatePage = useCallback(async (i) => {
    const matchesByIndex = paginateData(event?.matchList, i);
    const matches = await fetchAdditionalMatchData(matchesByIndex);
    setMatchListByPage(matches);
  }, [event?.matchList]);

  const onDownloadHandler = ({ event_id, meta }) => {
    const onConfirm = () => {
      downloadJson(`${event_id}_meta`, meta);
    };

    openConfirmPopup({
      icon: <DownloadIcon />,
      title: t('events:подтверждение.загрузить мета.заголовок'),
      message: t('events:подтверждение.загрузить мета.текст'),
      onConfirm,
      confirmButtonProps: {
        kind: 'success',
      },
      confirmLabel: t('events:подтверждение.submit', { context: 'скачать' }),
    });
  };

  const columns = useMemo(() => ([
    {
      Header: t('events:match table.event photo'),
      Cell: SampleCell,
      width: 70,
    },
    {
      Header: t('events:similarity'),
      accessor: 'similarity',
      Cell: SimilarityCell,
      width: 20,
    },
    {
      Header: t('events:match table.type'),
      accessor: (value) => t(`${value?.type}`),
      width: 20,
    },
    { Header: t('date created'), accessor: 'create_time', Cell: TableCells.DateCell, width: 80 },
    { Header: t('label'), accessor: 'label', width: 80 },
    {
      Header: t('additional information'),
      id: 1,
      Cell: AdditionalCell,
    },
    {
      id: 2,
      accessor: ({ event_id: eventId, face_id: faceId, isDeleted }) => {
        if (isDeleted) return undefined;
        return eventId ? `/events/${eventId}` : `/faces/${faceId}`;
      },
      Cell: GoToCell,
      width: 30,
    },
  ]), [t]);

  if (!event) return null;

  return (
    <Page className={st.Page} title={t('events:page.title')}>
      <Divider small />
      <Panel className={st.Panel}>
        <Grid>
          <GridRow>
            <GridCol cols={6}>
              <CommonEventInfo event={event} onDownloadHandler={onDownloadHandler} />
            </GridCol>
            <GridCol cols={6}>
              <LocationInfo {...event} />
            </GridCol>
          </GridRow>
        </Grid>
      </Panel>

      <Divider small />

      <Grid>
        <GridRow>
          <GridCol cols={6}>
            <DetectionCard event={event} />
          </GridCol>

          <GridCol cols={6}>
            {event?.top_match && <TopMatchCard topMatch={event?.top_match} />}
          </GridCol>
        </GridRow>
      </Grid>

      {filteredMatchListByPage?.length > 0 && (
        <>
          <Divider small />
          <Panel>
            <h6 className={st.MatchTable__Title}>{t('events:match table.title')}</h6>
            <Table
              columns={columns}
              data={filteredMatchListByPage}
              pageIndex={INITIAL_PAGE_INDEX}
              pageSize={PAGE_SIZE}
              pageSizeOptions={[PAGE_SIZE]}
              paginationType="offsetBased"
              setPageIndex={updatePage}
            />
          </Panel>
        </>
      )}
    </Page>
  );
};

export default connect((state) => ({
  can: viewerCan(state),
}))(EventPage);
