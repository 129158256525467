import { createSlice } from '@reduxjs/toolkit';
import { apiContainer } from '@vlabs/api-bindings';
import i18n from 'i18next';
import { toast } from 'react-toastify';

const initialState = {
  state: undefined,
  handlerOptions: [],
  dynamicHandlerId: undefined,
  data: [],
  meta: {},
  byId: [],
  pageData: [],
  pageIndex: 0,
  pageSize: 25,
  pageSizeOptions: [10, 25, 50, 100],
};

const store = createSlice({
  name: 'handlers',
  initialState,
  reducers: {
    setHandlers(state, { payload: { data, meta } }) {
      state.data = data;
      state.meta = meta;
      state.byId = [];
      data.forEach((h) => {
        const { handlerId } = h;
        state.byId[handlerId] = h;
      });
      state.handlerOptions = data.map(({ handlerId, description, isDynamic = false }) => ({
        value: handlerId,
        label: description,
        isDynamic,
        linkValueForStreams: window.location.origin + apiContainer.lunaClient.events.getGenerateURL(handlerId),
      })) || [];
      state.dynamicHandlerId = data.find(({ isDynamic }) => isDynamic)?.handlerId;
      state.state = 'loaded';
    },
    setPage(state, { payload: { pageIndex } = {} }) {
      if (pageIndex !== undefined) state.pageIndex = pageIndex;
    },
    setPageSize(state, { payload: { pageSize } = {} }) {
      if (pageSize !== undefined) state.pageSize = pageSize;
    },
    setPageData(state, { payload }) {
      state.pageData = payload;
    },
  },
});

export default store.reducer;

export const {
  setHandlerOptions, setHandlers,
  setPageSize, setPage, setPageData,
} = store.actions;

export const fetchHandlerList = async (dispatch) => {
  const data = await apiContainer.lunaClient.handlers.getAll();

  dispatch(setHandlers(data));
};

const fetchPage = async (dispatch, getState) => {
  const { handlers: {
    pageSize, pageIndex,
  } } = getState();

  const data = await apiContainer.lunaClient.handlers.getPage({ page_size: pageSize, page: pageIndex + 1 });
  dispatch(setPageData(data));
};

export const getAsyncHandlerOptions = async (params) => {
  const handlers = await apiContainer.lunaClient.handlers.getPage({ ...params });
  return handlers.map(({ handlerId, description }) => ({ value: handlerId, label: description }));
};

export const fetchHandler = async (id) => {
  const { data } = await apiContainer.lunaClient.handlers.get(id);
  return data;
};

export const submitHandlerData = (handlerId, values) => async (dispatch) => {
  if (handlerId === 'create' || handlerId === undefined) {
    await apiContainer.lunaClient.handlers.create(values);
    toast.success(`${i18n.t('handlers:api.успешно создан')}.`);
  } else {
    await apiContainer.lunaClient.handlers.update(handlerId, values);
    toast.success(`${i18n.t('handlers:api.успешно обновлен')}.`);
  }
  dispatch(fetchHandlerList);
  dispatch(fetchPage);
};

export const createHandler = (values) => async (dispatch) => {
  const { handler_id: handlerId } = await apiContainer.lunaClient.handlers.create(values);
  // dispatch(setCurrentHandler(handlerId));
  dispatch(fetchHandlerList);
  return handlerId;
};

export const createPreconfiguredHandler = (values) => async (dispatch) => {
  const { data: { handler_id: handlerId } } = await apiContainer.lunaClient.handlers.rawCreate(values);
  // dispatch(setCurrentHandler(handlerId));
  dispatch(fetchHandlerList);
  return handlerId;
};

export const deleteHandler = (id) => async (dispatch) => {
  await apiContainer.lunaClient.handlers.delete(id);
  toast.success(`${i18n.t('handlers:api.успешно удален')}.`);
  dispatch(fetchHandlerList);
  dispatch(fetchPage);
};

export const updatePageSize = (params) => async (dispatch) => {
  dispatch(setPageSize(params));
  dispatch(fetchPage);
};

export const updatePage = (params) => async (dispatch) => {
  dispatch(setPage(params));
  dispatch(fetchPage);
};
