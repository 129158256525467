import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import './Panel.sass';

const Panel = ({
  children,
  noBorder,
  className,
  withPadding,
  ...respProps
}) => {
  return (
    <div
      className={cn(
        className,
        {
          Panel: true,
          Panel_noBorder: noBorder,
          Panel_withPadding: withPadding,
        },
      )}
      {...respProps}
    >
      {children}
    </div>
  );
};

Panel.propTypes = {
  children: PropTypes.node,
  noBorder: PropTypes.bool,
  className: PropTypes.any,
  withPadding: PropTypes.bool,
};
Panel.defaultProps = {
  children: undefined,
  noBorder: undefined,
  className: undefined,
  withPadding: undefined,
};

export { Panel };
